import { Fragment } from 'react';
import { fromJS, List, Map } from 'immutable';

import { cn, FormGroup, Label, Link, TextInput } from '@keboola/design';

import { KEBOOLA_SNOWFLAKE_TRANSFORMATION } from '@/constants/componentIds';
import { FEATURE_DELETE_ROWS_BY_REFERENCE } from '@/constants/features';
import InfoTooltip from '@/react/common/InfoTooltip';
import Select from '@/react/common/Select';
import TextDivider from '@/react/common/TextDivider';
import ApplicationStore from '@/stores/ApplicationStore';

const OPERATORS_OPTIONS = [
  { label: 'in', value: 'eq' },
  { label: 'not in', value: 'ne' },
] as const;

const VALUES_FROM_OPTIONS = [
  { label: 'From values', value: 'values_from_set' },
  {
    label: 'From transformation table',
    value: 'values_from_workspace',
  },
] as const;

type VALUES_FROM_SET = List<string>;
type VALUES_FROM_WORKSPACE = Map<string, any>;
type Operator = (typeof OPERATORS_OPTIONS)[number]['value'];
type ValuesFrom = (typeof VALUES_FROM_OPTIONS)[number]['value'];
type DeleteWhereProperty = 'changed_since' | 'changed_until';
type WhereFilterProperty = 'column' | 'operator' | 'values_from_set' | 'values_from_workspace';
type WhereFilterValue = string | Operator | VALUES_FROM_SET | VALUES_FROM_WORKSPACE;

export const DeleteWhereInputs = (props: {
  show: boolean;
  componentId: string;
  value: Map<string, any>;
  onChange: (value: Map<string, any>) => void;
  columnsOptions: { label: string; value: string }[];
  disabled: boolean;
}) => {
  const isLegacyConfigUsed = props.value.get('delete_where_column', '') !== '';
  const isNewConfigUsed = !props.value.get('delete_where', List()).isEmpty();

  if (!props.show && !isLegacyConfigUsed && !isNewConfigUsed) {
    return null;
  }

  const getDeleteWhere = (index: number, property: DeleteWhereProperty) => {
    return props.value.getIn(['delete_where', index, property]);
  };

  const setDeleteWhere = (index: number, property: DeleteWhereProperty, value: string) => {
    props.onChange(
      props.value.update('delete_where', List(), (deleteWhere) => {
        return deleteWhere.setIn([index, property], value);
      }),
    );
  };

  const getWhereFilters = (
    whereIndex: number,
    filterIndex: number,
    property: WhereFilterProperty,
  ) => {
    return props.value.getIn(['delete_where', whereIndex, 'where_filters', filterIndex, property]);
  };

  const setWhereFilters = (
    whereIndex: number,
    filterIndex: number,
    property: WhereFilterProperty,
    value: WhereFilterValue,
  ) => {
    props.onChange(
      props.value.update('delete_where', List(), (deleteWhere: List<any>) => {
        return deleteWhere.updateIn([whereIndex, 'where_filters'], List(), (filters: List<any>) => {
          return filters.update(
            filterIndex,
            fromJS({ column: '', operator: 'eq', values_from_set: [] }),
            (filter: Map<string, any>) => {
              return filter.withMutations((filter: Map<string, any>) => {
                filter.set(property, value);

                if (property === 'values_from_workspace') {
                  filter.delete('values_from_set');
                }

                if (property === 'values_from_set') {
                  filter.delete('values_from_workspace');
                }
              });
            },
          );
        });
      }),
    );
  };

  const renderLegacyForm = () => {
    return (
      <>
        <div className="tw-grid tw-grid-cols-2 tw-gap-1">
          <Select
            allowCreate
            id="delete-where"
            options={props.columnsOptions}
            placeholder="Select a column"
            ariaLabel="Select a delete where column"
            disabled={props.disabled}
            value={props.value.get('delete_where_column', '')}
            onChange={(value: string) => {
              props.onChange(props.value.set('delete_where_column', value));
            }}
            promptTextCreator={(label) => (label ? `Select the "${label}" column` : '')}
          />
          <Select
            clearable={false}
            searchable={false}
            ariaLabel="Select a delete where operator"
            disabled={props.disabled}
            value={props.value.get('delete_where_operator')}
            onChange={(value: string) => {
              props.onChange(props.value.set('delete_where_operator', value));
            }}
            options={OPERATORS_OPTIONS}
          />
        </div>
        <Select
          multi
          allowCreate
          emptyStrings
          hideSelectAllOptions
          placeholder="Add a value"
          ariaLabel="Add a delete where values"
          disabled={props.disabled}
          value={props.value.get('delete_where_values')}
          onChange={(value) => {
            props.onChange(props.value.set('delete_where_values', value));
          }}
        />
      </>
    );
  };

  const renderWhereFilterRow = (whereIndex: number, filterIndex: number) => {
    const getRowValue = (property: WhereFilterProperty) => {
      return getWhereFilters(whereIndex, filterIndex, property);
    };

    const setRowValue = (property: WhereFilterProperty, value: WhereFilterValue) => {
      setWhereFilters(whereIndex, filterIndex, property, value);
    };

    const fromWorkspace = getRowValue('values_from_workspace');
    const allowUseWorkspaceTable =
      fromWorkspace ||
      (props.componentId === KEBOOLA_SNOWFLAKE_TRANSFORMATION &&
        ApplicationStore.hasCurrentProjectFeature(FEATURE_DELETE_ROWS_BY_REFERENCE));

    return (
      <Fragment key={`where-filter-${whereIndex}-${filterIndex}`}>
        <div
          className={cn(
            'tw-grid tw-gap-1',
            allowUseWorkspaceTable ? 'tw-grid-cols-5' : 'tw-grid-cols-3',
          )}
        >
          <Select
            id={`delete-where-${whereIndex}-${filterIndex}`}
            allowCreate
            options={props.columnsOptions}
            placeholder="Select a column"
            ariaLabel="Select a delete where column"
            disabled={props.disabled}
            value={getRowValue('column')}
            onChange={(column: string) => setRowValue('column', column)}
            promptTextCreator={(label) => (label ? `Select the "${label}" column` : '')}
            className="tw-col-span-2"
          />
          <Select
            clearable={false}
            searchable={false}
            disabled={props.disabled}
            placeholder="Select operator"
            ariaLabel="Select a delete where operator"
            options={OPERATORS_OPTIONS}
            value={getRowValue('operator') ?? 'eq'}
            onChange={(operator: Operator) => setRowValue('operator', operator)}
          />
          {allowUseWorkspaceTable && (
            <Select
              clearable={false}
              disabled={props.disabled}
              options={VALUES_FROM_OPTIONS}
              ariaLabel="Select a delete where values from"
              value={fromWorkspace ? 'values_from_workspace' : 'values_from_set'}
              onChange={(from: ValuesFrom) => {
                from === 'values_from_workspace'
                  ? setRowValue('values_from_workspace', Map({ table: '', column: '' }))
                  : setRowValue('values_from_set', List());
              }}
              className="tw-col-span-2"
            />
          )}
        </div>
        {fromWorkspace ? (
          <div className="tw-grid tw-grid-cols-2 tw-gap-1">
            <TextInput
              variant="secondary"
              placeholder="Table name"
              aria-label="Workspace table name"
              disabled={props.disabled}
              value={fromWorkspace.get('table')}
              onChange={(value) =>
                setRowValue('values_from_workspace', fromWorkspace.set('table', value))
              }
            />
            <TextInput
              variant="secondary"
              placeholder="Column name"
              aria-label="Workspace column name"
              disabled={props.disabled}
              value={fromWorkspace.get('column')}
              onChange={(value) =>
                setRowValue('values_from_workspace', fromWorkspace.set('column', value))
              }
            />
          </div>
        ) : (
          <Select
            multi
            allowCreate
            emptyStrings
            hideSelectAllOptions
            placeholder="Enter value"
            ariaLabel="Delete where values"
            disabled={props.disabled}
            value={getRowValue('values_from_set')}
            onChange={(value) => setRowValue('values_from_set', value)}
            promptTextCreator={(label) => `Set value "${label}"`}
          />
        )}
      </Fragment>
    );
  };

  const renderDeleteWhereRow = (whereIndex: number) => {
    return (
      <>
        {props.value
          .getIn(['delete_where', whereIndex, 'where_filters'], fromJS([{ column: '' }]))
          .map((_: any, index: number, list: List<any>) => {
            return (
              <>
                {renderWhereFilterRow(whereIndex, index)}
                {index < list.size - 1 && (
                  <TextDivider key={`separator-${whereIndex}-${index}`} label="AND" />
                )}
              </>
            );
          })
          .toArray()}
        {(getDeleteWhere(whereIndex, 'changed_since') ||
          getDeleteWhere(whereIndex, 'changed_until')) && (
          <>
            <TextDivider label="AND" />
            <div className="tw-grid tw-grid-cols-2 tw-gap-1">
              <FormGroup>
                <Label htmlFor={`changed-since-${whereIndex}`}>
                  Changed since
                  <StrtotimeInfoTooltip />
                </Label>
                <TextInput
                  id={`changed-since-${whereIndex}`}
                  variant="secondary"
                  disabled={props.disabled}
                  value={getDeleteWhere(whereIndex, 'changed_since')}
                  onChange={(value) => setDeleteWhere(whereIndex, 'changed_since', value)}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor={`changed-until-${whereIndex}`}>
                  Changed until
                  <StrtotimeInfoTooltip />
                </Label>
                <TextInput
                  id={`changed-until-${whereIndex}`}
                  variant="secondary"
                  disabled={props.disabled}
                  value={getDeleteWhere(whereIndex, 'changed_until')}
                  onChange={(value) => setDeleteWhere(whereIndex, 'changed_until', value)}
                />
              </FormGroup>
            </div>
          </>
        )}
      </>
    );
  };

  return (
    <FormGroup className="tw-mb-4">
      <Label htmlFor={isLegacyConfigUsed ? 'delete-where' : 'delete-where-0-0'}>
        Delete Rows <Label.Optional />
        <InfoTooltip tooltip="Delete matching rows in the destination table before importing the result." />
      </Label>
      {isLegacyConfigUsed
        ? renderLegacyForm()
        : props.value
            .get('delete_where', fromJS([{ where_filters: [] }]))
            .map((_: any, index: number, list: List<any>) => {
              return (
                <>
                  {renderDeleteWhereRow(index)}
                  {index < list.size - 1 && (
                    <TextDivider
                      key={`separator-${index}`}
                      label="Next query"
                      className="tw-my-4"
                    />
                  )}
                </>
              );
            })
            .toArray()}
    </FormGroup>
  );
};

const StrtotimeInfoTooltip = () => {
  return (
    <InfoTooltip
      tooltip={
        <>
          <p>
            Use <Link href="https://php.net/manual/en/function.strtotime.php">strtotime</Link>{' '}
            format.
          </p>
          <ul className="tw-mb-0 tw-mt-1 tw-list-none tw-pl-0">
            <li>-7 days</li>
            <li>last Monday</li>
            <li>2024-01-01</li>
          </ul>
        </>
      }
    />
  );
};
