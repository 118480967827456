import type { ReactNode } from 'react';

import { cn, IconButton } from '@keboola/design';

import { FullScreenModalHeader } from '@/react/common/FullScreenModal';

export const SidebarModalHeader = ({
  onHide,
  children,
  additionalButtons,
}: {
  onHide: () => void;
  children: ReactNode;
  additionalButtons?: ReactNode;
}) => {
  return (
    <FullScreenModalHeader
      showPreviousTitle={false}
      title={children}
      onClose={onHide}
      className="tw-border-0 tw-bg-white tw-px-3 tw-py-0"
    >
      {additionalButtons}
      <IconButton
        icon="xmark"
        onClick={onHide}
        variant="outline"
        className={cn('tw-shrink-0', { 'tw-ml-10': !additionalButtons })}
      />
    </FullScreenModalHeader>
  );
};
