export const OUTPUT_TABLES_DEFAULT = 1;

export const DURATION_DEFAULT = 1800;
export const DURATION_OPTIONS = [
  { label: '2 minutes', value: 120 },
  { label: '5 minutes', value: 300 },
  { label: '10 minutes', value: 600 },
  { label: '30 minutes', value: 1800 },
  { label: '1 hour', value: 3600 },
  { label: '2 hours', value: 7200 },
  { label: '4 hours', value: 14400 },
  { label: '8 hours', value: 28800 },
  { label: '12 hours', value: 43200 },
  { label: '24 hours', value: 86400 },
] as const;

export const STATUS_DEFAULT = 'error';
export const STATUS_OPTIONS = [
  { label: 'Success', value: 'success' },
  { label: 'Error', value: 'error' },
  { label: 'Warning', value: 'warning' },
  { label: 'Terminated', value: 'terminated' },
] as const;

export const BASIC_OPERATOR_OPTIONS = [
  { label: 'Is', value: 'EQUALS' },
  { label: 'Is not', value: 'NOT_EQUALS' },
] as const;

export const RELATIVE_OPERATOR_OPTIONS = [
  { label: 'Greater than', value: 'GREATER_THAN' },
  { label: 'Less than', value: 'LESS_THAN' },
] as const;

export const PHASE_OPTIONS = [
  { label: 'Phase Status', value: 'job.status' },
  { label: 'Phase Duration', value: 'job.duration', isDisabled: true },
] as const;

export const JOB_OPTIONS = [
  { label: 'Job Status', value: 'job.status' },
  { label: 'Job Duration', value: 'job.duration' },
  { label: 'Output tables', value: 'job.result.output.tables' },
] as const;
