import { useState } from 'react';
import type Promise from 'bluebird';
import { fromJS, Map } from 'immutable';

import { Button, Icon } from '@keboola/design';

import { createSimpleRow } from '@/modules/configurations/ConfigurationRowsActionCreators';
import CreateRowModal from '@/react/common/CreateRowModal';
import string from '@/utils/string';

export type OnCreate = (
  tableId: string | null,
  name: string,
  options?: { forceData?: Map<string, any>; addStorageTable?: boolean; description?: string },
) => Promise<any>;

export type OnRowCreated = (rowId: string) => void;

type EmptyConfig = (tableId: string | null, name: string) => Map<string, any>;

type Props = {
  component: Map<string, any>;
  configId: string;
  onRowCreated: OnRowCreated;
  tables?: Map<string, any>;
  buckets?: Map<string, any>;
  configData?: Map<string, any>;
  emptyConfig?: EmptyConfig;
  objectName?: string;
};

export const CreateConfigurationRowButton = ({
  component,
  configId,
  onRowCreated,
  tables,
  buckets,
  configData = Map(),
  emptyConfig = () => Map(),
  objectName = 'Row',
}: Props) => {
  const [show, setShow] = useState(false);

  const handleCreate: OnCreate = (tableId, name, options) => {
    let configData = options?.forceData ?? emptyConfig(tableId, string.webalize(tableId ?? ''));

    if (configData.isEmpty() && !component.get('emptyConfigurationRow', Map()).isEmpty()) {
      configData = component.get('emptyConfigurationRow');
    }

    if (options?.addStorageTable) {
      configData = configData.setIn(
        ['storage', 'input', 'tables'],
        fromJS([{ source: tableId, destination: `${tableId}.csv` }]),
      );
    }

    return createSimpleRow(
      component.get('id'),
      configId,
      {
        name,
        description: options?.description ?? '',
        configuration: JSON.stringify(configData.toJS()),
      },
      `${objectName} ${name} added`,
    );
  };

  return (
    <>
      <Button size="small" onClick={() => setShow(true)}>
        <Icon icon="plus" className="icon-addon-right" />
        Add {objectName.toLowerCase()}
      </Button>
      <CreateRowModal
        component={component}
        configId={configId}
        configData={configData}
        entity={objectName}
        buckets={buckets}
        tables={tables}
        show={show}
        onHide={() => setShow(false)}
        onCreate={handleCreate}
        onRowRedirect={onRowCreated}
      />
    </>
  );
};
