import { memo, useState } from 'react';
import { useDroppable } from '@dnd-kit/react';
import { Handle, Position } from '@xyflow/react';
import { Promise } from 'bluebird';
import { fromJS } from 'immutable';

import { cn } from '@keboola/design';

import PhaseEditModal from '@/modules/flows/components/PhaseEditModal';
import { AddButton } from '@/modules/flows-v2/builder/components/AddButton';
import PhaseActions from '@/modules/flows-v2/builder/components/PhaseActions';
import { PhaseLabel } from '@/modules/flows-v2/builder/components/PhaseLabel';
import { PhaseStatus } from '@/modules/flows-v2/builder/components/PhaseStatus';
import { PhaseTask } from '@/modules/flows-v2/builder/components/PhaseTask';
import { TaskBox } from '@/modules/flows-v2/builder/components/TaskBox';
import TaskCount from '@/modules/flows-v2/builder/components/TaskCount';
import { preparePhasesAndTasksAfterDelete, TASK_WIDTH } from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { VisualPhase } from '@/modules/flows-v2/builder/types';
import { updateLocalStateValue } from '@/modules/flows-v2/helpers';

export const Phase = memo(({ id, data }: { id: string; data: VisualPhase }) => {
  const [showEdit, setShowEdit] = useState(false);

  const [
    context,
    nodeDragging,
    selectedPhaseId,
    selectedTaskId,
    selectedAddButton,
    setSelectedPhaseId,
    setSelectedTaskId,
  ] = useBuilderStore((state) => [
    state.context,
    state.nodeDragging,
    state.selectedPhaseId,
    state.selectedTaskId,
    state.selectedAddButton,
    state.setSelectedPhaseId,
    state.setSelectedTaskId,
  ]);

  const { readOnly, config, phases, tasks } = context;

  const { isDropTarget, ref } = useDroppable({ id: data.id, disabled: readOnly });

  const isSelected = selectedPhaseId === data.id || selectedAddButton === data.id;

  const handleUpdate = (id: string, name: string, description: string) => {
    const newPhases = phases.map((p) => {
      if (p.get('id') === id) {
        return p.set('name', name).set('description', description);
      }

      return p;
    });

    updateLocalStateValue(config.get('id'), ['phases'], newPhases);
  };

  const handleDelete = () => {
    const { newPhases, newTasks } = preparePhasesAndTasksAfterDelete(data.id, phases, tasks);

    if (!newPhases.some((phase) => phase.get('id') === selectedPhaseId)) {
      setSelectedPhaseId(null);
    }

    if (!newTasks.some((task) => task.get('id') === selectedTaskId)) {
      setSelectedTaskId(null);
    }

    updateLocalStateValue(config.get('id'), ['phases'], newPhases);
    updateLocalStateValue(config.get('id'), ['tasks'], newTasks);

    return Promise.resolve();
  };

  return (
    <div
      ref={ref}
      className={cn(
        'nopan tw-group/phase tw-relative tw-rounded-md tw-border-2 tw-border-solid tw-border-neutral-200 tw-py-4 tw-px-2.5',
        { 'tw-border-secondary-500': isSelected || isDropTarget },
      )}
    >
      <div className="tw-flex tw-justify-center">
        <TaskCount tasks={data.tasks.length} />

        <PhaseLabel
          name={data.name}
          shouldMergeInto={isDropTarget}
          description={data.description}
          readOnly={readOnly}
          onEdit={() => setShowEdit(true)}
        />

        <div
          className="tw-grid tw-gap-4"
          style={{
            gridTemplateColumns: `repeat(${Math.min(4, data.tasks.length)}, ${TASK_WIDTH}px)`,
          }}
        >
          {data.tasks.map((task) => (
            <PhaseTask key={task.id} phaseId={data.id} task={task} readOnly={readOnly} />
          ))}

          {data.tasks.length === 0 && (
            <TaskBox disabled className="tw-justify-center tw-bg-transparent tw-shadow-none">
              <AddButton id={id} />
            </TaskBox>
          )}
        </div>

        <PhaseStatus id={id} phaseData={data} />
      </div>

      {!readOnly && !nodeDragging && (
        <PhaseActions
          phase={data}
          isActive={isSelected}
          hideAddButton={data.tasks.length === 0}
          handleDelete={handleDelete}
        />
      )}

      <PhaseEditModal
        show={showEdit}
        phase={fromJS(data)}
        onHide={() => setShowEdit(false)}
        onSubmit={handleUpdate}
      />

      <Handle type="target" position={Position.Top} className="tw-opacity-0" />
      <Handle type="source" position={Position.Bottom} className="tw-opacity-0" />
    </div>
  );
});
