import { useState } from 'react';
import { type Map } from 'immutable';

import { canManageSharedBucket } from '@/modules/admin/privileges';
import { isBucketLinked } from '@/modules/storage/helpers';
import { RowActionMenuItem } from '@/react/common';
import { ForceUnlinkModal } from './modals';

type Props = {
  bucket: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
};

export const ForceUnlink = ({ bucket, sapiToken, urlTemplates }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const haveRights = canManageSharedBucket(sapiToken);
  const bucketLinked = isBucketLinked(bucket);

  const isDisabled = !bucketLinked || !haveRights;
  const disabledReason = !bucketLinked
    ? 'Bucket is not linked anywhere.'
    : !haveRights
      ? "You don't have access to unlink this Bucket. Ask your organization administrator for the permission."
      : '';

  return (
    <>
      <RowActionMenuItem
        onSelect={() => setShowModal(true)}
        disabled={isDisabled}
        disabledReason={disabledReason}
      >
        Force unlink
      </RowActionMenuItem>
      <ForceUnlinkModal
        show={showModal}
        closeModalFn={() => setShowModal(false)}
        bucket={bucket}
        sapiToken={sapiToken}
        urlTemplates={urlTemplates}
      />
    </>
  );
};
