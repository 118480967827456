import { memo } from 'react';

import { cn, Tooltip } from '@keboola/design';

import type { VisualPhase } from '@/modules/flows-v2/builder/types';
import { ActionButton } from './ActionButton';
import { AddButton } from './AddButton';

const PhaseActions = memo(
  (props: {
    phase: VisualPhase;
    isActive: boolean;
    handleDelete: () => void;
    hideAddButton?: boolean;
  }) => {
    return (
      <span
        className={cn(
          'tw-absolute -tw-top-4 tw-right-4 tw-flex tw-gap-2 tw-opacity-0 tw-transition-opacity group-hover/phase:tw-opacity-100',
          props.isActive && 'tw-opacity-100',
        )}
      >
        <Tooltip placement="top" tooltip="Delete Phase">
          <ActionButton onClick={() => props.handleDelete()} icon="trash" />
        </Tooltip>
        {!props.hideAddButton && <AddButton id={props.phase.id} />}
      </span>
    );
  },
);

export default PhaseActions;
