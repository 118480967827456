import type { MouseEvent } from 'react';
import { Promise } from 'bluebird';
import type { List, Map } from 'immutable';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareConfigurationForSave } from '@/modules/orchestrations-v2/helpers';
import { clearLocalState } from '@/modules/orchestrations-v2/localState';
import { removeSchedulers } from '@/modules/scheduler/actions';
import RoutesStore from '@/stores/RoutesStore';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import { shouldUseNewWindow, windowOpen } from '@/utils/windowOpen';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_ORCHESTRATOR),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadFlow = (configId: string) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_ORCHESTRATOR, configId);
};

const saveFlow = (config: Map<string, any>, tasks: Map<string, any>, phases: List<any>) => {
  const preparedTasks = tasks.map((task) => {
    return !task.getIn(['task', 'configId']) ? task.set('enabled', false) : task;
  });

  const configuration = prepareConfigurationForSave(
    config.get('configuration'),
    phases,
    preparedTasks,
  ).toJS();

  return updateFlowConfiguration(
    config.get('id'),
    { configuration: JSON.stringify(configuration) },
    'Update flow tasks',
  ).then(() => clearLocalState(config.get('id')));
};

const deleteFlow = (componentId: string, flow: Map<string, any>, options = {}) => {
  return InstalledComponentsActionCreators.deleteConfiguration(componentId, flow.get('id'), {
    transition: false,
    notification: true,
    ...options,
  }).then(() => removeSchedulers(flow.get('schedulers')));
};

const updateFlowConfiguration = (
  configurationId: string,
  configuration: {
    configuration?: string;
    isDisabled?: boolean;
  },
  changeDescription: string,
) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_ORCHESTRATOR,
    configurationId,
    configuration,
    changeDescription,
  );
};

const redirectToTask = ({
  componentId,
  configurationId,
  flowId,
  taskId,
  event,
}: {
  componentId: string;
  configurationId: string;
  flowId: string;
  taskId: string;
  event?: MouseEvent | null;
}) => {
  const params = resolveRouterLinkParams(componentId, configurationId, null, true);

  if (!params) return;

  if (shouldUseNewWindow(event)) {
    return windowOpen(createUrlWithBasename(params.to, params.params));
  }

  RoutesStore.getRouter().transitionTo(
    params.to,
    params.params,
    componentId !== KEBOOLA_ORCHESTRATOR ? { flowId, taskId, scrollY: window.scrollY } : null,
  );
};

export { loadAll, loadFlow, saveFlow, deleteFlow, updateFlowConfiguration, redirectToTask };
