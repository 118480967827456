import { useState } from 'react';

import type { ComponentConfig } from '@keboola/api-client';
import { DOCUMENTATION_URL } from '@keboola/constants';
import { ButtonInline, Card, FormGroup, Icon, Label, Link, Switch } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import type { parseRequirementsFile } from '@/modules/data-apps/components/Deployment/PackagesCard/utils';
import { mergePackagesWithRequirements } from '@/modules/data-apps/components/Deployment/PackagesCard/utils';
import InfoTooltip from '@/react/common/InfoTooltip';
import ApplicationStore from '@/stores/ApplicationStore';
import { PackagesHelpBlock } from './PackagesHelpBlock';
import { PackagesSelect } from './PackagesSelect';
import { UploadRequirementsFileModal } from './UploadRequirementsFileModal';

type Props = {
  componentId: string;
  configId: string;
};

export const PackagesCard = ({ componentId, configId }: Props) => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isPackagesLoading, setIsPackagesLoading] = useState(false);

  const toggleUploadModal = () => {
    setIsUploadModalOpen((open) => !open);
  };

  const configImmutable = InstalledComponentsStore.getConfig(componentId, configId);
  const config = configImmutable.toJS() as ComponentConfig<'data-app'>;

  const isReadOnly = ApplicationStore.isReadOnly();
  const packages = config.configuration.parameters?.packages ?? [];
  const isFreezeActive = config.configuration.parameters?.dataApp?.freezeDependencies ?? false;
  const requirements = config.state?.data_app?.['requirements.txt'] ?? '';

  const handleFreezeChange = (value: boolean) => {
    const newConfig = configImmutable
      .deleteIn(['configuration', 'parameters', 'dataApp', 'git'])
      .setIn(['configuration', 'parameters', 'dataApp', 'freezeDependencies'], value)
      .get('configuration');

    setIsPackagesLoading(true);
    InstalledComponentsActionCreators.saveComponentConfigData(
      componentId,
      configId,
      newConfig,
      'Change freeze dependencies',
    ).finally(() => setIsPackagesLoading(false));
  };

  const handlePackagesChange = (value: string[]) => {
    const data = configImmutable
      .deleteIn(['configuration', 'parameters', 'dataApp', 'git'])
      .setIn(['configuration', 'parameters', 'packages'], value)
      .get('configuration');

    setIsPackagesLoading(true);
    return InstalledComponentsActionCreators.saveComponentConfigData(
      componentId,
      configId,
      data,
      'Change packages',
    ).finally(() => setIsPackagesLoading(false));
  };

  const handleUploadRequirementsFile = (
    requirements: ReturnType<typeof parseRequirementsFile>,
    strategy: 'update' | 'replace',
  ) => {
    const newPackages = mergePackagesWithRequirements(packages, requirements, strategy).map(
      ({ name, operator, version }) => [name, operator ?? '', version ?? ''].join(''),
    );

    handlePackagesChange(newPackages);
  };

  return (
    <>
      <UploadRequirementsFileModal
        show={isUploadModalOpen}
        onHide={toggleUploadModal}
        onSubmit={handleUploadRequirementsFile}
        isLoading={isPackagesLoading}
      />

      <Card>
        <Card.Header className="tw-flex tw-items-center tw-justify-between">
          <div className="tw-flex tw-items-center">
            <Card.Title>Packages</Card.Title>
            <InfoTooltip
              tooltip={
                <>
                  For more information about package installation and usage, and the list of
                  pre-installed packages, see our{' '}
                  <Link href={DOCUMENTATION_URL.DATA_APPS_PACKAGES}>documentation</Link>.
                </>
              }
            />
          </div>
          <div className="tw-flex tw-gap-8">
            <ButtonInline
              variant="link-reverse"
              className="tw-font-medium"
              onClick={toggleUploadModal}
            >
              <Icon icon="upload" />
              Upload File
            </ButtonInline>

            <div className="tw-flex tw-items-center tw-gap-2">
              <Switch checked={isFreezeActive} onChange={handleFreezeChange} id="freeze-packages" />
              <Label htmlFor="freeze-packages">Freeze Version</Label>
              <InfoTooltip
                tooltip="Ensure long-term stability by locking your packages to a fixed version."
                className="tw-m-0"
              />
            </div>
          </div>
        </Card.Header>

        <Card.Content>
          <FormGroup>
            <PackagesSelect
              componentId={componentId}
              configId={configId}
              onChange={handlePackagesChange}
              value={packages}
              isLoading={isPackagesLoading}
              isReadOnly={isReadOnly}
            />
            {requirements && <PackagesHelpBlock requirements={requirements} />}
          </FormGroup>
        </Card.Content>
      </Card>
    </>
  );
};
