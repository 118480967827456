import type Bluebird from 'bluebird';
import qs from 'qs';
import type superagentrequest from 'superagent';

import { SERVICE_SANDBOXES } from '@/constants/serviceIds';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import type { HttpMethod } from '@/utils/request';
import request from '@/utils/request';

const createRequest = (method: HttpMethod, path: string) => {
  return request(method, `${ServicesStore.getServiceUrl(SERVICE_SANDBOXES)}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

const ensureAllSandboxes = (
  response: superagentrequest.Response,
  allData = [],
  prevLink: string | null = null,
): unknown[] | Bluebird<unknown> => {
  const newData = response.body || [];
  const data = allData.concat(newData);
  const nextPageLink = response.headers.link;

  // we have to check backend limit (500 items per page) and previous link to prevent infinite loop (due to GCP and MySQL pagination bug)
  if (newData.length === 500 && nextPageLink && nextPageLink !== prevLink) {
    const url = new URL(nextPageLink);

    return request('GET', `${url.origin}${url.pathname}`)
      .query(qs.parse(new URLSearchParams(url.search).toString()))
      .set('X-StorageApi-Token', ApplicationStore.getSapiTokenString())
      .promise()
      .then((nextResponse) => ensureAllSandboxes(nextResponse, data, nextPageLink));
  }

  return data;
};

const SandboxesApi = {
  loadProjectSettings() {
    return createRequest('GET', `projects/current`)
      .promise()
      .then((response) => response.body)
      .catch((error) => {
        if (error.response && error.response.status === HTTP_STATUS_CODE_NOT_FOUND) {
          return {};
        }

        throw error;
      });
  },

  getSandboxes() {
    return createRequest('GET', 'sandboxes')
      .query(
        DevBranchesStore.isDevModeActive() ? { branchId: DevBranchesStore.getCurrentId() } : {},
      )
      .promise()
      .then((response) => ensureAllSandboxes(response));
  },

  getSandbox(id: string) {
    return createRequest('GET', 'sandboxes/' + id)
      .promise()
      .then((response) => response.body);
  },

  updateSandboxes(id: string, params: Record<string, any>) {
    return createRequest('PUT', 'sandboxes/' + id)
      .send({
        ...params,
        // TODO: Remove `branchId` from here after backend will be fixed (https://keboola.atlassian.net/browse/PS-3221)
        ...(DevBranchesStore.isDevModeActive() && {
          branchId: DevBranchesStore.getCurrentId()?.toString(),
        }),
      })
      .promise()
      .then((response) => response.body);
  },
};

export default SandboxesApi;
