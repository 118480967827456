import { Promise } from 'bluebird';
import type { List, Map } from 'immutable';

import { KEBOOLA_FLOW, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { clearLocalState } from './helpers';

const loadAll = () => {
  return Promise.all([
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_FLOW),
    InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SCHEDULER),
  ]);
};

const loadFlow = (configId: string) => {
  return InstalledComponentsActionCreators.loadComponentConfigData(KEBOOLA_FLOW, configId);
};

const saveFlow = (config: Map<string, any>, tasks: List<any>, phases: List<any>) => {
  const preparedTasks = tasks.map((task) => {
    if (task.getIn(['task', 'type']) === 'job' && !task.getIn(['task', 'configId'])) {
      return task.set('enabled', false);
    }

    if (
      task.getIn(['task', 'type']) === 'notification' &&
      !task.getIn(['task', 'channel', 'recipient'])
    ) {
      return task.set('enabled', false);
    }

    return task;
  }) as List<any>;

  const configuration = config
    .get('configuration')
    .set('tasks', preparedTasks)
    .set('phases', phases)
    .toJS();

  return updateFlowConfiguration(
    config.get('id'),
    { configuration: JSON.stringify(configuration) },
    'Update flow tasks',
  ).then(() => clearLocalState(config.get('id')));
};

const updateFlowConfiguration = (
  configurationId: string,
  configuration: {
    configuration?: string;
    isDisabled?: boolean;
  },
  changeDescription: string,
) => {
  return InstalledComponentsActionCreators.updateComponentConfiguration(
    KEBOOLA_FLOW,
    configurationId,
    configuration,
    changeDescription,
  );
};

export { loadAll, loadFlow, saveFlow, updateFlowConfiguration };
