import { ReactFlowProvider } from '@xyflow/react';
import type { List, Map } from 'immutable';

import { BuilderCanvas } from './components/BuilderCanvas';
import { DndProvider } from './components/DndProvider';
import { Sidebar } from './components/Sidebar';
import { BuilderProvider } from './store/BuilderProvider';

type Props = {
  config: Map<string, any>;
  configId: string;
  phases: List<any>;
  tasks: List<any>;
  readOnly: boolean;
  isChanged: boolean;
  folders: Map<string, any>;
  allComponents: Map<string, any>;
  patternComponents: Map<string, any>;
  configurations: Map<string, any>;
  deletedComponents: Map<string, any>;
  tablesMetadataMap: Map<string, any>;
  hasDataApps: boolean;
  hasPayAsYouGo: boolean;
  isDevModeActive: boolean;
  hasSnowflakePartnerConnectLimited: boolean;
  showBackendSize: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
};

export const Builder = (props: Props) => {
  return (
    <ReactFlowProvider>
      <BuilderProvider
        config={props.config}
        phases={props.phases}
        tasks={props.tasks}
        components={props.allComponents}
        configurations={props.configurations}
        deletedComponents={props.deletedComponents}
        tablesMetadataMap={props.tablesMetadataMap}
        readOnly={props.readOnly}
      >
        <DndProvider>
          <BuilderCanvas />
        </DndProvider>
        <Sidebar
          isChanged={props.isChanged}
          folders={props.folders}
          allComponents={props.allComponents}
          patternComponents={props.patternComponents}
          configurations={props.configurations}
          hasDataApps={props.hasDataApps}
          hasPayAsYouGo={props.hasPayAsYouGo}
          isDevModeActive={props.isDevModeActive}
          hasSnowflakePartnerConnectLimited={props.hasSnowflakePartnerConnectLimited}
          showBackendSize={props.showBackendSize}
          hasSnowflakeDynamicBackendSize={props.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={props.hasJobsDynamicBackendSize}
        />
      </BuilderProvider>
    </ReactFlowProvider>
  );
};
