import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import ComponentsActionCreators from '@/modules/components/ComponentsActionCreators';
import { ensureComponentWithDetails, hasGenericCodeBlocksUI } from '@/modules/components/helpers';
import type { ModalState } from '@/modules/shared-codes/types';
import { ListModal } from '@/react/common/ListModal';
import { ConfigurationModal } from './ConfigurationModal';

type Props = {
  allowedTransformationComponents: Map<string, any>;
  show: boolean;
  onSubmit: (componentId: string, name: string) => Promise<void>;
  onHide: () => void;
};

const initialComponentState: ModalState = {
  step: 'list',
  component: null,
};

export const SharedCodesWizard = ({
  show,
  allowedTransformationComponents,
  onSubmit,
  onHide,
}: Props) => {
  const [componentState, setComponentState] = useState<ModalState>(initialComponentState);

  const handleHide = () => {
    setComponentState(initialComponentState);
    onHide();
  };

  const handleSelectComponent = (component: Map<string, any>) => {
    ComponentsActionCreators.loadComponent(component.get('id'))
      .then(() => {
        return ensureComponentWithDetails(component.get('id'));
      })
      .then((detailedComponent) => {
        setComponentState((prev) => ({
          ...prev,
          step: 'create',
          component: detailedComponent,
        }));
      });
  };

  return (
    <Modal show={show} onHide={handleHide}>
      {componentState.step === 'create' ? (
        <ConfigurationModal
          component={componentState.component}
          onSubmit={(name) => onSubmit(componentState.component?.get('id'), name)}
          onHide={handleHide}
        />
      ) : (
        <ListModal
          title="New Shared Codes"
          suffix="Shared Codes"
          allowedComponents={allowedTransformationComponents}
          onSelectComponent={handleSelectComponent}
          icon="code"
          filterComponents={hasGenericCodeBlocksUI}
        />
      )}
    </Modal>
  );
};
