import { useMutation, useMutationState, useQuery, useQueryClient } from '@tanstack/react-query';

import { isAbortError } from '@keboola/api-client';

import type {
  AgentMessage,
  AutomationDetail,
  AutomationMessage,
  UserMessage,
} from '@/api/routes/aiService';
import * as AiApi from '@/modules/ai/api';
import {
  constructMessage,
  getCurrentlyFinishedTasks,
  isAutomationProcessing,
  notifyIfTaskJobFinished,
} from '@/modules/automations/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import type HttpError from '@/utils/errors/HttpError';

const abortControllerRegistry = new Map<string, AbortController>();

export const QUERY_KEYS = {
  automation: (id?: string) => ['automation', id] as const,
  automationMessages: (id?: string) => ['automation', id, 'messages'] as const,
} as const;

export const MUTATION_KEYS = {
  createAutomation: () => ['createAutomation'] as const,
  sendMessage: (id?: string) => ['sendMessage', id] as const,
} as const;

export const useAutomation = (id?: string, isDraft?: boolean) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: QUERY_KEYS.automation(id),
    queryFn: () =>
      AiApi.getAutomation(id!)
        .tap((automation) => {
          const previousAutomation = queryClient.getQueryData<AutomationDetail>(
            QUERY_KEYS.automation(id),
          );
          const currentlyFinishedTasks = getCurrentlyFinishedTasks(previousAutomation, automation);

          notifyIfTaskJobFinished(currentlyFinishedTasks);

          // Get AI service faked info message after successfully running whole flow
          if (currentlyFinishedTasks?.length) {
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automationMessages(id) });
          }
        })
        .catch((error) => {
          if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) return null;
          throw error;
        }),
    enabled: !!id && !!isDraft,
    refetchInterval: (query) =>
      query.state.data && isAutomationProcessing(query.state.data) ? 3000 : false,
  });
};

export const useAutomationMessages = (id?: string) => {
  const pendingMutations = useMutationState({
    filters: {
      mutationKey: MUTATION_KEYS.sendMessage(id),
      status: 'pending',
    },
  });

  return useQuery({
    queryKey: QUERY_KEYS.automationMessages(id),
    queryFn: () => AiApi.getAutomationMessages(id!).then((response) => response.messages),
    enabled: !!id,
    refetchOnWindowFocus: () => !pendingMutations.length,
  });
};

const resolveWhenReady = async (automation: AutomationDetail): Promise<AutomationDetail> => {
  if (!automation.id) throw new Error('Automation creation failed');

  const response = await AiApi.getAutomation(automation.id);
  if (response.status === 'provisioningSandbox' || response.status === 'ready') return response;
  if (response.status === 'failed') throw new Error('Automation creation failed');

  await new Promise((resolve) => setTimeout(resolve, 500));
  return resolveWhenReady(response);
};

export const useCreateAutomation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: MUTATION_KEYS.createAutomation(),
    mutationFn: async (initialMessage?: string) => {
      const automation = await AiApi.createAutomation();
      const readyAutomation = await resolveWhenReady(automation);

      if (!readyAutomation.id) throw new Error('Automation creation failed');

      if (initialMessage) {
        await AiApi.sendMessage(readyAutomation.id, constructMessage({ text: initialMessage }));
      }

      return readyAutomation;
    },
    onSuccess: (automation) => {
      if (automation.id) {
        queryClient.setQueryData(QUERY_KEYS.automation(automation.id), automation);
      }
    },
  });
};

export const useDeleteAutomation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => AiApi.deleteAutomation(id),
    onSuccess: (_, id) => {
      queryClient.setQueryData(QUERY_KEYS.automation(id), null);
    },
  });
};

export const useSendMessage = (id?: string) => {
  const queryClient = useQueryClient();

  return useMutation<AgentMessage, HttpError, UserMessage>({
    mutationKey: MUTATION_KEYS.sendMessage(id),
    mutationFn: (messageObject: UserMessage) => {
      if (!id) return Promise.reject('No ID provided');

      if (abortControllerRegistry.has(id)) {
        abortControllerRegistry.get(id)?.abort();
      }

      const abortController = new AbortController();
      abortControllerRegistry.set(id, abortController);

      return AiApi.sendMessage(id, messageObject, abortController.signal).finally(() => {
        abortControllerRegistry.delete(id);
      });
    },
    onMutate: async (newMessage) => {
      if (!id) return;

      await queryClient.cancelQueries({ queryKey: QUERY_KEYS.automationMessages(id) });

      queryClient.setQueryData(
        QUERY_KEYS.automationMessages(id),
        (old: AutomationMessage[] = []) => [...old, newMessage],
      );
    },
    onSuccess: async (agentMessage, userMessage) => {
      if (!id) return;

      queryClient.setQueryData(
        QUERY_KEYS.automationMessages(id),
        (oldMessages: AutomationMessage[] = []) => [...oldMessages, agentMessage],
      );

      if (agentMessage.type === 'flowGenerated') {
        await InstalledComponentsActionCreators.loadInstalledComponentsForce({
          include: 'configuration',
        });
      }

      if (
        agentMessage.type === 'flowGenerated' ||
        userMessage.type === 'sourceTableSelectionResponse' ||
        ['configurationEditFinished', 'transformationEditFinished'].includes(agentMessage.type)
      ) {
        queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automation(id) });
      }

      if (['configurationEditStarted', 'transformationEditStarted'].includes(userMessage.type)) {
        await InstalledComponentsActionCreators.loadComponentConfigDataForce(
          userMessage.data.componentId,
          userMessage.data.configurationId,
        );
      }
    },
    onSettled: (data, error) => {
      if (isAbortError(error)) return;

      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.automationMessages(id) });
    },
    retry: (failureCount, error) => {
      if (error?.response?.statusCode.toString().startsWith('4')) return false;
      if (isAbortError(error)) return false;
      return failureCount < 1;
    },
  });
};

export const redirectToAutomation = (automation: AutomationDetail) => {
  RoutesStore.getRouter().transitionTo(flowsRouteNames.DETAIL, {
    config: automation.configurationId,
  });
};
