const ActionTypes = {
  TRANSFORMATION_BUCKET_CREATE_SUCCESS: 'LEGACY_TRANSFORMATION_BUCKET_CREATE_SUCCESS',
  TRANSFORMATION_CREATE_SUCCESS: 'LEGACY_TRANSFORMATION_CREATE_SUCCESS',
  TRANSFORMATION_BUCKET_DELETE_SUCCESS: 'LEGACY_TRANSFORMATION_BUCKET_DELETE_SUCCESS',

  TRANSFORMATION_DELETE: 'LEGACY_TRANSFORMATION_DELETE',
  TRANSFORMATION_DELETE_SUCCESS: 'LEGACY_TRANSFORMATION_DELETE_SUCCESS',
  TRANSFORMATION_DELETE_ERROR: 'LEGACY_TRANSFORMATION_DELETE_ERROR',

  TRANSFORMATION_EDIT_SAVE_START: 'LEGACY_TRANSFORMATION_EDIT_SAVE_START',
  TRANSFORMATION_EDIT_SAVE_SUCCESS: 'LEGACY_TRANSFORMATION_EDIT_SAVE_SUCCESS',
  TRANSFORMATION_EDIT_SAVE_ERROR: 'LEGACY_TRANSFORMATION_EDIT_SAVE_ERROR',

  TRANSFORMATION_UPDATE_EDITING_FIELD: 'LEGACY_TRANSFORMATION_UPDATE_EDITING_FIELD',
  TRANSFORMATION_CANCEL_EDITING_FIELD: 'LEGACY_TRANSFORMATION_CANCEL_EDITING_FIELD',

  TRANSFORMATION_UPDATE_PARSE_QUERIES: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_START: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_START',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS',
  TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR: 'LEGACY_TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR',
};

const backends = {
  SNOWFLAKE: 'snowflake',
  DOCKER: 'docker',
};

const transformationType = {
  PYTHON: 'python',
  R: 'r',
};

const transformationLabels = {
  snowflake: 'Snowflake',
  docker: 'Docker',
  python: 'Python',
  r: 'R',
};

const routeNames = {
  ROOT: 'legacy-transformation',
  BUCKET: 'legacyTransformationBucket',
  DETAIL: 'legacyTransformationDetail',
  BUCKET_VERSIONS: 'legacy-transformation-bucket-versions',
};

export { ActionTypes, backends, transformationType, transformationLabels, routeNames };
