import type { ComponentProps } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import type { Icon } from '@keboola/design';

import { BETA, EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import ComponentBadges from '@/react/common/ComponentBadges';
import ComponentIcon from '@/react/common/ComponentIcon';
import ModalActionButton from '@/react/common/ModalActionButton';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  allowedComponents: Map<string, any>;
  onSelectComponent: (component: Map<string, any>) => void;
  title: string;
  suffix: string;
  icon: ComponentProps<typeof Icon>['icon'];
  filterComponents: (component: Map<string, any>) => boolean;
  getActionButtonProps?: (
    component: Map<string, any>,
  ) => Partial<{ isDisabled: boolean; disabledReason: string }>;
};

export const ListModal = ({
  allowedComponents,
  onSelectComponent,
  title,
  suffix,
  icon,
  filterComponents,
  getActionButtonProps,
}: Props) => {
  const filteredComponents = allowedComponents
    .filter(filterComponents)
    .sortBy((item: Map<string, any>) => item.get('name'))
    .sortBy((item: Map<string, any>) => item.get('flags').includes(BETA));
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
        <ModalIcon icon={icon} color="green" bold />
      </Modal.Header>
      <Modal.Body>
        {filteredComponents
          .map((component: Map<string, any>) => {
            const additionalProps = getActionButtonProps?.(component) ?? {};
            return (
              <ModalActionButton
                key={component.get('id')}
                icon={<ComponentIcon component={component} size="48" />}
                title={
                  <>
                    {component.get('name')} {suffix}
                    <ComponentBadges
                      flags={component
                        .get('flags')
                        .filter((flag: string) => flag !== EXCLUDE_FROM_NEW_LIST)}
                      className="tw-ml-2"
                    />
                  </>
                }
                description={component.get('description')}
                onClick={() => onSelectComponent(component)}
                {...additionalProps}
              />
            );
          })
          .toArray()}
      </Modal.Body>
    </>
  );
};
