import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { FormGroup, HelpBlock, Label, TextInput } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { GENERIC_DOCKER_UI_ROWS } from '@/constants/componentFlags';
import { componentTypes } from '@/constants/componentTypes';
import callDockerAction from '@/modules/components/DockerActionsApi';
import { hasWriterSimpleTableInput } from '@/modules/components/helpers';
import InstalledComponentsActions from '@/modules/components/InstalledComponentsActionCreators';
import JSONSchemaEditor from '@/modules/components/react/components/JSONSchemaEditor';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import type {
  OnCreate,
  OnRowCreated,
} from '@/modules/configurations/react/components/CreateConfigurationRowButton';
import InputMappingSource from '@/modules/transformations/react/components/mapping/InputMappingSource';
import SimpleError from '@/utils/errors/SimpleError';
import fromJSOrdered from '@/utils/fromJSOrdered';
import nextTick from '@/utils/nextTick';
import ConfirmButtons from './ConfirmButtons';
import ModalIcon from './ModalIcon';

type PreparedRow = {
  name: string;
  description: string;
  configuration: Record<string, any>;
};

const isWriterWithoutRows = (component: Map<string, any>) => {
  return (
    component.get('type') === componentTypes.WRITER &&
    !component.get('flags').includes(GENERIC_DOCKER_UI_ROWS)
  );
};

const generateEditorResetKey = () => _.uniqueId('json_schema_editor_');

const CreateRowModal = (props: {
  component: Map<string, any>;
  configId: string;
  configData?: Map<string, any>;
  buckets?: Map<string, any>;
  tables?: Map<string, any>;
  entity?: string;
  show?: boolean;
  onCreate: OnCreate;
  onRowRedirect: OnRowCreated;
  onHide: () => void;
}) => {
  const [value, setValue] = useState('');
  const [schemaValues, setSchemaValues] = useState(Map());
  const [editorResetKey, setEditorResetKey] = useState(generateEditorResetKey);
  const [isSaving, setIsSaving] = useState(false);

  const addStorageTable = hasWriterSimpleTableInput(props.component);
  const showTableSelector = isWriterWithoutRows(props.component) || addStorageTable;

  const entity = props.entity || 'Table';

  const handleCreatedRows = (result: any[]) => {
    return Promise.resolve()
      .then(() => {
        if (result.length === 1) {
          return props.onRowRedirect(result[0].id);
        }

        return InstalledComponentsActions.loadComponentConfigDataForce(
          props.component.get('id'),
          props.configId,
        ).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: `All ${entity.toLowerCase()}s have been created.`,
          });
        });
      })
      .then(props.onHide);
  };

  const renderForm = () => {
    const createRowSchema = props.component.get('createConfigurationRowSchema', Map());

    if (!createRowSchema.isEmpty()) {
      const isValid = createRowSchema.get('required', List()).every((key: string) => {
        const value = schemaValues.get(key);

        return List.isList(value) ? !(value as List<any>).isEmpty() : !!value;
      });

      return (
        <form
          onSubmit={(e: FormEvent<HTMLFormElement>) => {
            e.preventDefault();

            const rowData = Map({ parameters: schemaValues });
            const configData = props.configData
              ? props.configData.mergeDeep(rowData).toJS()
              : rowData.toJS();

            setIsSaving(true);
            return callDockerAction(props.component.get('id'), 'prepareRows', { configData })
              .then((rows) => {
                if (!Array.isArray(rows)) {
                  throw new SimpleError('Create error', 'Error while preparing rows.');
                }

                return Promise.each(rows, (row: PreparedRow) => {
                  return props.onCreate(null, row.name, {
                    description: row.description,
                    forceData: fromJSOrdered(row.configuration),
                  });
                });
              })
              .then(handleCreatedRows)
              .finally(() => setIsSaving(false));
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{createRowSchema.get('title') || 'Add rows'}</Modal.Title>
            <ModalIcon.Plus />
          </Modal.Header>
          <Modal.Body>
            <JSONSchemaEditor
              key={editorResetKey}
              readOnly={isSaving}
              schema={createRowSchema}
              component={props.component}
              value={schemaValues}
              onChange={setSchemaValues}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              isSaving={isSaving}
              isDisabled={!isValid}
              saveLabel={isSaving ? 'Creating...' : 'Create'}
              saveButtonType="submit"
            />
          </Modal.Footer>
        </form>
      );
    }

    return (
      <form
        onSubmit={(event: FormEvent<HTMLFormElement>) => {
          event.preventDefault();

          let data = [[value, value]];

          if (showTableSelector && props.buckets && props.tables) {
            const tables = props.buckets.has(value)
              ? props.tables.filter((table) => table.getIn(['bucket', 'id']) === value).toList()
              : List([props.tables.get(value)]);

            data = tables.map((table) => [table.get('id'), table.get('displayName')]).toArray();
          }

          setIsSaving(true);
          return Promise.mapSeries(data, ([tableId, name]) => {
            if (!showTableSelector) {
              return props.onCreate(tableId, name, { addStorageTable });
            }

            return StorageActionCreators.loadTableDetail(tableId)
              .then(nextTick)
              .then(() => props.onCreate(tableId, name, { addStorageTable }));
          })
            .then(handleCreatedRows)
            .finally(() => setIsSaving(false));
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add {entity.toLowerCase()}</Modal.Title>
          <ModalIcon.Plus />
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="tw-mb-4">
            {showTableSelector ? (
              <>
                <Label htmlFor="select-table">Select a table or a bucket</Label>
                <InputMappingSource
                  id="select-table"
                  disableMultiSelect // TODO: Add support here?
                  value={Map({ source: value })}
                  tables={props.tables}
                  buckets={props.buckets}
                  onChange={setValue}
                />
                <HelpBlock>
                  When a bucket is selected, a configuration for each table from the bucket will be
                  created.
                </HelpBlock>
              </>
            ) : (
              <>
                <Label htmlFor="name">Name</Label>
                <TextInput
                  id="name"
                  variant="secondary"
                  autoFocus
                  value={value}
                  onChange={(value) => setValue(value)}
                />
              </>
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={isSaving}
            isDisabled={!value}
            saveButtonType="submit"
            saveLabel={isSaving ? 'Creating...' : 'Create'}
          />
        </Modal.Footer>
      </form>
    );
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        setValue('');
        setSchemaValues(Map());
        setEditorResetKey(generateEditorResetKey());
      }}
    >
      {renderForm()}
    </Modal>
  );
};

export default CreateRowModal;
