import CodeMirror from 'codemirror';

// default search panel location
CodeMirror.defineOption('enableSearch', false);

function initializeSearch(cm, query) {
  const searchNext = (query, event, forceFirstResult) => {
    const state = getSearchState(cm);

    CodeMirror.e_stop(event);

    if (!query) {
      cm.setSelection(state.posFrom ?? CodeMirror.Pos(0));
    }

    if (query != state.query) {
      changeSearchState(cm, state, query);
      state.posFrom = state.posTo = cm.getCursor();
    }

    findNext(cm, event.shiftKey, forceFirstResult);
  };

  persistentDialog(
    cm,
    getQueryDialog(cm),
    query,
    searchNext,
    (event, query) => {
      var keyName = CodeMirror.keyName(event);
      var extra = cm.getOption('extraKeys');
      var cmd = (extra && extra[keyName]) || CodeMirror.keyMap[cm.getOption('keyMap')][keyName];

      if (['findNext', 'findPrev', 'find'].includes(cmd)) {
        searchNext(query, event);
      }
    },
    (event, query) => searchNext(query, event, true),
    'editor-search',
  );
}

function searchOverlay(query, caseInsensitive) {
  if (typeof query == 'string')
    query = new RegExp(
      query.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&'),
      caseInsensitive ? 'gi' : 'g',
    );
  else if (!query.global) query = new RegExp(query.source, query.ignoreCase ? 'gi' : 'g');

  return {
    token: function (stream) {
      query.lastIndex = stream.pos;
      var match = query.exec(stream.string);
      if (match && match.index == stream.pos) {
        stream.pos += match[0].length || 1;
        return 'searching';
      }
      if (match) {
        stream.pos = match.index;
      } else {
        stream.skipToEnd();
      }
    },
  };
}

function SearchState() {
  this.posFrom = this.posTo = this.lastQuery = this.query = null;
  this.overlay = null;
}

function normalizeNewlines(query) {
  return query.replace(/\r\n/g, '\n').replace(/\r/g, '\n').replace(/\n/g, '\\n');
}

function getSearchState(cm) {
  return cm.state.search || (cm.state.search = new SearchState());
}

function queryCaseInsensitive(query) {
  return typeof query == 'string' && query == query.toLowerCase();
}

function getSearchCursor(cm, query, pos) {
  // Heuristic: if the query string is all lowercase, do a case insensitive search.
  return cm.getSearchCursor(query, pos, {
    caseFold: queryCaseInsensitive(query),
    multiline: true,
  });
}

function persistentDialog(cm, text, deflt, onEnter, onKeyDown, onInput, wrapperId) {
  cm.openDialog(text, onEnter, {
    value: deflt,
    onKeyDown,
    onInput,
    wrapperId,
  });
}

function parseString(string) {
  return string.replace(/\\([nrt\\])/g, function (match, ch) {
    if (ch == 'n') return '\n';
    if (ch == 'r') return '\r';
    if (ch == 't') return '\t';
    if (ch == '\\') return '\\';
    return match;
  });
}

function parseQuery(query) {
  var isRE = query.match(/^\/(.*)\/([a-z]*)$/);
  if (isRE) {
    try {
      query = new RegExp(isRE[1], isRE[2].indexOf('i') == -1 ? '' : 'i');
    } catch {
      // Not a regular expression after all, do a string search
      query = parseString(query);
    }
  } else {
    query = parseString(query);
  }
  if (typeof query == 'string' ? query == '' : query.test('')) query = /x^/;
  return query;
}

function changeSearchState(cm, state, queryText) {
  const searchInput = cm
    .getWrapperElement()
    .closest('.CodeMirror-search-dialog-root')
    .querySelector('.CodeMirror-dialog#editor-search input');

  if (searchInput) {
    searchInput.value = queryText;
    searchInput.focus();
  }

  state.queryText = queryText;
  state.query = parseQuery(queryText);
  cm.removeOverlay(state.overlay, queryCaseInsensitive(state.query));
  state.overlay = searchOverlay(state.query, queryCaseInsensitive(state.query));
  cm.addOverlay(state.overlay);

  if (cm.showMatchesOnScrollbar) {
    if (state.annotate) {
      state.annotate.clear();
      state.annotate = null;
    }
    state.annotate = cm.showMatchesOnScrollbar(state.query, queryCaseInsensitive(state.query));
  }
}

function doSearch(cm, rev = false) {
  var cmWrap = cm.getWrapperElement();
  var state = getSearchState(cm);
  var isSearchFocused = !!cmWrap
    .closest('.CodeMirror-search-dialog-root')
    ?.querySelector('.CodeMirror-dialog#editor-search:focus-within');
  var query =
    (isSearchFocused ? state.query || cm.getSelection() : cm.getSelection() || state.query) ||
    state.lastQuery;

  if (!query || (query instanceof RegExp && query.source == 'x^')) {
    query = '';
  } else {
    query = normalizeNewlines(query);
  }

  if (cmWrap.classList.contains('dialog-opened')) {
    if (state.query !== query) {
      changeSearchState(cm, state, query);
    }

    findNext(cm, rev);
  } else if (cm.openDialog) {
    initializeSearch(cm, query);
  }
}

function findNext(cm, rev, forceFirstResult) {
  cm.operation(function () {
    var state = getSearchState(cm);
    var cursor = getSearchCursor(
      cm,
      state.query,
      forceFirstResult ? 0 : rev ? state.posFrom : state.posTo,
    );

    if (!cursor.find(rev)) {
      cursor = getSearchCursor(
        cm,
        state.query,
        rev ? CodeMirror.Pos(cm.lastLine()) : CodeMirror.Pos(cm.firstLine(), 0),
      );

      if (!cursor.find(rev)) {
        cursor = null;
      }
    }

    if (cursor) {
      state.posFrom = cursor.from();
      state.posTo = cursor.to();

      cm.setSelection(cursor.from(), cursor.to());
      cm.scrollIntoView({ from: cursor.from(), to: cursor.to() }, 20);
    } else {
      cm.setSelection(state.posFrom ?? CodeMirror.Pos(0));
    }

    const controlsElement = cm.display.wrapper
      .closest('.CodeMirror-search-dialog-root')
      .querySelector('.CodeMirror-search-input-controls');

    if (!state.query || state.query.source == 'x^') {
      controlsElement.classList.add('display-none');
    } else {
      const allMatchesCount = state.annotate.matches.length;
      const currentMatchIndex =
        state.annotate.matches.findIndex(
          (match) => CodeMirror.cmpPos(match.from, state.posFrom) === 0,
        ) + 1;

      controlsElement.querySelector('.CodeMirror-search-count').textContent =
        `${currentMatchIndex}/${allMatchesCount}`;
      controlsElement.classList.remove('display-none');
    }
  });
}

function clearSearch(cm) {
  cm.operation(function () {
    var state = getSearchState(cm);
    state.lastQuery = state.query;
    state.query = state.queryText = null;

    cm.removeOverlay(state.overlay);

    if (state.annotate) {
      state.annotate.clear();
      state.annotate = null;
    }

    changeSearchState(cm, state, '');
    findNext(cm, false);
  });
}

function el(tag, attrs) {
  var element = tag
    ? ['svg', 'path', 'rect'].includes(tag)
      ? document.createElementNS('http://www.w3.org/2000/svg', tag)
      : document.createElement(tag)
    : document.createDocumentFragment();
  for (var key in attrs) {
    if (['onclick', 'onpaste'].includes(key)) {
      element.addEventListener(key.slice(2), attrs[key]);
    } else {
      element.setAttribute(key, attrs[key]);
    }
  }
  for (var i = 2; i < arguments.length; i++) {
    var child = arguments[i];
    element.appendChild(typeof child == 'string' ? document.createTextNode(child) : child);
  }
  return element;
}

function getQueryDialog(cm) {
  var input = el('input', {
    type: 'text',
    class: 'CodeMirror-search-field form-control',
    id: 'CodeMirror-search-field',
    placeholder: 'Search',
    onpaste: (event) => {
      event.preventDefault();

      changeSearchState(cm, getSearchState(cm), event.clipboardData?.getData('text') ?? '');
      cm.execCommand('find');
    },
  });

  var label = el(
    'label',
    { class: 'CodeMirror-search-label' },
    el(
      'svg',
      {
        width: '20',
        height: '20',
        viewBox: '0 0 20 20',
        fill: 'none',
      },
      el('path', {
        d: 'M14.9981 8.49905C14.9981 9.93321 14.5325 11.258 13.7483 12.3329L17.7039 16.2917C18.0945 16.6822 18.0945 17.3165 17.7039 17.7071C17.3134 18.0976 16.6791 18.0976 16.2885 17.7071L12.3329 13.7483C11.258 14.5357 9.93321 14.9981 8.49905 14.9981C4.90895 14.9981 2 12.0891 2 8.49905C2 4.90895 4.90895 2 8.49905 2C12.0891 2 14.9981 4.90895 14.9981 8.49905ZM8.49905 12.9984C10.9831 12.9984 12.9984 10.9831 12.9984 8.49905C12.9984 6.01504 10.9831 3.99971 8.49905 3.99971C6.01504 3.99971 3.99971 6.01504 3.99971 8.49905C3.99971 10.9831 6.01504 12.9984 8.49905 12.9984Z',
        fill: '#7C8594',
      }),
    ),
    input,
  );
  label.setAttribute('for', 'CodeMirror-search-field');

  var count = el('span', { class: 'CodeMirror-search-count' }, cm.phrase('0/0'));

  var divider = el(
    'svg',
    {
      class: 'CodeMirror-search-divider',
      width: '1',
      height: '16',
      viewBox: '0 0 1 16',
      fill: 'none',
    },
    el('rect', { width: '1', height: '16', fill: '#C5CBD6' }),
  );

  var buttons = el(
    '',
    null,
    el(
      'button',
      {
        class: 'CodeMirror-search-previous-button btn-link-inline btn-link-muted btn btn-link',
        onclick: () => {
          changeSearchState(cm, getSearchState(cm), input.value);
          cm.execCommand('findPrev');
        },
      },
      el(
        'svg',
        {
          width: '20',
          height: '20',
          viewBox: '0 0 20 20',
          fill: 'none',
        },
        el('path', {
          d: 'M9.39485 6.2563C9.72955 5.91457 10.2731 5.91457 10.6078 6.2563L15.749 11.5053C16.0837 11.847 16.0837 12.402 15.749 12.7437C15.4143 13.0854 14.8707 13.0854 14.536 12.7437L10 8.11258L5.46402 12.741C5.12931 13.0827 4.58574 13.0827 4.25103 12.741C3.91632 12.3992 3.91632 11.8443 4.25103 11.5025L9.39217 6.25356L9.39485 6.2563Z',
          fill: '#7C8594',
        }),
      ),
    ),
    el(
      'button',
      {
        class: 'CodeMirror-search-next-button btn-link-inline btn-link-muted btn btn-link',
        onclick: () => {
          changeSearchState(cm, getSearchState(cm), input.value);
          cm.execCommand('findNext');
        },
      },
      el(
        'svg',
        {
          width: '20',
          height: '20',
          viewBox: '0 0 20 20',
          fill: 'none',
        },
        el('path', {
          d: 'M9.39485 13.7437C9.72955 14.0854 10.2731 14.0854 10.6078 13.7437L15.749 8.49473C16.0837 8.153 16.0837 7.59803 15.749 7.2563C15.4143 6.91457 14.8707 6.91457 14.536 7.2563L10 11.8874L5.46402 7.25903C5.12931 6.9173 4.58574 6.9173 4.25103 7.25903C3.91632 7.60076 3.91632 8.15573 4.25103 8.49746L9.39217 13.7464L9.39485 13.7437Z',
          fill: '#7C8594',
        }),
      ),
    ),
    el(
      'button',
      {
        class: 'CodeMirror-search-close-button btn-link-inline btn-link-muted btn btn-link',
        onclick: () => clearSearch(cm),
      },
      el(
        'svg',
        {
          width: '20',
          height: '20',
          viewBox: '0 0 20 20',
          fill: 'none',
        },
        el('path', {
          d: 'M15.6449 6.04935C16.1134 5.58082 16.1134 4.81993 15.6449 4.3514C15.1763 3.88287 14.4154 3.88287 13.9469 4.3514L10 8.30205L6.04935 4.35515C5.58082 3.88662 4.81993 3.88662 4.3514 4.35515C3.88287 4.82368 3.88287 5.58457 4.3514 6.0531L8.30205 10L4.35515 13.9506C3.88662 14.4192 3.88662 15.1801 4.35515 15.6486C4.82368 16.1171 5.58457 16.1171 6.0531 15.6486L10 11.698L13.9506 15.6449C14.4192 16.1134 15.1801 16.1134 15.6486 15.6449C16.1171 15.1763 16.1171 14.4154 15.6486 13.9469L11.698 10L15.6449 6.04935Z',
          fill: '#7C8594',
        }),
      ),
    ),
  );

  return el(
    '',
    null,
    label,
    el(
      'div',
      {
        class: 'CodeMirror-search-input-controls display-none',
      },
      count,
      divider,
      buttons,
    ),
  );
}
CodeMirror.commands.find = function (cm) {
  doSearch(cm, false);
};
CodeMirror.commands.findNext = function (cm) {
  doSearch(cm, false);
};
CodeMirror.commands.findPrev = function (cm) {
  doSearch(cm, true);
};
CodeMirror.commands.clearSearch = clearSearch;
