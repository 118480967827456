/**
 * Splits the content into lines, merging those ending with a continuation character.
 */
export const splitIntoLines = (content: string) => {
  if (!content) return [];

  const { lines } = content.split(/\r?\n/).reduce<{ lines: string[]; current: string }>(
    (acc, line) => {
      const trimmedLine = line.trim();
      if (trimmedLine.endsWith('\\')) {
        acc.current += trimmedLine.slice(0, -1).trim() + ' ';
      } else {
        acc.lines.push(acc.current + trimmedLine);
        acc.current = '';
      }
      return acc;
    },
    { lines: [], current: '' },
  );

  return lines;
};

export const parseRequirementsLine = (line: string) => {
  if (
    // skip empty lines, comments and flags
    !line ||
    line.startsWith('#') ||
    line.startsWith('-') ||
    // skip url-based requirements (git+, http://, https://)
    line.startsWith('http') ||
    line.startsWith('git+') ||
    // Skip direct references with @ notation
    line.includes(' @ ')
  )
    return null;

  const lineWithoutComment = line.split('#')[0].trim();

  /**
   * The regular expression consists of:
   * - A required segment to capture the left-hand side (LHS) of the comparison (package name).
   * - Capturing groups to optionally extract a comparison operator (`>=`, `<=`, `!=`, `==`, `~=`, `>`, `<`).
   * - Optionally capturing the right-hand side (RHS) of the expression following the operator (package version)
   */
  const match = lineWithoutComment.match(/^(.+?)(?:\s*(>=|<=|!=|==|~=|>|<)\s*(.+))?$/);

  if (!match) return null;

  const [, pkg, operator, version] = match;

  return {
    name: pkg,
    operator: operator || null,
    version: version || null,
  };
};

export const parseRequirementsFile = (content: string) =>
  splitIntoLines(content)
    .map((line) => parseRequirementsLine(line))
    .filter((line) => line !== null);

export const mergePackagesWithRequirements = (
  packages: string[],
  requirements: ReturnType<typeof parseRequirementsFile>,
  strategy: 'replace' | 'update',
) => {
  if (strategy === 'replace') return requirements;

  const packagesRequirements = packages
    .map((pkg) => parseRequirementsLine(pkg))
    .filter((pkg) => pkg !== null);

  const resultMap = new Map(requirements.map((pkg) => [pkg.name, pkg]));

  for (const pkgReq of packagesRequirements) {
    if (!resultMap.has(pkgReq.name)) {
      resultMap.set(pkgReq.name, pkgReq);
    }
  }

  return resultMap.values().toArray();
};
