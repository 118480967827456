import { type ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import type { MutationState } from '@tanstack/react-query';
import { useMutationState } from '@tanstack/react-query';
import _ from 'underscore';

import { Icon, Tooltip } from '@keboola/design';

import type { AgentMessage, AutomationDetail, UserMessage } from '@/api/routes/aiService';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import useFlow from '@/modules/flows/hooks/useFlow';
import { RouterLink } from '@/react/common';
import PromptInput from '@/react/common/AI/PromptInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import HttpError from '@/utils/errors/HttpError';
import { injectButtonStyles } from '@/utils/external/productFruits';
import Message from './interactive-elements/Message';
import { PRODUCT_FRUITS_SURVEY_IDS } from './constants';
import { constructMessage, isInteractiveMessageRequest, isUserError } from './helpers';
import { MUTATION_KEYS, useAutomation, useAutomationMessages, useSendMessage } from './queries';

type Props = {
  children: ReactNode;
};

const Automation = (props: Props) => {
  const {
    componentId,
    configId,
    rowId,
    configuration,
    taskId,
    allComponents,
    allAdmins,
    sapiTokenId,
  } = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getConfigId();

      return {
        componentId,
        configId,
        rowId: RoutesStore.getRowId(),
        configuration: InstalledComponentsStore.getConfigData(componentId, configId).toJS(),
        taskId: RoutesStore.getRouterState().getIn(['location', 'query', 'taskId']),
        allComponents: ComponentsStore.getAll().toJS(),
        allAdmins: ApplicationStore.getAdmins(),
        sapiTokenId: ApplicationStore.getSapiToken()?.get('id'),
      };
    },
    [],
    [ApplicationStore, RoutesStore, DevBranchesStore, ComponentsStore],
  );
  const { automationId } = useFlow();
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const isInitialRenderRef = useRef(true);
  const [prompt, setPrompt] = useState('');
  const { data: automation, isLoading: isAutomationLoading } = useAutomation(automationId, true);
  const { data: messages, isLoading: isMessagesLoading } = useAutomationMessages(automation?.id);
  const { mutateAsync: sendMessageMutation } = useSendMessage(automation?.id);
  const isAutomationCreatedInThisSession = !!useMutationState<MutationState<AutomationDetail>>({
    filters: {
      mutationKey: MUTATION_KEYS.createAutomation(),
      predicate: (mutation) =>
        !!mutation.state.data && (mutation.state.data as AutomationDetail).id === automationId,
    },
  })?.length;
  const pendingMutations = useMutationState<MutationState<AgentMessage, HttpError, UserMessage>>({
    filters: {
      mutationKey: MUTATION_KEYS.sendMessage(automation?.id),
      status: 'pending',
    },
  });
  const [failedMessage, setFailedMessage] = useState<UserMessage | null>(null);
  const isLoading = isAutomationLoading || isMessagesLoading || !!pendingMutations.length;
  const admin = allAdmins?.get(automation?.createdByToken?.name || '');
  const readOnly = sapiTokenId !== automation?.createdByToken?.id;

  const submitMessage = useCallback(
    async (messageObject: Partial<UserMessage>) => {
      if (!automation?.id) return;

      const message = constructMessage(
        {
          ...messageObject,
          data: {
            ...(taskId
              ? { taskId, componentId, configurationId: configId, configuration, rowId }
              : { configuration }),
            ...messageObject.data,
          },
        },
        allComponents,
      );

      if (message.text?.trim() || !_.isEmpty(message.data)) {
        setPrompt((prevPrompt) => (prevPrompt === message.text ? '' : prevPrompt));

        try {
          setFailedMessage(null);
          await sendMessageMutation(message);
        } catch (error) {
          if (!(error instanceof HttpError) || isUserError(error)) throw error;
          setFailedMessage(message);
        }
      }
    },
    [
      taskId,
      componentId,
      configId,
      rowId,
      configuration,
      automation,
      allComponents,
      sendMessageMutation,
    ],
  );

  // Side effects to execute when entering/leaving the automation view
  useEffect(() => {
    document.body.classList.add('tw-overflow-hidden', '!tw-pb-0');

    if (window.productFruitsIsReady) {
      injectButtonStyles({ left: '15px' });
    } else {
      window.productFruitsReady = () => injectButtonStyles({ left: '15px' });
    }

    setTimeout(() => {
      const jiraWidgetElement = window.document.querySelector<HTMLElement>('#jsd-widget');

      if (!jiraWidgetElement) return;

      jiraWidgetElement.style.left = '26px';
    }, 1000);

    return () => {
      document.body.classList.remove('tw-overflow-hidden', '!tw-pb-0');
      injectButtonStyles({ left: 'unset' });
    };
  }, []);

  useEffect(() => {
    if (messages?.length) {
      messagesContainerRef.current?.lastElementChild?.scrollIntoView({
        behavior: isInitialRenderRef.current ? 'instant' : 'smooth',
      });

      if (isInitialRenderRef.current) {
        isInitialRenderRef.current = false;
      }
    }
  }, [messages?.length, isLoading, failedMessage]);

  return (
    <div className="tw-flex tw-h-full tw-overflow-hidden">
      <div className="tw-relative tw-grow tw-overflow-y-auto tw-overflow-x-hidden tw-bg-neutral-50 tw-px-6 tw-pb-7 [&>.container-topbar]:tw-sticky [&>.container-topbar]:tw-top-0 [&>.container-topbar]:tw-z-10 [&>.container-topbar]:-tw-mx-6 [&>.container-topbar]:tw-mb-7 [&>.container-topbar]:tw-bg-white [&>.container-topbar]:tw-px-6 [&>.container-topbar]:tw-shadow-md [&_.box-separator:first-child]:tw-mt-0 [&_.col-sm-9:has(+.col-sm-3:empty)]:tw-w-full [&_.container]:tw-w-full">
        {props.children}
      </div>
      <div className="tw-flex tw-w-[420px] tw-shrink-0 tw-flex-col tw-gap-3 tw-overflow-hidden tw-border-0 tw-border-l tw-border-solid tw-border-neutral-150 tw-bg-white tw-p-4 tw-pt-3">
        <div className="tw-flex tw-items-center tw-justify-between">
          <h4 className="tw-my-0 tw-uppercase">Keboola AI Agent</h4>
          <Tooltip tooltip="Close AI Flow Builder">
            <RouterLink
              className="btn btn-default"
              to={flowsRouteNames.ROOT}
              onClick={() => {
                if (!window.productFruitsIsReady || !isAutomationCreatedInThisSession) return;

                window.productFruits?.api.surveys.startSurvey(
                  PRODUCT_FRUITS_SURVEY_IDS.AUTOMATION_LEFT_UNPUBLISHED,
                );
              }}
              onlyActiveOnIndex
            >
              <Icon icon="xmark" />
            </RouterLink>
          </Tooltip>
        </div>
        <div
          ref={messagesContainerRef}
          className="-tw-mx-5 tw-flex tw-grow tw-flex-col tw-gap-2 tw-overflow-x-hidden tw-overflow-y-scroll tw-px-5 tw-break-anywhere"
        >
          {messages?.map((messageObject, index) => (
            <Message
              key={index}
              admin={admin}
              messageObject={messageObject}
              submitMessage={submitMessage}
              isDisabled={isLoading || index < messages.length - 1}
            />
          ))}
          {!!failedMessage && (
            <Message
              admin={admin}
              messageObject={failedMessage}
              submitMessage={submitMessage}
              isFailed
            />
          )}
          {isLoading && <Message />}
        </div>
        <Tooltip tooltip="You can only edit automations created by you." forceHide={!readOnly}>
          <PromptInput
            autoFocus
            multiLine
            placeholder="Write Message"
            prompt={prompt}
            onChange={setPrompt}
            onSubmit={() => submitMessage({ text: prompt })}
            isDisabled={!prompt.trim() || isLoading}
            isDisabledInput={readOnly || isInteractiveMessageRequest(messages?.at(-1))}
            className="tw-mx-auto tw-w-full tw-max-w-4xl"
            variant="narrow"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default Automation;
