import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { URLS } from '@keboola/constants';
import { Button, ButtonGroup, ButtonLink, Icon, Link } from '@keboola/design';

import dayjs from '@/date';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ModalIcon from '@/react/common/ModalIcon';
import contactSupport from '@/utils/contactSupport';

const days = (expires: string) => {
  const days = Math.max(0, Math.round(dayjs(expires).diff(dayjs(), 'days', true)));
  switch (days) {
    case 0:
      return 'less than a day';
    case 1:
      return '1 day';
    default:
      return `${days} days`;
  }
};

const Expiration = ({
  expires,
  showInStickyPanel,
}: {
  expires?: string;
  showInStickyPanel?: boolean;
}) => {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  if (
    !expires ||
    parseInt(days(expires), 10) > 30 ||
    (showInStickyPanel && parseInt(days(expires), 10) > 7)
  ) {
    return null;
  }

  if (showInStickyPanel) {
    return (
      <>
        <div className="top-sticky-bar warning bg-color-orange tw-gap-1">
          <Icon icon="triangle-exclamation" className="color-warning icon-addon-right" />
          Your trial will expire in {days(expires)}.
          <Button size="small" onClick={() => setShowUpgradeModal(true)}>
            Upgrade Account
          </Button>
        </div>
        <UpgradeModal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} />
      </>
    );
  }

  return (
    <>
      <CollapsibleAlert
        id="project-expiration"
        title={`Your project will expire in ${days(expires)}`}
        variant="warning"
        className="tw-mb-6"
      >
        <p>
          To keep your project active and continue enjoying the benefits of Keboola, please{' '}
          <Link onClick={() => setShowUpgradeModal(true)}>upgrade your account</Link>.
        </p>
      </CollapsibleAlert>
      <UpgradeModal show={showUpgradeModal} onHide={() => setShowUpgradeModal(false)} />
    </>
  );
};

const UpgradeModal = (props: { show: boolean; onHide: () => void }) => {
  return (
    <Modal bsSize="large" show={props.show} onHide={props.onHide}>
      <Modal.Header closeButton>
        <ModalIcon icon="arrow-up" color="green" bold />
        <Modal.Title>Upgrade Project</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className="mt-0 mb-0 font-medium line-height-20">End of trial</h4>
        <p className="text-muted">
          Your trial period is ending soon. If you don&apos;t upgrade to a paid plan, your project
          will expire and you&apos;ll lose any work that you&apos;ve completed so far. Upgrade now
          to keep your project active and continue using Keboola.
        </p>
        <h4 className="mtp-6 mb-0 font-medium line-height-20">Get in touch with us</h4>
        <p className="text-muted">
          Get in touch with our friendly sales team to learn more about our paid plans. Our team
          will help you understand Keboola&apos;s full range of features and benefits, then tailor a
          plan that meets your specific needs. Don&apos;t miss out on the power of Keboola - upgrade
          today!
        </p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonGroup variant="block">
          <Button variant="outline" onClick={() => contactSupport()}>
            Contact Support
          </Button>
          <ButtonLink variant="outline">
            <Link href={URLS.SALES_CONTACT_FORM}>Contact our Sales Team</Link>
          </ButtonLink>
        </ButtonGroup>
      </Modal.Footer>
    </Modal>
  );
};

export default Expiration;
