import { useState } from 'react';
import type { HTMLInputTypeAttribute, ReactNode } from 'react';

import { Button, ButtonGroup, cn, Icon } from '@keboola/design';

type Props = {
  defaultKey?: string;
  defaultValue?: string;
  onSubmit: (key: string, value: string) => void;
  isSaving: boolean;
  checkDuplicate: (newKey: string) => boolean;
  checkDisabled?: (newKey: string) => boolean;
  keyPlaceholder?: string;
  valuePlaceholder?: string;
  nameOnly?: boolean;
  onReset: () => void;
  nameAddition?: string;
  getAdditionalErrorMessage?: ((key: string) => ReactNode) | null;
  valueInputType?: HTMLInputTypeAttribute;
  additionalInput?: ReactNode | null;
  allowEmptyValue?: boolean;
};

const Form = ({
  defaultKey = '',
  defaultValue = '',
  onSubmit,
  isSaving,
  checkDuplicate,
  checkDisabled,
  keyPlaceholder,
  valuePlaceholder,
  nameOnly = false,
  onReset,
  nameAddition,
  getAdditionalErrorMessage = null,
  valueInputType,
  additionalInput,
  allowEmptyValue = false,
}: Props) => {
  const [key, setKey] = useState(defaultKey);
  const [value, setValue] = useState(defaultValue);

  const isSavingDisabled = () => {
    return (
      !key.trim() ||
      (!value && !allowEmptyValue && !nameOnly) ||
      (defaultKey === (nameAddition ? `${nameAddition}${key}` : key) && defaultValue === value) ||
      isSaving ||
      checkDuplicate(key) ||
      checkDisabled?.(key)
    );
  };

  const handleReset = () => {
    setKey(defaultKey);
    setValue(defaultValue);
    onReset();
  };

  const errorMessage = checkDuplicate(key)
    ? `The name ${key} is already used.`
    : getAdditionalErrorMessage
      ? getAdditionalErrorMessage(key)
      : null;

  const hasError = !isSaving && errorMessage;

  return (
    <div className="variable-editing">
      <form
        onSubmit={(event) => {
          event.preventDefault();
          if (isSavingDisabled()) {
            return;
          }
          onSubmit(key, value);
        }}
      >
        <div className="tw-flex tw-w-full tw-flex-col">
          <div className="tw-flex tw-flex-col tw-gap-4 md-legacy:tw-flex-row md-legacy:tw-items-center md-legacy:tw-justify-between">
            <div className="tw-flex tw-w-full tw-items-center">
              <Icon icon="code" fixedWidth className="text-muted icon-addon-right" />
              <div className="variable-name tw-w-full">
                <input
                  type="text"
                  value={key}
                  onChange={(event) => {
                    setKey(event.target.value.trim());
                  }}
                  placeholder={keyPlaceholder ?? 'Name'}
                  className={cn('tw-w-full', {
                    'has-error': hasError,
                  })}
                  autoFocus
                />
              </div>
              {!nameOnly && (
                <div className="variable-value tw-w-full">
                  <input
                    className="tw-w-full"
                    type={valueInputType ?? 'text'}
                    value={value}
                    onChange={(event) => {
                      setValue(event.target.value);
                    }}
                    placeholder={valuePlaceholder ?? 'Value'}
                  />
                </div>
              )}
              {additionalInput}
            </div>
            <ButtonGroup space="medium" className="tw-ml-5">
              <Button type="submit" size="small" disabled={isSavingDisabled() || isSaving}>
                Save
              </Button>
              <Button variant="outline" type="reset" size="small" onClick={handleReset}>
                Cancel
              </Button>
            </ButtonGroup>
          </div>

          {hasError && <div className="color-danger tw-mt-2 tw-text-xs">{errorMessage}</div>}
        </div>
      </form>
    </div>
  );
};

export default Form;
