import { Component } from 'react';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { ClipboardInline } from '@keboola/design';

import {
  canLoadSharedBuckets,
  canManageSharedBucket,
  isSharingAvailable,
} from '@/modules/admin/privileges';
import { MetadataKeys, ObjectTypes } from '@/modules/components/MetadataConstants';
import { routeNames } from '@/modules/data-catalog/constants';
import BackendRow from '@/modules/data-catalog/react/BackendRow';
import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { BackendEntityInfoTooltip } from '@/modules/storage/components/BackendEntityInfoTooltip';
import { backends } from '@/modules/storage/constants';
import { CreatedDate, FileSize, RouterLink } from '@/react/common';
import RowsCount from '@/react/common/RowsCount';
import ApplicationStore from '@/stores/ApplicationStore.js';
import TablesList from './TablesList/TablesList';
import NotAvailable from './NotAvailable';
import ShareBucketSimple from './ShareBucketSimple';
import StorageDescription from './StorageDescription';

type Props = {
  components: Map<string, any>;
  configurations: Map<string, any>;
  allowedTransformationComponents: Map<string, any>;
  sandboxes: Map<string, any>;
  sapiToken: Map<string, any>;
  buckets: Map<string, any>;
  bucket: Map<string, any>;
  tables: Map<string, any>;
  allTables: Map<string, any>;
  pendingActions: Map<string, any>;
  deletingTables: Map<string, any>;
  truncatingTables: Map<string, any>;
  exportingTables: Map<string, any>;
  createSnapshotsTables: Map<string, any>;
  isDevModeActive: boolean;
  hasPayAsYouGo: boolean;
  canExportTable: boolean;
  hasFlows: boolean;
  canWriteBucket: boolean;
  urlTemplates: Map<string, any>;
  availableUsersOptions: List<any>;
  availableProjectsOptions: List<any>;
  hasSnowflakePartnerConnectLimited: boolean;
  isBucketBrowser?: boolean;
};

class BucketOverview extends Component<Props> {
  render() {
    return (
      <>
        {this.renderOverview()}
        {this.renderDescription()}
        <TablesList
          sapiToken={this.props.sapiToken}
          sandboxes={this.props.sandboxes}
          allowedTransformationComponents={this.props.allowedTransformationComponents}
          bucket={this.props.bucket}
          tables={this.props.tables}
          allTables={this.props.allTables}
          components={this.props.components}
          configurations={this.props.configurations}
          urlTemplates={this.props.urlTemplates}
          canWriteBucket={this.props.canWriteBucket}
          deletingTables={this.props.deletingTables}
          truncatingTables={this.props.truncatingTables}
          exportingTables={this.props.exportingTables}
          createSnapshotsTables={this.props.createSnapshotsTables}
          canExportTable={this.props.canExportTable}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          hasFlows={this.props.hasFlows}
          hasSnowflakePartnerConnectLimited={this.props.hasSnowflakePartnerConnectLimited}
          isBucketBrowser={this.props.isBucketBrowser}
        />
      </>
    );
  }

  renderSchema() {
    const backend = this.props.bucket.get('backend');
    const path = this.props.bucket.get('path');
    const isSnowflake = backend === backends.SNOWFLAKE;
    const isBigQuery = backend === backends.BIGQUERY;

    if (!ApplicationStore.hasReadOnlyStorage() || (!isSnowflake && !isBigQuery) || !path) {
      return null;
    }

    return (
      <div className="tw-flex tw-justify-between tw-text-base tw-font-medium">
        <span>{isSnowflake ? 'Schema' : 'Dataset Name'}</span>
        <span className="tw-flex tw-flex-row tw-items-center tw-gap-4">
          <BackendEntityInfoTooltip isSnowflakeBackend={isSnowflake} />
          <ClipboardInline
            icon={null}
            text={path}
            label={path}
            buttonClassName="tw-break-words tw-text-right tw-text-base tw-text-neutral-400 hover:tw-text-neutral-800"
          />
        </span>
      </div>
    );
  }

  renderOverview() {
    const isExternal = this.props.bucket.get('hasExternalSchema');
    const isLinked = this.props.bucket.has('sourceBucket');

    return (
      <div className="box info-row">
        <div className="info-row-section horizontal more-space">
          <div className="first-line font-medium flex-container">
            <span>ID</span>
            <ClipboardInline
              icon={null}
              text={this.props.bucket.get('id')}
              label={this.props.bucket.get('id')}
              buttonClassName="tw-break-words tw-text-right tw-text-base tw-text-neutral-400 hover:tw-text-neutral-800"
            />
          </div>
          <div className="first-line font-medium flex-container">
            <span>Name</span>
            <ClipboardInline
              icon={null}
              text={this.props.bucket.get('displayName')}
              label={this.props.bucket.get('displayName')}
              buttonClassName="tw-break-words tw-text-right tw-text-base tw-text-neutral-400 hover:tw-text-neutral-800"
            />
          </div>
          <BackendRow backend={this.props.bucket.get('backend')} />
          {this.renderSourceBucket()}
          {this.renderSchema()}
        </div>
        <div className="info-row-section horizontal">
          <p className="first-line f-14 font-medium flex-container">
            <span>Stage</span>
            <span className="font-normal text-muted">
              {this.props.bucket.get('stage').toUpperCase()}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Created</span>
            <CreatedDate
              className="font-normal text-muted"
              createdTime={this.props.bucket.get('created')}
            />
          </p>
          {!isExternal && (
            <p className="first-line f-14 font-medium flex-container">
              <span>Last change</span>
              <CreatedDate
                className="font-normal text-muted"
                createdTime={
                  isLinked
                    ? this.props.bucket.getIn(['sourceBucket', 'lastChangeDate'])
                    : this.props.bucket.get('lastChangeDate')
                }
              />
            </p>
          )}
          <p className="first-line f-14 font-medium flex-container">
            <span>Row count</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <RowsCount
                  count={
                    isLinked
                      ? this.props.bucket.getIn(['sourceBucket', 'rowsCount'])
                      : this.props.bucket.get('rowsCount')
                  }
                />
              )}
            </span>
          </p>
          <p className="first-line f-14 font-medium flex-container">
            <span>Data size</span>
            <span className="font-normal text-muted">
              {isExternal ? (
                <NotAvailable entity="external buckets" />
              ) : (
                <FileSize
                  size={
                    isLinked
                      ? this.props.bucket.getIn(['sourceBucket', 'dataSizeBytes'])
                      : this.props.bucket.get('dataSizeBytes')
                  }
                />
              )}
            </span>
          </p>
        </div>
      </div>
    );
  }

  renderSourceBucket() {
    const source = this.props.bucket.get('sourceBucket');

    if (source) {
      return (
        <p className="first-line flex-container">
          <span className="font-medium">Source bucket</span>
          <span className="font-normal text-muted">
            {canLoadSharedBuckets(this.props.sapiToken) ? (
              <RouterLink
                forceProduction
                to={routeNames.SHARED_WITH_YOU_DETAIL}
                params={{
                  projectId: source.getIn(['project', 'id']),
                  bucketId: source.get('id'),
                }}
              >
                {source.get('displayName')}
              </RouterLink>
            ) : (
              source.get('displayName')
            )}
          </span>
        </p>
      );
    }

    if (!this.props.bucket.get('sharing') && !isSharingAvailable(this.props.sapiToken)) {
      return null;
    }

    if (
      this.props.bucket.get('hasExternalSchema') === true &&
      this.props.bucket.get('backend') !== backends.SNOWFLAKE
    ) {
      return null;
    }

    return (
      <p className="first-line flex-container">
        <span className="font-medium">Sharing</span>
        <span>{this.renderSharingInfo()}</span>
      </p>
    );
  }

  renderSharingInfo() {
    if (this.props.bucket.get('sharing')) {
      return (
        <RouterLink
          forceProduction
          to={routeNames.SHARED_FROM_THIS_PROJECT_DETAIL}
          params={{ bucketId: this.props.bucket.get('id') }}
          className="btn btn-link btn-link-inline partly-underlined"
        >
          <span className="underline">Enabled</span>{' '}
          <span className="text-muted">(show detail)</span>
        </RouterLink>
      );
    }

    return (
      <ShareBucketSimple
        bucket={this.props.bucket}
        canShare={canManageSharedBucket(this.props.sapiToken)}
        availableUsersOptions={this.props.availableUsersOptions}
        availableProjectsOptions={this.props.availableProjectsOptions}
      />
    );
  }

  renderDescription() {
    return (
      <StorageDescription
        objectType={ObjectTypes.BUCKET}
        objectId={this.props.bucket.get('id')}
        metadata={this.props.bucket.get('metadata', List())}
        metadataKey={MetadataKeys.DESCRIPTION}
        readOnly={!this.props.canWriteBucket}
        isDevBucket={isCreatedInDevBranch(this.props.bucket)}
      />
    );
  }
}

export default BucketOverview;
