import { useEffect } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import { List, type Map } from 'immutable';

import { Alert, CollapsiblePanel, Icon } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import keyCodes from '@/constants/keyCodes';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { loadTriggersForce } from '@/modules/event-trigger/actions';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import { routeNames } from '@/modules/flows/constants';
import { CreatedDate, Truncated } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';

export const TriggersStorageWarning = (props: { tables: string[] }) => {
  const hasNewQueue = ApplicationStore.hasNewQueue();

  const store = useStores(
    () => {
      const flowTriggers = EventTriggersStore.getForComponent(KEBOOLA_ORCHESTRATOR)
        .map((configTriggers: Map<string, any>) => configTriggers.toList())
        .reduce((acc: List<any>, triggers: List<any>) => acc.concat(triggers), List());

      const triggers = flowTriggers
        .filter((trigger: Map<string, any>) => {
          return trigger.get('tables').some((table: Map<string, any>) => {
            return props.tables.includes(table.get('tableId'));
          });
        })
        .map((trigger: Map<string, any>) => trigger.get('configurationId'))
        .toArray();

      const effectedFlows = InstalledComponentsStore.getComponentConfigurations(
        KEBOOLA_ORCHESTRATOR,
      )
        .filter((flow: Map<string, any>) => triggers.includes(flow.get('id')))
        .map((flow: Map<string, any>) => {
          return {
            id: flow.get('id'),
            name: flow.get('name'),
            created: flow.getIn(['currentVersion', 'created']),
          };
        })
        .toArray();

      return { effectedFlows };
    },
    [props.tables],
    [InstalledComponentsStore, EventTriggersStore],
  );

  useEffect(() => {
    if (!hasNewQueue) {
      return;
    }

    loadTriggersForce();
    InstalledComponentsActionCreators.loadComponentConfigsDataForce(KEBOOLA_ORCHESTRATOR);
  }, [hasNewQueue]);

  if (!hasNewQueue || !store.effectedFlows.length) {
    return null;
  }

  const handleRedirect = (flowId: string, newWindow: boolean) => {
    if (newWindow) {
      return windowOpen(createUrlWithBasename(routeNames.DETAIL, { config: flowId }));
    }

    return RoutesStore.getRouter().transitionTo(routeNames.DETAIL, { config: flowId });
  };

  const handleOnClick = (flowId: string, e: MouseEvent) => {
    if (hasSelections()) {
      return;
    }

    handleRedirect(flowId, shouldUseNewWindow(e));
  };

  const handleOnKeyDown = (flowId: string, e: KeyboardEvent) => {
    if (e.key !== keyCodes.ENTER) {
      return;
    }

    handleRedirect(flowId, shouldUseNewWindow(e));
  };

  return (
    <>
      <Alert variant="warning" className="tw-my-4">
        A table triggers used in the following configurations will stop working.
      </Alert>
      <CollapsiblePanel
        defaultExpanded
        header="Effected flows"
        className="tw-my-4"
        bodyClassName="tw-p-0"
      >
        <table className="table table-hover">
          <tbody>
            {store.effectedFlows.map((flow: { id: string; name: string; created: string }) => {
              return (
                <tr
                  key={flow.id}
                  tabIndex={0}
                  role="button"
                  onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                  onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                  onClick={(e) => handleOnClick(flow.id, e)}
                  onKeyDown={(e) => handleOnKeyDown(flow.id, e)}
                >
                  <td className="!tw-pl-4">
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <Icon icon="bars-staggered" className="tw-text-neutral-400" />
                      <ComponentConfigurationLink
                        hasFlows
                        componentId={KEBOOLA_ORCHESTRATOR}
                        configId={flow.id}
                      >
                        <Truncated text={flow.name} />
                      </ComponentConfigurationLink>
                    </div>
                  </td>
                  <td className="!tw-pr-4 tw-text-neutral-400">
                    Last changed <CreatedDate createdTime={flow.created} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CollapsiblePanel>
    </>
  );
};
