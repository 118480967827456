import { useState } from 'react';
import type { Map } from 'immutable';

import { ButtonGroup } from '@keboola/design';

import { KEBOOLA_FLOW } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import JobsActionCreators from '@/modules/queue/actions';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import DescriptionButton from '@/react/common/DescriptionButton';
import DescriptionModal from '@/react/common/DescriptionModal';
import JobTerminateButton from '@/react/common/JobTerminateButton';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import useFlow from './hooks/useFlow';
import { saveFlow } from './actions';
import { clearLocalState } from './helpers';

const DetailHeader = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isTerminating, setIsTerminating] = useState(false);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const currentAdmin = useStores(() => ApplicationStore.getCurrentAdmin(), [], [ApplicationStore]);
  const store = useFlow();

  if (!store.configId) return null;

  const renderRunButton = () => {
    return (
      <RunComponentButton
        componentId={KEBOOLA_FLOW}
        runParams={() => ({ config: store.configId })}
        disabled={store.isChanged || !store.shouldAllowRunFlow}
        disabledReason={
          store.isChanged
            ? 'You need to save or reset the changes before running the flow.'
            : 'The flow cannot run without any configured and enabled tasks.'
        }
        label="Run flow"
        buttonIcon="circle-play"
        buttonBsStyle="success"
      >
        You are about to run the flow.
      </RunComponentButton>
    );
  };

  const handleSave = () => {
    setIsSaving(true);
    return saveFlow(store.config, store.tasks, store.phases).finally(() => setIsSaving(false));
  };

  return (
    <>
      <ButtonGroup>
        <DescriptionButton
          onClick={() => setShowDescriptionModal(true)}
          isFilled={!!store.flow.get('description')}
          readOnly={store.readOnly}
          showsInModalOnly
        />
        <CatchUnsavedChanges
          key={store.activeTab}
          isDirty={store.isChanged}
          onSave={handleSave}
          onDirtyLeave={() => clearLocalState(store.configId)}
          text={
            <p>When saving, all job tasks without a selected configuration will be disabled.</p>
          }
        >
          <SaveButtons
            onReset={() => clearLocalState(store.configId)}
            isSaving={isSaving}
            onSave={handleSave}
            isChanged={store.isChanged}
            showModal={store.tasks.some((task: Map<string, any>) => {
              if (task.get('type') === 'job') {
                return !task.getIn(['task', 'configId']) && task.get('enabled', true);
              }

              if (task.get('type') === 'notification') {
                return !task.getIn(['task', 'channel', 'recipient']) && task.get('enabled', true);
              }

              return false;
            })}
            modalTitle="Save Flow"
            modalBody={
              <>
                <p>You are about to save the flow.</p>
                <p>All tasks without a selected configuration will be disabled.</p>
              </>
            }
          />
        </CatchUnsavedChanges>
        {renderRunButton()}
        <JobTerminateButton
          isFlow
          job={store.runningJob}
          sapiToken={store.sapiToken}
          isTerminating={
            isTerminating ||
            (store.runningJob &&
              store.jobsPendingActions.getIn(['terminating', store.runningJob.get('id')], false))
          }
          onTerminate={() => {
            setIsTerminating(true);
            return JobsActionCreators.terminateJob(store.runningJob.get('id'));
          }}
        />
        <OneTimeNotificationButton
          job={store.runningJob}
          notifications={store.notifications}
          admin={currentAdmin}
        />
      </ButtonGroup>
      <DescriptionModal
        entity="flow"
        readOnly={store.readOnly}
        show={showDescriptionModal}
        onHide={() => setShowDescriptionModal(false)}
        description={store.flow.get('description')}
        onSave={(description: string) => {
          return InstalledComponentsActionCreators.updateComponentConfiguration(
            KEBOOLA_FLOW,
            store.configId,
            { description },
            'Update description',
            'description',
          );
        }}
        componentId={KEBOOLA_FLOW}
        configId={store.configId}
      />
    </>
  );
};

export default DetailHeader;
