import { OperationalError } from 'bluebird';

import { isAbortError, isApiError } from '@keboola/api-client';

import { REQUEST_ABORTED_ERROR, REQUEST_TIMEOUT_ERROR } from '@/constants/superagent';
import Error from './Error';
import HttpError from './HttpError';
import SimpleError from './SimpleError';
import SyncActionError from './SyncActionError';

export const HTTP_STATUS_CODE_BAD_REQUEST = 400;
const HTTP_STATUS_CODE_UNAUTHORIZED = 401;
const HTTP_STATUS_CODE_FORBIDDEN = 403;
export const HTTP_STATUS_CODE_NOT_FOUND = 404;
const HTTP_STATUS_CODE_CONFLICT = 409;
export const HTTP_STATUS_CODE_UNPROCESSABLE_ENTITY = 422;
export const HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR = 500;
export const HTTP_STATUS_CODE_BAD_GATEWAY = 502;
export const HTTP_STATUS_CODE_SERVICE_UNAVAILABLE = 503;
export const HTTP_STATUS_CODE_GATEWAY_TIMEOUT = 504;

const MUTED_ERROR_IDS = [
  'abortError',
  'connectTimeout',
  'connectTimeoutRequestAborted',
  'couldNotConnect',
];

const shouldBeMuted = (error) => {
  if (MUTED_ERROR_IDS.includes(error.id)) {
    return true;
  }

  if (!error.isUserError && !document.hasFocus()) {
    return true;
  }

  return false;
};

const createPresentationalError = (exception) => {
  let error = exception;

  if (isApiError(error)) {
    return createFromXhrErrorFromApi(error);
  }
  if (isAbortError(error)) {
    error = new Error('AbortError', error.message);
    error.isUserError = true;
    error.id = 'abortError';
    return error;
  }
  if (error instanceof HttpError) {
    return createFromXhrError(error);
  }
  if (error.code === REQUEST_TIMEOUT_ERROR) {
    error = new Error('Request timeout', error.message);
    error.isUserError = true;
    error.id = 'connectTimeout';
    return error;
  }
  if (error.code === REQUEST_ABORTED_ERROR) {
    error = new Error('Request timed out', 'Please try again later.');
    error.isUserError = true;
    error.id = 'connectTimeoutRequestAborted';
    return error;
  }
  if (error.crossDomain) {
    error = new Error(
      'There was an error performing your request',
      'Please try again later. This may also occur when an adblock plugin is used; make sure you do not use any for this site. We have no ads here anyway.',
    );
    error.id = 'couldNotConnect';
    error.isUserError = true;
    return error;
  }
  if (error instanceof OperationalError || error.isOperational) {
    // error from bluebird
    return new Error('Connection error', error.message);
  }
  if (error instanceof SimpleError) {
    error = new Error(error.title, error.message);
    error.isUserError = true;
    return error;
  }
  if (error instanceof SyncActionError) {
    error = new Error('Action failed', error.message);
    error.isUserError = true;
    return error;
  }
  if (typeof error === 'string') {
    error = new Error('Error', error);
    error.isUserError = true;
    return error;
  }

  return new Error('Application error', 'Please reload the browser.');
};

const createFromXhrErrorFromApi = (apiError) => {
  let title = 'Error';
  let text = '';
  let isMaintenance = false;

  text =
    apiError.response.data?.message ||
    apiError.response.data?.error ||
    apiError.response.data?.errorMessage;

  if (
    apiError.response.status === HTTP_STATUS_CODE_SERVICE_UNAVAILABLE &&
    apiError.response.data?.status === 'maintenance'
  ) {
    isMaintenance = true;
    title = 'Maintenance';
    text =
      apiError.response.data?.reason ||
      'The service is currently down for scheduled maintenance. We expect to be back soon. Thanks for your patience.';
  }

  if (!text) {
    text = 'Application error. Please reload the browser.';
  }

  const error = new Error(
    typeof title === 'string' ? title : 'Error',
    typeof text === 'string' ? text : '',
    apiError,
    apiError.response.data?.exceptionId,
  );

  if (
    [
      HTTP_STATUS_CODE_BAD_REQUEST,
      HTTP_STATUS_CODE_UNAUTHORIZED,
      HTTP_STATUS_CODE_FORBIDDEN,
      HTTP_STATUS_CODE_NOT_FOUND,
      HTTP_STATUS_CODE_CONFLICT,
      HTTP_STATUS_CODE_INTERNAL_SERVER_ERROR,
    ].includes(apiError.response.status)
  ) {
    error.isUserError = true;
  } else if (isMaintenance) {
    error.isMaintenanceError = true;
  }

  return error;
};

var createFromXhrError = (httpError) => {
  let title = 'Error';
  let text = '';
  let isMaintenance = false;

  if (httpError.response.body?.error) {
    text = httpError.response.body.error?.message || httpError.response.body.error;
  }

  if (
    httpError.response.body?.message ||
    httpError.response.error?.message ||
    httpError.response.body?.errorMessage
  ) {
    title = text;
    text =
      httpError.response.body?.message ||
      httpError.response.error?.message ||
      httpError.response.body?.errorMessage;
  }

  if (
    httpError.response.status === HTTP_STATUS_CODE_SERVICE_UNAVAILABLE &&
    httpError.response.body?.status === 'maintenance'
  ) {
    isMaintenance = true;
    title = 'Maintenance';
    text =
      httpError.response.body.reason ||
      'The service is currently down for scheduled maintenance. We expect to be back soon. Thanks for your patience.';
  }

  if (!text) {
    text = 'Application error. Please reload the browser.';
  }

  const error = new Error(
    typeof title === 'string' ? title : 'Error',
    typeof text === 'string' ? text : '',
    httpError,
    httpError.response.body?.exceptionId,
  );

  if (
    [
      HTTP_STATUS_CODE_BAD_REQUEST,
      HTTP_STATUS_CODE_UNAUTHORIZED,
      HTTP_STATUS_CODE_FORBIDDEN,
      HTTP_STATUS_CODE_NOT_FOUND,
      HTTP_STATUS_CODE_CONFLICT,
    ].includes(httpError.response.status)
  ) {
    error.isUserError = true;
  } else if (isMaintenance) {
    error.isMaintenanceError = true;
  }

  return error;
};

export { shouldBeMuted, createPresentationalError };
