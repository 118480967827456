import type { ButtonHTMLAttributes, ComponentProps } from 'react';

import { cn } from '../../utils';
import { Icon } from '../Icon';

import { buttonStyles, iconStyles } from './styles';
import type { CommonButtonProps } from './types';

type IconButtonProps = {
  icon: ComponentProps<typeof Icon>['icon'];
  isFilledIcon?: boolean;
  isLoading?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = ({
  onClick,
  size = 'medium',
  variant = 'primary',
  type = 'button',
  disabled = false,
  icon,
  isFilledIcon = false,
  isLoading = false,
  className,
}: CommonButtonProps & IconButtonProps & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const styles = buttonStyles({ variant, size, icon, disabled, isFilledIcon, className });
  const iconClasses = iconStyles({ size });
  return (
    <button
      data-button-group-id="icon-button"
      type={type}
      onClick={onClick}
      className={cn(styles, {
        'tw-h-fit tw-w-fit': variant === 'inline',
      })}
      disabled={disabled}
      data-disabled={disabled}
    >
      <Icon spin={isLoading} icon={isLoading ? 'spinner' : icon} className={iconClasses} />
    </button>
  );
};
