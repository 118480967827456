import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import {
  Alert,
  Button,
  FormGroup,
  HelpBlock,
  Icon,
  Label,
  Link,
  TextInput,
  Tooltip,
} from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { FEATURE_SAML_ROLES } from '@/constants/features';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import dayjs from '@/date';
import { canManageUsers } from '@/modules/admin/privileges';
import { inviteUser } from '@/modules/settings/actions';
import StackFeaturesStore from '@/modules/stack-features/Store';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ExpirationDatePicker from '@/react/common/ExpirationDatePicker';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const DATA_FORMAT = 'YYYY-MM-DDTHH:mm';

const prepareExpired = (expires: string) => {
  return expires ? dayjs(expires).format(DATA_FORMAT) : null;
};

const prepareExpirationSeconds = (expiryDate: string) => {
  return Math.ceil(dayjs(expiryDate).diff(dayjs(), 'seconds', true));
};

const InviteUserHeaderButton = () => {
  const store = useStores(
    () => {
      return {
        sapiToken: ApplicationStore.getSapiToken(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        hasSamlRoles: StackFeaturesStore.hasStackFeature(FEATURE_SAML_ROLES),
      };
    },
    [],
    [ApplicationStore, StackFeaturesStore],
  );

  const [state, setState] = useState<{
    error: null | { message: string };
    tempData: Map<string, any>;
    showModal: boolean;
    isLoading: boolean;
  }>({
    error: null,
    tempData: Map(),
    showModal: false,
    isLoading: false,
  });

  if (store.hasSamlRoles) {
    return null;
  }

  const canInvite = canManageUsers(store.sapiToken);

  const isDisabled =
    !/\S+@\S+\.\S+/.test(state.tempData.get('email')) || !state.tempData.get('role');

  const getOptions = () => {
    if (ApplicationStore.hasProtectedDefaultBranch()) {
      return [
        { value: ADMIN_ROLES.PRODUCTION_MANAGER, label: 'Production manager' },
        { value: ADMIN_ROLES.DEVELOPER, label: 'Developer' },
        { value: ADMIN_ROLES.REVIEWER, label: 'Reviewer' },
      ];
    }

    return [
      { value: ADMIN_ROLES.SHARE, label: 'Share' },
      { value: ADMIN_ROLES.ADMIN, label: 'Admin' },
      { value: ADMIN_ROLES.GUEST, label: 'Guest' },
      { value: ADMIN_ROLES.READ_ONLY, label: 'Read Only' },
    ].filter((option) => {
      if (option.value === ADMIN_ROLES.READ_ONLY && !store.hasNewQueue) {
        return false;
      }

      if (
        option.value === ADMIN_ROLES.SHARE &&
        !store.sapiToken.getIn(['admin', 'isOrganizationMember'])
      ) {
        return false;
      }

      return true;
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setState((prevState) => ({ ...prevState, isLoading: true }));
    inviteUser(
      state.tempData.get('email'),
      state.tempData.get('role'),
      state.tempData.get('expiryDate')
        ? prepareExpirationSeconds(state.tempData.get('expiryDate'))
        : void 0,
    )
      .then(() => setState((prevState) => ({ ...prevState, showModal: false })))
      .catch((error) => {
        if (!error?.message) {
          throw error;
        }

        return setState((prevState) => ({ ...prevState, error }));
      })
      .finally(() => setState((prevState) => ({ ...prevState, isLoading: false })));
  };

  return (
    <>
      <Tooltip
        tooltip="You don't have permission to invite a user. Please request the necessary permissions from your organization's administrator."
        placement="top"
        type="explanatory"
        forceHide={canInvite}
      >
        <Button
          className={NEW_ENTITY_BUTTON}
          onClick={() => canInvite && setState((prevState) => ({ ...prevState, showModal: true }))}
          disabled={!canInvite}
        >
          <Icon icon="plus" />
          Invite User
        </Button>
      </Tooltip>
      <Modal
        show={state.showModal}
        onHide={() => setState((prevState) => ({ ...prevState, showModal: false }))}
        onEnter={() => {
          setState((prevState) => ({
            ...prevState,
            tempData: Map(),
            error: null,
          }));
        }}
      >
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title>Invite User to Project</Modal.Title>
            <ModalIcon icon="plus" color="green" bold />
          </Modal.Header>
          <Modal.Body className="!tw-mb-4 tw-flex tw-flex-col tw-gap-4">
            <p className="tw-mb-0">
              Enter the user&apos;s email address. An invitation will be sent to their inbox.
            </p>
            {state.error && <Alert variant="error">{state.error.message}</Alert>}
            <FormGroup>
              <Label htmlFor="email">Email</Label>
              <TextInput
                id="email"
                autoFocus
                variant="secondary"
                value={state.tempData.get('email', '')}
                onChange={(value) =>
                  setState((prevState) => ({
                    ...prevState,
                    tempData: prevState.tempData.set('email', value),
                    error: null,
                  }))
                }
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="role">Role</Label>
              <Select
                id="role"
                clearable={false}
                placeholder="Select role"
                value={state.tempData.get('role')}
                onChange={(role: string) =>
                  setState((prevState) => ({
                    ...prevState,
                    tempData: prevState.tempData.set('role', role),
                  }))
                }
                options={getOptions()}
              />
              <HelpBlock>
                Read more about available roles in the{' '}
                <Link href={`${URLS.USER_DOCUMENTATION}/management/project/users/#user-roles`}>
                  documentation
                </Link>
                .
              </HelpBlock>
            </FormGroup>
            <ExpirationDatePicker
              type="datetime-local"
              dataFormat={DATA_FORMAT}
              minDate={dayjs().add(1, 'hour').format(DATA_FORMAT)}
              isActive={state.tempData.get('expires')}
              expiryDate={prepareExpired(state.tempData.get('expiryDate'))}
              onChange={(expiryDate) => {
                setState((prevState) => ({
                  ...prevState,
                  tempData: prevState.tempData
                    .set('expires', expiryDate !== null)
                    .set('expiryDate', expiryDate),
                }));
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={state.isLoading ? 'Inviting a user...' : 'Invite user'}
              isSaving={state.isLoading}
              isDisabled={isDisabled}
            />
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default InviteUserHeaderButton;
