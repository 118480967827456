import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Button, ButtonGroup, Icon, Link } from '@keboola/design';

import string from '@/utils/string';
import CreateProjectModal from './CreateProjectModal';
import ResetProjectModal from './ResetProjectModal';

const Credentials = createReactClass({
  propTypes: {
    isLoadingModel: PropTypes.bool.isRequired,
    config: PropTypes.shape({
      pid: PropTypes.string.isRequired,
      login: PropTypes.string.isRequired,
      password: PropTypes.string.isRequired,
      backendUrl: PropTypes.string,
    }),
    disabled: PropTypes.bool.isRequired,
    onHandleCreate: PropTypes.func.isRequired,
    onHandleResetProject: PropTypes.func.isRequired,
    buckets: PropTypes.object.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      showCreateProjectModal: false,
      showResetProjectModal: false,
    };
  },

  closeCreateProjectModal() {
    if (!this.props.disabled) {
      this.setState({ showCreateProjectModal: false });
    }
  },

  handleCreateProject(newProject) {
    this.props.onHandleCreate(newProject).then(this.closeCreateProjectModal);
  },

  closeResetProjectModal() {
    if (!this.props.disabled) {
      this.setState({ showResetProjectModal: false });
    }
  },

  handleResetProject() {
    return this.props.onHandleResetProject().then(this.closeResetProjectModal);
  },

  render() {
    return (
      <>
        {!this.props.readOnly && (
          <ResetProjectModal
            show={this.state.showResetProjectModal}
            pid={this.props.config.pid}
            onHide={() => this.setState({ showResetProjectModal: false })}
            onConfirm={this.handleResetProject}
            disabled={this.props.disabled}
          />
        )}
        <CreateProjectModal
          isLoadingModel={this.props.isLoadingModel}
          show={this.state.showCreateProjectModal}
          onHide={this.closeCreateProjectModal}
          onCreate={this.handleCreateProject}
          disabled={this.props.disabled}
          config={this.props.config}
          buckets={this.props.buckets}
        />
        {this.renderProvisioning()}
      </>
    );
  },

  renderProvisioning() {
    if (!this.props.config.pid) {
      return this.renderNoCredentials();
    }

    return (
      <div>
        <div className="text-right mb-2">
          {this.renderGoToProjectLink()}
          {!this.props.readOnly && (
            <ButtonGroup space="small">
              <Button onClick={() => this.setState({ showCreateProjectModal: true })}>
                Change
              </Button>
              <Button
                type="button"
                variant="danger"
                onClick={() => this.setState({ showResetProjectModal: true })}
              >
                Disconnect
              </Button>
            </ButtonGroup>
          )}
        </div>
        <div>
          <div>GoodData Project Id: {this.props.config.pid}</div>
          <div>GoodData Project Login: {this.props.config.login}</div>
        </div>
      </div>
    );
  },

  getGoodDataUrl() {
    return this.props.config.backendUrl || 'https://secure.gooddata.com';
  },

  renderGoToProjectLink() {
    const gdUrl = this.getGoodDataUrl();
    const targetUrl = `${string.rtrim(gdUrl, '/')}/dashboards/#/project/${this.props.config.pid}`;
    return (
      <Link href={targetUrl} className="btn btn-link">
        <Icon icon="chart-bar" fixedWidth />
        Go To Project
      </Link>
    );
  },

  renderNoCredentials() {
    return (
      <div className="p-1 tw-flex tw-flex-col tw-items-center">
        <p>No project set up yet.</p>
        {!this.props.readOnly && (
          <Button
            size="small"
            disabled={this.props.disabled}
            onClick={() => this.setState({ showCreateProjectModal: true })}
          >
            Set Up GoodData Project
          </Button>
        )}
      </div>
    );
  },
});

export default Credentials;
