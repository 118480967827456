import { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@keboola/design';

import { RowActionMenuItem } from '@/react/common';
import Loader from '@/react/common/Loader';

class DeleteConfigurationRowButton extends Component {
  render() {
    if (this.props.mode === 'link') {
      return (
        <button onClick={this.props.onClick} className="btn btn-link tw-w-full">
          {this.props.isPending ? <Loader /> : <Icon icon="trash" fixedWidth />}Delete Row
        </button>
      );
    }

    return (
      <RowActionMenuItem
        onSelect={this.props.onClick}
        disabled={this.props.isPending}
        onKeyDown={this.props.onKeyDown}
      >
        {this.props.isPending ? <Loader /> : <Icon icon="trash" fixedWidth />}Delete row
      </RowActionMenuItem>
    );
  }
}

DeleteConfigurationRowButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isPending: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['menuitem', 'link']).isRequired,
  onKeyDown: PropTypes.func,
};

export default DeleteConfigurationRowButton;
