import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/components-directory/constants';
import { RouterLink } from '@/react/common';

const configurationRowDeleted = (componentId, configurationId, row) => {
  return createReactClass({
    render() {
      return (
        <>
          Row{' '}
          <RouterLink
            to={routeNames.GENERIC_CONFIG_VERSIONS}
            params={{ component: componentId, config: configurationId }}
          >
            {row.get('name') || 'Untitled'}
          </RouterLink>{' '}
          has been deleted.
        </>
      );
    },
  });
};

export default configurationRowDeleted;
