import { useQuery } from '@tanstack/react-query';

import { useApiClient } from '@keboola/api-client';
import { Button } from '@keboola/design';

import { storageApiKey } from '@/constants/queryKeys';
import { openSsoSanboxInNewWindow } from './utils';

type Props = {
  id: string;
  branchId: string;
  className?: string;
  disabled?: boolean;
};

export const SSOLoginButton = ({ id, branchId, className, disabled }: Props) => {
  const api = useApiClient();

  const workspaceSaml2LoginQuery = useQuery({
    queryKey: storageApiKey.workspaceSaml2Login({ workspaceId: id, branchId }),
    queryFn: ({ signal, queryKey }) =>
      api.storage.branches.getWorkspaceSaml2Login(queryKey[1].path, signal),
    retry: false,
    enabled: false,
  });

  const ssoLogin = async () => {
    const { data, isSuccess } = await workspaceSaml2LoginQuery.refetch();
    if (!isSuccess) return;
    openSsoSanboxInNewWindow(data);
  };

  return (
    <Button
      icon="circle-play"
      disabled={disabled}
      isLoading={workspaceSaml2LoginQuery.isFetching}
      onClick={ssoLogin}
      className={className}
    >
      Connect
    </Button>
  );
};
