import { Component } from 'react';
import PropTypes from 'prop-types';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { Map } from 'immutable';

import { HelpBlock, Icon } from '@keboola/design';

import { TDE_EXPORTER } from '@/constants/componentIds';
import GooglePicker from '@/modules/google-utils/react/GooglePicker';
import ViewTemplates from '@/modules/google-utils/react/PickerViewTemplates';
import AuthorizationRow from '@/modules/oauth-v2/react/AuthorizationRow';
import Loader from '@/react/common/Loader';

class OauthV2WriterRow extends Component {
  render() {
    return (
      <>
        <div className="form-horizontal">
          <FormGroup>
            <ControlLabel className="col-sm-2">Destination</ControlLabel>
            <FormControl.Static className="col-sm-10" componentClass="div">
              {this.props.renderComponent()}
            </FormControl.Static>
          </FormGroup>
          <FormGroup>
            <ControlLabel className="col-sm-2">Authorization</ControlLabel>
            <FormControl.Static className="col-sm-10" componentClass="div">
              {this.renderAuthorizedInfo()}
            </FormControl.Static>
          </FormGroup>
          {this.props.isAuthorized && this.props.componentId === 'keboola.wr-google-drive' && (
            <FormGroup>
              <ControlLabel className="col-sm-2">Folder</ControlLabel>
              <FormControl.Static className="col-sm-10" componentClass="div">
                {this.renderGdriveFolderPicker()}
                {this.renderGdriveFolder()}
                {!this.props.componentData.getIn(['folder', 'id']) && (
                  <HelpBlock className="tw-mt-1">
                    If no folder is selected all files will be uploaded to root.
                  </HelpBlock>
                )}
              </FormControl.Static>
            </FormGroup>
          )}
          {this.props.isAuthorized && (
            <FormGroup>
              <ControlLabel className="col-sm-2">Instant Upload</ControlLabel>
              <FormControl.Static className="col-sm-10" componentClass="div">
                {this.props.renderEnableUpload(this.props.oauthCredentials.get('authorizedFor'))}
              </FormControl.Static>
            </FormGroup>
          )}
        </div>
      </>
    );
  }

  saveGdriveFolder(id, title) {
    const path = ['parameters', this.props.componentId];
    let data = this.props.componentData.set('folder', Map());
    if (id) {
      data = this.props.componentData.setIn(['folder', 'id'], id).setIn(['folder', 'title'], title);
    }
    this.props.updateLocalState([this.props.componentId, 'saving'], true);
    return this.props
      .setConfigDataFn(path, data)
      .then(() => this.props.updateLocalState([this.props.componentId, 'saving'], false));
  }

  renderGdriveFolder() {
    if (this.props.localState.getIn([this.props.componentId, 'saving'], false)) {
      return <Loader className="icon-addon-left" />;
    }
    if (!this.props.componentData.getIn(['folder', 'id'])) {
      return null;
    }
    return (
      <button onClick={() => this.saveGdriveFolder(null, null)} className="btn btn-default ml-1">
        Reset
      </button>
    );
  }

  renderGdriveFolderPicker() {
    const folderId = this.props.componentData.getIn(['folder', 'id']);
    const folderName = this.props.componentData.getIn(['folder', 'title']);

    return (
      <GooglePicker
        dialogTitle="Select a folder"
        buttonLabel={
          <>
            <Icon icon="folder" className="icon-addon-right" />
            {folderId ? `/${folderName}` : 'Select a folder'}
          </>
        }
        onPickedFn={(data) => {
          let foldersData = data.filter((file) => file.type === 'folder');
          const folderId = foldersData[0].id;
          const folderName = foldersData[0].name;
          foldersData[0].title = folderName;
          return this.saveGdriveFolder(folderId, folderName);
        }}
        buttonProps={{ bsStyle: 'success' }}
        views={[
          ViewTemplates.rootFolder,
          ViewTemplates.sharedFolders,
          ViewTemplates.starredFolders,
          ViewTemplates.recent,
        ]}
      />
    );
  }

  renderAuthorizedInfo() {
    return (
      <AuthorizationRow
        withoutBox
        disableExternalAuthorization
        wrapperComponentId={TDE_EXPORTER}
        readOnly={this.props.readOnly}
        configId={this.props.configId}
        componentId={this.props.componentId}
        credentials={this.props.oauthCredentials}
        onResetCredentials={this.props.resetUploadTask}
        admins={this.props.admins}
      />
    );
  }
}

OauthV2WriterRow.propTypes = {
  configId: PropTypes.string.isRequired,
  componentData: PropTypes.object.isRequired,
  oauthCredentials: PropTypes.object,
  componentId: PropTypes.string.isRequired,
  localState: PropTypes.object.isRequired,
  readOnly: PropTypes.bool.isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  updateLocalState: PropTypes.func,
  setConfigDataFn: PropTypes.func,
  renderComponent: PropTypes.func,
  renderEnableUpload: PropTypes.func,
  isAuthorized: PropTypes.bool,
  resetUploadTask: PropTypes.func,
};

export default OauthV2WriterRow;
