import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import useStores from '@/react/hooks/useStores';
import { getAutomationFromFlowMetadata } from './helpers';

type Props = {
  configIds: string[];
};

export const FlowDeletingWarning = ({ configIds }: Props) => {
  const { hasDraftAutomation } = useStores(
    () => {
      const componentsMetadata = InstalledComponentsStore.getAllMetadata() as Map<string, any>;
      const hasDraftAutomation = configIds.some(
        (configId) => getAutomationFromFlowMetadata(configId, componentsMetadata).isDraft,
      );

      return { hasDraftAutomation };
    },
    [configIds],
    [InstalledComponentsStore],
  );

  if (!hasDraftAutomation) return null;

  return (
    <Alert
      title="Deleting a draft AI flow will also remove all configurations within. This action cannot be undone."
      variant="warning"
      className="tw-mb-4"
    />
  );
};
