export const KeboolaHttpHeader = {
  STORAGE_API_TOKEN: 'X-StorageApi-Token',
  MANAGEMENT_API_TOKEN: 'X-Kbc-Manageapitoken',
};

export const ErrorMessage = {
  UNINITIALIZED_CLIENT:
    'Uninitialized client: To initialize the Keboola client, use either the .init() method or the <ApiClientProvider/> component.',
  REACT_MISSING_CONTEXT_PROVIDER: 'useApiClient must be used within <ApiClientProvider />',
  REACT_MISSING_DEV_CONTEXT_PROVIDER: 'useApiClient must be used within <DevApiClientProvider />',
};
