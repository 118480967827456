import { type FormEvent, type SyntheticEvent, useState } from 'react';
import { Map } from 'immutable';

import { Alert, Button, Icon } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import { routeNames as projectSettingsRouteNames } from '@/modules/settings/constants';
import { createToken, sendToken, updateLocalState } from '@/modules/tokens/actionCreators';
import TokenEditor from '@/modules/tokens/react/components/tokenEditor/TokenEditor';
import { TokenString } from '@/modules/tokens/react/components/TokenString';
import { SendTokenModal } from '@/modules/tokens/react/modals/SendTokenModal';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import { RouterLink } from '@/react/common';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

export const NewToken = () => {
  const store = useStores(
    () => {
      const localState = TokensStore.localState() as Map<string, any>;

      return {
        localState,
        allBuckets: BucketsStore.getAll(),
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        token: localState.get('newToken', Map()),
        isSendingToken: TokensStore.isSendingToken,
        sapiToken: ApplicationStore.getSapiToken(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
      };
    },
    [],
    [ApplicationStore, TokensStore, BucketsStore, ComponentsStore],
  );

  const [createdToken, setCreatedToken] = useState(Map<string, any>());
  const [sendModal, setSendModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const isValid = !!(store.token.get('description') && store.token.get('expiresIn') !== 0);

  const handleSave = (e: FormEvent<HTMLFormElement> | SyntheticEvent) => {
    e.preventDefault();

    let token = store.token;

    if (!token.get('expiresIn')) {
      token = token.delete('expiresIn');
    }

    if (store.sapiToken.getIn(['admin', 'role']) === ADMIN_ROLES.PRODUCTION_MANAGER) {
      token = token.set('canCreateJobs', true);
    }

    setIsSaving(true);
    return createToken(token.toJS())
      .then((createdToken) => {
        setCreatedToken(createdToken);
        updateLocalState(store.localState.delete('newToken'));
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  const handleSendToken = (params: { email?: string; message?: string }) => {
    return sendToken(createdToken.get('id'), params).then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              Token <b>{createdToken.get('description')}</b> sent to <b>{params.email}</b>.
            </>
          );
        },
      });

      setSendModal(false);
    });
  };

  const renderTokenCreated = () => {
    const creatorLink = (
      <RouterLink
        to={projectSettingsRouteNames.TOKEN_DETAIL}
        params={{ tokenId: createdToken.get('id') }}
      >
        {createdToken.get('description')}
      </RouterLink>
    );

    const isSending = store.isSendingToken(createdToken.get('id'));

    return (
      <div className="text-center">
        <SendTokenModal
          token={createdToken}
          show={sendModal}
          onSendFn={handleSendToken}
          onHideFn={() => setSendModal(false)}
          isSending={!!isSending}
        />
        <Alert variant="success" className="tw-mb-5">
          Token {creatorLink} has been created.
        </Alert>
        <TokenString
          token={createdToken}
          sendTokenComponent={
            <Button
              variant="outline"
              onClick={() => setSendModal(true)}
              className="mt-1 tw-justify-self-center"
            >
              <Icon icon="share" />
              Send token via email
            </Button>
          }
        />
      </div>
    );
  };

  return (
    <div className="box">
      <div className="box-content">
        {createdToken.count() ? (
          renderTokenCreated()
        ) : (
          <div className="form form-horizontal">
            <div className="save-buttons">
              <ConfirmButtons
                saveButtonType="submit"
                isSaving={isSaving}
                isDisabled={!isValid}
                onSave={handleSave}
                saveLabel="Create"
                showCancel={false}
              />
            </div>
            <TokenEditor
              isEditing={false}
              disabled={isSaving}
              token={store.token}
              sapiToken={store.sapiToken}
              allBuckets={store.allBuckets}
              allComponents={store.allComponents}
              updateToken={(newToken) => {
                updateLocalState(store.localState.set('newToken', newToken));
              }}
              hasNewQueue={store.hasNewQueue}
            />
          </div>
        )}
      </div>
    </div>
  );
};
