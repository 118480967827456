import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Button } from '@keboola/design';

import BetaTitle from '@/modules/lineage/BetaTitle';
import { AiIcon } from '@/react/common/AI/svgGradient';
import NewAutomation from './NewAutomation';
import NewAutomationProgressModal from './NewAutomationProgressModal';

const NewAutomationModal = () => {
  const [showModal, setShowModal] = useState(false);
  const [isPending, setIsPending] = useState(false);

  return (
    <>
      <Button
        variant="secondary"
        onClick={() => setShowModal(true)}
        className="tw-bg-gradient-to-br tw-from-secondary-600 tw-to-purple-600 !tw-text-white"
      >
        <AiIcon className="[&_path]:tw-fill-white" />
        AI Flow Builder
      </Button>
      <Modal
        show={showModal && !isPending}
        onHide={() => setShowModal(false)}
        dialogClassName="tw-mt-[30vh] tw-max-w-[90%] tw-w-[800px] tw-rounded-2xl"
      >
        <Modal.Header closeButton>
          <span className="modal-icon circle-icon bigger-icon tw-bg-gradient-to-br tw-from-secondary-600 tw-to-purple-600 [&_path]:tw-fill-white">
            <AiIcon />
          </span>
          <Modal.Title>
            <BetaTitle>Use AI to Design Your Flow</BetaTitle>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewAutomation
            subtitle="Let our AI create a pipeline for you. Provide a prompt with your requirements, and we'll generate it for you."
            onSubmit={() => setIsPending(true)}
            showHint
          />
        </Modal.Body>
      </Modal>
      <NewAutomationProgressModal show={isPending} animation={false} />
    </>
  );
};

export default NewAutomationModal;
