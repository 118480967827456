import { memo, useEffect } from 'react';
import { autoUpdate, flip, FloatingPortal, useFloating } from '@floating-ui/react';
import type { List, Map } from 'immutable';

import { Button, Icon, Tooltip } from '@keboola/design';

import {
  canHaveIfCondition,
  extractPhaseId,
  prepareNewNotificationTask,
  prepareNewVariableTask,
  preparePhasesForNewTask,
} from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import { updateLocalStateValue } from '@/modules/flows-v2/helpers';
import { ActionButton } from './ActionButton';

type Props = {
  id: string;
  className?: string;
};

export const AddButton = memo(({ id, className }: Props) => {
  const { refs, elements, floatingStyles, update } = useFloating({ middleware: [flip()] });

  const [
    context,
    nodes,
    nodeDragging,
    selectedAddButton,
    setSelectedPhaseId,
    setSelectedTaskId,
    setSelectedAddButton,
    setSelectedConditionId,
  ] = useBuilderStore((state) => [
    state.context,
    state.nodes,
    state.nodeDragging,
    state.selectedAddButton,
    state.setSelectedPhaseId,
    state.setSelectedTaskId,
    state.setSelectedAddButton,
    state.setSelectedConditionId,
  ]);

  useEffect(() => {
    if (!elements.reference || !elements.floating) {
      return;
    }

    const cleanup = autoUpdate(elements.reference, elements.floating, update, {
      animationFrame: true,
    });

    return () => {
      cleanup();
    };
  }, [elements, update]);

  const isOpen = selectedAddButton === id;
  const isAlone = id.startsWith('after:') || id.startsWith('before:');
  const allowIfCondition = canHaveIfCondition(id, context.phases);
  const size = isAlone ? 'medium' : 'small';

  const updateTaskAndPhases = (phases: List<any>, newTask: Map<string, any>) => {
    updateLocalStateValue(context.config.get('id'), ['phases'], phases);
    updateLocalStateValue(context.config.get('id'), ['tasks'], context.tasks.push(newTask));
    setSelectedTaskId(newTask.get('id'));
  };

  const addNotification = () => {
    const phaseData = preparePhasesForNewTask(id, context.phases, nodes, 'Notification');

    if (phaseData) {
      updateTaskAndPhases(phaseData.phases, prepareNewNotificationTask(phaseData.phase.get('id')));
    }
  };

  const addVariable = () => {
    const phaseData = preparePhasesForNewTask(id, context.phases, nodes, 'Variable');

    if (phaseData) {
      updateTaskAndPhases(phaseData.phases, prepareNewVariableTask(phaseData.phase.get('id')));
    }
  };

  if (nodeDragging && !isAlone) {
    return null;
  }

  return (
    <>
      <Tooltip placement="top" tooltip={isOpen ? 'Close' : 'Add Task'} triggerClassName={className}>
        <span ref={refs.setReference}>
          <ActionButton
            size={size}
            icon={isOpen ? 'xmark' : 'plus'}
            onClick={() => setSelectedAddButton(isOpen ? null : id)}
          />
        </span>
      </Tooltip>
      {isOpen && (
        <FloatingPortal root={document.querySelector('.react-flow__renderer') as HTMLElement}>
          <div ref={refs.setFloating} style={floatingStyles} className="nodrag nopan tw-z-[1000]">
            <div className="tw-flex tw-w-64 tw-flex-col tw-gap-1 tw-rounded-lg tw-bg-white tw-p-3 tw-shadow-md">
              <Button
                variant="outline"
                onClick={() => setSelectedPhaseId(id)}
                className="tw-justify-start tw-border-none tw-px-3 tw-text-base tw-font-medium tw-normal-case"
              >
                <Icon
                  fixedWidth
                  icon={['fad', 'layer-plus']}
                  className="tw-mr-1 !tw-text-secondary-500"
                />
                Add Component
              </Button>
              {allowIfCondition && (
                <Button
                  variant="outline"
                  onClick={() => setSelectedConditionId(extractPhaseId(id))}
                  className="tw-justify-start tw-border-none tw-px-3 tw-text-base tw-font-medium tw-normal-case"
                >
                  <Icon
                    fixedWidth
                    rotation={90}
                    icon={['fad', 'split']}
                    className="tw-mr-1 !tw-text-secondary-500"
                  />
                  Add Conditions
                </Button>
              )}
              <Button
                variant="outline"
                onClick={() => addNotification()}
                className="tw-justify-start tw-border-none tw-px-3 tw-text-base tw-font-medium tw-normal-case"
              >
                <Icon
                  fixedWidth
                  icon={['fad', 'bell-ring']}
                  className="tw-mr-1 !tw-text-secondary-500"
                />
                Add Notification
              </Button>
              <Button
                variant="outline"
                onClick={() => addVariable()}
                className="tw-justify-start tw-border-none tw-px-3 tw-text-base tw-font-medium tw-normal-case"
              >
                <Icon
                  fixedWidth
                  icon={['fad', 'brackets-curly']}
                  className="tw-mr-1 !tw-text-secondary-500"
                />
                Set Variables
              </Button>
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
});
