import type {
  ApiResponse,
  BodyParam,
  CreateFetchClientOptions,
  FetchClientOptions,
  PathParam,
  QueryParam,
} from './createFetchClient';
import { createFetchClient } from './createFetchClient';

type FetchParams<Path = PathParam, Query = QueryParam, Body = BodyParam> = {
  path?: Path;
  query?: Query;
  body?: Body;
};

type FetchMethod = <Data, Path = PathParam, Query = QueryParam, Body = BodyParam>(
  url: string,
  params: FetchParams<Path, Query, Body>,
  options?: FetchClientOptions,
) => Promise<ApiResponse<Data>>;

type FetchClient = {
  get: FetchMethod;
  post: FetchMethod;
  put: FetchMethod;
  patch: FetchMethod;
  delete: FetchMethod;
};

export const createGenericFetchClient = (defaultOptions: CreateFetchClientOptions) =>
  createFetchClient(defaultOptions) as FetchClient;
