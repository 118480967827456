import { forwardRef } from 'react';

import { cn } from '@keboola/design';

import { TASK_HEIGHT, TASK_WIDTH } from '@/modules/flows-v2/builder/helpers';

type Props = {
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  className?: string;
};

export const TaskBox = forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        'nodrag tw-group/task tw-bg-white tw-p-2 tw-flex tw-items-center tw-gap-3 tw-rounded-lg tw-shadow tw-relative tw-border-solid tw-border-2 tw-border-transparent',
        { clickable: !props.disabled, 'tw-border-secondary-500': props.active },
        props.className,
      )}
      style={{ width: `${TASK_WIDTH}px`, height: `${TASK_HEIGHT}px` }}
      onClick={() => {
        if (props.disabled) {
          return;
        }

        props.onClick?.();
      }}
    >
      {props.children}
    </div>
  );
});
