import type { ParsedQs } from 'qs';
import { stringify } from 'qs';

export type Query = ParsedQs | URLSearchParams | null;

export const getQueryString = (query?: Query): string => {
  if (!query) {
    return '';
  }

  if (query instanceof URLSearchParams) {
    const queryString = query.toString();
    return queryString ? `?${queryString}` : '';
  }

  const queryString = stringify(query);
  return queryString ? `?${queryString}` : '';
};
