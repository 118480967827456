import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { routeNames } from '@/modules/transformations/Constants';
import { RouterLink } from '@/react/common';

const copyTransformationNotification = (bucketId, transformation) => {
  return createReactClass({
    propTypes: {
      onClick: PropTypes.func.isRequired,
    },
    render() {
      return (
        <span>
          Transformation{' '}
          <RouterLink
            to={routeNames.DETAIL}
            params={{
              config: bucketId,
              row: transformation.get('id'),
            }}
            onClick={this.props.onClick}
          >
            {transformation.get('name')}
          </RouterLink>{' '}
          was created
        </span>
      );
    },
  });
};

export default copyTransformationNotification;
