import type { Map } from 'immutable';

import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { RouterLink } from '@/react/common';
import { ExternalProjectBucketLink } from './ExternalProjectBucketLink';

type Props = {
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  alias: Map<string, any>;
};

const ProjectAliasLink = (props: Props) => {
  const projectId = parseInt(props.alias.getIn(['project', 'id']), 10);

  if (props.sapiToken.getIn(['owner', 'id']) === projectId) {
    return (
      <RouterLink
        to={routeNames.TABLE}
        params={{
          bucketId: props.alias.get('id'),
          bucketTab: bucketTabs.OVERVIEW,
          tableName: props.alias.getIn(['table', 'name']),
          tableTab: tableTabs.OVERVIEW,
        }}
      >
        {props.alias.get('id')} / {props.alias.getIn(['table', 'displayName'])}
      </RouterLink>
    );
  }

  if (props.sapiToken.getIn(['admin', 'isOrganizationMember'])) {
    return (
      <ExternalProjectBucketLink
        bucketId={props.alias.get('id')}
        projectId={props.alias.getIn(['project', 'id'])}
        projectName={props.alias.getIn(['project', 'name'])}
        urlTemplates={props.urlTemplates}
      />
    );
  }

  return (
    <span>
      {props.alias.getIn(['project', 'name'])} / {props.alias.get('id')} /{' '}
      {props.alias.getIn(['table', 'displayName'])}
    </span>
  );
};

export default ProjectAliasLink;
