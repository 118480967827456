import type { ReactNode, SyntheticEvent } from 'react';

import { Tooltip } from '@keboola/design';

import { READ_ONLY_TOOLTIP_MESSAGE } from './constants';

type Props = {
  children: ReactNode;
  readOnly: boolean;
  className?: string;
  onClick?: (e: SyntheticEvent<Element>) => void;
};

const ReadOnlyTooltip = ({ children, readOnly, className, onClick }: Props) => {
  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip={READ_ONLY_TOOLTIP_MESSAGE}
      forceHide={!readOnly}
      triggerClassName={className}
      triggerOnClick={onClick}
    >
      {children}
    </Tooltip>
  );
};

export default ReadOnlyTooltip;
