import type { ReactNode } from 'react';
import Textarea from 'react-textarea-autosize';
import type { Map } from 'immutable';

import { FormGroup, IconButton, Label, Switch, Tooltip } from '@keboola/design';

import type { EditTaskProperties, VisualNotificationTask } from '@/modules/flows-v2/builder/types';
import Gravatar from '@/react/common/Gravatar';
import MarkedText from '@/react/common/MarkedText';
import OptionalFormLabel from '@/react/common/OptionalFormLabel';
import Select from '@/react/common/Select';
import ApplicationStore from '@/stores/ApplicationStore';
import isEmailValid from '@/utils/isEmailValid';
import string from '@/utils/string';

export const NotificationDetail = ({
  readOnly,
  task,
  onChange,
}: {
  readOnly: boolean;
  task: VisualNotificationTask;
  onChange: (properties: EditTaskProperties) => void;
}) => {
  const allAdmins = ApplicationStore.getAdmins();

  return (
    <>
      <FormGroup>
        <Label htmlFor="notification-message">
          Notification Message <OptionalFormLabel />
        </Label>
        <Textarea
          id="notification-message"
          minRows={4}
          value={task.message ?? ''}
          onChange={(e) => onChange([{ keysPath: ['task', 'message'], value: e.target.value }])}
          disabled={readOnly}
          className="form-control"
        />
      </FormGroup>

      <div className="tw-flex tw-items-center tw-gap-2">
        <Switch
          checked={task.enabled}
          disabled={readOnly}
          onChange={() => onChange([{ keysPath: ['enabled'], value: !task.enabled }])}
          id="notification-enabled"
        />
        <Label htmlFor="notification-enabled">Notification Enabled</Label>
      </div>

      <div className="tw-mt-6">
        <h4 className="tw-mb-1 tw-mt-0 tw-text-sm tw-font-medium">Destination</h4>
        <p className="tw-text-xs tw-text-neutral-400">
          Select where the notification will be sent.
        </p>
        <Select
          allowCreate
          noResultsText="Enter valid email address"
          placeholder="Choose from your colleagues or enter an email address"
          promptTextCreator={(label) =>
            `Add ${string.pluralize(label?.split(',').length, 'email')}`
          }
          disabled={!!task.recipient || readOnly}
          options={allAdmins
            .map((admin: Map<string, any>) => admin.get('email'))
            .map((email: string) => email.toLowerCase())
            .toSet()
            .map((email: string) => {
              const admin = allAdmins.get(email);

              return {
                value: email,
                label: (inputString: string) => (
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Gravatar user={admin} />
                    <div className="tw-flex tw-flex-col">
                      <strong className="tw-text-sm tw-font-medium">
                        <MarkedText
                          source={admin ? admin.get('name', '') : 'Guest'}
                          mark={inputString}
                        />
                      </strong>
                      <small className="tw-text-neutral-400">
                        <MarkedText source={email} mark={inputString} />
                      </small>
                    </div>
                  </div>
                ),
                name: admin ? admin.get('name', '') : 'Guest',
              };
            })
            .sortBy((option: { name: string; value: string; label: ReactNode }) =>
              option.name.toLowerCase(),
            )
            .toArray()}
          onChange={(email: string) =>
            onChange([{ keysPath: ['task', 'channel', 'recipient'], value: email }])
          }
          isValidNewOption={isEmailValid}
        />
      </div>
      {task.recipient && (
        <div className="tw-flex tw-items-center tw-justify-between tw-px-0.5 tw-py-2.5">
          <div className="tw-flex tw-items-center tw-gap-2">
            <Gravatar user={allAdmins.get(task.recipient)} />
            <div className="tw-flex tw-flex-col">
              <strong className="tw-text-sm tw-font-medium">
                {allAdmins.getIn([task.recipient, 'name'], '') || 'Guest'}
              </strong>
              <span className="tw-text-xs tw-text-neutral-400">{task.recipient}</span>
            </div>
          </div>
          {!readOnly && (
            <Tooltip placement="top" tooltip="Remove notification">
              <IconButton
                variant="invisible"
                icon="circle-xmark"
                onClick={() =>
                  onChange([{ keysPath: ['task', 'channel', 'recipient'], value: '' }])
                }
                disabled={readOnly}
              />
            </Tooltip>
          )}
        </div>
      )}
    </>
  );
};
