import { Button, cn, Icon } from '@keboola/design';

import { hasInvalidConditions } from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { VisualPhase } from '@/modules/flows-v2/builder/types';

export const PhaseStatus = ({ id, phaseData }: { id: string; phaseData: VisualPhase }) => {
  const [context, selectedConditionId, setSelectedConditionId] = useBuilderStore((state) => [
    state.context,
    state.selectedConditionId,
    state.setSelectedConditionId,
  ]);

  const isActive = selectedConditionId === id;
  const hasConditions = phaseData.next.length > 1;
  const hasEndFlow = phaseData.next.some((goto) => goto === null);
  const showConditionLabel = hasConditions || hasEndFlow || isActive;

  if (!showConditionLabel) {
    return null;
  }

  return (
    <div className="tw-absolute -tw-bottom-5">
      <Button
        size="small"
        variant="outline"
        onClick={() => setSelectedConditionId(isActive ? null : id)}
        className={cn('tw-rounded-full tw-border-2 tw-py-1 tw-text-xs tw-normal-case', {
          'tw-border-warning-500': hasInvalidConditions(id, context.phases, context.tasks),
        })}
      >
        <Icon rotation={90} icon={['fad', 'split']} className="tw-mr-1 !tw-text-secondary-500" />
        {!hasConditions && hasEndFlow
          ? 'End Flow'
          : hasConditions && hasEndFlow
            ? 'Conditions with End Flow'
            : 'Conditions'}
      </Button>
    </div>
  );
};
