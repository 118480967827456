import { useState } from 'react';

import { KEBOOLA_LEGACY_TRANSFORMATION as componentId } from '@/constants/componentIds';
import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import TransformationsStore from '@/modules/legacy-transformation/stores/TransformationsStore';
import DescriptionBox from '@/react/common/DescriptionBox/DescriptionBox';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

const Description = (props: { bucketId: string; transformationId?: string }) => {
  const state = useStores(
    () => {
      const transformation = TransformationsStore.getTransformation(
        props.bucketId,
        props.transformationId,
      );

      return {
        readOnly: ApplicationStore.isReadOnly(),
        description: transformation.get('description', ''),
      };
    },
    [props.bucketId, props.transformationId],
    [ApplicationStore, TransformationsStore],
  );

  const [editorValue, setEditorValue] = useState<string | null>(null);

  const handleSave = async () => {
    const newDescription = editorValue || '';

    return ActionCreators.changeTransformationProperty(
      props.bucketId,
      props.transformationId,
      'description',
      newDescription.trim(),
      'Update transformation description',
    );
  };

  return (
    <DescriptionBox
      editorValue={editorValue}
      onSetEditorValue={setEditorValue}
      description={state.description}
      onSave={handleSave}
      readOnly={state.readOnly}
      placeholder="Transformation Description"
      componentId={componentId}
      configId={props.bucketId}
    />
  );
};

export default Description;
