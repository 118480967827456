import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { cn } from '@keboola/design';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import * as jobsResolver from '@/modules/jobs/JobComponentResolver';
import { routeNames as queueRouteNames } from '@/modules/queue/constants';
import { isPhaseJob } from '@/modules/queue/helpers';
import * as queueResolver from '@/modules/queue/jobResolver';
import { CreatedDate, RouterLink } from '@/react/common';
import ComponentWithIconAndType from '@/react/common/ComponentWithIconAndType';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';

class LatestActivityJob extends Component {
  render() {
    const component = this.props.hasNewQueue
      ? queueResolver.getComponentByJob(this.props.job)
      : jobsResolver.getComponentByJob(this.props.job);
    const config = this.props.hasNewQueue
      ? queueResolver.getConfigurationName(this.props.job)
      : jobsResolver.getConfigurationName(this.props.job);
    const isError = this.props.job.get('status') === 'error';

    return (
      <RouterLink
        className="tr"
        to={this.props.hasNewQueue ? queueRouteNames.JOB_DETAIL : jobsRouteNames.DETAIL}
        params={{ jobId: this.props.job.get('id') }}
      >
        <div className={cn('td', { 'bg-danger': isError })}>
          <ComponentWithIconAndType
            component={component}
            isPhase={isPhaseJob(this.props.job)}
            nameSuffix={config && `: ${config}`}
          />
        </div>
        <div className={cn('td w-200 text-right text-muted', { 'bg-danger': isError })}>
          <JobDuration
            status={this.props.job.get('status')}
            startTime={this.props.job.get('startTime')}
            endTime={this.props.job.get('endTime')}
          />
        </div>
        <div className={cn('td w-200 text-right text-muted', { 'bg-danger': isError })}>
          <CreatedDate createdTime={this.props.job.get('createdTime')} />
        </div>
        <div className={cn('td w-100', { 'bg-danger': isError })}>
          <JobStatusLabel status={this.props.job.get('status')} />
        </div>
      </RouterLink>
    );
  }
}

LatestActivityJob.propTypes = {
  job: PropTypes.instanceOf(Map).isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
};

export default LatestActivityJob;
