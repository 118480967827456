import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { FormGroup, Label, TextInput } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  component: Map<string, any> | null;
  onSubmit: (name: string) => Promise<void>;
  onHide: () => void;
};

export const ConfigurationModal = ({ component, onSubmit, onHide }: Props) => {
  const [componentName, setComponentName] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!componentName) return;
    setIsLoading(true);
    onSubmit(componentName.trim())
      .then(() => {
        onHide();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleInputChange = (value: string) => {
    setComponentName(value);
  };

  const isDisabled = isLoading || !componentName;

  return (
    <form onSubmit={handleSubmit}>
      <Modal.Header closeButton>
        <Modal.Title>New {component?.get('name')} Shared Codes</Modal.Title>
        <ModalIcon icon="code" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        <FormGroup>
          <Label htmlFor="name">Name</Label>
          <TextInput
            id="name"
            autoFocus
            type="text"
            value={componentName}
            onChange={handleInputChange}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          saveLabel={isLoading ? 'Creating shared code...' : 'Create shared code'}
          saveStyle="primary"
          saveButtonType="submit"
          isSaving={isLoading}
          isDisabled={isDisabled}
        />
      </Modal.Footer>
    </form>
  );
};
