import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import TransformationsStore from '@/modules/legacy-transformation/stores/TransformationsStore';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';

const TransformationName = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TransformationsStore)],

  propTypes: {
    bucketId: PropTypes.string.isRequired,
    transformationId: PropTypes.string.isRequired,
  },

  getInitialState() {
    const transformation = TransformationsStore.getTransformation(
      this.props.bucketId,
      this.props.transformationId,
    );

    return {
      value: transformation.get('name', ''),
      isEditing: false,
      isSaving: false,
    };
  },

  getStateFromStores() {
    const transformation = TransformationsStore.getTransformation(
      this.props.bucketId,
      this.props.transformationId,
    );

    return {
      originalValue: transformation.get('name', ''),
      readOnly: ApplicationStore.isReadOnly(),
    };
  },

  componentDidUpdate() {
    if (
      !this.state.isEditing &&
      !this.state.isSaving &&
      this.state.originalValue !== this.state.value
    ) {
      this.setState({ value: this.state.originalValue });
    }
  },

  render() {
    return (
      <InlineEditTextInput
        placeholder="My Transformation"
        readOnly={this.state.readOnly}
        text={this.state.value}
        isSaving={this.state.isSaving}
        isEditing={this.state.isEditing}
        isValid={!!this.state.value.trim()}
        isChanged={this.state.value !== this.state.originalValue}
        onEditStart={this.handleEditStart}
        onEditCancel={this.handleEditCancel}
        onEditChange={this.handleEditChange}
        onEditSubmit={this.handleEditSubmit}
      />
    );
  },

  handleEditStart() {
    this.setState({ isEditing: true });
  },

  handleEditCancel() {
    this.setState({ isEditing: false, value: this.state.originalValue });
  },

  handleEditChange(newValue) {
    this.setState({ value: newValue });
  },

  handleEditSubmit() {
    this.setState({ isSaving: true });
    ActionCreators.changeTransformationProperty(
      this.props.bucketId,
      this.props.transformationId,
      'name',
      this.state.value.trim(),
      'Update name',
    ).finally(() => {
      this.setState({ isSaving: false, isEditing: false });
    });
  },
});

export default TransformationName;
