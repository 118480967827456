import { type Map } from 'immutable';

import { FormGroup, Label } from '@keboola/design';

import { flattenJobs } from '@/modules/ex-generic/helpers';
import Select from '@/react/common/Select';
import ParentForm from './ParentForm';
import UrlPathControl from './UrlPathControl';
import UrlPreview from './UrlPreview';

const PathSettings = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  savedJob: Map<string, any>;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  const pathAlreadyUsed = flattenJobs(props.parameters).some((job: Map<string, any>) => {
    if (job.delete('endpointPath').equals(props.savedJob)) {
      return false;
    }

    return job.get('endpoint') === props.editing.get('endpoint');
  });

  return (
    <div className="box">
      <div className="box-content">
        <div className="tw-grid tw-grid-cols-5 tw-items-start tw-gap-4">
          <FormGroup>
            <Label htmlFor="method">Method</Label>
            <Select
              id="method"
              clearable={false}
              disabled={props.readOnly}
              value={props.editing.get('method')}
              onChange={(value: string) => props.setEditing(props.editing.set('method', value))}
              options={[
                { label: 'GET', value: 'GET' },
                { label: 'POST (json)', value: 'POST' },
                { label: 'POST (url-encoded)', value: 'FORM' },
              ]}
            />
          </FormGroup>
          <div className="tw-col-span-4">
            <UrlPreview
              path={props.editing.get('endpoint', '')}
              baseUrl={props.parameters.getIn(['api', 'baseUrl'], '')}
            />
          </div>
        </div>
        <UrlPathControl
          editing={props.editing}
          setEditing={props.setEditing}
          showUsedWarning={pathAlreadyUsed}
        />
        <ParentForm
          readOnly={props.readOnly}
          parameters={props.parameters}
          editing={props.editing}
          setEditing={props.setEditing}
          savedJob={props.savedJob}
        />
      </div>
    </div>
  );
};

export default PathSettings;
