import { useState } from 'react';
import type { MouseEvent } from 'react';
import { components as selectComponents } from 'react-select';
import { Map } from 'immutable';

import { Badge, ButtonInline, Icon, Tooltip } from '@keboola/design';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import { FUNCTION_NAME, PARAMETER_TYPE } from '@/modules/ex-generic/constants';
import {
  isSimpleParameter,
  parameterType,
  preparePropertyName,
} from '@/modules/ex-generic/helpers';
import Select from '@/react/common/Select';
import string from '@/utils/string';
import NewParameter from './NewParameter';

const UNKNOWN_FUNCTION = 'Unknown function';

const UserParameterSelector = (props: {
  readOnly: boolean;
  row: Map<string, any>;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  onChange: (value: string, userParameter?: PARAM_VALUE) => void;
  userParameters: Map<string, any>;
}) => {
  const [selectInput, setSelectInput] = useState('');
  const [addNewParameter, setAddNewParameter] = useState<Record<string, any> | null>(null);

  const name = props.row.get('key', '');
  const rowValue = props.row.get('value', '');
  const isUserParameter = Map.isMap(rowValue);
  const value = isUserParameter
    ? isSimpleParameter(rowValue)
      ? rowValue.get('attr')
      : props.userParameters.findKey((parameter, name) => {
          return rowValue.get(FUNCTION_NAME) === name;
        }) || UNKNOWN_FUNCTION
    : rowValue;

  const userParametersOptions = props.userParameters
    .map((value, name) => {
      const isFunction = Map.isMap(value);
      const isEncrypted = name?.startsWith('#');

      return {
        label: (
          <Badge
            variant={
              isEncrypted
                ? 'cyan'
                : isFunction
                  ? 'purple-inverse'
                  : !isFunction && !isEncrypted
                    ? 'blue'
                    : 'gray'
            }
            className="!tw-text-xs !tw-normal-case"
          >
            {preparePropertyName(name as string)}
            {!isFunction && !isEncrypted && <>: {string.truncate(String(value), 28)}</>}
          </Badge>
        ),
        value: name,
      };
    })
    .sortBy((option) => option?.label)
    .toArray();

  const handleCreateNew = () => {
    if (selectInput) {
      return setAddNewParameter({ name, value: selectInput });
    }

    if (isUserParameter && !isSimpleParameter(props.row.get('value'))) {
      return setAddNewParameter({
        name,
        type: PARAMETER_TYPE.FUNCTION,
        value: JSON.stringify(
          props.userParameters.get(value, props.row.get('value')).remove(FUNCTION_NAME),
          null,
          '  ',
        ),
      });
    }

    return setAddNewParameter({ name });
  };

  const options = [
    {
      label: (
        <div className="tw-flex tw-justify-between !tw-font-medium">
          <span className="tw-text-xs tw-uppercase tw-tracking-widest">Select User Parameter</span>
          {!props.readOnly && (
            <div className="tw-inline-flex tw-gap-4">
              <ButtonInline
                variant="link"
                color="custom"
                className="tw-text-xs tw-uppercase tw-text-neutral-400"
                onClick={handleCreateNew}
              >
                <Icon icon="plus" className="tw-text-primary-500" />
                Create New
              </ButtonInline>
            </div>
          )}
        </div>
      ),
      options: userParametersOptions,
    },
  ];

  const handleBadgeClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (!props.readOnly) {
      setAddNewParameter({ local: true, name, value, type: parameterType(value) });
    }
  };

  return (
    <>
      <div className="tw-flex tw-flex-1 tw-items-center tw-justify-start">
        <Select
          value={String(value)}
          className="tw-flex-1 [&_div[class*='value-container']]:tw-flex"
          placeholder="Select or create new parameter"
          inputValue={selectInput}
          onInputChange={setSelectInput}
          onChange={(value: string) => props.onChange(value, props.userParameters.get(value))}
          options={options}
          singleValueRenderer={(optionProps) =>
            isUserParameter ? (
              <selectComponents.SingleValue {...optionProps}>
                {optionProps.data.label}
              </selectComponents.SingleValue>
            ) : (
              <div onMouseDown={handleBadgeClick} className="tw-cursor-pointer">
                <Tooltip placement="top" type="action" tooltip="Edit local parameter">
                  <Badge className="!tw-text-xs tw-transition-colors hover:!tw-bg-neutral-500">
                    {string.truncate(String(value), 28)}
                    {!props.readOnly && <Icon icon="pen" className="f-11 tw-ml-2" />}
                  </Badge>
                </Tooltip>
              </div>
            )
          }
          noOptionRenderer={() => {
            return (
              <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-p-4">
                {!!userParametersOptions?.length && (
                  <div className="text-muted">No &quot;{selectInput}&quot; parameter found</div>
                )}
                {!props.readOnly && (
                  <ButtonInline
                    variant="link"
                    color="custom"
                    className="!tw-font-medium tw-text-neutral-400"
                    onClick={handleCreateNew}
                  >
                    <Icon icon="plus" className="tw-text-primary-500" />
                    Create New Parameter
                  </ButtonInline>
                )}
              </div>
            );
          }}
        />
        {isUserParameter && !props.userParameters?.has(value) && (
          <Tooltip placement="top" type="explanatory" tooltip="User parameter not found.">
            <Icon
              icon="exclamation-triangle"
              className="tw-ml-4 tw-text-base tw-text-warning-500"
            />
          </Tooltip>
        )}
      </div>
      <NewParameter
        show={!!addNewParameter}
        parameters={props.parameters}
        onSave={props.onSave}
        onHide={() => setAddNewParameter(null)}
        onCreated={props.onChange}
        initParams={addNewParameter}
      />
    </>
  );
};

export default UserParameterSelector;
