import { useState } from 'react';

import { SwitchStateLink } from '@/react/common/ActionControls/SwitchStateLink';
import ConfirmModal from '@/react/common/ConfirmModal';

type Props = {
  isEnabled: boolean;
  isReadOnly: boolean;
  onUpdateConfiguration: (params: string[], description: string) => Promise<void>;
};

const DevBranchUnsafeSwitch = ({ isEnabled, isReadOnly, onUpdateConfiguration }: Props) => {
  const [isSavingConfigurationRuntimeSafeParam, setIsSavingConfigurationRuntimeSafeParam] =
    useState(false);
  const [showDevBranchUnsafeConfirmModal, setShowDevBranchUnsafeConfirmModal] = useState(false);

  const toggleDevBranchSafeRun = () => {
    setIsSavingConfigurationRuntimeSafeParam(true);
    return onUpdateConfiguration(
      ['runtime', 'safe'],
      `Mark the configuration as "${
        isEnabled ? 'unsafe' : 'safe'
      }" to be run in a development branch.`,
    ).finally(() => setIsSavingConfigurationRuntimeSafeParam(false));
  };

  return (
    <li>
      <SwitchStateLink
        label="Safe for run in branch"
        onChange={(value) =>
          value ? setShowDevBranchUnsafeConfirmModal(true) : toggleDevBranchSafeRun()
        }
        isActive={isEnabled}
        isPending={isSavingConfigurationRuntimeSafeParam}
        readOnly={isReadOnly}
      />
      <ConfirmModal
        buttonType="success"
        buttonLabel="Confirm"
        icon={['far', 'code-branch']}
        title="Mark Configuration as Safe"
        show={showDevBranchUnsafeConfirmModal}
        onHide={() => setShowDevBranchUnsafeConfirmModal(false)}
        text={
          <p>
            Are you sure you want to mark this configuration as safe for running in a development
            branch?
          </p>
        }
        onConfirm={toggleDevBranchSafeRun}
      />
    </li>
  );
};

export default DevBranchUnsafeSwitch;
