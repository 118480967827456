import { useEffect, useState } from 'react';
import type BluebirdPromise from 'bluebird';
import type { Map } from 'immutable';

import { cn, IconButton, Tooltip } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import descriptionExcerpt from '@/utils/descriptionExcerpt';
import isMarkdown from '@/utils/isMarkdown';
import DescriptionModal from './DescriptionModal';
import InlineEditTextInput from './InlineEditTextInput';

const MAX_EXCERPT_LENGTH = 60;
const MAX_ROW_EXCERPT_LENGTH = 50;

type Props = {
  entity: string;
  description?: string;
  readOnly: boolean;
  onSave: (description: string) => BluebirdPromise<void>;
  component?: Map<string, any>;
  config?: Map<string, any>;
  row?: Map<string, any>;
};

const InlineDescriptionEditInput = ({
  entity,
  description: savedDescription = '',
  onSave,
  readOnly,
  component,
  config,
  row,
}: Props) => {
  const [description, setNewDescription] = useState(savedDescription);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isEditing && !showModal && savedDescription !== description) {
      setNewDescription(savedDescription);
    }
  }, [isEditing, showModal, savedDescription, description]);

  if (!isEditing && !description) {
    return null;
  }

  const excerptLength = entity === 'Row' ? MAX_ROW_EXCERPT_LENGTH : MAX_EXCERPT_LENGTH;

  if (readOnly) {
    return (
      <span>{description ? descriptionExcerpt(description, excerptLength) : 'No description'}</span>
    );
  }

  const saveHandler = (forcedDescription?: string) => {
    const newDescription = forcedDescription || description || '';

    setIsSaving(true);
    return onSave(newDescription.trim())
      .then(() => {
        setIsEditing(false);
        setNewDescription(newDescription.trim());
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div className={cn('list-row-inline-edit-input-wrapper', { editing: isEditing })}>
      {!isEditing && (
        <>
          {descriptionExcerpt(showModal ? savedDescription : description, excerptLength)}
          <Tooltip tooltip="Edit Description" placement="top">
            <IconButton
              variant="invisible"
              size="extra-small"
              icon="pen"
              className="tw-ml-1 tw-opacity-0 tw-transition-opacity tw-duration-200 group-hover:tw-opacity-100"
              onClick={(event) => {
                event.preventDefault();
                event.stopPropagation();

                savedDescription.length > excerptLength ||
                savedDescription.includes('\n') ||
                isMarkdown(savedDescription)
                  ? setShowModal(true)
                  : setIsEditing(true);
              }}
            />
          </Tooltip>
        </>
      )}
      {isEditing && (
        <div
          onClick={(event) => {
            event.stopPropagation();
            event.preventDefault();
          }}
          onKeyDown={(event) => {
            if (event.key === keyCodes.ENTER) {
              event.stopPropagation();
            }
          }}
        >
          <InlineEditTextInput
            isEditing
            placeholder="Description"
            isSaving={isSaving}
            isChanged={description !== savedDescription}
            text={description}
            onEditStart={() => null}
            onEditCancel={() => {
              setNewDescription(savedDescription);
              setIsEditing(false);
            }}
            onEditChange={setNewDescription}
            onEditSubmit={saveHandler}
          />
        </div>
      )}
      <DescriptionModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onSave={saveHandler}
        description={description}
        entity={entity}
        {...(component &&
          config && {
            componentId: component.get('id'),
            configId: config.get('id'),
            rowId: row?.get('id'),
          })}
      />
    </div>
  );
};

export default InlineDescriptionEditInput;
