import { Dropdown } from 'react-bootstrap';
import type { Map } from 'immutable';

import { cn, Icon } from '@keboola/design';

import { APP_ROUTE } from '@/constants/routeNames';
import { canLoadSharedBuckets, isSharingAvailable } from '@/modules/admin/privileges';
import { routeNames as componentRoutes } from '@/modules/components-directory/constants';
import { routeNames as dataAppsRoutes } from '@/modules/data-apps/constants';
import { routeNames as dataCatalogRouteNames } from '@/modules/data-catalog/constants';
import { routeNames as flowRoutes } from '@/modules/flows/constants';
import { routeNames as flowV2Routes } from '@/modules/flows-v2/constants';
import { routeNames as jobRoutes } from '@/modules/jobs/Constants';
import { routeNames as legacyOrchestrationRoutes } from '@/modules/orchestrations/Constants';
import { routeNames as orchestrationRoutes } from '@/modules/orchestrations-v2/constants';
import { routeNames as queueRoutes } from '@/modules/queue/constants';
import { routeNames as workspacesRouteNames } from '@/modules/sandboxes/Constants';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import { routeNames as storageRoutes } from '@/modules/storage/constants';
import { routeNames as templateRoutes } from '@/modules/templates/constants';
import { routeNames as legacyTransformationsRouteNames } from '@/modules/transformations/Constants';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import { RouterLink } from '@/react/common';
import RoutesStore from '@/stores/RoutesStore';
import { useIsActiveByPathName } from '@/utils/router/hooks/useIsActiveByPathName';
import LinkMenuItem from './LinkMenuItem';

type Props = {
  sapiToken: Map<string, any>;
  showNewTransformations: boolean;
  hideOldTransformations: boolean;
  hasNewQueue: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  isDevModeActive: boolean;
  hasConfigurations: boolean;
  hasConditionalFlows: boolean;
  hasFlowsOnly: boolean;
  hasFlows: boolean;
  hasTemplates: boolean;
  hasInvalidCustomBackend: boolean;
  isDemoPreview: boolean;
  hasDataApps: boolean;
};

const Navigation = (props: Props) => {
  const isActiveByPathName = useIsActiveByPathName();

  const renderComponents = () => {
    if (props.hasDataApps && !props.isDevModeActive) {
      return (
        <Dropdown
          id="navbar-components"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={cn('navbar-link', {
              active:
                isActiveByPathName(componentRoutes.ROOT) || isActiveByPathName(dataAppsRoutes.ROOT),
              'btn-disabled': props.hasInvalidCustomBackend,
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
            Components
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT}
              active={isActiveByPathName(componentRoutes.ROOT)}
            >
              <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
              Components
            </LinkMenuItem>
            <LinkMenuItem to={dataAppsRoutes.ROOT} active={isActiveByPathName(dataAppsRoutes.ROOT)}>
              <Icon className="icon-addon-right" fixedWidth icon="browser" />
              Data Apps
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    return (
      <li>
        <RouterLink
          to={props.hasConfigurations ? componentRoutes.CONFIGURATIONS : componentRoutes.ROOT}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          isActive={isActiveByPathName(componentRoutes.ROOT)}
        >
          <Icon className="icon-addon-right" fixedWidth icon="layer-group" />
          <span>Components</span>
        </RouterLink>
      </li>
    );
  };

  const renderFlows = () => {
    if (!props.hasFlows && !props.hasFlowsOnly) {
      return null;
    }

    if (props.isDemoPreview || (props.hasFlowsOnly && !props.hasConditionalFlows)) {
      return (
        <li>
          <RouterLink
            to={flowRoutes.ROOT}
            disabled={props.hasInvalidCustomBackend}
            className={cn('navbar-link', {
              'tw-opacity-60': props.hasInvalidCustomBackend,
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
            <span>Flows</span>
          </RouterLink>
        </li>
      );
    }

    return (
      <Dropdown
        id="navbar-flows"
        componentClass="li"
        rootCloseEvent="mousedown"
        disabled={props.hasInvalidCustomBackend}
      >
        <Dropdown.Toggle
          noCaret
          bsStyle="link"
          className={cn('navbar-link', {
            'btn-disabled': props.hasInvalidCustomBackend,
            active:
              isActiveByPathName(flowRoutes.ROOT) ||
              isActiveByPathName(flowV2Routes.ROOT) ||
              isActiveByPathName(orchestrationRoutes.ROOT),
          })}
        >
          <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          Flows
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <LinkMenuItem to={flowRoutes.ROOT} active={isActiveByPathName(flowRoutes.ROOT)}>
            <Icon className="icon-addon-right" icon="bars-staggered" fixedWidth />
            Flows
          </LinkMenuItem>
          {props.hasConditionalFlows ? (
            <LinkMenuItem to={flowV2Routes.ROOT} active={isActiveByPathName(flowV2Routes.ROOT)}>
              <Icon className="icon-addon-right" icon={['fad', 'split']} rotation={90} fixedWidth />
              Conditional Flows
            </LinkMenuItem>
          ) : (
            <LinkMenuItem
              to={orchestrationRoutes.ROOT}
              active={isActiveByPathName(orchestrationRoutes.ROOT)}
            >
              <Icon className="icon-addon-right" icon="window-restore" fixedWidth />
              Orchestrations
            </LinkMenuItem>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderUseCases = () => {
    if (!props.hasTemplates || props.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={templateRoutes.ROOT}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          isActive={
            isActiveByPathName(templateRoutes.ROOT) ||
            (isActiveByPathName(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
              [templateRoutes.ROOT, templateRoutes.TEMPLATES].includes(
                RoutesStore.getRouterState().getIn(['location', 'query', 'd']),
              ))
          }
        >
          <Icon className="icon-addon-right" fixedWidth icon="book-open" />
          Templates
        </RouterLink>
      </li>
    );
  };

  const renderDataCatalog = () => {
    if (!isSharingAvailable(props.sapiToken)) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={
            !canLoadSharedBuckets(props.sapiToken)
              ? dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT
              : dataCatalogRouteNames.SHARED_WITH_YOU
          }
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          isActive={
            isActiveByPathName(dataCatalogRouteNames.SHARED_WITH_YOU) ||
            isActiveByPathName(dataCatalogRouteNames.SHARED_FROM_THIS_PROJECT)
          }
          disabled={props.hasInvalidCustomBackend}
        >
          <Icon className="icon-addon-right" fixedWidth icon="book-blank" />
          <span>Data Catalog</span>
        </RouterLink>
      </li>
    );
  };

  const renderOrchestrations = () => {
    if (props.hasFlows || props.hasFlowsOnly || (props.isDevModeActive && !props.hasNewQueue)) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={props.hasNewQueue ? orchestrationRoutes.ROOT : legacyOrchestrationRoutes.ROOT}
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          disabled={props.hasInvalidCustomBackend}
        >
          <Icon className="icon-addon-right" fixedWidth icon="bars-staggered" />
          <span>Orchestrations</span>
        </RouterLink>
      </li>
    );
  };

  const renderTransformationsMenu = () => {
    if (props.showNewTransformations && !props.hideOldTransformations) {
      return (
        <Dropdown
          id="navbar-transformations"
          componentClass="li"
          rootCloseEvent="mousedown"
          disabled={props.hasInvalidCustomBackend}
        >
          <Dropdown.Toggle
            noCaret
            bsStyle="link"
            className={cn('navbar-link', {
              active:
                isActiveByPathName(legacyTransformationsRouteNames.ROOT) ||
                isActiveByPathName(transformationsRouteNames.ROOT),
            })}
          >
            <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
            Transformations
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <LinkMenuItem
              to={legacyTransformationsRouteNames.ROOT}
              active={isActiveByPathName(legacyTransformationsRouteNames.ROOT)}
            >
              <Icon className="icon-addon-right" icon="window-restore" fixedWidth />
              Legacy Transformations
            </LinkMenuItem>
            <LinkMenuItem
              to={transformationsRouteNames.ROOT}
              active={isActiveByPathName(transformationsRouteNames.ROOT)}
            >
              <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
              Transformations
            </LinkMenuItem>
          </Dropdown.Menu>
        </Dropdown>
      );
    }

    if (props.showNewTransformations) {
      return (
        <li>
          <RouterLink
            to={transformationsRouteNames.ROOT}
            disabled={props.hasInvalidCustomBackend}
            className={cn('navbar-link', {
              'tw-opacity-60': props.hasInvalidCustomBackend,
            })}
            isActive={
              isActiveByPathName(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
              RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
                transformationsRouteNames.ROOT
            }
          >
            <Icon className="icon-addon-right" fixedWidth icon="window-restore" />
            <span>Transformations</span>
          </RouterLink>
        </li>
      );
    }
  };

  const renderWorkspaces = () => {
    if (!props.showNewTransformations) {
      return null;
    }

    return (
      <li>
        <RouterLink
          to={workspacesRouteNames.WORKSPACES}
          disabled={props.hasInvalidCustomBackend}
          className={cn('navbar-link', {
            'tw-opacity-60': props.hasInvalidCustomBackend,
          })}
          isActive={
            isActiveByPathName(snowflakePartnerConnectRouteNames.UPGRADE_PAGE) &&
            RoutesStore.getRouterState().getIn(['location', 'query', 'd']) ===
              workspacesRouteNames.WORKSPACES
          }
        >
          <Icon className="icon-addon-right" fixedWidth icon="box" />
          <span>Workspaces</span>
        </RouterLink>
      </li>
    );
  };

  return (
    <div className="navbar-collapse collapse">
      <div className="navbar">
        <ul className="nav navbar-nav">
          <li>
            <RouterLink to={APP_ROUTE} className="navbar-link" onlyActiveOnIndex>
              <Icon className="icon-addon-right" fixedWidth icon="gauge" />
              <span>Dashboard</span>
            </RouterLink>
          </li>
          {renderFlows()}
          {renderComponents()}
          {renderUseCases()}
          {renderDataCatalog()}
          <li>
            <RouterLink
              to={storageRoutes.ROOT}
              className={cn('navbar-link', {
                'tw-opacity-60': props.hasInvalidCustomBackend,
              })}
              disabled={props.hasInvalidCustomBackend}
            >
              <Icon className="icon-addon-right" fixedWidth icon="warehouse" />
              <span>Storage</span>
            </RouterLink>
          </li>
          {renderOrchestrations()}
          {renderTransformationsMenu()}
          {renderWorkspaces()}
          <li>
            <RouterLink
              to={props.hasNewQueue ? queueRoutes.JOBS : jobRoutes.ROOT}
              className={cn('navbar-link', {
                'tw-opacity-60': props.hasInvalidCustomBackend,
              })}
              disabled={props.hasInvalidCustomBackend}
            >
              <Icon className="icon-addon-right" fixedWidth icon="circle-play" />
              <span>Jobs</span>
            </RouterLink>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navigation;
