import BlankImage from '../../../../../images/illustrations/blank.png';

import { PaginatedBox } from '@/react/common/PaginatedBox/PaginatedBox';
import { PaginatedBoxItem } from '@/react/common/PaginatedBox/PaginatedBoxItem';
import { fileUrl } from '@/utils/fileHelpers';
import NewTransformationBucketButton from './NewTransformationBucketButton';

export const BlankTransformationsPage = ({
  transformationLanguagesText,
}: {
  transformationLanguagesText: string;
}) => (
  <div className="blank-page">
    <img src={fileUrl(BlankImage)} alt="Transformations introduction" className="intro-image" />
    <PaginatedBox>
      <PaginatedBoxItem>
        <h2>What is a transformation bucket?</h2>
        <p className="intro-text">
          A transformation bucket is a container for related transformations.
          <br />
          Once the bucket is created, you can start creating transformations inside it.
        </p>
      </PaginatedBoxItem>
      <PaginatedBoxItem>
        <h2>What is a transformation?</h2>
        <p className="intro-text">
          Transformations pick data from Storage, manipulate it and then store it back.
          <br />
          {transformationLanguagesText}
        </p>
      </PaginatedBoxItem>
    </PaginatedBox>
    <div className="intro-action">
      <NewTransformationBucketButton label="New Transformation Bucket" />
    </div>
  </div>
);
