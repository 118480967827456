import type { Map } from 'immutable';

import { Icon, Link, Tooltip } from '@keboola/design';

import { canDownloadSlicedFile } from '@/modules/admin/privileges';
import ModalHandler from '@/modules/sapi-events/sliced-files-downloader/ModalHandler';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  file: Map<string, any>;
};

const FileLinkButton = (props: Props) => {
  if (props.file.get('isSliced') && !canDownloadSlicedFile(ApplicationStore.getSapiToken())) {
    return null;
  }

  if (props.file.get('isSliced')) {
    return <ModalHandler mode="button" file={props.file} />;
  }

  return (
    <Tooltip tooltip="Download file" placement="top">
      <Link className="btn btn-link text-muted" href={props.file.get('url')}>
        <Icon icon="circle-down" fixedWidth />
      </Link>
    </Tooltip>
  );
};

export default FileLinkButton;
