import { Map } from 'immutable';

import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { loadTableDetail, tokenVerify } from '@/modules/storage/actions';
import { routeNames } from './constants';
import Index from './Index';

export default {
  name: routeNames.ROOT,
  title: (routerState) => {
    const tableId = routerState.getIn(['params', 'tableId']);
    const table = StorageTablesStore.getTable(tableId, Map());
    return `Table ${table.get('displayName', tableId)}`;
  },
  path: 'table-preview/:tableId/:tableTab?',
  requireData: [() => tokenVerify(), (params) => loadTableDetail(params.tableId)],
  defaultRouteHandler: Index,
};
