import { Modal } from 'react-bootstrap';

import { FakeProgressBar } from '@keboola/design';

import { AiIcon } from '@/react/common/AI/svgGradient';

const NewAutomationProgressModal = ({
  show,
  animation = true,
}: {
  show: boolean;
  animation?: boolean;
}) => {
  return (
    <Modal
      show={show}
      className="[&_.modal-content]:tw-rounded-2xl [&_.modal-content]:tw-bg-gradient-to-br [&_.modal-content]:tw-from-[#0975E033] [&_.modal-content]:tw-to-[#6F36E033]"
      dialogClassName="tw-mt-[30vh] tw-max-w-[90%] tw-w-[800px] tw-rounded-2xl"
      animation={animation}
    >
      <Modal.Body className="!tw-px-12 !tw-pb-6 tw-pt-5">
        <h3 className="tw-mb-8 tw-mt-2">
          Generating your first flow with the help of our AI Copilot.
          <AiIcon className="tw-ml-2" />
        </h3>
        <FakeProgressBar barClassName="tw-bg-gradient-to-br tw-from-[#0975E0] tw-to-[#6F36E0]" />
      </Modal.Body>
    </Modal>
  );
};

export default NewAutomationProgressModal;
