import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isFlowComponent } from '@/modules/flows-v2/helpers';
import { CreatedDate } from '@/react/common';
import ConfirmButtons from './ConfirmButtons';
import ModalIcon from './ModalIcon';

type Props = {
  version: Map<string, any>;
  show: boolean;
  onClose: () => void;
  onRollback: () => void;
  componentId: string;
};

const RollbackVersionModal = ({ onClose, componentId, version, show, onRollback }: Props) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Version Rollback</Modal.Title>
        <ModalIcon color="green" icon="arrow-rotate-left" bold />
      </Modal.Header>
      <Modal.Body>
        {isFlowComponent(componentId) && (
          <Alert variant="warning" className="tw-mb-5">
            If the flow has a schedule set, the rollback will not affect it.
          </Alert>
        )}
        <p>
          Are you sure you want to roll back version #{version.get('version')} created{' '}
          <CreatedDate createdTime={version.get('created')} /> by{' '}
          {version.getIn(['creatorToken', 'description'], 'unknown')}?
        </p>
        <p>The rollback copies the older version into a new version, so no version will be lost.</p>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons block saveLabel="Restore version" onSave={onRollback} />
      </Modal.Footer>
    </Modal>
  );
};

export default RollbackVersionModal;
