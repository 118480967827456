import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import type { Map } from 'immutable';
import { Seq } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { factory as defaultEventsFactory } from '@/modules/sapi-events/EventsService';
import EventsTable from '@/modules/sapi-events/react/EventsTable';
import BlockButton from '@/react/common/BlockButton';
import SearchPanel from '@/react/common/SearchPanel';

const predefinedSearches = [
  {
    name: 'Omit table fetches',
    query: 'NOT event:storage.tableDataPreview OR NOT event:storage.tableDetail',
  },
  {
    name: 'Omit table exports',
    query: 'NOT event:storage.tableExported',
  },
  {
    name: 'Imports/exports only',
    query: ['ImportStarted', 'ImportDone', 'ImportError', 'Exported']
      .map((type) => `event:storage.table${type}`)
      .join(' OR '),
  },
];

type EventsProps = {
  admins: Map<any, any>;
  eventsFactory?: ReturnType<typeof defaultEventsFactory>;
};

type EventsState = {
  events: Seq<any, any>;
  isLoadingMore: boolean | undefined;
  isRefreshing: boolean | undefined;
  isLoading: boolean | undefined;
  hasMore: boolean | undefined;
  searchQuery: string;
};

const Events = ({ admins, eventsFactory = defaultEventsFactory() }: EventsProps) => {
  const [state, setState] = useState<EventsState>({
    events: Seq(),
    isLoadingMore: false,
    isRefreshing: false,
    isLoading: true,
    hasMore: true,
    searchQuery: '',
  });

  const eventsRef = React.useRef(eventsFactory);
  const _events = eventsRef.current;

  const handleChange = useCallback(() => {
    const isLoading = _events.getIsLoading();

    setState((prevState) => ({
      ...prevState,
      searchQuery: _events.getQuery(),
      events: _events.getEvents(),
      isRefreshing: isLoading && prevState.isRefreshing,
      isLoading,
      isLoadingMore: _events.getIsLoadingOlder(),
      hasMore: _events.getHasMore(),
    }));
  }, [_events]);

  useEffect(() => {
    _events.addChangeListener(handleChange);
    _events.load();

    return () => {
      _events.removeChangeListener(handleChange);
      _events.reset();
    };
  }, [_events, handleChange]);

  const handleRefresh = () => {
    setState((prevState) => ({ ...prevState, isRefreshing: true }));
    _events.load();
  };

  const handleLoadEvent = (eventId: string) => {
    return _events.loadEvent(eventId);
  };

  const handleLoadMore = () => {
    if (state.isLoading || state.isLoadingMore) {
      return;
    }
    _events.loadMoreMax();
  };

  const handleSearchSubmit = (searchQuery: string) => {
    _events.setQuery(searchQuery);
    _events.load();
  };

  const isSearching = state.isLoading && !state.isRefreshing;

  const renderMoreButton = () => {
    if (state.events.isEmpty() || !state.hasMore || isSearching) {
      return null;
    }

    return (
      <BlockButton
        onClick={handleLoadMore}
        isLoading={state.isLoadingMore}
        disabled={state.isLoading || state.isLoadingMore}
      />
    );
  };

  return (
    <>
      <SearchPanel
        searchFieldPlaceholder="Search events"
        query={state.searchQuery}
        onSearch={(query) => setState((prevState) => ({ ...prevState, searchQuery: query }))}
        onSubmit={handleSearchSubmit}
        predefinedSearches={predefinedSearches}
        additionalActions={
          <Tooltip tooltip="Refresh events" placement="top">
            <IconButton
              variant="outline"
              size="small"
              onClick={handleRefresh}
              isLoading={state.isRefreshing}
              icon="arrows-rotate"
            />
          </Tooltip>
        }
      />
      <div className="box">
        <InfiniteScroll initialLoad={false} hasMore={state.hasMore} loadMore={handleLoadMore}>
          <EventsTable
            events={state.events}
            admins={admins}
            isLoading={isSearching ?? false}
            loadEvent={handleLoadEvent}
            searchQuery={state.searchQuery}
          />
        </InfiniteScroll>
        {renderMoreButton()}
      </div>
    </>
  );
};

export default Events;
