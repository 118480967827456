import { useState } from 'react';
import { Button } from 'react-bootstrap';
import type { List, Map } from 'immutable';
import { Set } from 'immutable';

import { ClipboardIcon, cn, Icon, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { isInternal } from '@/constants/helpers';
import { deleteFiles } from '@/modules/storage/actions';
import type { FileExpiration } from '@/modules/storage/helpers';
import { fileExpirationStatus } from '@/modules/storage/helpers';
import { CreatedDate, FileSize } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import FileLink from '@/react/common/FileLink';
import Loader from '@/react/common/Loader';
import MultiActionsHeader from '@/react/common/MultiActionsHeader';
import MultiActionsSelectCheckbox from '@/react/common/MultiActionsSelectCheckbox';
import FileLinkButton from './FileLinkButton';
import FileTags from './FileTags';
import MultipleDownloadModal from './MultipleDownloadModal';

type Props = {
  readOnly: boolean;
  canDownloadSlicedFile: boolean;
  files: Map<string, any>;
  admins: Map<string, any>;
  downloadingFiles: List<string>;
  onSearchQuery: (query: string) => void;
  onDeleteFile: (fileId: number) => Promise<unknown>;
  isDeleting: Map<string, boolean>;
  searchQuery: string;
};

const FilesTable = (props: Props) => {
  const [selected, setSelected] = useState(Set<number>());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMultipleDeleteModal, setShowMultipleDeleteModal] = useState(false);
  const [showMultipleDownloadModal, setShowMultipleDownloadModal] = useState(false);
  const [deleteFile, setDeleteFile] = useState<Map<string, any> | null>(null);

  if (!props.files.count()) {
    return null;
  }

  const renderRow = (file: Map<string, any>) => {
    const isDeleting = props.isDeleting.get(file.get('id'), false);
    const admin = props.admins.get(file.getIn(['creatorToken', 'description']));
    const expirationStatus = fileExpirationStatus(file);

    return (
      <tr key={file.get('id')} className="hoverable-actions-with-replacement">
        <td>
          <div className="flex-container flex-start">
            {!props.readOnly && (
              <MultiActionsSelectCheckbox
                entity="file"
                isChecked={selected.has(file.get('id'))}
                isDisabled={isDeleting}
                onToggle={(checked) => {
                  setSelected((prevState) =>
                    checked ? prevState.add(file.get('id')) : prevState.delete(file.get('id')),
                  );
                }}
              />
            )}
            <span className={cn({ 'ml-1': !props.readOnly })}>
              <span className="tw-flex tw-items-center tw-gap-2.5">
                {props.readOnly || expirationStatus.expired ? (
                  file.get('name')
                ) : (
                  <FileLink file={file} showFilesize={false} linkClass="color-inherit" />
                )}
                {renderClipboard(file, expirationStatus)}
              </span>
              <FileTags file={file} readOnly={props.readOnly} onSearchQuery={props.onSearchQuery} />
            </span>
          </div>
        </td>
        <td>{renderFileLink(file)}</td>
        <td>
          <div className="flex-container">
            <span>File size</span>
            <FileSize size={file.get('sizeBytes')} />
          </div>
          <div className="flex-container">
            <span className="no-wrap">Created</span>
            <span className="text-right">
              {admin
                ? admin.get('name') || admin.get('email')
                : isInternal(file.getIn(['creatorToken', 'description']), 'schedule')
                  ? 'Scheduled run'
                  : file.getIn(['creatorToken', 'description'])}
            </span>
          </div>
          <div className="flex-container">
            <span>Uploaded</span>
            <CreatedDate createdTime={file.get('created')} />
          </div>
        </td>
        {props.readOnly || expirationStatus.expired ? (
          <td>{renderStatus(file, expirationStatus)}</td>
        ) : (
          <td className="no-wrap">
            {props.downloadingFiles.includes(file.get('id')) ? (
              <span className="text-muted">
                <Loader className="btn-icon" />
                Preparing file
              </span>
            ) : (
              <div className="actions-container">
                <div className="not-actions">{renderStatus(file, expirationStatus)}</div>
                <div className="actions">
                  <FileLinkButton file={file} />
                  {renderDeleteFile(file)}
                </div>
              </div>
            )}
          </td>
        )}
      </tr>
    );
  };

  const renderStatus = (file: Map<string, any>, expirationStatus: FileExpiration) => {
    return (
      <>
        {file.get('isPublic') && <div className="text-muted">Public</div>}
        {!file.get('isEncrypted') && <div className="text-muted">Not encrypted</div>}
        {expiration(expirationStatus)}
      </>
    );
  };

  const renderFileLink = (file: Map<string, any>) => {
    const preparedSearchQuery = `id:${file.get('id')}`;

    if (props.searchQuery === preparedSearchQuery) {
      return file.get('id');
    }

    return (
      <Button
        bsStyle="link"
        className="btn-link-inline color-inherit"
        onClick={() => props.onSearchQuery(preparedSearchQuery)}
      >
        {file.get('id')}
      </Button>
    );
  };

  const renderDeleteModal = () => {
    const file = deleteFile;

    if (!file) {
      return null;
    }

    return (
      <ConfirmModal
        show={showDeleteModal}
        onHide={() => {
          setShowDeleteModal(false);
          setDeleteFile(null);
        }}
        icon="trash"
        title="Delete File"
        text={
          <p>
            Are you sure you want to delete the file {file.get('id')} ({file.get('name')})?
          </p>
        }
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() =>
          props.onDeleteFile(file.get('id')).then(() => {
            ApplicationActionCreators.sendNotification({
              type: 'info',
              message: () => (
                <>
                  File <b>{file.get('name')}</b> has been removed.
                </>
              ),
            });
          })
        }
      />
    );
  };

  const renderMultipleDeleteModal = () => {
    return (
      <ConfirmModal
        closeAfterResolve
        show={showMultipleDeleteModal}
        icon="trash"
        title="Delete Selected"
        text={`Are you sure you want to delete the ${
          selected.count() > 1 ? 'selected files' : 'file'
        }?`}
        buttonLabel="Delete"
        buttonType="danger"
        onConfirm={() => {
          return deleteFiles(selected.toArray()).then(() => setSelected(Set()));
        }}
        onHide={() => setShowMultipleDeleteModal(false)}
        isLoading={!props.isDeleting.isEmpty()}
      />
    );
  };

  const renderMultipleDownloadModal = () => {
    return (
      <MultipleDownloadModal
        show={showMultipleDownloadModal}
        files={props.files}
        selectedIds={selected}
        canDownloadSlicedFile={props.canDownloadSlicedFile}
        downloadingFiles={props.downloadingFiles}
        onConfirm={() => setSelected(Set())}
        onHide={() => setShowMultipleDownloadModal(false)}
      />
    );
  };

  const expiration = (status: FileExpiration) => {
    if (status.permanent) {
      return <div className="text-muted">{status.text}</div>;
    }

    return <div className={status.expired ? 'text-danger' : 'text-success'}>{status.text}</div>;
  };

  const renderClipboard = (file: Map<string, any>, expirationStatus: FileExpiration) => {
    if (file.get('isSliced') || expirationStatus.expired) {
      return null;
    }

    return <ClipboardIcon tooltipText="Copy file URL to clipboard" text={file.get('url')} />;
  };

  const renderDeleteFile = (file: Map<string, any>) => {
    const isDeleting = props.isDeleting.get(file.get('id'), false);

    return (
      <Tooltip tooltip="Delete file" placement="top">
        <Button
          bsStyle="link"
          className="text-muted"
          disabled={isDeleting}
          onClick={() => {
            setShowDeleteModal(true);
            setDeleteFile(file);
          }}
        >
          {isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
        </Button>
      </Tooltip>
    );
  };

  return (
    <>
      {renderDeleteModal()}
      {renderMultipleDeleteModal()}
      {renderMultipleDownloadModal()}
      <table className="table table-hover overflow-break-anywhere">
        <thead>
          <tr>
            <th>
              <MultiActionsHeader
                entity="file"
                hide={props.readOnly}
                totalCount={props.files.count()}
                selectedCount={selected.count()}
                disabled={!props.isDeleting.isEmpty()}
                onToggleAll={(checked) => {
                  setSelected(!checked ? Set() : props.files.map((file) => file.get('id')).toSet());
                }}
                placeholder="Name"
              >
                <div className="table-action-buttons">
                  <Tooltip placement="top" tooltip="Delete Selected">
                    <Button
                      bsStyle="link"
                      className="text-muted"
                      onClick={() => setShowMultipleDeleteModal(true)}
                      disabled={!props.isDeleting.isEmpty()}
                    >
                      {!props.isDeleting.isEmpty() ? <Loader /> : <Icon icon="trash" fixedWidth />}
                    </Button>
                  </Tooltip>
                  <Tooltip placement="top" tooltip="Download Selected">
                    <Button
                      bsStyle="link"
                      className="text-muted"
                      onClick={() => setShowMultipleDownloadModal(true)}
                    >
                      <Icon icon="circle-down" fixedWidth />
                    </Button>
                  </Tooltip>
                </div>
              </MultiActionsHeader>
            </th>
            <th className="w-150">File ID</th>
            <th className="w-300">Detail</th>
            <th className="w-200">Status</th>
          </tr>
        </thead>
        <tbody>
          {props.files
            .sortBy((file) => -1 * file.get('id'))
            .map(renderRow)
            .toArray()}
        </tbody>
      </table>
    </>
  );
};

export default FilesTable;
