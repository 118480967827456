import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { bucketTabs, routeNames } from '@/modules/storage/constants';
import { CreatedDate, Truncated } from '@/react/common';
import BucketStageLabel from './BucketStageLabel';
import ComponentIcon from './ComponentIcon';
import { RouterLink } from './RouterLink';

const StorageDataModal = (props: {
  show: boolean;
  onHide: () => void;
  buckets: Map<string, any>;
  component?: Map<string, any>;
  config?: Map<string, any>;
}) => {
  return (
    <span className="tw-hidden" onClick={(e) => e.stopPropagation()}>
      <Modal className="simple-modal-with-table" show={props.show} onHide={props.onHide}>
        <Modal.Header className="no-border" closeButton>
          <Modal.Title>
            {props.config ? `${props.config.get('name')} - ` : ''}Recently Updated Buckets
            {props.component && (
              <ComponentIcon
                className="circle-icon bigger-icon modal-icon"
                component={props.component}
              />
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <div className="th">Name</div>
                <div className="th">Last Change</div>
              </div>
            </div>
            <div className="tbody">
              {props.buckets
                .map((tables: Map<string, any>) => {
                  const bucket = tables.first().get('bucket', Map());

                  return (
                    <RouterLink
                      key={bucket.get('id')}
                      to={routeNames.BUCKET}
                      params={{ bucketId: bucket.get('id'), bucketTab: bucketTabs.OVERVIEW }}
                      className="tr"
                    >
                      <div className="td color-main">
                        <div className="flex-container flex-start">
                          <Icon
                            fixedWidth
                            icon="folder"
                            className="f-16 text-muted icon-addon-right"
                          />
                          <Truncated text={bucket.get('displayName')} />
                          <BucketStageLabel stage={bucket.get('stage')} placement="right" round />
                        </div>
                      </div>
                      <div className="td text-muted">
                        <CreatedDate createdTime={bucket.get('lastChangeDate')} />
                      </div>
                    </RouterLink>
                  );
                })
                .toArray()}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </span>
  );
};

export default StorageDataModal;
