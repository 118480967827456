import { type ReactNode, useEffect } from 'react';

import keyCodes from '@/constants/keyCodes';
import FullScreenModal from '@/react/common/FullScreenModal';

export const SidebarModal = ({ onHide, children }: { onHide: () => void; children: ReactNode }) => {
  useEffect(() => {
    const hide = (e: KeyboardEvent) => e.key === keyCodes.ESCAPE && onHide();
    document.addEventListener('keydown', hide, { passive: true });
    return () => document.removeEventListener('keydown', hide);
  }, [onHide]);

  useEffect(() => {
    document.body.classList.add('modal-open');

    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <FullScreenModal
      className="tw-top-[72px] tw-h-[calc(100vh-72px)] tw-w-[640px] tw-justify-self-end tw-border-y-0 tw-border-l tw-border-r-0 tw-border-solid tw-border-neutral-150 [&_.modal-content]:tw-h-full [&_.modal-content]:tw-bg-white [&_.modal-content]:tw-px-3 [&_.modal-content]:tw-pb-0 [&_.modal-content]:tw-pt-6"
      wrapperClassName="tw-flex tw-flex-col tw-gap-3 tw-h-full tw-overflow-hidden"
      hideOverflow={false}
    >
      {children}
    </FullScreenModal>
  );
};
