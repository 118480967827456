import type { GenericFetchClient } from '../storageClient';

import type { AsyncStorageJobResponse, DeleteTableRows, GetDataPreview, PrevieData } from './types';

export const createTables = (client: GenericFetchClient) => {
  const deleteTableRows = async (id: string, query?: DeleteTableRows, signal?: AbortSignal) => {
    const { data } = await client.delete<AsyncStorageJobResponse, { id: string }, DeleteTableRows>(
      `/tables/{id}/rows`,
      { path: { id }, query },
      { signal },
    );

    return data;
  };

  const getDataPreview = async (id: string, query?: GetDataPreview, signal?: AbortSignal) => {
    const { data } = await client.get<PrevieData, { id: string }, GetDataPreview>(
      `/tables/{id}/data-preview`,
      { path: { id }, query: { format: 'json', ...query } },
      { signal },
    );

    return data;
  };

  return {
    getDataPreview,
    deleteTableRows,
  };
};
