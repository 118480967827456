import { useState } from 'react';
import type { ChangeEvent, FormEvent, ReactNode } from 'react';
import { FormControl, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import type { ValidityState } from '@keboola/design';
import { FormGroup, HelpBlock, Label, Link, TextInput } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const PAGINATION_METHODS = {
  OFFSET: 'offset',
  PAGENUM: 'pagenum',
  RESPONSE_URL: 'response.url',
  RESPONSE_PARAM: 'response.param',
  CURSOR: 'cursor',
} as const;

const NewPagination = (props: {
  show: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  onHide: () => void;
  onCreated?: (name: string) => void;
  forceName?: string;
  existingPagination?: string | null;
}) => {
  const [formData, setFormData] = useState(Map());
  const [isSaving, setSaving] = useState(false);
  const name = formData.get('name', '') as string;

  const nameAlreadyUsed = props.parameters
    .getIn(['api', 'pagination', 'scrollers'], Map())
    .some((value: Map<string, any>, key: string) => {
      return key !== props.existingPagination && name === key;
    });

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSaving(true);
    const name = formData.get('name') as string;
    const scroller = Map({ method: formData.get('method', PAGINATION_METHODS.OFFSET) }).update(
      (scroller) => {
        const method = scroller.get('method');

        if (method === PAGINATION_METHODS.OFFSET) {
          return scroller
            .set('limit', formData.get('limit', 100))
            .set('limitParam', formData.get('limitParam', 'limit'))
            .set('offsetParam', formData.get('offsetParam', 'offset'))
            .set('firstPageParams', formData.get('firstPageParams', true))
            .set('offsetFromJob', formData.get('offsetFromJob', false));
        }

        if (method === PAGINATION_METHODS.PAGENUM) {
          return scroller
            .set('limit', formData.get('limit', 100))
            .set('limitParam', formData.get('limitParam', 'limit'))
            .set('pageParam', formData.get('pageParam', 'page'))
            .set('firstPageParams', formData.get('firstPageParams', true))
            .set('firstPage', formData.get('firstPage', 1));
        }

        if (method === PAGINATION_METHODS.RESPONSE_URL) {
          return scroller
            .set('urlKey', formData.get('urlKey', 'next_page'))
            .set('delimiter', formData.get('delimiter', '.'))
            .set('paramIsQuery', formData.get('paramIsQuery', false))
            .set('includeParams', formData.get('includeParams', false));
        }

        if (method === PAGINATION_METHODS.RESPONSE_PARAM) {
          return scroller
            .set('responseParam', formData.get('responseParam', ''))
            .set('queryParam', formData.get('queryParam', ''))
            .set('includeParams', formData.get('includeParams', false));
        }

        if (method === PAGINATION_METHODS.CURSOR) {
          return scroller
            .set('idKey', formData.get('idKey', ''))
            .set('param', formData.get('param', ''))
            .set('increment', formData.get('increment', 0))
            .set('reverse', formData.get('reverse', false));
        }

        return scroller;
      },
    );

    return props
      .onSave(
        props.parameters
          .setIn(['api', 'pagination', 'method'], 'multiple')
          .setIn(['api', 'pagination', 'scrollers', name], scroller),
        'Add new pagination',
      )
      .then(props.onHide)
      .then(() => props.onCreated?.(name))
      .finally(() => setSaving(false));
  };

  const renderInput = (
    type: 'text' | 'number' | 'boolean',
    label: string,
    property: string,
    defaultValue: string | number | boolean = '',
    help?: string,
  ) => {
    if (type === 'text') {
      return (
        <FormGroup className="tw-mb-4">
          <Label htmlFor="input">{label}</Label>
          <TextInput
            id="input"
            variant="secondary"
            value={formData.get(property, defaultValue) as string}
            onChange={(value) => {
              setFormData(formData.set(property, value));
            }}
          />

          <HelpBlock>{help}</HelpBlock>
        </FormGroup>
      );
    }

    return (
      <FormGroup className="tw-mb-4">
        <Label htmlFor="select-boolean">{label}</Label>
        {type === 'boolean' ? (
          <Select
            id="select-boolean"
            clearable={false}
            value={formData.get(property, defaultValue)}
            onChange={(value: boolean) => setFormData(formData.set(property, value))}
            options={[
              { label: 'True', value: true },
              { label: 'False', value: false },
            ]}
          />
        ) : (
          <FormControl
            type="number"
            value={formData.get(property, defaultValue)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setFormData(formData.set(property, parseInt(e.target.value, 10)));
            }}
          />
        )}
        {help && <HelpBlock className="tw-mt-1">{help}</HelpBlock>}
      </FormGroup>
    );
  };

  const renderAdditionalFields = () => {
    const method = formData.get('method', PAGINATION_METHODS.OFFSET);

    if (method === PAGINATION_METHODS.OFFSET) {
      return (
        <>
          {renderInput('number', 'Limit', 'limit', 100)}
          {renderInput(
            'text',
            'Limit Parameter',
            'limitParam',
            'limit',
            'Name of the parameter in which the API expects the page size.',
          )}
          {renderInput(
            'text',
            'Offset Parameter',
            'offsetParam',
            'offset',
            'Name of the parameter in which the API expects the item offset.',
          )}
          {renderInput(
            'boolean',
            'First Page Parameters',
            'firstPageParams',
            true,
            'When false, the first page is retrieved without the page parameters.',
          )}
          {renderInput(
            'boolean',
            'Offset From Job',
            'offsetFromJob',
            false,
            'When true, the offset parameter value is taken from the job parameters.',
          )}
        </>
      );
    }

    if (method === PAGINATION_METHODS.PAGENUM) {
      return (
        <>
          {renderInput('number', 'Limit', 'limit', '100')}
          {renderInput(
            'text',
            'Limit Parameter',
            'limitParam',
            'limit',
            'Name of the parameter in which the API expects the page size.',
          )}
          {renderInput(
            'text',
            'Page Parameter',
            'pageParam',
            'page',
            'Name of the parameter in which the API expects the page number.',
          )}
          {renderInput(
            'boolean',
            'First Page Parameters',
            'firstPageParams',
            true,
            'When false, the first page will be retrieved without the page parameters.',
          )}
          {renderInput('number', 'First Page', 'firstPage', 1, 'Index of the first page.')}
        </>
      );
    }

    if (method === PAGINATION_METHODS.RESPONSE_URL) {
      return (
        <>
          {renderInput(
            'text',
            'URL',
            'urlKey',
            'next_page',
            'Path in the response to the field which contains the URL of the next request.',
          )}
          {renderInput(
            'text',
            'Delimeter',
            'delimiter',
            '.',
            'Char used as the delimiter of the nested keys.',
          )}
          {renderInput(
            'boolean',
            'Params is Query',
            'paramIsQuery',
            false,
            'When true, URL is assumed to be only query string parameters.',
          )}
          {renderInput(
            'boolean',
            'Include Params',
            'includeParams',
            false,
            'Job parameters are added to the parameters of the URL provided in the response.',
          )}
        </>
      );
    }

    if (method === PAGINATION_METHODS.RESPONSE_PARAM) {
      return (
        <>
          {renderInput(
            'text',
            'Response Parameter',
            'responseParam',
            '',
            'Path to the key which contains the value used for scrolling.',
          )}
          {renderInput(
            'text',
            'Query Parameter',
            'queryParam',
            '',
            'Name of the query string parameter in which the above value should be sent to the API.',
          )}
          {renderInput(
            'boolean',
            'Include Parameters',
            'includeParams',
            false,
            'When true, the job parameters are added to the provided URL.',
          )}
        </>
      );
    }

    if (method === PAGINATION_METHODS.CURSOR) {
      return (
        <>
          {renderInput(
            'text',
            'Id Key',
            'idKey',
            '',
            'Path to the key which contains the value of the cursor. The path is entered relative to the exported items.',
          )}
          {renderInput(
            'text',
            'Parameter',
            'param',
            '',
            'Name of the query string parameter in which the above cursor value should be sent to the API.',
          )}
          {renderInput(
            'number',
            'Increment Value',
            'increment',
            '0',
            'Value by which the cursor value will be incremented or decremented.',
          )}
          {renderInput(
            'boolean',
            'Reverse',
            'reverse',
            false,
            'When true, the cursor is reversed.',
          )}
        </>
      );
    }

    return null;
  };

  const fieldNameState = nameAlreadyUsed && props.show ? 'error' : 'default';
  const fieldNameMessages: Partial<Record<ValidityState, ReactNode>> = {
    error: 'A paginator with the same name already exists.',
  };

  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      onEnter={() => {
        if (!props.existingPagination) {
          return setFormData(Map({ name: props.forceName || '' }));
        }

        return setFormData(
          props.parameters
            .getIn(['api', 'pagination', 'scrollers', props.existingPagination], Map())
            .set('name', props.existingPagination),
        );
      }}
    >
      <form onSubmit={handleSave}>
        <Modal.Header closeButton>
          <Modal.Title>{props.existingPagination ? 'Update' : 'New'} Pagination</Modal.Title>
          {props.existingPagination ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>
        <Modal.Body>
          <FormGroup className="tw-mb-4" state={fieldNameState}>
            <Label htmlFor="name">Name</Label>
            <FormGroup.TextInput
              id="name"
              variant="secondary"
              autoFocus
              value={name}
              onChange={(value) => {
                setFormData(formData.set('name', value));
              }}
            />

            <FormGroup.Help>{fieldNameMessages[fieldNameState]}</FormGroup.Help>
          </FormGroup>

          <FormGroup className="tw-mb-4">
            <Label htmlFor="method">Method</Label>
            <Select
              id="method"
              clearable={false}
              value={formData.get('method', PAGINATION_METHODS.OFFSET)}
              onChange={(value: string) => setFormData(formData.set('method', value))}
              options={[
                { label: 'Offset', value: PAGINATION_METHODS.OFFSET },
                { label: 'Pagenum', value: PAGINATION_METHODS.PAGENUM },
                { label: 'Response URL', value: PAGINATION_METHODS.RESPONSE_URL },
                { label: 'Response Param', value: PAGINATION_METHODS.RESPONSE_PARAM },
                { label: 'Cursor', value: PAGINATION_METHODS.CURSOR },
              ]}
            />
            <FormGroup.Help>
              Read more about each type in the{' '}
              <Link
                href={`${URLS.DEVELOPERS_DOCUMENTATION}/extend/generic-extractor/configuration/api/pagination/`}
              >
                documentation
              </Link>
              .
            </FormGroup.Help>
          </FormGroup>
          {renderAdditionalFields()}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            showCancel={false}
            isSaving={isSaving}
            saveButtonType="submit"
            saveLabel={`${props.existingPagination ? 'Update' : 'Create'} Pagination`}
            isDisabled={!name.trim() || nameAlreadyUsed}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default NewPagination;
