import { cn, Icon } from '@keboola/design';

import { ObjectTypes } from '@/modules/components/MetadataConstants';
import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { RouterLink, TableLabels, Truncated } from '@/react/common';
import type { RowData } from '@/types/types';
import { Description } from './Description';

type Props = {
  row: RowData;
  canWrite: boolean;
};

export const TableCell = ({ row, canWrite }: Props) => {
  const table = row.original.item;

  return (
    <span className="flex-container flex-start overflow-break-anywhere">
      <Icon icon="table" className="text-muted" />
      <div>
        <div className="flex-container flex-start">
          <RouterLink
            to={routeNames.TABLE}
            params={{
              bucketId: table.getIn(['bucket', 'id']),
              bucketTab: bucketTabs.OVERVIEW,
              tableName: table.get('name'),
              tableTab: tableTabs.OVERVIEW,
            }}
            className={cn('link-inherit tw-mr-2.5', {
              'dotted-underline': table.get('isAlias', false),
            })}
          >
            <Truncated text={table.get('displayName')} />
          </RouterLink>
          <TableLabels table={table} />
        </div>
        <Description row={row} entity={ObjectTypes.TABLE} readOnly={!canWrite} />
      </div>
    </span>
  );
};
