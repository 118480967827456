import { useEffect, useState } from 'react';
import type { ReactNode } from 'react';

import { IconButton, Tooltip } from '@keboola/design';

import { Truncated } from '@/react/common';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';

type Props = {
  name: string;
  readOnly: boolean;
  onSave: (name: string) => Promise<unknown>;
  label?: ReactNode;
};

const FolderName = (props: Props) => {
  const [folderName, setFolderName] = useState(props.name);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    if (!isEditing && props.name !== folderName) {
      setFolderName(props.name);
    }
  }, [isEditing, props.name, folderName]);

  if (props.readOnly) {
    return (
      <span className="folder-name tw-flex tw-items-center">
        <Truncated text={props.label || folderName} twoLines />
      </span>
    );
  }

  if (isEditing) {
    return (
      <InlineEditTextInput
        isEditing
        className="tw-inline-flex tw-items-center"
        placeholder="Folder name"
        isValid={!!folderName.trim()}
        isChanged={props.name !== folderName}
        text={folderName}
        isSaving={isSaving}
        onEditChange={setFolderName}
        onEditCancel={() => setIsEditing(false)}
        onEditSubmit={() => {
          setIsSaving(true);

          return props.onSave(folderName.trim()).finally(() => setIsSaving(false));
        }}
      />
    );
  }

  return (
    <span className="folder-name tw-flex tw-items-center tw-gap-1">
      <Truncated text={props.label || folderName} twoLines />
      <Tooltip tooltip="Rename folder" placement="top">
        <IconButton
          variant="invisible"
          icon="pen"
          size="extra-small"
          className="tw-opacity-0 tw-transition-opacity tw-duration-200 group-hover:tw-opacity-100"
          onClick={(e) => {
            e.stopPropagation();

            setIsEditing(true);
            setFolderName(props.name);
          }}
        />
      </Tooltip>
    </span>
  );
};

export default FolderName;
