import type {
  VisualJobTask,
  VisualNotificationTask,
  VisualVariableTask,
} from '@/modules/flows-v2/builder/types';
import { Draggable } from './Draggable';
import { PhaseJobTask } from './PhaseJobTask';
import { PhaseNotificationTask } from './PhaseNotificationTask';
import { PhaseVariableTask } from './PhaseVariableTask';

export const PhaseTask = ({
  phaseId,
  task,
  readOnly,
}: {
  phaseId: string;
  task: VisualJobTask | VisualNotificationTask | VisualVariableTask;
  readOnly: boolean;
}) => {
  const phaseTask =
    task.variant === 'notification' ? (
      <PhaseNotificationTask task={task} readOnly={readOnly} />
    ) : task.variant === 'variable' ? (
      <PhaseVariableTask task={task} readOnly={readOnly} />
    ) : task.variant === 'job' ? (
      <PhaseJobTask task={task} readOnly={readOnly} />
    ) : null;

  if (!phaseTask) {
    return null;
  }

  return (
    <Draggable id={`${phaseId}:${task.id}`} disabled={readOnly}>
      {phaseTask}
    </Draggable>
  );
};
