import { resolveRouterLinkParams } from '@/modules/components/helpers';
import type { ParsedFQID } from '@/modules/lineage/fqid';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import type { TableData } from '@/modules/lineage/rfTypes';
import {
  bucketTabs,
  routeNames as storageRouteNames,
  tableTabs,
} from '@/modules/storage/constants';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import string from '@/utils/string';

export const constructBucketFqidFromTableNode = (node: TableData) => {
  return constructFQID({
    type: FQID_TYPES.bucket,
    data: { projectId: node.fqid.match(/\d+/)?.[0] ?? '', bucketId: node.bucket.id },
  });
};

const createHref = (params: ParsedFQID | Record<string, never>) => {
  switch (params.type) {
    case FQID_TYPES.config: {
      const linkParams = resolveRouterLinkParams(params.componentId, params.configId, null, true);

      return linkParams ? createUrlWithBasename(linkParams.to, linkParams.params) : null;
    }

    case FQID_TYPES.table: {
      return params.tableId
        ? createUrlWithBasename(storageRouteNames.TABLE, {
            bucketId: string.strLeftBack(params.tableId, '.'),
            bucketTab: bucketTabs.OVERVIEW,
            tableName: string.strRightBack(params.tableId, '.'),
            tableTab: tableTabs.OVERVIEW,
          })
        : null;
    }

    case FQID_TYPES.bucket: {
      return params.bucketId
        ? createUrlWithBasename(storageRouteNames.BUCKET, {
            bucketId: params.bucketId,
            bucketTab: bucketTabs.OVERVIEW,
          })
        : null;
    }

    default:
      return null;
  }
};

export const prepareHref = (params: ParsedFQID | Record<string, never>) => {
  const href = createHref(params);

  if (!href || !params.projectId) {
    return null;
  }

  return window.location.origin + href.replace(/\d+/, params.projectId ?? '');
};
