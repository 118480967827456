import type React from 'react';

import type { AutomationDetail } from '@/api/routes/aiService';
import { useAutomation } from './queries';

type WithAutomationProps = {
  automationId?: string;
  automation?: AutomationDetail;
};

export function withAutomation<P extends WithAutomationProps>(
  WrappedComponent: React.ComponentType<P>,
) {
  return function WithAutomation(
    props: Omit<P, keyof WithAutomationProps> & {
      automationId?: string;
      isAutomationView?: boolean;
    },
  ) {
    const { data: automation } = useAutomation(props.automationId, props.isAutomationView);

    return <WrappedComponent {...(props as P)} {...(props.isAutomationView && { automation })} />;
  };
}
