import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { IconButton } from '@keboola/design';

import UpdateStorageModal from './UpdateStorageModal';

class UpdateStorageButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  render() {
    return (
      <>
        <IconButton
          variant="inline"
          className="tw-ml-3 tw-inline-flex tw-h-max tw-align-middle"
          onClick={() => this.setState({ showModal: true })}
          icon="pen"
        />
        <UpdateStorageModal
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          project={this.props.project}
          onSubmit={this.props.onSubmit}
        />
      </>
    );
  }
}

UpdateStorageButton.propTypes = {
  project: PropTypes.instanceOf(Map).isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default UpdateStorageButton;
