import { useState } from 'react';
import Promise from 'bluebird';
import type { Map } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_ORCHESTRATOR,
  KEBOOLA_SANDBOXES,
} from '@/constants/componentIds';
import { FlowDeletingWarning } from '@/modules/automations/FlowDeletingWarning';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import * as dataAppsActions from '@/modules/data-apps/actions';
import { deleteFlow } from '@/modules/flows/actions';
import { isFlowComponent } from '@/modules/flows-v2/helpers';
import SandboxesActions from '@/modules/sandboxes/Actions';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { hasDisabledRestore } from '@/modules/trash/utils';
import { updateFlowTasks } from '@/react/common/actions';
import ConfirmModal from '@/react/common/ConfirmModal';
import DeleteConfigConfirmBody from '@/react/common/DeleteConfigConfirmBody';
import FlowsValidationBox from '@/react/common/FlowsValidationBox';
import { usedFlows } from '@/react/common/helpers';
import { getRealComponentId } from './helpers';

type SelectedRows = { config: Map<string, any>; component: Map<string, any> }[];

const multiDeleteNotification = (rows: SelectedRows) => {
  return ApplicationActionCreators.sendNotification({
    type: 'info',
    message: 'Selected configurations have been moved to Trash.',
    button: {
      label: 'Restore',
      action: () => {
        return Promise.each(rows, (row) => {
          const { component, config } = row;

          return InstalledComponentsActionCreators.restoreConfiguration(component, config, {
            skipNotification: true,
          });
        }).then(() => {
          ApplicationActionCreators.sendNotification({
            type: 'success',
            message: 'Selected configurations have been restored.',
          });
        });
      },
    },
  });
};

type Props = {
  show: boolean;
  onHide: () => void;
  rows: SelectedRows;
  flows: Map<string, any>;
  entity?: string;
};

const DeleteConfigurationsModal = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const singleRow = props.rows.length === 1;
  const flowConfigIds = props.rows
    .filter((row) => getRealComponentId(row.config, row.component) === KEBOOLA_ORCHESTRATOR)
    .map((row) => row.config.get('id'));

  return (
    <ConfirmModal
      closeAfterResolve
      show={props.show}
      onHide={props.onHide}
      icon="trash"
      buttonType="danger"
      title={singleRow ? 'Delete configuration' : 'Delete selected'}
      buttonLabel={singleRow ? 'Delete' : 'Delete selected'}
      text={
        singleRow ? (
          <DeleteConfigConfirmBody
            flows={props.flows}
            config={props.rows[0].config}
            componentId={getRealComponentId(props.rows[0].config, props.rows[0].component)}
          />
        ) : (
          <>
            {flowConfigIds.length > 0 && <FlowDeletingWarning configIds={flowConfigIds} />}
            <p>
              Are you sure you want to delete the selected {props.entity || 'configuration'}
              s?
            </p>
            <FlowsValidationBox
              flows={props.flows}
              configurations={props.rows.map((row) => {
                return {
                  componentId: getRealComponentId(row.config, row.component),
                  config: row.config,
                };
              })}
            />
          </>
        )
      }
      isLoading={isLoading}
      onConfirm={() => {
        setIsLoading(true);
        return Promise.each(props.rows, (row) => {
          return Promise.each(
            usedFlows(props.flows, row.component.get('id'), row.config),
            (flow: Map<string, any>) => {
              return updateFlowTasks(flow, row.component.get('id'), row.config);
            },
          ).then(() => {
            const componentId = row.component.get('id');
            const configId = row.config.get('id');
            const sandboxId = row.config.getIn(['configuration', 'parameters', 'id']);

            if (isFlowComponent(componentId)) {
              return deleteFlow(componentId, row.config, { notification: singleRow });
            }

            if (
              componentId === KEBOOLA_DATA_APPS &&
              !SandboxesStore.getSandbox(sandboxId).isEmpty()
            ) {
              return dataAppsActions.deleteApp(sandboxId);
            }

            if (
              componentId === KEBOOLA_SANDBOXES &&
              !SandboxesStore.getSandbox(sandboxId).isEmpty()
            ) {
              return SandboxesActions.deleteSandbox(sandboxId, configId);
            }

            return InstalledComponentsActionCreators.deleteConfiguration(
              getRealComponentId(row.config, row.component),
              configId,
              { transition: false, notification: singleRow },
            );
          });
        })
          .then(() => {
            props.onHide();

            if (
              !singleRow &&
              props.rows.every((row) => !hasDisabledRestore(row.component.get('id')))
            ) {
              multiDeleteNotification(props.rows);
            }
          })
          .finally(() => setIsLoading(false));
      }}
    />
  );
};

export default DeleteConfigurationsModal;
