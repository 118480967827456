import { Fragment, useState } from 'react';
import type { ReactNode } from 'react';
import { Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { removeAliasTableFilter, setAliasTableFilter } from '@/modules/storage/actions';
import ConfirmModal from '@/react/common/ConfirmModal';
import AliasFilterModal from './AliasFilterModal';

type Props = {
  table: Map<string, any>;
  canEdit: boolean;
  hasTableAliases: boolean;
  settingAliasFilter: boolean;
  removingAliasFilter: boolean;
};

const TableAliasFilter = (props: Props) => {
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const filter = props.table.get('aliasFilter', Map());

  const renderLabel = (filter: Map<string, any>) => {
    if (!filter.count()) {
      return <span className="ml-auto">No filter set</span>;
    }

    return (
      <span className="ml-auto">
        Where <b>{filter.get('column')}</b> {whereOperator(filter)} {renderValues(filter)}
      </span>
    );
  };

  const renderEditButton = (filter: Map<string, any>) => {
    if (!props.canEdit) {
      return null;
    }

    return (
      <span>
        <Tooltip
          tooltip={
            props.hasTableAliases
              ? 'Can be modified only for tables without existing aliases'
              : filter.count() > 0
                ? 'Edit filter'
                : 'Set filter'
          }
          type={props.hasTableAliases ? 'explanatory' : 'action'}
          placement="top"
        >
          <IconButton
            variant="inline"
            className="tw-inline-flex tw-h-max"
            onClick={() => setShowUpdateModal(true)}
            disabled={
              props.settingAliasFilter || props.removingAliasFilter || props.hasTableAliases
            }
            icon="pen"
            isLoading={props.settingAliasFilter}
          />
        </Tooltip>
        <AliasFilterModal
          key={props.table.get('lastChangeDate')}
          show={showUpdateModal}
          onSubmit={handleSubmit}
          onHide={() => setShowUpdateModal(false)}
          isSaving={props.settingAliasFilter}
          table={props.table}
        />
      </span>
    );
  };

  const renderDeleteButton = (filter: Map<string, any>) => {
    if (!props.canEdit || !filter.count()) {
      return null;
    }

    return (
      <span>
        <Tooltip tooltip="Remove filter" placement="top">
          <IconButton
            variant="inline"
            className="tw-inline-flex tw-h-max"
            onClick={() => setShowRemoveModal(true)}
            disabled={props.settingAliasFilter || props.removingAliasFilter}
            icon="trash"
            isLoading={props.removingAliasFilter}
          />
        </Tooltip>
        <ConfirmModal
          show={showRemoveModal}
          onHide={() => setShowRemoveModal(false)}
          icon="trash"
          title="Remove alias filter"
          text={<p>Are you sure you want to remove the filter?</p>}
          buttonLabel="Delete"
          buttonType="danger"
          onConfirm={handleRemoveFilter}
        />
      </span>
    );
  };

  const handleSubmit = (params: Record<string, any>) => {
    const tableId = props.table.get('id');
    return setAliasTableFilter(tableId, params);
  };

  const handleRemoveFilter = () => {
    const tableId = props.table.get('id');
    return removeAliasTableFilter(tableId);
  };

  const whereOperator = (filter: Map<string, any>) => {
    const operator = filter.get('operator');
    const valuesCount = filter.get('values').count();

    if (operator === 'eq' && valuesCount <= 1) {
      return 'is';
    }

    if (operator === 'eq' && valuesCount > 1) {
      return 'in';
    }

    if (operator === 'ne' && valuesCount <= 1) {
      return 'is not';
    }

    return 'not in';
  };

  const renderValues = (filter: Map<string, any>) => {
    return filter
      .get('values')
      .map((value: string, index: number) => {
        if (value === '') {
          return <code key={index}>%_EMPTY_STRING_%</code>;
        }

        if (value === ' ') {
          return <code key={index}>%_SPACE_CHARACTER_%</code>;
        }

        return <Fragment key={index}>{value}</Fragment>;
      })
      .reduce((prev: ReactNode | ReactNode[], curr: ReactNode) => [prev, ', ', curr]);
  };

  return (
    <>
      <span className="tw-inline-flex tw-items-center tw-gap-3">
        Alias filter
        {renderEditButton(filter)}
        {renderDeleteButton(filter)}
      </span>
      <span className="font-normal text-muted">{renderLabel(filter)}</span>
    </>
  );
};

export default TableAliasFilter;
