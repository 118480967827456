import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { ButtonInline, HelpBlock } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  requirements: string;
};

export const PackagesHelpBlock = ({ requirements }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      <HelpBlock className="tw-flex tw-gap-1">
        <ButtonInline onClick={open} className="tw-text-xs" type="button" variant="link">
          Click Here
        </ButtonInline>
        <span>
          to view the packages and their versions from the <strong>requirements.txt</strong> file
          for this data app.
        </span>
      </HelpBlock>

      <Modal show={isOpen} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Environment Dependencies</Modal.Title>
          <ModalIcon icon="book-blank" color="blue" bold />
        </Modal.Header>

        <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
          <p className="tw-mb-0 tw-text-neutral-800">
            Here is the list of installed library versions for this data app (obtained using the pip
            freeze command). You can use this list to verify the exact versions of the dependencies
            required for this project.
          </p>

          <CodeEditor
            className="tw-max-h-none"
            value={requirements}
            options={{
              mode: 'text/plain',
              lineNumbers: true,
              readOnly: true,
              cursorHeight: 0,
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
