import type { Map } from 'immutable';

import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import { SharedWithYouBuckets } from './SharedWithYouBuckets';
import { TabNavigation } from './TabNavigation';

const SharedWithYou = () => {
  const {
    admins,
    currentAdmin,
    currentProjectId,
    sapiToken,
    urlTemplates,
    allBuckets,
    sharedBuckets,
    linkedBuckets,
  } = useStores(
    () => ({
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      currentProjectId: ApplicationStore.getCurrentProjectId(),
      sapiToken: ApplicationStore.getSapiToken(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      allBuckets: StorageBucketsStore.getAll() as Map<string, any>,
      sharedBuckets: StorageBucketsStore.getSharedBuckets() as Map<string, any>,
      linkedBuckets: StorageBucketsStore.getLinkedBuckets() as Map<string, any>,
    }),
    [],
    [ApplicationStore, StorageBucketsStore],
  );

  return (
    <>
      <TabNavigation />
      <SharedWithYouBuckets
        admins={admins}
        currentAdmin={currentAdmin}
        sharedBuckets={sharedBuckets}
        linkedBuckets={linkedBuckets}
        currentProjectId={currentProjectId}
        sapiToken={sapiToken}
        urlTemplates={urlTemplates}
        allBuckets={allBuckets}
      />
    </>
  );
};

export default SharedWithYou;
