import { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import { List, Map } from 'immutable';
import _ from 'underscore';

import { cn } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { COLLAPSED_CONFIGURATIONS } from '@/constants/localStorageKeys';
import { getFolderFromMetadata } from '@/modules/components/helpers';
import CollapseButton from '@/react/common/CollapseButton';
import ComponentBadges from '@/react/common/ComponentBadges';
import ComponentDetailLink from '@/react/common/ComponentDetailLink';
import ComponentIcon from '@/react/common/ComponentIcon';
import ComponentName from '@/react/common/ComponentName';
import ComponentType from '@/react/common/ComponentType';
import Table from '@/react/common/ConfigurationsTable/Table';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import { StorageDataForConfiguration } from '@/react/common/StorageData';
import useLocalStorage from '@/react/hooks/useLocalStorage';
import onClickSelectionCell from '@/utils/onClickSelectionCell';
import string from '@/utils/string';
import ActionButtons from './ActionButtons';
import CheckboxCell from './CheckboxCell';
import ConfigurationActions from './ConfigurationActions';
import ConfigurationNameWithDescription from './ConfigurationNameWithDescription';
import ConfigurationsFolderName from './ConfigurationsFolderName';
import { DEFAULT_FOLDER_NAME, MULTI_ACTIONS } from './constants';
import DeleteConfigurationsModal from './DeleteConfigurationsModal';
import { getRealComponentId } from './helpers';
import LastChange from './LastChange';
import LastChangeActionCell from './LastChangeActionCell';
import MoveConfigurationsModal from './MoveConfigurationsModal';
import LastUseActionCell from './RenderLastUseActionCell';
import UsedInCell from './UsedInCell';
import { useJobsLoader } from './useJobsLoader';

/** @type {any} */
const ConfigurationsTable = ({
  allConfigurations,
  configurations,
  availableConfigurations = Map(),
  notifications,
  component,
  currentAdmin,
  admins,
  readOnly,
  renderAdditionalActions = _.noop,
  additionalColumns = [],
  allowCreateConfig = false,
  hasFlows,
  hasNewQueue,
  isDevModeActive = false,
  showComponentIcon = false,
  showComponentDetailLink = false,
  componentsMetadata = Map(),
  expandedFolders = Map(),
  showMigrations = false,
  showData = false,
  showUsedIn = false,
  hideCommonActions = false,
  allComponents,
  tablesMetadataMap,
  latestJobs = null,
  forceShowAll = false,
  supportFolders = false,
  entity,
  customClasses = Map(),
  renderCustomConfigurationIcon,
  renderNameLabel,
  renderCustomLabel,
  renderAdditionalMultiActions,
  columnsHiddenForSmallerScreens = [],
  isLoading = false,
  className,
  getMultiActionDeleteDisabledReason,
}) => {
  const [isCollapsed, setIsCollapsed] = useLocalStorage(
    `${COLLAPSED_CONFIGURATIONS}-${component?.get('id')}`,
    false,
  );
  const [multiAction, setMultiAction] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);

  const ref = useJobsLoader({
    component,
    configurations,
    isCollapsed: !forceShowAll && isCollapsed,
    skipDelay: ['transformation', 'flow'].includes(entity),
    disable: !hasNewQueue || !latestJobs,
  });

  const flows = allConfigurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map());

  const columns = useMemo(() => {
    const customLastColumn = additionalColumns.find((column) => column.isLastColumn);

    const renderActions = (row, body) => {
      if (row.original.data.config?.get('isDummyItem')) {
        return (
          <div className="actions-container">
            <div className="not-actions">{body}</div>
            <div className="actions -tw-mr-1">
              <DeleteConfigurationButton
                mode="navigation"
                flows={flows}
                componentId={row.original.data.component?.get('id')}
                config={row.original.data.config}
              />
            </div>
          </div>
        );
      }

      return (
        <ConfigurationActions
          configuration={row.original.data.config}
          component={row.original.data.component}
          flows={flows}
          renderAdditionalActions={renderAdditionalActions}
          componentsMetadata={componentsMetadata}
          hasFlows={hasFlows}
          readOnly={readOnly}
          hasNewQueue={hasNewQueue}
          hideCommonActions={hideCommonActions}
          notifications={notifications}
          currentAdmin={currentAdmin}
          latestJob={row.original.job}
          {...(supportFolders && {
            onMove: () => {
              setMultiAction(MULTI_ACTIONS.MOVE);
              setSelectedRows([row.original.data]);
            },
          })}
        >
          {body}
        </ConfigurationActions>
      );
    };

    return [
      {
        sortingFn: 'sortByName',
        header: ({ table }) => {
          const rows = table.getRowModel().flatRows.filter((row) => !!row.original.data?.config);
          const isAllSelected = rows.every((row) => row.getIsSelected());
          const isSomeSelected = rows.some((row) => row.getIsSelected());

          const selectedConfigurations = table
            .getSelectedRowModel()
            .flatRows.map((row) => row.original.data)
            .filter((row) => !!row?.config);

          return (
            <div className="tw-inline-flex tw-items-center">
              {!readOnly && rows.length > 0 && (
                <div
                  onClick={onClickSelectionCell}
                  className="-tw-ml-6 tw-inline-flex tw-items-center tw-p-1 tw-pl-6 tw-pr-3"
                >
                  <CheckboxCell
                    tooltip={isAllSelected || isSomeSelected ? 'Deselect all' : 'Select all'}
                    checked={isAllSelected}
                    indeterminate={!isAllSelected && isSomeSelected}
                    onChange={() => {
                      const toggleState = !(isAllSelected || isSomeSelected);
                      table
                        .getRowModel()
                        .flatRows.filter((row) => {
                          return (
                            !row.original.data.config?.get('isUnselectable') &&
                            (!supportFolders || row.depth > 0)
                          );
                        })
                        .forEach((row) => row.toggleSelected(toggleState));
                    }}
                  />
                </div>
              )}
              <ActionButtons
                supportFolders={supportFolders}
                openDeleteModal={() => {
                  setMultiAction(MULTI_ACTIONS.DELETE);
                  setSelectedRows(selectedConfigurations);
                }}
                openMoveModal={() => {
                  setMultiAction(MULTI_ACTIONS.MOVE);
                  setSelectedRows(selectedConfigurations);
                }}
                renderAdditionalMultiActions={renderAdditionalMultiActions}
                selectedConfigurations={selectedConfigurations}
                entity={entity}
                disableDeleteReason={getMultiActionDeleteDisabledReason?.(selectedConfigurations)}
              />
            </div>
          );
        },
        cell: ({ row, inFolder }) => {
          const isSelected = row.getIsSelected() || row.getIsAllSubRowsSelected();
          const isSomeSelected = row.getIsSomeSelected();

          return (
            <div className={cn('tw-inline-flex tw-items-center', { 'tw-pl-7': !!inFolder })}>
              {!readOnly && (
                <div
                  onClick={onClickSelectionCell}
                  className={cn(
                    'tw-inline-flex tw-items-center tw-p-3',
                    !!inFolder ? '-tw-ml-12 tw-pl-12' : '-tw-ml-6 tw-pl-6',
                  )}
                >
                  <CheckboxCell
                    tooltip={
                      supportFolders && row.depth === 0
                        ? `${isSelected || isSomeSelected ? 'Deselect' : 'Select'} folder`
                        : `${isSelected ? 'Deselect' : 'Select'} ${entity || 'configuration'}`
                    }
                    checked={isSelected}
                    indeterminate={!isSelected && isSomeSelected}
                    onChange={(checked) => {
                      if (supportFolders && row.depth === 0) {
                        row.subRows.forEach((subRow) => {
                          subRow.toggleSelected(!(isSelected || isSomeSelected));
                        });
                      } else {
                        row.toggleSelected(checked);
                      }
                    }}
                    disabled={row.original.data.config?.get('isUnselectable') ?? false}
                  />
                </div>
              )}
              {supportFolders && row.depth === 0 ? (
                <ConfigurationsFolderName
                  name={row.original.data}
                  isExpanded={row.getIsExpanded()}
                  readOnly={readOnly}
                  rows={row.subRows}
                />
              ) : (
                <ConfigurationNameWithDescription
                  allConfigurations={allConfigurations}
                  configuration={row.original.data.config}
                  component={row.original.data.component}
                  readOnly={readOnly}
                  showComponentIcon={showComponentIcon}
                  customConfigurationIcon={renderCustomConfigurationIcon?.(row)}
                  nameLabel={renderNameLabel?.(row.original.data)}
                  customLabel={renderCustomLabel?.(row.original.data)}
                  hasFlows={hasFlows}
                  componentsMetadata={componentsMetadata}
                  isDevModeActive={isDevModeActive}
                />
              )}
            </div>
          );
        },
        accessorKey: 'data',
      },
      ...additionalColumns.filter(
        (column) => !column.isLastColumn && column.accessorKey !== 'run_results',
      ),
      showData &&
        tablesMetadataMap && {
          enableSorting: false,
          header: 'Data',
          cell: ({ row }) => {
            if (supportFolders && row.depth === 0) {
              return null;
            }

            return (
              <span onClick={(e) => e.stopPropagation()}>
                <StorageDataForConfiguration
                  tableMode
                  tablesMetadataMap={tablesMetadataMap}
                  component={row.original.data.component}
                  config={row.original.data.config}
                />
              </span>
            );
          },
          accessorKey: 'storage_data',
        },
      showUsedIn &&
        hasFlows && {
          enableSorting: false,
          accessorKey: 'used_in',
          header: 'Used In',
          cell: ({ row }) => {
            const { config, component } = row.original.data;

            if (!component || !config) {
              return null;
            }

            return (
              <UsedInCell
                component={component}
                config={config}
                componentsMetadata={componentsMetadata}
                flows={flows}
              />
            );
          },
        },
      {
        sortingFn: 'sortByLastChange',
        header: 'Last Change',
        cell: ({ row }) => {
          if (supportFolders && row.depth === 0) {
            return null;
          }

          if ((hasNewQueue && latestJobs) || customLastColumn) {
            return <LastChange configuration={row.original.data.config} admins={admins} />;
          }

          return <LastChangeActionCell admins={admins} row={row} renderAction={renderActions} />;
        },
        accessorKey: 'last_change',
      },
      ...additionalColumns.filter((column) => column.accessorKey === 'run_results'),
      hasNewQueue &&
        latestJobs && {
          sortingFn: 'sortByLastUse',
          header: 'Last Use',
          cell: ({ row }) => {
            if (supportFolders && row.depth === 0) {
              return null;
            }

            return <LastUseActionCell admins={admins} row={row} renderAction={renderActions} />;
          },
          accessorKey: 'job',
        },
      customLastColumn && {
        ...customLastColumn,
        cell: (props) => {
          if (
            props.row.original.data.config?.get('isCreating') ||
            props.row.original.data.config?.get('isDummyItem')
          ) {
            return customLastColumn.cell(props);
          }

          return renderActions(props.row, customLastColumn.cell(props));
        },
      },
    ].filter((column, index) => !!column && (!configurations.isEmpty() || index === 0));
  }, [
    additionalColumns,
    showData,
    showUsedIn,
    tablesMetadataMap,
    hasNewQueue,
    latestJobs,
    renderAdditionalActions,
    hasFlows,
    readOnly,
    isDevModeActive,
    hideCommonActions,
    componentsMetadata,
    supportFolders,
    configurations,
    notifications,
    flows,
    getMultiActionDeleteDisabledReason,
    renderAdditionalMultiActions,
    entity,
    allConfigurations,
    showComponentIcon,
    renderCustomConfigurationIcon,
    renderNameLabel,
    renderCustomLabel,
    currentAdmin,
    admins,
  ]);

  const memoizedRows = useMemo(() => {
    const restructureConfigurations = (configurations) =>
      configurations
        .map((configuration, configurationId) => {
          const componentData = configuration.get('component', component);
          const realComponentId = getRealComponentId(configuration, componentData);

          return {
            data: {
              config: configuration,
              component: componentData,
              id: `${realComponentId}-${configurationId}`,
            },
            job: latestJobs?.getIn([realComponentId, configurationId, 0], Map()),
          };
        })
        .toArray();

    if (supportFolders) {
      return configurations
        .map((configuration) => {
          const folder = getFolderFromMetadata(
            componentsMetadata.getIn([
              configuration.get('component', component).get('id'),
              configuration.get('id'),
            ]),
            DEFAULT_FOLDER_NAME,
          );

          return configuration.set('folder', folder);
        })
        .groupBy((configuration) => configuration.get('folder'))
        .map((configurations, folder) => {
          return {
            data: folder,
            subRows: restructureConfigurations(configurations),
          };
        })
        .toArray();
    }

    return restructureConfigurations(configurations);
  }, [component, componentsMetadata, configurations, latestJobs, supportFolders]);

  const toggleCollapse = () => {
    if (forceShowAll) {
      return;
    }

    setIsCollapsed(!isCollapsed);
    document.activeElement?.blur();
  };

  return (
    <>
      <div ref={ref} className={cn('box mbp-6', className)}>
        {showComponentDetailLink && (
          <div
            onClick={toggleCollapse}
            className={cn('component-name-above-table tw-flex tw-items-center tw-justify-between', {
              'btn-collapse-area': !forceShowAll,
            })}
          >
            <span className="tw-flex tw-items-center">
              <ComponentIcon component={component} size="48" className="tw-mr-4" />
              <div className="tw-flex tw-flex-col">
                <div className="tw-flex tw-items-center">
                  <ComponentDetailLink componentId={component.get('id')}>
                    <ComponentName className="line-height-24" component={component} />
                  </ComponentDetailLink>
                  <ComponentBadges flags={component.get('flags')} className="tw-ml-2" />
                </div>
                <ComponentType
                  noIcon
                  type={component.get('type')}
                  className="tw-mt-0.5 tw-text-sm tw-font-medium"
                />
              </div>
            </span>
            {!forceShowAll && (
              <CollapseButton
                entity="configurations"
                isCollapsed={isCollapsed}
                onToggle={toggleCollapse}
              />
            )}
          </div>
        )}
        {!forceShowAll && isCollapsed ? (
          <div className="collapsed-configurations clickable" onClick={toggleCollapse}>
            <span className="tw-font-semibold">{memoizedRows.length} </span>
            <span className="text-muted">
              {string.pluralize(memoizedRows.length, 'Configuration')}
            </span>
          </div>
        ) : (
          <Table
            columns={columns}
            data={memoizedRows}
            component={component}
            readOnly={readOnly}
            hasNewQueue={hasNewQueue}
            hasFlows={hasFlows}
            showMigrations={showMigrations}
            allComponents={allComponents}
            allowCreateConfig={allowCreateConfig}
            entity={entity}
            customClasses={customClasses}
            configurations={configurations}
            forceShowAll={forceShowAll}
            supportFolders={supportFolders}
            expandedFolders={expandedFolders}
            columnsHiddenForSmallerScreens={columnsHiddenForSmallerScreens}
            isLoading={isLoading}
          />
        )}
      </div>
      <DeleteConfigurationsModal
        show={multiAction === MULTI_ACTIONS.DELETE}
        onHide={() => setMultiAction('')}
        rows={selectedRows}
        flows={flows}
        entity={entity}
      />
      <MoveConfigurationsModal
        show={multiAction === MULTI_ACTIONS.MOVE}
        onHide={() => setMultiAction('')}
        rows={selectedRows}
        component={component}
        availableConfigurations={availableConfigurations}
        componentsMetadata={componentsMetadata}
        isDevModeActive={isDevModeActive}
      />
    </>
  );
};

ConfigurationsTable.propTypes = {
  currentAdmin: PropTypes.instanceOf(Map).isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  allConfigurations: PropTypes.instanceOf(Map).isRequired,
  configurations: PropTypes.instanceOf(Map).isRequired,
  readOnly: PropTypes.bool.isRequired,
  hasNewQueue: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
  notifications: PropTypes.instanceOf(List),
  availableConfigurations: PropTypes.instanceOf(Map),
  component: PropTypes.instanceOf(Map),
  allComponents: PropTypes.instanceOf(Map),
  tablesMetadataMap: PropTypes.instanceOf(Map),
  customClasses: PropTypes.instanceOf(Map),
  latestJobs: PropTypes.instanceOf(Map),
  componentsMetadata: PropTypes.instanceOf(Map),
  expandedFolders: PropTypes.instanceOf(Map),
  renderAdditionalActions: PropTypes.func,
  renderAdditionalMultiActions: PropTypes.func,
  renderCustomConfigurationIcon: PropTypes.func,
  renderNameLabel: PropTypes.func,
  renderCustomLabel: PropTypes.func,
  additionalColumns: PropTypes.array,
  columnsHiddenForSmallerScreens: PropTypes.array,
  isLoading: PropTypes.bool,
  allowCreateConfig: PropTypes.bool,
  showComponentDetailLink: PropTypes.bool,
  showMigrations: PropTypes.bool,
  showComponentIcon: PropTypes.bool,
  showData: PropTypes.bool,
  showUsedIn: PropTypes.bool,
  hideCommonActions: PropTypes.bool,
  forceShowAll: PropTypes.bool,
  supportFolders: PropTypes.bool,
  isDevModeActive: PropTypes.bool,
  entity: PropTypes.string,
  className: PropTypes.string,
};

/** @type {any} */
const MemoizedConfigurationTable = memo(ConfigurationsTable, shallowEqualImmutable);

export default MemoizedConfigurationTable;
