import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import { RouterLink } from '@/react/common';

const ConfigurationCopiedNotification = createReactClass({
  propTypes: {
    name: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    configId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    rowId: PropTypes.string,
    hasFlows: PropTypes.bool.isRequired,
    entity: PropTypes.string,
    action: PropTypes.string,
    onClick: PropTypes.func,
  },

  render() {
    const linkParams = resolveRouterLinkParams(
      this.props.componentId,
      this.props.configId,
      this.props.rowId,
      this.props.hasFlows,
    );

    if (!linkParams) {
      return null;
    }

    return (
      <>
        {this.props.entity || `${this.props.rowId ? 'Row' : 'Configuration'}`}{' '}
        <RouterLink to={linkParams.to} params={linkParams.params} onClick={this.props.onClick}>
          {this.props.name}
        </RouterLink>{' '}
        has been {this.props.action || 'copied'}.
      </>
    );
  },
});

export default ConfigurationCopiedNotification;
