import type { Map } from 'immutable';

import type { AutomationMessage } from '@/api/routes/aiService';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import useStores from '@/react/hooks/useStores';

const InteractiveMessageResponse = ({ messageObject }: { messageObject: AutomationMessage }) => {
  const store = useStores(
    () => ({
      allComponents: ComponentsStore.getAll() as Map<string, any>,
      allTables: StorageTablesStore.getAll() as Map<string, any>,
    }),
    [],
    [ComponentsStore, StorageTablesStore],
  );

  if (!messageObject.data.selectedValue && !messageObject.data.selectedValues?.length) {
    return 'Not Relevant';
  }

  switch (messageObject.type) {
    case 'componentSelectionResponse':
      return messageObject.data.selectedValues
        ?.map((id) => store.allComponents.getIn([id, 'name']) ?? id)
        .join(', ');
    case 'sourceTableSelectionResponse':
      return messageObject.data.selectedValues
        ?.map((id) => {
          return store.allTables.getIn([id, 'displayName']) ?? id;
        })
        .join(', ');
    case 'intentConfirmationResponse':
      return messageObject.data.selectedValue;
    default:
      return null;
  }
};

export default InteractiveMessageResponse;
