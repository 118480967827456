import { createDataScienceClient } from './clients/dataScience';
import { createEncryptionClient } from './clients/encryption';
import { createManagementClient } from './clients/management';
import { createSandboxesClient } from './clients/sandboxes';
import { createStorageClient } from './clients/storage';
import type { ClientInitOptions } from './clients/types';
import { createVaultClient } from './clients/vault';
import { ErrorMessage } from './constants';
import { assert } from './utils';

export type ApiClientOptions = {
  token: {
    storageApi: string;
    managementApi?: string;
  };
} & Omit<ClientInitOptions, 'token'>;

function assertIsClientDefined<T>(value: T | null | undefined): asserts value is T {
  assert(value, ErrorMessage.UNINITIALIZED_CLIENT);
}

type StorageClient = ReturnType<typeof createStorageClient>;
type StackInfo = Awaited<ReturnType<StorageClient['getStackInfo']>>['stackInfo'];
type VaultClient = ReturnType<typeof createVaultClient>;
type DataScienceClient = ReturnType<typeof createDataScienceClient>;
type ManagementClient = ReturnType<typeof createManagementClient>;
type SandboxesClient = ReturnType<typeof createSandboxesClient>;
type EncryptionClient = ReturnType<typeof createEncryptionClient>;

export class ApiClient {
  private _stackInfo: StackInfo | null = null;
  private _storage: StorageClient | null = null;
  private _vault: VaultClient | null = null;
  private _dataScience: DataScienceClient | null = null;
  private _management: ManagementClient | null = null;
  private _encryption: EncryptionClient | null = null;
  private _sandboxes: SandboxesClient | null = null;

  get storage() {
    assertIsClientDefined(this._storage);
    return this._storage;
  }

  get vault() {
    assertIsClientDefined(this._vault);
    return this._vault;
  }

  get stackInfo() {
    assertIsClientDefined(this._stackInfo);
    return this._stackInfo;
  }

  get dataScience() {
    assertIsClientDefined(this._dataScience);
    return this._dataScience;
  }

  get management() {
    assertIsClientDefined(this._management);
    return this._management;
  }

  get encryption() {
    assertIsClientDefined(this._encryption);
    return this._encryption;
  }

  get sandboxes() {
    assertIsClientDefined(this._sandboxes);
    return this._sandboxes;
  }

  /**
   * Initialize the API clients provided by Keboola.
   * @param options
   */
  async init(options: ApiClientOptions) {
    const storageClient = createStorageClient({
      baseUrl: options.baseUrl,
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._management = createManagementClient({
      baseUrl: options.baseUrl,
      token: options.token.managementApi,
      callbacks: options.callbacks,
    });

    const { stackInfo, serviceMap } = await storageClient.getStackInfo({
      exclude: 'componentDetails',
    });

    this._vault = createVaultClient({
      baseUrl: serviceMap.vault,
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._dataScience = createDataScienceClient({
      baseUrl: serviceMap['data-science'],
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._encryption = createEncryptionClient({
      baseUrl: serviceMap.encryption,
      callbacks: options.callbacks,
    });

    this._sandboxes = createSandboxesClient({
      baseUrl: serviceMap.sandboxes,
      token: options.token.storageApi,
      callbacks: options.callbacks,
    });

    this._stackInfo = stackInfo;
    this._storage = storageClient;
  }
}
