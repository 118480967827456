import '@/utils/codemirror/setup';

import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Controlled as CodeMirror } from 'react-codemirror2';
import ReactDOM from 'react-dom';

import { DOCUMENTATION_URL } from '@keboola/constants';
import { HelpBlock, KeyCode, Link } from '@keboola/design';

import nextTick from '@/utils/nextTick';

class CodeEditor extends Component {
  constructor(props) {
    super(props);

    this.editorDidMount = this.editorDidMount.bind(this);
    this.onBeforeChange = this.onBeforeChange.bind(this);
    this.isHelpBlockVisible = this.isHelpBlockVisible.bind(this);
    this.editorRef = createRef();
  }

  isHelpBlockVisible() {
    const { options, withAutocomplete, withToggleComment, help, helpPostfix } = this.props;
    if (options?.readOnly === true) return false;

    return withAutocomplete || withToggleComment || help || helpPostfix;
  }

  render() {
    return (
      <>
        <CodeMirror
          value={this.props.value}
          options={{
            ...(this.props.withSearch
              ? { enableSearch: true }
              : {
                  extraKeys: {
                    'Ctrl-F': false,
                    'Cmd-F': false,
                    'Ctrl-G': false,
                    'Cmd-G': false,
                    'Shift-Ctrl-G': false,
                    'Shift-Cmd-G': false,
                    ...this.props.options?.extraKeys,
                  },
                }),
            ...this.props.options,
          }}
          editorDidMount={this.editorDidMount}
          onBeforeChange={this.onBeforeChange}
          className={this.props.className}
        />
        {this.isHelpBlockVisible() ? (
          <HelpBlock className="tw-mt-2 tw-flex tw-flex-wrap tw-gap-1">
            {this.props.help}
            {this.props.withAutocomplete ? (
              <div className="tw-flex tw-gap-1">
                <span>
                  Use <KeyCode>Ctrl+Space</KeyCode> or <KeyCode>Option+Space</KeyCode> to trigger
                </span>
                <Link href={DOCUMENTATION_URL.AUTOCOMPLETION}>autocomplete</Link>.
              </div>
            ) : null}

            {this.props.withToggleComment ? (
              <div>
                Use <KeyCode>Ctrl+/</KeyCode> or <KeyCode>Command+/</KeyCode> to toggle comments.
              </div>
            ) : null}
            {this.props.helpPostfix}
          </HelpBlock>
        ) : null}
      </>
    );
  }

  onBeforeChange(editor, data, value) {
    if (this.props.onChange) {
      ReactDOM.flushSync(() => this.props.onChange(value));
    }
  }

  editorDidMount(editor) {
    this.editorRef.current = editor;

    if (this.props.id) {
      editor.getInputField().id = this.props.id;
    }

    if (this.props.value) {
      nextTick(() => editor.refresh());
    }

    if (this.props.autoFocus) {
      editor.focus();
    }

    if (this.props.editorDidMount) {
      this.props.editorDidMount(editor);
    }

    if (this.props.withSearch) {
      editor.execCommand('find');
    }

    if (editor.options.mode === 'application/json' && !editor.options.readOnly) {
      editor.setOption('lint', true);
    }
  }
}

CodeEditor.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.object,
  onChange: PropTypes.func,
  editorDidMount: PropTypes.func,
  help: PropTypes.node,
  helpPostfix: PropTypes.node,
  autoFocus: PropTypes.bool,
  withAutocomplete: PropTypes.bool,
  withToggleComment: PropTypes.bool,
  withSearch: PropTypes.bool,
  className: PropTypes.string,
};

export default CodeEditor;
