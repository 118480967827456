import type { ChangeEvent } from 'react';
import { FormControl } from 'react-bootstrap';
import type { Map } from 'immutable';

import { FormGroup, HelpBlock, Label } from '@keboola/design';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import DocumentationLink from './DocumentationLink';

const Certificate = (props: {
  readOnly: boolean;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
}) => {
  return (
    <CollapsibleBox
      childrenClassName="tw-flex tw-flex-col tw-gap-4"
      title="Certificates"
      titleSuffix={<DocumentationLink path="configuration/api/#ca-certificate" />}
    >
      <FormGroup>
        <Label htmlFor="caCertificate">CA Certificate</Label>
        <FormControl
          id="caCertificate"
          rows={3}
          type="text"
          componentClass="textarea"
          disabled={props.readOnly}
          placeholder="Custom certificate authority bundle in crt/pem format."
          value={props.editing.get('caCertificate')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            props.setEditing(props.editing.set('caCertificate', e.target.value));
          }}
        />
        <HelpBlock className="tw-mt-1">
          It allows connecting to an HTTPS server with an untrusted/self-signed certificate. The
          value is not the server&apos;s certificate, but the certificate of the certificate
          authority used to generate the server certificate.
        </HelpBlock>
      </FormGroup>
      <FormGroup>
        <Label htmlFor="clientCertificate">Client Certificate</Label>
        <FormControl
          id="clientCertificate"
          rows={3}
          type="text"
          componentClass="textarea"
          disabled={props.readOnly}
          placeholder="Value is the client certificate, followed by the private key. Both are in crt/pem format."
          value={props.editing.get('clientCertificate')}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            props.setEditing(props.editing.set('clientCertificate', e.target.value));
          }}
        />
        <HelpBlock className="tw-mt-1">
          This is required if the server requires two-way SSL authentication, so in addition to
          verifying the server, the server verifies the client.
        </HelpBlock>
      </FormGroup>
    </CollapsibleBox>
  );
};

export default Certificate;
