import { Children } from 'react';
import type { ReactNode } from 'react';
import type { RouteProps } from 'react-router';
import { Route } from 'react-router';

type NamedRouteProps = {
  name?: string;
  childRoutes?: NamedRouteProps[];
} & RouteProps;

const routesMap: Record<string, string> = {};

export const resolvePathname = (name?: string, params?: Record<string, any> | null) => {
  if (name && name in routesMap) {
    return formatRoute(routesMap[name], params);
  }

  return name ?? '';
};

export const createRoutesForNamedURLResolver = (rootRoute: NamedRouteProps) => {
  const NamedRoute = (props: NamedRouteProps) => <Route {...props} />;
  const composeRoutes = (route: NamedRouteProps) => {
    const path = route.path || route.name;

    if (!route.childRoutes || route.childRoutes.length === 0) {
      return <NamedRoute key={route.name} name={route.name} path={path} />;
    }

    return (
      <NamedRoute key={route.name} name={route.name} path={path}>
        {route.childRoutes.map(composeRoutes)}
      </NamedRoute>
    );
  };

  mergeRouteTree(
    <Route path="/">
      <NamedRoute path={rootRoute.path} name={rootRoute.name} />
      {rootRoute.childRoutes?.map(composeRoutes)}
    </Route>,
  );
};

const formatRoute = (routePath: string, params?: Record<string, string> | null) => {
  const tokens: Record<string, string> = {};

  if (params) {
    for (const paramName in params) {
      const paramRegex = new RegExp('(/|^):' + paramName + '\\??(/|$)');

      routePath = routePath.replace(paramRegex, (_, g1, g2) => {
        tokens[paramName] = encodeURIComponent(params[paramName]);
        return `${g1}<${paramName}>${g2}`;
      });
    }
  }

  return routePath
    .replace(/(\/[^/]*\?)/g, '') // remove unresolved optional segments
    .replace(/<(.*?)>/g, (_, token) => tokens[token]) // replace tokens with their values
    .replace(/\/+/g, '/') // remove repeating slashes
    .replace(/\/+$/, '') // remove trailing slashes
    .replace(/^$/, '/'); // replace empty path with root
};

const mergeRouteTree = (routeTree: ReactNode, prefix = '') => {
  const routes = Array.isArray(routeTree) ? routeTree : [routeTree];

  routes.forEach((route) => {
    if (!route) return;

    let newPrefix = '';

    if (route.props) {
      const routePath = route.props.path || '';
      newPrefix = (
        routePath.at(0) === '/' ? routePath : [prefix, routePath].filter(Boolean).join('/')
      ).replace(/\/+/g, '/');

      if (route.props.name) {
        routesMap[route.props.name] = newPrefix;
      }

      Children.forEach(route.props.children, (child) => mergeRouteTree(child, newPrefix));
    }
  });
};
