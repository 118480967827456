import { useEffect } from 'react';
import { ScrollRestoration } from 'react-router';

import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';

export const ScrollManager = () => {
  const scroll = useStores(
    () => RoutesStore.getRouterState().getIn(['location', 'query', 'scroll'], 0),
    [],
    [RoutesStore],
  );

  useEffect(() => {
    if (scroll > 0) {
      window.requestAnimationFrame(() => window.scrollTo(0, scroll));
    }
  }, [scroll]);

  return <ScrollRestoration />;
};
