import { Badge } from '@keboola/design';

const ActiveCountBadge = ({
  totalCount,
  activeCount,
}: {
  totalCount: number;
  activeCount: number;
}) => (
  <Badge variant={activeCount === 0 ? 'gray' : 'blue'}>{`${activeCount} / ${totalCount}`}</Badge>
);

export default ActiveCountBadge;
