import { skipToken, useQuery } from '@tanstack/react-query';

import type { SandboxDetail } from '@keboola/api-client';
import { useApiClient } from '@keboola/api-client';

import { storageApiKey } from '@/constants/queryKeys';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { LinkLoginButton } from './LinkLoginButton';
import { SSOLoginButton } from './SSOLoginButton';
import { createSandboxUrl } from './utils';

type Props = {
  sandbox?: SandboxDetail;
  className?: string;
  disabled?: boolean;
};

export const ConnectToSandboxButton = ({ sandbox, className, disabled }: Props) => {
  const api = useApiClient();

  const physicalId = sandbox?.physicalId;
  const branchId =
    sandbox?.branchId ?? (DevBranchesStore.getDefaultBranchId() as unknown as string);
  const isSnowflakeSandbox = sandbox?.type === 'snowflake';
  const url = createSandboxUrl(sandbox);

  const workspaceQuery = useQuery({
    queryKey: physicalId
      ? storageApiKey.workspaceDetail({ workspaceId: physicalId, branchId })
      : [],
    queryFn: physicalId
      ? ({ signal, queryKey }) => api.storage.workspaces.getWorkspace(queryKey[1].path, signal)
      : skipToken,
    retry: false,
    enabled: isSnowflakeSandbox,
  });

  const isSsoLoginAvailable = workspaceQuery.data?.connection?.ssoLoginAvailable ?? false;

  return isSsoLoginAvailable && physicalId ? (
    <SSOLoginButton id={physicalId} branchId={branchId} className={className} disabled={disabled} />
  ) : (
    <LinkLoginButton
      className={className}
      url={url}
      isLoading={workspaceQuery.isFetching}
      disabled={!url || disabled}
    />
  );
};
