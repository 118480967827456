import type { Map } from 'immutable';
import _ from 'underscore';

import { Link } from '@keboola/design';

import { bucketTabs, routeNames } from '@/modules/storage/constants';
import { createUrl } from '@/utils/router/createUrl';

export const ExternalProjectBucketLink = ({
  bucketId,
  projectId,
  projectName,
  urlTemplates,
}: {
  bucketId: string;
  projectId: string;
  projectName: string;
  urlTemplates: Map<string, string>;
}) => {
  const projectPath = _.template(urlTemplates.get('project'))({ projectId });
  const bucketUrl = createUrl(routeNames.BUCKET, { bucketId, bucketTab: bucketTabs.OVERVIEW });

  return (
    <>
      <Link href={projectPath}>{projectName}</Link>
      {' / '}
      <Link href={`${projectPath}${bucketUrl}`}>{bucketId}</Link>
    </>
  );
};
