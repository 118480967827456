import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { Alert, FormGroup } from '@keboola/design';

import { parseSecretsFromToml } from '@/modules/data-apps/helpers';
import CodeEditor from '@/react/common/CodeEditor';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FilesDropZone from '@/react/common/FilesDropZone';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmit: (secrets: Record<string, string | string[]>) => Promise<void>;
};

export const UploadSecretsModal = ({ show, onHide, onSubmit }: Props) => {
  const [file, setFile] = useState<File | null>(null);
  const [contents, setContents] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Secrets File</Modal.Title>
        <ModalIcon icon="upload" color="blue" bold />
      </Modal.Header>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!contents) {
            setError('Could not read the file');
            return;
          }
          const parsed = parseSecretsFromToml(contents);
          if (Object.keys(parsed).length === 0) {
            setError('No secrets found in the file');
            return;
          }
          setIsSaving(true);
          onSubmit(parsed)
            .then(() => onHide())
            .finally(() => setIsSaving(false));
        }}
      >
        <Modal.Body>
          <p>
            Uploading a secrets file will automatically create new secrets if they do not already
            exist. If a secret with the same name is already present, its value will be updated.
            Existing secrets that don&#39;t match any names in the uploaded file will be preserved.
          </p>
          <FormGroup className="tw-gap-4">
            {!!error && <Alert variant="error">{error}</Alert>}
            <FilesDropZone
              allowedType={{ 'application/toml': ['.toml'] }}
              errorSelect={!!error}
              maxFileSize={1000 * 1000}
              onDrop={(files) => {
                const reader = new FileReader();
                const file = files[0];

                setFile(file);

                if (!file) {
                  setError(null);
                  setContents(null);
                  return;
                }

                reader.onload = function (e) {
                  const contents = e?.target?.result as string;
                  if (contents) {
                    setContents(contents);
                  }
                };
                reader.readAsText(file);
              }}
              files={file}
            />
            {contents && (
              <CodeEditor
                value={contents}
                onChange={(newContents: string) => {
                  if (!newContents) {
                    setFile(null);
                  }
                  setContents(newContents);
                }}
                options={{
                  mode: 'text/x-toml',
                  lineNumbers: false,
                }}
              />
            )}
          </FormGroup>
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveButtonType="submit"
            saveLabel="Save secrets"
            isDisabled={!contents || isSaving}
            isSaving={isSaving}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};
