import { createContext, useContext } from 'react';

import { ErrorMessage } from '../constants';
import type { createDevApiClient } from '../devClient';
import { assert } from '../utils';

const context = createContext<ReturnType<typeof createDevApiClient> | null>(null);

export const Provider = context.Provider;

export const useDevApiClient = () => {
  const devApiClient = useContext(context);
  assert(devApiClient, ErrorMessage.REACT_MISSING_DEV_CONTEXT_PROVIDER);
  return devApiClient;
};
