import Promise from 'bluebird';
import type { List, Seq } from 'immutable';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import routerAction from '@/actions/RouterActionCreators';
import { apiClient } from '@/apiClient';
import type { sortEntities } from '@/constants';
import dispatcher from '@/Dispatcher';
import { ActionTypes as globalActionTypes } from '@/modules/components/Constants';
import MetadataActionCreators from '@/modules/components/MetadataActionCreators';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import storageApi from '@/modules/components/StorageApi';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prefixTagsWithDevBranchId } from '@/modules/dev-branches/helpers';
import RoutesStore from '@/stores/RoutesStore';
import HttpError from '@/utils/errors/HttpError';
import SimpleError from '@/utils/errors/SimpleError';
import tableHasBeenExportedNotification from './components/tableHasBeenExportedNotification';
import storageExplorerApi from './api';
import { actionTypes, bucketTabs, filesLimit, jobsLimit, routeNames } from './constants';
import type { Backend, Bucket, ExportTypes } from './types';

const updateSearchQuery = (query = '') => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_SEARCH_QUERY,
    query,
  });
};

const updateSearchFilters = (searchFilters = Map()) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_SEARCH_FILTERS,
    searchFilters,
  });
};

const toggleContextFilter = () => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_CONTEXT_FILTER,
  });
};

const toggleExpandedBucket = (bucketId: string, isExpanded: boolean) => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_EXPAND_BUCKET,
    bucketId,
    isExpanded,
  });
};

const toggleShowAllBuckets = (show: boolean) => {
  dispatcher.handleViewAction({
    type: actionTypes.TOGGLE_SHOW_ALL_BUCKETS,
    show,
  });
};

const updateBucketsSort = (sort: {
  entity: (typeof sortEntities)[keyof typeof sortEntities];
  order: 1 | -1;
}) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_BUCKETS_SORT,
    sort,
  });
};

const errorNotification = (message: unknown) => {
  if (!_.isString(message)) {
    throw message;
  }

  ApplicationActionCreators.sendNotification({
    type: 'error',
    message: message,
  });
};

const tokenVerify = () => {
  return StorageActionCreators.tokenVerify();
};

const loadJobs = () => {
  return StorageActionCreators.loadJobs({ limit: jobsLimit });
};

const loadJob = (jobId: string) => {
  return StorageActionCreators.loadJob(jobId);
};

const loadMoreJobs = (offset: number) => {
  return StorageActionCreators.loadMoreJobs({ limit: jobsLimit, offset });
};

const uploadFile = (id: string, file: File, params?: { isPermanent: boolean; tags: string[] }) => {
  return StorageActionCreators.uploadFile(id, file, params);
};

const deleteFile = (fileId: number) => {
  return StorageActionCreators.deleteFile(fileId)
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const deleteFiles = (files: number[]) => {
  return Promise.each(files, deleteFile)
    .then(loadFiles)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'All files have been removed.',
      });
    });
};

const loadFiles = (params?: Record<string, any>) => {
  return StorageActionCreators.loadFilesForce({ ...params, limit: filesLimit })
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const loadMoreFiles = (params: { offset: number }) => {
  return StorageActionCreators.loadMoreFiles({ ...params, limit: filesLimit })
    .catch(HttpError, (error) => {
      throw error.message;
    })
    .catch(errorNotification);
};

const filterFiles = (query: string) => {
  dispatcher.handleViewAction({
    type: globalActionTypes.STORAGE_FILES_RESET,
  });

  routerAction.updateQuery({ ...(query && { q: query }) });
  RoutesStore.getRouter().replaceTo(routeNames.FILES, null, { ...(query && { q: query }) });
};

const updateFilesSearchQuery = (query: string) => {
  dispatcher.handleViewAction({
    type: actionTypes.UPDATE_FILES_SEARCH_QUERY,
    query,
  });
};

const createBucket = (newBucket: Bucket, transitionToRoute = routeNames.BUCKET) => {
  return StorageActionCreators.createBucket(newBucket).then((bucket) => {
    if (transitionToRoute) {
      return RoutesStore.getRouter().transitionTo(transitionToRoute, {
        bucketId: bucket.id,
        bucketTab: bucketTabs.OVERVIEW,
      });
    }

    return fromJS(bucket);
  });
};

const registerExternalBucket = (
  backend: Backend,
  bucketName: string,
  path: string[],
  isSnowflakeSharedDatabase: boolean,
) => {
  return StorageActionCreators.registerExternalBucket({
    stage: 'in',
    backend: backend,
    name: bucketName,
    displayName: bucketName,
    path,
    isSnowflakeSharedDatabase,
  });
};

const refreshExternalBucket = (bucketId: string) => {
  return StorageActionCreators.refreshExternalBucket(bucketId);
};

const updateBucket = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.updateBucket(bucketId, params);
};

const deleteBucket = (
  bucketId: string,
  options: { forceDelete: boolean; transition?: boolean },
) => {
  return StorageActionCreators.deleteBucket(bucketId, options);
};

const deleteBucketsAndTables = (selected: Map<string, any>[]) => {
  const buckets = selected.filter((row: Map<string, any>) => row.has('stage'));
  const tables = selected.filter((row: Map<string, any>) => {
    return (
      !row.has('stage') &&
      !buckets.some((bucket: Map<string, any>) => bucket.get('id') === row.getIn(['bucket', 'id']))
    );
  });

  return Promise.each(buckets, (bucket: Map<string, any>) => {
    return storageApi
      .deleteBucket(bucket.get('id'), { force: true, async: true })
      .then(StorageActionCreators.waitForFinishedStorageJob);
  })
    .then(() => {
      return Promise.each(tables, (table) =>
        storageApi.deleteTable(table.get('id'), { force: true }),
      );
    })
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: 'Selected items have been removed.',
      });

      return StorageActionCreators.loadBucketsAndTablesForce();
    });
};

const createTable = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.createTable(bucketId, params);
};

const createTableDefinition = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.createTableDefinition(bucketId, params);
};

const createAliasTable = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.createAliasTable(bucketId, params);
};

const updateTable = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.updateTable(tableId, params);
};

const deleteTable = (tableId: string, onTableDeleteHook?: () => void) => {
  // @ts-expect-error deleteTable method is in JS
  return StorageActionCreators.deleteTable(tableId, { forceDelete: true, onTableDeleteHook });
};

const deleteMultipleTables = (tablesIds: Seq<any, any>) => {
  return StorageActionCreators.deleteMultipleTables(tablesIds, { forceDelete: true });
};

const deleteMultipleColumns = (
  tableId: string,
  columns: Seq<any, any> | string[],
  options: { force: boolean },
) => {
  return StorageActionCreators.deleteMultipleColumns(tableId, columns, options).then(() => {
    return StorageActionCreators.loadTableDetailForce(tableId);
  });
};

const truncateTable = (tableId: string) => {
  return StorageActionCreators.truncateTable(tableId)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Table <b>{tableId}</b> has been truncated.
          </>
        ),
      });
    })
    .catch(errorNotification);
};

const truncateMultipleTables = (tablesIds: Seq<any, any>) => {
  return StorageActionCreators.truncateMultipleTables(tablesIds);
};

const pullTable = (tableId: string) => {
  return StorageActionCreators.pullTable(tableId)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => (
          <>
            Table <b>{tableId}</b> has been cloned.
          </>
        ),
      });

      return StorageActionCreators.loadTableDetailForce(tableId).then(
        (table: { bucket: { id: string } }) => {
          return StorageActionCreators.loadBucketDetailForce(table.bucket.id);
        },
      );
    })
    .catch(errorNotification);
};

const exportTables = (tables: List<any> | Map<string, any>, type: ExportTypes) => {
  const tablesIds = tables.map((table) => table.get('id')) as List<any> | Map<string, any>;

  dispatcher.handleViewAction({
    type: globalActionTypes.STORAGE_TABLES_EXPORT,
    tablesIds,
  });

  return storageExplorerApi
    .exportTables(tablesIds, type)
    .then(StorageActionCreators.waitForFinishedStorageJob)
    .catch((error) => {
      dispatcher.handleViewAction({
        type: globalActionTypes.STORAGE_TABLES_EXPORT_ERROR,
        tablesIds,
      });

      throw error?.message || error;
    })
    .then((response) => {
      dispatcher.handleViewAction({
        type: globalActionTypes.STORAGE_TABLES_EXPORT_SUCCESS,
        tablesIds,
      });

      return storageApi.getFilesWithRetry({
        // @ts-expect-error not typed
        runId: response.runId,
        'tags[]': prefixTagsWithDevBranchId(
          ['storage-merged-export'],
          DevBranchesStore.getCurrentId(),
        ),
      });
    })
    .then((files) => {
      if (!files || files.length === 0) {
        throw new SimpleError('Loading files for download failed', 'Please try again.');
      }

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: tableHasBeenExportedNotification(tables),
        link: {
          download: true,
          href: files[0].url,
          label: 'Download Now',
        },
        timeout: 0,
      });
    });
};

const loadTable = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.loadTable(tableId, params);
};

const loadTableDetail = (tableId: string) => {
  return StorageActionCreators.loadTableDetail(tableId);
};

const loadBucketDetail = (bucketId: string) => {
  return StorageActionCreators.loadBucketDetail(bucketId);
};

const setAliasTableFilter = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.setAliasTableFilter(tableId, params).catch(HttpError, (error) => {
    throw error.message;
  });
};

const removeAliasTableFilter = (tableId: string) => {
  return StorageActionCreators.removeAliasTableFilter(tableId).catch(HttpError, (error) => {
    throw error.message;
  });
};

const createTablePrimaryKey = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.createTablePrimaryKey(tableId, params).catch(errorNotification);
};

const removeTablePrimaryKey = (tableId: string) => {
  return StorageActionCreators.removeTablePrimaryKey(tableId).catch(errorNotification);
};

const restoreUsingTimeTravel = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.restoreUsingTimeTravel(bucketId, params).catch(errorNotification);
};

const loadSnapshots = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.loadTableSnapshots(tableId, params);
};

const createSnapshots = (tableIds: Seq<any, any> | string[], params: Record<string, any>) => {
  return StorageActionCreators.createSnapshots(tableIds, params);
};

const createTableFromSnapshot = (bucketId: string, params: Record<string, any>) => {
  return StorageActionCreators.createTableFromSnapshot(bucketId, params).catch(errorNotification);
};

const deleteSnapshot = (tableId: string, snapshotId: string) => {
  return StorageActionCreators.deleteSnapshot(tableId, snapshotId);
};

const dataPreview = (tableId: string, params: Record<string, any>) => {
  return storageApi.tableDataJsonPreview(tableId, params);
};

const deleteTableRows = (tableId: string, params: Record<string, any>) => {
  return (
    apiClient.storage.tables
      .deleteTableRows(tableId, params)
      .then(StorageActionCreators.waitForFinishedStorageJob)
      // @ts-expect-error not typed
      .then(({ results }) => {
        return StorageActionCreators.loadTableDetailForce(tableId).then(() => {
          ApplicationActionCreators.sendNotification({
            message: () => {
              return (
                <>
                  A <b>{results.deletedRows} rows</b> have been successfully deleted.
                </>
              );
            },
          });

          return null;
        });
      })
  );
};

const addTableColumn = (tableId: string, params: Record<string, any>) => {
  return StorageActionCreators.addTableColumn(tableId, params).catch(errorNotification);
};

const addFileTag = (fileId: number, tag: string) => {
  return StorageActionCreators.addFileTag(fileId, tag).catch(errorNotification);
};

const deleteFileTag = (fileId: number, tag: string) => {
  return StorageActionCreators.deleteFileTag(fileId, tag).catch(errorNotification);
};

const saveColumnMetadata = (columnId: string, keyValues: Map<string, any>) => {
  return MetadataActionCreators.saveMetadataSet('column', columnId, keyValues).catch(
    errorNotification,
  );
};

const deleteColumnMetadata = (columnId: string, metadataId: string) => {
  return MetadataActionCreators.deleteMetadata('column', columnId, metadataId).catch(
    errorNotification,
  );
};

export {
  tokenVerify,
  createBucket,
  registerExternalBucket,
  refreshExternalBucket,
  updateBucket,
  deleteBucket,
  deleteBucketsAndTables,
  createTable,
  createTableDefinition,
  createAliasTable,
  updateTable,
  deleteTable,
  deleteMultipleTables,
  deleteMultipleColumns,
  setAliasTableFilter,
  removeAliasTableFilter,
  createTablePrimaryKey,
  removeTablePrimaryKey,
  truncateTable,
  truncateMultipleTables,
  pullTable,
  exportTables,
  loadTable,
  loadTableDetail,
  loadBucketDetail,
  loadJob,
  loadJobs,
  loadMoreJobs,
  uploadFile,
  deleteFile,
  deleteFiles,
  loadFiles,
  loadMoreFiles,
  filterFiles,
  toggleContextFilter,
  updateSearchQuery,
  updateSearchFilters,
  updateFilesSearchQuery,
  restoreUsingTimeTravel,
  loadSnapshots,
  createSnapshots,
  createTableFromSnapshot,
  deleteSnapshot,
  dataPreview,
  deleteTableRows,
  addTableColumn,
  saveColumnMetadata,
  deleteColumnMetadata,
  toggleExpandedBucket,
  updateBucketsSort,
  toggleShowAllBuckets,
  addFileTag,
  deleteFileTag,
};
