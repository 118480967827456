import { useState } from 'react';
import type { FormEvent } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';

type Props = {
  show: boolean;
  table: Map<string, any>;
  columns: string[];
  isDeleting: boolean;
  tableAliases: Map<string, any>[];
  tableLinks: Map<string, any>[];
  onConfirm: (forceDelete: boolean) => Promise<void>;
  onHide: () => void;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
};

const DeleteColumnsModal = (props: Props) => {
  const [forceDelete, setForceDelete] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const hasAliasesOrLinks = props.tableAliases.length > 0 || props.tableLinks.length > 0;
  const isDisabled = hasAliasesOrLinks && !forceDelete;

  const handleHide = () => {
    setForceDelete(false);
    setError(null);
    props.onHide();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    props
      .onConfirm(forceDelete)
      .then(handleHide)
      .catch((error) => {
        setError(error);
      });
  };

  return (
    <Modal show={props.show} onHide={handleHide}>
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Columns</Modal.Title>
          <ModalIcon.Trash />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert variant="error" className="tw-mb-5">
              {error}
            </Alert>
          )}
          <p>Are you sure you want to delete the selected columns?</p>
          <DevBranchStorageWarning
            message="The columns will also be deleted from the table in production."
            hasProductionEntity={!isCreatedInDevBranch(props.table.get('bucket'))}
          />
          <div className="well">
            <p className="text-muted small">These columns will be deleted:</p>
            <ul className="list-unstyled mb-0">
              {props.columns.map((columnName) => (
                <li key={columnName}>{columnName}</li>
              ))}
            </ul>
          </div>
          {hasAliasesOrLinks && (
            <div>
              <Checkbox
                checked={forceDelete}
                onChange={() => setForceDelete((prevState) => !prevState)}
              >
                Delete columns from table and alias tables
              </Checkbox>
              <TableAliasesAndLinks
                sapiToken={props.sapiToken}
                urlTemplates={props.urlTemplates}
                tableAliases={props.tableAliases}
                tableLinks={props.tableLinks}
                onLinkClick={props.onHide}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isDisabled={isDisabled}
            isSaving={props.isDeleting}
            saveLabel={props.isDeleting ? 'Deleting...' : 'Delete'}
            saveStyle="danger"
            saveButtonType="submit"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default DeleteColumnsModal;
