import { useState } from 'react';

import { Icon } from '@keboola/design';

import PromptInput from '@/react/common/AI/PromptInput';
import PredefinedExamples from './Examples';
import NewAutomationProgressModal from './NewAutomationProgressModal';
import { redirectToAutomation, useCreateAutomation } from './queries';

const NewAutomation = ({
  subtitle,
  showHint,
  showExamples,
  onSubmit,
}: {
  subtitle?: string;
  showHint?: boolean;
  showExamples?: boolean;
  onSubmit?: () => void;
}) => {
  const [prompt, setPrompt] = useState('');
  const { mutateAsync: createAutomation } = useCreateAutomation();
  const [isPending, setIsPending] = useState(false);

  const handleSubmit = async (message: string) => {
    if (isPending) return;

    setIsPending(true);

    if (onSubmit) {
      onSubmit();
    }

    if (message !== prompt) {
      setPrompt(message);
    }

    try {
      const automation = await createAutomation(message);
      redirectToAutomation(automation);
    } finally {
      setIsPending(false);
    }
  };

  return (
    <div className="tw-relative">
      <div className="tw-peer">
        <p className="tw-mb-6 tw-text-neutral-500">{subtitle}</p>
        <PromptInput
          highlight
          multiLine
          prompt={prompt}
          onChange={setPrompt}
          onSubmit={() => handleSubmit(prompt.trim())}
          isLoading={isPending}
          isDisabled={isPending || !prompt.trim()}
          isDisabledInput={isPending}
          loadingLabel="Processing..."
          placeholder="e.g. I'd like to see how different restaurants from my chain perform"
          label={
            <>
              Build it <Icon icon="angle-right" className="tw-ml-2 !tw-text-sm" />
            </>
          }
        />
      </div>
      {showExamples && <PredefinedExamples onClick={handleSubmit} disabled={isPending} />}
      {showHint && (
        <p className="tw-mb-0 tw-mt-3 tw-text-xs tw-text-neutral-400">
          What is your <span className="tw-text-neutral-800">intent</span>? Please provide as much
          detail as possible, including your{' '}
          <span className="tw-text-neutral-800">data source</span>,{' '}
          <span className="tw-text-neutral-800">destination</span>, and any data that needs to be{' '}
          <span className="tw-text-neutral-800">transformed</span>. The more details you can
          provide, the better I can assist you!
        </p>
      )}
      <NewAutomationProgressModal show={isPending} />
    </div>
  );
};

export default NewAutomation;
