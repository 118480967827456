import { Label, Switch } from '@keboola/design';

import type { EditTaskProperties, VisualVariableTask } from '@/modules/flows-v2/builder/types';

export const VariableDetail = ({
  task,
  onChange,
  readOnly,
}: {
  task: VisualVariableTask;
  onChange: (properties: EditTaskProperties) => void;
  readOnly: boolean;
}) => {
  return (
    <div className="tw-flex tw-items-center tw-gap-2">
      <Switch
        id="variable-enabled"
        disabled={readOnly}
        checked={task.enabled}
        onChange={() => onChange([{ keysPath: ['enabled'], value: !task.enabled }])}
      />
      <Label htmlFor="variable-enabled">Variable Enabled</Label>
    </div>
  );
};
