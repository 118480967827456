import { useMemo } from 'react';
import type { KeyboardEvent, ReactNode } from 'react';
import { autocompletion, completeAnyWord } from '@codemirror/autocomplete';
import { jsonParseLinter } from '@codemirror/lang-json';
import { linter } from '@codemirror/lint';
import { EditorView, keymap } from '@codemirror/view';
import { type Extension } from '@uiw/react-codemirror';
import { EditorState } from '@uiw/react-codemirror';

import { keyCodes } from '../../constants/keyCodes';
import { cn } from '../../utils';
import { HelpBlock } from '../HelpBlock';

import { AutocompleteHelp } from './AutocompleteHelp';
import { ButtonToolbar } from './ButtonToolbar';
import { DiffEditor } from './DiffEditor';
import type { LANGUAGE_MODE } from './helpers';
import { getBaseThemeStyles, getLanguageMode } from './helpers';
import { SimpleEditor } from './SimpleEditor';
import { ToggleComponentHelp } from './ToggleComponentHelp';

type Props = {
  value: string;
  onChange: (value: string) => void;
  mode?: LANGUAGE_MODE;
  previousValue?: string;
  placeholder?: string;
  showDiff?: boolean;
  readOnly?: boolean;
  autocompletions?: string[];
  extraKeys?: Record<string, () => void>;
  withAutocomplete?: boolean;
  withToggleCommentHint?: boolean;
  withAutofocus?: boolean;
  completeAny?: boolean;
  help?: ReactNode;
  helpPostfix?: ReactNode;
  renderAdditionalButtons?: (value?: string) => ReactNode;
  className?: string;
};

export const CodeEditor = ({
  value,
  onChange,
  mode = 'application/json',
  previousValue = '',
  placeholder = '',
  showDiff = false,
  readOnly = false,
  autocompletions = [],
  extraKeys = {},
  withAutocomplete = false,
  withToggleCommentHint = false,
  withAutofocus = false,
  completeAny = false,
  help = null,
  helpPostfix = null,
  renderAdditionalButtons,
  className,
}: Props) => {
  const commonExtensions: Extension[] = useMemo(() => {
    return [
      getLanguageMode(mode),
      mode === 'application/json' && linter(jsonParseLinter()),
      EditorView.lineWrapping,
      EditorState.languageData.of(() => [
        { autocomplete: completeAny ? completeAnyWord : autocompletions },
      ]),
      keymap.of(
        Object.entries(extraKeys).map(([key, run]) => ({
          key,
          run: () => {
            if (typeof run === 'string') return run;

            run();
            return true;
          },
        })),
      ),
      autocompletion({ activateOnTyping: completeAny }),
      EditorView.theme(getBaseThemeStyles(showDiff)),
    ].filter(Boolean) as Extension[];
  }, [showDiff, autocompletions, completeAny, extraKeys, mode]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cn(
        'tw-relative tw-flex tw-min-h-0 tw-flex-col [&_.cm-mergeViewEditor]:tw-overflow-visible [&_.cm-mergeViewEditors]:tw-h-full [&_.cm-mergeView]:tw-h-full [&_>div]:tw-h-full [&_>div]:tw-overflow-auto [&_>div]:tw-bg-neutral-50',
        { 'tw-mt-5': !!renderAdditionalButtons },
        className,
      )}
      onKeyDown={(event: KeyboardEvent) => {
        if (event.key === keyCodes.ESCAPE) {
          event.stopPropagation();
        }
      }}
    >
      <div className="tw-pointer-events-none tw-absolute tw-z-10 tw-flex !tw-h-auto tw-w-full tw-translate-y-[calc(-100%_+_8px)] !tw-overflow-visible !tw-bg-transparent">
        {showDiff && (
          <div className="tw-flex-1">
            <ButtonToolbar value={previousValue}>
              {renderAdditionalButtons?.(previousValue)}
            </ButtonToolbar>
          </div>
        )}
        <div className="tw-flex-1">
          <ButtonToolbar value={value}>{renderAdditionalButtons?.(value)}</ButtonToolbar>
        </div>
      </div>
      {showDiff ? (
        <DiffEditor
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          placeholder={placeholder}
          commonExtensions={commonExtensions}
          previousValue={previousValue}
        />
      ) : (
        <SimpleEditor
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          readOnly={readOnly}
          commonExtensions={commonExtensions}
          autoFocus={withAutofocus}
        />
      )}
      {!readOnly && (withAutocomplete || withToggleCommentHint || help || helpPostfix) && (
        <HelpBlock className="tw-mt-2 tw-flex tw-flex-wrap tw-gap-1">
          {help}
          {withAutocomplete ? <AutocompleteHelp /> : null}
          {withToggleCommentHint ? <ToggleComponentHelp /> : null}
          {helpPostfix}
        </HelpBlock>
      )}
    </div>
  );
};
