import { useMemo } from 'react';

import type { AppNode, NextConditionWithId } from '@/modules/flows-v2/builder/types';
import Select from '@/react/common/Select';
import { prepareGoToOptions } from './helpers';

const CONTINUE_TO_PLACEHOLDER = '_continue_to_';

const GO_TO_OPTIONS = [
  { label: 'Continue To', value: CONTINUE_TO_PLACEHOLDER },
  { label: 'End Flow', value: null },
];

export const GoToSelect = ({
  id,
  parentIds,
  nodes,
  condition,
  onChange,
  readOnly,
}: {
  id: string;
  parentIds: string[];
  nodes: AppNode[];
  condition: NextConditionWithId;
  onChange: (goto: string | null) => void;
  readOnly: boolean;
}) => {
  const options = useMemo(() => {
    return prepareGoToOptions(nodes, parentIds, id);
  }, [nodes, parentIds, id]);

  return (
    <div className="tw-flex tw-w-full tw-items-center tw-gap-3">
      <Select
        clearable={false}
        searchable={false}
        disabled={readOnly}
        value={condition.goto !== null ? CONTINUE_TO_PLACEHOLDER : null}
        options={GO_TO_OPTIONS}
        onChange={(value: string) => onChange(value === '' ? null : value)}
        className="tw-w-full"
      />
      {condition.goto !== null && (
        <Select
          allowCreate
          disabled={readOnly}
          placeholder="Select or create phase"
          promptTextCreator={(inputValue) => `Create new phase "${inputValue}"`}
          value={condition.goto === CONTINUE_TO_PLACEHOLDER ? '' : condition.goto}
          options={options}
          onChange={onChange}
          className="tw-w-full"
        />
      )}
    </div>
  );
};
