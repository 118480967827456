import { KeboolaHttpHeader } from '../../constants';
import {
  ApiError,
  type ApiResponse,
  createGenericFetchClient,
  defaultValidateStatus,
  HttpContentType,
  HttpHeader,
  type MiddlewareFn,
} from '../../fetchClient';
import { createVerifyClient } from '../verify';
import type { ManagementToken } from '../verify/types';

type ManagementTokenWithStringToken = { token: string } & ManagementToken;

export class ManagementClientAuthError extends ApiError {
  title: string;
  message: string;
  constructor(title: string, message: string, res: ApiResponse) {
    super(res);
    this.title = title;
    this.message = message;
  }
}

export const isManagementClientAuthError = (error: unknown): error is ManagementClientAuthError =>
  error instanceof ManagementClientAuthError;

const createAuth = (baseUrl: string) => {
  const fetchClient = createGenericFetchClient({ baseUrl });

  const createCurrentUserSessionToken = async (accessToken: string) => {
    const headers = new Headers({ [KeboolaHttpHeader.MANAGEMENT_API_TOKEN]: accessToken });
    const { data } = await fetchClient.post<ManagementTokenWithStringToken>(
      '/manage/current-user/session-token',
      {},
      { headers },
    );
    return data.token;
  };

  const createAdminAccountSessionToken = async () => {
    const { data } = await fetchClient.post<ManagementTokenWithStringToken>(
      '/admin/account/session-token',
      {},
      {
        // transform valid response, to invalid and throw custom error
        validateStatus: (apiResponse) => {
          const { response } = apiResponse;

          // If the response is "302 Found", then response type is "text/html", because backend detected
          // that session expired and redirected user to login screen
          const isTextContent = response.headers
            .get(HttpHeader.CONTENT_TYPE)
            ?.includes(HttpContentType.TEXT_HTML);

          if (isTextContent)
            return new ManagementClientAuthError(
              'Your session expired',
              'You will be logged out automatically.',
              apiResponse,
            );

          return defaultValidateStatus(apiResponse);
        },
      },
    );
    return data.token;
  };

  return {
    createCurrentUserSessionToken,
    createAdminAccountSessionToken,
  };
};

export const createAuthMiddleware = ({
  accessToken,
  baseUrl,
}: {
  baseUrl: string;
  accessToken?: string;
}): MiddlewareFn => {
  const auth = createAuth(baseUrl);
  const verify = createVerifyClient();

  let token: string | undefined = accessToken;
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  const isDevelopment = process.env.NODE_ENV === 'development';

  return async (request, next) => {
    if (!!token) {
      const isValid = await verify.managementApiToken(baseUrl, token);
      if (!isValid) {
        // in dev env we use manage token with type "admin" to create new manage token with type "session"
        // in production we create manage token with type "session" directly, since we are authorized
        // using PHP session
        token = isDevelopment
          ? await auth.createCurrentUserSessionToken(token)
          : await auth.createAdminAccountSessionToken();
      }
    } else {
      token = await auth.createAdminAccountSessionToken();
    }

    request.request.headers.set(KeboolaHttpHeader.MANAGEMENT_API_TOKEN, token);
    return next(request);
  };
};
