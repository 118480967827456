import type { Location } from 'react-router';
import { useLocation } from 'react-router';

export const useIsActiveByPathName = () => {
  const location = useLocation();

  return (pathName: string, indexOnly = false): boolean =>
    isActiveByPathName(location, pathName, indexOnly);
};

export const isActiveByPathName = (
  { pathname }: Location,
  pathName: string,
  indexOnly = false,
): boolean => {
  const pathNameWithLeadingSlash = pathName.startsWith('/') ? pathName : '/' + pathName;

  return indexOnly
    ? pathname === pathNameWithLeadingSlash
    : pathname.startsWith(pathNameWithLeadingSlash);
};
