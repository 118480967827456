import { useState } from 'react';
import type Bluebird from 'bluebird';

import type { ComponentConfig } from '@keboola/api-client';
import { Button, FormGroup, Label } from '@keboola/design';

import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import CodeEditorPreview from '@/react/common/CodeEditorPreview';
import SaveButtons from '@/react/common/SaveButtons';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  componentId: string;
  configId: string;
  onSave: (value: string) => Bluebird<any>;
  isLoading?: boolean;
};

const defaultOptions: Record<string, unknown> = {
  mode: 'text/x-python',
  placeholder: '-- Your code goes here',
};

const getCodeMirrorOptions = (isReadOnly: boolean) => {
  if (isReadOnly) {
    return {
      ...defaultOptions,
      cursorHeight: 0,
      readOnly: true,
    };
  }

  return {
    ...defaultOptions,
    hintOptions: {
      completeSingle: false,
      container: document.querySelector('.full-screen-modal.full-screen-editor'),
    },
  };
};

export const CodeField = ({ componentId, configId, onSave, isLoading = false }: Props) => {
  const configImmutable = InstalledComponentsStore.getConfig(componentId, configId);
  const config = configImmutable.toJS() as ComponentConfig<'data-app'>;
  const code = config.configuration.parameters?.script?.[0] ?? '';

  const [isOpen, setIsOpen] = useState(false);
  const [codeValue, setCodeValue] = useState(code);

  const isCodeEdited = code !== codeValue;
  const isReadOnly = ApplicationStore.isReadOnly();
  const isCodeAvailable = !!code;
  const isEditable = !isCodeAvailable && !isReadOnly;
  const codeMirrorOptions = getCodeMirrorOptions(isReadOnly);

  const handleSave = () => {
    return onSave(codeValue);
  };

  const resetCode = () => {
    setCodeValue(code);
  };

  const open = () => {
    resetCode();
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isEditable && (
        <div className="tw-flex tw-justify-center">
          <Button onClick={open}>Add Code</Button>
        </div>
      )}

      {isCodeAvailable && (
        <FormGroup>
          <Label htmlFor="deployment-code-preview">Code</Label>
          <CodeEditorPreview
            id="deployment-code-preview"
            mode="text/x-python"
            readOnly={isReadOnly}
            code={code}
            onClick={open}
          />
        </FormGroup>
      )}

      {isOpen && (
        <CodeEditorModal
          withAutocomplete
          editorKey={`data-app-${configId}`}
          title="Code"
          value={codeValue}
          isChanged={isCodeEdited}
          onChange={setCodeValue}
          onSave={handleSave}
          onReset={resetCode}
          onClose={close}
          renderAdditionalButtons={() => (
            <SaveButtons
              disabled={isReadOnly}
              isSaving={isLoading}
              isChanged={isCodeEdited}
              onSave={handleSave}
              onReset={resetCode}
            />
          )}
          codeMirrorOptions={codeMirrorOptions}
        />
      )}
    </>
  );
};
