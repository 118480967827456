import { useState } from 'react';
import type { KeyboardEvent } from 'react';
import type { Map } from 'immutable';

import type { ComponentConfig } from '@keboola/api-client';
import { Alert, Button, HelpBlock, Icon, Label, Switch, Tooltip } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import type { ExistingApp } from '@/api/routes/dataScienceService';
import date from '@/date';
import { updateApp } from '@/modules/data-apps/actions';
import {
  AUTO_SLEEP_NOTE,
  DEFAULT_EXPIRATION,
  EXPIRATION_PATH,
  EXPIRATION_PATH_FALLBACK,
  MINIMUM_EXPIRTION,
  SIZE_PATH,
} from '@/modules/data-apps/constants';
import { isAppConfigured, prepareExpiration } from '@/modules/data-apps/helpers';
import WorkspaceSizeControl from '@/modules/sandboxes/components/WorkspaceSizeControl';
import { SANDBOX_SIZES, SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import { RowActionMenuItem } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import InfoTooltip from '@/react/common/InfoTooltip';
import { EXPIRATION_OPTIONS } from '@/react/common/TimeoutControl/constants';
import TimeoutControl from '@/react/common/TimeoutControl/TimeoutControl';

const DeployDataApp = (props: {
  mode: 'sidebar' | 'menuitem';
  config: Map<string, any>;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  app?: ExistingApp;
  onKeyDown?: (event: KeyboardEvent) => void;
}) => {
  const [size, setSize] = useState(SANDBOX_SIZES.TINY);
  const [expiration, setExpiration] = useState(DEFAULT_EXPIRATION);
  const [updateDependencies, setUpdateDependencies] = useState(false);

  const [deploying, setDeploying] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCustomExpiration, setCustomExpiration] = useState(false);

  const configJs = props.config.toJS() as ComponentConfig<'data-app'>;
  const isFreezeActive = configJs.configuration.parameters?.dataApp?.freezeDependencies ?? false;
  const versionsExist = !!configJs.state?.data_app?.['requirements.txt'];

  const isConfigured = isAppConfigured(props.config);

  const isDisabled = props.readOnly || !isConfigured || deploying;

  const handleSetExpiration = (value: number, customExpiration: boolean) => {
    setExpiration(value);
    setCustomExpiration(customExpiration);
  };

  const handleDeploy = () => {
    const config = props.config
      .setIn(SIZE_PATH, size)
      .setIn(EXPIRATION_PATH, expiration)
      .deleteIn(EXPIRATION_PATH_FALLBACK);

    setDeploying(true);
    return updateApp(props.app, config, 'running', updateDependencies)
      .then(() => {
        ApplicationActionCreators.sendNotification({
          message: () => {
            return (
              <>
                <strong>{config.get('name')}</strong> data app is starting.
              </>
            );
          },
        });

        // prevent another deployment for 10 seconds
        setTimeout(() => setDeploying(false), 10000);
      })
      .catch(() => setDeploying(false));
  };

  const handleClick = () => setShowConfirmModal(true);

  const label = `${!props.app?.url ? 'Deploy' : 'Start'} Data App`;

  const renderDeployAction = () => {
    if (props.mode === 'sidebar') {
      return (
        <Button block disabled={isDisabled} onClick={handleClick}>
          <Icon icon="play-circle" />
          {label}
        </Button>
      );
    }

    return (
      <RowActionMenuItem onSelect={handleClick} disabled={isDisabled} onKeyDown={props.onKeyDown}>
        <Icon icon="play-circle" fixedWidth />
        {label}
      </RowActionMenuItem>
    );
  };

  return (
    <>
      {isConfigured ? (
        renderDeployAction()
      ) : (
        <Tooltip tooltip="Data App is not configured" placement="top">
          {renderDeployAction()}
        </Tooltip>
      )}
      <ConfirmModal
        closeAfterResolve
        isLoading={deploying}
        isDisabled={expiration < MINIMUM_EXPIRTION}
        show={showConfirmModal}
        onEnter={() => {
          const expiration = prepareExpiration(props.config);

          setUpdateDependencies(false);
          setSize(props.config.getIn(SIZE_PATH, SANDBOX_SIZES.TINY));
          handleSetExpiration(
            expiration,
            !EXPIRATION_OPTIONS.some(({ value }) => value === expiration),
          );
        }}
        onHide={() => setShowConfirmModal(false)}
        icon="play-circle"
        title={label}
        buttonLabel={label}
        text={
          <>
            {props.app?.state === 'running' && (
              <Alert className="tw-mb-5">
                App is currently running. Are you sure you want to deploy a new version?
              </Alert>
            )}
            <WorkspaceSizeControl
              type={SANDBOX_TYPE.STREAMLIT}
              value={size}
              onChange={setSize}
              isDisabled={isDisabled}
              hasPayAsYouGo={props.hasPayAsYouGo}
            />
            <TimeoutControl
              value={expiration}
              onChange={handleSetExpiration}
              isDisabled={isDisabled}
              showCustom={showCustomExpiration}
              label={
                <>
                  Inactivity Timeout
                  <InfoTooltip tooltip={AUTO_SLEEP_NOTE} />
                </>
              }
              help={
                <HelpBlock
                  className="tw-mt-1"
                  state={expiration < MINIMUM_EXPIRTION ? 'error' : 'default'}
                >
                  The minimum timeout is {date.duration(MINIMUM_EXPIRTION, 'second').humanize()}.
                </HelpBlock>
              }
            />

            {isFreezeActive && versionsExist && (
              <div className="tw-mb-4 tw-flex tw-items-center tw-gap-2">
                <Switch
                  checked={updateDependencies}
                  onChange={setUpdateDependencies}
                  id="updates-packages-dependencies-switch"
                />
                <Label htmlFor="updates-packages-dependencies-switch">
                  Update packages dependencies
                </Label>

                <InfoTooltip
                  className="tw-m-0"
                  tooltip="When enabled, all package dependencies will be updated to their latest versions if no version is explicitly defined. The dependencies will be resolved, and packages will be frozen in the Data App state."
                />
              </div>
            )}
          </>
        }
        onConfirm={handleDeploy}
      />
    </>
  );
};

export default DeployDataApp;
