import {
  KDS_EX_DV360,
  KDS_TEAM_EX_YOUTUBE_ANALYTICS,
  KEBOOLA_EX_FACEBOOK,
  KEBOOLA_EX_FACEBOOK_ADS,
  KEBOOLA_EX_INSTAGRAM,
} from '@/constants/componentIds';
import ApplicationStore from '@/stores/ApplicationStore';
import { Constants } from './Constants';

export const isGoogleService = (componentId: string) => {
  return (
    [KDS_EX_DV360, KDS_TEAM_EX_YOUTUBE_ANALYTICS].includes(componentId) ||
    ['google', 'gmail', 'gsuite', 'gcalendar'].some((key) => componentId.includes(key))
  );
};

export const isFacebookService = (componentId: string) =>
  [KEBOOLA_EX_FACEBOOK, KEBOOLA_EX_FACEBOOK_ADS, KEBOOLA_EX_INSTAGRAM].includes(componentId);

export const getReturnUrl = (credentialsId: string) => {
  const returnUrl = new URL(
    `${window.location.origin}${ApplicationStore.getProjectPageUrl(Constants.ROUTE_NAME)}`,
  );
  returnUrl.searchParams.append('credentialsId', credentialsId);

  return returnUrl.toString();
};

export const prepareCredentialsId = (
  credentialsId: string,
  componentId?: string,
  configId?: string,
  branchId?: string,
) => {
  return [componentId, configId, branchId, credentialsId].filter(Boolean).join('-');
};
