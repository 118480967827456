import { useState } from 'react';
import type { FormEvent } from 'react';
import { ControlLabel, FormGroup, Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { canWriteBucket } from '@/modules/admin/privileges';
import { filterProductionBuckets } from '@/modules/dev-branches/helpers';
import { bucketDisplayNameWithStage, validateTableName } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';
import PredefinedInput from './PredefinedInput';

type InitialState = {
  bucketId: string;
  name: string;
  warning: string | null;
  isSaving: boolean;
};

const INITIAL_STATE: InitialState = {
  bucketId: '',
  name: '',
  warning: null,
  isSaving: false,
};

type Props = {
  sapiToken: Map<string, any>;
  show: boolean;
  snapshot: Map<string, any>;
  buckets: Map<string, any>;
  tables: Map<string, any>;
  activeBucketId: string;
  tableName: string;
  onConfirm: (snapshotId: string, bucketId: string, tableName: string) => Promise<any>;
  onHide: () => void;
};

const CreateTableFromSnapshotModal = (props: Props) => {
  const [state, setState] = useState<InitialState>(INITIAL_STATE);

  const bucketsOptions = filterProductionBuckets(props.buckets)
    .filter((bucket: Map<string, any>) => canWriteBucket(props.sapiToken, bucket))
    .sortBy((bucket: Map<string, any>) => bucketDisplayNameWithStage(bucket))
    .map((bucket: Map<string, any>) => {
      return {
        value: bucket.get('id'),
        label: bucketLabel(bucket),
        name: bucket.get('displayName'),
      };
    })
    .toArray();

  const isDisabled = !state.bucketId || !state.name || !!state.warning;

  const handleHide = () => {
    setState(INITIAL_STATE);
    props.onHide();
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setState((prevState) => ({ ...prevState, isSaving: true }));
    return props
      .onConfirm(props.snapshot.get('id'), state.bucketId, state.name)
      .then(handleHide)
      .finally(() => setState((prevState) => ({ ...prevState, isSaving: false })));
  };

  return (
    <Modal
      show={props.show}
      onHide={handleHide}
      onEnter={() =>
        setState((prevState) => ({
          ...prevState,
          bucketId: props.activeBucketId,
          name: `${props.tableName}_${props.snapshot.get('id')}`,
        }))
      }
    >
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Create Table from Snapshot {props.snapshot.get('id')}</Modal.Title>
          <ModalIcon icon="table" color="green" bold />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <ControlLabel>Destination Bucket</ControlLabel>
            <Select
              autoFocus
              clearable={false}
              value={state.bucketId}
              onChange={(selected: string) => {
                setState((prevState) => ({
                  ...prevState,
                  bucketId: selected,
                  warning: validateTableName(
                    prevState.name,
                    props.tables.filter(
                      (table) => table.getIn(['bucket', 'id']) === selected,
                    ) as Map<string, any>,
                  ),
                }));
              }}
              options={bucketsOptions}
            />
          </FormGroup>
          <PredefinedInput
            entity="tableName"
            value={state.name}
            warning={state.warning}
            onChange={(name) => {
              setState((prevState) => ({
                ...prevState,
                name,
                warning: validateTableName(
                  name,
                  props.tables.filter(
                    (table) => table.getIn(['bucket', 'id']) === prevState.bucketId,
                  ) as Map<string, any>,
                ),
              }));
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isDisabled={isDisabled}
            saveLabel="Create table"
            saveButtonType="submit"
            isSaving={state.isSaving}
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateTableFromSnapshotModal;
