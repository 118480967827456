import { cn, Icon, Tooltip } from '@keboola/design';

import { Truncated } from '@/react/common';

export const PhaseLabel = ({
  name,
  description,
  readOnly,
  onEdit,
  shouldMergeInto,
}: {
  name: string;
  description: string;
  readOnly: boolean;
  shouldMergeInto: boolean;
  onEdit: () => void;
}) => {
  if (shouldMergeInto) {
    return (
      <div className="tw-absolute tw-top-[-14px] tw-rounded tw-bg-secondary-500 tw-px-2 tw-text-sm tw-font-medium tw-leading-6 tw-text-white">
        Run in parallel
      </div>
    );
  }

  return (
    <Tooltip
      tooltip={
        <>
          <div className="tw-text-sm tw-font-medium">{name}</div>
          {description && <span className="tw-mb-2 tw-text-neutral-100">{description}</span>}
        </>
      }
      placement="top"
      type="explanatory"
      className="tw-max-w-md tw-bg-neutral-800 tw-p-4 tw-text-left tw-leading-5"
      triggerClassName={cn(
        'tw-absolute tw-top-[-14px] tw-z-0 tw-max-w-[min(60%,520px)] tw-rounded tw-bg-neutral-800 tw-px-2 tw-text-sm tw-font-medium tw-leading-6 tw-text-white tw-flex tw-items-center',
        { clickable: !readOnly },
      )}
      triggerOnClick={() => !readOnly && onEdit()}
    >
      <Truncated text={name} noTooltip />
      {!!description && <Icon icon="note-sticky" className="tw-ml-2 tw-text-secondary-500" />}
    </Tooltip>
  );
};
