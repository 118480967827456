import React, { useEffect, useState } from 'react';
import type { Map } from 'immutable';
import { Seq } from 'immutable';

import { factory as eventsFactory } from '@/modules/sapi-events/TableEventsService';
import Loader from '@/react/common/Loader';
import LatestImportGraph from './LatestImportGraph';

type LatestImportsProps = {
  table: Map<string, any>;
};

const LatestImports = ({ table }: LatestImportsProps) => {
  const [state, setState] = useState<{
    events: Seq<string, any>;
    isLoading: boolean;
  }>({
    events: Seq(),
    isLoading: false,
  });

  const eventsRef = React.useRef<ReturnType<typeof eventsFactory>>();
  const tableId = table.get('id');

  useEffect(() => {
    eventsRef.current = eventsFactory(tableId, { component: 'storage' });
    const _events = eventsRef.current;
    _events.setQuery('event:storage.tableImportDone');
    _events.addChangeListener(handleChange);
    _events.load();

    return () => {
      _events.removeChangeListener(handleChange);
      _events.reset();
    };
  }, [tableId]);

  const handleChange = () => {
    const _events = eventsRef.current;
    if (!_events) return;

    setState((prevState) => ({
      ...prevState,
      events: _events.getEvents(),
      isLoading: _events.getIsLoading() ?? false,
    }));
  };

  if (state.isLoading) {
    return (
      <p>
        <Loader /> Loading graph...
      </p>
    );
  }

  if (state.events.count() < 2) {
    return <p>Graph cannot be rendered, there aren&apos;t enough import events.</p>;
  }

  return <LatestImportGraph data={state.events} />;
};

export default LatestImports;
