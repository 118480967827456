import { useCallback, useEffect, useRef, useState } from 'react';
import type { FormEvent, ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import type Bluebird from 'bluebird';
import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';

import { BETA } from '@/constants/componentFlags';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { findCreatedFromMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import SandboxesActions from '@/modules/sandboxes/Actions';
import {
  AUTO_SLEEP_DEFAULT,
  CONTAINER_BASED,
  FORCE_READONLY_STORAGE_ACCESS,
  FORM_STEPS,
  SANDBOX_SIZES,
  SANDBOX_TYPE,
  SUPPORT_SIZES,
} from '@/modules/sandboxes/Constants';
import {
  needsToLoadCredentials,
  resolveComponentIdFromSandboxType,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import type { SandboxType, WorkspaceStep } from '@/modules/sandboxes/types';
import { getComponentIconUrl } from '@/utils/componentIconFinder';
import jobPoller from '@/utils/jobPoller';
import CreateModal from './CreateModal';
import ListModal from './ListModal';
import UpdateModal from './UpdateModal';

type State = {
  step: SandboxType;
  error: ReactNode | null;
  sandbox: Map<string, any>;
  isLoaded: boolean;
  isLoading: boolean;
  tempData: Map<string, any>;
  isLoadingWorkspaces: boolean;
  isLoadingWorkspace: boolean;
  createdWorkspace: Map<string, any> | null;
};

const INITIAL_STATE: State = {
  step: FORM_STEPS.SANDBOX_LIST,
  error: null,
  sandbox: Map(),
  isLoaded: false,
  isLoading: false,
  tempData: Map(),
  isLoadingWorkspaces: false,
  isLoadingWorkspace: false,
  createdWorkspace: null,
};

type Props = {
  allowedComponents: Map<string, any>;
  hasPayAsYouGo: boolean;
  show: boolean;
  onSubmit: (
    name: string,
    type: string,
    options: Map<string, any>,
    params: Map<string, any>,
    description: string,
  ) => Promise<any>;
  onHide: () => void;
  forceType?: SandboxType | null;
  forceStep?: WorkspaceStep;
  onUpdate?: (workspace: Map<string, any>, preserve: boolean) => Promise<any>;
  workspaces?: Map<string, any>;
  metadata?: Map<string, any>;
  sourceTransformation?: Map<string, any>;
  hasTableInputMapping?: boolean;
  simple?: boolean;
};

const AddSandboxModal = ({
  allowedComponents,
  hasPayAsYouGo,
  show,
  onSubmit,
  onHide,
  forceType,
  forceStep,
  onUpdate,
  workspaces,
  metadata = Map(),
  sourceTransformation = Map(),
  hasTableInputMapping = false,
  simple,
}: Props) => {
  const [state, setState] = useState(INITIAL_STATE);

  const cancelablePromiseRef = useRef<Bluebird<unknown> | null>(null);

  const findWorkspaceDetail = useCallback(
    (configId: string) => {
      if (!workspaces) {
        return null;
      }

      return workspaces.find((workspace) => {
        return workspace.getIn(['configuration', 'id']) === configId;
      });
    },
    [workspaces],
  );

  useEffect(() => {
    const workspace = findWorkspaceDetail(state.tempData.get('workspace'));

    if (workspace && needsToLoadCredentials(workspace) && !state.isLoadingWorkspace) {
      setState((prevState) => ({ ...prevState, isLoadingWorkspace: true }));
      SandboxesActions.loadSandboxForce(workspace.get('id')).then(() => {
        setState((prevState) => ({ ...prevState, isLoadingWorkspace: false }));
      });
    }
  }, [findWorkspaceDetail, state.isLoadingWorkspace, state.tempData]);

  const changeData = (key: string, value: unknown) => {
    setState((prevState) => ({
      ...prevState,
      tempData: prevState.tempData.set(key, value),
      isLoaded: false,
      error: null,
    }));
  };

  const prepareWorkspaces = () => {
    return fromJS(Object.values(SANDBOX_TYPE))
      .filter((type: string) =>
        allowedComponents.has(resolveComponentIdFromSandboxType(type) ?? ''),
      )
      .map((type: string) => {
        const component = allowedComponents.get(resolveComponentIdFromSandboxType(type) ?? '');

        return Map({
          type,
          name: component.get('name'),
          description: component.get('description'),
          imageSrc: getComponentIconUrl(component),
          beta: component.get('flags', List()).includes(BETA),
        });
      });
  };

  const checkSandbox = () => {
    if (forceType && forceStep) {
      const data = findCreatedFromMetadata(
        resolveComponentIdFromSandboxType(forceType),
        sourceTransformation.get('id'),
        metadata,
      );
      const optionExists =
        workspaces?.some((workspace) => {
          return workspace.get('configurationId') === data?.configurationId;
        }) ?? false;

      return handleSelectSandbox(
        prepareWorkspaces().find((sandbox: Map<string, any>) => sandbox.get('type') === forceType),
        forceStep,
        sourceTransformation.get('name'),
        optionExists ? data.configurationId : '',
      );
    }

    setState(INITIAL_STATE);
  };

  const loadWorkspaces = () => {
    setState((prevState) => ({ ...prevState, isLoadingWorkspaces: true }));
    return Promise.all([
      InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_SANDBOXES),
      SandboxesActions.loadSandboxes(),
    ]).then(() => {
      setState((prevState) => ({ ...prevState, isLoadingWorkspaces: false }));
    });
  };

  const handleSubmitUpdateWorkspace = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state.isLoading || !onUpdate || !workspaces) {
      return;
    }

    const workspace = workspaces.find((workspace) => {
      return workspace.get('configurationId') === state.tempData.get('workspace');
    });

    setState((prevState) => ({ ...prevState, isLoading: true, isLoaded: false, error: null }));
    return onUpdate(workspace, state.tempData.get('preserve')).then(({ url }) => {
      cancelablePromiseRef.current = jobPoller
        .poll(url)
        .then(() => SandboxesActions.loadSandboxForce(workspace.get('id')))
        .then(() =>
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isLoaded: true,
            error: null,
          })),
        )
        .catch((error) => {
          return setState((prevState) => ({
            ...prevState,
            isLoading: false,
            isLoaded: false,
            error: error?.message || 'An unknown error occurred when loading data.',
          }));
        });
    });
  };

  const handleSubmitCreateWorkspace = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (state.isLoading) {
      return;
    }

    const options = Map({
      shared: state.tempData.get('shared', false),
      readOnlyStorageAccess: FORCE_READONLY_STORAGE_ACCESS.includes(state.sandbox.get('type'))
        ? true
        : state.tempData.get('readOnlyStorageAccess', false),
    });

    let params = Map<string, any>();

    if (CONTAINER_BASED.includes(state.sandbox.get('type'))) {
      params = params.set(
        'expirationAfterHours',
        state.tempData.get('expirationAfterHours', AUTO_SLEEP_DEFAULT),
      );
    }

    if (SUPPORT_SIZES.includes(state.sandbox.get('type'))) {
      params = params.set('size', state.tempData.get('size', SANDBOX_SIZES.SMALL));
    }

    if (state.tempData.get('imageVersion')) {
      params = params.set('imageVersion', state.tempData.get('imageVersion'));
    }

    setState((prevState) => ({
      ...prevState,
      isLoading: true,
      createdWorkspace: null,
      error: null,
    }));
    cancelablePromiseRef.current = onSubmit(
      state.tempData.get('name'),
      state.sandbox.get('type'),
      options,
      params,
      state.tempData.get('description', ''),
    ).then(({ config, job }) => {
      if (simple) {
        return onHide();
      }

      cancelablePromiseRef.current = jobPoller
        .poll(job.url)
        .then(() => {
          return InstalledComponentsActionCreators.loadComponentConfigDataForce(
            KEBOOLA_SANDBOXES,
            config.id,
          );
        })
        .then((configData) => SandboxesActions.loadSandboxForce(configData.parameters?.id))
        .then(() => {
          const workspace = SandboxesStore.getSandboxes().find(
            (workspace) => workspace.get('configurationId') === config.id,
          );
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            createdWorkspace: workspace,
            error: null,
          }));
        })
        .catch((error) => {
          return setState((prevState) => ({
            ...prevState,
            isLoading: false,
            createdWorkspace: null,
            error: error?.message || 'An unknown error occurred when creating the workspace.',
          }));
        });
    });
  };

  const handleSelectSandbox = (
    sandbox: Map<string, any>,
    step: SandboxType,
    name = '',
    workspace = '',
  ) => {
    if (step === FORM_STEPS.SANDBOX_UPDATE) {
      loadWorkspaces();
    }

    const data = Map({
      name,
      workspace,
      shared: false,
      description: '',
      size: SANDBOX_SIZES.SMALL,
      preserve: false,
    });

    setState((prevState) => ({ ...prevState, sandbox, step, tempData: data, isLoading: false }));
  };

  const renderSandboxUpdateModal = () => {
    return (
      <UpdateModal
        isLoadingWorkspaces={state.isLoadingWorkspaces}
        error={state.error}
        isLoading={state.isLoading}
        isLoaded={state.isLoaded}
        forceType={forceType}
        tempData={state.tempData}
        onSetTempData={changeData}
        sourceTransformation={sourceTransformation}
        simple={simple ?? false}
        isLoadingWorkspace={state.isLoadingWorkspace}
        sandboxType={state.sandbox.get('type', '')}
        onSubmit={handleSubmitUpdateWorkspace}
        workspaces={workspaces ?? Map()}
        onFindWorkspaceDetail={findWorkspaceDetail}
      />
    );
  };

  const renderSandboxCreateModal = () => {
    return (
      <CreateModal
        sandbox={state.sandbox}
        tempData={state.tempData}
        sourceTransformation={sourceTransformation}
        forceStep={forceStep}
        simple={simple ?? false}
        hasPayAsYouGo={hasPayAsYouGo}
        isLoading={state.isLoading}
        createdWorkspace={state.createdWorkspace}
        error={state.error}
        isLoadingWorkspace={state.isLoadingWorkspace}
        onSetTempData={changeData}
        onResetState={() => setState(INITIAL_STATE)}
        onSubmit={handleSubmitCreateWorkspace}
      />
    );
  };

  const renderSandboxListModal = () => {
    return (
      <ListModal
        forceStep={forceStep}
        hasTableInputMapping={hasTableInputMapping}
        onPrepareWorkspaces={prepareWorkspaces}
        onSelectSandbox={handleSelectSandbox}
      />
    );
  };

  const renderBody = () => {
    if (state.step === FORM_STEPS.SANDBOX_CREATE) {
      return renderSandboxCreateModal();
    }

    if (state.step === FORM_STEPS.SANDBOX_UPDATE) {
      return renderSandboxUpdateModal();
    }

    return renderSandboxListModal();
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Modal
        show={show}
        onHide={onHide}
        onEnter={checkSandbox}
        onExit={() => cancelablePromiseRef.current?.cancel()}
      >
        {renderBody()}
      </Modal>
    </span>
  );
};

export default AddSandboxModal;
