import type { SyntheticEvent } from 'react';
import type { Table } from '@tanstack/react-table';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { CONFIGURATIONS_SORT_BY } from '@/constants/localStorageKeys';
import { setItem } from '@/utils/localStorage';
import ColumnHeader from './ColumnHeader';

const TableHead = (props: {
  tableInstance: Table<unknown>;
  allowCreateConfig: boolean;
  sortByKey: string;
  customClasses: Map<string, any>;
  readOnly: boolean;
}) => {
  return (
    <div
      className={cn('table table-hover react-table', {
        'is-sticky bg-color-white': props.tableInstance.getRowModel().rows.length > 10,
        'tw-pb-0': !props.allowCreateConfig,
      })}
    >
      <div className="thead">
        {props.tableInstance.getHeaderGroups().map((headerGroup) => (
          <div key={headerGroup.id} className="tr with-action-buttons">
            {headerGroup.headers.map((header) => {
              const selectedRows = props.tableInstance.getSelectedRowModel().flatRows.length;
              const disableSort = header.column.id === 'data' && selectedRows > 0;
              const sortingHandler = header.column.getToggleSortingHandler();
              const canSort = header.column.getCanSort() && !disableSort;

              return (
                <div
                  key={header.id}
                  className={cn(
                    'th !tw-h-20',
                    props.customClasses.getIn([header.column.id, 'th']),
                    {
                      'tw-w-32 tw-text-right': ['storage_data', 'used_in'].includes(
                        header.column.id,
                      ),
                      'tw-w-44 tw-text-right': header.column.id === 'last_change',
                      'tw-w-52 tw-text-right': header.column.id === 'job',
                      clickable: canSort,
                    },
                  )}
                  {...(canSort &&
                    sortingHandler && {
                      onClick: (e: SyntheticEvent) => {
                        setItem(
                          `${CONFIGURATIONS_SORT_BY}-${props.sortByKey}`,
                          `${header.column.id}:${
                            !header.column.getIsSorted()
                              ? 'asc'
                              : header.column.getIsSorted() === 'desc'
                                ? 'asc'
                                : 'desc'
                          }`,
                        );
                        sortingHandler(e);
                      },
                    })}
                >
                  <ColumnHeader header={header} canSort={canSort} />
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableHead;
