import { memo, useCallback } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';

import { Badge, cn } from '@keboola/design';

import { sortEntities } from '@/constants';
import { useSorter } from '@/hooks';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { routeNames } from '@/modules/data-catalog/constants';
import type { FlatTableRow } from '@/modules/data-catalog/types';
import type { SortConfig } from '@/modules/data-catalog/utils';
import { getInitialSort, saveSort, sortKeys } from '@/modules/data-catalog/utils';
import { getDescriptionValue } from '@/modules/storage/helpers';
import { CircleIcon, Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import { DEFAULT_BUCKET_COLOR } from '@/react/constants';
import string from '@/utils/string';
import { DataCatalogTable } from './DataCatalogTable';
import {
  ActionsDropdown,
  lastModified,
  ownerColumn,
  SharedLabel,
  sizeColumn,
  SortableHeader,
} from './tableComponents';

const updateSort = (newSort: SortConfig) => {
  saveSort(sortKeys.SHARED_FROM_PROJECT, newSort);
};

type Props = {
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  availableUsersOptions: List<any>;
  availableProjectsOptions: List<any>;
  mySharedBuckets: Map<string, any>;
};

export const SharedFromThisProjectBuckets = memo(
  ({
    admins,
    currentAdmin,
    sapiToken,
    urlTemplates,
    availableUsersOptions,
    availableProjectsOptions,
    mySharedBuckets,
  }: Props) => {
    const { sort, sorter, setSort } = useSorter(
      fromJS(getInitialSort(sortKeys.SHARED_FROM_PROJECT)),
      updateSort,
    );

    const getColumns = useCallback(
      (query: string) => {
        return [
          {
            id: 'displayName',
            header: () => (
              <SortableHeader
                label="Name"
                entityKey={sortEntities.NAME}
                arrowSide="right"
                sort={sort}
                setSort={setSort}
              />
            ),
            cell: ({ row }: { row: FlatTableRow }) => {
              const bucket = row.original;
              const bucketColor = bucket.get('color');
              const metadata = bucket.get('metadata', List());
              const displayName = bucket.get('displayName');

              const description =
                getDescriptionValue(metadata, MetadataKeys.SHARED_DESCRIPTION) ||
                getDescriptionValue(metadata, MetadataKeys.DESCRIPTION);

              return (
                <div className={cn('tw-inline-flex tw-items-center')}>
                  <CircleIcon icon="folder" backgroundColor={bucketColor ?? DEFAULT_BUCKET_COLOR} />
                  <div className={cn('tw-items-center tw-flex-col tw-px-3')}>
                    <Truncated
                      tooltip={displayName}
                      text={<MarkedText source={displayName} mark={query} />}
                    />
                    <div className="text-muted tw-w-[280px] tw-truncate">
                      {string.truncate(description, 60)}
                    </div>
                  </div>
                </div>
              );
            },
          },
          ownerColumn({ admins, sort, setSort }),
          sizeColumn({ sort, setSort }),
          {
            id: 'shared',
            header: () => (
              <SortableHeader
                label="Shared to"
                entityKey={sortEntities.SHARED}
                sort={sort}
                setSort={setSort}
                justifyToEnd
              />
            ),
            cell: ({ row }: { row: FlatTableRow }) => {
              const bucket = row.original;
              const sharing = bucket.get('sharing');
              const sharingParameters = bucket.get('sharingParameters');
              const { projects, users } = sharingParameters.toJS();
              const linkedByCount = bucket.get('linkedBy', List()).count();

              return (
                <div className="tw-text-right">
                  <div>
                    <SharedLabel projects={projects} users={users} sharing={sharing} />
                  </div>
                  {linkedByCount > 0 && (
                    <Badge
                      className="tw-mt-[2px]"
                      text={`${linkedByCount} Linked`}
                      variant="green-inverse"
                    />
                  )}
                </div>
              );
            },
          },
          lastModified({ sort, setSort }),
          {
            id: 'actions',
            header: '',
            cell: ({ row }: { row: FlatTableRow }) => (
              <ActionsDropdown
                bucket={row.original}
                sapiToken={sapiToken}
                urlTemplates={urlTemplates}
                availableUsersOptions={availableUsersOptions}
                availableProjectsOptions={availableProjectsOptions}
              />
            ),
          },
        ];
      },
      [
        admins,
        availableProjectsOptions,
        availableUsersOptions,
        sapiToken,
        setSort,
        sort,
        urlTemplates,
      ],
    );

    return (
      <DataCatalogTable
        getColumns={getColumns}
        buckets={mySharedBuckets}
        sourceTab={routeNames.SHARED_FROM_THIS_PROJECT}
        currentAdmin={currentAdmin}
        sorter={sorter}
      />
    );
  },
  shallowEqualImmutable,
);
