import type { ReactNode } from 'react';
import { Map } from 'immutable';

import { Icon } from '@keboola/design';

import {
  EX_GENERIC_V_2,
  KEBOOLA_DATA_APPS,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
} from '@/constants/componentIds';
import { getAutomationFromFlowMetadata } from '@/modules/automations/helpers';
import { resolveRouterLinkParams } from '@/modules/components/helpers';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames } from '@/modules/components-directory/constants';
import { routeNames as dataAppsRouteNames } from '@/modules/data-apps/constants';
import {
  DBT_COMPONENTS,
  routeNames as transformationRouteNames,
} from '@/modules/transformations-v2/constants';
import { TabLink, TabNav } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { getRealComponentId } from './ConfigurationsTable/helpers';
import DeleteConfigurationButton from './DeleteConfigurationButton';

type Props = {
  componentId: string;
  configId: string;
  rowId?: string;
  versionsLinkTo?: string;
  notificationsLinkTo?: string;
  additionalButtons?: ReactNode;
} & (
  | {
      showDeleteButton?: never;
      config?: never;
      flows?: never;
    }
  | {
      showDeleteButton: boolean;
      config: Map<string, any>;
      flows: Map<string, any>;
    }
);

const ConfigurationTabs = (props: Props) => {
  const store = useStores(
    () => ({
      hasNewQueue: ApplicationStore.hasNewQueue(),
      isAutomationView: getAutomationFromFlowMetadata(
        RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']),
        InstalledComponentsStore.getAllMetadata() as Map<string, any>,
      ).isDraft,
      location: RoutesStore.getRouterState().get('location', Map()).toJS(),
    }),
    [],
    [RoutesStore, ApplicationStore, InstalledComponentsStore],
  );

  const linkParams = resolveRouterLinkParams(props.componentId, props.configId, props.rowId);

  if (store.isAutomationView) return null;

  const isDataAppComponent = props.componentId === KEBOOLA_DATA_APPS;
  const isExGenericV2Component = props.componentId === EX_GENERIC_V_2;
  const isDbtComponent = DBT_COMPONENTS.includes(props.componentId);

  return (
    <div id="configuration-nav" className="tabs-with-border-wrapper flex-container">
      <TabNav>
        {linkParams && (
          <TabLink
            to={linkParams.to}
            params={linkParams.params}
            activeByIndexOnly={!isExGenericV2Component}
          >
            <Icon icon="circle-info" className="icon-addon-right" />
            Information &amp; Settings
          </TabLink>
        )}

        {isDataAppComponent && (
          <TabLink
            to={dataAppsRouteNames.DATA_APP_TERMINAL_LOGS}
            params={{ config: props.configId }}
          >
            <Icon icon="square-terminal" className="icon-addon-right" />
            Terminal Logs
          </TabLink>
        )}

        {store.hasNewQueue && isExGenericV2Component && (
          <TabLink
            to={routeNames.GENERIC_CONFIG_JOBS}
            params={{ component: props.componentId, config: props.configId }}
          >
            <Icon icon="rotate" className="icon-addon-right" />
            All Runs
          </TabLink>
        )}
        {store.hasNewQueue &&
          !props.rowId &&
          ![KEBOOLA_SANDBOXES, KEBOOLA_SHARED_CODE].includes(props.componentId) && (
            <TabLink
              to={props.notificationsLinkTo || routeNames.GENERIC_CONFIG_NOTIFICATIONS}
              params={{ component: props.componentId, config: props.configId }}
            >
              <Icon icon="bell-on" className="icon-addon-right" />
              Notifications
            </TabLink>
          )}
        {isDbtComponent && (
          <TabLink
            to={transformationRouteNames.GENERIC_TRANSFORMATION_CONFIG_DISCOVERY}
            params={{ component: props.componentId, config: props.configId }}
          >
            <Icon icon="magnifying-glass" className="icon-addon-right" />
            Discovery
          </TabLink>
        )}
        <TabLink
          to={
            props.versionsLinkTo
              ? props.versionsLinkTo
              : props.rowId
                ? routeNames.GENERIC_CONFIG_ROW_VERSIONS
                : routeNames.GENERIC_CONFIG_VERSIONS
          }
          params={{ component: props.componentId, config: props.configId, row: props.rowId }}
        >
          <Icon icon="clone" className="icon-addon-right" />
          Versions
        </TabLink>
      </TabNav>
      {(props.showDeleteButton || props.additionalButtons) && (
        <span className="ml-auto">
          {props.showDeleteButton && (
            <DeleteConfigurationButton
              mode="navigation"
              flows={props.flows}
              componentId={getRealComponentId(props.config, Map({ id: props.componentId }))}
              config={props.config}
            />
          )}
          {props.additionalButtons}
        </span>
      )}
    </div>
  );
};

export default ConfigurationTabs;
