import { Component } from 'react';
import PropTypes from 'prop-types';

import { Icon } from '@keboola/design';

import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import { TabLink, TabNav } from '@/react/common';

const NAV_TABS = [
  { icon: 'gear', title: 'Transformations', routeName: transformationsRouteNames.ROOT },
  { icon: 'code', title: 'Shared Code', routeName: transformationsRouteNames.SHARED_CODES },
];

class TransformationTabs extends Component {
  render() {
    return (
      <>
        <div className="tabs-with-border-wrapper">
          <TabNav>
            {NAV_TABS.map((tab) => (
              <TabLink key={tab.routeName} to={tab.routeName}>
                <Icon icon={tab.icon} className="icon-addon-right" />
                {tab.title}
              </TabLink>
            ))}
          </TabNav>
        </div>
        {this.props.children}
      </>
    );
  }
}

TransformationTabs.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransformationTabs;
