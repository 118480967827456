import { useState } from 'react';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import {
  getResponseTypeFromRequestType,
  isInteractiveMessageRequest,
} from '@/modules/automations/helpers';
import ComponentSelection from './ComponentSelection';
import IntentConfirmation from './IntentConfirmation';
import TableSelection from './TableSelection';

const InteractiveMessageRequest = ({
  messageObject,
  submitMessage,
  isDisabled,
}: {
  messageObject: AutomationMessage;
  submitMessage: (messageObject: Partial<UserMessage>) => Promise<void>;
  isDisabled?: boolean;
}) => {
  const [wasSubmitted, setWasSubmitted] = useState(false);

  if (!isInteractiveMessageRequest(messageObject)) return null;

  const submit = (data?: UserMessage['data']) => {
    if (isDisabled) return;

    submitMessage({
      type: getResponseTypeFromRequestType(messageObject.type),
      data,
    }).then(() => setWasSubmitted(true));
  };

  if (wasSubmitted) return null;

  switch (messageObject.type) {
    case 'componentSelectionRequest':
      return (
        <ComponentSelection
          componentsIds={messageObject.data.options}
          componentType={messageObject.data.componentType}
          onSubmit={(componentIds) => submit({ selectedValues: componentIds })}
          isDisabled={isDisabled}
        />
      );

    case 'sourceTableSelectionRequest':
      return (
        <TableSelection
          tablesIds={messageObject.data.options}
          onClick={(tablesIds) => submit({ selectedValues: tablesIds })}
          isDisabled={isDisabled}
        />
      );

    case 'intentConfirmationRequest':
      return (
        <IntentConfirmation
          onClick={(confirm) => submit({ selectedValue: confirm })}
          isDisabled={isDisabled}
        />
      );

    default:
      return null;
  }
};

export default InteractiveMessageRequest;
