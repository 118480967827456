import { Tooltip } from '@keboola/design';

import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import { ActionButton } from './ActionButton';

type Props = {
  readOnly: boolean;
  onOpen: () => void;
  onDelete: () => void;
  onExploreData?: () => void;
};

const TaskActions = (props: Props) => {
  const [nodeDragging] = useBuilderStore((state) => [state.nodeDragging]);

  if (nodeDragging || (props.readOnly && !props.onExploreData)) {
    return null;
  }

  return (
    <span className="tw-absolute -tw-bottom-4 tw-right-4 tw-flex tw-items-center tw-gap-2 tw-opacity-0 tw-transition-opacity group-hover/task:tw-opacity-100">
      {!props.readOnly && (
        <Tooltip placement="top" tooltip="Delete Task">
          <ActionButton onClick={props.onDelete} icon="trash" />
        </Tooltip>
      )}
      {props.onExploreData && (
        <Tooltip placement="top" tooltip="Explore Data">
          <ActionButton onClick={props.onExploreData} icon="warehouse" />
        </Tooltip>
      )}
      {!props.readOnly && (
        <Tooltip placement="top" tooltip="Show Details">
          <ActionButton onClick={props.onOpen} icon="gear" />
        </Tooltip>
      )}
    </span>
  );
};

export default TaskActions;
