import { ConditionForm } from './Conditions/ConditionForm';
import { SidebarModal } from './SidebarModal';

type Props = {
  id: string;
  readOnly: boolean;
  onHide: () => void;
};

export const ConditionDetailModal = ({ id, readOnly, onHide }: Props) => {
  return (
    <SidebarModal onHide={onHide}>
      <ConditionForm id={id} readOnly={readOnly} onHide={onHide} />
    </SidebarModal>
  );
};
