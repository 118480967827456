import PropTypes from 'prop-types';
import createReactClass from 'create-react-class';

import { Icon, Tooltip } from '@keboola/design';

import ConfirmModal from '@/react/common/ConfirmModal';
import Loader from '@/react/common/Loader';

const ClearStateButton = createReactClass({
  propTypes: {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      isPending: false,
      showModal: false,
    };
  },

  render() {
    return (
      <>
        {this.renderButton()}
        <ConfirmModal
          closeAfterResolve
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: false })}
          icon="arrow-rotate-left"
          title="Clear state"
          text={
            <p>
              Delete the current stored state of the configuration, e.g., the progress of an
              incremental process.
            </p>
          }
          buttonLabel="Clear state"
          buttonType="success"
          onConfirm={this.clearState}
          isLoading={this.state.isPending}
        />
      </>
    );
  },

  renderButton() {
    if (this.state.isPending) {
      return (
        <button className="btn btn-link">
          <Loader /> Clear state
        </button>
      );
    }

    if (this.props.disabled) {
      return (
        <Tooltip tooltip="No stored state" placement="top" triggerClassName="tw-w-full">
          <button className="btn btn-link disabled">
            <Icon icon="arrow-rotate-left" fixedWidth />
            Clear state
          </button>
        </Tooltip>
      );
    }

    return (
      <Tooltip
        tooltip="State stores information from the previous run(s) and allows, e.g., incremental loads."
        placement="top"
        type="explanatory"
        triggerClassName="tw-w-full"
      >
        <button className="btn btn-link" onClick={() => this.setState({ showModal: true })}>
          <Icon icon="arrow-rotate-left" fixedWidth />
          Clear state
        </button>
      </Tooltip>
    );
  },

  clearState() {
    this.setState({ isPending: true });
    return this.props.onClick().finally(() => this.setState({ isPending: false }));
  },
});

export default ClearStateButton;
