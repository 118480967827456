import type { Map } from 'immutable';

import {
  KEBOOLA_EX_FACEBOOK,
  KEBOOLA_EX_FACEBOOK_ADS,
  KEBOOLA_EX_INSTAGRAM,
} from '@/constants/componentIds';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { DEFAULT_API_VERSION } from '@/modules/ex-facebook/storeProvisioning';
import { Truncated } from '@/react/common';
import CollapsibleAlert from '@/react/common/CollapsibleAlert';
import ComponentName from '@/react/common/ComponentName';
import string from '@/utils/string';

const MINIMUM_API_VERSION = 18;

const DeprecatedFacebookApi = (props: { components: Map<string, any> }) => {
  const effectedComponents = props.components
    .filter((component: Map<string, any>) => {
      return [KEBOOLA_EX_FACEBOOK, KEBOOLA_EX_FACEBOOK_ADS, KEBOOLA_EX_INSTAGRAM].includes(
        component.get('id'),
      );
    })
    .map((component: Map<string, any>) => {
      const configurations = component
        .get('configurations')
        .filter((configuration: Map<string, any>) => {
          const versions = configuration.getIn(['configuration', 'parameters', 'api-version']);

          // transform v14.0 type of string to number
          return parseInt(string.ltrim(versions, 'v')) < MINIMUM_API_VERSION;
        });

      if (configurations.isEmpty()) {
        return null;
      }

      return (
        <div
          key={component.get('id')}
          className="flex-container flex-column align-top flex-inline w-300"
        >
          <h3 className="f-14 mt-0">
            <ComponentName component={component} />
          </h3>
          <ul className="mlp-2 mb-0">
            {configurations
              .map((config: Map<string, any>) => {
                return (
                  <li key={config.get('id')} className="color-primary">
                    <ComponentConfigurationLink
                      configId={config.get('id')}
                      componentId={component.get('id')}
                      className="line-height-24 f-14 color-primary tw-inline-flex"
                    >
                      <Truncated text={config.get('name')} />
                    </ComponentConfigurationLink>
                  </li>
                );
              })
              .toArray()}
          </ul>
        </div>
      );
    })
    .filter(Boolean);

  if (effectedComponents.isEmpty()) {
    return null;
  }

  return (
    <CollapsibleAlert
      id="deprecated-components-apis"
      title="There are components using deprecated Facebook APIs"
      variant="warning"
      className="tw-mb-6"
    >
      <p>
        Note that the following component configurations are using a deprecated Facebook API
        version. Please update the configurations to use at least {DEFAULT_API_VERSION} API version.
      </p>
      <div className="flex-container flex-start align-top tw-flex-row tw-flex-wrap tw-gap-4">
        {effectedComponents.toArray()}
      </div>
    </CollapsibleAlert>
  );
};

export default DeprecatedFacebookApi;
