import type { Path } from 'react-router';

import type { Query } from '@/utils/router/getQueryString';
import { getQueryString } from '@/utils/router/getQueryString';
import { resolvePathname } from '@/utils/router/namedRouterResolver';

export const createPath = (
  to: string,
  params?: Record<string, any> | null,
  query?: Query,
  hash?: string,
): Partial<Path> => ({
  pathname: resolvePathname(to, params),
  search: getQueryString(query),
  hash: hash,
});
