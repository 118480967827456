import { Promise as BluebirdPromise } from 'bluebird';
import type { Map } from 'immutable';

import Api from '@/api';
import type {
  ConversationExecuteTransformationRequest,
  Feedback,
  UserMessage,
} from '@/api/routes/aiService';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';

const getBranchId = () =>
  `${
    DevBranchesStore.isDevModeActive()
      ? DevBranchesStore.getCurrentId()
      : DevBranchesStore.getDefaultBranchId()
  }`;

const getErrorExplanation = (job: Map<string, any>) => {
  return Api.aiService.explainError({
    jobId: job.get('id'),
    error: job.getIn(['result', 'message']),
  });
};

const getConfigDescription = (componentId: string, configId: string, rowId?: string) => {
  return Api.aiService.describeConfiguration({
    branchId: getBranchId(),
    componentId,
    configId,
    rowId,
  });
};

const sendFeedback = (data: Feedback) => {
  return Api.aiService.feedback(data);
};

const createConversation = (componentId: string, configId: string) => {
  return Api.aiService.createConversation({
    branchId: getBranchId(),
    componentId,
    configId,
  });
};

const getConversation = (conversationId: string) => {
  return Api.aiService.getConversation({ conversationId });
};

const deleteConversation = (conversationId: string) => {
  return getConversation(conversationId).then((conversation) => {
    if (conversation.status !== 'ready') return;

    return Api.aiService.deleteConversation({ conversationId });
  });
};

const generateQuery = (conversationId: string, prompt: string, existingQuery?: string) => {
  return Api.aiService.generateQuery(
    { conversationId },
    { prompt, ...(existingQuery && { existingQuery }) },
  );
};

const executeTransformation = (
  conversationId: string,
  transformation: ConversationExecuteTransformationRequest['transformation'],
) => {
  return Api.aiService
    .executeTransformation({ conversationId }, { transformation })
    .then(() => getLastTransformationExecutionResult(conversationId));
};

const getLastTransformationExecutionResult = (
  conversationId: string,
): ReturnType<typeof Api.aiService.getLastResult> => {
  return getConversation(conversationId).then((conversation) => {
    if (conversation.status === 'executing') {
      return BluebirdPromise.delay(500).then(() =>
        getLastTransformationExecutionResult(conversationId),
      );
    }

    return Api.aiService.getLastResult({ conversationId });
  });
};

const getOutputTables = (conversationId: string, queries: string[]) => {
  return Api.aiService.getOutputTables({ id: conversationId }, { queries });
};

const suggestComponent = (prompt: string) => {
  return Api.aiService.suggestComponent({ prompt });
};

const getAutomation = (automationId: string) => {
  return Api.aiService.getAutomation({ automationId });
};

const deleteAutomation = (automationId: string) => {
  return Api.aiService.deleteAutomation({ automationId }).catch((error) => {
    if (error.response.status === HTTP_STATUS_CODE_NOT_FOUND) return;
    throw error;
  });
};

const createAutomation = () => {
  return Api.aiService.createAutomation({
    branchId: (DevBranchesStore.isDevModeActive()
      ? DevBranchesStore.getCurrentId()
      : DevBranchesStore.getDefaultBranchId())!.toString(),
  });
};

const sendMessage = (automationId: string, params: UserMessage, signal?: AbortSignal) => {
  if (params.data.taskId) {
    params.data.taskId = params.data.taskId.toString();
  }

  return Api.aiService.postMessageToAutomation({ automationId }, params, signal);
};

const getAutomationMessages = (automationId: string) => {
  return Api.aiService.getAutomationMessages({ automationId });
};

export {
  getErrorExplanation,
  getConfigDescription,
  sendFeedback,
  createConversation,
  getConversation,
  deleteConversation,
  generateQuery,
  executeTransformation,
  getOutputTables,
  suggestComponent,
  getAutomation,
  deleteAutomation,
  createAutomation,
  sendMessage,
  getAutomationMessages,
};
