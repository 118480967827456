import type { MouseEvent } from 'react';
import Switch from 'rc-switch';

import { cn, Icon, Tooltip } from '@keboola/design';

import { RowActionMenuItem } from '@/react/common';
import SwitchStateLabel from './SwitchStateLabel';

type Props = {
  isActive: boolean;
  isPending?: boolean;
  buttonDisabled?: boolean;
  onChange?: (isActive: boolean) => void;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  noTooltips?: boolean;
  activateTooltip?: string;
  deactivateTooltip?: string;
  mode?: string;
  onKeyDown?: () => void;
  withLabel?: boolean;
  customLabel?: string;
  className?: string;
  triggerClassName?: string;
};

const ActivateDeactivateSwitch = ({
  isActive,
  isPending = false,
  buttonDisabled = false,
  onChange,
  tooltipPlacement = 'top',
  noTooltips,
  activateTooltip = 'Enable',
  deactivateTooltip = 'Disable',
  mode,
  onKeyDown,
  withLabel = false,
  customLabel,
  className,
  triggerClassName,
}: Props) => {
  const handleOnClick = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    onChange?.(!isActive);
  };

  if (mode === 'menuitem') {
    return (
      <RowActionMenuItem
        onSelect={handleOnClick}
        disabled={buttonDisabled || isPending}
        onKeyDown={onKeyDown}
      >
        <Icon className="bigger" icon={isActive ? 'toggle-large-on' : 'toggle-large-off'} />
        <SwitchStateLabel isActive={isActive} />
      </RowActionMenuItem>
    );
  }

  const switchContent = (
    <div
      className={cn('switch-wrapper no-wrap', className, {
        clickable: !(buttonDisabled || isPending),
      })}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();

        if (buttonDisabled || isPending) {
          return;
        }

        onChange?.(!isActive);
      }}
    >
      <Switch
        prefixCls="switch"
        checked={isActive}
        disabled={buttonDisabled || isPending}
        className={cn({ wider: mode !== 'menuitem' })}
      />
      {withLabel && (
        <span className="switch-wrapper-label">
          {customLabel ?? (isActive ? 'Enabled' : 'Disabled')}
        </span>
      )}
    </div>
  );

  if (noTooltips || buttonDisabled) {
    return switchContent;
  }

  return (
    <Tooltip
      placement={tooltipPlacement}
      tooltip={isActive ? deactivateTooltip : activateTooltip}
      triggerClassName={cn('tw-inline-flex', triggerClassName)}
    >
      {switchContent}
    </Tooltip>
  );
};

export default ActivateDeactivateSwitch;
