import { useState } from 'react';
import type { SyntheticEvent } from 'react';
import type { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { refreshExternalBucket } from '@/modules/storage/actions';
import { RowActionMenuItem } from '@/react/common';
import Loader from '@/react/common/Loader';

const RefreshExternalBucketMenuItem = (props: { bucket: Map<string, any>; readOnly: boolean }) => {
  const [isLoading, setIsLoading] = useState(false);

  if (!props.bucket.get('hasExternalSchema', false)) {
    return null;
  }

  const isDisabled = props.readOnly || isLoading || props.bucket.has('sourceBucket');

  return (
    <RowActionMenuItem
      eventKey="refresh"
      disabled={isDisabled}
      disabledReason={
        isLoading
          ? 'Refreshing bucket...'
          : 'Refresh operation is blocked. Ask owner of the source bucket to refresh it.'
      }
      onSelect={() => {
        setIsLoading(true);
        refreshExternalBucket(props.bucket.get('id')).finally(() => setIsLoading(false));
      }}
      onClick={(event: SyntheticEvent) => event.stopPropagation()}
    >
      {isLoading ? (
        <Loader className="icon-addon-right" />
      ) : (
        <Icon icon="rotate" fixedWidth className="icon-addon-right" />
      )}
      Refresh bucket
    </RowActionMenuItem>
  );
};

export default RefreshExternalBucketMenuItem;
