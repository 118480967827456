import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { cn, IconButton } from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import {
  ensureConfigurationsDetails,
  getComponentTypeColorClassName,
} from '@/modules/components/helpers';
import ComponentDetail from '@/modules/flows/components/TaskDetailModal/ComponentDetail';
import ConfigurationDetail from '@/modules/flows/components/TaskDetailModal/ConfigurationDetail';
import TaskIcon from '@/modules/flows/components/TaskIcon';
import { resolveComponentId, selectConfigWithRedirect } from '@/modules/flows/helpers';
import { Truncated } from '@/react/common';
import ComponentBadges from '@/react/common/ComponentBadges';
import { SidebarModal } from './SidebarModal';
import { SidebarModalHeader } from './SidebarModalHeader';

type Props = {
  configId: string;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  task: Map<string, any>;
  lastJob?: Map<string, any>;
  onEditTask: (id: string, properties: { keysPath: string[]; value: any }[]) => void;
  onSelectConfig: (
    taskId: string,
    componentId: string,
    configId: string,
    options?: { autosave: boolean },
  ) => Promise<any>;
  patternComponents: Map<string, any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  folders: Map<string, any>;
  showBackendSize: boolean;
  isDevModeActive: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
  onHide: () => void;
  onSetTaskParams: (taskId: string) => void;
  onDeleteTask: () => void;
};

export const TaskDetailModal = React.memo(
  ({
    configId,
    task,
    lastJob,
    readOnly,
    hasPayAsYouGo,
    onEditTask,
    onSelectConfig,
    allConfigurations,
    allComponents,
    folders,
    showBackendSize,
    hasSnowflakeDynamicBackendSize,
    patternComponents,
    hasJobsDynamicBackendSize,
    isDevModeActive,
    onHide,
    onSetTaskParams,
    onDeleteTask,
  }: Props) => {
    const componentId = task.get('componentId');
    const [previousConfigId, setPreviousConfigId] = useState<string | null>(null);

    useEffect(() => {
      ensureConfigurationsDetails(componentId);
    }, [componentId]);

    const flags = allComponents.getIn([task.get('componentId'), 'flags'], List());

    const handleSelectConfigWithRedirect = (
      event?: React.MouseEvent | null,
      componentId?: string,
      config?: string,
    ) => {
      const configurationId = config || task.get('configId');
      const realComponentId =
        componentId || resolveComponentId(allConfigurations, task, configurationId);

      return selectConfigWithRedirect(
        event ?? null,
        realComponentId,
        configurationId,
        task.get('id'),
        onSelectConfig,
        configId,
      );
    };

    const handleChangeConfiguration = () => {
      setPreviousConfigId(task.get('configId'));
      const realComponentId = resolveComponentId(allConfigurations, task, task.get('configId'));
      onEditTask(task.get('id'), [
        ...(componentId !== realComponentId
          ? [
              {
                keysPath: ['task', 'fakeComponentId'],
                value: componentId,
              },
            ]
          : []),
        {
          keysPath: ['task', 'configId'],
          value: '',
        },
      ]);
    };

    const handleSelectConfiguration = (configId: string) => {
      setPreviousConfigId(null);
      return onSelectConfig(
        task.get('id'),
        resolveComponentId(allConfigurations, task, configId),
        configId,
      );
    };

    const handleBack = () => {
      if (!previousConfigId) {
        return;
      }

      return handleSelectConfiguration(previousConfigId);
    };

    const configurationName = allConfigurations.getIn(
      [componentId, 'configurations', task.get('configId'), 'name'],
      '',
    );

    const isConfigDetail = !!task.get('configId');

    const customType =
      task.get('componentId') === KEBOOLA_ORCHESTRATOR
        ? 'Flow'
        : task.get('componentId') === KEBOOLA_DATA_APPS
          ? 'Data App'
          : '';

    const type = task.get('type') || (isConfigDetail ? customType : '');

    const typeColor = !!task.get('type')
      ? getComponentTypeColorClassName(task.get('type'))
      : 'tw-text-neutral-400';

    const renderBody = () => {
      if (isConfigDetail) {
        return (
          <ConfigurationDetail
            task={task}
            readOnly={readOnly}
            lastJob={lastJob}
            hideContinueOnFailure
            onEditTask={onEditTask}
            onSelectConfigWithRedirect={handleSelectConfigWithRedirect}
            allConfigurations={allConfigurations}
            showBackendSize={showBackendSize}
            hasSnowflakeDynamicBackendSize={hasSnowflakeDynamicBackendSize}
            hasJobsDynamicBackendSize={hasJobsDynamicBackendSize}
            isDevModeActive={isDevModeActive}
            onSetTaskParams={onSetTaskParams}
            onDeleteTask={onDeleteTask}
            onBack={handleChangeConfiguration}
          />
        );
      }

      return (
        <ComponentDetail
          readOnly={readOnly}
          hasPayAsYouGo={hasPayAsYouGo}
          task={task}
          onSelectConfig={handleSelectConfiguration}
          onSelectConfigWithRedirect={handleSelectConfigWithRedirect}
          patternComponents={patternComponents}
          allConfigurations={allConfigurations}
          allComponents={allComponents}
          folders={folders}
          isDevModeActive={isDevModeActive}
        />
      );
    };

    return (
      <SidebarModal onHide={onHide}>
        <SidebarModalHeader onHide={onHide}>
          <div className="tw-flex tw-w-full tw-items-center">
            {!!previousConfigId && (
              <IconButton
                className="tw-mr-4"
                variant="outline"
                onClick={handleBack}
                icon="angle-left"
              />
            )}
            <TaskIcon src={task.get('iconUrl')} size={32} />
            <div className="font-medium">
              <div className="tw-flex">
                <Truncated
                  className="tw-text-sm"
                  text={task.get('configId') ? configurationName : task.get('component')}
                />
                {task.get('componentId') !== KEBOOLA_DATA_APPS &&
                  task.get('componentId') !== KEBOOLA_ORCHESTRATOR && (
                    <ComponentBadges flags={flags} className="tw-ml-2" />
                  )}
              </div>

              {!!type && <div className={cn('tw-text-xs', typeColor)}>{type}</div>}
            </div>
          </div>
        </SidebarModalHeader>
        <Modal.Body className="tw-flex tw-grow tw-flex-col tw-gap-4 tw-overflow-hidden tw-p-1">
          {renderBody()}
        </Modal.Body>
      </SidebarModal>
    );
  },
);
