import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import { ButtonGroup, cn, IconButton, Tooltip } from '@keboola/design';

import FullScreenModal, { FullScreenModalHeader } from './FullScreenModal';

class FullScreenEditor extends Component {
  componentDidMount() {
    this.props.onEnter?.();
  }

  render() {
    return (
      <FullScreenModal
        className={cn('full-screen-editor', this.props.className)}
        onHide={() => {
          if (!this.props.disableEscClose && !this.props.closeDisabledReason) {
            this.props.onClose();
          }
        }}
      >
        <FullScreenModalHeader
          title={this.props.renderTitle()}
          isTitleWithIcon
          onClose={this.props.onClose}
        >
          <ButtonGroup space="medium">
            {this.props.renderButtons && this.props.renderButtons()}
            {this.props.renderCloseButton
              ? this.props.renderCloseButton()
              : this.renderCloseButton()}
          </ButtonGroup>
        </FullScreenModalHeader>

        <Modal.Body>{this.props.renderEditor()}</Modal.Body>
      </FullScreenModal>
    );
  }

  renderCloseButton() {
    if (this.props.closeDisabledReason) {
      return (
        <Tooltip placement="bottom" tooltip={this.props.closeDisabledReason} type="explanatory">
          <IconButton variant="outline" icon="xmark" disabled />
        </Tooltip>
      );
    }

    return <IconButton variant="outline" icon="xmark" onClick={this.props.onClose} />;
  }
}

FullScreenEditor.propTypes = {
  renderTitle: PropTypes.func.isRequired,
  renderEditor: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  renderButtons: PropTypes.func,
  renderCloseButton: PropTypes.func,
  closeDisabledReason: PropTypes.string,
  className: PropTypes.string,
  disableEscClose: PropTypes.bool,
};

FullScreenEditor.defaultProps = {
  disableEscClose: false,
};

export default FullScreenEditor;
