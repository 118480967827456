import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { Alert, Button, Icon } from '@keboola/design';

import * as componentFlags from '@/constants/componentFlags';
import {
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_EXASOL_TRANSFORMATION,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_REDSHIFT_TRANSFORMATION,
  KEBOOLA_SANDBOXES,
  KEBOOLA_SHARED_CODE,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
  KEBOOLA_TERADATA_TRANSFORMATION,
  KEBOOLA_VARIABLES,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_AI_CONVERSATIONS, FEATURE_IS_SINGLE_TENANT } from '@/constants/features';
import { canResetState } from '@/modules/admin/privileges';
import { getAutomationFromFlowMetadata } from '@/modules/automations/helpers';
import {
  getAllowedTransformations,
  getDestinationTypeFromStagingStorage,
  getSourceTypeFromStagingStorage,
  hasDevModeProblematicMapping,
  hasGenericCodeBlocksUI,
  hasGenericDockerUI,
  hasGenericPackagesUI,
  hasGenericTemplatesUI,
  hasGenericUI,
  hasSingleSyncAction,
  hasVariables,
  removeSingleSyncActionFromSchema,
  supportsDynamicBackendSize,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import BackendSizeSelect from '@/modules/components/react/components/BackendSizeSelect';
import CodeBlocksHeader from '@/modules/components/react/components/CodeBlocksHeader';
import Configuration from '@/modules/components/react/components/Configuration';
import CreateWorkspaceButton from '@/modules/components/react/components/CreateWorkspaceButton';
import CodeBlocksConfiguration from '@/modules/components/react/components/generic/code-blocks/CodeBlocksConfiguration';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import FileOutputMapping from '@/modules/components/react/components/generic/FileOutputMapping';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import TableInputMappingReadOnlyInfo from '@/modules/components/react/components/generic/TableInputMappingReadOnlyInfo';
import TableOutputMapping from '@/modules/components/react/components/generic/TableOutputMapping';
import {
  getMissingSharedCodesVariables,
  prepareVariables,
} from '@/modules/components/react/components/generic/variables/helpers';
import Variables from '@/modules/components/react/components/generic/variables/Variables';
import VariablesOverridePanel from '@/modules/components/react/components/generic/variables/VariablesOverridePanel';
import GenericSyncActionsButton from '@/modules/components/react/components/GenericSyncActionsButton';
import MappingsWrapper from '@/modules/components/react/components/MappingsWrapper';
import Processors from '@/modules/components/react/components/Processors';
import QueryTimeoutModal from '@/modules/components/react/components/QueryTimeoutModal';
import SampleDataDetail from '@/modules/components/react/components/SampleDataDetail';
import TemplatedConfiguration from '@/modules/components/react/components/TemplatedConfiguration';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import ClearStateButton from '@/modules/configurations/react/components/ClearStateButton';
import { isEmptyComponentState } from '@/modules/configurations/utils/configurationState';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { routeNames as oracleTransformationRouteNames } from '@/modules/oracle-transformation/constants';
import GenericPatternUi from '@/modules/pattern/react/GenericPatternUi';
import { ONLY_READONLY_STORAGE } from '@/modules/sandboxes/Constants';
import {
  hasSandbox,
  prepareSandboxes,
  resolveSandboxTypeFromComponentId,
} from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import StackFeaturesStore from '@/modules/stack-features/Store';
import Packages from '@/modules/transformations/react/pages/transformation-detail/Packages';
import { RouterLink } from '@/react/common';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import CatchUnsavedRunWarning from '@/react/common/CatchUnsavedRunWarning';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import contactSupport from '@/utils/contactSupport';
import fromJSOrdered from '@/utils/fromJSOrdered';
import { isValidJsonConfig } from '@/utils/validation';
import { GenericConfigBody } from './GenericConfigBody';
import GenericRows from './GenericRows';

const GenericDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      StackFeaturesStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      VersionsStore,
      ConfigurationRowsStore,
      SandboxesStore,
      RoutesStore,
    ),
  ],

  getStateFromStores() {
    const configId = RoutesStore.getCurrentRouteParam('config');
    const componentId = RoutesStore.getCurrentRouteParam('component');
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);
    const sapiToken = ApplicationStore.getSapiToken();
    const allConfigurations = InstalledComponentsStore.getAll();
    const componentsMetadata = InstalledComponentsStore.getAllMetadata();
    const sharedCodes = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SHARED_CODE);
    const componentSharedCodes = sharedCodes.find(
      (config) => config.getIn(['configuration', 'componentId']) === componentId,
      null,
      Map(),
    );
    const allSharedCodes = componentSharedCodes.get('rows', List());
    const variables = InstalledComponentsStore.getComponentConfigurations(KEBOOLA_VARIABLES);
    const variablesConfigData = variables.get(configData.get('variables_id'), Map());
    const variablesConfiguration = variablesConfigData.get('configuration', Map());
    const variablesConfigurationRow = variablesConfigData.get('rows', List()).find(
      (row) => {
        return row.get('id') === configData.get('variables_values_id', null);
      },
      null,
      Map(),
    );
    const preparedVariables = prepareVariables(allConfigurations, componentId, configId);
    const editingConfigDataParameters = InstalledComponentsStore.getEditingRawConfigDataParameters(
      componentId,
      configId,
      configData.hasIn(['runtime', 'codePattern', 'componentId'])
        ? configData.getIn(['runtime', 'codePattern', 'parameters'], Map())
        : configData.get('parameters', Map()),
    );
    const allowedTransformationComponents = getAllowedTransformations(
      ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
      sapiToken,
      ApplicationStore.getCurrentProjectFeatures(),
      StackFeaturesStore.getAll(),
    );
    const component = ComponentsStore.getComponent(componentId) || Map();
    const { automationId, isDraft: isAutomationView } = getAutomationFromFlowMetadata(
      RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']),
      InstalledComponentsStore.getAllMetadata(),
    );

    return {
      componentId,
      configId,
      component,
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      readOnly: ApplicationStore.isReadOnly(),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(componentId, configId),
      configData: configData,
      rows: ConfigurationRowsStore.getRows(componentId, configId),
      editingConfigData: InstalledComponentsStore.getEditingRawConfigData(componentId, configId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      isChanged: InstalledComponentsStore.isChangedRawConfigData(componentId, configId),
      isTemplatedChanged: InstalledComponentsStore.isChangedTemplatedConfig(componentId, configId),
      isParametersChanged: InstalledComponentsStore.isChangedRawConfigDataParameters(
        componentId,
        configId,
      ),
      isSaving: InstalledComponentsStore.isSavingConfigData(componentId, configId),
      editingConfigDataParameters,
      isValidEditingConfigData: InstalledComponentsStore.isValidEditingConfigData(
        componentId,
        configId,
      ),
      isValidEditingConfigDataParameters: isValidJsonConfig(editingConfigDataParameters),
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      componentSharedCodes,
      sharedCodes: allSharedCodes,
      variablesConfiguration: variablesConfiguration,
      variablesConfigurationRow: variablesConfigurationRow,
      preparedVariables,
      missingVariables: getMissingSharedCodesVariables(
        configData,
        preparedVariables,
        allSharedCodes,
        variables,
      ),
      allComponents: ComponentsStore.getAll(),
      allConfigurations,
      componentsMetadata,
      allowedTransformationComponents,
      allowedCreateWorkspace: allowedTransformationComponents.filter((component, componentId) =>
        hasSandbox(componentId),
      ),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      isSingleTenant: StackFeaturesStore.hasStackFeature(FEATURE_IS_SINGLE_TENANT),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
      ),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasSnowflakeDynamicBackendSize: ApplicationStore.hasSnowflakeDynamicBackendSize(),
      hasJobsDynamicBackendSize: ApplicationStore.hasJobsDynamicBackendSize(),
      canResetState: canResetState(sapiToken, component),
      hasAiConversations:
        ApplicationStore.hasCurrentProjectFeature(FEATURE_AI_CONVERSATIONS) &&
        componentId === KEBOOLA_SNOWFLAKE_TRANSFORMATION,
      hasRowsFlag: component.get('flags', List()).includes(componentFlags.GENERIC_DOCKER_UI_ROWS),
      automationId,
      isAutomationView,
    };
  },

  getInitialState() {
    return {
      variablesOverride: Map(),
    };
  },

  render() {
    return (
      <GenericConfigBody
        key={`${this.state.componentId}-${this.state.configId}`}
        componentId={this.state.componentId}
        configId={this.state.configId}
        sidebarProps={{
          run: {
            forceModal:
              this.hasPreparedVariables() ||
              this.hasDevModeProblematicMapping() ||
              this.state.isParametersChanged ||
              this.state.isTemplatedChanged,
            params: this.runParams(),
            disabled:
              this.state.hasRowsFlag && this.state.rows.isEmpty()
                ? 'There are no rows configured.'
                : '',
            title:
              this.state.component.get('type') === componentTypes.TRANSFORMATION
                ? 'Run transformation'
                : 'Run component',
            text: (
              <>
                {(this.state.isParametersChanged || this.state.isTemplatedChanged) && (
                  <CatchUnsavedRunWarning />
                )}
                <p>
                  You are about to run the{' '}
                  {this.state.component.get('type') === componentTypes.TRANSFORMATION
                    ? 'transformation'
                    : 'component'}
                  .
                </p>
                {this.renderDevModeWarning()}
                {this.renderVariablesOverride()}
              </>
            ),
          },
          additionalButtons: this.additionalButtons(),
        }}
      >
        {this.renderBody()}
      </GenericConfigBody>
    );
  },

  additionalButtons() {
    const additionalButtons = [];

    if (
      [
        KEBOOLA_SNOWFLAKE_TRANSFORMATION,
        KEBOOLA_REDSHIFT_TRANSFORMATION,
        KEBOOLA_SYNAPSE_TRANSFORMATION,
        KEBOOLA_EXASOL_TRANSFORMATION,
        KEBOOLA_TERADATA_TRANSFORMATION,
        KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
      ].includes(this.state.componentId)
    ) {
      additionalButtons.push(
        <QueryTimeoutModal
          config={this.state.config}
          componentId={this.state.componentId}
          defaultTimeout={this.state.component.getIn([
            'data',
            'image_parameters',
            'default_query_timeout',
          ])}
          readOnly={this.state.readOnly}
        />,
      );
    }

    if (
      supportsDynamicBackendSize(this.state.component) &&
      this.state.hasNewQueue &&
      !this.state.readOnly
    ) {
      additionalButtons.push(
        <BackendSizeSelect
          componentId={this.state.componentId}
          currentSize={this.state.config.getIn(['configuration', 'runtime', 'backend', 'type'])}
          changeBackendSize={(newBackendSize) =>
            InstalledComponentsActionCreators.updateComponentConfiguration(
              this.state.componentId,
              this.state.config.get('id'),
              {
                configuration: JSON.stringify(
                  this.state.config
                    .get('configuration')
                    .setIn(['runtime', 'backend', 'type'], newBackendSize)
                    .toJS(),
                ),
              },
              'Change transformation backend size',
            )
          }
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          hasSnowflakeDynamicBackendSize={this.state.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={this.state.hasJobsDynamicBackendSize}
          isSingleTenant={this.state.isSingleTenant}
        />,
      );
    }

    if (this.state.componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
      additionalButtons.push(
        <RouterLink
          to={oracleTransformationRouteNames.TRANSFORMATION_CREDENTIALS}
          params={{
            component: this.state.componentId,
            config: this.state.configId,
          }}
        >
          <Icon icon="user" fixedWidth />
          Database credentials
        </RouterLink>,
      );
    }

    if (!this.state.readOnly && this.state.allowedCreateWorkspace.has(this.state.componentId)) {
      additionalButtons.push(
        <CreateWorkspaceButton
          key="create-workspace"
          componentId={this.state.componentId}
          allowedComponents={this.state.allowedCreateWorkspace}
          config={this.state.config}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
          workspaces={this.state.sandboxes}
          metadata={this.state.componentsMetadata}
        />,
      );
    }

    if (this.state.canResetState) {
      additionalButtons.push(
        <ClearStateButton
          key="clear-state"
          onClick={this.onResetState}
          disabled={isEmptyComponentState(this.state.config.get('state', Map()))}
        />,
      );
    }

    if (hasSingleSyncAction(this.state.component.get('configurationSchema'))) {
      additionalButtons.push(
        <GenericSyncActionsButton
          key="sync-actions-button"
          configId={this.state.configId}
          componentId={this.state.componentId}
          schema={this.state.component.get('configurationSchema')}
        />,
      );
    }

    return additionalButtons;
  },

  renderBody() {
    if (this.state.componentId === KEBOOLA_EX_SAMPLE_DATA) {
      return (
        <SampleDataDetail
          allTables={this.state.tables}
          component={this.state.allComponents.get(
            this.state.configData.getIn(['parameters', 'componentId']),
          )}
          name={this.state.config.get('name')}
          hasNewQueue={this.state.hasNewQueue}
          configurationId={this.state.configId}
        />
      );
    }

    if (this.state.configData.hasIn(['runtime', 'codePattern', 'componentId'])) {
      const patternComponent = this.state.allComponents.get(
        this.state.configData.getIn(['runtime', 'codePattern', 'componentId']),
      );

      return (
        <CatchUnsavedChanges
          isDirty={this.state.isParametersChanged}
          onSave={this.onGenericPatternSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigDataParameters}
          onDirtyLeave={this.onEditParametersCancel}
        >
          <GenericPatternUi
            readOnly={this.state.readOnly}
            configId={this.state.configId}
            component={this.state.component}
            tables={this.state.tables}
            buckets={this.state.buckets}
            config={this.state.config}
            configData={this.state.configData}
            pattern={patternComponent}
            parameters={this.state.editingConfigDataParameters}
            parametersIsChanged={this.state.isParametersChanged}
            isSaving={this.state.isSaving}
            parametersOnEditCancel={this.onEditParametersCancel}
            parametersOnEditChange={this.onEditParametersChange}
            parametersOnEditSubmit={this.onGenericPatternSubmit}
            parametersIsValid={this.state.isValidEditingConfigDataParameters}
            isDevModeActive={this.state.isDevModeActive}
            allowedComponents={this.state.allowedTransformationComponents}
            sandboxes={this.state.sandboxes}
            hasPayAsYouGo={this.state.hasPayAsYouGo}
          />
        </CatchUnsavedChanges>
      );
    }

    return (
      <>
        <MappingsWrapper>
          {this.tableInputMapping()}
          {this.fileInputMapping()}
          {this.tableOutputMapping()}
          {this.fileOutputMapping()}
        </MappingsWrapper>
        {this.renderCodeBlocksHeader()}
        {this.renderPackages()}
        {this.renderVariables()}
        {this.configuration()}
        {this.configurationRows()}
        {this.processorsConfiguration()}
      </>
    );
  },

  renderVariablesOverride() {
    if (!this.hasPreparedVariables()) {
      return null;
    }

    return (
      <VariablesOverridePanel
        allConfigurations={this.state.allConfigurations}
        componentId={this.state.componentId}
        configId={this.state.configId}
        onChangeFn={(variablesOverride) =>
          this.setState({
            variablesOverride,
          })
        }
      />
    );
  },

  tableInputMapping() {
    if (ONLY_READONLY_STORAGE.includes(resolveSandboxTypeFromComponentId(this.state.componentId))) {
      return <TableInputMappingReadOnlyInfo />;
    }

    if (
      !this.state.component
        .get('flags', List())
        .includes(componentFlags.GENERIC_DOCKER_UI_TABLE_INPUT) ||
      this.state.hasRowsFlag
    ) {
      return null;
    }

    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        destinationType={getDestinationTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'input']),
          this.state.componentId,
        )}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        allowedComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  fileInputMapping() {
    if (
      !this.state.component
        .get('flags', List())
        .includes(componentFlags.GENERIC_DOCKER_UI_FILE_INPUT) ||
      this.state.hasRowsFlag
    ) {
      return null;
    }

    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        allowedComponents={this.state.allowedTransformationComponents}
        sandboxes={this.state.sandboxes}
        hasPayAsYouGo={this.state.hasPayAsYouGo}
      />
    );
  },

  tableOutputMapping() {
    if (
      !this.state.component
        .get('flags', List())
        .includes(componentFlags.GENERIC_DOCKER_UI_TABLE_OUTPUT) ||
      this.state.hasRowsFlag
    ) {
      return null;
    }

    return (
      <TableOutputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        configName={this.state.config.get('name')}
        value={this.state.configData.getIn(['storage', 'output', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        sourceType={getSourceTypeFromStagingStorage(
          this.state.component.getIn(['data', 'staging_storage', 'output']),
        )}
      />
    );
  },

  fileOutputMapping() {
    if (
      !this.state.component
        .get('flags', List())
        .includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT) ||
      this.state.hasRowsFlag
    ) {
      return null;
    }

    return (
      <FileOutputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'output', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
      />
    );
  },

  renderCodeBlocksHeader() {
    if (!hasGenericCodeBlocksUI(this.state.component)) {
      return null;
    }

    return (
      <CodeBlocksHeader
        readOnly={this.state.readOnly}
        config={this.state.config}
        component={this.state.component}
        blocks={this.state.configData.getIn(['parameters', 'blocks'], List())}
        sharedCodes={this.state.sharedCodes}
        tables={this.state.tables}
        variables={this.state.variablesConfigurationRow}
      />
    );
  },

  renderPackages() {
    if (!hasGenericPackagesUI(this.state.component)) {
      return null;
    }

    return (
      <Packages
        disabled={this.state.readOnly}
        componentId={this.state.componentId}
        packages={this.state.configData.getIn(['parameters', 'packages'], List())}
        onEditChange={(packages) => {
          const configData = this.state.configData.setIn(['parameters', 'packages'], packages);

          return InstalledComponentsActionCreators.updateComponentConfiguration(
            this.state.componentId,
            this.state.configId,
            { configuration: JSON.stringify(configData.toJS()) },
            'Change packages',
          );
        }}
        forGenericComponents
      />
    );
  },

  renderDevModeWarning() {
    if (!this.hasDevModeProblematicMapping()) {
      return null;
    }

    return (
      <Alert variant="warning" className="tw-mb-5">
        Using the same table on the input and output mapping with incremental load turned on may
        cause unexpected results. Please create the bucket and the specific table in the development
        branch first, or turn the incremental load off.
      </Alert>
    );
  },

  renderVariables() {
    if (!hasVariables()) {
      return null;
    }

    return (
      <Variables
        readOnly={this.state.readOnly}
        mainConfiguration={this.state.configData}
        mainComponentId={this.state.componentId}
        mainConfigurationId={this.state.configId}
        configuration={this.state.variablesConfiguration}
        row={this.state.variablesConfigurationRow}
        variables={this.state.preparedVariables}
        missingVariables={this.state.missingVariables}
        configVariablesId={this.state.configData.get('variables_id', null)}
        configVariablesValuesId={this.state.configData.get('variables_values_id', null)}
      />
    );
  },

  runParams() {
    if (this.state.variablesOverride.count() > 0) {
      return () => ({
        config: this.state.configId,
        variableValuesData: {
          values: this.state.preparedVariables.map((value) => {
            return {
              name: value.get('name'),
              value: this.state.variablesOverride.get(value.get('name'), value.get('value')),
            };
          }),
        },
      });
    }

    return () => ({ config: this.state.configId });
  },

  configuration() {
    if (hasGenericCodeBlocksUI(this.state.component)) {
      return (
        <CodeBlocksConfiguration
          readOnly={this.state.readOnly}
          component={this.state.component}
          configId={this.state.configId}
          configuration={this.state.config}
          sharedCodes={this.state.sharedCodes}
          componentSharedCodes={this.state.componentSharedCodes}
          variables={this.state.variablesConfigurationRow}
          tables={this.state.tables}
          hasAiConversations={this.state.hasAiConversations}
          automationId={this.state.automationId}
          isAutomationView={this.state.isAutomationView}
        />
      );
    }

    if (hasGenericTemplatesUI(this.state.component)) {
      return <TemplatedConfiguration />;
    }

    if (hasGenericUI(this.state.component)) {
      return (
        <CatchUnsavedChanges
          isDirty={this.state.isChanged}
          onSave={this.onEditSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigData}
          onDirtyLeave={this.onEditCancel}
        >
          <Configuration
            readOnly={this.state.readOnly}
            component={this.state.component}
            data={this.state.editingConfigData}
            isSaving={this.state.isSaving}
            onEditCancel={this.onEditCancel}
            onEditChange={this.onEditChange}
            isChanged={this.state.isChanged}
            onEditSubmit={this.onEditSubmit}
            isValid={this.state.isValidEditingConfigData}
          />
        </CatchUnsavedChanges>
      );
    }

    if (hasGenericDockerUI(this.state.component)) {
      return (
        <CatchUnsavedChanges
          isDirty={this.state.isParametersChanged}
          onSave={this.onEditParametersSubmit}
          isSaveDisabled={!this.state.isValidEditingConfigDataParameters}
          onDirtyLeave={this.onEditParametersCancel}
        >
          <Configuration
            readOnly={this.state.readOnly}
            component={this.state.component}
            data={this.state.editingConfigDataParameters}
            isChanged={this.state.isParametersChanged}
            isSaving={this.state.isSaving}
            onEditCancel={this.onEditParametersCancel}
            onEditChange={this.onEditParametersChange}
            onEditSubmit={this.onEditParametersSubmit}
            isValid={this.state.isValidEditingConfigDataParameters}
            schema={removeSingleSyncActionFromSchema(
              this.state.component.get('configurationSchema'),
            )}
            hasRowsFlag={this.state.hasRowsFlag}
          />
        </CatchUnsavedChanges>
      );
    }

    return (
      <div className="box">
        <div className="box-content">
          <p>
            This component has to be configured manually. Please contact our support team for
            assistance.
          </p>
          <Button onClick={() => contactSupport()}>Contact Support</Button>
        </div>
      </div>
    );
  },

  configurationRows() {
    if (!this.state.hasRowsFlag) {
      return null;
    }

    return (
      <GenericRows
        readOnly={this.state.readOnly}
        rows={this.state.rows}
        tables={this.state.tables}
        buckets={this.state.buckets}
        config={this.state.config}
        component={this.state.component}
      />
    );
  },

  processorsConfiguration() {
    if (
      (this.state.hasRowsFlag && !this.state.configData.has('processors')) ||
      (![componentTypes.EXTRACTOR, componentTypes.WRITER].includes(
        this.state.component.get('type', ''),
      ) &&
        !this.state.component
          .get('flags', List())
          .includes(componentFlags.GENERIC_DOCKER_UI_PROCESSORS) &&
        !this.state.configData.has('processors'))
    ) {
      return null;
    }

    return (
      <Processors
        value={this.state.configData.get('processors', Map())}
        readOnly={this.state.readOnly}
        onSubmit={this.onProcessorsSubmit}
      />
    );
  },

  onProcessorsSubmit(processors) {
    const configData = processors
      ? this.state.configData.set('processors', processors)
      : this.state.configData.delete('processors');

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update processors configuration',
    );
  },

  onEditCancel() {
    InstalledComponentsActionCreators.cancelEditComponentRawConfigData(
      this.state.componentId,
      this.state.configId,
    );
  },

  onEditChange(newValue) {
    InstalledComponentsActionCreators.updateEditComponentRawConfigData(
      this.state.componentId,
      this.state.configId,
      newValue,
    );
  },

  onEditSubmit() {
    const configData = fromJSOrdered(JSON.parse(this.state.editingConfigData));

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update configuration',
    );
  },

  onEditParametersCancel() {
    InstalledComponentsActionCreators.cancelEditComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId,
    );
  },

  onEditParametersChange(newValue) {
    InstalledComponentsActionCreators.updateEditComponentRawConfigDataParameters(
      this.state.componentId,
      this.state.configId,
      newValue,
    );
  },

  onGenericPatternSubmit() {
    const configData = this.state.configData.setIn(
      ['runtime', 'codePattern', 'parameters'],
      fromJS(JSON.parse(this.state.editingConfigDataParameters)).toOrderedMap(),
    );

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update parameters',
    );
  },

  onEditParametersSubmit() {
    const configData = this.state.configData.set(
      'parameters',
      fromJS(JSON.parse(this.state.editingConfigDataParameters)).toOrderedMap(),
    );

    return InstalledComponentsActionCreators.saveComponentConfigData(
      this.state.componentId,
      this.state.configId,
      configData,
      'Update parameters',
    );
  },

  onResetState() {
    return InstalledComponentsActionCreators.resetComponentConfigurationState(
      this.state.componentId,
      this.state.configId,
    );
  },

  resetStateEnable() {
    return (
      !this.state.readOnly &&
      this.state.component
        .get('flags', List())
        .includes(componentFlags.GENERIC_DOCKER_UI_RESET_STATE)
    );
  },

  hasPreparedVariables() {
    return hasVariables() && this.state.preparedVariables.count() > 0;
  },

  hasDevModeProblematicMapping() {
    return hasDevModeProblematicMapping(
      DevBranchesStore.getCurrentId(),
      this.state.configData.get('storage', Map()),
      this.state.tables,
    );
  },
});

export default GenericDetail;
