import Promise from 'bluebird';
import _ from 'underscore';

import * as serviceIds from '@/constants/serviceIds';
import HttpError from '@/utils/errors/HttpError';
import SimpleError from '@/utils/errors/SimpleError';
import * as _Api from './routes/index';
type _Api = typeof _Api;

// When re-generating the API routes, this object should be modified
// to include any new API routes, remove old ones, or to update
// existing ones with new response types.
const Api = {
  templatesService: {
    TemplatesIndex: def(
      _Api.templatesService.TemplatesIndex,
    ).body<_Api.templatesService.Templates>(),
    VersionIndex: def(
      _Api.templatesService.VersionIndex,
    ).body<_Api.templatesService.VersionDetailExtended>(),
    InputsIndex: def(_Api.templatesService.InputsIndex).body<_Api.templatesService.Inputs>(),
    ValidateInputs: def(
      _Api.templatesService.ValidateInputs,
    ).body<_Api.templatesService.ValidationResult>(),
    UseTemplateVersion: def(_Api.templatesService.UseTemplateVersion).body<
      _Api.templatesService.ValidationError | _Api.templatesService.Task
    >(),
    InstancesIndex: def(
      _Api.templatesService.InstancesIndex,
    ).body<_Api.templatesService.Instances>(),
    InstanceIndex: def(
      _Api.templatesService.InstanceIndex,
    ).body<_Api.templatesService.InstanceDetail>(),
    UpdateInstance: def(
      _Api.templatesService.UpdateInstance,
    ).body<_Api.templatesService.InstanceDetail>(),
    UpgradeInstanceInputsIndex: def(
      _Api.templatesService.UpgradeInstanceInputsIndex,
    ).body<_Api.templatesService.Inputs>(),
    UpgradeInstanceValidateInputs: def(
      _Api.templatesService.UpgradeInstanceValidateInputs,
    ).body<_Api.templatesService.ValidationResult>(),
    UpgradeInstance: def(_Api.templatesService.UpgradeInstance).body<
      _Api.templatesService.ValidationError | _Api.templatesService.Task
    >(),
    DeleteInstance: def(_Api.templatesService.DeleteInstance).body<
      _Api.templatesService.ValidationError | _Api.templatesService.Task
    >(),
    GetTask: def(_Api.templatesService.GetTask).body<_Api.templatesService.Task>(),
  },
  streamService: {
    ListSources: def(_Api.streamService.ListSources).body<
      _Api.streamService.SourcesList | _Api.streamService.GenericError
    >(),
    CreateSource: def(_Api.streamService.CreateSource).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    DeleteSource: def(_Api.streamService.DeleteSource).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    GetSource: def(_Api.streamService.GetSource).body<
      _Api.streamService.Source | _Api.streamService.GenericError
    >(),
    UpdateSource: def(_Api.streamService.UpdateSource).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    TestSource: def(_Api.streamService.TestSource).body<
      _Api.streamService.TestResult | _Api.streamService.GenericError
    >(),
    ListSinks: def(_Api.streamService.ListSinks).body<
      _Api.streamService.SinksList | _Api.streamService.GenericError
    >(),
    CreateSink: def(_Api.streamService.CreateSink).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    DeleteSink: def(_Api.streamService.DeleteSink).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    GetSink: def(_Api.streamService.GetSink).body<
      _Api.streamService.Sink | _Api.streamService.GenericError
    >(),
    UpdateSink: def(_Api.streamService.UpdateSink).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    UpdateSinkSettings: def(_Api.streamService.UpdateSinkSettings).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
    GetSinkSettings: def(_Api.streamService.GetSinkSettings).body<
      _Api.streamService.SettingsResult | _Api.streamService.GenericError
    >(),
    SinkStatisticsFiles: def(_Api.streamService.SinkStatisticsFiles).body<
      _Api.streamService.SinkStatisticsFilesResult | _Api.streamService.GenericError
    >(),
    SinkStatisticsTotal: def(_Api.streamService.SinkStatisticsTotal).body<
      _Api.streamService.SinkStatisticsTotalResult | _Api.streamService.GenericError
    >(),
    SinkStatisticsClear: def(_Api.streamService.SinkStatisticsClear).body(),
    GetTask: def(_Api.streamService.GetTask).body<
      _Api.streamService.Task | _Api.streamService.GenericError
    >(),
  },
  dataScienceService: {
    getApp: def(_Api.dataScienceService.getApp).body<_Api.dataScienceService.ExistingApp>(),
    listApps: def(_Api.dataScienceService.listApps).body<_Api.dataScienceService.ExistingApp[]>(),
    createApp: def(_Api.dataScienceService.createApp).body<_Api.dataScienceService.ExistingApp>(),
    patchApp: def(_Api.dataScienceService.patchApp).body(),
    deleteApp: def(_Api.dataScienceService.deleteApp).body(),
    getAppPassword: def(_Api.dataScienceService.getAppPassword).body<{ password: string }>(),
    resetAppPassword: def(_Api.dataScienceService.resetAppPassword).body(),
    runtimes: def(_Api.dataScienceService.runtimes).body<_Api.dataScienceService.Runtime>(),
  },
  aiService: {
    explainError: def(_Api.aiService.explainError).body<_Api.aiService.ErrorExplanation>(),
    describeConfiguration: def(
      _Api.aiService.describeConfiguration,
    ).body<_Api.aiService.ObjectDescription>(),
    feedback: def(_Api.aiService.feedback).body(),
    createConversation: def(_Api.aiService.createConversation).body<_Api.aiService.Conversation>(),
    getConversation: def(_Api.aiService.getConversation).body<_Api.aiService.Conversation>(),
    deleteConversation: def(_Api.aiService.deleteConversation).body(),
    generateQuery: def(
      _Api.aiService.generateQuery,
    ).body<_Api.aiService.ConversationGenerateQueryResponse>(),
    executeTransformation: def(_Api.aiService.executeTransformation).body(),
    getLastResult: def(
      _Api.aiService.getLastResult,
    ).body<_Api.aiService.ConversationExecuteQueryResponse>(),
    getOutputTables: def(
      _Api.aiService.getOutputTables,
    ).body<_Api.aiService.GetOutputTablesResponse>(),
    suggestComponent: def(
      _Api.aiService.suggestComponent,
    ).body<_Api.aiService.ComponentSuggestion>(),
    postMessageToAutomation: def(
      _Api.aiService.postMessageToAutomation,
    ).body<_Api.aiService.AgentMessage>(),
    getAutomation: def(_Api.aiService.getAutomation).body<_Api.aiService.AutomationDetail>(),
    deleteAutomation: def(_Api.aiService.deleteAutomation).body(),
    createAutomation: def(_Api.aiService.createAutomation).body<_Api.aiService.AutomationDetail>(),
    getAutomationMessages: def(
      _Api.aiService.getAutomationMessages,
    ).body<_Api.aiService.AutomationMessagesListResponse>(),
  },
};

// ensure that all generated api routes are wrapped
// eslint-disable-next-line
const _check: {
  [M in keyof _Api]: {
    [R in keyof _Api[M] as R extends 'init' ? never : R]?: (...args: any[]) => any;
  };
} = Api;

const MAXIMUM_RETRIES = 5;

// Wraps native `fetch` in order to
// - Return Bluebird `Promise` instead of native one.
// - Handle error response by wrapping it in `HttpError`.
// - Retry on 503 status code.
function fetchWrapper<Body>(
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  retry = 1,
): Promise<Body> {
  if (init?.method) {
    init.method = init.method.toUpperCase();
  }

  return Promise.resolve().then(() => {
    return fetch(input, init).then((response) => {
      if (response.status === 503) {
        if (retry > MAXIMUM_RETRIES) {
          throw new SimpleError(
            'Maximum retries exceeded',
            'The project is locked because another operation is in progress. Please try again later.',
          );
        }

        return Promise.delay(Math.pow(3, retry) * 100).then(() => {
          return fetchWrapper(input, init, retry + 1);
        });
      }

      return response
        .json()
        .catch(_.noop)
        .then((body) => {
          if (response.ok) return body;
          throw new HttpError({
            body,
            status: response.status,
            statusCode: response.status,
          });
        });
    });
  });
}

type ServiceId = (typeof serviceIds)[keyof typeof serviceIds];
type Services = { id: ServiceId; url: string }[];

function getServiceUrl(services: Services, serviceId: ServiceId) {
  const service = services.find(({ id }) => id === serviceId);

  if (!service?.url) {
    // this fallback is primary to support "local" development for KBC team
    // it wont work properly but at least the app should start
    return window.location.origin;
  }

  return service.url;
}

export function init(services: Services, sapiToken: string) {
  _Api.templatesService.init(
    getServiceUrl(services, serviceIds.SERVICE_TEMPLATES),
    sapiToken,
    (...args) =>
      fetchWrapper(...args).catch((error) => {
        if (error.response && 'ValidationResult' in error.response) return error.response;
        throw error;
      }),
  );
  _Api.streamService.init(
    getServiceUrl(services, serviceIds.SERVICE_STREAM),
    sapiToken,
    fetchWrapper,
  );
  _Api.dataScienceService.init(
    getServiceUrl(services, serviceIds.SERVICE_DATA_SCIENCE),
    sapiToken,
    fetchWrapper,
  );
  _Api.aiService.init(getServiceUrl(services, serviceIds.SERVICE_AI), sapiToken, fetchWrapper);
}

// definition is done in two steps, because TypeScript currently
// does not support partial type parameter inference
function def<Args extends any[]>(
  route: (...args: Args) => globalThis.Promise<Response>,
): { body<Body>(): (...args: Args) => Promise<Body> } {
  return {
    body() {
      return route as any;
    },
  };
}

export default Api;
