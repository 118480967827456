import { flexRender } from '@tanstack/react-table';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import TableDivideRow from '@/react/common/TableDivideRow';
import { DEFAULT_FOLDER_NAME, type FolderRow } from './constants';
import { onTableRowKeyDown } from './helpers';

type Props = {
  row: FolderRow;
  entity?: string;
  customClasses: Map<string, any>;
  isFirst: boolean;
};

const FolderRow = (props: Props) => {
  if (props.row.original.data === DEFAULT_FOLDER_NAME) {
    if (props.isFirst) {
      return null;
    }

    return (
      <TableDivideRow className="text-muted tw-capitalize">
        {props.entity ? `${props.entity}s` : 'Configurations'} without folder
      </TableDivideRow>
    );
  }

  const rowAction = () => {
    if (props.entity) {
      InstalledComponentsActionCreators.toggleExpandedFolder(
        props.entity,
        props.row.original.data,
        !props.row.getIsExpanded(),
      );
    }
    props.row.toggleExpanded(!props.row.getIsExpanded());
  };

  return (
    <div
      tabIndex={0}
      role="button"
      className="tr folder-row clickable tw-group"
      onClick={rowAction}
      onKeyDown={onTableRowKeyDown(rowAction)}
    >
      {props.row.getVisibleCells().map((cell) => {
        return (
          <div
            key={cell.id}
            className={cn('td', props.customClasses.getIn([cell.column.id, 'td']))}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </div>
        );
      })}
    </div>
  );
};

export default FolderRow;
