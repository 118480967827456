import type { GenericFetchClient } from '../storageClient';

import type { ComponentConfig, ConfigType, GetConfigurationPath } from './types';

export const createComponentsAndConfigurations = (client: GenericFetchClient) => {
  const getConfiguration = async <T extends ConfigType = 'unknown'>(
    path: GetConfigurationPath,
    signal?: AbortSignal,
  ) => {
    const { data } = await client.get<ComponentConfig<T>, GetConfigurationPath>(
      '/branch/{branchId}/components/{componentId}/configs/{configId}',
      { path },
      { signal },
    );

    return data;
  };

  return {
    getConfiguration,
  };
};
