import { useCallback, useEffect, useState } from 'react';
import { MenuItem } from 'react-bootstrap';
import Promise from 'bluebird';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';
import Switch from 'rc-switch';

import { ButtonGroup, Tooltip } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { isAutomationSuccessfullyConfigured } from '@/modules/automations/helpers';
import PublishButton from '@/modules/automations/PublishButton';
import { useAutomation } from '@/modules/automations/queries';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import OneTimeNotificationButton from '@/modules/notifications/components/OneTimeNotificationButton';
import { clearLocalState, updateLocalStateValue } from '@/modules/orchestrations-v2/localState';
import JobsActionCreators from '@/modules/queue/actions';
import { JOB_FAILED_STATUSES, JOB_FINISHED_STATUSES } from '@/modules/queue/constants';
import JobsStore from '@/modules/queue/store';
import CatchUnsavedChanges from '@/react/common/CatchUnsavedChanges';
import DescriptionButton from '@/react/common/DescriptionButton';
import DescriptionModal from '@/react/common/DescriptionModal';
import JobTerminateButton from '@/react/common/JobTerminateButton';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import Timer from '@/utils/Timer';
import RunSelectedModal from './components/RunSelectedModal';
import TaskIcon from './components/TaskIcon';
import TaskName from './components/TaskName';
import useFlow from './hooks/useFlow';
import { saveFlow } from './actions';
import { filterDisabledTasks, prepareSelectedTasks, prepareTask } from './helpers';

const POLL_INTERVAL = 5 * 1000; // 5 seconds

const DetailHeader = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isTerminating, setIsTerminating] = useState(false);
  const [showRunModalAs, setShowRunModalAs] = useState<'retry' | 'empty' | null>(null);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  const currentAdmin = useStores(() => ApplicationStore.getCurrentAdmin(), [], [ApplicationStore]);
  const store = useFlow();
  const { data: automation } = useAutomation(store.automationId, store.isDraftAutomation);

  const pollRunningJob = useCallback(() => {
    if (!store.runningJob) {
      Timer.stop(pollRunningJob);
      return;
    }
    const runningJobId = store.runningJob.get('id');
    // reload the job
    return JobsActionCreators.loadJobForce(runningJobId).then(() => {
      const parentJob = JobsStore.get(runningJobId);
      // job is not done, reload its children + trigger update
      return JobsActionCreators.loadChildJobsForce(parentJob).then(() => {
        updateLocalStateValue(store.configId, ['runningJob'], parentJob);
        if (JOB_FINISHED_STATUSES.includes(parentJob.get('status'))) {
          // if job is done, stop polling
          Timer.stop(pollRunningJob);
          setIsTerminating(false);
        }
        return Promise.resolve();
      });
    });
  }, [store.configId, store.runningJob]);

  useEffect(() => {
    if (store.flowStatus?.get('isRunning') && !Timer.isPolling(pollRunningJob)) {
      Timer.poll(pollRunningJob, { interval: POLL_INTERVAL, skipFirst: true });
    }

    return () => {
      Timer.stop(pollRunningJob);
    };
  }, [pollRunningJob, store.flowStatus]);

  if (!store.configId) return null;

  if (store.isDraftAutomation) {
    if (!automation || !isAutomationSuccessfullyConfigured(automation)) return null;
    return <PublishButton automationId={automation.id} configId={store.configId} />;
  }

  const handleRunStart = (job: Record<string, any>) => {
    updateLocalStateValue(store.configId, ['runningJob'], fromJS(job));
    setIsTerminating(false);
    return Promise.resolve();
  };

  const renderRunButton = () => {
    const disabledOrNonConfiguredTasks = store.config
      .getIn(['configuration', 'tasks'], List())
      .filter((savedTask: Map<string, any>) => {
        return (
          store.tasks.some((task: Map<string, any>) => task.get('id') === savedTask.get('id')) &&
          (!savedTask.get('enabled', true) || !savedTask.getIn(['task', 'configId']))
        );
      });

    return (
      <RunComponentButton
        componentId={KEBOOLA_ORCHESTRATOR}
        runParams={() => ({ config: store.configId })}
        disabled={store.isChanged || !store.shouldAllowRunFlow}
        disabledReason={
          store.isChanged
            ? 'You need to save or reset the changes before running the flow.'
            : 'The flow cannot run without any configured and enabled tasks.'
        }
        label="Run flow"
        buttonIcon="circle-play"
        buttonBsStyle="success"
        forceModal={!disabledOrNonConfiguredTasks.isEmpty()}
        title={!disabledOrNonConfiguredTasks.isEmpty() ? 'Are you sure?' : void 0}
        onAfterRun={handleRunStart}
        additionalActions={
          <>
            <MenuItem onSelect={() => setShowRunModalAs('empty')}>Run selected tasks</MenuItem>
            {JOB_FAILED_STATUSES.includes(store.runningJob?.get('status')) && (
              <MenuItem onSelect={() => setShowRunModalAs('retry')}>Re-run failed tasks</MenuItem>
            )}
          </>
        }
      >
        {!disabledOrNonConfiguredTasks.isEmpty() ? (
          <>
            <p className="mb-2">
              The following components are disabled or without a configuration. Therefore, they will
              not perform any action within the flow.
            </p>
            {disabledOrNonConfiguredTasks
              .sortBy((task: Map<string, any>) => (!!task.getIn(['task', 'configId']) ? -1 : 0))
              .map(renderTaskRow)
              .toArray()}
          </>
        ) : (
          'You are about to run the flow.'
        )}
      </RunComponentButton>
    );
  };

  const renderTaskRow = (savedTask: Map<string, any>) => {
    const editedTask = prepareTask(
      store.tasks.find((task: Map<string, any>) => task.get('id') === savedTask.get('id')),
      store.components,
      store.installedComponents,
      store.deletedComponents,
    );

    return (
      <div key={editedTask.get('id')} className="flex-container mb-1">
        <div className="flex-container flex-start">
          <TaskIcon src={editedTask.get('iconUrl')} size={48} />
          <TaskName
            isDragged={false}
            isBlank={!editedTask.get('configId')}
            isDeleted={editedTask.get('hasDeletedConfiguration')}
            name={editedTask.get('name')}
            componentName={editedTask.get('component')}
            componentType={editedTask.get('type')}
          />
        </div>
        {!!editedTask.get('configId') && (
          <Tooltip placement="top" tooltip={editedTask.get('enabled', true) ? 'Disable' : 'Enable'}>
            <Switch
              className="wider"
              prefixCls="switch"
              checked={editedTask.get('enabled', true)}
              onChange={(checked) => {
                const updatedTasks = store.tasks.map((task: Map<string, any>) => {
                  return task.get('id') === editedTask.get('id')
                    ? task.set('enabled', checked)
                    : task;
                });

                updateLocalStateValue(store.configId, 'tasks', updatedTasks);
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const handleSave = () => {
    setIsSaving(true);
    return saveFlow(store.config, store.tasks, store.phases).finally(() => setIsSaving(false));
  };

  return (
    <>
      <ButtonGroup>
        <DescriptionButton
          onClick={() => setShowDescriptionModal(true)}
          isFilled={!!store.flow.get('description')}
          readOnly={store.readOnly}
          showsInModalOnly
        />
        <CatchUnsavedChanges
          key={store.activeTab}
          isDirty={store.isChanged}
          onSave={handleSave}
          onDirtyLeave={() => clearLocalState(store.configId)}
          text={<p>When saving, all tasks without a selected configuration will be disabled.</p>}
        >
          <SaveButtons
            onReset={() => clearLocalState(store.configId)}
            isSaving={isSaving}
            onSave={handleSave}
            isChanged={store.isChanged}
            showModal={store.tasks.some((task: Map<string, any>) => {
              return !task.getIn(['task', 'configId']) && task.get('enabled', true);
            })}
            modalTitle="Save Flow"
            modalBody={
              <>
                <p>You are about to save the flow.</p>
                <p>All tasks without a selected configuration will be disabled.</p>
              </>
            }
          />
        </CatchUnsavedChanges>
        {renderRunButton()}
        <JobTerminateButton
          isFlow
          job={store.runningJob}
          sapiToken={store.sapiToken}
          isTerminating={
            isTerminating ||
            (store.runningJob &&
              store.jobsPendingActions.getIn(['terminating', store.runningJob.get('id')], false))
          }
          onTerminate={() => {
            setIsTerminating(true);
            return JobsActionCreators.terminateJob(store.runningJob.get('id'));
          }}
        />
        <OneTimeNotificationButton
          job={store.runningJob}
          notifications={store.notifications}
          admin={currentAdmin}
        />
      </ButtonGroup>
      <RunSelectedModal
        allConfigurations={store.installedComponents}
        hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
        phases={
          showRunModalAs === 'retry'
            ? filterDisabledTasks(store.visualizationPhases)
            : store.visualizationPhases
        }
        show={!!showRunModalAs}
        isRetry={showRunModalAs === 'retry'}
        onHide={() => setShowRunModalAs(null)}
        onRun={(selected, variablesOverride) => {
          return InstalledComponentsActionCreators.runComponent({
            component: KEBOOLA_ORCHESTRATOR,
            data: {
              config: store.configId,
              ...(store.hasProtectedDefaultBranch
                ? {
                    ...(showRunModalAs === 'retry' &&
                      !!store.latestJob && {
                        previousJobId: store.latestJob.get('id'),
                      }),
                    onlyOrchestrationTaskIds: Object.entries(selected)
                      .filter(([, value]) => value)
                      .map(([key]) => key),
                  }
                : {
                    configData: prepareSelectedTasks(
                      store.config.get('configuration'),
                      selected,
                      store.installedComponents,
                      variablesOverride,
                    ),
                  }),
            },
          }).then(handleRunStart);
        }}
        {...(store.flowStatus && { status: store.flowStatus })}
      />
      <DescriptionModal
        entity="flow"
        readOnly={store.readOnly}
        show={showDescriptionModal}
        onHide={() => setShowDescriptionModal(false)}
        description={store.flow.get('description')}
        onSave={(description: string) => {
          return InstalledComponentsActionCreators.updateComponentConfiguration(
            KEBOOLA_ORCHESTRATOR,
            store.configId,
            { description },
            'Update description',
            'description',
          );
        }}
        componentId={KEBOOLA_ORCHESTRATOR}
        configId={store.configId}
      />
    </>
  );
};

export default DetailHeader;
