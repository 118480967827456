import type { ReactNode } from 'react';

import { ClipboardInline } from '../Clipboard';

export const ButtonToolbar = ({ value, children }: { value?: string; children?: ReactNode }) => {
  if (!value && !children) return null;

  return (
    <div className="tw-pointer-events-auto tw-ml-auto tw-mr-4 tw-flex tw-w-fit tw-gap-1 tw-overflow-visible tw-rounded-lg tw-border tw-border-solid tw-border-neutral-150 !tw-bg-white tw-p-[3px] tw-shadow">
      {!!value && (
        <ClipboardInline
          label={null}
          text={value}
          buttonClassName="tw-rounded !tw-px-[9px] !tw-py-[7px] tw-text-neutral-400 hover|focus:tw-bg-neutral-100 hover|focus:tw-text-secondary-500 [&_svg]:tw-mr-0"
        />
      )}
      {children}
    </div>
  );
};
