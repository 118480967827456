import { useCallback, useEffect, useRef, useState } from 'react';

import { SERVICE_OAUTH } from '@/constants/serviceIds';
import StorageApi from '@/modules/components/StorageApi';
import { Constants } from '@/modules/oauth-v2/Constants';
import { getReturnUrl, prepareCredentialsId } from '@/modules/oauth-v2/helpers';
import { processRedirectData } from '@/modules/oauth-v2/OauthUtils';
import ServicesStore from '@/modules/services/Store';
import ApplicationStore from '@/stores/ApplicationStore';
import { windowOpen } from '@/utils/windowOpen';

type Props = {
  authorizedFor: string;
  componentId: string;
  configId?: string;
  wrapperComponentId?: string;
  branchId?: string;
  children: React.ReactNode;
  onComplete?: (credentials: { id: string; version: number }) => void;
  skipSave?: boolean;
};

export const AuthorizationForm = ({
  authorizedFor,
  componentId,
  configId,
  wrapperComponentId,
  branchId,
  children,
  onComplete,
  skipSave,
}: Props) => {
  const windowRef = useRef<Window | null>(null);
  const formElementRef = useRef<HTMLFormElement>(null);
  const [fallbackCredentialsId, setFallbackCredentialsId] = useState<string | null>(null);

  useEffect(() => {
    const windowEventHandler = (event: MessageEvent) => {
      if (event.data?.isAuthorized) {
        processRedirectData(
          componentId,
          configId,
          event.data.credentialsId || fallbackCredentialsId,
          branchId,
          wrapperComponentId,
          skipSave,
        ).then((credentialsId) => {
          windowRef.current?.close();
          onComplete?.({ id: credentialsId, version: Constants.OAUTH_VERSION_3 });
        });

        window.removeEventListener('message', windowEventHandler);
      }
    };

    window.addEventListener('message', windowEventHandler, false);

    return () => {
      window.removeEventListener('message', windowEventHandler);
    };
  }, [
    branchId,
    componentId,
    configId,
    fallbackCredentialsId,
    onComplete,
    skipSave,
    wrapperComponentId,
  ]);

  const appendHiddenInput = useCallback((name: string, value: string) => {
    const existingInput = formElementRef.current?.querySelector(`input[name=${name}]`);

    if (existingInput) {
      (existingInput as HTMLInputElement).value = value;
    } else {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = name;
      input.value = value;
      formElementRef.current?.appendChild(input);
    }
  }, []);

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      event.stopPropagation();

      StorageApi.generateUniqueId().then((uniqueId) => {
        const credentialsId = prepareCredentialsId(uniqueId, componentId, configId, branchId);

        setFallbackCredentialsId(credentialsId);

        appendHiddenInput('id', credentialsId);
        appendHiddenInput('token', ApplicationStore.getSapiTokenString());
        appendHiddenInput('returnUrl', getReturnUrl(credentialsId));
        appendHiddenInput('authorizedFor', authorizedFor);

        if (branchId && branchId !== 'null') {
          appendHiddenInput('branchId', branchId);
        }

        windowRef.current = windowOpen('', 'popup,toolbar=no,menubar=no', 'OAuthPopup');
        formElementRef.current?.submit();
      });
    },
    [appendHiddenInput, authorizedFor, branchId, componentId, configId],
  );

  return (
    <form
      method="POST"
      target="OAuthPopup"
      className="form form-horizontal"
      ref={formElementRef}
      onSubmit={handleSubmit}
      action={`${ServicesStore.getServiceUrl(SERVICE_OAUTH)}/authorize/${componentId}`}
    >
      {children}
    </form>
  );
};
