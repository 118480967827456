import { Component } from 'react';
import { List, Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { usedInInputMapping, usedInMapping, usedInOutputMapping } from '@/modules/storage/helpers';
import { User } from '@/react/common';
import ComponentWithIconAndType from '@/react/common/ComponentWithIconAndType';
import Loader from '@/react/common/Loader';

const SEARCHED_TYPES = [
  componentTypes.TRANSFORMATION,
  componentTypes.WRITER,
  componentTypes.APPLICATION,
];

type State = {
  loading: boolean;
};

type Props = {
  tableId: string;
  configurations: Map<string, any>;
  admins: Map<string, any>;
  hasShowTransformationMigration: boolean;
  hasFlows: boolean;
};

class TableUsage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    InstalledComponentsActionCreators.loadInstalledComponentsForce({
      include: 'configuration,rows',
    }).finally(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div className="box">
        <table className="table table-hover">
          <thead>
            <tr>
              <th className="w-400">
                Component{' '}
                <Tooltip placement="top" tooltip={this.getTooltip()} type="static-explanatory">
                  <Icon icon="circle-info" className="icon-addon-left text-muted" />
                </Tooltip>
              </th>
              <th>Configuration</th>
              <th className="w-200">Used in</th>
              <th className="w-50">Version</th>
              <th className="w-200">Created</th>
            </tr>
          </thead>
          <tbody>
            {this.state.loading ? (
              <tr className="no-hover">
                <td colSpan={5}>
                  <Loader /> Loading data...
                </td>
              </tr>
            ) : (
              this.renderData()
            )}
          </tbody>
        </table>
      </div>
    );
  }

  renderData() {
    const components = this.prepareComponent();

    if (components.isEmpty()) {
      return (
        <tr className="no-hover">
          <td colSpan={5}>
            The table is not used in any {this.getComponentType(componentTypes.TRANSFORMATION)},{' '}
            {this.getComponentType(componentTypes.APPLICATION)} or{' '}
            {this.getComponentType(componentTypes.WRITER)}.
          </td>
        </tr>
      );
    }

    return (
      components
        .sortBy((component) => component.get('name').toLowerCase())
        // @ts-expect-error componentId cannot be undefined
        .map((component: Map<string, any>, componentId: string) => {
          return component
            .get('configurations')
            .sortBy((config: Map<string, any>) => config.get('name').toLowerCase())
            .map((config: Map<string, any>, configId: string) => {
              if (config.get('rows', List()).isEmpty()) {
                return (
                  <tr key={`${componentId}-${configId}`}>
                    <td>
                      <ComponentWithIconAndType component={component} />
                    </td>
                    <td>
                      <ComponentConfigurationLink
                        componentId={componentId}
                        configId={configId}
                        hasFlows={this.props.hasFlows}
                      >
                        {config.get('name')}
                      </ComponentConfigurationLink>
                    </td>
                    <td>{this.renderUsedIn(config)}</td>
                    <td>{this.renderVersion(config)}</td>
                    <td>{this.renderUser(config)}</td>
                  </tr>
                );
              }

              return config
                .get('rows', List())
                .map((row: Map<string, any>) => {
                  return (
                    <tr key={`${componentId}-${configId}-${row.get('id')}`}>
                      <td>
                        <ComponentWithIconAndType component={component} />
                      </td>
                      <td>
                        <ComponentConfigurationLink
                          componentId={componentId}
                          configId={configId}
                          rowId={row.get('id')}
                        >
                          {config.get('name')} - {row.get('name')}
                        </ComponentConfigurationLink>
                      </td>
                      <td>{this.renderUsedIn(row)}</td>
                      <td>{this.renderVersion(row)}</td>
                      <td>{this.renderUser(row)}</td>
                    </tr>
                  );
                })
                .toArray();
            })
            .toArray();
        })
        .toArray()
    );
  }

  renderUsedIn(config: Map<string, any>) {
    const inInputMapping = usedInInputMapping(config, this.props.tableId);
    const inOutputMapping = usedInOutputMapping(config, this.props.tableId);

    if (inInputMapping && inOutputMapping) {
      return 'Input and Output mapping';
    }

    return inInputMapping ? 'Input mapping' : 'Output mapping';
  }

  renderVersion(config: Map<string, any>) {
    return '#' + config.get('version');
  }

  renderUser(config: Map<string, any>) {
    const admin = this.props.admins.get(config.getIn(['creatorToken', 'description']));

    if (!admin) {
      return config.getIn(['creatorToken', 'description']);
    }

    return <User user={admin} />;
  }

  getTooltip() {
    return `We check only ${this.getComponentType(componentTypes.TRANSFORMATION)}s${
      this.props.hasShowTransformationMigration ? ' (non Legacy)' : ''
    }, ${this.getComponentType(componentTypes.APPLICATION)}s and ${this.getComponentType(
      componentTypes.WRITER,
    )}s.`;
  }

  getComponentType(type: string) {
    return getNewComponentTypeLabel(type);
  }

  prepareComponent() {
    return this.props.configurations
      .filter((component) => SEARCHED_TYPES.includes(component.get('type')))
      .map((component) => {
        return component.update('configurations', Map(), (configurations: Map<string, any>) => {
          return (
            configurations
              .map((config: Map<string, any>) => {
                return config.update('rows', List(), (rows) => {
                  return rows.filter((row: Map<string, any>) =>
                    usedInMapping(row, this.props.tableId),
                  );
                });
              })
              // @ts-expect-error config cannot be undefined
              .filter((config: Map<string, any>) => {
                return (
                  usedInMapping(config, this.props.tableId) || !config.get('rows', List()).isEmpty()
                );
              })
          );
        });
      })
      .filter((component) => !component.get('configurations', Map()).isEmpty());
  }
}

export default TableUsage;
