import { type Edge, MarkerType, type Node } from '@xyflow/react';
import type { ElkNode, LayoutOptions } from 'elkjs/lib/elk.bundled.js';
import ELK from 'elkjs/lib/elk.bundled.js';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import { componentTypes } from '@/constants';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import { getComponentIconUrl } from '@/utils/componentIconFinder';
import string from '@/utils/string';
import { getAllDescendantPhases } from './components/Conditions/helpers';
import type {
  AppNode,
  NextCondition,
  NextConditionWithId,
  Operand,
  Phase,
  Task,
  VisualPhase,
} from './types';

const flowElk = new ELK();

const layoutOptions: LayoutOptions = {
  'elk.algorithm': 'layered',
  'elk.direction': 'DOWN',
  'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
  'elk.layered.considerModelOrder.strategy': 'NODES_AND_EDGES',
  'elk.layered.spacing.nodeNodeBetweenLayers': '80',
  'elk.spacing.nodeNode': '40',
  'elk.spacing.edgeNode': '400',
};

export const TASK_WIDTH = 304;
export const TASK_HEIGHT = 70;

const PHASE_BASE_WIDTH = 328;
const PHASE_BASE_HEIGHT = 106;

export const ARROW_OFFSET = 12;

export const generateId = () => {
  const start = Date.now().toString(36);
  const end = Math.random().toString(36).substring(2, 8);

  return `${start}-${end}`;
};

const generateName = (prefix: string, names: string[]) => {
  const highestNumber = names.reduce((highestNumber = 0, name) => {
    const match = name.match(new RegExp(`^${prefix} (\\d+)$`));

    if (match) {
      return Math.max(highestNumber, parseInt(match[1], 10));
    }

    return highestNumber;
  }, 0);

  return `${prefix} ${highestNumber + 1}`;
};

export const generatePhaseName = (phases: List<any>, name = 'Phase'): string => {
  const names: string[] = phases
    .map((phase) => phase.get('name'))
    .filter(Boolean)
    .toArray();

  return generateName(name, names);
};

export const generateConditionName = (conditions: NextConditionWithId[]): string => {
  const names = conditions.map((condition) => condition.name).filter(Boolean) as string[];

  return generateName('Condition', names);
};

export const extractPhaseId = (id: string) => {
  if (!id.includes(':')) {
    return id.split('|')[0];
  }

  return id.split(':')[1].split('|')[0];
};

export const getLayoutedElements = (nodes: AppNode[], edges: Edge[], allMeasured: boolean) => {
  const opacity = { opacity: allMeasured ? 1 : 0 };

  const graph: ElkNode & { children: Node[] } = {
    id: 'root',
    layoutOptions,
    children: nodes.map((node) => {
      return {
        ...node,
        width: node.measured?.width ?? node?.width ?? PHASE_BASE_WIDTH,
        height: node.measured?.height ?? node?.height ?? PHASE_BASE_HEIGHT,
      };
    }),
    edges: edges.map(({ id, source, target }) => ({ id, sources: [source], targets: [target] })),
  };

  return flowElk.layout(graph).then(({ children }) => {
    if (!children) {
      return { nodes: [], edges: [] };
    }

    return {
      nodes: children.map((node) => {
        const opacityNode = node.position.x === 0 && node.position.y === 0 ? 0 : 1;
        return {
          ..._.omit(node, 'width', 'height', 'x', 'y'),
          position: { x: node.x || 0, y: node.y || 0 },
          style: { ...node.style, opacity: opacityNode },
          hidden: false,
        };
      }) as AppNode[],
      edges: edges.map((edge) => ({
        ...edge,
        style: { ...edge.style, ...opacity },
        hidden: false,
      })) as Edge[],
    };
  });
};

const preparePhaseData = (
  phase: Phase,
  tasks: Task[],
  components: Map<string, any>,
  installedComponents: Map<string, any>,
  deletedComponents: Map<string, any>,
): VisualPhase => {
  return {
    id: phase.id,
    name: phase.name,
    description: phase.description ?? '',
    tasks: tasks
      .filter((task) => task.phase === phase.id)
      .map((task) => prepareTaskData(task, components, installedComponents, deletedComponents))
      .sort((a, b) => a.name.localeCompare(b.name)),
    next: phase.next?.map((condition) => condition.goto) ?? [],
  };
};

const prepareTaskData = (
  task: Task,
  components: Map<string, any>,
  installedComponents: Map<string, any>,
  deletedComponents: Map<string, any>,
) => {
  if (task.task.type === 'notification') {
    return {
      variant: 'notification' as const,
      id: task.id,
      name: task.name,
      title: task.task.title,
      recipient: task.task.channel.recipient,
      message: task.task.message,
      enabled: task.enabled,
    };
  }

  if (task.task.type === 'variable') {
    return {
      variant: 'variable' as const,
      id: task.id,
      name: task.name,
      enabled: task.enabled,
    };
  }

  const { configId, componentId } = task.task;

  const component = components.get(componentId);
  const config = installedComponents.getIn([componentId, 'configurations', configId], Map());

  const componentName = component.get('name');
  const isDeleted = !!configId && config.isEmpty();
  const inTrash = deletedComponents.hasIn([componentId, 'configurations', configId, 'name']);
  const specificRows = task.task?.configRowIds ?? [];
  const availableRows = config
    .get('rows', List())
    .map((row: Map<string, any>) => ({ value: row.get('id'), label: row.get('name') }));

  return {
    variant: 'job' as const,
    id: task.id,
    name: isDeleted
      ? inTrash
        ? `Deleted (${deletedComponents.getIn([componentId, 'configurations', configId, 'name'])})`
        : 'Deleted Configuration'
      : config.get('name', configId),
    specificRows,
    availableRows,
    componentId: component.get('id'),
    type: Object.values(componentTypes).includes(component.get('type'))
      ? getNewComponentTypeLabel(component.get('type'))
      : '',
    configId,
    component: component.get('name') ?? 'Invalid component',
    iconUrl: getComponentIconUrl(component),
    enabled: task.enabled ?? true,
    invalid: !componentName,
    hasDeletedConfiguration: isDeleted,
    hasConfigurationInTrash: inTrash,
  };
};

const prepareNode = <T extends 'phase' | 'add' | 'empty'>(
  id: string,
  type: T,
  data: T extends 'phase' ? VisualPhase : Record<string, never>,
) => {
  return { id, type, position: { x: 0, y: 0 }, data, hidden: true };
};

const prepareEdge = (source: string, target: string, type: 'between' | 'alone') => {
  return {
    type,
    id: `${type}:${source}|${target}`,
    source: source,
    target: target,
    markerEnd: { type: MarkerType.ArrowClosed, width: 20, height: 20 },
    animated: false,
    selectable: false,
    focusable: false,
    hidden: true,
  };
};

export const prepareFlowData = (
  phases: Phase[],
  tasks: Task[],
  components: Map<string, any>,
  configurations: Map<string, any>,
  deletedComponents: Map<string, any>,
) => {
  const nodes: AppNode[] = [];
  const edges: Edge[] = [];

  phases.forEach((phase) => {
    const phaseNode = prepareNode(
      phase.id,
      'phase',
      preparePhaseData(phase, tasks, components, configurations, deletedComponents),
    );

    nodes.push(phaseNode);
  });

  phases.forEach((phase) => {
    phase.next?.forEach(({ goto }) => {
      if (goto && nodes.find((node) => node.id === goto)) {
        edges.push(prepareEdge(phase.id, goto, 'between'));
      }
    });
  });

  phases.forEach((phase) => {
    if (!phase.next) {
      const addNode = prepareNode(`after:${phase.id}`, 'add', {});
      const found = nodes.find((node) => node.id === phase.id);

      nodes.push({ ...addNode, position: found?.position ?? { x: 0, y: 0 } });
      edges.push(prepareEdge(phase.id, addNode.id, 'alone'));
    }

    if (!edges.some((edge) => edge.target === phase.id)) {
      const addNode = prepareNode(`before:${phase.id}`, 'add', {});

      nodes.push(addNode);
      edges.push(prepareEdge(addNode.id, phase.id, 'alone'));
    }
  });

  if (nodes.length === 0) {
    const emptyNode = prepareNode('empty', 'empty', {});

    nodes.push(emptyNode);
  }

  return { nodes, edges };
};

export const compareNodes = (
  currentNodes: AppNode[],
  newNodes: AppNode[],
  keys = ['id', 'data', 'type'],
) => {
  return _.isEqual(
    newNodes.map((node) => _.pick(node, keys)),
    currentNodes.map((node) => _.pick(node, keys)),
  );
};

const checkIfPhaseIdAddedOrRemoved = (newNodes: AppNode[], currentNodes: AppNode[]) => {
  return (
    newNodes.length !== currentNodes.length || !compareNodes(newNodes, currentNodes, ['id', 'type'])
  );
};

const checkIfTasksAreMoved = (newNodes: AppNode[], currentNodes: AppNode[]) => {
  return newNodes.some(({ data }, index) => {
    return data.tasks && data.tasks.length !== currentNodes[index].data.tasks.length;
  });
};

const prepareNodesForConditionsComparison = (nodes: AppNode[]) => {
  return nodes.map((node) => ({ id: node.id, next: node.data?.next }));
};

const checkIfConditionsAreChanged = (newNodes: AppNode[], currentNodes: AppNode[]) => {
  return !_.isEqual(
    prepareNodesForConditionsComparison(currentNodes),
    prepareNodesForConditionsComparison(newNodes),
  );
};

export const onlyDataUpdated = (currentNodes: AppNode[], newNodes: AppNode[]) => {
  if (
    checkIfPhaseIdAddedOrRemoved(newNodes, currentNodes) ||
    checkIfTasksAreMoved(newNodes, currentNodes) ||
    checkIfConditionsAreChanged(newNodes, currentNodes)
  ) {
    return false;
  }

  return true;
};

export const compareEdges = (currentEdges: Edge[], newEdges: Edge[]) => {
  return _.isEqual(
    newEdges.map((edge) => _.pick(edge, ['id', 'source', 'target'])),
    currentEdges.map((edge) => _.pick(edge, ['id', 'source', 'target'])),
  );
};

export const isEmptyFlow = (nodes: AppNode[]) => {
  return nodes.length === 1 && nodes[0].type === 'empty';
};

export const prepareNewPhase = (id: string, name: string) => {
  return Map({ id, name });
};

export const preparePhasesForNewTask = (
  selectedPhaseId: string | null,
  currentPhases: List<any>,
  nodes: AppNode[],
  name = 'Phase',
) => {
  if (!selectedPhaseId) {
    return;
  }

  let phase: Map<string, any>;
  let phases = currentPhases;

  if (
    isEmptyFlow(nodes) ||
    selectedPhaseId?.startsWith('before:') ||
    selectedPhaseId?.startsWith('between:') ||
    selectedPhaseId?.startsWith('after:')
  ) {
    phase = prepareNewPhase(generateId(), generatePhaseName(currentPhases, name));
    phases = phases.push(phase);
  } else {
    phase = phases.find((phase) => phase.get('id') === selectedPhaseId);
  }

  if (!phase) {
    return;
  }

  if (selectedPhaseId?.startsWith('before:')) {
    const nextPhaseId = extractPhaseId(selectedPhaseId);
    phases = phases.setIn([-1, 'next'], fromJS([{ goto: nextPhaseId }]));
  } else if (selectedPhaseId?.startsWith('between:')) {
    const [prevPhaseId, nextPhaseId] = string.strRight(selectedPhaseId, ':').split('|');
    const prevPhase = phases.find((p) => p.get('id') === prevPhaseId);

    if (prevPhase) {
      phases = phases.map((p) => {
        if (p.get('id') === prevPhaseId) {
          const next = p.get('next', List());

          if (!next.isEmpty()) {
            // If there are conditions, update only the one pointing to nextPhaseId
            return p.update('next', (next: List<any>) => {
              return next.map((n: Map<string, any>) => {
                if (n.get('goto') === nextPhaseId) {
                  return n.set('goto', phase.get('id'));
                }

                return n;
              });
            });
          }

          return p.set('next', fromJS([{ goto: phase.get('id') }]));
        }
        return p;
      }) as List<any>;

      // Set the new phase to point to the nextPhaseId
      phase = phase.set('next', fromJS([{ goto: nextPhaseId }]));
      phases = phases.set(-1, phase);
    }
  } else if (selectedPhaseId?.startsWith('after:')) {
    const prevPhaseId = extractPhaseId(selectedPhaseId);
    const prevPhase = phases.find((p) => p.get('id') === prevPhaseId);

    if (prevPhase) {
      phases = phases.map((p) => {
        if (p.get('id') === prevPhaseId) {
          return p.set('next', fromJS([{ goto: phase.get('id') }]));
        }
        return p;
      }) as List<any>;
    }
  }

  return { phase, phases };
};

export const prepareNewJobTask = (
  phaseId: string,
  component: Map<string, any>,
  configuration?: Map<string, any>,
) => {
  return fromJS({
    id: generateId(),
    name: configuration ? `${component.get('id')}-${configuration.get('id')}` : component.get('id'),
    phase: phaseId,
    task: {
      mode: 'run',
      componentId: component.get('id'),
      configId: configuration ? configuration.get('id') : '',
    },
    enabled: true,
  });
};

export const prepareNewNotificationTask = (phaseId: string) => {
  return fromJS({
    id: generateId(),
    name: 'My Notification',
    phase: phaseId,
    task: {
      type: 'notification',
      title: '',
      message: '',
      channel: {
        type: 'email',
        recipient: '',
      },
    },
    enabled: true,
  });
};

export const prepareNewVariableTask = (phaseId: string) => {
  return fromJS({
    id: generateId(),
    name: 'My Variable',
    phase: phaseId,
    task: {
      type: 'variable',
    },
    enabled: true,
  });
};

const getParentIds = (phases: List<any>, ids: string[]) => {
  return phases
    .filter((phase) => {
      return phase.get('next', List()).some((condition: Map<string, any>) => {
        return ids.includes(condition.get('goto'));
      });
    })
    .map((phase) => phase.get('id'))
    .toSet()
    .toArray();
};

export const getAllParentIds = (id: string, phases: List<any>) => {
  const parentIds: string[] = [];

  let currentIds = getParentIds(phases, [id]);
  while (currentIds.length) {
    parentIds.push(...currentIds);
    currentIds = getParentIds(phases, currentIds);
  }

  return parentIds;
};

export const getDefaultOperand = (id: string): Operand => {
  const phaseId = extractPhaseId(id);

  return {
    type: 'operator',
    operator: 'EQUALS',
    operands: [
      { type: 'phase', phase: phaseId, value: 'job.status' },
      { type: 'const', value: 'error' },
    ],
  };
};

export const prepareNewNextCondition = (
  id = generateId(),
  name = 'Condition',
): NextConditionWithId => {
  const phaseId = extractPhaseId(id);

  return {
    id,
    name,
    condition: {
      type: 'operator',
      operator: 'AND',
      operands: [getDefaultOperand(phaseId)],
    },
    goto: '',
  };
};

export const prepareGoTo = (goto: string | null) => {
  if (!goto) {
    return null;
  }

  if (goto.includes(' ')) {
    return string.webalize(goto);
  }

  return goto;
};

export const areConditionsValid = (conditions: NextConditionWithId[]) => {
  return conditions.every((condition) => {
    return condition.goto === null || String(condition.goto).length > 0;
  });
};

export const clearOrphansGoToNull = (phases: List<any>) => {
  return phases.map((p) => {
    if (
      p.get('next', List()).size ===
      p.get('next', List()).count((n: Map<string, any>) => n.get('goto') === null)
    ) {
      return p.delete('next');
    }

    return p;
  }) as List<any>;
};

const filterTasks = (tasks: List<any>, phaseId: string) => {
  return tasks.filter((t) => t.get('phase') !== phaseId) as List<any>;
};

const handlePhaseDeleteWithOneParentAndOneChild = (
  phases: List<any>,
  tasks: List<any>,
  phaseId: string,
  parentId: string,
  childId: string,
) => {
  const newPhases = phases
    .filter((p) => p.get('id') !== phaseId)
    .map((p) => {
      if (p.get('id') !== parentId) {
        return p;
      }

      const hasDirectConnectionToChild = p
        .get('next', List())
        .some((n: Map<string, any>) => n.get('goto') === childId);

      if (hasDirectConnectionToChild) {
        return p.update('next', (next: List<any>) => {
          return next.filter((n: Map<string, any>) => n.get('goto') !== phaseId);
        });
      }

      // no condition pointing to the child, add a new one
      return p.update('next', (next: List<any>) => {
        return next.map((n: Map<string, any>) => {
          if (n.get('goto') === phaseId) {
            return n.set('goto', childId);
          }

          return n;
        });
      });
    }) as List<any>;

  return { newPhases: clearOrphansGoToNull(newPhases), newTasks: filterTasks(tasks, phaseId) };
};

const handlePhaseDeleteWithOneParentAndNoChildren = (
  phases: List<any>,
  tasks: List<any>,
  phaseId: string,
) => {
  const newPhases = phases.filter((p) => p.get('id') !== phaseId) as List<any>;

  return { newPhases: clearOrphansGoToNull(newPhases), newTasks: filterTasks(tasks, phaseId) };
};

const handlePhaseDeleteWithMultipleOrNoChildren = (
  phases: List<any>,
  tasks: List<any>,
  phaseId: string,
) => {
  const phasesToDelete = getAllDescendantPhases(phaseId, phases);

  const newPhases = phases
    .filter((p) => !phasesToDelete.has(p.get('id')))
    .map((p) => {
      if (!p.has('next')) {
        return p;
      }

      return p.update('next', (next: List<any>) => {
        return next.filter((n: Map<string, any>) => !phasesToDelete.has(n.get('goto')));
      });
    }) as List<any>;
  const newTasks = tasks.filter((t) => !phasesToDelete.has(t.get('phase'))) as List<any>;

  return { newPhases: clearOrphansGoToNull(newPhases), newTasks };
};

const getPhaseChildren = (phase: Map<string, any>): string[] => {
  return phase
    .get('next', List())
    .map((next: Map<string, any>) => next.get('goto'))
    .filter(Boolean)
    .toArray();
};

const getPhaseParents = (phaseId: string, phases: List<any>): string[] => {
  return phases
    .filter((p) => {
      return p.get('next', List()).some((next: Map<string, any>) => {
        return next.get('goto') === phaseId;
      });
    })
    .map((p) => p.get('id'))
    .toArray();
};

export const preparePhasesAndTasksAfterDelete = (
  phaseId: string,
  phases: List<any>,
  tasks: List<any>,
): { newPhases: List<any>; newTasks: List<any> } => {
  const phaseToDelete = phases.find((p) => p.get('id') === phaseId);

  if (!phaseToDelete) {
    return { newPhases: phases, newTasks: tasks };
  }

  const children = getPhaseChildren(phaseToDelete);
  const parents = getPhaseParents(phaseId, phases);

  if (children.length === 1 && parents.length === 1) {
    return handlePhaseDeleteWithOneParentAndOneChild(
      phases,
      tasks,
      phaseId,
      parents[0],
      children[0],
    );
  }

  if (children.length === 1 && parents.length === 0) {
    return handlePhaseDeleteWithOneParentAndNoChildren(phases, tasks, phaseId);
  }

  return handlePhaseDeleteWithMultipleOrNoChildren(phases, tasks, phaseId);
};

export const canHaveIfCondition = (id: string, phases: List<any>) => {
  if (id.startsWith('after:')) {
    return true;
  }

  if (id.startsWith('between:')) {
    const beforePhaseId = extractPhaseId(id);
    const phase = phases.find((p) => p.get('id') === beforePhaseId);

    if (!phase) {
      return false;
    }

    return phase.get('next', List()).size === 1;
  }

  return false;
};

export const prepareInitialConditions = (phases: List<any>, phaseId: string) => {
  const phase = phases.find((phase: Map<string, any>) => phase.get('id') === phaseId, null, Map());

  const hasConditions = phase
    .get('next', List())
    .some((next: Map<string, any>) => next.has('condition'));

  const nextConditions = hasConditions
    ? phase.get('next')
    : fromJS([
        prepareNewNextCondition(phaseId, 'Condition 1'),
        { goto: phase.getIn(['next', 0, 'goto'], null) },
      ]);

  return nextConditions
    .map((condition: Map<string, any>, index: number) => condition.set('id', index))
    .toJS();
};

export const withDataIfAvailable = (currentNode: AppNode, newNode: AppNode) => {
  if (currentNode.type === 'phase' && newNode.type === 'phase') {
    return { ...currentNode, data: newNode.data };
  }

  return currentNode;
};

export const hasInvalidConditions = (id: string, phases: List<any>, tasks: List<any>) => {
  const conditions = phases.find((p) => p.get('id') === id)?.get('next', List());

  if (!conditions || conditions.isEmpty()) {
    return false;
  }

  const phasesIds = [id, ...getPhaseParents(id, phases)];

  return conditions.some((condition: Map<string, any>) => {
    return !isValidCondition(condition.toJS(), phasesIds, tasks);
  });
};

export const isValidCondition = (
  condition: NextCondition,
  phasesIds: string[],
  tasks: List<any>,
) => {
  if (!condition.condition) {
    return true;
  }

  const isValidPhase = (phaseId?: string) => {
    return !!phaseId && phasesIds.includes(phaseId);
  };
  const isValidTask = (taskId: string) => {
    const task = tasks.find((t) => t.get('id') === taskId);
    return !!task && isValidPhase(task.get('phase')) && task.get('enabled');
  };

  return condition.condition.operands.every((operand) => {
    if ('phase' in operand) {
      return isValidPhase(operand.phase);
    }

    return operand.operands.every((operand) => {
      if (operand.type === 'phase') {
        return isValidPhase(operand.phase);
      }

      if (operand.type === 'task') {
        return isValidTask(operand.task);
      }

      if (operand.type === 'function') {
        return operand.operands.every((operand) => {
          if (operand.type === 'task') {
            return isValidTask(operand.task);
          }

          return true;
        });
      }

      return true;
    });
  });
};
