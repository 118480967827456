import { Map } from 'immutable';

import * as Constants from '@/constants/KbcConstants';
import Dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import RoutesStore from './RoutesStore';

let _store = initStore('RoutePendingStore', Map({ isPolling: false, isPending: false }));

const RoutePendingStore = StoreUtils.createStore({
  isPending() {
    return _store.get('isPending', false);
  },

  isPolling() {
    return _store.get('isPolling', false);
  },
});

RoutePendingStore.dispatchToken = Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case Constants.ActionTypes.ROUTER_ROUTE_CHANGE_START: {
      if (RoutesStore.getRouterState()?.getIn(['location', 'pathname']) === action.newPathname) {
        return;
      }

      _store = _store.set('isPending', true);
      return RoutePendingStore.emitChange();
    }

    case Constants.ActionTypes.ROUTER_ROUTE_CHANGE_SUCCESS:
    case Constants.ActionTypes.ROUTER_ROUTE_CHANGE_ERROR:
      _store = _store.set('isPending', false);
      return RoutePendingStore.emitChange();

    case Constants.ActionTypes.APPLICATION_POLL_LOADING:
      _store = _store.set('isPolling', action.loading);
      return RoutePendingStore.emitChange();
  }
});

export default RoutePendingStore;
