import type { List } from 'immutable';

import { cn } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import { canManageNotifications } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { isFlowComponent } from '@/modules/flows-v2/helpers';
import NotificationsStore from '@/modules/notifications/store';
import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import ConfigurationTabs from '@/react/common/ConfigurationTabs';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import Notifications from './components/Notifications';

const Detail = () => {
  const state = useStores(
    () => {
      const componentId = RoutesStore.getCurrentRouteComponentId();
      const configId = RoutesStore.getCurrentRouteParam('configId');

      return {
        configId,
        componentId,
        allNotifications: NotificationsStore.getAll(),
        admins: ApplicationStore.getAdmins(),
        canSetupNotifications: canManageNotifications(ApplicationStore.getSapiToken()),
        notifications: NotificationsStore.getNotifications(componentId, configId) as List<any>,
        componentType: ComponentsStore.getComponent(componentId).get('type'),
        hasTabs: !isFlowComponent(componentId),
      };
    },
    [],
    [ApplicationStore, RoutesStore, NotificationsStore, ComponentsStore],
  );

  return (
    <>
      {state.hasTabs && (
        <ConfigurationTabs
          componentId={state.componentId}
          configId={state.configId}
          {...(state.componentType === componentTypes.TRANSFORMATION && {
            versionsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_VERSIONS,
            notificationsLinkTo: transformationRoutes.GENERIC_TRANSFORMATION_NOTIFICATIONS,
          })}
        />
      )}
      <div className={cn({ 'box-separator': state.hasTabs })}>
        <Notifications
          configId={state.configId}
          componentId={state.componentId}
          notifications={state.notifications}
          allNotifications={state.allNotifications}
          admins={state.admins}
          readOnly={!state.canSetupNotifications}
        />
      </div>
    </>
  );
};

export default Detail;
