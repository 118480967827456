import type { Map } from 'immutable';
import { List } from 'immutable';
import _ from 'underscore';

import type { ExistingApp } from '@/api/routes/dataScienceService';
import string from '@/utils/string';
import type { ThemeName, ThemeValues } from './constants';
import {
  DEFAULT_EXPIRATION,
  EXPIRATION_PATH,
  EXPIRATION_PATH_FALLBACK,
  OIDC_PROVIDERS,
  themes,
} from './constants';

const getAppState = (app?: ExistingApp) => {
  return app?.state || 'created';
};

const prepareDataApps = (configs: Map<string, any>, apps: ExistingApp[]) => {
  const nonDeletedApps = apps.filter((app) => !['deleted', 'deleting'].includes(app.state));

  return configs.map((config) => {
    return config.set(
      'app',
      nonDeletedApps.find(({ configId }) => configId === config.get('id')),
    );
  }) as Map<string, any>;
};

const isAppConfigured = (config: Map<string, any>) => {
  if (!config.hasIn(['configuration', 'authorization', 'app_proxy', 'auth_rules'])) {
    return false;
  }

  return (
    !!config.getIn(['configuration', 'parameters', 'dataApp', 'git', 'repository']) ||
    !!config.getIn(['configuration', 'parameters', 'script'], List()).join('').trim()
  );
};

const prepareSlug = (name: string) => {
  return string.trim(name.replace(/[^a-zA-Z0-9-]/g, '-'), ' -').toLowerCase();
};

const resolveProvider = (formData: Map<string, any>) => {
  return (
    Object.values(OIDC_PROVIDERS).find(
      (provider) => provider.url && formData.get('issuer_url', '').includes(provider.url),
    ) || OIDC_PROVIDERS.oidc
  );
};

const prepareProviderFormData = (formData: Map<string, any>) => {
  return formData.withMutations((data) => {
    const provider = resolveProvider(formData);

    if (!provider?.values) {
      return;
    }

    Object.entries(provider.values).forEach(([key, { property, regex }]) => {
      data.set(key, formData.get(property)?.match(regex)?.[1] || '');
    });
  });
};

const prepareValueFromMapping = (
  formData: Map<string, any>,
  property: string,
  mappings?: Record<string, string>,
) => {
  return mappings?.[property]
    ? mappings[property].replace(/{([^}]+)}/g, (match, key) => formData.get(key, ''))
    : formData.get(property, '');
};

const prepareExpiration = (config: Map<string, any>) => {
  if (config.hasIn(EXPIRATION_PATH)) {
    return config.getIn(EXPIRATION_PATH);
  }

  if (config.hasIn(EXPIRATION_PATH_FALLBACK)) {
    return config.getIn(EXPIRATION_PATH_FALLBACK) * 3600;
  }

  return DEFAULT_EXPIRATION;
};

const getClipboardSecret = (name: string) => `st.secrets["${name}"]`;

const prepareTheme = (parsedConfigToml?: Record<string, any>): ThemeValues => {
  return _.omit(parsedConfigToml?.theme ?? {}, 'themeName');
};

const resolveThemeName = (themeValues: ThemeValues): ThemeName => {
  const theme = Object.entries(themes)
    .filter(([name]) => !['streamlit', 'custom'].includes(name))
    .find(([, values]) => {
      return Object.entries(values).every(([key, value]) => {
        return themeValues[key] === value;
      });
    });

  if (theme) {
    return theme[0] as ThemeName;
  }

  return Object.keys(themeValues).length === 0 ? 'streamlit' : 'custom';
};

const parseSecretsFromToml = (tomlContent: string) => {
  const result: Record<string, any> = {};

  const lines = tomlContent.split('\n');

  lines.forEach((line) => {
    if (!line.startsWith('[')) {
      const [key, ...rest] = line.split('=');
      const value = rest.join('=');
      if (!key || !value) {
        return;
      }

      const encryptedKey = `#${key.trim()}`;

      if (value.trim().startsWith('"') && value.trim().endsWith('"')) {
        result[encryptedKey] = value.trim().slice(1, -1);
        return;
      }
      result[encryptedKey] = `${value.trim()}`;
    }
  });

  return result;
};

export {
  getAppState,
  isAppConfigured,
  prepareSlug,
  resolveProvider,
  prepareDataApps,
  prepareExpiration,
  prepareProviderFormData,
  prepareValueFromMapping,
  getClipboardSecret,
  prepareTheme,
  resolveThemeName,
  parseSecretsFromToml,
};
