import createReactClass from 'create-react-class';
import { Map, OrderedMap } from 'immutable';

import { ButtonGroup, ButtonInline, RadioGroup, Search } from '@keboola/design';

import { componentTypes } from '@/constants/componentTypes';
import {
  COLLAPSED_CONFIGURATIONS,
  CONFIGURATIONS_COMPONENTS_SORT_BY,
} from '@/constants/localStorageKeys';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { prepareTablesMetadataMap } from '@/modules/storage/helpers';
import { RouterLink } from '@/react/common';
import BoxLoader from '@/react/common/BoxLoader';
import ConfigurationsTable from '@/react/common/ConfigurationsTable/ConfigurationsTable';
import { SORT } from '@/react/common/constants';
import LazyList from '@/react/common/LazyList';
import NoResultsFound from '@/react/common/NoResultsFound';
import SortSelect from '@/react/common/SortSelect';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import { CHANGE_EVENT_KEY, getItem, setItem } from '@/utils/localStorage';
import { allowedTypes, routeNames } from './constants';
import { mergeSampleDataToConfigurations, sortComponents } from './helpers';

const Configurations = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      DevBranchesStore,
      ComponentsStore,
      InstalledComponentsStore,
      StorageTablesStore,
      NotificationsStore,
      JobsStore,
    ),
  ],

  getStateFromStores() {
    const installedComponents = InstalledComponentsStore.getAll().filter((component) =>
      allowedTypes.includes(component.get('type')),
    );
    const allComponents = ComponentsStore.getAll();

    return {
      installedComponents,
      allComponents,
      allTables: StorageTablesStore.getAll(),
      allConfigurations: InstalledComponentsStore.getAll(),
      components: mergeSampleDataToConfigurations(installedComponents, allComponents),
      componentsMetadata: InstalledComponentsStore.getAllMetadata(),
      latestJobs: JobsStore.getLatestConfigJobs(),
      isLoadingConfigurations: InstalledComponentsStore.getIsLoading(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      notifications: NotificationsStore.getAll(),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      filterType: RoutesStore.getRouterState().getIn(['location', 'query', 'type'], ''),
      filterQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
      sortBy: getItem(CONFIGURATIONS_COMPONENTS_SORT_BY, SORT.RECENTLY_ADDED),
      isSomeTableCollapsed: false,
    };
  },

  refreshStateFromLocalStorage() {
    this.setState({
      isSomeTableCollapsed: this.getComponentsFiltered().some((component, componentId) =>
        getItem(`${COLLAPSED_CONFIGURATIONS}-${componentId}`, false),
      ),
    });
  },

  componentDidMount() {
    window.addEventListener(CHANGE_EVENT_KEY, this.refreshStateFromLocalStorage);
    this.refreshStateFromLocalStorage();
  },

  componentWillUnmount() {
    window.removeEventListener(CHANGE_EVENT_KEY, this.refreshStateFromLocalStorage);
  },

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.filterQuery !== this.state.filterQuery ||
      prevState.filterType !== this.state.filterType
    ) {
      RoutesStore.getRouter().updateQuery({
        q: this.state.filterQuery,
        type: this.state.filterType,
      });
    }
  },

  render() {
    const components = this.getComponentsFiltered();

    return (
      <>
        <Search
          placeholder={this.getPlaceholder()}
          defaultValue={this.state.filterQuery}
          onChange={(query) => this.setState({ filterQuery: query })}
          suffix={
            <RadioGroup
              variant="button"
              value={this.state.filterType}
              onChange={(type) => {
                this.setState({ filterType: type });
              }}
            >
              <RadioGroup.Item value={componentTypes.ALL}>All</RadioGroup.Item>
              <RadioGroup.Separator />
              <RadioGroup.Item value={componentTypes.EXTRACTOR}>Data Sources</RadioGroup.Item>
              <RadioGroup.Item value={componentTypes.WRITER}>Data Destinations</RadioGroup.Item>
              <RadioGroup.Item value={componentTypes.APPLICATION}>Applications</RadioGroup.Item>
            </RadioGroup>
          }
        />
        {!this.state.components.isEmpty() && (
          <ButtonGroup space="large" className="tw-my-6 tw-w-full tw-justify-end">
            {this.renderCollapseAllToggle(components)}
            <SortSelect
              value={this.state.sortBy}
              onChange={(sortBy) => {
                this.setState({ sortBy });
                setItem(CONFIGURATIONS_COMPONENTS_SORT_BY, sortBy);
              }}
              disabled={components.isEmpty()}
              supportedOptions={[SORT.A_Z, SORT.Z_A, SORT.RECENTLY_ADDED, SORT.RECENTLY_USED]}
            />
          </ButtonGroup>
        )}
        <LazyList limit={5} items={components} render={this.renderConfigurations} />
      </>
    );
  },

  renderCollapseAllToggle(components) {
    const shouldExpand = this.state.isSomeTableCollapsed;

    return (
      <ButtonInline
        onClick={() => {
          components.map((component, componentId) => {
            setItem(`${COLLAPSED_CONFIGURATIONS}-${componentId}`, !shouldExpand);
          });
        }}
        disabled={components.isEmpty()}
      >
        {shouldExpand ? 'Expand' : 'Collapse'} Components
      </ButtonInline>
    );
  },

  renderConfigurations(componentsFiltered) {
    if (
      this.state.isLoadingConfigurations &&
      this.state.installedComponents.isEmpty() &&
      componentsFiltered.isEmpty()
    ) {
      return <BoxLoader entity="configurations" />;
    }

    if (this.state.installedComponents.isEmpty() && componentsFiltered.isEmpty()) {
      return (
        <div className="box tw-mt-4">
          <div className="box-content">
            No configurations created yet. Go to{' '}
            <RouterLink to={routeNames.ROOT}>Components Directory</RouterLink> page to create a new
            one.
          </div>
        </div>
      );
    }

    if (componentsFiltered.isEmpty()) {
      return <NoResultsFound className="tw-mt-4" entityName="configurations" />;
    }

    const firstComponentId = componentsFiltered.first()?.get('id');

    return componentsFiltered
      .map((component) => {
        return (
          <ConfigurationsTable
            showMigrations
            showComponentDetailLink
            showUsedIn
            showData={component.get('type') !== componentTypes.WRITER}
            allowCreateConfig
            key={component.get('id')}
            component={component}
            admins={this.state.admins}
            currentAdmin={this.state.currentAdmin}
            notifications={this.state.notifications}
            tablesMetadataMap={prepareTablesMetadataMap(this.state.allTables)}
            componentsMetadata={this.state.componentsMetadata}
            allComponents={this.state.allComponents}
            allConfigurations={this.state.allConfigurations}
            latestJobs={this.state.latestJobs}
            readOnly={this.state.readOnly}
            hasNewQueue={this.state.hasNewQueue}
            hasFlows={this.state.hasFlows}
            configurations={component.get('configurations', OrderedMap())}
            forceShowAll={!!this.state.filterQuery}
            {...(firstComponentId === component.get('id') && { className: 'mt-0' })}
          />
        );
      })
      .toArray();
  },

  getPlaceholder() {
    const components = this.state.installedComponents.filter((component) => {
      return !this.state.filterType || component.get('type') === this.state.filterType;
    });

    return `Search${
      !this.state.filterType ? ' all' : ''
    } components (${components.count()}) and configurations (${components
      .flatMap((component) => component.get('configurations'))
      .count()})`;
  },

  getComponentsFiltered() {
    let components = this.state.components.filter(
      (component) => !this.state.filterType || component.get('type') === this.state.filterType,
    );

    components = sortComponents(components, this.state.sortBy, this.state.latestJobs);

    if (!this.state.filterQuery) {
      return components;
    }

    const exactIdMatchComponent = this.state.allComponents.find(
      (component) => component.get('id') === this.state.filterQuery,
    );
    const filteredComponents = components
      .map((component) => {
        if (
          component.get('id') === this.state.filterQuery ||
          matchByWords(component.get('name'), this.state.filterQuery)
        ) {
          return component;
        }

        return component.set(
          'configurations',
          component.get('configurations', Map()).filter((configuration) => {
            return (
              configuration.get('id') === this.state.filterQuery ||
              matchByWords(
                [configuration.get('name'), configuration.get('description')],
                this.state.filterQuery,
              )
            );
          }),
        );
      })
      .filter((component) => component.get('configurations').count() > 0);

    if (exactIdMatchComponent && !filteredComponents.has(exactIdMatchComponent.get('id'))) {
      return filteredComponents.set(exactIdMatchComponent.get('id'), exactIdMatchComponent);
    }

    return filteredComponents;
  },
});

export default Configurations;
