import type { Dispatch, SetStateAction } from 'react';
import { DragDropProvider } from '@dnd-kit/react';

import { Badge, Button, Icon } from '@keboola/design';

import { generateConditionName, prepareNewNextCondition } from '@/modules/flows-v2/builder/helpers';
import type { AppNode, NextConditionWithId } from '@/modules/flows-v2/builder/types';
import { ConditionItem } from './ConditionItem';
import { GoToSelect } from './GoToSelect';
import { type DragEndEvent, reorderConditions } from './helpers';

export const ConditionsList = ({
  id,
  readOnly,
  parentIds,
  nodes,
  conditions,
  setConditions,
  isConditionValid,
}: {
  id: string;
  readOnly: boolean;
  nodes: AppNode[];
  parentIds: string[];
  conditions: NextConditionWithId[];
  setConditions: Dispatch<SetStateAction<NextConditionWithId[]>>;
  isConditionValid: (condition: NextConditionWithId) => boolean;
}) => {
  const finalCondition = conditions[conditions.length - 1];

  const updateCondition = (index: number, updatedCondition: NextConditionWithId) => {
    setConditions((prevConditions) =>
      prevConditions.map((condition, i) => {
        return i === index ? updatedCondition : condition;
      }),
    );
  };

  const handleReorder = (event: DragEndEvent) => {
    const updatedConditions = reorderConditions(conditions, event);

    if (!updatedConditions) {
      return;
    }

    setConditions(updatedConditions);
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <DragDropProvider onDragEnd={handleReorder}>
        {conditions.slice(0, -1).map((condition, index) => {
          return (
            <ConditionItem
              key={condition.id}
              id={id}
              index={index}
              nodes={nodes}
              parentIds={parentIds}
              condition={condition}
              readOnly={readOnly}
              onChange={(condition) => updateCondition(index, condition)}
              isValid={isConditionValid}
              onDelete={() =>
                setConditions((prevConditions) => prevConditions.filter((_, i) => i !== index))
              }
            />
          );
        })}
      </DragDropProvider>
      {!readOnly && (
        <div className="tw-flex tw-items-center tw-gap-3">
          <Button
            variant="outline"
            size="small"
            onClick={() => {
              setConditions((prevConditions) => [
                ...prevConditions.slice(0, -1),
                prepareNewNextCondition(id, generateConditionName(prevConditions)),
                ...prevConditions.slice(-1),
              ]);
            }}
            className="tw-self-start"
          >
            <Icon icon="plus" />
            Add Condition
          </Button>
          <span className="tw-text-xs tw-text-neutral-400">
            Only the first matching IF condition will be executed.
          </span>
        </div>
      )}

      <div className="tw-flex tw-flex-col tw-items-start tw-gap-3">
        <Badge
          variant="orange-inverse"
          className="tw-w-[50px] tw-text-xs tw-uppercase tw-leading-5"
        >
          Else
        </Badge>
        <GoToSelect
          id={id}
          nodes={nodes}
          parentIds={parentIds}
          condition={finalCondition}
          readOnly={readOnly}
          onChange={(goto) => updateCondition(conditions.length - 1, { ...finalCondition, goto })}
        />
      </div>
    </div>
  );
};
