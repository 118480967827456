/* eslint-disable react/no-multi-comp */
import Promise from 'bluebird';
import { Iterable, List } from 'immutable';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import dispatcher from '@/Dispatcher';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import jobsApi from '@/modules/jobs/JobsApi';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import { RouterLink } from '@/react/common';
import RoutesStore from '@/stores/RoutesStore';
import OrchestrationJobsStore from './stores/OrchestrationJobsStore';
import OrchestrationStore from './stores/OrchestrationsStore';
import * as constants from './Constants';
import { LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH, routeNames } from './Constants';
import orchestrationsApi from './OrchestrationsApi';

const rephaseTasks = (tasks) => {
  const isNullPhase = (phase) => phase === null || phase === 0 || typeof phase === 'undefined';
  let nullPhaseIdx = 1;
  let currentPhase = { id: null };
  const result = [];
  for (let task of tasks) {
    const { phase } = task;
    if (isNullPhase(phase) || phase.toString() !== currentPhase.id) {
      let newPhaseId = phase;
      if (isNullPhase(phase)) {
        newPhaseId = `Phase ${nullPhaseIdx}`;
        nullPhaseIdx++;
      }
      // create new phase
      const newPhase = {
        id: `${newPhaseId}`,
        tasks: [task],
      };
      currentPhase = newPhase;
      result.push(newPhase);
    } else {
      currentPhase.tasks.push(task);
    }
  }
  // return tasks
  return result;
};

const dephaseTasks = (tasks) => {
  let result = List();
  tasks.forEach((phase) => {
    const phaseId = phase.get('id');
    return phase.get('tasks').forEach((task) => (result = result.push(task.set('phase', phaseId))));
  });
  return result;
};

const ActionCreators = {
  rephaseTasks(tasks) {
    return rephaseTasks(tasks);
  },

  dephaseTasks(tasks) {
    return dephaseTasks(tasks);
  },

  loadOrchestrationsForce() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATIONS_LOAD,
    });
    return orchestrationsApi
      .getOrchestrations()
      .then((orchestrations) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATIONS_LOAD_SUCCESS,
          orchestrations,
        });
      })
      .catch((err) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATIONS_LOAD_ERROR,
        });
        throw err;
      });
  },

  loadOrchestrations() {
    if (OrchestrationStore.getIsLoaded()) {
      this.loadOrchestrationsForce();
      return Promise.resolve();
    }

    return this.loadOrchestrationsForce();
  },

  loadOrchestrationForce(id) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_LOAD,
      orchestrationId: id,
    });

    VersionsActionCreators.loadVersionsForce('orchestrator', id.toString());

    return orchestrationsApi
      .getOrchestration(id)
      .then(this.receiveOrchestration)
      .catch((error) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_LOAD_ERROR,
        });
        throw error;
      });
  },

  receiveOrchestration(orchestration) {
    orchestration.tasks = rephaseTasks(orchestration.tasks);
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_LOAD_SUCCESS,
      orchestration,
    });
  },

  loadOrchestration(id) {
    if (OrchestrationStore.has(id) && OrchestrationStore.hasOrchestrationTasks(id)) {
      return Promise.resolve();
    }
    return this.loadOrchestrationForce(id);
  },

  deleteOrchestration(id) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_DELETE_START,
      orchestrationId: id,
    });

    const orchestration = OrchestrationStore.get(id);

    return orchestrationsApi
      .deleteOrchestration(id)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_DELETE_SUCCESS,
          orchestrationId: id,
        });

        return ApplicationActionCreators.sendNotification({
          type: 'info',
          message: (props) => (
            <>
              Orchestration <b>{orchestration.get('name')}</b> was moved to{' '}
              <RouterLink
                to={trashRouteNames.SETTINGS_TRASH}
                onClick={props.onClick}
                query={{ q: orchestration.get('id') }}
              >
                Trash
              </RouterLink>
            </>
          ),
        });
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_DELETE_ERROR,
          orchestrationId: id,
        });
        throw e;
      });
  },

  createOrchestration(data, redirect = true) {
    let newOrchestration = {};
    return orchestrationsApi
      .createOrchestration(data)
      .then((orchestration) => {
        newOrchestration = orchestration;
        return InstalledComponentsActionCreators.loadInstalledComponentsForce();
      })
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_CREATE_SUCCESS,
          orchestration: newOrchestration,
        });

        if (!redirect) {
          return newOrchestration;
        }
        return RoutesStore.getRouter().transitionTo(routeNames.ORCHESTRATION, {
          orchestrationId: newOrchestration.id,
        });
      });
  },

  loadMoreJobs(orchestrationId) {
    const jobs = OrchestrationJobsStore.getOrchestrationJobs(orchestrationId);
    return this.loadOrchestrationJobsForce(
      orchestrationId,
      LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH.initial,
      jobs.count(),
    );
  },

  loadOrchestrationJobsForce(
    orchestrationId,
    limit = LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH.reload,
    offset = 0,
  ) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOBS_LOAD,
      orchestrationId,
    });

    return orchestrationsApi
      .getOrchestrationJobs(orchestrationId, limit, offset)
      .then((jobs) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOBS_LOAD_SUCCESS,
          orchestrationId,
          jobs,
        });
        return jobs;
      })
      .catch((error) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOBS_LOAD_ERROR,
          orchestrationId,
        });
        throw error;
      });
  },

  loadOrchestrationJobs(orchestrationId) {
    if (OrchestrationJobsStore.hasOrchestrationJobs(orchestrationId)) {
      return Promise.resolve();
    }
    return this.loadOrchestrationJobsForce(
      orchestrationId,
      LIMIT_OF_ORCHESTRATION_JOBS_TO_FETCH.initial,
    );
  },

  loadJobForce(jobId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOB_LOAD,
      jobId,
    });

    return orchestrationsApi.getJob(jobId).then((job) =>
      dispatcher.handleViewAction({
        type: constants.ActionTypes.ORCHESTRATION_JOB_LOAD_SUCCESS,
        orchestrationId: job.orchestrationId,
        job,
      }),
    );
  },

  loadJob(jobId) {
    if (OrchestrationJobsStore.getJob(jobId)) {
      this.loadJobForce(jobId);
      return Promise.resolve();
    }
    return this.loadJobForce(jobId);
  },

  activateOrchestration(id) {
    return this.changeActiveState(id, true);
  },

  disableOrchestration(id) {
    return this.changeActiveState(id, false);
  },

  changeActiveState(orchestrationId, active) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_ACTIVE_CHANGE_START,
      active,
      orchestrationId,
    });

    return orchestrationsApi
      .updateOrchestration(orchestrationId, { active })
      .then((response) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_ACTIVE_CHANGE_SUCCESS,
          active: response.active,
          orchestrationId,
        });

        return VersionsActionCreators.loadVersionsForce('orchestrator', orchestrationId.toString());
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_ACTIVE_CHANGE_ERROR,
          active,
          orchestrationId,
        });
        throw e;
      });
  },

  startOrchestrationFieldEdit(orchestrationId, fieldName) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_FIELD_EDIT_START,
      orchestrationId,
      field: fieldName,
    });
  },

  cancelOrchestrationFieldEdit(orchestrationId, fieldName) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_FIELD_EDIT_CANCEL,
      orchestrationId,
      field: fieldName,
    });
  },

  updateOrchestrationFieldEdit(orchestrationId, fieldName, newValue) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_FIELD_EDIT_UPDATE,
      orchestrationId,
      field: fieldName,
      value: newValue,
    });
  },

  saveOrchestrationField(orchestrationId, fieldName) {
    const value = OrchestrationStore.getEditingValue(orchestrationId, fieldName);

    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_START,
      orchestrationId,
      field: fieldName,
    });

    const data = {};
    data[fieldName] = value;

    return orchestrationsApi
      .updateOrchestration(orchestrationId, data)
      .then((orchestration) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_SUCCESS,
          orchestrationId,
          field: fieldName,
          orchestration,
        });
        return VersionsActionCreators.loadVersionsForce('orchestrator', orchestrationId.toString());
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_ERROR,
          orchestrationId,
          field: fieldName,
          error: e,
        });
        throw e;
      });
  },

  startOrchestrationTasksEdit(orchestrationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_TASKS_EDIT_START,
      orchestrationId,
    });
  },

  cancelOrchestrationTasksEdit(orchestrationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_TASKS_EDIT_CANCEL,
      orchestrationId,
    });
  },

  updateOrchestrationsTasksEdit(orchestrationId, tasks) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_TASKS_EDIT_UPDATE,
      orchestrationId,
      tasks,
    });
  },

  saveOrchestrationTasks(orchestrationId) {
    let tasks = OrchestrationStore.getEditingValue(orchestrationId, 'tasks');
    tasks = dephaseTasks(tasks);
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_TASKS_SAVE_START,
      orchestrationId,
    });

    return orchestrationsApi
      .saveOrchestrationTasks(orchestrationId, tasks.toJS())
      .then((data) => {
        // update tasks from server
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_TASKS_SAVE_SUCCESS,
          orchestrationId,
          tasks: rephaseTasks(data),
        });
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_TASKS_SAVE_ERROR,
          orchestrationId,
          error: e,
        });
        throw e;
      });
  },

  startJobRetryTasksEdit(jobId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOB_RETRY_EDIT_START,
      jobId,
    });
  },

  updateJobRetryTasksEdit(jobId, tasks) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOB_RETRY_EDIT_UPDATE,
      jobId,
      tasks,
    });
  },

  startOrchestrationRunTasksEdit(orchestrationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_RUN_TASK_EDIT_START,
      orchestrationId,
    });
  },

  cancelOrchestrationRunTasksEdit(orchestrationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_RUN_TASK_EDIT_CANCEL,
      orchestrationId,
    });
  },

  updateOrchestrationRunTasksEdit(orchestrationId, tasks) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_RUN_TASK_EDIT_UPDATE,
      orchestrationId,
      tasks,
    });
  },

  startOrchestrationNotificationsEdit(id) {
    return this.startOrchestrationFieldEdit(id, 'notifications');
  },

  cancelOrchestrationNotificationsEdit(id) {
    return this.cancelOrchestrationFieldEdit(id, 'notifications');
  },

  updateOrchestrationNotificationsEdit(id, newNotifications) {
    return this.updateOrchestrationFieldEdit(id, 'notifications', newNotifications);
  },

  saveOrchestrationNotificationsEdit(id) {
    const notifications = OrchestrationStore.getEditingValue(id, 'notifications');

    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_START,
      orchestrationId: id,
      field: 'notifications',
    });

    return orchestrationsApi
      .saveOrchestrtionNotifications(id, notifications.toJS())
      .then((data) =>
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_SUCCESS,
          orchestrationId: id,
          field: 'notifications',
          notifications: data,
        }),
      )
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_FIELD_SAVE_ERROR,
          orchestrationId: id,
          field: 'notifications',
          error: e,
        });
        throw e;
      });
  },

  runOrchestration(id, tasks, notify = false) {
    let data = { config: id };

    if (tasks) {
      data.tasks = dephaseTasks(tasks);
    }

    return orchestrationsApi.runOrchestration(data).then((newJob) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.ORCHESTRATION_JOB_LOAD_SUCCESS,
        orchestrationId: newJob.orchestrationId,
        job: newJob,
      });
      if (tasks) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_RUN_TASK_EDIT_SUCCESS,
          orchestrationId: newJob.orchestrationId,
        });
      }
      if (notify) {
        return ApplicationActionCreators.sendNotification({
          type: 'info',
          message: (props) => (
            <>
              <RouterLink
                to={routeNames.JOB}
                params={{
                  jobId: newJob.id,
                  orchestrationId: id,
                }}
                onClick={props.onClick}
              >
                Orchestrator job
              </RouterLink>
              {' has been scheduled'}
            </>
          ),
        });
      }
    });
  },

  terminateJob(jobId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOB_TERMINATE_START,
      jobId,
    });

    return jobsApi
      .terminateJob(jobId)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOB_TERMINATE_SUCCESS,
          jobId,
        });
        return this.loadJobForce(jobId);
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOB_TERMINATE_ERROR,
          jobId,
        });
        throw e;
      });
  },

  retryOrchestrationJob(jobId, orchestrationId, notify = false) {
    const tasks = OrchestrationJobsStore.getEditingValue(jobId, 'tasks').map((task) => {
      return task.update('actionParameters', (parameters) => {
        return Iterable.isIterable(parameters) ? JSON.stringify(parameters.toJS()) : parameters;
      });
    });

    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATION_JOB_RETRY_START,
      jobId,
    });

    return orchestrationsApi
      .retryJob(jobId, tasks)
      .then((newJob) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOB_RETRY_SUCCESS,
          jobId,
          job: newJob,
        });
        this.loadOrchestrationJobsForce(orchestrationId);
        if (notify) {
          return ApplicationActionCreators.sendNotification({
            type: 'info',
            message: (props) => (
              <>
                <RouterLink
                  to={routeNames.JOB}
                  params={{
                    jobId: newJob.id,
                    orchestrationId,
                  }}
                  onClick={props.onClick}
                >
                  Orchestrator job
                </RouterLink>
                {' has been scheduled'}
              </>
            ),
          });
        }
      })
      .catch((e) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.ORCHESTRATION_JOB_RETRY_ERROR,
          jobId,
        });
        throw e;
      });
  },

  setOrchestrationsListSortByNameOption(option) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ORCHESTRATIONS_LIST_SORT_BY_NAME,
      option,
    });
  },
};

export default ActionCreators;
