import InfiniteScroll from 'react-infinite-scroller';
import type { Map } from 'immutable';

import JobsStore from '@/modules/components/stores/StorageJobsStore';
import { FileSize, RouterLink } from '@/react/common';
import BlockButton from '@/react/common/BlockButton';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import tableIdParser from '@/utils/tableIdParser';
import JobDetailModal from './components/JobDetailModal';
import JobsTable from './components/JobsTable';
import StorageTabs from './components/StorageTabs';
import { loadMoreJobs } from './actions';
import { bucketTabs, routeNames, tableTabs } from './constants';

const Jobs = () => {
  const store = useStores(
    () => {
      return {
        hasMore: JobsStore.hasMore() as boolean,
        isLoading: JobsStore.getIsLoading(),
        isLoadingMore: JobsStore.getIsLoadingMore() as boolean,
        jobs: JobsStore.getAll(),
        jobDetail: JobsStore.getJob(RoutesStore.getCurrentRouteParam('jobId')),
        admins: ApplicationStore.getAdmins(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
      };
    },
    [],
    [RoutesStore, JobsStore, ApplicationStore],
  );

  const fetchMoreJobs = () => {
    if (store.isLoadingMore) return;

    return loadMoreJobs(store.jobs.count());
  };

  const renderJobs = () => {
    if (store.jobs.isEmpty() && !store.isLoading) {
      return <div className="box-content">No jobs found.</div>;
    }

    return (
      <>
        <InfiniteScroll initialLoad={false} hasMore={store.hasMore} loadMore={fetchMoreJobs}>
          <JobsTable
            jobs={store.jobs}
            admins={store.admins}
            renderTableLink={renderTableLink}
            renderDataTransfer={renderDataTransfer}
            showJobDetailModal={(jobId) => {
              RoutesStore.getRouter().replaceTo(routeNames.JOBS, {
                jobId,
              });
            }}
          />
        </InfiniteScroll>
        {store.hasMore && <BlockButton onClick={fetchMoreJobs} isLoading={store.isLoadingMore} />}
        <JobDetailModal
          show={!store.jobDetail.isEmpty()}
          job={store.jobDetail}
          onHide={() => {
            RoutesStore.getRouter().replaceTo(routeNames.JOBS);
          }}
          renderTableLink={renderTableLink}
          renderDataTransfer={renderDataTransfer}
        />
      </>
    );
  };

  const renderTableLink = (job: Map<string, any>) => {
    const tableCreate = job.get('operationName') === 'tableCreate';
    const tableId = tableCreate
      ? job.getIn(['results', 'id'], job.get('tableId'))
      : job.get('tableId');

    if (!tableId) {
      return null;
    }

    const { stage, bucket, table } = tableIdParser.parse(tableId).parts;

    return (
      <RouterLink
        to={routeNames.TABLE}
        params={{
          bucketId: `${stage}.${bucket}`,
          bucketTab: bucketTabs.OVERVIEW,
          tableName: table,
          tableTab: tableTabs.OVERVIEW,
        }}
        onClick={(event) => event.stopPropagation()}
      >
        {tableId}
      </RouterLink>
    );
  };

  const renderDataTransfer = (job: Map<string, any>) => {
    const metric = job.get('metrics');
    const inData = metric.get('inCompressed', false)
      ? metric.get('inBytes')
      : metric.get('inBytesUncompressed');
    const outData = metric.get('outCompressed', false)
      ? metric.get('outBytes')
      : metric.get('outBytesUncompressed');

    return <FileSize size={inData + outData} />;
  };

  return (
    <StorageTabs
      hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
      hasPayAsYouGo={store.hasPayAsYouGo}
      canEnableDataStreams={store.canEnableDataStreams}
    >
      <div className="box">{renderJobs()}</div>
    </StorageTabs>
  );
};

export default Jobs;
