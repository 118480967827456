import { useRef } from 'react';
import type { ReactNode } from 'react';
import type { Blocker } from 'react-router';
import { useBlocker } from 'react-router';
import { Map } from 'immutable';

import { componentTypes } from '@/constants/componentTypes';
import { constructMessage, getAutomationFromFlowMetadata } from '@/modules/automations/helpers';
import { useSendMessage } from '@/modules/automations/queries';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { getCurrentFlowId } from '@/modules/flows/helpers';
import { resolveComponent } from '@/react/admin/project/helpers';
import useStores from '@/react/hooks/useStores';
import RoutesStore from '@/stores/RoutesStore';
import nextTick from '@/utils/nextTick';
import { createUrl } from '@/utils/router/createUrl';
import { isActiveByPathName } from '@/utils/router/hooks/useIsActiveByPathName';
import CatchUnsavedChangesModal from './CatchUnsavedChangesModal';

type Props = {
  children: ReactNode;
  isDirty: boolean;
  onSave: () => Promise<unknown>;
  isSaveDisabled?: boolean;
  onDirtyLeave?: () => void;
  text?: ReactNode;
};

const CatchUnsavedChanges = (props: Props) => {
  const { flowId, taskId, automationId, isAutomationTaskView, component, configuration } =
    useStores(
      () => {
        const { isDraft: isAutomationView, automationId } = getAutomationFromFlowMetadata(
          getCurrentFlowId(),
          InstalledComponentsStore.getAllMetadata() as Map<string, any>,
        );
        const { flowId, taskId } = RoutesStore.getRouterState()
          .getIn(['location', 'query'], Map())
          .toJS();

        return {
          flowId,
          taskId,
          automationId,
          isAutomationTaskView: isAutomationView && !!taskId,
          component: resolveComponent()?.toJS(),
          configuration: InstalledComponentsStore.getConfig(
            RoutesStore.getCurrentRouteComponentId(),
            RoutesStore.getConfigId(),
          )?.toJS(),
        };
      },
      [],
      [RoutesStore, InstalledComponentsStore],
    );
  const { mutateAsync: sendMessageMutation } = useSendMessage(automationId);
  const blockerRef = useRef<Blocker | null>(null);

  const sendAutomationMessage = async () => {
    if (!isAutomationTaskView) return;

    await sendMessageMutation(
      constructMessage({
        type:
          component.type === componentTypes.TRANSFORMATION
            ? 'transformationEditFinished'
            : 'configurationEditFinished',
        data: {
          taskId,
          componentId: component.id,
          configurationId: configuration.id,
          configuration: configuration.configuration,
          configurationVersion: configuration.version,
        },
      }),
    );
  };

  blockerRef.current = useBlocker(({ currentLocation, nextLocation }) => {
    if (nextLocation?.state?.force || currentLocation.pathname === nextLocation.pathname)
      return false;

    if (props.isDirty) return true;

    if (
      !isAutomationTaskView ||
      !isActiveByPathName(nextLocation, createUrl(flowsRouteNames.DETAIL, { config: flowId }))
    ) {
      return false;
    }

    sendAutomationMessage().finally(() => blockerRef.current?.proceed?.());

    return true;
  });

  const shouldBlock = blockerRef.current?.state === 'blocked';

  const handleLeave = async () => {
    if (props.isDirty && props.onDirtyLeave) {
      nextTick(props.onDirtyLeave);
    }

    await sendAutomationMessage();

    if (shouldBlock) {
      blockerRef.current?.proceed?.();
    }
  };

  const handleSave = async () => {
    await props.onSave();
    return handleLeave();
  };

  const handleHide = () => {
    if (shouldBlock) {
      blockerRef.current?.reset?.();
    }
  };

  return (
    <>
      {props.children}
      <CatchUnsavedChangesModal
        show={shouldBlock && props.isDirty}
        text={props.text}
        isSaveDisabled={props.isSaveDisabled}
        onSave={handleSave}
        onLeave={handleLeave}
        onHide={handleHide}
      />
    </>
  );
};

export default CatchUnsavedChanges;
