import { FormControl, InputGroup } from 'react-bootstrap';
import type { OrderedMap } from 'immutable';

import { HelpBlock } from '@keboola/design';

type SourceSearchInEditorProps = {
  inputSearch: OrderedMap<string, string>;
  id?: string;
};

const SourceSearchInEditor = ({ inputSearch, id }: SourceSearchInEditorProps) => (
  <>
    <InputGroup>
      <InputGroup.Addon>
        <small>{inputSearch.get('key', '')}</small>
      </InputGroup.Addon>
      <FormControl id={id} value={inputSearch.get('value', '')} disabled readOnly />
    </InputGroup>
    <HelpBlock className="tw-mt-1">Table will be searched by its metadata</HelpBlock>
  </>
);

export default SourceSearchInEditor;
