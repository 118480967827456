import { Component, startTransition } from 'react';
import PropTypes from 'prop-types';
import { createBrowserRouter, createRoutesFromElements } from 'react-router';
import { RouterProvider } from 'react-router/dom';
import Promise from 'bluebird';
import qs from 'qs';
import _ from 'underscore';

import { TooltipProvider } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { Loading } from '@/modules/components/Loading';
import { createPath } from '@/utils/router/createPath';
import { createUrl } from '@/utils/router/createUrl';
import { getBaseName } from '@/utils/router/getBaseName';
import { createRoutesForNamedURLResolver } from '@/utils/router/namedRouterResolver';
import ApplicationActionCreators from './actions/ApplicationActionCreators';
import RouterActionCreators from './actions/RouterActionCreators';
import ComponentsActionCreators from './modules/components/ComponentsActionCreators';
import InstalledComponentsActionCreators from './modules/components/InstalledComponentsActionCreators';
import StorageActionCreators from './modules/components/StorageActionCreators';
import StorageApi from './modules/components/StorageApi';
import { loadMergeRequests, setCurrentDevBranchId } from './modules/dev-branches/actions';
import { redirectToProductionIfBranchNotFound } from './modules/dev-branches/helpers';
import { loadRuntimes } from './modules/runtimes/actions';
import ServicesActionCreators from './modules/services/ActionCreators';
import { generateSessionToken, loadProject } from './modules/settings/actions';
import { catchSessionTokenError } from './modules/settings/helpers';
import StackFeaturesActionCreators from './modules/stack-features/ActionCreators';
import { loadVariables } from './modules/vault/actions';
import { ErrorBoundaryWithNotification } from './react/layout/ErrorBoundaryWithNotification';
import ApplicationStore from './stores/ApplicationStore';
import { createReactRouterRoutes } from './utils/router/createReactRouterRoutes';
import { init as initApis } from './api';
import appRoutes from './routes';

class Root extends Component {
  state = {
    router: null,
  };

  componentDidMount() {
    this.checkSafariBrowser();
    this.loadRequiredData().then(this.createRoutes);
  }

  render() {
    return (
      <ErrorBoundaryWithNotification>
        <TooltipProvider>
          {this.state.router ? <RouterProvider router={this.state.router} /> : <Loading />}
        </TooltipProvider>
      </ErrorBoundaryWithNotification>
    );
  }

  loadRequiredData = () => {
    const { sapi, organizations, projectTemplates, kbc, tokenStats } = this.props.data;

    ApplicationActionCreators.receiveApplicationData({
      isPreview: this.props.isPreview,
      isDemoPreview: this.props.isDemoPreview,
      sapiUrl: sapi.url,
      sapiToken: sapi.token,
      organizations,
      projectTemplates,
      kbc,
      tokenStats,
    });
    RouterActionCreators.routesConfigurationReceive(appRoutes);
    setCurrentDevBranchId();

    return Promise.all([
      StorageApi.loadStackInfo().then((stackInfo) => {
        ServicesActionCreators.receive(stackInfo.services);
        StackFeaturesActionCreators.receive(stackInfo.features);
        ApplicationActionCreators.receiveStack(stackInfo.stack);
        ComponentsActionCreators.receiveAllComponents(stackInfo.components);
        initApis(stackInfo.services, sapi.token.token);
      }),
      StorageActionCreators.loadDevBranches(),
    ]).tap(() => {
      generateSessionToken()
        .then(() => loadProject())
        .catch(catchSessionTokenError);

      if (ApplicationStore.hasNewQueue()) {
        loadRuntimes();
      }

      if (ApplicationStore.hasProtectedDefaultBranch()) {
        loadMergeRequests();
        loadVariables();
      }

      return Promise.all([
        // force to load buckets and tables to prevent next load to be blocking
        StorageActionCreators.loadBucketsAndTablesForce({ include: 'columns' }),
        ApplicationStore.hasAiAutomations() &&
          InstalledComponentsActionCreators.loadComponentsMetadataForce(KEBOOLA_ORCHESTRATOR),
        InstalledComponentsActionCreators.loadInstalledComponentsForce({
          include: 'configuration',
        }).catch((error) => {
          if (redirectToProductionIfBranchNotFound(error)) {
            return;
          }

          throw error;
        }),
      ]);
    });
  };

  createRoutes = () => {
    createRoutesForNamedURLResolver(appRoutes);
    const basename = getBaseName();
    const routes = createRoutesFromElements(createReactRouterRoutes(appRoutes, basename));
    const router = createBrowserRouter(routes, { basename });

    RouterActionCreators.routerCreated({
      transitionTo: (name, params, query, hash) =>
        router.navigate(createPath(name, params, query, hash)),
      transitionToForce: (name, params, query) =>
        router.navigate(createPath(name, params, query), { state: { force: true } }),
      replaceTo: (name, params, query, hash) =>
        router.navigate(createPath(name, params, query, hash), { replace: true }),
      updateQuery: (newQuery) => {
        const query = _.pick(
          { ...qs.parse(new URLSearchParams(window.location.search).toString()), ...newQuery },
          Boolean,
        );
        window.history.replaceState(
          {},
          document.title,
          createUrl(window.location.pathname, null, query),
        );
      },
    });

    router.subscribe(({ navigation }) => {
      // start navigation
      if (Boolean(navigation.location)) {
        RouterActionCreators.routeChangeStart(navigation.location.pathname);
        return;
      }

      // navigation is finished, startTransition match react-router behavior
      startTransition(() => RouterActionCreators.routeChangeSuccess());
    });

    this.setState({ router });
  };

  checkSafariBrowser = () => {
    if (navigator.userAgent?.includes('Safari') && !navigator.userAgent?.includes('Chrome')) {
      document.body.classList.add('safari-browser');
    }
  };
}

Root.propTypes = {
  data: PropTypes.shape({
    kbc: PropTypes.object.isRequired,
    sapi: PropTypes.object.isRequired,
    tokenStats: PropTypes.object.isRequired,
    organizations: PropTypes.array.isRequired,
    projectTemplates: PropTypes.array.isRequired,
  }).isRequired,
};

export default Root;
