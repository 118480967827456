import type { ComponentProps } from 'react';
import * as RSwitch from '@radix-ui/react-switch';
import type { VariantProps } from 'class-variance-authority';

import { switchVariants, thumbVariants } from './styles';

type RSwitchProps = ComponentProps<typeof RSwitch.Root>;

export type SwitchChangeEventHandler = RSwitchProps['onCheckedChange'];

type Props = { onChange?: SwitchChangeEventHandler; id?: string | number } & Omit<
  RSwitchProps,
  'asChild' | 'onCheckedChange' | 'onChange' | 'id'
> &
  VariantProps<typeof switchVariants>;

export const Switch = ({
  className,
  size,
  disabled = false,
  onChange,
  defaultChecked,
  id,
  ...props
}: Props) => {
  return (
    <RSwitch.Root
      {...props}
      id={id?.toString()}
      onCheckedChange={onChange}
      defaultChecked={defaultChecked}
      disabled={disabled}
      className={switchVariants({
        className,
        size,
      })}
    >
      <RSwitch.Thumb className={thumbVariants({ size })} />
    </RSwitch.Root>
  );
};
