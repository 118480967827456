import { backends } from '@/modules/legacy-transformation/Constants';

const resolveBackendName = function (transformation) {
  if (transformation.get('backend') === backends.DOCKER) {
    return transformation.get('type');
  }
  return transformation.get('backend');
};

export { resolveBackendName };
