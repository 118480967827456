import { useState } from 'react';

import { Button } from '@keboola/design';

import { PRODUCT_FRUITS_PUBLISHED_AUTOMATION_SURVEY_SHOWN } from '@/constants/localStorageKeys';
import { useDeleteAutomation } from '@/modules/automations/queries';
import { getItem, setItem } from '@/utils/localStorage';
import { PRODUCT_FRUITS_SURVEY_IDS } from './constants';
import { savePublishedAutomationIdMetadata } from './helpers';

const PublishButton = (props: { automationId?: string; configId: string }) => {
  const [isPublishing, setIsPublishing] = useState(false);
  const { mutateAsync: deleteAutomation } = useDeleteAutomation();

  if (!props.automationId) return null;

  const handlePublish = async () => {
    if (!props.automationId) return null;

    setIsPublishing(true);

    try {
      await savePublishedAutomationIdMetadata(props.configId, props.automationId);
      deleteAutomation(props.automationId);

      if (!window.productFruitsIsReady || getItem(PRODUCT_FRUITS_PUBLISHED_AUTOMATION_SURVEY_SHOWN))
        return;

      window.productFruits?.api.surveys.startSurvey(PRODUCT_FRUITS_SURVEY_IDS.AUTOMATION_PUBLISHED);
      setItem(PRODUCT_FRUITS_PUBLISHED_AUTOMATION_SURVEY_SHOWN, true);
    } finally {
      setIsPublishing(false);
    }
  };

  return (
    <Button disabled={isPublishing} onClick={handlePublish}>
      Publish
    </Button>
  );
};

export default PublishButton;
