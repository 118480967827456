import * as constants from '@/constants/KbcConstants';
import dispatcher from '@/Dispatcher';

export default {
  routesConfigurationReceive(routes) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_ROUTES_CONFIGURATION_RECEIVE,
      routes,
    });
  },

  updateQuery(query) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_UPDATE_QUERY,
      query,
    });
  },

  routeChangeStart(newPathname) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_ROUTE_CHANGE_START,
      newPathname,
    });
  },

  routeChangeSuccess() {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_ROUTE_CHANGE_SUCCESS,
    });
  },

  routeChangeError(error) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_ROUTE_CHANGE_ERROR,
      error,
    });
  },

  routerCreated(router) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.ROUTER_CREATED,
      router,
    });
  },
};
