import { routeNames } from '@/modules/data-catalog/constants';
import { TabLink, TabNav } from '@/react/common';

export const TabNavigation = () => (
  <div className="tabs-with-border-wrapper">
    <TabNav>
      <TabLink to={routeNames.SHARED_WITH_YOU}>Shared with you</TabLink>
      <TabLink to={routeNames.SHARED_FROM_THIS_PROJECT}>Shared from this project</TabLink>
    </TabNav>
  </div>
);
