import { Button, Card, Icon } from '@keboola/design';

const PredefinedExamples = ({
  onClick,
  disabled,
}: {
  onClick: (prompt: string) => void;
  disabled: boolean;
}) => (
  <Card className="tw-absolute tw-z-10 tw-mt-2 tw-hidden tw-w-full !tw-px-4 peer-focus-within:tw-flex">
    <Card.Header>
      <Card.Title className="tw-text-xs/5 tw-uppercase tw-text-neutral-500">
        Try our examples
      </Card.Title>
    </Card.Header>
    <Card.Content>
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Example
          onClick={onClick}
          prompt="I want to have a report in Looker on the most frequently sold products in our Shopify inventory."
          disabled={disabled}
        />
        <Example
          onClick={onClick}
          prompt="I would like to create a weekly analysis of our marketing campaigns."
          disabled={disabled}
        />
        <Example
          onClick={onClick}
          prompt="Our team aims to gather customer support tickets from Jira, analyze their urgency levels, and push the data to Looker."
          disabled={disabled}
        />
      </div>
    </Card.Content>
  </Card>
);

const Example = ({
  prompt,
  onClick,
  disabled,
}: {
  prompt: string;
  onClick: (prompt: string) => void;
  disabled: boolean;
}) => (
  <Button
    onMouseDown={(event) => {
      event.preventDefault();
      onClick(prompt);
    }}
    variant="outline"
    className="tw-max-h-none tw-justify-between tw-px-3 tw-py-2.5 tw-text-left tw-text-sm tw-font-normal tw-normal-case tw-tracking-normal !tw-text-neutral-400"
    disabled={disabled}
  >
    <span>{prompt}</span>
    <Icon icon="angle-right" />
  </Button>
);

export default PredefinedExamples;
