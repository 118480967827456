import { cn } from '@keboola/design';

import { deleteTask } from '@/modules/flows-v2/builder/actions';
import { TaskBox } from '@/modules/flows-v2/builder/components/TaskBox';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { VisualVariableTask } from '@/modules/flows-v2/builder/types';
import { CircleIcon, Truncated } from '@/react/common';
import TaskActions from './TaskActions';

export const PhaseVariableTask = ({
  task,
  readOnly,
}: {
  task: VisualVariableTask;
  readOnly: boolean;
}) => {
  const [context, selectedTaskId, setSelectedTaskId] = useBuilderStore((state) => [
    state.context,
    state.selectedTaskId,
    state.setSelectedTaskId,
  ]);

  const handleDeleteTask = () => {
    deleteTask(context.config.get('id'), task.id, context.tasks);
    setSelectedTaskId(null);
  };

  return (
    <TaskBox
      onClick={() => setSelectedTaskId(task.id)}
      active={selectedTaskId === task.id}
      className={cn({ 'tw-bg-neutral-50': !task.enabled })}
    >
      <CircleIcon
        bold
        smaller
        icon="code"
        color="blue"
        className={cn({ 'tw-opacity-50': !task.enabled })}
      />

      <h4 className={cn('tw-m-0', { 'tw-opacity-50': !task.enabled })}>
        <Truncated twoLines text={task.name} className="break-all" />
        <div className="tw-text-xs tw-text-neutral-400">Set Variable</div>
      </h4>

      <TaskActions
        readOnly={readOnly}
        onDelete={handleDeleteTask}
        onOpen={() => setSelectedTaskId(task.id)}
      />
    </TaskBox>
  );
};
