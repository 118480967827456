import { useState } from 'react';
import { type List, Map } from 'immutable';

import { IconButton, Tooltip } from '@keboola/design';

import { getProfileFullName } from '@/modules/ex-google-analytics-v4/helpers';
import { FilterPanel, Truncated } from '@/react/common';
import Checkbox from '@/react/common/Checkbox';
import ConfirmModal from '@/react/common/ConfirmModal';
import MarkedText from '@/react/common/MarkedText';
import { matchByWords } from '@/utils';
import string from '@/utils/string';

const ProfilesTable = (props: {
  profiles: List<any>;
  onSave: (profiles: List<any>) => Promise<any>;
  readOnly: boolean;
}) => {
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState(Map());
  const [isDeleting, setDeleting] = useState(Map());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const filteredProfiles = props.profiles.filter((profile: Map<string, any>) => {
    return matchByWords(getProfileFullName(profile), query);
  });

  const getProfileId = (profile: Map<string, any>) => {
    return profile.get('id', profile.get('propertyKey'));
  };

  const renderProfiles = () => {
    if (!query && props.profiles.isEmpty()) {
      return (
        <tr className="no-hover">
          <td>No profiles selected</td>
          <td />
        </tr>
      );
    }

    if (!!query && filteredProfiles.isEmpty()) {
      return (
        <tr className="no-hover">
          <td>No profiles found</td>
          <td />
        </tr>
      );
    }

    return filteredProfiles
      .map((profile: Map<string, any>) => {
        const id = getProfileId(profile);
        const isSelected = selected.has(id);
        const profileName = getProfileFullName(profile);

        return (
          <tr key={id} className="hoverable-actions">
            <td>
              <div className="tw-flex tw-items-center tw-justify-items-start">
                {!props.readOnly && (
                  <Checkbox
                    checked={isSelected}
                    onChange={() => {
                      setSelected(isSelected ? selected.delete(id) : selected.set(id, true));
                    }}
                    className="tw-mr-3"
                  />
                )}
                <Truncated
                  tooltip={profileName}
                  text={<MarkedText source={profileName} mark={query} />}
                />
              </div>
            </td>
            <td className="pr-1">
              {!props.readOnly && (
                <Tooltip
                  placement="top"
                  tooltip="Delete selected"
                  triggerClassName="tw-inline-flex"
                >
                  <IconButton
                    variant="invisible"
                    onClick={() => {
                      const profiles = props.profiles
                        .filter((savedProfile) => !savedProfile.equals(profile))
                        .toList();

                      setDeleting(isDeleting.set(id, true));
                      props.onSave(profiles).finally(() => {
                        setDeleting(isDeleting.delete(id));
                      });
                    }}
                    disabled={isDeleting.has(id)}
                    icon="trash"
                    isLoading={isDeleting.has(id)}
                  />
                </Tooltip>
              )}
            </td>
          </tr>
        );
      })
      .toArray();
  };

  const isAllSelected =
    !filteredProfiles.isEmpty() && selected.count() === filteredProfiles.count();
  const isSomeSelected = !selected.isEmpty() && selected.count() < filteredProfiles.count();

  return (
    <>
      <FilterPanel query={query} onChange={setQuery} className="tw-mb-4" />
      <div className="box tw-mb-6">
        <table className="table table-hover condensed no-spacing">
          <thead>
            <tr>
              <th>
                <div className="tw-flex tw-h-8 tw-items-center tw-justify-items-start">
                  {!props.readOnly && (
                    <Checkbox
                      checked={isAllSelected}
                      indeterminate={isSomeSelected}
                      disabled={filteredProfiles.isEmpty()}
                      onChange={() => {
                        setSelected(
                          isAllSelected || isSomeSelected
                            ? Map()
                            : (filteredProfiles
                                .toMap()
                                .mapKeys((key, profile) => getProfileId(profile))
                                .map(() => true) as Map<string, boolean>),
                        );
                      }}
                      className="tw-mr-3"
                    />
                  )}
                  Profiles
                  {!selected.isEmpty() && (
                    <div className="table-action-buttons">
                      <Tooltip placement="top" tooltip="Delete selected">
                        <IconButton
                          variant="invisible"
                          onClick={() => setShowDeleteModal(true)}
                          icon="trash"
                        />
                      </Tooltip>
                    </div>
                  )}
                </div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>{renderProfiles()}</tbody>
        </table>
        <ConfirmModal
          closeAfterResolve
          icon="trash"
          buttonType="danger"
          title="Delete selected profiles"
          buttonLabel="Delete"
          show={showDeleteModal}
          isLoading={!!isDeleting.get('selected')}
          onHide={() => setShowDeleteModal(false)}
          text={
            <>
              Are you sure you want to delete the <b>{selected.count()}</b> selected{' '}
              {string.pluralize(selected.count(), 'profile')}?
            </>
          }
          onConfirm={() => {
            const profiles = filteredProfiles
              .filter((profile) => !selected.has(getProfileId(profile)))
              .toList();

            setDeleting(isDeleting.set('selected', true));
            return props
              .onSave(profiles)
              .then(() => {
                setSelected(Map());
                setShowDeleteModal(false);
              })
              .finally(() => setDeleting(isDeleting.delete('selected')));
          }}
        />
      </div>
    </>
  );
};

export default ProfilesTable;
