import { useState } from 'react';

import Select from '@/react/common/Select';

const NEVER_EXPIRES = -2;
const CUSTOM_VALUE = -1;
const DEFAULT_CUSTOM_VALUE = 0;

type SelectOption = {
  label: string;
  value: number;
};

const selectOptions: SelectOption[] = [
  { label: 'Never', value: NEVER_EXPIRES },
  { label: '1 hour', value: 1 * 3600 },
  { label: '2 hours', value: 2 * 3600 },
  { label: '12 hours', value: 12 * 3600 },
  { label: '24 hours', value: 24 * 3600 },
  { label: '48 hours', value: 48 * 3600 },
  { label: 'Custom', value: CUSTOM_VALUE },
];

type ExpiresInEditProps = {
  value: number | null;
  disabled: boolean;
  onChange: (value: number | null) => void;
};

export const ExpiresInEdit = ({ value, disabled, onChange }: ExpiresInEditProps) => {
  const hasSelectValue = value === null || selectOptions.some((option) => option.value === value);

  const initialSelectValue = hasSelectValue
    ? value === null
      ? NEVER_EXPIRES
      : value
    : CUSTOM_VALUE;

  const [selectValue, setSelectValue] = useState<number>(initialSelectValue);

  const handleSelectChange = (newValue: number) => {
    setSelectValue(newValue);
    if (newValue !== CUSTOM_VALUE) {
      onChange(newValue === NEVER_EXPIRES ? null : newValue);
      return;
    }
    if (!value) {
      onChange(DEFAULT_CUSTOM_VALUE);
    }
  };

  return (
    <div className="col-sm-9">
      <Select
        disabled={disabled}
        clearable={false}
        searchable={false}
        options={selectOptions}
        value={selectValue}
        onChange={handleSelectChange}
      />
      {selectValue === CUSTOM_VALUE && (
        <div className="input-group tw-mt-2">
          <input
            disabled={disabled}
            type="number"
            min="1"
            value={value !== null ? value / 3600 : ''}
            onChange={(e) => onChange(Number(e.target.value) * 3600)}
            className="form-control"
          />
          <span className="input-group-addon">hours</span>
        </div>
      )}
    </div>
  );
};
