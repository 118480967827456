import Promise from 'bluebird';
import { fromJS, List, Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_SLICED_FILES_DOWNLOADER } from '@/constants/componentIds';
import * as applicationConstants from '@/constants/KbcConstants';
import dispatcher from '@/Dispatcher';
import componentRunner from '@/modules/components/ComponentRunner';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { loadSnapshots } from '@/modules/storage/actions';
import externalBucketNotification from '@/modules/storage/components/externalBucketNotification';
import tableAliasCreatedNotification from '@/modules/storage/components/tableAliasCreatedNotification';
import tableHasBeenRestoredNotification from '@/modules/storage/components/tableHasBeenRestoredNotification';
import { routeNames as storageRouteNames } from '@/modules/storage/constants';
import { downloadFile, prefixBucketNameWithBranchId } from '@/modules/storage/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import jobPoller from '@/utils/jobPoller';
import StorageBucketsStore from './stores/StorageBucketsStore';
import StorageFilesStore from './stores/StorageFilesStore';
import StorageTablesStore from './stores/StorageTablesStore';
import { getTableColumnMetadataPath } from './utils/tableMetadataHelper';
import * as constants from './Constants';
import importApi from './ImportApi';
import { MetadataKeys } from './MetadataConstants';
import storageApi from './StorageApi';

const BUCKETS_AND_TABLES_RELOAD_WAIT_TIME_MS = 60000;

const loadBucketsAndTables = _.throttle(() => {
  if (StorageTablesStore.getIsLoaded()) {
    loadBucketsAndTablesForce();
    return Promise.resolve();
  }
  return loadBucketsAndTablesForce();
}, BUCKETS_AND_TABLES_RELOAD_WAIT_TIME_MS);

const loadBucketsAndTablesForce = (params) => {
  if (StorageTablesStore.getIsLoading()) {
    return Promise.resolve();
  }

  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_START,
  });
  return Promise.props({ buckets: storageApi.getBuckets(), tables: storageApi.getTables(params) })
    .then(({ buckets, tables }) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_SUCCESS,
        buckets,
        tables,
      });
    })
    .finally(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKETS_AND_TABLES_LOAD_DONE,
      });
    });
};

const tokenVerify = () => {
  if (ApplicationStore.getSapiToken().has('bucketPermissions')) {
    tokenVerifyForce();
    return Promise.resolve();
  }

  return tokenVerifyForce();
};

const tokenVerifyForce = () => {
  return storageApi.verifyToken().then((sapiToken) => {
    dispatcher.handleViewAction({
      type: applicationConstants.ActionTypes.SAPI_TOKEN_RECEIVED,
      sapiToken,
    });
  });
};

const loadTableDetail = (tableId) => {
  const table = StorageTablesStore.getTable(tableId, Map());

  if (table.hasIn(getTableColumnMetadataPath(table))) {
    loadTableDetailForce(tableId);
    return Promise.resolve(table);
  }

  return loadTableDetailForce(tableId);
};

const loadTableDetailForce = (tableId) => {
  return storageApi.getTable(tableId).then((table) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_TABLE_DETAIL_LOAD_SUCCESS,
      table,
    });
    return table;
  });
};

const loadBucketDetail = (bucketId) => {
  if (StorageBucketsStore.hasBucket(bucketId)) {
    loadBucketDetailForce(bucketId);
    return Promise.resolve();
  }
  return loadBucketDetailForce(bucketId);
};

const loadBucketDetailForce = (bucketId) => {
  return storageApi.getBucket(bucketId).then((bucket) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_BUCKET_DETAIL_LOAD_SUCCESS,
      bucket,
    });
    return bucket;
  });
};

const loadFilesForce = (params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_FILES_LOAD,
  });
  return storageApi
    .getFiles(params)
    .then((files) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILES_LOAD_SUCCESS,
        files,
        params,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILES_LOAD_ERROR,
      });
      throw error;
    });
};

const loadFiles = (params) => {
  if (StorageFilesStore.getIsLoaded()) {
    return Promise.resolve();
  }
  return loadFilesForce(params);
};

const loadMoreFiles = (params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_FILES_LOAD_MORE,
  });
  return storageApi
    .getFiles(params)
    .then((files) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILES_LOAD_MORE_SUCCESS,
        files: files,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILES_LOAD_MORE_ERROR,
      });
      throw error;
    });
};

const createBucket = (params, options) => {
  const currentBranchId = DevBranchesStore.getCurrentId();

  if (DevBranchesStore.isDevModeActive() && !ApplicationStore.hasProtectedDefaultBranch()) {
    params = {
      ...params,
      displayName: prefixBucketNameWithBranchId(params.displayName, currentBranchId),
      name: prefixBucketNameWithBranchId(params.name, currentBranchId),
    };
  }
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_BUCKET_CREATE,
    params: params,
  });
  return storageApi
    .createBucket(params, options)
    .then((response) => {
      if (options?.async) {
        return waitForFinishedStorageJob(response).then(({ results }) => {
          return loadBucketDetailForce(results.id);
        });
      }

      return response;
    })
    .then((bucket) => {
      if (DevBranchesStore.isDevModeActive()) {
        return storageApi
          .saveBucketMetadata(
            bucket.id,
            fromJS({
              [MetadataKeys.CREATED_BY_BRANCH_ID]: currentBranchId,
            }),
            'system',
          )
          .then((metadata) => {
            return { ...bucket, metadata };
          });
      }
      return bucket;
    })
    .then((bucket) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKET_CREATE_SUCCESS,
        bucket,
      });

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => {
          return (
            <>
              Bucket <b>{bucket.displayName}</b> has been linked.
            </>
          );
        },
      });

      return bucket;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKET_CREATE_ERROR,
      });
      throw error?.message || error;
    });
};

const registerExternalBucket = (params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_BUCKET_CREATE,
    params: params,
  });
  return storageApi
    .registerBucket(params)
    .then(waitForFinishedStorageJob)
    .then(({ results }) => {
      return storageApi.getBucket(results.id).then((bucket) => {
        const hasWarnings = !!results?.warnings?.length;

        ApplicationActionCreators.sendNotification({
          type: hasWarnings ? 'warning' : 'info',
          message: externalBucketNotification('created', bucket.displayName, results?.warnings),
        });

        dispatcher.handleViewAction({
          type: constants.ActionTypes.STORAGE_BUCKET_CREATE_SUCCESS,
          bucket,
        });
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKET_CREATE_ERROR,
      });
      throw error?.message || error;
    });
};

const refreshExternalBucket = (bucketId) => {
  return storageApi
    .refreshExternalBucket(bucketId)
    .then(waitForFinishedStorageJob)
    .then(({ results }) => {
      return loadBucketDetailForce(bucketId).then((bucket) => {
        const hasWarnings = !!results?.warnings?.length;

        ApplicationActionCreators.sendNotification({
          type: hasWarnings ? 'warning' : 'info',
          message: externalBucketNotification('updated', bucket.displayName, results?.warnings),
        });
      });
    });
};

const deleteBucket = (bucketId, options) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_BUCKET_DELETE,
    bucketId: bucketId,
  });
  return storageApi
    .deleteBucket(bucketId, { force: !!options?.forceDelete, async: true })
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKET_DELETE_SUCCESS,
        bucketId: bucketId,
      });

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => {
          return (
            <>
              Bucket <b>{bucketId}</b> has been removed.
            </>
          );
        },
        skip: Boolean(options?.skipNotification),
      });

      if (options?.transition) {
        RoutesStore.getRouter().transitionTo(storageRouteNames.ROOT);
      }
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_BUCKET_DELETE_ERROR,
        bucketId: bucketId,
      });
      throw error;
    });
};

const updateBucket = (bucketId, params) => {
  return storageApi
    .updateBucket(bucketId, params)
    .then(() => loadBucketDetailForce(bucketId))
    .catch((error) => {
      throw error?.message || error;
    });
};

const shareBucket = (sharingType, bucketId, params) => {
  return storageApi
    .shareBucket(sharingType, bucketId, params)
    .then(waitForFinishedStorageJob)
    .then(() => loadBucketDetailForce(bucketId))
    .catch((error) => {
      throw error?.message || error;
    });
};

const unshareBucket = (bucket) => {
  const projectIds = bucket
    .get('linkedBy', List())
    .map((link) => link.getIn(['project', 'id']))
    .toArray();

  const forceUnlinkPromise =
    projectIds.length !== 0 ? forceUnlinkBucketFromProjects(bucket, projectIds) : Promise.resolve();

  return forceUnlinkPromise
    .then(() => storageApi.unshareBucket(bucket.get('id')))
    .then(waitForFinishedStorageJob)
    .then(() => {
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => (
          <>
            Sharing of <b>{bucket.get('displayName')}</b> has been disabled.
          </>
        ),
      });

      return loadBucketDetailForce(bucket.get('id'));
    })
    .catch((error) => {
      throw error?.message || error;
    });
};

const sharedBuckets = () => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_SHARED_BUCKETS_LOAD,
  });
  return storageApi
    .sharedBuckets()
    .then((data) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_SHARED_BUCKETS_LOAD_SUCCESS,
        sharedBuckets: data,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_SHARED_BUCKETS_LOAD_ERROR,
      });
      throw error;
    });
};

const createTable = (bucketId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_CREATE,
  });
  return storageApi
    .createTable(bucketId, params)
    .then(waitForFinishedStorageJob)
    .tap(({ results }) => loadTableDetailForce(results.id))
    .then(({ results }) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_SUCCESS,
      });
      return results.id;
    })
    .catch((error) => {
      // clean up possible created table
      if (params.dataFileId) {
        storageApi.deleteTable(`${bucketId}.${params.name}`, { force: true }).catch((error) => {
          if (error?.response?.status === HTTP_STATUS_CODE_NOT_FOUND) {
            return; // table was not created
          }

          throw error;
        });
      }

      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_ERROR,
      });
      throw error?.message || error;
    });
};

const createTableDefinition = (bucketId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_CREATE,
  });
  return storageApi
    .createTableDefinition(bucketId, params)
    .then(waitForFinishedStorageJob)
    .tap(({ results }) => loadTableDetailForce(results.id))
    .then(({ results }) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_SUCCESS,
      });
      return results.id;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_ERROR,
      });
      throw error?.message || error;
    });
};

const restoreUsingTimeTravel = (bucketId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL,
    tableId: params.sourceTableId,
  });
  return storageApi
    .createTable(bucketId, params)
    .then(waitForFinishedStorageJob)
    .tap(({ results }) => loadTableDetailForce(results.id))
    .then(({ results }) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL_SUCCESS,
        tableId: params.sourceTableId,
      });

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: tableHasBeenRestoredNotification(StorageTablesStore.getTable(results.id)),
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_RESTORE_TIME_TRAVEL_ERROR,
        tableId: params.sourceTableId,
      });
      throw error?.message || error;
    });
};

const createTableFromSnapshot = (bucketId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT,
    snapshotId: params.snapshotId,
  });
  return storageApi
    .createTable(bucketId, params)
    .then(waitForFinishedStorageJob)
    .tap(({ results }) => loadTableDetailForce(results.id))
    .then(({ results }) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT_SUCCESS,
        snapshotId: params.snapshotId,
      });

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: tableHasBeenRestoredNotification(StorageTablesStore.getTable(results.id)),
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_FROM_SNAPSHOT_ERROR,
        snapshotId: params.snapshotId,
      });
      throw error?.message || error;
    });
};

const truncateTable = (tableId) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TRUNCATE_TABLE,
    tableId: tableId,
  });
  return storageApi
    .truncateTable(tableId)
    .then(waitForFinishedStorageJob)
    .then(() => loadTableDetailForce(tableId))
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TRUNCATE_TABLE_SUCCESS,
        tableId: tableId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TRUNCATE_TABLE_ERROR,
        tableId: tableId,
      });
      throw error?.message || error;
    });
};

const truncateMultipleTables = (tablesIds) => {
  return Promise.each(tablesIds, (tableId) => truncateTable(tableId)).then(() =>
    ApplicationActionCreators.sendNotification({
      type: 'info',
      message: `Selected tables have been truncated.`,
    }),
  );
};

const pullTable = (tableId) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_PULL_TABLE,
    tableId: tableId,
  });
  return storageApi
    .pullTable(tableId)
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_PULL_TABLE_SUCCESS,
        tableId: tableId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_PULL_TABLE_ERROR,
        tableId: tableId,
      });
      throw error?.message || error;
    });
};

const deleteTable = (
  tableId,
  options = { forceDelete: false, skipNotification: false, onTableDeleteHook: _.noop },
) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_DELETE_TABLE,
    tableId: tableId,
  });
  return storageApi
    .deleteTable(tableId, { force: options.forceDelete })
    .tap(() => options.onTableDeleteHook?.())
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_DELETE_TABLE_SUCCESS,
        tableId: tableId,
      });

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => {
          return (
            <>
              Table <b>{tableId}</b> has been removed.
            </>
          );
        },
        skip: Boolean(options.skipNotification),
      });

      return null;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_DELETE_TABLE_ERROR,
        tableId: tableId,
      });
      throw error;
    });
};

const deleteMultipleTables = (tablesIds, options) => {
  return Promise.each(tablesIds, (tableId) =>
    deleteTable(tableId, { ...options, skipNotification: true }),
  ).then(() =>
    ApplicationActionCreators.sendNotification({
      type: 'info',
      message: `Selected tables have been removed.`,
    }),
  );
};

const deleteMultipleColumns = (tableId, columns, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMNS,
    tableId,
    columns,
  });
  return Promise.each(columns, (columnName) => deleteTableColumn(tableId, columnName, params)).then(
    () =>
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: `Selected columns have been removed.`,
      }),
  );
};

const createAliasTable = (bucketId, params, options) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE,
  });
  return storageApi
    .createAliasTable(bucketId, params)
    .tap((response) => loadTableDetailForce(response.id))
    .then((table) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE_SUCCESS,
      });

      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: tableAliasCreatedNotification(table),
        skip: Boolean(options?.skipNotification),
      });

      return null;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_ALIAS_TABLE_CREATE_ERROR,
      });
      throw error?.message || error;
    });
};

const setAliasTableFilter = (tableId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER,
    tableId: tableId,
  });
  return storageApi
    .setAliasTableFilter(tableId, params)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER_SUCCESS,
        tableId: tableId,
      });
      return loadTableDetailForce(tableId);
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_SET_ALIAS_TABLE_FILTER_ERROR,
        tableId: tableId,
      });
      throw error;
    });
};

const removeAliasTableFilter = (tableId) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER,
    tableId: tableId,
  });
  return storageApi
    .removeAliasTableFilter(tableId)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER_SUCCESS,
        tableId: tableId,
      });
      return loadTableDetailForce(tableId);
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_REMOVE_ALIAS_TABLE_FILTER_ERROR,
        tableId: tableId,
      });
      throw error;
    });
};

const loadTable = (tableId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_LOAD,
  });
  return storageApi
    .loadTable(tableId, params)
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_LOAD_SUCCESS,
      });
      return loadTableDetailForce(tableId);
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_LOAD_ERROR,
      });
      throw error?.message || error;
    });
};

const updateTable = (tableId, params) => {
  return storageApi
    .updateTable(tableId, params)
    .then(() => loadTableDetailForce(tableId))
    .catch((error) => {
      throw error?.message || error;
    });
};

const createTablePrimaryKey = (tableId, params) => {
  return storageApi
    .createTablePrimaryKey(tableId, params)
    .then(waitForFinishedStorageJob)
    .then(() => loadTableDetailForce(tableId))
    .catch((error) => {
      throw error?.message || error;
    });
};

const removeTablePrimaryKey = (tableId) => {
  return storageApi
    .removeTablePrimaryKey(tableId)
    .then(waitForFinishedStorageJob)
    .then(() => loadTableDetailForce(tableId))
    .catch((error) => {
      throw error?.message || error;
    });
};

const createSnapshots = async (tableIds, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS,
    tableIds,
  });

  return Promise.map(
    tableIds,
    (tableId) => {
      return storageApi
        .createSnapshot(tableId, params)
        .then((response) => {
          return waitForFinishedStorageJob(response);
        })
        .then(() => {
          return loadSnapshots(tableId);
        })
        .reflect();
    },
    { concurrency: 5 },
  )
    .then((responses) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS_SUCCESS,
        tableIds,
      });

      const fulfilledResponsesCount = responses.filter((r) => r.isFulfilled()).length;
      const rejectedResponsesCount = responses.length - fulfilledResponsesCount;

      if (fulfilledResponsesCount !== 0) {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message:
            fulfilledResponsesCount > 1
              ? `${fulfilledResponsesCount} snapshots have been created.`
              : 'Snapshot has been created.',
        });
      }
      if (rejectedResponsesCount !== 0) {
        ApplicationActionCreators.sendNotification({
          type: 'error',
          message: `${rejectedResponsesCount} snapshot${
            rejectedResponsesCount > 1 ? 's' : ''
          } failed.`,
        });
      }
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_CREATE_SNAPSHOTS_ERROR,
        tableIds,
      });
      throw error?.message || error;
    });
};

const deleteSnapshot = (tableId, snapshotId) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT,
    snapshotId,
  });
  return storageApi
    .deleteSnapshot(snapshotId)
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT_SUCCESS,
        tableId,
        snapshotId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_TABLE_REMOVE_SNAPSHOT_ERROR,
        snapshotId,
      });
      throw error?.message || error;
    });
};

const addTableColumn = (tableId, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN,
    tableId: tableId,
  });
  return storageApi
    .addTableColumn(tableId, params)
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN_SUCCESS,
        tableId: tableId,
      });
      return loadTableDetailForce(tableId);
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_ADD_TABLE_COLUMN_ERROR,
        tableId: tableId,
      });
      throw error?.message || error;
    });
};

const deleteTableColumn = (tableId, columnName, params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN,
    tableId: tableId,
    columnName: columnName,
  });
  return storageApi
    .deleteTableColumn(tableId, columnName, params)
    .then(waitForFinishedStorageJob)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN_SUCCESS,
        tableId: tableId,
        columnName: columnName,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_DELETE_TABLE_COLUMN_ERROR,
        tableId: tableId,
        columnName: columnName,
      });
      throw error?.message || error;
    });
};

const uploadFile = (id, file, params = {}) => {
  const uploadParams = {
    notify: params.notify ? '1' : '0',
    isPermanent: params.isPermanent ? '1' : '0',
    'tags[]': params.tags || [],
  };

  const reportProgress = _.throttle((percent) => {
    if (percent < 100) {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_UPLOAD,
        id: id,
        progress: percent,
      });
    }
  }, 800);

  reportProgress(1);
  return importApi
    .fileUpload(file, uploadParams, (e) => reportProgress(e.percent))
    .then((response) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_UPLOAD_SUCCESS,
        id: id,
      });
      return response.id;
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_UPLOAD_ERROR,
        id: id,
      });
      throw error?.message || error;
    });
};

const deleteFile = (fileId) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_FILE_DELETE,
    fileId: fileId,
  });
  return storageApi
    .deleteFile(fileId)
    .then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_DELETE_SUCCESS,
        fileId: fileId,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_DELETE_ERROR,
        fileId: fileId,
      });
      throw error;
    });
};

const addFileTag = (fileId, tag) => {
  return storageApi.addFileTag(fileId, tag).then(() => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_FILE_ADD_TAG_SUCCESS,
      fileId: fileId,
      tag: tag,
    });
  });
};

const deleteFileTag = (fileId, tag) => {
  return storageApi.deleteFileTag(fileId, tag).then(() => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_FILE_DELETE_TAG_SUCCESS,
      fileId: fileId,
      tag: tag,
    });
  });
};

const loadTableSnapshots = (tableId, params) => {
  return storageApi.loadTableSnapshots(tableId, params).then((data) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_TABLE_SNAPSHOTS_LOAD_SUCCESS,
      tableId,
      snapshots: data,
    });
  });
};

const loadJob = (jobId) => {
  return storageApi.getJob(jobId).then((job) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_JOB_LOAD_SUCCESS,
      job,
    });
  });
};

const loadJobs = (params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_JOBS_LOAD,
  });
  return storageApi
    .getJobs({ ...params, limit: params.limit + 1 })
    .then((jobs) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_JOBS_LOAD_SUCCESS,
        jobs: jobs,
        jobsLimit: params.limit,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_JOBS_LOAD_ERROR,
      });
      throw error;
    });
};

const loadMoreJobs = (params) => {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_JOBS_LOAD_MORE,
  });
  return storageApi
    .getJobs({ ...params, limit: params.limit + 1 })
    .then((jobs) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_JOBS_LOAD_MORE_SUCCESS,
        jobs: jobs,
        jobsLimit: params.limit,
      });
    })
    .catch((error) => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_JOBS_LOAD_MORE_ERROR,
      });
      throw error;
    });
};

const loadDevBranchesForce = () => {
  return storageApi.getDevBranches().then((devBranches) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_DEV_BRANCHES_LOAD_SUCCESS,
      devBranches,
    });
  });
};

const loadDevBranches = () => {
  if (DevBranchesStore.getIsLoaded()) {
    loadDevBranchesForce();
    return Promise.resolve();
  }
  return loadDevBranchesForce();
};

const createDevBranch = (name, description) => {
  return storageApi
    .createDevBranch({ name, description })
    .then(waitForFinishedStorageJob)
    .then(({ results, status, error }) => {
      if (status === 'error') {
        throw error;
      }

      return results.id;
    });
};

const updateDevBranch = (devBranchId, params) => {
  return storageApi.updateDevBranch(devBranchId, params).then((devBranch) => {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.STORAGE_DEV_BRANCH_UPDATE_SUCCESS,
      devBranchId,
      devBranch,
    });
  });
};

const deleteDevBranch = (id) => {
  return storageApi
    .deleteDevBranch(id)
    .then(waitForFinishedStorageJob)
    .then(() => {
      if (id === DevBranchesStore.getCurrentId()) {
        return window.location.replace(ApplicationStore.getProjectBaseUrl());
      }

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: `Development branch has been successfully deleted.`,
      });

      loadBucketsAndTablesForce();
      loadDevBranchesForce();
    });
};

const forceUnlinkBucketFromProjects = (bucket, projectIds) => {
  return Promise.each(projectIds, (projectId) => {
    return storageApi
      .forceUnlinkBucket(bucket.get('id'), projectId)
      .then(waitForFinishedStorageJob)
      .then(() => loadBucketDetailForce(bucket.get('id')))
      .catch((error) => {
        throw error?.message || error;
      });
  });
};

const downloadSlicedFileJob = (fileId) => {
  const hasNewQueue = ApplicationStore.hasNewQueue();
  return componentRunner[hasNewQueue ? 'run' : 'runProduction']({
    method: 'run',
    component: KEBOOLA_SLICED_FILES_DOWNLOADER,
    data: {
      configData: {
        storage: {
          input: {
            files: [hasNewQueue ? { file_ids: [fileId] } : { query: `id:${fileId}` }],
          },
        },
      },
    },
  });
};

const downloadSlicedFile = (file) => {
  const fileId = file.id;

  dispatcher.handleViewAction({
    type: constants.ActionTypes.STORAGE_FILE_PREPARE_DOWNLOAD,
    fileId,
  });

  return downloadSlicedFileJob(fileId)
    .then((job) =>
      waitForFinishedStorageJob(job).then((job) => {
        return storageApi.getFiles({ runId: job.runId }).then((files) => {
          downloadFile(files[0].url, files[0].name);
        });
      }),
    )
    .catch(() => {
      ApplicationActionCreators.sendNotification({
        type: 'error',
        message: `Preparing file ${file.name} to download have failed!`,
      });
    })
    .finally(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.STORAGE_FILE_PREPARE_DOWNLOAD_DONE,
        fileId,
      });
    });
};

const updateBucketOwnerAction = ({ bucketId, ownerId, ownerEmail }) => {
  return storageApi
    .updateBucketOwner({ bucketId, ownerId, ownerEmail })
    .then(() => loadBucketDetailForce(bucketId));
};

const getBucketOwnerAction = (bucketId) => storageApi.getBucketOwner(bucketId);

const waitForFinishedStorageJob = ({ url }) => jobPoller.poll(url);

export default {
  tokenVerify,
  tokenVerifyForce,
  loadBucketsAndTables,
  loadBucketsAndTablesForce,
  loadTableDetail,
  loadTableDetailForce,
  loadBucketDetail,
  loadBucketDetailForce,
  loadFiles,
  loadFilesForce,
  loadMoreFiles,
  createBucket,
  registerExternalBucket,
  refreshExternalBucket,
  deleteBucket,
  updateBucket,
  shareBucket,
  unshareBucket,
  sharedBuckets,
  createTable,
  createTableDefinition,
  restoreUsingTimeTravel,
  createTableFromSnapshot,
  truncateTable,
  truncateMultipleTables,
  pullTable,
  deleteTable,
  deleteMultipleTables,
  deleteMultipleColumns,
  createAliasTable,
  setAliasTableFilter,
  removeAliasTableFilter,
  loadTable,
  updateTable,
  createTablePrimaryKey,
  removeTablePrimaryKey,
  createSnapshots,
  deleteSnapshot,
  addTableColumn,
  deleteTableColumn,
  uploadFile,
  deleteFile,
  addFileTag,
  deleteFileTag,
  loadTableSnapshots,
  loadJob,
  loadJobs,
  loadMoreJobs,
  loadDevBranches,
  createDevBranch,
  updateDevBranch,
  deleteDevBranch,
  downloadSlicedFile,
  downloadSlicedFileJob,
  forceUnlinkBucketFromProjects,
  waitForFinishedStorageJob,
  updateBucketOwnerAction,
  getBucketOwnerAction,
};
