import { useState } from 'react';
import type { Map } from 'immutable';

import ApplicationStore from '@/stores/ApplicationStore';
import { DeploymentCard, type UIFormType } from './DeploymentCard';
import { PackagesCard } from './PackagesCard';

type Props = {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
};

export const Deployment = ({ componentId, configId, configData }: Props) => {
  const code = configData.getIn(['parameters', 'script', 0], '');
  const [type, setType] = useState<UIFormType>(!!code ? 'code' : 'git');
  const isReadOnly = ApplicationStore.isReadOnly() as boolean;

  return (
    <div className="tw-mb-6">
      <DeploymentCard
        onTypeChange={setType}
        type={type}
        componentId={componentId}
        configId={configId}
        configData={configData}
        readOnly={isReadOnly}
      />

      {type === 'code' && <PackagesCard componentId={componentId} configId={configId} />}
    </div>
  );
};
