import { APP_ROUTE } from '@/constants/routeNames';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import * as Api from './api';

const getQueriesToRun = () => {
  return Api.check().then((response) => response.queriesToRun);
};

const upgrade = () => {
  return Api.upgrade().then(() => {
    window.location.href = createUrlWithBasename(APP_ROUTE);
  });
};

export { getQueriesToRun, upgrade };
