import { memo } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { Icon } from '@keboola/design';

import { routeNames as jobsRouteNames } from '@/modules/jobs/Constants';
import { LATEST_JOBS_LIMIT, routeNames } from '@/modules/queue/constants';
import { filterLatestJobs } from '@/modules/queue/helpers';
import { RouterLink } from '@/react/common';
import Loader from '@/react/common/Loader';
import { getLegacyComponentQuery, getQuery } from '@/utils/jobsQueryBuilder';
import SidebarJobsRow from './SidebarJobsRow';

type Props = {
  jobs: Map<string, any>;
  admins: Map<string, any>;
  allConfigurations: Map<string, any>;
  currentAdmin: Map<string, any>;
  notifications: List<any>;
  componentId: string;
  configId: string;
  hasNewQueue: boolean;
  rowId?: string;
};

const SidebarJobs = memo((props: Props) => {
  const getFilteredJobs = () => {
    const jobs = props.jobs.get('jobs', Map());

    return props.hasNewQueue ? filterLatestJobs(jobs, props.rowId as any) : jobs;
  };

  const renderJobs = () => {
    const jobs = getFilteredJobs();

    if (jobs.isEmpty()) {
      return <p className="text-muted">No uses found</p>;
    }

    return jobs
      .slice(0, LATEST_JOBS_LIMIT)
      .map((job: Map<string, any>) => (
        <SidebarJobsRow
          key={job.get('id')}
          job={job}
          admin={props.admins.get(job.getIn(['token', 'description']))}
          hasNewQueue={props.hasNewQueue}
          allConfigurations={props.allConfigurations}
          currentAdmin={props.currentAdmin}
          notifications={props.notifications}
        />
      ))
      .toArray();
  };

  const renderAllJobsLink = () => {
    const firstJob = getFilteredJobs().first() || Map();

    if (firstJob.isEmpty() || (!firstJob.get('params') && !firstJob.get('component'))) {
      return null;
    }

    if (props.hasNewQueue) {
      return (
        <RouterLink
          className="job-link jobs-page dark"
          to={routeNames.JOBS}
          query={{
            component: props.componentId,
            config: props.configId,
            row: props.rowId,
          }}
        >
          <Icon icon="angle-right" className="icon-addon-right" />
          Show All Uses
        </RouterLink>
      );
    }

    const queryFunction = firstJob.getIn(['params', 'component'])
      ? getQuery
      : getLegacyComponentQuery;

    return (
      <RouterLink
        className="job-link jobs-page dark"
        to={jobsRouteNames.ROOT}
        query={{
          q: queryFunction(props.componentId, props.configId, props.rowId),
        }}
      >
        <Icon icon="angle-right" className="icon-addon-right" />
        Show All Uses
      </RouterLink>
    );
  };

  return (
    <div className="sidebar-box">
      <h4 className="mb-1">Last Use {props.jobs.get('isLoading') && <Loader />}</h4>
      {renderJobs()}
      {renderAllJobsLink()}
    </div>
  );
}, shallowEqualImmutable);

export default SidebarJobs;
