import { useState } from 'react';
import type { ReactNode } from 'react';
import { Map } from 'immutable';

import { IconButton, Search, Tooltip } from '@keboola/design';

import { FILTERS, FILTERS_GROUP } from '@/constants';
import { canManageBuckets, canWriteBucket } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import BetaTitle from '@/modules/lineage/BetaTitle';
import { constructFQID, FQID_TYPES } from '@/modules/lineage/fqid';
import TablesLineage from '@/modules/lineage/TablesLineage/TablesLineage';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { factory as eventsFactory } from '@/modules/sapi-events/TableEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { TabLink, TabNav } from '@/react/common';
import useForceUpdate from '@/react/hooks/useForceUpdate';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ColumnsPreview from './components/ColumnsPreview';
import DataSample from './components/DataSample';
import DataSampleColumnOrderInfo from './components/DataSampleColumnOrderInfo';
import DataSampleHelp from './components/DataSampleHelp';
import TableEvents from './components/Events';
import SearchContextDropdown from './components/SearchContextDropdown';
import Snapshots from './components/Snapshots';
import TableActions from './components/TableActions';
import TableGraph from './components/TableGraph';
import TableOverview from './components/TableOverview';
import TableSchema from './components/TableSchema';
import TableUsage from './components/TableUsage';
import { toggleContextFilter } from './actions';
import { bucketTabs, routeNames, tableTabs } from './constants';
import { getTableAliases, getTableLinks } from './helpers';
import StorageStore from './store';

const Table = () => {
  const store = useStores(
    () => {
      const sapiToken = ApplicationStore.getSapiToken();
      const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
      const tableName = RoutesStore.getCurrentRouteParam('tableName');
      const table = TablesStore.getTableByName(tableName, bucketId);
      const bucket = table.get('bucket', Map());
      const allBuckets = BucketsStore.getAll();
      const searchFilters = (StorageStore.getSearchFilters() ?? Map()) as Map<string, any>;
      const contextFilter = StorageStore.getContextFilter() as boolean;
      const isDevModeActive = DevBranchesStore.isDevModeActive();
      const availableBuckets = isDevModeActive
        ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
        : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
          ? filterDevBranchBuckets(allBuckets)
          : filterProductionBuckets(allBuckets);
      const hasNewTransformationsOnly = ApplicationStore.hasNewTransformationsOnly();
      const hasShowTransformationMigration = ApplicationStore.hasShowTransformationMigration();

      return {
        sapiToken,
        bucket,
        table,
        bucketId,
        allBuckets,
        contextFilter,
        availableBuckets,
        isDevModeActive,
        activeTab: RoutesStore.getCurrentRouteParam('tableTab') || tableTabs.OVERVIEW,
        indexSearchFilters: searchFilters,
        allTables: TablesStore.getAll() as Map<string, any>,
        indexSearchQuery: StorageStore.getSearchQuery() as string,
        canWriteBucket: canWriteBucket(sapiToken, bucket),
        canManageBuckets: canManageBuckets(sapiToken),
        components: ComponentsStore.getAll() as Map<string, any>,
        configurations: InstalledComponentsStore.getAll() as Map<string, any>,
        urlTemplates: ApplicationStore.getUrlTemplates(),
        admins: ApplicationStore.getAdmins(),
        snapshots: TablesStore.getTableSnapshots(table.get('id')),
        settingAliasFilter: TablesStore.getIsSettingAliasFilter(table.get('id')),
        removingAliasFilter: TablesStore.getIsRemovingAliasFilter(table.get('id')),
        exportingTable: TablesStore.getIsExportingTable(table.get('id')),
        creatingFromSnapshot: TablesStore.getIsCreatingFromSnapshot(),
        deletingSnapshot: TablesStore.getIsDeletingSnapshot(),
        addingColumn: TablesStore.getAddingColumn(),
        deletingColumn: TablesStore.getDeletingColumn().get(table.get('id'), Map()),
        hasGraph: !hasNewTransformationsOnly,
        hasUsage: hasNewTransformationsOnly || hasShowTransformationMigration,
        hasShowTransformationMigration,
        hasFlows: ApplicationStore.hasFlows(),
        currentProjectId: ApplicationStore.getCurrentProjectId(),
        hasAiLineage: ApplicationStore.hasAiLineage(),
        currentProject: ApplicationStore.getCurrentProject(),
        hasAllowedAi: StackFeaturesStore.hasAllowedAi(),
      };
    },
    [],
    [
      ApplicationStore,
      RoutesStore,
      StorageStore,
      ComponentsStore,
      StackFeaturesStore,
      InstalledComponentsStore,
      BucketsStore,
      TablesStore,
      SandboxesStore,
      DevBranchesStore,
    ],
  );
  const [showFullScreenDataSample, setShowFullScreenDataSample] = useState(false);
  const [isLineageModalOpened, setIsLineageModalOpened] = useState(false);
  const [searchQuery, setSearchQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const forceUpdate = useForceUpdate();

  if (store.bucket.isEmpty()) {
    return <p>Bucket not found</p>;
  }

  if (store.table.isEmpty()) {
    return <p>Table not found</p>;
  }

  const tableAliases = getTableAliases(store.table, store.allTables, store.sapiToken);
  const tableLinks = getTableLinks(store.table, store.allBuckets.get(store.bucketId, Map()));

  const renderTabLink = (
    tab: (typeof tableTabs)[keyof typeof tableTabs],
    label: ReactNode,
    className?: string,
  ) => {
    return (
      <TabLink
        active={store.activeTab === tab}
        to={routeNames.TABLE}
        params={{
          bucketId: store.bucketId,
          bucketTab: bucketTabs.OVERVIEW,
          tableName: store.table.get('name'),
          tableTab: tab,
        }}
        className={className}
      >
        {label}
      </TabLink>
    );
  };

  const renderTabContent = (tableAliases: Map<string, any>[], tableLinks: Map<string, any>[]) => {
    if (store.activeTab === tableTabs.SCHEMA) {
      return (
        <div className="tw-flex tw-flex-col tw-gap-6">
          <Search
            placeholder={placeholder}
            defaultValue={searchQuery}
            onChange={(searchQuery) => {
              setSearchQuery(searchQuery);
              RoutesStore.getRouter().updateQuery({ q: searchQuery });
            }}
          />
          <TableSchema
            sapiToken={store.sapiToken}
            tables={store.allTables}
            table={store.table}
            canWriteBucket={store.canWriteBucket}
            canManageBuckets={store.canManageBuckets}
            addingColumn={store.addingColumn}
            deletingColumn={store.deletingColumn}
            tableAliases={tableAliases}
            tableLinks={tableLinks}
            urlTemplates={store.urlTemplates}
            components={store.components}
            searchQuery={searchQuery}
          />
        </div>
      );
    }

    if (store.activeTab === tableTabs.EVENTS) {
      return (
        <TableEvents eventsFactory={eventsFactory(store.table.get('id'))} admins={store.admins} />
      );
    }

    if (store.activeTab === tableTabs.DATA_SAMPLE) {
      return (
        <DataSample
          backend={store.bucket.get('backend')}
          table={store.table}
          bucket={store.bucket}
          fullScreen={showFullScreenDataSample}
          closeFullScreen={() => setShowFullScreenDataSample(false)}
          onChangeColumnOrder={() => forceUpdate()}
        />
      );
    }

    if (store.activeTab === tableTabs.SNAPSHOT_AND_RESTORE) {
      return (
        <Snapshots
          snapshots={store.snapshots}
          sapiToken={store.sapiToken}
          admins={store.admins}
          buckets={store.allBuckets}
          tables={store.allTables}
          table={store.table}
          creatingFromSnapshot={store.creatingFromSnapshot}
          deletingSnapshot={store.deletingSnapshot}
        />
      );
    }

    if (store.hasGraph && store.activeTab === tableTabs.GRAPH) {
      return (
        <TableGraph
          key={store.table.get('lastImportDate') || tableTabs.GRAPH}
          table={store.table}
        />
      );
    }

    if (store.hasUsage && store.activeTab === tableTabs.USAGE) {
      return (
        <TableUsage
          admins={store.admins}
          tableId={store.table.get('id')}
          configurations={store.configurations}
          hasShowTransformationMigration={store.hasShowTransformationMigration}
          hasFlows={store.hasFlows}
        />
      );
    }

    if (store.activeTab === tableTabs.LINEAGE) {
      const startingPointFqid = constructFQID({
        type: FQID_TYPES.table,
        data: { projectId: store.currentProjectId, tableId: store.table.get('id') },
      });

      return (
        <TablesLineage
          isLocked={!store.hasAiLineage}
          allComponents={store.components}
          startingPointFqid={startingPointFqid}
          nodeName={store.table.get('name', '')}
          projectName={store.currentProject.get('name', '')}
          projectId={`${store.currentProjectId}`}
          onOpenModal={() => setIsLineageModalOpened(true)}
          onCloseModal={isLineageModalOpened ? () => setIsLineageModalOpened(false) : null}
        />
      );
    }

    return (
      <>
        <TableOverview
          table={store.table}
          tables={store.allTables}
          components={store.components}
          configurations={store.configurations}
          tableAliases={tableAliases}
          tableLinks={tableLinks}
          sapiToken={store.sapiToken}
          urlTemplates={store.urlTemplates}
          settingAliasFilter={store.settingAliasFilter}
          removingAliasFilter={store.removingAliasFilter}
          canWriteBucket={store.canWriteBucket}
          hasFlows={store.hasFlows}
        />
        <ColumnsPreview key={store.table.get('lastChangeDate')} tableId={store.table.get('id')} />
      </>
    );
  };

  const placeholder = `Search columns (${store.table.get('columns').count()})`;

  return (
    <>
      <div className="tabs-with-border-wrapper flex-container flex-start align-top">
        <SearchContextDropdown
          activeBucket={store.bucket.get('id')}
          activeTable={store.table.get('id')}
          searchQuery={store.indexSearchQuery}
          searchFilters={store.indexSearchFilters}
          contextFilter={store.contextFilter}
          toggleContextFilter={toggleContextFilter}
          tables={store.allTables}
          buckets={store.availableBuckets}
          activeTab={store.activeTab}
        />
        <TabNav>
          {renderTabLink(tableTabs.OVERVIEW, 'Overview', 'ml-1')}
          {renderTabLink(tableTabs.SCHEMA, 'Schema')}
          {renderTabLink(tableTabs.EVENTS, 'Events')}
          {renderTabLink(tableTabs.DATA_SAMPLE, 'Data Sample')}
          {!store.table.get('isAlias') &&
            renderTabLink(tableTabs.SNAPSHOT_AND_RESTORE, 'Snapshots')}
          {store.hasGraph && renderTabLink(tableTabs.GRAPH, 'Graph')}
          {store.hasUsage && renderTabLink(tableTabs.USAGE, 'Usage')}
          {store.hasAiLineage &&
            store.hasAllowedAi &&
            store.hasFlows &&
            !store.isDevModeActive &&
            renderTabLink(tableTabs.LINEAGE, <BetaTitle>Data Lineage</BetaTitle>)}
        </TabNav>
        <div className="ml-auto flex-container flex-end">
          {!store.exportingTable && store.activeTab === tableTabs.DATA_SAMPLE && (
            <>
              <DataSampleColumnOrderInfo
                tableId={store.table.get('id')}
                onResetColumnsOrder={() => forceUpdate()}
              />
              <DataSampleHelp backend={store.bucket.get('backend')} />
              <Tooltip placement="left" tooltip="Expand data sample to full screen">
                <IconButton
                  variant="invisible"
                  icon="up-right-and-down-left-from-center"
                  onClick={() => setShowFullScreenDataSample(true)}
                />
              </Tooltip>
            </>
          )}
          <TableActions
            table={store.table}
            onDeleteTable={() => {
              RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
                bucketId: store.bucket.get('id'),
                bucketTab: bucketTabs.OVERVIEW,
              });
            }}
          />
        </div>
      </div>
      <div key={store.table.get('id')}>{renderTabContent(tableAliases, tableLinks)}</div>
    </>
  );
};

export default Table;
