import { memo, useMemo } from 'react';
import type { Extension } from '@uiw/react-codemirror';
import CodeMirror, {
  EditorState,
  EditorView,
  placeholder as cmPlaceholder,
} from '@uiw/react-codemirror';

import { BASIS_SETUP, customTheme } from './helpers';

export const SimpleEditor = memo(
  ({
    value,
    onChange,
    commonExtensions,
    placeholder,
    autoFocus,
    readOnly,
  }: {
    value: string;
    onChange: (value: string) => void;
    commonExtensions: Extension[];
    placeholder: string;
    autoFocus: boolean;
    readOnly: boolean;
  }) => {
    const extensions = useMemo(() => {
      return [
        ...commonExtensions,
        cmPlaceholder(placeholder),
        EditorView.editable.of(!readOnly),
        EditorState.readOnly.of(readOnly),
      ];
    }, [commonExtensions, placeholder, readOnly]);

    return (
      <CodeMirror
        theme={customTheme}
        value={value}
        onChange={onChange}
        extensions={extensions}
        autoFocus={autoFocus}
        basicSetup={BASIS_SETUP}
      />
    );
  },
);
