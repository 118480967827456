import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { ClipboardIcon } from '@keboola/design';

export const TokenString = ({
  token,
  sendTokenComponent,
}: {
  token: Map<string, any>;
  sendTokenComponent?: ReactNode;
}) => {
  const tokenString = token.get('token');

  return (
    <pre>
      <div>
        {tokenString}
        <ClipboardIcon text={tokenString} />
      </div>
      {sendTokenComponent}
    </pre>
  );
};
