import { useState } from 'react';
import type { ChangeEvent, KeyboardEvent } from 'react';
import { FormControl } from 'react-bootstrap';

import { Tooltip } from '@keboola/design';

import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  value?: string;
  id?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  tooltip?: string;
  className?: string;
};

const PasswordControl = ({
  onChange,
  onFocus,
  value = '',
  id,
  disabled = false,
  autoFocus,
  placeholder,
  tooltip = 'Caps Lock is ON',
  className,
}: Props) => {
  const [capsLock, setCapsLock] = useState(false);

  const handleBlur = () => {
    if (capsLock) {
      setCapsLock(false);
    }
  };

  const handleKeyPress = (event: KeyboardEvent) => {
    if (event.getModifierState('CapsLock') !== capsLock) {
      setCapsLock(event.getModifierState('CapsLock'));
    }
  };

  return (
    <Tooltip
      placement="top"
      tooltip={tooltip}
      forceShow={capsLock}
      forceHide={!capsLock}
      triggerClassName={className}
    >
      <FormControl
        id={id}
        autoFocus={autoFocus}
        type={ApplicationStore.hasProtectedDefaultBranch() ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        onKeyPress={handleKeyPress}
        onBlur={handleBlur}
        onFocus={onFocus}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete="new-password"
      />
    </Tooltip>
  );
};

export default PasswordControl;
