import { json } from '@codemirror/lang-json';
import { python } from '@codemirror/lang-python';
import { PLSQL, sql } from '@codemirror/lang-sql';
import { StreamLanguage } from '@codemirror/language';
import { julia } from '@codemirror/legacy-modes/mode/julia';
import { r } from '@codemirror/legacy-modes/mode/r';
import { githubLightInit } from '@uiw/codemirror-theme-github';

import colors from '@keboola/tailwind-config/colors';

export type LANGUAGE_MODE =
  | 'application/json'
  | 'text/x-python'
  | 'text/x-sql'
  | 'text/x-sfsql'
  | 'text/x-plsql'
  | 'text/x-rsrc'
  | 'text/x-julia';

export const getBaseThemeStyles = (showDiff?: boolean) => ({
  '*': { lineHeight: '20px' },
  '*::selection': {
    backgroundColor: colors.secondary[500],
    color: `${colors.white} !important`,
  },
  '&.cm-editor:has(.cm-content[contenteditable="false"])': { backgroundColor: colors.neutral[150] },
  '&.cm-editor:has(.cm-content[contenteditable="false"]) .cm-gutters': {
    backgroundColor: colors.neutral[200],
  },
  '&.cm-editor .cm-scroller': { overflowAnchor: 'none' },
  '&.cm-editor, &.cm-editor .cm-scroller': {
    minHeight: '100%',
    flexGrow: 1,
    alignItems: 'stretch !important',
    outline: 'none',
  },
  '.cm-gutter.cm-foldGutter': { display: 'none !important' },
  '.cm-gutter.cm-lineNumbers': { paddingLeft: '20px', paddingRight: '6px' },
  '.cm-gutters': { height: 'auto' },
  ...(showDiff && {
    '&.cm-merge-a .cm-changedLine': { backgroundColor: colors.error[100] },
    '&.cm-merge-b .cm-changedLine': { backgroundColor: colors.primary[100] },
    '&.cm-merge-a .cm-changedLineGutter': { backgroundColor: colors.error[200] },
    '&.cm-merge-b .cm-changedLineGutter': { backgroundColor: colors.primary[200] },
    '.cm-changeGutter': { position: 'absolute', zIndex: '-1', width: '100%', paddingLeft: 0 },
  }),
});

export const BASIS_SETUP = { drawSelection: false };

export const customTheme = githubLightInit({
  settings: {
    fontFamily: `'SFMono-Regular', 'Menlo', 'Monaco', 'Consolas', 'Liberation Mono', 'Courier New'`,
    background: colors.neutral[50],
    gutterBackground: colors.neutral[100],
    gutterBorder: 'transparent',
    lineHighlight: 'transparent',
    selection: colors.secondary[500],
  },
});

export const getLanguageMode = (mode?: LANGUAGE_MODE) => {
  switch (mode) {
    case 'text/x-python':
      return python();
    case 'text/x-sql':
    case 'text/x-sfsql':
      return sql({ upperCaseKeywords: true });
    case 'text/x-plsql':
      return sql({ upperCaseKeywords: true, dialect: PLSQL });
    case 'text/x-rsrc':
      return StreamLanguage.define(r);
    case 'text/x-julia':
      return StreamLanguage.define(julia);
    default:
      return json();
  }
};
