import { useState } from 'react';
import type { ReactNode } from 'react';
import { useDropzone } from 'react-dropzone';

import { ButtonInline, cn, FormGroup, HelpBlock, Icon, Label } from '@keboola/design';

import string from '@/utils/string';
import { byteConverter } from './helpers';
import Select from './Select';

type FileRejection = {
  errors: { code: string; message: string }[];
  file: File;
};

const MAX_FILE_SIZE_DEFAULT = 2000 * 1000 * 1000; // 2GB,

const ALLOWED_TYPES_FOR_TABLE = {
  'text/csv': ['.csv'],
  'application/gzip': ['.gz'],
  'text/tab-separated-values': ['.tsv'],
};

const FilesDropZone = (props: {
  onDrop: (files: File[], options?: { force: boolean }) => void;
  className?: string;
  files?: File | File[] | null;
  multiple?: boolean;
  errorSelect?: boolean;
  allowAllFiles?: boolean;
  allowedType?: { [type: string]: string[] };
  isDisabled?: boolean;
  label?: ReactNode;
  helpText?: ReactNode;
  maxFileSize?: number;
}) => {
  const [dragEnter, setDragEnter] = useState(false);
  const maxSize = props.maxFileSize ?? MAX_FILE_SIZE_DEFAULT;
  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    multiple: !!props.multiple,
    disabled: !!props.isDisabled,
    onDropAccepted: props.onDrop,
    onDrop: () => setDragEnter(false),
    onDragEnter: () => setDragEnter(true),
    onDragLeave: () => setDragEnter(false),
    maxSize,
    ...(!props.allowAllFiles && { accept: props.allowedType || ALLOWED_TYPES_FOR_TABLE }),
  });
  const files = props.files instanceof File ? [props.files] : props.files || [];
  const showOnlySelectedFile = !props.multiple && files.length > 0;

  const tooLargeFiles: FileRejection[] = fileRejections.filter(({ errors }: FileRejection) => {
    return errors.some(({ code }) => code === 'file-too-large');
  });
  const invalidTypeFiles: FileRejection[] = fileRejections.filter(({ errors }: FileRejection) => {
    return errors.some(({ code }) => code === 'file-invalid-type');
  });

  return (
    <>
      {props.label && !showOnlySelectedFile && <Label htmlFor="file">{props.label}</Label>}
      <div className={cn('files-dropzone', props.className)}>
        {!showOnlySelectedFile && (
          <div
            {...getRootProps({
              className: cn('dropzone-container flex-container flex-column justify-center', {
                disabled: !!props.isDisabled,
                'drag-enter': dragEnter,
                'mbp-4': files.length > 0,
              }),
            })}
          >
            <input id="file" {...getInputProps()} />
            <Icon icon={['fad', 'upload']} className="f-32 color-primary mbp-6" />
            <p className="f-14 font-medium line-height-20 mbp-2">
              Drag &amp; Drop here or{' '}
              <ButtonInline>Select {string.pluralize(props.multiple ? 2 : 1, 'File')}</ButtonInline>
            </p>
            <p className="f-12 line-height-16 text-muted m-0 text-center">
              {props.helpText || `Maximum file size ${byteConverter(maxSize)}`}
            </p>
          </div>
        )}
        {files.length > 0 && (
          <FormGroup className="m-0">
            <Label htmlFor="selected-files">
              Selected {string.pluralize(files.length, 'File')}
            </Label>
            <Select
              id="selected-files"
              multi
              noDropdown
              clearable={false}
              value={files.map((file) => file.name)}
              className={cn(props.errorSelect && 'error')}
              onChange={(newFiles) => {
                props.onDrop(
                  files.filter((file) => newFiles.includes(file.name)),
                  { force: true },
                );
              }}
            />
          </FormGroup>
        )}
        {tooLargeFiles.length > 0 && (
          <HelpBlock className="tw-mt-1" state="error">
            The {string.pluralize(tooLargeFiles.length, 'file')}{' '}
            {string.list(tooLargeFiles.map(({ file }) => file.name))} cannot be uploaded because the
            file size exceeds the limit.
          </HelpBlock>
        )}
        {invalidTypeFiles.length > 0 && (
          <HelpBlock className="tw-mt-1" state="error">
            The {string.pluralize(invalidTypeFiles.length, 'file')}{' '}
            {string.list(invalidTypeFiles.map(({ file }) => file.name))} cannot be uploaded because
            the file type is not allowed.
          </HelpBlock>
        )}
      </div>
    </>
  );
};

export default FilesDropZone;
