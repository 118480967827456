import { useState } from 'react';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import { ButtonInline, FormGroup, Icon, Label } from '@keboola/design';

import CollapsibleBox from '@/react/common/CollapsibleBox';
import Select from '@/react/common/Select';
import DocumentationLink from './DocumentationLink';
import NewPagination from './NewPagination';

const Pagination = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  editing: Map<string, any>;
  setEditing: (editing: Map<string, any>) => void;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
  rawEditingData: Map<string, any>;
}) => {
  const [createNewPagination, setCreateNewPagination] = useState(false);
  const [selectInput, setSelectInput] = useState('');

  const pagination = props.parameters.getIn(['api', 'pagination'], Map());
  const scrollers = pagination.get('scrollers', Map());

  const options = [
    {
      label: (
        <div className="tw-flex tw-justify-between !tw-font-medium">
          <span className="tw-text-xs tw-uppercase tw-tracking-widest">Select Pagination</span>
          {!props.readOnly && (
            <ButtonInline
              color="custom"
              className="tw-text-xs tw-uppercase tw-text-neutral-400"
              onClick={() => setCreateNewPagination(true)}
            >
              <Icon icon="plus" className="tw-text-primary-500" />
              Create New
            </ButtonInline>
          )}
        </div>
      ),
      options: scrollers
        .map((_: Map<string, any>, name: string) => ({ label: name, value: name }))
        .toArray(),
    },
  ];

  if (scrollers.isEmpty() && !pagination.isEmpty()) {
    return null;
  }

  return (
    <>
      <CollapsibleBox
        title="Pagination"
        defaultOpen={!scrollers.isEmpty()}
        titleSuffix={<DocumentationLink path="configuration/api/pagination/" />}
      >
        <FormGroup>
          <Label htmlFor="pagination">Select Pagination</Label>
          <Select
            id="pagination"
            disabled={props.readOnly}
            placeholder="Select or create new pagination"
            inputValue={selectInput}
            onInputChange={setSelectInput}
            value={props.editing.get('scroller')}
            onChange={(value: string) => {
              props.setEditing(props.editing.set('scroller', value));
            }}
            options={options}
            noOptionRenderer={() => {
              return (
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-3 tw-p-4">
                  {!scrollers.isEmpty() && (
                    <div className="text-muted">No &quot;{selectInput}&quot; pagination found</div>
                  )}
                  {!props.readOnly && (
                    <ButtonInline
                      color="custom"
                      className="tw-font-medium tw-text-neutral-400"
                      onClick={() => setCreateNewPagination(true)}
                    >
                      <Icon icon="plus" className="tw-text-primary-500" />
                      Create New Pagination
                    </ButtonInline>
                  )}
                </div>
              );
            }}
          />
        </FormGroup>
      </CollapsibleBox>
      <NewPagination
        show={createNewPagination}
        forceName={selectInput}
        parameters={props.parameters}
        onSave={props.onSave}
        onHide={() => setCreateNewPagination(false)}
        onCreated={(name: string) => props.setEditing(props.editing.set('scroller', name))}
      />
    </>
  );
};

export default Pagination;
