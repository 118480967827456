import { useState } from 'react';

import { ButtonInline, Tooltip } from '@keboola/design';

import ConfirmModal from './ConfirmModal';
import Loader from './Loader';

const ResetAutomaticLoadTypeButton = ({ onClick }: { onClick: () => Promise<void> }) => {
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const button = (
    <Tooltip
      tooltip="Clears information about automatic incremental load."
      placement="top"
      type="explanatory"
    >
      {isPending ? (
        <ButtonInline variant="link" disabled>
          Reset <Loader />
        </ButtonInline>
      ) : (
        <ButtonInline variant="link" onClick={() => setShowModal(true)}>
          Reset
        </ButtonInline>
      )}
    </Tooltip>
  );

  const clearState = () => {
    setIsPending(true);
    return onClick().catch((error) => {
      setIsPending(false);
      throw error;
    });
  };

  return (
    <>
      {button}
      <ConfirmModal
        closeAfterResolve
        show={showModal}
        onHide={() => setShowModal(false)}
        icon="arrow-rotate-left"
        title="Reset automatic incremental load"
        text={
          <p>
            This will clear the information about automatic incremental load. Next execution will
            load all data in the table.
          </p>
        }
        buttonLabel="Reset automatic incremental load"
        buttonType="success"
        onConfirm={clearState}
        isLoading={isPending}
      />
    </>
  );
};

export default ResetAutomaticLoadTypeButton;
