import type { ReactNode } from 'react';
import type { List, Map } from 'immutable';

import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import Loader from '@/react/common/Loader';
import TimeAndUser from '@/react/common/TimeAndUser';

type Props = {
  jobs: List<any>;
  admins: Map<string, any>;
  showJobDetailModal: (jobId: string) => void;
  renderTableLink: (job: Map<string, any>) => ReactNode;
  renderDataTransfer: (job: Map<string, any>) => ReactNode;
};
const JobsTable = (props: Props) => {
  const renderRows = () => {
    if (props.jobs.isEmpty()) {
      return (
        <tr>
          <td colSpan={7}>
            <Loader /> loading jobs..
          </td>
        </tr>
      );
    }

    return props.jobs.map(renderRow).toArray();
  };

  const renderRow = (job: Map<string, any>) => {
    const admin = props.admins.get(job.getIn(['creatorToken', 'description']));

    return (
      <tr
        key={job.get('id')}
        onClick={() => props.showJobDetailModal(job.get('id'))}
        className="clickable"
      >
        <td>{job.get('id')}</td>
        <td>{job.get('operationName')}</td>
        <td className="tw-break-all">{props.renderTableLink(job)}</td>
        <td className="text-right">
          <JobDuration
            status={job.get('status')}
            startTime={job.get('startTime')}
            endTime={job.get('endTime')}
          />
        </td>
        <td className="text-right">{props.renderDataTransfer(job)}</td>
        <td className="text-right">
          <TimeAndUser
            admin={admin}
            time={job.get('createdTime')}
            fallbackName={job.getIn(['creatorToken', 'description'])}
          />
        </td>
        <td>
          <JobStatusLabel status={job.get('status')} />
        </td>
      </tr>
    );
  };

  return (
    <div className="table-responsive">
      <table className="table table-hover">
        <thead>
          <tr>
            <th>ID</th>
            <th>Operation</th>
            <th>Table ID</th>
            <th className="text-right">Duration</th>
            <th className="text-right">Data Transfer</th>
            <th className="text-right">Created</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>{renderRows()}</tbody>
      </table>
    </div>
  );
};

export default JobsTable;
