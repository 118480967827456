import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  disabled?: boolean;
  disabledReason?: string;
};

export const ExportSelected = ({ onClick, disabled = false, disabledReason }: Props) => (
  <TableIconAction
    tooltip={disabledReason || 'Export selected'}
    onClick={onClick}
    disabled={disabled}
    libraryIcon="down-to-line"
  />
);
