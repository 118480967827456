import { ControlLabel, FormGroup } from 'react-bootstrap';
import Promise from 'bluebird';
import { Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import { getBackendSize } from '@/modules/flows/helpers';
import BackendSizeButtons from '@/react/common/BackendSizeButtons';

type Props = {
  task: Map<string, any>;
  readOnly: boolean;
  onEditTask: (id: string, properties: { keysPath: string[]; value: any }[]) => void;
  allConfigurations: Map<string, any>;
  showBackendSize: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
};

const BackendSize = ({
  task,
  readOnly,
  onEditTask,
  allConfigurations,
  showBackendSize,
  hasSnowflakeDynamicBackendSize,
  hasJobsDynamicBackendSize,
}: Props) => {
  const hasSelectedConfig = !!task.get('configId') && !task.get('hasDeletedConfiguration');
  const backendSize =
    showBackendSize &&
    getBackendSize(
      allConfigurations.get(task.get('componentId', ''), Map()),
      task,
      hasSnowflakeDynamicBackendSize,
      hasJobsDynamicBackendSize,
    );

  return (
    backendSize &&
    hasSelectedConfig && (
      <FormGroup className="flex-container">
        <ControlLabel className="text-muted">
          Backend Size
          <Tooltip
            placement="top"
            type="static-explanatory"
            className="wider"
            tooltip="When dealing with large amounts of complex data, try increasing the backend of your pipeline to accelerate your transformation process."
          >
            <Icon icon="circle-info" className="f-16 tw-ml-1" />
          </Tooltip>
        </ControlLabel>
        <BackendSizeButtons
          disabled={readOnly}
          currentSize={backendSize}
          componentId={task.get('componentId')}
          onSelect={(value) => {
            onEditTask(task.get('id'), [{ keysPath: ['task', 'backend', 'type'], value }]);
            return Promise.resolve();
          }}
        />
      </FormGroup>
    )
  );
};

export default BackendSize;
