import type { List, Map } from 'immutable';

import { bucketDisplayNameWithStage } from '@/modules/storage/helpers';
import Select from '@/react/common/Select';
import { bucketLabel } from '@/react/common/selectLabels';

type Props = {
  disabled: boolean;
  onChange: (bucketPermissions: Map<string, any>) => void;
  bucketPermissions: Map<string, any>;
  allBuckets: Map<string, any>;
  permission: string;
  wrapperClassName?: string;
};

export const BucketsSelector = (props: Props) => {
  const selectedPermissions = props.bucketPermissions
    .filter((perm) => perm === props.permission)
    .keySeq()
    .toArray();

  const options = props.allBuckets
    .filter((bucket, bucketId) =>
      props.bucketPermissions.has(bucketId!)
        ? props.bucketPermissions.get(bucketId!) === props.permission
        : true,
    )
    .sortBy((bucket) => bucketDisplayNameWithStage(bucket))
    .map((bucket) => ({
      value: bucket.get('id'),
      label: bucketLabel(bucket),
      name: bucket.get('displayName'),
    }))
    .toArray();

  const handleSelect = (bucketsArray: List<any>) => {
    const perm = props.permission;
    const currentBucketPermissions = props.bucketPermissions.filter((p) => p !== perm) as Map<
      string,
      any
    >;
    const newPermissions = bucketsArray.reduce<Map<string, any>>(
      (memo, bucketId: string) => memo!.set(bucketId, perm),
      currentBucketPermissions,
    );
    props.onChange(newPermissions);
  };

  return (
    <div className={props.wrapperClassName}>
      <Select
        placeholder={`Select buckets for ${props.permission} access`}
        multi
        disabled={props.disabled}
        value={selectedPermissions}
        onChange={handleSelect}
        options={options}
      />
      <span className="help-block">
        Token has{' '}
        {props.permission === 'write' && (
          <>
            <strong>read</strong> and{' '}
          </>
        )}
        <strong>{props.permission}</strong> access to the selected buckets.
      </span>
    </div>
  );
};
