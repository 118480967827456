import type { MouseEvent } from 'react';

import { Button, Tooltip } from '@keboola/design';

import string from '@/utils/string';
import type { RunActionLinkStatus } from './types';
import { RunActionLinkStatusObj } from './types';

type Props = {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  label: string;
  className?: string;
  tooltip?: string;
  isDisabled?: boolean;
  status?: RunActionLinkStatus;
};

export const RunActionLink = ({
  onClick,
  className,
  label = 'Run',
  tooltip = 'Run',
  status = RunActionLinkStatusObj.READY,
}: Props) => {
  const isDisabled =
    status === RunActionLinkStatusObj.RUNNING || status === RunActionLinkStatusObj.DISABLED;
  return (
    <Tooltip
      type="explanatory"
      placement="top"
      tooltip={tooltip}
      forceHide={!isDisabled || !tooltip}
    >
      <Button
        block
        disabled={isDisabled}
        className={className}
        onClick={onClick}
        isLoading={status === RunActionLinkStatusObj.RUNNING}
        icon="circle-play"
      >
        {string.capitalize(label.toLowerCase())}
      </Button>
    </Tooltip>
  );
};
