import PropTypes from 'prop-types';
import { ControlLabel, FormGroup } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { getProfileFullName } from '@/modules/ex-google-analytics-v4/helpers';
import Select from '@/react/common/Select';

const ProfileSelector = createReactClass({
  propTypes: {
    allProfiles: PropTypes.object.isRequired,
    onSelectProfile: PropTypes.func.isRequired,
    selectedProfile: PropTypes.string.isRequired,
    isEditing: PropTypes.bool.isRequired,
    isNewDataApiActive: PropTypes.bool.isRequired,
  },

  render() {
    if (this.props.isEditing) {
      return (
        <FormGroup>
          <ControlLabel className="col-md-2">Profile</ControlLabel>
          <div className="col-md-10">
            <Select
              clearable={false}
              value={this.props.selectedProfile}
              onChange={this.props.onSelectProfile}
              options={[{ value: '', label: '-- all profiles --' }, ...this.getOptions()]}
            />
          </div>
        </FormGroup>
      );
    }

    return (
      <FormGroup>
        <ControlLabel className="control-label col-md-2">Profile</ControlLabel>
        <div className="col-md-10">
          <div className="form-control-static">{this.renderStaticProfile()}</div>
        </div>
      </FormGroup>
    );
  },

  renderStaticProfile() {
    if (!this.props.selectedProfile) {
      return `-- all profiles --`;
    }

    const found = this.props.allProfiles.find(
      (profile) =>
        profile.get(this.props.isNewDataApiActive ? 'propertyKey' : 'id') ===
        this.props.selectedProfile,
    );

    if (found) {
      return getProfileFullName(found);
    }
    return `Unknown profile (${this.props.selectedProfile})`;
  },

  getOptions() {
    if (!this.props.isNewDataApiActive) {
      return this.props.allProfiles
        .groupBy((profile) => profile.get('accountName') + '/ ' + profile.get('webPropertyName'))
        .map((group, groupName) => {
          return {
            label: groupName,
            options: group
              .map((item) => {
                return { value: item.get('id'), label: item.get('name') };
              })
              .toArray(),
          };
        })
        .toArray();
    }

    return this.props.allProfiles
      .map((profile) => {
        return {
          value: profile.get('propertyKey'),
          label: `${profile.get('accountName')} / ${profile.get('propertyName')}`,
        };
      })
      .toArray();
  },
});

export default ProfileSelector;
