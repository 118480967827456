import { useEffect, useMemo, useState } from 'react';
import type React from 'react';
import { Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import {
  cn,
  EmptySearchContent,
  FormGroup,
  Icon,
  IconButton,
  SkeletonList,
  Tabs,
  TabsContent,
  TextInput,
} from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import ConfigurationRow from '@/modules/flows/components/ConfigurationRow';
import CreateConfiguration from '@/modules/flows/components/CreateConfiguration';
import { selectConfigWithRedirect } from '@/modules/flows/helpers';
import { Truncated } from '@/react/common';
import FullScreenModal, { FullScreenModalHeader } from '@/react/common/FullScreenModal';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils/matchByWords';
import AddTaskComponentRow from './AddTaskComponentRow';
import { aggregateConfigurations, getComponents, VALID_TYPES } from './helpers';
import SearchedList from './SearchedList';

type Props = {
  show: boolean;
  onHide: () => void;
  onSelect: (
    component: Map<string, any>,
    configuration?: Map<string, any>,
    options?: { autosave: boolean },
  ) => void;
  components: Map<string, any>;
  configurations: Map<string, any>;
  hasSnowflakePartnerConnectLimited: boolean;
  hasDataApps: boolean;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  folders: Map<string, any>;
  patternComponents: Map<string, any>;
  isDevModeActive: boolean;
  configId: string;
  newFlows?: boolean;
};

type TabKey = 'all' | 'transformations' | 'components' | 'flows' | 'data-apps';

const COMPONENT_TYPES_IN_FILTER = [
  componentTypes.EXTRACTOR,
  componentTypes.WRITER,
  componentTypes.APPLICATION,
] as const;

const tabContentClassName = 'tw-flex tw-grow tw-flex-col tw-gap-3 tw-overflow-y-auto';

const ContentWrapper = ({
  debouncedQuery,
  query,
  children,
  loadingClassName,
}: {
  debouncedQuery: string;
  query: string;
  children: React.ReactNode;
  loadingClassName?: string;
}) => {
  if (debouncedQuery !== query) {
    return (
      <SkeletonList
        className={loadingClassName}
        iconHeight="lg"
        iconClassName="tw-w-8"
        rowHeight="md"
      />
    );
  }
  return children;
};

const AddTaskModal = (props: Props) => {
  const [query, setQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');
  const [componentType, setComponentType] = useState('');
  const [selectedTab, setSelectedTab] = useState<TabKey>('all');

  const debouncedFilteredValues = useMemo(() => {
    const allComponents = getComponents(
      props.components,
      props.configurations,
      debouncedQuery,
      VALID_TYPES,
      props.hasSnowflakePartnerConnectLimited,
      Map({
        [KEBOOLA_ORCHESTRATOR]: props.components.get(KEBOOLA_ORCHESTRATOR),
        ...(props.hasDataApps && { [KEBOOLA_DATA_APPS]: props.components.get(KEBOOLA_DATA_APPS) }),
      }),
    );

    const components = getComponents(
      props.components,
      props.configurations,
      debouncedQuery,
      componentType ? [componentType] : COMPONENT_TYPES_IN_FILTER,
      props.hasSnowflakePartnerConnectLimited,
    );

    const transformations = getComponents(
      props.components,
      props.configurations,
      debouncedQuery,
      [componentTypes.TRANSFORMATION],
      props.hasSnowflakePartnerConnectLimited,
    );

    const allConfigurations = aggregateConfigurations(
      props.configurations,
      debouncedQuery,
      VALID_TYPES,
    );

    const configurations = aggregateConfigurations(
      props.configurations,
      debouncedQuery,
      componentType ? [componentType] : COMPONENT_TYPES_IN_FILTER,
    );

    const configurationsOfTransformations = aggregateConfigurations(
      props.configurations,
      debouncedQuery,
      [componentTypes.TRANSFORMATION],
    );

    const flows = props.configurations
      .getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map())
      .filter((configuration: Map<string, any>) =>
        matchByWords(configuration.get('name'), debouncedQuery),
      );

    const dataApps = props.hasDataApps
      ? props.configurations
          .getIn([KEBOOLA_DATA_APPS, 'configurations'], Map())
          .filter((configuration: Map<string, any>) =>
            matchByWords(configuration.get('name'), debouncedQuery),
          )
      : Map();

    return {
      allComponents,
      components,
      transformations,
      allConfigurations,
      configurations,
      configurationsOfTransformations,
      flows: Map({ data: flows, component: props.components.get(KEBOOLA_ORCHESTRATOR, Map()) }),
      dataApps: Map({ data: dataApps, component: props.components.get(KEBOOLA_DATA_APPS, Map()) }),
    };
  }, [
    props.components,
    props.configurations,
    props.hasSnowflakePartnerConnectLimited,
    props.hasDataApps,
    debouncedQuery,
    componentType,
  ]);

  const debouncedHandleSearch = useMemo(
    () =>
      _.debounce((nextQuery: string) => {
        setDebouncedQuery(nextQuery);
      }, 500),
    [],
  );

  useEffect(() => {
    const checkPressEscape = (e: KeyboardEvent) => {
      if (props.show && e.key === keyCodes.ESCAPE) {
        props.onHide();
      }
    };

    window.addEventListener('keyup', checkPressEscape);
    return () => {
      window.removeEventListener('keyup', checkPressEscape);
    };
  }, [props]);

  const renderAll = () => {
    if (debouncedQuery && debouncedFilteredValues !== null) {
      return (
        <SearchedList
          allConfigurations={props.configurations}
          components={debouncedFilteredValues?.allComponents.filter(
            (component: Map<string, any>) =>
              component.get('type') !== componentTypes.TRANSFORMATION,
          )}
          transformations={debouncedFilteredValues?.transformations}
          configurations={debouncedFilteredValues.allConfigurations}
          flows={debouncedFilteredValues?.flows}
          dataApps={debouncedFilteredValues?.dataApps}
          query={debouncedQuery}
          onClick={props.onSelect}
        />
      );
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-1">
        {renderComponentsRows(debouncedFilteredValues?.allComponents)}
      </div>
    );
  };

  const renderConfigurations = (configurations: Map<string, any> | undefined) => {
    if (!configurations || configurations.get('data', Map()).isEmpty()) {
      return <EmptySearchContent />;
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-1">
        {configurations
          .get('data')
          .map((configuration: Map<string, any>) => (
            <ConfigurationRow
              key={configuration.get('id')}
              component={configurations.get('component')}
              configuration={configuration}
              onSelect={props.onSelect}
              query={debouncedQuery}
              customFooterContent={
                configuration.get('description') ? (
                  <Truncated
                    className="tw-text-neutral-400"
                    text={configuration.get('description')}
                  />
                ) : null
              }
            />
          ))
          .toArray()}
      </div>
    );
  };

  const renderComponents = () => {
    if (debouncedQuery && debouncedFilteredValues !== null) {
      return (
        <SearchedList
          allConfigurations={props.configurations}
          components={debouncedFilteredValues?.components}
          configurations={debouncedFilteredValues.configurations}
          query={debouncedQuery}
          onClick={props.onSelect}
        />
      );
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-1">
        {renderComponentsRows(debouncedFilteredValues?.components)}
      </div>
    );
  };

  const renderTransformations = () => {
    if (debouncedQuery && debouncedFilteredValues !== null) {
      return (
        <SearchedList
          allConfigurations={props.configurations}
          transformations={debouncedFilteredValues?.transformations}
          configurations={debouncedFilteredValues.configurationsOfTransformations}
          query={debouncedQuery}
          onClick={props.onSelect}
        />
      );
    }

    return (
      <div className="tw-flex tw-flex-col tw-gap-1">
        {renderComponentsRows(debouncedFilteredValues?.transformations)}
      </div>
    );
  };

  const renderComponentsRows = (components: Map<string, any>) => {
    return components
      .map((component: Map<string, any>) => {
        const configurations = props.configurations
          .getIn([component.get('id'), 'configurations'], Map())
          .count();

        return (
          <AddTaskComponentRow
            key={component.get('id')}
            component={component}
            configurations={configurations}
            onSelect={props.onSelect}
            query={debouncedQuery}
          />
        );
      })
      .toArray();
  };

  const createConfigProps = {
    readOnly: props.readOnly,
    hasPayAsYouGo: props.hasPayAsYouGo,
    allConfigurations: props.configurations,
    allComponents: props.components,
    folders: props.folders,
    patternComponents: props.patternComponents,
    isDevModeActive: props.isDevModeActive,
    onCreate: (componentId: string, configId: string) => {
      return Promise.resolve().then(() => {
        selectConfigWithRedirect(
          null,
          componentId,
          configId,
          componentId,
          (_, componentId, configId, options) => {
            return Promise.resolve().then(() => {
              return props.onSelect(fromJS({ id: componentId }), fromJS({ id: configId }), options);
            });
          },
          props.configId,
        );
        props.onHide();
      });
    },
  };

  const renderAddNewTaskDialog = () => {
    return (
      <FullScreenModal
        className={cn(
          'tw-justify-self-end [&_.modal-content]:tw-h-full [&_.modal-content]:tw-bg-white [&_.modal-content]:tw-px-3 [&_.modal-content]:tw-pb-0 [&_.modal-content]:tw-pt-6',
          props.newFlows
            ? 'tw-top-[72px] tw-h-[calc(100vh-72px)] tw-w-[640px] tw-border-y-0 tw-border-l tw-border-r-0 tw-border-solid tw-border-neutral-150'
            : 'tw-h-screen tw-w-[480px] tw-shadow-[0px_3px_4px_0px_rgba(34,37,41,0.12)] ',
        )}
        wrapperClassName="tw-flex tw-flex-col tw-gap-6 tw-h-full tw-overflow-hidden"
        hideOverflow={false}
      >
        <FullScreenModalHeader
          showPreviousTitle={false}
          title={props.newFlows ? 'Select Component' : 'Select Task'}
          onClose={props.onHide}
          className="tw-border-0 tw-bg-white tw-px-3 tw-py-0"
        >
          <IconButton
            icon="xmark"
            onClick={props.onHide}
            variant="outline"
            className="tw-ml-10 tw-shrink-0"
          />
        </FullScreenModalHeader>
        <Modal.Body className="tw-flex tw-grow tw-flex-col tw-gap-4 tw-overflow-hidden tw-p-1">
          <FormGroup className="tw-px-2">
            <TextInput
              allowClear
              variant="secondary"
              prefix={<Icon className="tw-text-neutral-400" icon="magnifying-glass" />}
              placeholder="Search Components or Configurations"
              value={query}
              onChange={(query) => {
                setQuery(query);
                debouncedHandleSearch(query);
              }}
            />
          </FormGroup>
          <Tabs
            rootClassName="tw-gap-1 tw-overflow-hidden tw-flex-grow"
            listClassName="tw-gap-6 tw-mx-2"
            value={selectedTab}
            inModal
            onValueChange={(tab: TabKey) => setSelectedTab(tab)}
            triggers={[
              {
                value: 'all',
                title: 'All',
              },
              {
                value: 'components',
                title: 'Components',
                disabled:
                  debouncedFilteredValues?.components.size +
                    debouncedFilteredValues?.configurations.size ===
                  0,
              },
              {
                value: 'transformations',
                title: 'Transformations',
                disabled:
                  debouncedFilteredValues?.transformations.size +
                    debouncedFilteredValues?.configurationsOfTransformations.size ===
                  0,
              },
              {
                value: 'flows',
                title: 'Flows',
                disabled: debouncedFilteredValues.flows.get('data').size === 0,
              },
              {
                value: 'data-apps',
                title: 'Data Apps',
                disabled:
                  !props.hasDataApps || debouncedFilteredValues.dataApps.get('data').size === 0,
              },
            ]}
          >
            <TabsContent
              value="all"
              className={cn('tw-pt-2', { [tabContentClassName]: selectedTab === 'all' })}
            >
              {selectedTab === 'all' && (
                <ContentWrapper query={query} debouncedQuery={debouncedQuery}>
                  {renderAll()}
                </ContentWrapper>
              )}
            </TabsContent>
            <TabsContent
              value="components"
              className={cn({ [tabContentClassName]: selectedTab === 'components' }, 'tw-gap-1')}
            >
              {selectedTab === 'components' && (
                <ContentWrapper
                  loadingClassName="tw-pt-2"
                  query={query}
                  debouncedQuery={debouncedQuery}
                >
                  <FormGroup className="tw-p-2">
                    <Select
                      clearable={false}
                      value={componentType}
                      onChange={(type: string) => setComponentType(type)}
                      options={[
                        { label: 'All components', value: '' },
                        ...COMPONENT_TYPES_IN_FILTER.map((type) => ({
                          value: type,
                          label: getNewComponentTypeLabel(type),
                        })),
                      ]}
                    />
                  </FormGroup>
                  {renderComponents()}
                </ContentWrapper>
              )}
            </TabsContent>
            <TabsContent
              value="transformations"
              className={cn('tw-pt-2', {
                [tabContentClassName]: selectedTab === 'transformations',
              })}
            >
              {selectedTab === 'transformations' && (
                <ContentWrapper query={query} debouncedQuery={debouncedQuery}>
                  {renderTransformations()}
                </ContentWrapper>
              )}
            </TabsContent>
            <TabsContent
              value="flows"
              className={cn('tw-pt-2', { [tabContentClassName]: selectedTab === 'flows' })}
            >
              {selectedTab === 'flows' && (
                <ContentWrapper query={query} debouncedQuery={debouncedQuery}>
                  <CreateConfiguration
                    {...createConfigProps}
                    task={fromJS({ componentId: KEBOOLA_ORCHESTRATOR })}
                    component={props.components.get(KEBOOLA_ORCHESTRATOR, Map())}
                    customName="Flow"
                    variant={
                      props.configurations.getIn([KEBOOLA_ORCHESTRATOR, 'configurations'], Map())
                        .size === 0
                        ? 'primary'
                        : 'outline'
                    }
                  />
                  {renderConfigurations(debouncedFilteredValues?.flows)}
                </ContentWrapper>
              )}
            </TabsContent>
            <TabsContent
              value="data-apps"
              className={cn('tw-pt-2', { [tabContentClassName]: selectedTab === 'data-apps' })}
            >
              {selectedTab === 'data-apps' && (
                <ContentWrapper query={query} debouncedQuery={debouncedQuery}>
                  <CreateConfiguration
                    {...createConfigProps}
                    task={fromJS({ componentId: KEBOOLA_DATA_APPS })}
                    component={props.components.get(KEBOOLA_DATA_APPS, Map())}
                    customName="Data App"
                    variant={
                      props.configurations.getIn([KEBOOLA_DATA_APPS, 'configurations'], Map())
                        .size === 0
                        ? 'primary'
                        : 'outline'
                    }
                  />
                  {renderConfigurations(debouncedFilteredValues?.dataApps)}
                </ContentWrapper>
              )}
            </TabsContent>
          </Tabs>
        </Modal.Body>
      </FullScreenModal>
    );
  };

  if (!props.show) {
    return null;
  }

  return renderAddNewTaskDialog();
};

export default AddTaskModal;
