import type { Map } from 'immutable';

import { cn, Icon, IconButton, Tooltip } from '@keboola/design';

const PhaseInput = (props: {
  phase: Map<string, any>;
  previousPhase: Map<string, any>;
  newTaskPosition: null | string;
  handleShowAddTaskModal: (position: string | false, isOpen: boolean) => void;
  readOnly: boolean;
  isLast: boolean;
}) => {
  const inlineButtonId = `${props.previousPhase.get('id')}:${props.phase.get('id')}`;

  const isOpen = props.newTaskPosition === inlineButtonId;

  return (
    <>
      {!props.isLast && !props.readOnly && (
        <div className="between-phases-action">
          <Tooltip
            placement="top"
            tooltip={isOpen ? 'Close' : 'Add Task'}
            triggerClassName="add-task-inline-container"
          >
            <IconButton
              variant="invisible"
              className={cn('add-task-inline circle-button [&_svg]:!tw-text-white', {
                active: isOpen,
              })}
              onClick={() => props.handleShowAddTaskModal(inlineButtonId, !isOpen)}
              icon={isOpen ? 'xmark' : 'plus'}
            />
          </Tooltip>
        </div>
      )}
      <Icon icon="caret-down" className="f-16" />
    </>
  );
};

export default PhaseInput;
