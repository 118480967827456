import type { Map } from 'immutable';
import _ from 'underscore';

import { Link } from '@keboola/design';

import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { createUrl } from '@/utils/router/createUrl';
import { parse as parseTable } from '@/utils/tableIdParser';

export const ExternalProjectTableLink = ({
  table,
  urlTemplates,
}: {
  table: Map<string, any>;
  urlTemplates: Map<string, any>;
}) => {
  const parsedTable = parseTable(table.get('id'));

  const projectPath = _.template(urlTemplates.get('project'))({
    projectId: table.getIn(['project', 'id']),
  });

  const tableUrl = createUrl(routeNames.TABLE, {
    bucketId: `${parsedTable.parts.stage}.${parsedTable.parts.bucket}`,
    bucketTab: bucketTabs.OVERVIEW,
    tableName: parsedTable.parts.table,
    tableTab: tableTabs.OVERVIEW,
  });

  return (
    <span>
      <Link href={projectPath}>{table.getIn(['project', 'name'])}</Link>
      {' / '}
      <Link href={`${projectPath}${tableUrl}`}>{table.get('id')}</Link>
    </span>
  );
};
