import type { ButtonHTMLAttributes, ComponentProps, ReactNode } from 'react';

import { Icon } from '../Icon';

import { buttonStyles } from './styles';
import type { CommonButtonProps } from './types';

type ButtonProps = {
  children: ReactNode;
  icon?: ComponentProps<typeof Icon>['icon'];
  isLoading?: boolean;
  block?: boolean;
} & CommonButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({
  children,
  onClick,
  size = 'medium',
  variant = 'primary',
  disabled = false,
  className,
  type = 'button',
  icon,
  isLoading = false,
  block = false,
  ...buttonProps
}: ButtonProps) => {
  const styles = buttonStyles({ variant, size, disabled, className, isBlock: block });
  return (
    <button {...buttonProps} type={type} onClick={onClick} disabled={disabled} className={styles}>
      {isLoading && (
        <Icon spin icon="spinner" className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle" />
      )}
      {icon && !isLoading && (
        <Icon icon={icon} className="tw-inline-block tw-h-4 tw-w-4 tw-align-middle" />
      )}
      {children}
    </button>
  );
};
