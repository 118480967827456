import { useState } from 'react';
import type { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { CollapsiblePanel } from '@keboola/design';

import { CreatedDate } from '@/react/common';
import JobDuration from '@/react/common/JobDuration';
import JobStatusLabel from '@/react/common/JobStatusLabel';
import ModalIcon from '@/react/common/ModalIcon';
import Tree from '@/react/common/Tree';

type Props = {
  show: boolean;
  onHide: () => void;
  job: Map<string, any>;
  renderTableLink: (job: Map<string, any>) => ReactNode;
  renderDataTransfer: (job: Map<string, any>) => ReactNode;
};

const JobDetailModal = (props: Props) => {
  const [job, setJob] = useState<Map<string, any>>(Map());

  const renderDetail = () => {
    const tableIdLink = props.renderTableLink(job);
    const runId = job.get('runId');
    const fileId = job.getIn(
      ['results', 'file', 'id'],
      job.getIn(['operationParams', 'source', 'fileId']),
    );

    return (
      <div className="well">
        <p className="flex-container">
          <strong>Created time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={job.get('createdTime')}
          />
        </p>
        <p className="flex-container">
          <strong>Status</strong>
          <span className="text-muted">
            <JobStatusLabel status={job.get('status')} />
          </span>
        </p>
        <p className="flex-container">
          <strong>Start time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={job.get('startTime')}
          />
        </p>
        <p className="flex-container">
          <strong>End time</strong>
          <CreatedDate
            absolute
            withSeconds
            className="text-muted"
            createdTime={job.get('endTime')}
          />
        </p>
        <p className="flex-container">
          <strong>Duration</strong>
          <span className="text-muted">
            <JobDuration
              status={job.get('status')}
              startTime={job.get('startTime')}
              endTime={job.get('endTime')}
            />
          </span>
        </p>
        <p className="flex-container">
          <strong>Data Transfer</strong>
          <span className="text-muted">{props.renderDataTransfer(job)}</span>
        </p>
        <p className="flex-container">
          <strong>Operation</strong>
          <span className="text-muted">{job.get('operationName')}</span>
        </p>
        {tableIdLink && (
          <p className="flex-container">
            <strong>Table ID</strong>
            <span className="text-muted text-right">{tableIdLink}</span>
          </p>
        )}
        {runId && (
          <p className="flex-container">
            <strong>Run ID</strong>
            <span className="text-muted">{runId}</span>
          </p>
        )}
        {fileId && (
          <p className="flex-container">
            <strong>File ID</strong>
            <span className="text-muted">{fileId}</span>
          </p>
        )}
      </div>
    );
  };

  const renderTreeData = (header: string, param: string) => {
    const data = job.get(param);

    if (!data || !data.count()) {
      return null;
    }

    return (
      <CollapsiblePanel header={header} className="tw-mb-3">
        <Tree data={data} />
      </CollapsiblePanel>
    );
  };

  const renderBody = () => {
    if (job.isEmpty()) {
      return null;
    }

    return (
      <Modal.Body>
        {renderDetail()}
        {renderTreeData('Params', 'operationParams')}
        {renderTreeData('Results', 'results')}
        {renderTreeData('Error', 'error')}
      </Modal.Body>
    );
  };

  return (
    <Modal bsSize="large" show={props.show} onHide={props.onHide} onEnter={() => setJob(props.job)}>
      <Modal.Header closeButton>
        <Modal.Title>Job {job.get('id')}</Modal.Title>
        <ModalIcon icon="magnifying-glass" color="blue" />
      </Modal.Header>
      {renderBody()}
    </Modal>
  );
};

export default JobDetailModal;
