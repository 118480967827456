import { useState } from 'react';
import { Promise } from 'bluebird';
import { fromJS, type List, Map } from 'immutable';

import AddTaskModal from '@/modules/flows/components/AddTaskModal/AddTaskModal';
import TaskParamsModal from '@/modules/flows/components/TaskParamsModal';
import { saveFlow } from '@/modules/flows-v2/actions';
import { prepareNewJobTask, preparePhasesForNewTask } from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { EditTaskProperties } from '@/modules/flows-v2/builder/types';
import { updateLocalStateValue } from '@/modules/flows-v2/helpers';
import nextTick from '@/utils/nextTick';
import { ConditionDetailModal } from './ConditionDetailModal';
import { NotificationDetailModal } from './NotificationDetailModal';
import { TaskDetailModal } from './TaskDetailModal';
import { VariableDetailModal } from './VariableDetailModal';

type Props = {
  isChanged: boolean;
  folders: Map<string, any>;
  allComponents: Map<string, any>;
  patternComponents: Map<string, any>;
  configurations: Map<string, any>;
  hasDataApps: boolean;
  hasPayAsYouGo: boolean;
  isDevModeActive: boolean;
  showBackendSize: boolean;
  hasSnowflakePartnerConnectLimited: boolean;
  hasSnowflakeDynamicBackendSize: boolean;
  hasJobsDynamicBackendSize: boolean;
};

export const Sidebar = (props: Props) => {
  const [editParamsTask, setEditParamsTask] = useState(Map());
  const [
    context,
    selectedPhaseId,
    selectedTaskId,
    selectedConditionId,
    nodes,
    setSelectedPhaseId,
    setSelectedTaskId,
    setSelectedConditionId,
  ] = useBuilderStore((state) => [
    state.context,
    state.selectedPhaseId,
    state.selectedTaskId,
    state.selectedConditionId,
    state.nodes,
    state.setSelectedPhaseId,
    state.setSelectedTaskId,
    state.setSelectedConditionId,
  ]);

  const handleAddTask = (
    component: Map<string, any>,
    configuration?: Map<string, any>,
    options?: { autosave: boolean },
  ) => {
    const phaseData = preparePhasesForNewTask(selectedPhaseId, context.phases, nodes);

    if (!phaseData) {
      return;
    }

    const newTask = prepareNewJobTask(phaseData.phase.get('id'), component, configuration);
    const tasks = context.tasks.push(newTask);

    updateLocalStateValue(context.config.get('id'), ['phases'], phaseData.phases);
    updateLocalStateValue(context.config.get('id'), ['tasks'], tasks);
    setSelectedTaskId(newTask.get('id'));

    if (!options?.autosave) {
      return Promise.resolve();
    }

    // save flow
    return nextTick(() => saveFlow(context.config, tasks, phaseData.phases));
  };

  const handleEditTask = (taskId: string, properties: EditTaskProperties) => {
    updateLocalStateValue(
      context.config.get('id'),
      ['tasks'],
      context.tasks.map((task) => {
        if (task.get('id') === taskId) {
          return properties.reduce(
            (updatedTask, { keysPath, value }) => updatedTask.setIn(keysPath, value),
            task,
          );
        }

        return task;
      }),
    );
  };

  const handleDeleteTask = () => {
    const tasks = context.tasks.filter((task) => task.get('id') !== selectedTaskId);

    updateLocalStateValue(context.config.get('id'), ['tasks'], tasks);
    setSelectedTaskId(null);
  };

  const handleSelectConfig = (
    taskId: string,
    componentId: string,
    configId: string,
    options?: { autosave: boolean },
  ) => {
    // autosave is used before redirect, so if know there is no change we can skip updating the flows
    if (options?.autosave && !props.isChanged) {
      return Promise.resolve();
    }

    const tasks = context.tasks.map((task) => {
      if (task.get('id') === taskId) {
        const newName = `${componentId}-${configId}`;
        const isConfigChanged = task.get('name') !== newName;

        return task
          .set('name', newName)
          .setIn(['task', 'configId'], configId)
          .setIn(['task', 'componentId'], componentId)
          .update('task', (task: Map<string, any>) => {
            return isConfigChanged ? task.delete('configRowIds') : task;
          });
      }

      return task;
    }) as List<any>;

    updateLocalStateValue(context.config.get('id'), ['tasks'], tasks);

    if (!options?.autosave) {
      return Promise.resolve();
    }

    return nextTick(() => saveFlow(context.config, tasks, context.phases));
  };

  const handleSetTaskParams = (params: Record<string, any>) => {
    updateLocalStateValue(
      context.config.get('id'),
      ['tasks'],
      context.tasks.map((task) => {
        if (task.get('id') === editParamsTask.get('id')) {
          return task.set('task', fromJS(params));
        }

        return task;
      }),
    );
  };

  if (selectedPhaseId) {
    return (
      <AddTaskModal
        show
        newFlows
        onHide={() => setSelectedPhaseId(null)}
        readOnly={context.readOnly}
        onSelect={handleAddTask}
        components={props.allComponents}
        configurations={props.configurations}
        hasSnowflakePartnerConnectLimited={props.hasSnowflakePartnerConnectLimited}
        hasDataApps={props.hasDataApps}
        hasPayAsYouGo={props.hasPayAsYouGo}
        folders={props.folders}
        patternComponents={props.patternComponents}
        isDevModeActive={props.isDevModeActive}
        configId={context.config.get('id')}
      />
    );
  }

  if (selectedTaskId) {
    const allTasks = nodes.flatMap((node) => {
      return node.type === 'phase' ? node.data.tasks : [];
    });
    const task = allTasks.find((task) => task.id === selectedTaskId);

    if (!task) {
      return null;
    }

    if (task.variant === 'variable') {
      return (
        <VariableDetailModal
          task={task}
          readOnly={context.readOnly}
          onEditTask={handleEditTask}
          onHide={() => setSelectedTaskId(null)}
        />
      );
    }

    if (task.variant === 'notification') {
      return (
        <NotificationDetailModal
          task={task}
          readOnly={context.readOnly}
          onEditTask={handleEditTask}
          onHide={() => setSelectedTaskId(null)}
        />
      );
    }

    return (
      <>
        <TaskDetailModal
          onHide={() => setSelectedTaskId(null)}
          task={fromJS(task)}
          readOnly={context.readOnly}
          hasPayAsYouGo={props.hasPayAsYouGo}
          onEditTask={handleEditTask}
          onSelectConfig={handleSelectConfig}
          allConfigurations={props.configurations}
          allComponents={props.allComponents}
          folders={props.folders}
          showBackendSize={props.showBackendSize}
          patternComponents={props.patternComponents}
          hasSnowflakeDynamicBackendSize={props.hasSnowflakeDynamicBackendSize}
          hasJobsDynamicBackendSize={props.hasJobsDynamicBackendSize}
          configId={context.config.get('id')}
          onSetTaskParams={() => {
            const task = context.tasks.find((task) => task.get('id') === selectedTaskId);
            setEditParamsTask(task);
          }}
          isDevModeActive={props.isDevModeActive}
          onDeleteTask={handleDeleteTask}
        />
        <TaskParamsModal
          readOnly={context.readOnly}
          show={!editParamsTask.isEmpty()}
          onHide={() => setEditParamsTask(Map())}
          onSave={handleSetTaskParams}
          params={editParamsTask.get('task', Map()) as Map<string, any>}
        />
      </>
    );
  }

  if (selectedConditionId) {
    return (
      <ConditionDetailModal
        id={selectedConditionId}
        readOnly={context.readOnly}
        onHide={() => setSelectedConditionId(null)}
      />
    );
  }

  return null;
};
