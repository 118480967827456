import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { cn, Icon } from '@keboola/design';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import ActionCreators from '@/modules/legacy-transformation/ActionCreators';
import { routeNames } from '@/modules/legacy-transformation/Constants';
import { RouterLink, RowActionDropdown, RowActionMenuItem, Truncated } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import CopyTransformationButton from './CopyTransformationButton';
import TransformationType from './TransformationType';

const TransformationRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    transformation: PropTypes.object,
    latestVersionId: PropTypes.number,
    bucket: PropTypes.object,
    transformationBuckets: PropTypes.object,
    pendingActions: PropTypes.object,
    readOnly: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <RouterLink
        className={cn('tr hoverable-actions', {
          'row-disabled': this.props.transformation.get('disabled'),
        })}
        to={routeNames.DETAIL}
        params={{ row: this.props.transformation.get('id'), config: this.props.bucket.get('id') }}
      >
        {this.renderRow()}
      </RouterLink>
    );
  },

  renderRow() {
    return (
      <>
        <span className="td">
          <div className="flex-container flex-start">
            {this.renderIcons()}
            <div>
              <Truncated className="component-name" text={this.props.transformation.get('name')} />
              <span className="f-13 text-muted">
                <InlineDescriptionEditInput
                  entity="Transformation"
                  description={this.props.transformation.get('description', '')}
                  onSave={this.handleDescriptionChange}
                  readOnly={this.props.readOnly}
                />
              </span>
            </div>
          </div>
        </span>
        <span className="td pr-1 w-400">
          {!this.props.readOnly && (
            <RowActionDropdown>
              <RunComponentButton
                mode="menuitem"
                componentId={KEBOOLA_LEGACY_TRANSFORMATION}
                title="Run Transformation"
                disabled={this.props.transformation.get('disabled')}
                runParams={() => ({
                  config: this.props.bucket.get('id'),
                  configData: {
                    parameters: { transformationIds: [this.props.transformation.get('id')] },
                  },
                })}
              >
                You are about to run the transformation{' '}
                {this.props.transformation.get('name', this.props.transformation.get('id'))}.
              </RunComponentButton>
              <RowActionMenuItem divider />
              <ActivateDeactivateSwitch
                mode="menuitem"
                isActive={!this.props.transformation.get('disabled')}
                isPending={this.props.pendingActions.has('save-disabled')}
                onChange={this._handleActiveChange}
              />
              <CopyTransformationButton
                mode="menuitem"
                transformation={this.props.transformation}
                transformationBuckets={this.props.transformationBuckets}
                bucketId={this.props.bucket.get('id')}
              />
              <RowActionMenuItem divider />
              <RowActionMenuItem
                onSelect={this._deleteTransformation}
                disabled={this.props.pendingActions.get('delete')}
              >
                <Icon icon="trash" fixedWidth />
                Delete Transformation
              </RowActionMenuItem>
            </RowActionDropdown>
          )}
        </span>
      </>
    );
  },

  renderIcons() {
    return (
      <div className="transformation-backend-icon">
        <TransformationType
          transformation={this.props.transformation}
          imageSize="40"
          showLabel={false}
        />
        <div className="phase-icon">{this.props.transformation.get('phase') || 1}</div>
      </div>
    );
  },

  _deleteTransformation() {
    // if transformation is deleted immediately view is rendered with missing bucket because of store changed
    return setTimeout(() =>
      ActionCreators.deleteTransformation(
        this.props.bucket.get('id'),
        this.props.transformation.get('id'),
      ),
    );
  },

  _handleActiveChange(newValue) {
    const changeDescription = `Transformation ${this.props.transformation.get(
      'name',
      this.props.transformation.get('id'),
    )} ${newValue ? 'enabled' : 'disabled'}`;

    return ActionCreators.changeTransformationProperty(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'disabled',
      !newValue,
      changeDescription,
    );
  },

  handleDescriptionChange(newDescription) {
    return ActionCreators.changeTransformationProperty(
      this.props.bucket.get('id'),
      this.props.transformation.get('id'),
      'description',
      newDescription,
    );
  },
});

export default TransformationRow;
