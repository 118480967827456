import { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import { STORAGE_EVENTS } from '@/modules/home/constants';
import { routeNames as trashRouteNames } from '@/modules/trash/constants';
import { CreatedDate, RouterLink } from '@/react/common';
import Gravatar from '@/react/common/Gravatar';
import string from '@/utils/string';

class LatestActivityConfiguration extends Component {
  render() {
    switch (this.props.event.get('event')) {
      case STORAGE_EVENTS.CREATED:
      case STORAGE_EVENTS.EDITED:
      case STORAGE_EVENTS.RESTORED:
      case STORAGE_EVENTS.COPIED:
      case STORAGE_EVENTS.ROLLBACK:
        return (
          <ComponentConfigurationLink
            className="tr"
            componentId={this.props.component.get('id')}
            configId={this.props.event.getIn(['params', 'configurationId'])}
            hasFlows={this.props.hasFlows}
          >
            {this.renderBody()}
          </ComponentConfigurationLink>
        );

      case STORAGE_EVENTS.ROW_CREATED:
      case STORAGE_EVENTS.ROW_EDITED:
      case STORAGE_EVENTS.ROW_ROLLBACK:
      case STORAGE_EVENTS.ROW_COPIED:
        return (
          <ComponentConfigurationLink
            className="tr"
            componentId={this.props.component.get('id')}
            componentType={this.props.component.get('type')}
            configId={this.props.event.getIn(['params', 'configuration', 'configurationId'])}
            rowId={this.props.event.getIn(['params', 'rowId'])}
          >
            {this.renderBody()}
          </ComponentConfigurationLink>
        );

      case STORAGE_EVENTS.DELETED:
      case STORAGE_EVENTS.ROW_DELETED:
        return (
          <RouterLink
            className="tr"
            to={trashRouteNames.SETTINGS_TRASH}
            query={{ q: this.props.event.getIn(['params', 'configuration', 'configurationId']) }}
          >
            {this.renderBody()}
          </RouterLink>
        );
    }

    return <div className="tr">{this.renderBody()}</div>;
  }

  renderBody() {
    return (
      <>
        <div className="td">{this.renderMessage()}</div>
        <div className="td w-200 text-right text-muted">
          Version #{this.props.event.getIn(['params', 'version'])}
        </div>
        <div className="td w-200 text-right text-muted">
          <CreatedDate createdTime={this.props.event.get('created')} />
        </div>
        {!this.props.showedOnlyConfigurations && <div className="td w-100" />}
      </>
    );
  }

  renderMessage() {
    const token = this.props.event.get('token');
    const user = this.props.admins.get(token.get('name'));
    const name = this.props.event.getIn(['params', 'name']);

    if (user) {
      return (
        <>
          <span className="icon-addon-right">
            <Gravatar user={user} size={28} />
          </span>
          {user.get('name') || user.get('email')} {this.renderActionLabel()}{' '}
          <b className="font-medium color-primary">{string.truncate(name, 50)}</b>
          {this.props.groupCount > 1 && (
            <>
              {' '}
              <span className="text-muted">({this.props.groupCount})</span>
            </>
          )}
        </>
      );
    }

    return (
      <>
        {token.get('name')} {this.renderActionLabel()}{' '}
        <b className="font-medium color-primary">{string.truncate(name, 50)}</b>
      </>
    );
  }

  renderActionLabel() {
    switch (this.props.event.get('event')) {
      case STORAGE_EVENTS.CREATED:
      case STORAGE_EVENTS.ROW_CREATED:
        return 'created:';

      case STORAGE_EVENTS.EDITED:
      case STORAGE_EVENTS.ROW_EDITED:
        return 'edited:';

      case STORAGE_EVENTS.RESTORED:
        return 'restored:';

      case STORAGE_EVENTS.COPIED:
      case STORAGE_EVENTS.ROW_COPIED:
        return 'copied:';

      case STORAGE_EVENTS.DELETED:
      case STORAGE_EVENTS.ROW_DELETED:
        return 'deleted:';

      case STORAGE_EVENTS.ROLLBACK:
      case STORAGE_EVENTS.ROW_ROLLBACK:
        return 'rolled back:';

      case STORAGE_EVENTS.PURGED:
        return 'deleted permanently:';

      default:
        return null;
    }
  }
}

LatestActivityConfiguration.propTypes = {
  event: PropTypes.instanceOf(Map).isRequired,
  component: PropTypes.instanceOf(Map).isRequired,
  groupCount: PropTypes.number.isRequired,
  admins: PropTypes.instanceOf(Map).isRequired,
  showedOnlyConfigurations: PropTypes.bool.isRequired,
  hasFlows: PropTypes.bool.isRequired,
};

export default LatestActivityConfiguration;
