import { List } from 'immutable';

import callDockerAction from '@/modules/components/DockerActionsApi';
import componentsActions from '@/modules/components/InstalledComponentsActionCreators';
import RoutesStore from '@/stores/RoutesStore';
import { prepareQueryToSave, updateProtectedProperties } from './helpers';
import * as storeProvisioning from './storeProvisioning';

export function createActions(componentId) {
  function getStore(configId) {
    return storeProvisioning.createStore(componentId, configId);
  }

  function localState(configId) {
    return storeProvisioning.getLocalState(componentId, configId);
  }

  function updateLocalState(configId, path, data) {
    const ls = localState(configId);
    const newLocalState = ls.setIn([].concat(path), data);
    componentsActions.updateLocalState(componentId, configId, newLocalState, path);
  }

  function removeFromLocalState(configId, path) {
    const ls = localState(configId);
    const newLocalState = ls.deleteIn([].concat(path));
    componentsActions.updateLocalState(componentId, configId, newLocalState, path);
  }

  function saveConfigData(configId, data, waitingPath, changeDescription) {
    updateLocalState(configId, waitingPath, true);
    return componentsActions
      .saveComponentConfigData(componentId, configId, data, changeDescription)
      .then(() => updateLocalState(configId, waitingPath, false));
  }

  return {
    setQueriesFilter(configId, query) {
      updateLocalState(configId, 'queriesFilter', query);
    },

    cancelCredentialsEdit(configId) {
      removeFromLocalState(configId, ['isChangedCredentials'], null);
      removeFromLocalState(configId, ['editingCredentials'], null);
    },

    updateEditingCredentials(configId, newCredentials) {
      updateLocalState(configId, 'editingCredentials', newCredentials);
      if (!localState(configId).get('isChangedCredentials', false)) {
        updateLocalState(configId, ['isChangedCredentials'], true);
      }
    },

    changeQueryEnabledState(configId, qid, newValue) {
      const store = getStore(configId);
      const newQueries = store.getQueries().map((q) => {
        if (q.get('id') === qid) {
          return q.set('enabled', newValue);
        }
        return q;
      });
      const diffMsg = `${!!newValue ? 'Enable' : 'Disable'} export ${store
        .getConfigQuery(qid)
        .get('name')}`;
      const newData = store.configData.setIn(['parameters', 'exports'], newQueries);
      return saveConfigData(configId, newData, ['pending', qid, 'enabled'], diffMsg);
    },

    createNewQuery(configId) {
      const store = getStore(configId);
      let newQuery = store.getNewQuery();
      updateLocalState(configId, ['newQueries', newQuery.get('id')], newQuery);
      updateLocalState(
        configId,
        ['newQueriesIdsList'],
        store.getNewQueriesIdsList().unshift(newQuery.get('id')),
      );
      updateLocalState(configId, ['editingQueries', newQuery.get('id')], newQuery);
      return newQuery;
    },

    saveCredentialsEdit(configId) {
      const store = getStore(configId);
      let credentials = store.getEditingCredentials();
      credentials = updateProtectedProperties(credentials, store.getCredentials());
      const newConfigData = store.configData.setIn(['parameters', 'db'], credentials);
      return saveConfigData(
        configId,
        newConfigData,
        'isSavingCredentials',
        'Update credentials',
      ).then(() => {
        this.cancelCredentialsEdit(configId);
        RoutesStore.getRouter().transitionTo(componentId, { config: configId });
      });
    },

    deleteQuery(configId, qid) {
      const store = getStore(configId);
      const query = store.getQueries().find((q) => q.get('id') === qid);
      if (store.isNewQuery(qid) || !query) {
        removeFromLocalState(configId, ['newQueries', qid]);
        removeFromLocalState(configId, ['editingQueries', qid]);
        return;
      }
      const newQueries = store.getQueries().filter((q) => q.get('id') !== qid);
      const newData = store.configData.setIn(['parameters', 'exports'], newQueries);
      return saveConfigData(
        configId,
        newData,
        ['pending', qid, 'deleteQuery'],
        'Delete export ' + query.get('name'),
      );
    },

    updateEditingQuery(configId, query) {
      const queryId = query.get('id');
      updateLocalState(configId, ['editingQueries', queryId], query);
      if (!localState(configId).getIn(['isChanged', queryId], false)) {
        updateLocalState(configId, ['isChanged', queryId], true);
      }
    },

    cancelQueryEdit(configId, queryId) {
      removeFromLocalState(configId, ['editingQueries', queryId]);
    },

    resetQueryEdit(configId, queryId) {
      removeFromLocalState(configId, ['isChanged', queryId]);
      const store = getStore(configId);
      if (store.isNewQuery(queryId)) {
        const newQuery = store.getNewQuery(queryId);
        updateLocalState(configId, ['newQueries', queryId], newQuery);
        updateLocalState(configId, ['editingQueries', queryId], newQuery);
      } else {
        removeFromLocalState(configId, ['editingQueries', queryId]);
      }
    },

    resetQueryState(configId, queryId) {
      const currentState = getStore(configId).configState;
      let updateState = currentState.deleteIn(['component', 'lastFetchedRow', queryId]);
      if (!updateState.getIn(['component', 'lastFetchedRow']).count()) {
        updateState = updateState.deleteIn(['component', 'lastFetchedRow']);
      }
      return componentsActions.updateComponentState(componentId, configId, updateState);
    },

    saveQueryEdit(configId, queryId) {
      const store = getStore(configId);
      let newQuery = store.getEditingQuery(queryId);
      let newQueries = store.getQueries().filter((q) => q.get('id') !== newQuery.get('id'));

      newQuery = prepareQueryToSave(newQuery);

      newQueries = newQueries.push(newQuery);
      const newData = store.configData.setIn(['parameters', 'exports'], newQueries);
      return saveConfigData(
        configId,
        newData,
        ['isSaving', queryId],
        'Edit export ' + newQuery.get('name'),
      ).then(() => {
        this.cancelQueryEdit(configId, queryId);
        removeFromLocalState(configId, ['isSaving', queryId]);
        removeFromLocalState(configId, ['isChanged', queryId]);
        if (store.isNewQuery(queryId)) {
          removeFromLocalState(configId, ['newQueries', queryId]);
        }
      });
    },

    testCredentials(configId, credentials) {
      const store = getStore(configId);
      return callDockerAction(componentId, 'testConnection', {
        configData: store.configData
          .setIn(['parameters', 'exports'], List())
          .setIn(
            ['parameters', 'db'],
            updateProtectedProperties(credentials, store.getCredentials()),
          )
          .toJS(),
      });
    },

    prepareSingleQueryRunData(configId, query) {
      const store = getStore(configId);
      const runData = store.configData.setIn(['parameters', 'exports'], List().push(query));
      return runData;
    },
  };
}
