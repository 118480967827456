import { IconButton, Tooltip } from '@keboola/design';

type Props = {
  isCollapsed: boolean;
  entity?: string;
  isDisabled?: boolean;
};

const TableCollapseButton = ({ isCollapsed, entity, isDisabled }: Props) => {
  let tooltip = isCollapsed ? 'More Details' : 'Less Details';

  if (entity) {
    tooltip = `${isCollapsed ? 'Expand' : 'Collapse'} ${entity}`;
  }

  return (
    <Tooltip placement="top" tooltip={tooltip} triggerClassName="table-collapse-wrapper">
      <IconButton
        variant="inline"
        disabled={isDisabled}
        icon={['far', isCollapsed ? 'angle-right' : 'angle-down']}
        onClick={() => (document.activeElement as HTMLElement | null)?.blur()}
      />
    </Tooltip>
  );
};

export default TableCollapseButton;
