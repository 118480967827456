import type { ReactElement } from 'react';
import { replace, Route } from 'react-router';
import Promise from 'bluebird';
import type { Map } from 'immutable';

import StorageActionCreators from '@/modules/components/StorageActionCreators';
import StorageJobsStore from '@/modules/components/stores/StorageJobsStore';
import StreamActionCreators from '@/modules/stream/actions';
import DetailHeaderButtons from '@/modules/stream/components/DetailHeaderButtons';
import StreamIndexHeaderButton from '@/modules/stream/components/IndexHeaderButton';
import NameEdit from '@/modules/stream/components/NameEdit';
import Detail from '@/modules/stream/Detail';
import { findSource } from '@/modules/stream/helpers';
import StreamIndex from '@/modules/stream/Index';
import StreamStore from '@/modules/stream/store';
import DummyBreadcrumb from '@/react/common/DummyBreadcrumb';
import ApplicationStore from '@/stores/ApplicationStore';
import BucketLabelsFromStore from './components/BucketLabelsFromStore';
import BucketNameEdit from './components/BucketNameEdit';
import BucketTitle from './components/BucketTitle';
import CreateTableButton from './components/CreateTableButton';
import HeaderButtons from './components/HeaderButtons';
import TableNameEdit from './components/TableNameEdit';
import TableRouteLabels from './components/TableRouteLabels';
import TableTitle from './components/TableTitle';
import UploadFileButton from './components/UploadFileButton';
import {
  loadBucketDetail,
  loadFiles,
  loadJob,
  loadJobs,
  loadTableDetail,
  tokenVerify,
  updateFilesSearchQuery,
} from './actions';
import Bucket from './Bucket';
import { bucketTabs, routeNames, tableTabs } from './constants';
import Events from './Events';
import Files from './Files';
import Index from './Index';
import Jobs from './Jobs';
import Table from './Table';

const STORAGE_TITLE = 'Storage';
const SOURCE_POLL_INTERVAL = 15 * 1000; // 15 seconds

export const storageRoutes = {
  name: routeNames.ROOT,
  title: STORAGE_TITLE,
  headerButtonsHandler: HeaderButtons,
  defaultRouteHandler: Index,
  requireData: [() => tokenVerify(), () => StorageActionCreators.loadBucketsAndTables()],
  childRoutes: [
    {
      name: routeNames.JOBS,
      path: 'jobs/:jobId?',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Jobs,
      poll: {
        skipFirst: true,
        action: () => loadJobs(),
      },
      requireData: [
        ({ jobId }: { jobId: string }) => {
          if (jobId) {
            if (StorageJobsStore.getAll().isEmpty()) {
              loadJobs();
            }

            if (!StorageJobsStore.getJob(jobId).isEmpty()) {
              return Promise.resolve();
            }

            return loadJob(jobId);
          }

          // init loading without waiting, we will show loading on the index page
          loadJobs();
        },
      ],
    },
    {
      name: routeNames.EVENTS,
      path: 'events',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Events,
    },
    {
      name: routeNames.STREAMS,
      path: 'data-streams',
      title: 'Data Streams',
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: StreamIndex,
      headerButtonsHandler: StreamIndexHeaderButton,
      requireData: [
        (params: Record<string, any>) => {
          if (!ApplicationStore.hasDataStreams() || 'sourceId' in params) return Promise.resolve();

          return StreamActionCreators.loadSources();
        },
      ],
      childRoutes: [
        {
          name: routeNames.STREAM_DETAIL,
          path: ':sourceId',
          title: (routerState: Map<string, any>) =>
            findSource(StreamStore.getStore().sources, routerState.getIn(['params', 'sourceId']))
              ?.name,
          nameEdit: () => <NameEdit />,
          defaultRouteHandler: Detail,
          headerButtonsHandler: DetailHeaderButtons,
          requireData: (params: { sourceId: string }) =>
            StreamActionCreators.loadSource(params.sourceId),
          poll: {
            skipFirst: true,
            interval: SOURCE_POLL_INTERVAL,
            action: (params: { sourceId: string }) =>
              StreamActionCreators.loadSource(params.sourceId),
          },
        },
      ],
    },
    {
      name: routeNames.FILES,
      path: 'files',
      title: STORAGE_TITLE,
      breadcrumbHandler: DummyBreadcrumb,
      defaultRouteHandler: Files,
      headerButtonsHandler: UploadFileButton,
      poll: {
        skipFirst: true,
        action: (params: Record<string, any>, query: { q?: string }) =>
          loadFiles({ ...(query.q && { q: query.q }) }),
      },
      requireData: [
        (params: Record<string, any>, query: { q?: string }) => {
          if (query.q || query.q === '') {
            updateFilesSearchQuery(query.q);

            return loadFiles({ q: query.q });
          }

          return loadFiles();
        },
      ],
    },
    {
      name: routeNames.BUCKET,
      path: ':bucketId/:bucketTab',
      titleHandler: BucketTitle,
      nameEdit(params: { bucketId: string }) {
        return <BucketNameEdit key={params.bucketId} bucketId={params.bucketId} />;
      },
      requireData: (params: { bucketId: string }) => loadBucketDetail(params.bucketId),
      defaultRouteHandler: Bucket,
      headerButtonsHandler: CreateTableButton,
      labelHandler: BucketLabelsFromStore,
      childRoutes: [
        {
          name: routeNames.TABLE,
          path: 'table/:tableName/:tableTab',
          titleHandler: TableTitle,
          labelHandler: TableRouteLabels,
          nameEdit(params: { bucketId: string; tableName: string }) {
            return (
              <TableNameEdit
                key={`${params.bucketId}.${params.tableName}`}
                bucketId={params.bucketId}
                tableName={params.tableName}
              />
            );
          },
          requireData: (params: { bucketId: string; tableName: string }) =>
            loadTableDetail(`${params.bucketId}.${params.tableName}`),
          defaultRouteHandler: Table,
        },
      ],
    },
  ],
};

export const getStorageRedirects = (): ReactElement[] => [
  <Route
    key={`${routeNames.BUCKET}-missing-bucketTab-redirect`}
    path={`${routeNames.ROOT}/:bucketId`}
    loader={({ params: { bucketId } }) =>
      replace(`/${routeNames.ROOT}/${bucketId}/${bucketTabs.OVERVIEW}`)
    }
  />,
  <Route
    key={`${routeNames.TABLE}-missing-bucketTab-redirect`}
    path={`${routeNames.ROOT}/:bucketId/table/:tableName/:tableTab`}
    loader={({ params: { bucketId, tableName, tableTab } }) =>
      replace(
        `/${routeNames.ROOT}/${bucketId}/${bucketTabs.OVERVIEW}/table/${tableName}/${tableTab}`,
      )
    }
  />,
  <Route
    key={`${routeNames.TABLE}-missing-bucketTab-and-tableTab-redirect`}
    path={`${routeNames.ROOT}/:bucketId/table/:tableName`}
    loader={({ params: { bucketId, tableName } }) =>
      replace(
        `/${routeNames.ROOT}/${bucketId}/${bucketTabs.OVERVIEW}/table/${tableName}/${tableTabs.OVERVIEW}`,
      )
    }
  />,
];
