import type { MultiValueGenericProps } from 'react-select';
import { components } from 'react-select';
import type { List } from 'immutable';

import { Tooltip } from '@keboola/design';

import { PREINSTALLED_PACKAGES } from '@/modules/data-apps/constants';
import Select from '@/react/common/Select';

const DEFAULT_PACKAGES_PLACEHOLDER = '__default';

type Props = {
  id?: string;
  componentId: string;
  configId: string;
  isLoading?: boolean;
  value?: string[];
  onChange?: (value: string[]) => void;
  isReadOnly?: boolean;
};

export const PackagesSelect = ({
  isLoading = false,
  onChange,
  value = [],
  isReadOnly = false,
}: Props) => {
  const handleChange = (packagesImmutable: List<string>) => {
    const packages = packagesImmutable.toJS() as string[];
    const withoutDefault = packages.filter((value) => value !== DEFAULT_PACKAGES_PLACEHOLDER);
    onChange?.(withoutDefault);
  };

  const multiValueRenderer = (props: MultiValueGenericProps<{ value: string; label: string }>) => {
    const isDefaultValue = props.data.value === DEFAULT_PACKAGES_PLACEHOLDER;

    if (!isDefaultValue || !Array.isArray(props.children))
      return <components.MultiValueContainer {...props} />;

    return (
      <Tooltip placement="top" type="explanatory" tooltip={PREINSTALLED_PACKAGES.join(', ')}>
        <components.MultiValueContainer {...props}>
          <components.MultiValueLabel {...props.children[0].props}>
            Default Packages
          </components.MultiValueLabel>
        </components.MultiValueContainer>
      </Tooltip>
    );
  };

  return (
    <Select
      id="deployment-packages-field"
      multi
      allowCreate
      clearable={value.length > 0}
      value={[DEFAULT_PACKAGES_PLACEHOLDER, ...value]}
      placeholder="Add packages"
      onChange={handleChange}
      promptTextCreator={(label) => `Add package "${label}"`}
      disabled={isLoading || isReadOnly}
      multiValueRenderer={multiValueRenderer}
    />
  );
};
