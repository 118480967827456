import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Icon, Tooltip } from '@keboola/design';

import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SCHEDULER } from '@/constants/componentIds';
import { canManageTokens } from '@/modules/admin/privileges';
import ComponentDescription from '@/modules/components/react/components/ComponentDescription';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import EventTriggersStore from '@/modules/event-trigger/EventTriggersStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import CronRecord from '@/modules/scheduler/components/CronRecord';
import { SCHEDULE_STATE } from '@/modules/scheduler/constants';
import { getSingleScheduler } from '@/modules/scheduler/helpers';
import { RouterLink } from '@/react/common';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import Confirm from '@/react/common/Confirm';
import Sidebar from '@/react/layout/Sidebar/Sidebar';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ErrorContent from '@/react/pages/ErrorContent';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import JobsTable from './components/JobsTable';
import Schedule from './components/Schedule';
import TasksSummary from './components/TasksSummary';
import { deleteOrchestration, updateOrchestration } from './actions';
import { routeNames } from './constants';
import { prepareOrchestration } from './helpers';

const OrchestrationDetail = createReactClass({
  mixins: [
    createStoreMixin(
      RoutesStore,
      ApplicationStore,
      InstalledComponentsStore,
      JobsStore,
      NotificationsStore,
      EventTriggersStore,
      TablesStore,
      BucketsStore,
    ),
  ],

  getStateFromStores() {
    const orchestrationId = RoutesStore.getCurrentRouteParam('config');
    const latestJobsData = JobsStore.getLatestJobs(KEBOOLA_ORCHESTRATOR, orchestrationId);

    return {
      orchestrationId,
      latestJobsData,
      orchestration: prepareOrchestration(
        InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, orchestrationId),
        InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SCHEDULER),
      ),
      notifications: NotificationsStore.getNotifications(KEBOOLA_ORCHESTRATOR, orchestrationId),
      jobs: latestJobsData.get('jobs', Map()),
      tables: TablesStore.getAll(),
      buckets: BucketsStore.getAll(),
      trigger: EventTriggersStore.getSingle(KEBOOLA_ORCHESTRATOR, orchestrationId),
      admins: ApplicationStore.getAdmins(),
      sapiTokenCanManageTokens: canManageTokens(ApplicationStore.getSapiToken()),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
    };
  },

  getInitialState() {
    return { isTogglingOrchestration: false };
  },

  render() {
    if (this.state.orchestration.isEmpty()) {
      return <ErrorContent headerText="Orchestration not found" />;
    }

    return (
      <div className="row">
        <div className="col-sm-9">
          <ComponentDescription
            componentId={KEBOOLA_ORCHESTRATOR}
            configId={this.state.orchestration.get('id')}
            placeholderEntity="Orchestrator"
          />
          <div className="box info-row">
            {this.renderTasks()}
            {this.renderSchedule()}
            {this.renderNotifications()}
          </div>
          {this.renderJobs()}
        </div>
        <div className="col-sm-3">
          <Sidebar
            hideJobs
            hideCopy
            hideSchedule
            componentId={KEBOOLA_ORCHESTRATOR}
            configId={this.state.orchestration.get('id')}
            run={{
              title: 'Run orchestration',
              disabled: this.state.orchestration.getIn(['configuration', 'tasks'], List()).isEmpty()
                ? 'Orchestration cannot run without tasks.'
                : '',
              text: (
                <>
                  You are about to run the orchestration{' '}
                  <strong>{this.state.orchestration.get('name')}</strong>.
                </>
              ),
            }}
            delete={this.renderDeleteButton()}
            versionsLinkTo={routeNames.VERSIONS}
            additionalButtons={this.renderDisableSwitch()}
          />
        </div>
      </div>
    );
  },

  renderTasks() {
    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Tasks
          <RouterLink
            className="btn-link-inline btn-link-muted icon-addon-left"
            to={routeNames.TASKS}
            params={{ config: this.state.orchestration.get('id') }}
          >
            <Icon icon="pen" />
          </RouterLink>
        </h4>
        <p className="last-line text-muted">
          <TasksSummary orchestration={this.state.orchestration} />
        </p>
      </div>
    );
  },

  renderSchedule() {
    const scheduler = getSingleScheduler(this.state.orchestration.get('schedulers'));
    const schedule = scheduler.getIn(['configuration', 'schedule'], Map());

    return (
      <div className="info-row-section">
        <h4 className="first-line">
          {this.state.trigger.isEmpty() ? 'Schedule' : 'Event Trigger'}
          <Schedule
            orchestration={this.state.orchestration}
            tables={this.state.tables}
            buckets={this.state.buckets}
            trigger={this.state.trigger}
            readOnly={
              this.state.readOnly ||
              this.state.isDevModeActive ||
              !this.state.sapiTokenCanManageTokens
            }
          />
          {this.state.isDevModeActive &&
            (!!schedule.get('cronTab') || !this.state.trigger.isEmpty()) && (
              <Tooltip
                placement="top"
                type="static-explanatory"
                tooltip={`${
                  schedule.get('cronTab') ? 'Schedule' : 'Trigger'
                } are inactive in development branches`}
              >
                <Icon fixedWidth icon="circle-info" className="icon-addon-left" />
              </Tooltip>
            )}
        </h4>
        <div className="last-line text-muted">
          {this.state.trigger.isEmpty() ? (
            <CronRecord
              crontabRecord={schedule.get('cronTab')}
              crontabTimezone={schedule.get('timezone')}
              disabled={schedule.get('state') !== SCHEDULE_STATE.ENABLED}
            />
          ) : (
            <div>
              <span>Tables to check: {this.state.trigger.get('tables', List()).count()}</span>
              <br />
              <span>
                Cooldown period: {this.state.trigger.get('coolDownPeriodMinutes', 0)} minutes
              </span>
            </div>
          )}
        </div>
      </div>
    );
  },

  renderNotifications() {
    if (!this.state.readOnly && !this.state.isDevModeActive && this.state.notifications.isEmpty()) {
      return (
        <div className="info-row-section">
          <RouterLink
            className="btn btn-primary"
            to={routeNames.NOTIFICATIONS}
            params={{ config: this.state.orchestration.get('id') }}
          >
            <Icon icon="pen" className="icon-addon-right" />
            Configure notifications
          </RouterLink>
        </div>
      );
    }

    return (
      <div className="info-row-section">
        <h4 className="first-line">
          Notifications
          {!this.state.readOnly && !this.state.isDevModeActive && (
            <RouterLink
              className="btn-link-inline btn-link-muted icon-addon-left"
              to={routeNames.NOTIFICATIONS}
              params={{ config: this.state.orchestration.get('id') }}
            >
              <Icon icon="pen" />
            </RouterLink>
          )}
        </h4>
        <p className="last-line text-muted">
          {this.state.notifications.isEmpty() ? (
            'No notifications'
          ) : (
            <>
              {this.state.notifications.count()}{' '}
              {string.pluralize(this.state.notifications.count(), 'notification')} set
            </>
          )}
        </p>
      </div>
    );
  },

  renderJobs() {
    return (
      <JobsTable
        orchestration={this.state.orchestration}
        jobs={this.state.jobs}
        admins={this.state.admins}
        hasNewQueue={this.state.hasNewQueue}
        isLoading={this.state.latestJobsData.get('isLoading', false)}
      />
    );
  },

  renderDeleteButton() {
    const name = this.state.orchestration.get('name');

    return (
      <Confirm
        icon="trash"
        title="Delete orchestration"
        text={`Are you sure you want to delete the orchestration ${name}?`}
        buttonLabel="Delete Orchestration"
        onConfirm={() => deleteOrchestration(this.state.orchestration, { transition: true })}
      >
        <Button bsStyle="link" className="btn-block">
          <Icon icon="trash" fixedWidth />
          Delete orchestration
        </Button>
      </Confirm>
    );
  },

  renderDisableSwitch() {
    return (
      <ActivateDeactivateButton
        isActive={!this.state.orchestration.get('isDisabled', false)}
        isPending={this.state.isTogglingOrchestration}
        onChange={(isEnabled) => {
          this.setState({ isTogglingOrchestration: true });

          return updateOrchestration(
            this.state.orchestration.get('id'),
            { isDisabled: !isEnabled },
            `${isEnabled ? 'Enable' : 'Disable'} orchestration`,
          ).finally(() => this.setState({ isTogglingOrchestration: false }));
        }}
        readOnly={this.state.readOnly}
      />
    );
  },
});

export default OrchestrationDetail;
