import createReactClass from 'create-react-class';
import { List, Map } from 'immutable';

import { Alert, IconButton } from '@keboola/design';

import {
  KDS_TEAM_WR_EXASOL,
  KDS_TEAM_WR_FIREBOLT,
  KEBOOLA_WR_DB_ORACLE,
  KEBOOLA_WR_SISENSE,
} from '@/constants/componentIds';
import FiltersDescription from '@/modules/components/react/components/generic/FiltersDescription';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import columnValidation from '@/modules/components/utils/columnTypeValidation';
import RowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DeleteRowButton from '@/modules/configurations/react/components/DeleteConfigurationRowButton';
import { DISABLED_NULLABLE, WR_ORACLE_DEFAULT_BULK_SIZE } from '@/modules/wr-db/constants';
import {
  getStorageInputTablesWithSourceSearch,
  supportFullLoadOnly,
  supportLoadTables,
} from '@/modules/wr-db/helpers';
import ColumnsEditor from '@/modules/wr-db/react/components/ColumnsEditor';
import PostRunScripts from '@/modules/wr-db/react/components/PostRunScripts';
import SisenseRelations from '@/modules/wr-db/react/components/SisenseRelations';
import IncrementalSetupModal from '@/modules/wr-db/react/pages/table/IncrementalSetupModal';
import IndexesSetupModal from '@/modules/wr-db/react/pages/table/IndexesSetupModal';
import {
  getSizeParam,
  prepareColumns,
  prepareEditingColumns,
  prepareNullable,
} from '@/modules/wr-db/react/pages/table/tableHelpers';
import {
  deleteRow,
  editRowColumn,
  resetRow,
  saveBulkSize,
  saveCaseSensitivity,
  saveFireboltTableLoad,
  saveIndexesSetting,
  saveLoadingSetting,
  savePostRunScripts,
  saveRelationship,
  saveRowColumns,
  saveTableName,
  saveTableType,
  toggleRow,
} from '@/modules/wr-db/rowsActions';
import * as columnsMetadata from '@/modules/wr-db/templates/columnsMetadata';
import {
  getComponentDataTypes,
  getDisabledColumnFields,
} from '@/modules/wr-db/templates/dataTypes';
import ActivateDeactivateButton from '@/react/common/ActivateDeactivateButton';
import AutomaticLoadTypeLastUpdated from '@/react/common/AutomaticLoadTypeLastUpdated';
import changedSinceConstants from '@/react/common/changedSinceConstants';
import RunUnsavedWarning from '@/react/common/RunUnsavedWarning';
import Select from '@/react/common/Select';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import BulkSizeEdit from './BulkSizeEdit';
import DestinationTableSelector from './DestinationTableSelector';
import TableNameEdit from './TableNameEdit';

const Row = (componentId) => {
  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        RoutesStore,
        RowsStore,
        StorageTablesStore,
        InstalledComponentsStore,
        ComponentsStore,
      ),
    ],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const rowId = RoutesStore.getCurrentRouteParam('row');
      const row = RowsStore.get(componentId, configId, rowId);
      const rows = RowsStore.getRows(componentId, configId);
      const rowConfiguration = row.get('configuration', Map());
      const editing = RowsStore.getEditingConfiguration(componentId, configId, rowId);
      const pendingActions = RowsStore.getPendingActions(componentId, configId, rowId);
      const tableId = rowConfiguration.getIn(['parameters', 'tableId']);
      const storageTable = StorageTablesStore.getTable(tableId, Map());

      const component = ComponentsStore.getComponent(componentId);

      const items = rowConfiguration.getIn(['parameters', 'items'], List()).map(prepareNullable);
      const columns = prepareColumns(items, storageTable.get('columns', List()));

      const editingItems = editing
        .getIn(['parameters', 'items'], List())
        .map(prepareNullable)
        .toMap()
        .mapKeys((key, column) => column.get('name'));
      const editingColumns = prepareEditingColumns(columns, editingItems);
      const hasChanges = !prepareEditingColumns(columns, Map()).equals(editingColumns);
      const localState = InstalledComponentsStore.getLocalState(componentId, configId);

      return {
        row,
        rows,
        rowId,
        tableId,
        configId,
        columns,
        hasChanges,
        editing,
        storageTable,
        editingColumns,
        rowConfiguration,
        pendingActions,
        localState,
        component,
        configuration: InstalledComponentsStore.getConfig(componentId, configId),
        allTables: StorageTablesStore.getAll(),
        rootConfigData: InstalledComponentsStore.getConfigData(componentId, configId),
        tablesWithSourceSearchInputMapping: getStorageInputTablesWithSourceSearch(rowConfiguration),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    getInitialState() {
      return {
        columnsValidation: Map(),
        showIncrementalModal: false,
        showIndexesModal: false,
      };
    },

    render() {
      if (!this.state.row.count()) {
        return null;
      }

      return (
        <GenericConfigBody
          key={`${componentId}-${this.state.configId}-${this.state.rowId}`}
          componentId={componentId}
          configId={this.state.configId}
          rowId={this.state.rowId}
          sidebarProps={{
            run: {
              forceModal: this.state.hasChanges,
              disabled: this.state.pendingActions.has('save-configuration'),
              text: (
                <>
                  {this.state.hasChanges && (
                    <RunUnsavedWarning section="columns" entity="component" />
                  )}
                  You are about to run {this.state.row.get('name')}.
                </>
              ),
            },
            delete: (
              <DeleteRowButton
                mode="link"
                isPending={this.state.pendingActions.has('delete')}
                onClick={this.handleDelete}
              />
            ),
            additionalButtons: (
              <ActivateDeactivateButton
                isActive={!this.state.row.get('isDisabled', false)}
                isPending={
                  this.state.pendingActions.has('enable') ||
                  this.state.pendingActions.has('disable')
                }
                onChange={this.handleActivateDeactivate}
                readOnly={this.state.readOnly}
              />
            ),
          }}
        >
          <div className="box info-row">
            {this.renderSourceInfo()}
            {this.renderTableEdit()}
          </div>
          {!supportFullLoadOnly(componentId) && (
            <>
              <div className="box info-row">
                {this.renderIncrementalSetup()}
                {componentId === KDS_TEAM_WR_FIREBOLT
                  ? this.renderFireboltTableSetup()
                  : this.renderTableFiltersRow()}
                {this.renderPrimaryKey()}
                {componentId === KEBOOLA_WR_DB_ORACLE && this.renderOracleBulkSizeInput()}
              </div>
              {this.renderPrimaryKeyMismatch()}
            </>
          )}
          {componentId === KDS_TEAM_WR_FIREBOLT && this.renderIndexesSetup()}
          {this.renderColumnEditor()}
          {this.renderFireboltPostRunScripts()}
          {this.renderSisenseRelations()}
        </GenericConfigBody>
      );
    },

    renderColumnEditor() {
      return (
        <ColumnsEditor
          readOnly={this.state.readOnly}
          componentId={componentId}
          tableId={this.state.tableId}
          tableExists={this.state.allTables.has(this.state.tableId)}
          dataTypes={getComponentDataTypes(componentId)}
          disabledColumnFields={getDisabledColumnFields(componentId)}
          columns={this.state.columns}
          primaryKey={this.state.rowConfiguration.getIn(['parameters', 'primaryKey'], List())}
          defaultColumnsTypes={columnsMetadata.prepareColumnsTypesAsMap(
            componentId,
            this.state.storageTable,
          )}
          editingColumns={this.state.editingColumns}
          columnsValidation={this.state.columnsValidation}
          editColumnFn={this.handleEditColumn}
          hasChanges={this.state.hasChanges}
          isSaving={this.state.pendingActions.has('save-configuration')}
          onColumnsCancel={this.handleEditColumnsCancel}
          onColumnsSave={this.handleEditColumnsSave}
        />
      );
    },

    renderSourceInfo() {
      return (
        <div className="info-row-section">
          <h4 className="first-line">Source table</h4>
          <div>
            {this.state.tablesWithSourceSearchInputMapping.has(this.state.tableId) ? (
              <>
                Search table by metadata
                <br />
                <code>
                  {this.state.tablesWithSourceSearchInputMapping.getIn([
                    this.state.tableId,
                    'source_search',
                    'value',
                  ])}
                </code>
              </>
            ) : (
              <StorageApiTableLinkEx tableId={this.state.tableId} />
            )}
          </div>
        </div>
      );
    },

    renderTableEdit() {
      return (
        <>
          <div className="info-row-section">
            <h4 className="first-line">Database table name</h4>
            <div>
              {supportLoadTables(componentId, this.state.rootConfigData) ? (
                <DestinationTableSelector
                  componentId={componentId}
                  configId={this.state.configId}
                  localState={this.state.localState}
                  tableName={this.state.rowConfiguration.getIn(['parameters', 'dbName'])}
                  onChange={(dbName) => {
                    return saveTableName(componentId, this.state.configId, this.state.row, dbName);
                  }}
                  readOnly={this.state.readOnly}
                  rootConfigData={this.state.rootConfigData}
                />
              ) : (
                <TableNameEdit
                  readOnly={this.state.readOnly}
                  tableName={this.state.rowConfiguration.getIn(['parameters', 'dbName'])}
                  onSubmit={(dbName) => {
                    return saveTableName(componentId, this.state.configId, this.state.row, dbName);
                  }}
                />
              )}
            </div>
          </div>
          {componentId === KDS_TEAM_WR_FIREBOLT && (
            <div className="info-row-section">
              <h4 className="first-line">Database table type</h4>
              <div>
                <Select
                  options={[
                    { label: 'Dimension', value: 'Dimension' },
                    { label: 'Fact', value: 'Fact' },
                  ]}
                  value={this.state.rowConfiguration.getIn(
                    ['parameters', 'table_type'],
                    'Dimension',
                  )}
                  onChange={(tableType) => {
                    return saveTableType(
                      componentId,
                      this.state.configId,
                      this.state.row,
                      tableType,
                    );
                  }}
                  disabled={this.state.readOnly}
                  clearable={false}
                  searchable={false}
                />
              </div>
            </div>
          )}
          {componentId === KDS_TEAM_WR_EXASOL && (
            <div className="info-row-section">
              <h4 className="first-line">Case sensitivity</h4>
              <div>
                <Select
                  options={[
                    { label: 'Case-sensitive', value: 'Case sensitive' },
                    { label: 'Case-insensitive', value: 'Non case sensitive' },
                  ]}
                  value={this.state.rowConfiguration.getIn(
                    ['parameters', 'caseSensitive'],
                    'Case sensitive',
                  )}
                  onChange={(caseSensitive) => {
                    return saveCaseSensitivity(
                      componentId,
                      this.state.configId,
                      this.state.row,
                      caseSensitive,
                    );
                  }}
                  disabled={this.state.readOnly}
                  clearable={false}
                  searchable={false}
                />
              </div>
            </div>
          )}
        </>
      );
    },

    renderOracleBulkSizeInput() {
      return (
        <div className="info-row-section">
          <h4 className="first-line">Bulk size</h4>
          <BulkSizeEdit
            readOnly={this.state.readOnly}
            bulkSize={this.state.rowConfiguration.getIn(
              ['parameters', 'bulkSize'],
              WR_ORACLE_DEFAULT_BULK_SIZE,
            )}
            onChange={(bulkSize) => {
              return saveBulkSize(componentId, this.state.configId, this.state.row, bulkSize);
            }}
          />
        </div>
      );
    },

    renderIncrementalSetup() {
      const isIncremental = this.state.rowConfiguration.getIn(['parameters', 'incremental'], false);
      const primaryKey = this.state.rowConfiguration.getIn(['parameters', 'primaryKey'], List());
      const tableMapping = this.state.rowConfiguration.getIn(
        ['storage', 'input', 'tables', 0],
        Map(),
      );
      const isAdaptive = tableMapping.get('changed_since') === changedSinceConstants.ADAPTIVE_VALUE;

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            {componentId === KDS_TEAM_WR_FIREBOLT ? 'Input table settings' : 'Load type'}
            {this.editIncrementalButton()}
          </h4>
          <div>
            {isIncremental
              ? isAdaptive
                ? 'Automatic incremental load'
                : 'Manual incremental load'
              : 'Full load'}
            <IncrementalSetupModal
              componentId={componentId}
              show={this.state.showIncrementalModal}
              onHide={() => this.setState({ showIncrementalModal: false })}
              currentPK={primaryKey}
              currentMapping={tableMapping}
              columns={this.getColumns()}
              isIncremental={isIncremental}
              allTables={this.state.allTables}
              onSave={(incremental, primaryKey, newMapping) => {
                return saveLoadingSetting(componentId, this.state.configId, this.state.row, {
                  incremental,
                  primaryKey,
                  newMapping,
                });
              }}
              customFieldsValues={Map()}
              supportsPrimaryKey={componentId !== KDS_TEAM_WR_FIREBOLT}
            />
            {isIncremental && isAdaptive && (
              <AutomaticLoadTypeLastUpdated tableId={this.state.tableId} />
            )}
          </div>
        </div>
      );
    },

    renderFireboltPostRunScripts() {
      if (componentId !== KDS_TEAM_WR_FIREBOLT) {
        return null;
      }

      return (
        <PostRunScripts
          readOnly={this.state.readOnly}
          data={this.state.row.getIn(['configuration', 'parameters', 'post_run_scripts'], Map())}
          onSave={(data) => {
            return savePostRunScripts(componentId, this.state.configId, this.state.row, data);
          }}
        />
      );
    },

    renderSisenseRelations() {
      if (componentId !== KEBOOLA_WR_SISENSE) {
        return null;
      }

      return (
        <SisenseRelations
          readOnly={this.state.readOnly}
          onSave={(relationships) => {
            return saveRelationship(
              componentId,
              this.state.configId,
              this.state.row,
              relationships,
            );
          }}
          columns={this.state.columns}
          relations={this.state.row.getIn(['configuration', 'parameters', 'relationships'], List())}
          otherTables={this.state.rows
            .filter((row) => row.get('id') !== this.state.rowId)
            .map((row) => row.getIn(['configuration', 'parameters'], Map()))}
        />
      );
    },

    renderPrimaryKey() {
      if (componentId === KDS_TEAM_WR_FIREBOLT) {
        return null;
      }

      const primaryKeys = this.state.rowConfiguration.getIn(['parameters', 'primaryKey'], List());

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Primary key
            {this.editIncrementalButton()}
          </h4>
          <div>{primaryKeys.join(', ') || 'N/A'}</div>
        </div>
      );
    },

    renderIndexesSetup() {
      return (
        <>
          <div className="box info-row">
            <div className="info-row-section">
              <h4 className="first-line">
                Primary index
                {this.editIndexesButton()}
              </h4>
              <div>
                {this.state.rowConfiguration
                  .getIn(['parameters', 'primaryIndex'], List())
                  .join(', ') || 'N/A'}
              </div>
            </div>
            {this.state.rowConfiguration.getIn(['parameters', 'table_type'], 'Dimension') ===
            'Fact' ? (
              <div className="info-row-section">
                <h4 className="first-line">
                  Aggregating index
                  {this.editIndexesButton()}
                </h4>
                <div>
                  {this.state.rowConfiguration
                    .getIn(['parameters', 'aggregation_indexes'], List())
                    .map((aggregationIndex) =>
                      string.strRight(aggregationIndex.get('index_name', ''), 'kbc_'),
                    )
                    .join(', ') || 'N/A'}
                </div>
              </div>
            ) : (
              <div className="info-row-section">
                <h4 className="first-line">
                  Join index
                  {this.editIndexesButton()}
                </h4>
                <div>
                  {this.state.rowConfiguration
                    .getIn(['parameters', 'join_indexes'], List())
                    .map((joinIndex) => string.strRight(joinIndex.get('index_name', ''), 'kbc_'))
                    .join(', ') || 'N/A'}
                </div>
              </div>
            )}
          </div>
          <IndexesSetupModal
            show={this.state.showIndexesModal}
            onHide={() => this.setState({ showIndexesModal: false })}
            onSave={(newIndexes) => {
              return saveIndexesSetting(
                componentId,
                this.state.configId,
                this.state.row,
                newIndexes,
              );
            }}
            columns={this.getColumns()
              .map((column) => ({ label: column, value: column }))
              .toJS()}
            configurationParameters={this.state.rowConfiguration.get('parameters')}
          />
        </>
      );
    },

    renderFireboltTableSetup() {
      return (
        <div className="info-row-section">
          <h4 className="first-line">Firebolt table load type</h4>
          <div>
            <Select
              options={[
                { label: 'Full load', value: 'Full load' },
                { label: 'Incremental load', value: 'Incremental load' },
                { label: 'Delete from', value: 'Delete from' },
              ]}
              value={this.state.rowConfiguration.getIn(
                ['parameters', 'loading_options', 'load_type'],
                'Full load',
              )}
              onChange={(loadingOption) => {
                return saveFireboltTableLoad(
                  componentId,
                  this.state.configId,
                  this.state.row,
                  loadingOption,
                );
              }}
              disabled={this.state.readOnly}
              clearable={false}
              searchable={false}
            />
          </div>
        </div>
      );
    },

    renderTableFiltersRow() {
      const tableMapping = this.state.rowConfiguration.getIn(
        ['storage', 'input', 'tables', 0],
        Map(),
      );

      return (
        <div className="info-row-section">
          <h4 className="first-line">
            Data filter
            {this.editIncrementalButton()}
          </h4>
          <div>
            <FiltersDescription value={tableMapping} rootClassName="" />
          </div>
        </div>
      );
    },

    renderEditButton(statePath) {
      if (this.state.readOnly) {
        return null;
      }

      return (
        <IconButton
          icon="pen"
          variant="inline"
          className="tw-ml-2.5 tw-align-middle"
          disabled={this.state.hasChanges}
          onClick={() => this.setState({ [statePath]: true })}
        />
      );
    },

    editIncrementalButton() {
      return this.renderEditButton('showIncrementalModal');
    },

    editIndexesButton() {
      return this.renderEditButton('showIndexesModal');
    },

    renderPrimaryKeyMismatch() {
      const columns = this.state.columns.map((column) => column.get('dbName'));
      const validPrimaryKey = this.state.rowConfiguration
        .getIn(['parameters', 'primaryKey'], List())
        .every((primaryKey) => columns.includes(primaryKey));

      if (validPrimaryKey) {
        return null;
      }

      return (
        <Alert variant="warning" className="tw-mb-5">
          The primary key is set to a non-existing column(s). Please update the primary key
          settings.
        </Alert>
      );
    },

    getColumns() {
      return this.state.columns
        .filter((column) => {
          if (DISABLED_NULLABLE.includes(componentId)) {
            return column.get('nullable') === false;
          }

          return true;
        })
        .map((column) => column.get('dbName'));
    },

    handleDelete() {
      return deleteRow(componentId, this.state.configId, this.state.row, true);
    },

    handleActivateDeactivate() {
      return toggleRow(componentId, this.state.configId, this.state.row);
    },

    handleEditColumn(newColumn) {
      this.validateColumn(newColumn);
      return editRowColumn(componentId, this.state.configId, this.state.rowId, newColumn);
    },

    handleEditColumnsSave() {
      const columns = this.state.columns.map((column) =>
        this.state.editingColumns.get(column.get('name')),
      );
      return saveRowColumns(
        componentId,
        this.state.configId,
        this.state.row,
        this.state.editing,
        columns,
      ).then(this.handleEditColumnsCancel);
    },

    handleEditColumnsCancel() {
      this.setState({ columnsValidation: Map() });
      resetRow(componentId, this.state.configId, this.state.rowId);
    },

    validateColumn(column) {
      const type = column.get('type');
      const haveSize = getSizeParam(componentId, type);
      const isValid = !haveSize || columnValidation.validate(type, column.get('size'));

      this.setState((state) => ({
        columnsValidation: state.columnsValidation.set(column.get('name'), isValid),
      }));
    },
  });
};

export default Row;
