import type {
  CSSProperties,
  KeyboardEvent,
  KeyboardEventHandler,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
} from 'react';
import { NavLink } from 'react-router';

import { cn } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareProductionHref } from '@/modules/dev-branches/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import { createPath } from '@/utils/router/createPath';
import { createUrlWithBasename } from '@/utils/router/createUrl';

type Props = {
  to?: string;
  params?: Record<string, any>;
  query?: Record<string, any>;
  hash?: string;
  className?: string;
  title?: string;
  style?: CSSProperties;
  activeClassName?: string;
  isActive?: boolean;
  onClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  onlyActiveOnIndex?: boolean;
  role?: string;
  tabIndex?: number;
  children?: ReactNode;
  disabled?: boolean;
  'data-disabled'?: boolean;
  forceProduction?: boolean;
  openInNewTab?: boolean;
};

export const RouterLink = (props: Props) => {
  const isDisabled = props.disabled || props?.['data-disabled'];

  if (isDisabled) {
    return (
      <span
        className={props.className}
        title={props.title}
        style={props.style}
        data-disabled={isDisabled}
      >
        {props.children}
      </span>
    );
  }

  const commonProps = {
    title: props.title,
    style: props.style,
    className: props.className,
    onClick: (e: MouseEvent) => {
      e.stopPropagation();
      props.onClick?.(e);
    },
    onKeyDown: (e: KeyboardEvent) => {
      if (e.key === keyCodes.ENTER) {
        e.stopPropagation();
      }

      if (props.onKeyDown) {
        props.onKeyDown(e);
      }
    },
    role: props.role,
    tabIndex: props.tabIndex,
  };
  const blankPageProps = { target: '_blank', rel: 'noreferrer' };

  if (props.forceProduction && DevBranchesStore.isDevModeActive()) {
    return (
      <a
        {...commonProps}
        {...blankPageProps}
        href={prepareProductionHref(
          ApplicationStore.getProjectBaseUrl(),
          createUrlWithBasename(props.to ?? '', props.params, props.query),
          DevBranchesStore.getCurrentId(),
        )}
      >
        {props.children}
      </a>
    );
  }

  return (
    <NavLink
      {...commonProps}
      to={createPath(props.to ?? '', props.params, props.query, props.hash)}
      className={({ isActive }) => {
        return cn(commonProps.className, {
          [props.activeClassName ?? 'active']: isActive || props.isActive,
        });
      }}
      end={props.onlyActiveOnIndex ?? false}
      {...(props.openInNewTab && blankPageProps)}
    >
      {props.children}
    </NavLink>
  );
};
