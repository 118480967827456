import { Outlet } from 'react-router';

import { APP_ROUTE } from '@/constants/routeNames';
import NewAutomation from '@/modules/automations/NewAutomation';
import useStores from '@/react/hooks/useStores';
import ErrorPage from '@/react/pages/ErrorPage';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { ErrorBoundaryWithNotification } from './ErrorBoundaryWithNotification';
import PageTitle from './PageTitle';

export const MainContainer = () => {
  const store = useStores(
    () => {
      return {
        isError: RoutesStore.isError(),
        hasAiAutomations: ApplicationStore.hasAiAutomations(),
        readOnly: ApplicationStore.isReadOnly(),
        currentRouteName: RoutesStore.getCurrentRouteConfig().get('name'),
      };
    },
    [],
    [RoutesStore, ApplicationStore],
  );

  return (
    <ErrorBoundaryWithNotification>
      {store.currentRouteName === APP_ROUTE && store.hasAiAutomations && !store.readOnly && (
        <div className="container">
          <h1 className="tw-mb-3 tw-mt-12">Generate your data pipeline with AI Flow Builder</h1>
          <NewAutomation
            subtitle="Share your data source, destination, and any transformations needed for the best assistance!"
            showExamples
          />
          <hr className="tw-mb-6 tw-mt-12" />
        </div>
      )}
      <PageTitle />
      <div className="container main-container">{store.isError ? <ErrorPage /> : <Outlet />}</div>
    </ErrorBoundaryWithNotification>
  );
};
