import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import { getTelemetryUrl } from './helpers';

type WorkspaceData = { userToken: string; workspaceId: string; dashboardId: string };

const createRequest = (path: string) => {
  return request('POST', `${getTelemetryUrl()}/${path}`).set(
    'X-StorageApi-Token',
    ApplicationStore.getSapiTokenString(),
  );
};

const createCredentials = (params = {}) => {
  return createRequest('provisioning/workspace')
    .send({ project_type: ApplicationStore.getCurrentProject().get('type'), ...params })
    .promise()
    .then((response) => response.body);
};

export { createCredentials, type WorkspaceData };
