import { Map } from 'immutable';

import { KEBOOLA_FLOW, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import dayjs from '@/date';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';

export const isFlowComponent = (componentId: string) => {
  return [KEBOOLA_ORCHESTRATOR, KEBOOLA_FLOW].includes(componentId);
};

/** Ensures that `tasks` contains at least some tasks are configured and enabled */
export const shouldAllowRunFlow = (tasks: Map<string, any>) => {
  return tasks.some(
    (task: Map<string, any>) => !!task.getIn(['task', 'configId']) && task.get('enabled', true),
  );
};

/** Returns true if `job` was run with the current version of `config` */
export const jobVersionMatch = (config: Map<string, any>, job: Map<string, any>) => {
  const jobStartTime = dayjs(job.getIn(['startTime']));
  const currentVersionCreatedTime = dayjs(config.getIn(['currentVersion', 'created']));
  return currentVersionCreatedTime.isBefore(jobStartTime);
};

export const getLocalStateValue = (configId: string, path: string[], defaultValue: any) => {
  return InstalledComponentsStore.getLocalState(KEBOOLA_FLOW, configId).getIn(path, defaultValue);
};

export const updateLocalStateValue = (configId: string, path: string[], data: any) => {
  InstalledComponentsActionCreators.updateLocalState(
    KEBOOLA_FLOW,
    configId,
    InstalledComponentsStore.getLocalState(KEBOOLA_FLOW, configId).setIn(path, data),
  );
};

export const clearLocalState = (configId: string) => {
  InstalledComponentsActionCreators.updateLocalState(KEBOOLA_FLOW, configId, Map());
};

export const prepareFlow = (flow: Map<string, any>, schedulers: Map<string, any>) => {
  return flow.set(
    'schedulers',
    schedulers.filter((scheduler) => {
      return (
        scheduler.getIn(['configuration', 'target', 'componentId']) === KEBOOLA_FLOW &&
        scheduler.getIn(['configuration', 'target', 'configurationId']) === flow.get('id')
      );
    }),
  );
};

export const prepareFlows = (flows: Map<string, any>, schedulers: Map<string, any>) => {
  return flows.map((flow) => prepareFlow(flow, schedulers)) as Map<string, any>;
};
