import {
  BaseEdge,
  type Edge,
  EdgeLabelRenderer,
  type EdgeProps,
  getSmoothStepPath,
} from '@xyflow/react';

import { cn } from '@keboola/design';

import { AddButton } from '@/modules/flows-v2/builder/components/AddButton';
import { ARROW_OFFSET } from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';

const ADD_BUTTON_OFFSET = 50;

export const EdgeWithButton = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  style,
}: EdgeProps<Edge>) => {
  const [selectedPhaseId, selectedAddButton] = useBuilderStore((state) => [
    state.selectedPhaseId,
    state.selectedAddButton,
  ]);

  // Calculate the edge path
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: targetY - ARROW_OFFSET,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={style} markerEnd={markerEnd} />
      <EdgeLabelRenderer>
        <div
          className="nodrag nopan tw-absolute"
          style={{
            transform: `translate(-50%, -50%) translate(${targetX}px,${targetY - ADD_BUTTON_OFFSET}px)`,
          }}
        >
          <div className="tw-group/edge tw-pointer-events-auto tw-relative tw-z-10 tw-px-12 tw-py-4">
            <AddButton
              id={id}
              className={cn('tw-opacity-0 tw-transition-opacity group-hover/edge:tw-opacity-100', {
                'tw-opacity-100': selectedPhaseId === id || selectedAddButton === id,
              })}
            />
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};
