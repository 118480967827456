const roles = {
  USER: 'user',
  AGENT: 'agent',
} as const;

const ERROR_MESSAGE_PREFIX = 'It seems the configuration failed with error:';

const PRODUCT_FRUITS_SURVEY_IDS = {
  AUTOMATION_PUBLISHED: '6e416e9e-19fc-4791-8472-fd5cb1e91eb2',
  AUTOMATION_LEFT_UNPUBLISHED: '55c5b6bf-33bf-4353-b620-176a42a568c7',
} as const;

export { roles, ERROR_MESSAGE_PREFIX, PRODUCT_FRUITS_SURVEY_IDS };
