import parseTransformation from './parseTransformation';

export default function (bucket) {
  // deep cloning hack
  let response = JSON.parse(JSON.stringify(bucket));
  response.transformations = response.configuration?.parameters?.transformations;
  delete response.rows;
  if (response.transformations) {
    for (let i = 0; i < response.transformations.length; i++) {
      response.transformations[i] = parseTransformation(response.transformations[i]);
    }
  } else {
    response.transformations = [];
  }

  return response;
}
