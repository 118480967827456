import { useState } from 'react';
import type BluebirdPromise from 'bluebird';
import { List, Map } from 'immutable';

import { Badge, Icon, Search } from '@keboola/design';

import { isInternal } from '@/constants/helpers';
import { canManageBuckets, canManageTokens } from '@/modules/admin/privileges';
import { routeNames } from '@/modules/settings/constants';
import { RefreshTokenModal } from '@/modules/tokens/react/modals/RefreshTokenModal';
import { SendTokenModal } from '@/modules/tokens/react/modals/SendTokenModal';
import { CreatedDate, RouterLink, RowActionDropdown, RowActionMenuItem } from '@/react/common';
import Check from '@/react/common/Check';
import ConfirmMenuItem from '@/react/common/ConfirmMenuItem';
import LazyList from '@/react/common/LazyList';
import Loader from '@/react/common/Loader';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';
import string from '@/utils/string';

const renderComponentsAccess = (token: Map<string, any>) => {
  if (canManageBuckets(token)) {
    return 'All components';
  }

  const accessCnt = token.get('componentAccess', List()).count();

  if (!accessCnt) {
    return 'None';
  }

  return `${accessCnt} ${string.pluralize(accessCnt, 'component')}`;
};

const renderBucketsAccess = (token: Map<string, any>) => {
  if (canManageBuckets(token)) {
    return 'All buckets';
  }

  const accessCnt = token.get('bucketPermissions', List()).count();

  if (accessCnt === 0) {
    return 'None';
  }

  return `${accessCnt} ${string.pluralize(accessCnt, 'bucket')}`;
};

const renderMasterLabel = (token: Map<string, any>) => {
  if (!token.get('isMasterToken', false)) {
    return null;
  }

  return <Badge text="Master" variant="green" placement="right" />;
};

type Props = {
  tokens: List<Map<string, any>>;
  sapiToken: Map<string, any>;
  onDeleteFn: (token: Map<string, any>) => BluebirdPromise<unknown>;
  isDeletingFn: (token: Map<string, any>) => boolean;
  onSendTokenFn: (
    token: Map<string, any>,
    params: { email: string; message: string },
  ) => BluebirdPromise<void>;
  isSendingTokenFn: (tokenId: string) => boolean;
  onRefreshFn: (token: Map<string, any>) => BluebirdPromise<Map<string, any>>;
  isRefreshingFn: (token: Map<string, any>) => boolean;
  hasNewQueue: boolean;
};

export const TokensTable = (props: Props) => {
  const [searchQuery, setSearchQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );
  const [sendToken, setSendToken] = useState<Map<string, any>>(Map());
  const [refreshToken, setRefreshToken] = useState<Map<string, any>>(Map());

  const isYoursToken = (token: Map<string, any>) => {
    return token.get('id') === props.sapiToken.get('id');
  };

  const filteredTokens = props.tokens
    .filter((token) => matchByWords(token!.get('description'), searchQuery))
    .sortBy((token) => token!.get('description').toLowerCase())
    .sortBy((token) => (isYoursToken(token!) ? -1 : 0));

  const renderYoursLabel = (token: Map<string, any>) => {
    if (!isYoursToken(token)) {
      return null;
    }

    return <Badge text="Yours" variant="blue" placement="right" />;
  };

  const renderTokenDelete = (token: Map<string, any>) => {
    const isDeleting = props.isDeletingFn(token);

    return (
      <ConfirmMenuItem
        icon="trash"
        title="Delete Token"
        text={`Are you sure you want to delete the token ${token.get('description')} (${token.get(
          'id',
        )})?`}
        buttonLabel="Delete"
        onConfirm={() => props.onDeleteFn(token)}
        isDisabled={isDeleting}
      >
        {isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
        Delete token
      </ConfirmMenuItem>
    );
  };

  const renderTokenSendButton = (token: Map<string, any>) => {
    if (token.get('isMasterToken', false)) {
      return null;
    }

    const isSending = props.isSendingTokenFn(token.get('id'));

    return (
      <RowActionMenuItem onSelect={() => setSendToken(token)} disabled={isSending}>
        {isSending ? <Loader /> : <Icon icon="share" fixedWidth />}
        Send via email
      </RowActionMenuItem>
    );
  };

  const renderTokenRefreshButton = (token: Map<string, any>) => {
    const isRefreshing = props.isRefreshingFn(token);

    return (
      <RowActionMenuItem onSelect={() => setRefreshToken(token)} disabled={isRefreshing}>
        {isRefreshing ? <Loader /> : <Icon icon="arrows-rotate" fixedWidth />}
        Refresh token
      </RowActionMenuItem>
    );
  };

  const showLoading = (token: Map<string, any>) => {
    const isSending = props.isSendingTokenFn(token.get('id'));
    const isRefreshing = props.isRefreshingFn(token);
    const isDeleting = props.isDeletingFn(token);
    return isSending || isRefreshing || isDeleting;
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <RefreshTokenModal
        token={refreshToken}
        show={!!refreshToken.get('id')}
        onHideFn={() => setRefreshToken(Map())}
        onRefreshFn={() => props.onRefreshFn(refreshToken)}
        isRefreshing={props.isRefreshingFn(refreshToken)}
        hasNewQueue={props.hasNewQueue}
        sapiToken={props.sapiToken}
      />
      <SendTokenModal
        token={sendToken}
        show={!!sendToken.get('id')}
        onHideFn={() => setSendToken(Map())}
        onSendFn={(params) => props.onSendTokenFn(sendToken, params)}
        isSending={props.isSendingTokenFn(sendToken.get('id'))}
      />
      <Search
        placeholder="Search tokens"
        defaultValue={searchQuery}
        onChange={(searchQuery) => {
          setSearchQuery(searchQuery);
          RoutesStore.getRouter().updateQuery({ q: searchQuery });
        }}
      />
      <div className="box">
        <div className="table table-hover">
          <div className="thead is-sticky bg-color-white">
            <div className="tr">
              <div className="th">Description</div>
              <div className="th">Created</div>
              <div className="th">Refreshed</div>
              <div className="th">Expires</div>
              <div className="th">Files</div>
              <div className="th">Components</div>
              <div className="th">Buckets</div>
              <div className="th">Tokens</div>
            </div>
          </div>
          <LazyList
            className="tbody"
            items={filteredTokens}
            render={(tokens) =>
              tokens.map((tokenImmutable) => {
                const token = tokenImmutable as Map<string, any>;
                return (
                  <RouterLink
                    to={routeNames.TOKEN_DETAIL}
                    params={{ tokenId: token.get('id') }}
                    className="tr hoverable-actions-with-replacement"
                    key={token.get('id')}
                  >
                    <div className="td">
                      {token.get('description')} {renderYoursLabel(token)}
                      {renderMasterLabel(token)}
                    </div>
                    <div className="td">
                      <CreatedDate createdTime={token.get('created')} />
                    </div>
                    <div className="td">
                      <CreatedDate createdTime={token.get('refreshed')} />
                    </div>
                    <div className="td">
                      <CreatedDate createdTime={token.get('expires')} fallback="Never" />
                    </div>
                    <div className="td">
                      {token.get('canReadAllFileUploads') ? 'All files' : 'Own files'}
                    </div>
                    <div className="td">{renderComponentsAccess(token)}</div>
                    <div className="td">{renderBucketsAccess(token)}</div>
                    <div className="td">
                      <div className="actions-container">
                        <div className="not-actions">
                          <Check isChecked={canManageTokens(token)} />
                        </div>
                        <div className="actions">
                          <RowActionDropdown showLoading={showLoading(token)}>
                            {renderTokenSendButton(token)}
                            {!isInternal(token.get('description')) && (
                              <>
                                {renderTokenRefreshButton(token)}
                                {!token.has('admin') && (
                                  <>
                                    <RowActionMenuItem divider />
                                    {renderTokenDelete(token)}
                                  </>
                                )}
                              </>
                            )}
                          </RowActionDropdown>
                        </div>
                      </div>
                    </div>
                  </RouterLink>
                );
              })
            }
          />
        </div>
      </div>
    </div>
  );
};
