import { useMemo, useState } from 'react';
import type { List, Map } from 'immutable';

import { Alert, Tabs, TabsContent } from '@keboola/design';

import { isInternal } from '@/constants/helpers';
import { canManageTokens } from '@/modules/admin/privileges';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import Events from '@/modules/sapi-events/react/Events';
import { updateLocalState, updateToken } from '@/modules/tokens/actionCreators';
import TokenEditor from '@/modules/tokens/react/components/tokenEditor/TokenEditor';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import createTokenEventsApi from '@/modules/tokens/TokenEventsApi';
import SaveButtons from '@/react/common/SaveButtons';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

export const Detail = () => {
  const store = useStores(
    () => {
      const localState = TokensStore.localState() as Map<string, any>;
      const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
      const token = (TokensStore.getAll() as List<any>).find((t) => t.get('id') === tokenId);

      return {
        localState,
        tokenId,
        token,
        admins: ApplicationStore.getAdmins(),
        editedToken: localState.getIn(['editedToken', tokenId], token),
        allComponents: ComponentsStore.getAll() as Map<string, any>,
        allBuckets: BucketsStore.getAll(),
        sapiToken: ApplicationStore.getSapiToken(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
      };
    },
    [],
    [ApplicationStore, TokensStore, BucketsStore, ComponentsStore],
  );

  const [isSaving, setIsSaving] = useState(false);
  const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
  const eventsApi = useMemo(() => createTokenEventsApi(tokenId), [tokenId]);

  const isValid = !!(
    store.editedToken.get('description') && store.editedToken.get('expiresIn') !== 0
  );

  const resetEditedToken = () => {
    const updatedLocalState = store.localState.deleteIn(['editedToken', store.tokenId]);
    updateLocalState(updatedLocalState);
  };

  const handleUpdateToken = (newToken: Map<string, any>) => {
    const updatedLocalState = store.localState.setIn(['editedToken', store.tokenId], newToken);
    updateLocalState(updatedLocalState);
  };

  const handleSave = () => {
    setIsSaving(true);
    return updateToken(store.tokenId, store.editedToken.toJS()).finally(() => {
      setIsSaving(false);
      resetEditedToken();
    });
  };

  return store.token ? (
    <Tabs
      triggers={[
        { title: 'Overview', value: 'overview' },
        { title: 'Events', value: 'events' },
      ]}
    >
      <TabsContent value="overview">
        <div className="box p-1 form form-horizontal">
          {isInternal(store.token.get('description')) && (
            <Alert title="Internal token" className="tw-mb-5">
              Note that internal tokens cannot be modified by users in the UI, as doing so may cause
              issues with the product&apos;s functionality. However, it may be updated via API if
              necessary.
            </Alert>
          )}
          <div className="save-buttons">
            <SaveButtons
              isSaving={isSaving}
              disabled={!isValid || !canManageTokens(store.sapiToken)}
              isChanged={!store.token.equals(store.editedToken)}
              onSave={handleSave}
              onReset={resetEditedToken}
            />
          </div>
          <TokenEditor
            isEditing
            disabled={
              isSaving ||
              !canManageTokens(store.sapiToken) ||
              isInternal(store.token.get('description'))
            }
            token={store.editedToken}
            sapiToken={store.sapiToken}
            allBuckets={store.allBuckets}
            allComponents={store.allComponents}
            updateToken={handleUpdateToken}
            hasNewQueue={store.hasNewQueue}
          />
        </div>
      </TabsContent>
      <TabsContent value="events">
        <Events autoReload eventsApi={eventsApi} admins={store.admins} />
      </TabsContent>
    </Tabs>
  ) : (
    <p>Token {store.tokenId} does not exist or has been removed.</p>
  );
};
