import { cn, IconButton, Tooltip } from '@keboola/design';

const COMMON_CLASSES = 'actions task-actions tw-z-[1]';

type Props = {
  onOpen: () => void;
  onDelete: () => void;
  onExploreData?: () => void;
  hasData: boolean;
  readOnly: boolean;
};

const TaskActions = (props: Props) => {
  const showExploreData = props.onExploreData && props.hasData;

  if (props.readOnly && !showExploreData) return null;

  if (props.readOnly && showExploreData) {
    return (
      <span
        className={cn(COMMON_CLASSES, '!tw-opacity-100')}
        onMouseDown={(e) => e.stopPropagation()}
      >
        <Tooltip placement="top" tooltip="Explore Data">
          <IconButton
            variant="inline"
            className="circle-button tw-flex"
            onClick={props.onExploreData}
            icon="warehouse"
          />
        </Tooltip>
      </span>
    );
  }

  return (
    <span className={COMMON_CLASSES} onMouseDown={(e) => e.stopPropagation()}>
      <Tooltip placement="top" tooltip="Delete Task">
        <IconButton
          variant="invisible"
          className="circle-button tw-flex"
          onClick={props.onDelete}
          icon="trash"
        />
      </Tooltip>
      {showExploreData && (
        <Tooltip placement="top" tooltip="Explore Data">
          <IconButton
            variant="invisible"
            className="circle-button tw-flex"
            onClick={props.onExploreData}
            icon="warehouse"
          />
        </Tooltip>
      )}
      <Tooltip placement="top" tooltip="Show Details">
        <IconButton
          variant="invisible"
          className="circle-button tw-flex"
          onClick={props.onOpen}
          icon="gear"
        />
      </Tooltip>
    </span>
  );
};

export default TaskActions;
