import { useMemo } from 'react';

import { KEBOOLA_FLOW } from '@/constants/componentIds';
import { findTemplateInstanceIdFromMetadata } from '@/modules/components/helpers';
import Notifications from '@/modules/notifications/components/Notifications';
import Schedules from '@/modules/scheduler/components/Schedules';
import { activeSchedules } from '@/modules/scheduler/helpers';
import { routeNames as templatesRouteNames } from '@/modules/templates/constants';
import { RouterLink } from '@/react/common';
import FlowActions from './components/FlowActions';
import Tabs from './components/Tabs';
import useFlow from './hooks/useFlow';
import { Builder } from './builder';
import { tabs } from './constants';
import Versions from './Versions';

const Detail = () => {
  const store = useFlow();

  const templateInstanceId = useMemo(() => {
    return findTemplateInstanceIdFromMetadata(
      KEBOOLA_FLOW,
      store.configId,
      store.componentsMetadata,
    );
  }, [store.configId, store.componentsMetadata]);

  if (!store.configId) return;

  const renderTabContent = () => {
    if (store.activeTab === tabs.SCHEDULES) {
      return (
        <div className="mt-2">
          <Schedules
            configId={store.configId}
            componentId={KEBOOLA_FLOW}
            tables={store.tables}
            buckets={store.buckets}
            triggers={store.triggers}
            schedulers={store.flow.get('schedulers')}
            hasDataStreams={store.hasDataStreams}
            isDevModeActive={store.isDevModeActive}
            canManageTriggers={store.canManageTriggers}
            canManageSchedule={store.canManageSchedule}
            hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
            dataStreamSources={store.dataStreamSources}
          />
        </div>
      );
    }

    if (store.activeTab === tabs.NOTIFICATIONS) {
      return (
        <div className="mt-1">
          <Notifications
            configId={store.configId}
            componentId={KEBOOLA_FLOW}
            notifications={store.notifications}
            allNotifications={store.allNotifications}
            admins={store.admins}
            readOnly={!store.canSetupNotifications}
          />
        </div>
      );
    }

    if (store.activeTab === tabs.VERSIONS) {
      return (
        <div className="mt-2">
          <Versions />
        </div>
      );
    }

    return (
      <Builder
        config={store.config}
        configId={store.configId}
        isChanged={store.isChanged}
        tasks={store.tasks}
        phases={store.phases}
        readOnly={store.readOnly}
        folders={store.folders}
        allComponents={store.allComponents}
        tablesMetadataMap={store.tablesMetadataMap}
        patternComponents={store.allowedPatternComponents}
        configurations={store.configurations}
        deletedComponents={store.deletedComponents}
        hasDataApps={store.hasDataApps}
        hasPayAsYouGo={store.hasPayAsYouGo}
        isDevModeActive={store.isDevModeActive}
        showBackendSize={store.showBackendSize}
        hasSnowflakePartnerConnectLimited={store.hasSnowflakePartnerConnectLimited}
        hasSnowflakeDynamicBackendSize={store.hasSnowflakeDynamicBackendSize}
        hasJobsDynamicBackendSize={store.hasJobsDynamicBackendSize}
      />
    );
  };

  return (
    <>
      <div className="tabs-with-border-wrapper flex-container flex-nowrap align-top">
        <Tabs
          configId={store.configId}
          activeTab={store.activeTab}
          activeSchedules={activeSchedules(store.triggers, store.flow.get('schedulers'))}
        />
        <div className="flow-navigation-controls flex-container flex-end">
          {templateInstanceId && (
            <div className="navigation-control">
              <span className="text-muted">Created from </span>
              <RouterLink
                to={templatesRouteNames.INSTANCE_DETAIL}
                params={{ instanceId: templateInstanceId }}
                className="font-medium"
              >
                Template
              </RouterLink>
            </div>
          )}
          <FlowActions
            flow={store.flow}
            config={store.config}
            component={store.component}
            otherFlows={store.otherFlows}
            readOnly={store.readOnly}
          />
        </div>
      </div>
      {renderTabContent()}
    </>
  );
};

export default Detail;
