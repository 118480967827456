import { KeboolaHttpHeader } from '../../constants';
import { createCallbackMiddleware, createOpenapiFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';
import type { GetSandboxPath } from './types';

export const createSandboxesClient = ({ baseUrl, callbacks, token }: ClientInitOptions) => {
  const client = createOpenapiFetchClient<paths>({
    baseUrl: baseUrl,
    middlewares: [createCallbackMiddleware(callbacks)],
    headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
  });

  const getSandbox = async (id: GetSandboxPath['id'], signal?: AbortSignal) => {
    const { data } = await client.get(
      '/sandboxes/{id}',
      {
        path: { id },
      },
      { signal },
    );
    return data;
  };

  return {
    getSandbox,
  };
};
