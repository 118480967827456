import { useState } from 'react';
import { List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { SHARES_TYPES } from '@/constants';
import { canExportTable, canManageBucket, isSharingAvailable } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import ShareModal from '@/modules/data-catalog/react/ShareModal';
import { deleteBucket, exportTables } from '@/modules/storage/actions';
import { getBucketShareTooltipText, isBucketShareDisabled } from '@/modules/storage/helpers';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import { ShareBucketIcon } from '@/react/common/icons';
import Loader from '@/react/common/Loader';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import DeleteBucketModal from './DeleteBucketModal';
import DeleteUnlinkBucketMenuItem from './DeleteUnlinkBucketMenuItem';
import ExportModal from './ExportModal';
import RefreshExternalBucketMenuItem from './RefreshExternalBucketMenuItem';

const BucketActions = ({ bucket }: { bucket: Map<string, any> }) => {
  const bucketId = bucket.get('id');

  const state = useStores(
    () => {
      const sapiToken = ApplicationStore.getSapiToken();
      const allBuckets = BucketsStore.getAll() as Map<string, any>;
      const allTables = TablesStore.getAll() as Map<string, any>;
      const availableUsersOptions = ApplicationStore.getSharingToAdminsData();
      const availableProjectsOptions = ApplicationStore.getSharingToProjectsData();
      const tables = allTables.filter((table) => table.getIn(['bucket', 'id']) === bucketId) as Map<
        string,
        any
      >;

      return {
        tables,
        sapiToken,
        allBuckets,
        allTables,
        availableUsersOptions,
        availableProjectsOptions,
        readOnly: ApplicationStore.isReadOnly(),
        canExportTable: canExportTable(sapiToken),
        exportingTables: TablesStore.getExportingTables(),
        deletingBucket: BucketsStore.deletingBuckets(bucketId),
      };
    },
    [bucketId],
    [ApplicationStore, TablesStore, BucketsStore],
  );

  const [isExportModalOpen, setExportModalOpen] = useState(false);
  const [isDeleteBucketModalOpen, setIsDeleteBucketModalOpen] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false);

  const handleDeleteBucket = () => {
    return deleteBucket(bucket.get('id'), {
      forceDelete: state.tables.count() > 0,
      transition: true,
    });
  };

  if (
    !state.tables.isEmpty() &&
    state.tables.every((table, tableId) => state.exportingTables.get(tableId))
  ) {
    return (
      <span className="text-muted line-height-40">
        <Loader className="icon-addon-right" />
        Preparing export
      </span>
    );
  }

  if (state.readOnly) {
    return null;
  }

  return (
    <>
      <RowActionDropdown toggleClassName="in-navigation">
        {state.canExportTable && (
          <RowActionMenuItem
            disabled={state.tables.isEmpty()}
            disabledReason="No tables in the bucket."
            onSelect={() => setExportModalOpen(true)}
          >
            <Icon fixedWidth icon="down-to-line" />
            Export bucket
          </RowActionMenuItem>
        )}
        {isSharingAvailable(state.sapiToken) && (
          <RowActionMenuItem
            disabled={isBucketShareDisabled(bucket)}
            disabledReason={getBucketShareTooltipText(bucket)}
            onSelect={() => setShareModalOpen(true)}
          >
            <ShareBucketIcon />
            Share bucket
          </RowActionMenuItem>
        )}
        <RefreshExternalBucketMenuItem
          bucket={bucket}
          readOnly={!canManageBucket(state.sapiToken, bucket)}
        />
        <RowActionMenuItem divider />
        <DeleteUnlinkBucketMenuItem
          bucket={bucket}
          token={state.sapiToken}
          onSelect={() => setIsDeleteBucketModalOpen(true)}
          linkedBucketsCount={bucket.get('linkedBy', Map()).count()}
          isLinked={bucket.has('sourceBucket')}
        />
      </RowActionDropdown>
      <ExportModal
        show={isExportModalOpen}
        tables={List([state.tables])}
        onSubmit={(type) => exportTables(state.tables, type)}
        onHide={() => setExportModalOpen(false)}
      />
      <DeleteBucketModal
        show={isDeleteBucketModalOpen}
        bucket={bucket}
        tables={state.tables}
        deleting={state.deletingBucket}
        onConfirm={handleDeleteBucket}
        onHide={() => setIsDeleteBucketModalOpen(false)}
      />
      <ShareModal
        showModal={isShareModalOpen}
        onHide={() => setShareModalOpen(false)}
        sapiToken={state.sapiToken}
        availableUsersOptions={state.availableUsersOptions}
        availableProjectsOptions={state.availableProjectsOptions}
        allBuckets={state.allBuckets}
        allTables={state.allTables}
        defaultSelectedBucket={bucket}
        modalStep={SHARES_TYPES.EXISTING_BUCKET}
      />
    </>
  );
};

export default BucketActions;
