import { Component } from 'react';
import { Map } from 'immutable';

import { CollapsiblePanel } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { getTableAliases, getTableLinks, tableName } from '@/modules/storage/helpers';
import { Truncated } from '@/react/common';
import ConfirmModal from '@/react/common/ConfirmModal';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import { TriggersStorageWarning } from './TriggersStorageWarning';

type Props = {
  sapiToken: Map<string, any>;
  allTables: Map<string, any>;
  allBuckets: Map<string, any>;
  show: boolean;
  isLoading: boolean;
  selected: Map<string, any>[];
  onSubmit: () => Promise<unknown>;
  onHide: () => void;
};
class DeleteBucketsAndTablesModal extends Component<Props> {
  render() {
    const buckets = this.props.selected.filter(
      (row) => row.has('stage') && !row.has('sourceBucket'),
    );
    const linkedBuckets = this.props.selected.filter(
      (row) => row.has('stage') && row.has('sourceBucket'),
    );
    const bucketKeys = buckets
      .map((bucket) => bucket.get('id'))
      .concat(linkedBuckets.map((bucket) => bucket.get('id')));
    const tables = this.props.selected.filter(
      (row) => !row.has('stage') && !bucketKeys.includes(row.getIn(['bucket', 'id'])),
    );
    const tablesAliases = tables.reduce<Map<string, any>[]>(
      (tableAliases, table) => [
        ...tableAliases,
        ...getTableAliases(table, this.props.allTables, this.props.sapiToken),
      ],
      [],
    );
    const tablesLinks = tables.reduce<Map<string, any>[]>(
      (tableLinks, table) => [
        ...tableLinks,
        ...getTableLinks(table, this.props.allBuckets.get(table.getIn(['bucket', 'id']), Map())),
      ],
      [],
    );

    const renderCollapsiblePanel = (
      header: string,
      rows: Map<string, any>[],
      getValue: (row: Map<string, any>) => string,
    ) => {
      if (rows.length === 0) {
        return null;
      }

      return (
        <CollapsiblePanel defaultExpanded header={header} className="tw-my-4" bodyClassName="p-0">
          <table className="table table-hover">
            <tbody>
              {rows.map((row: Map<string, any>) => (
                <tr key={row.get('id')}>
                  <td className="!tw-px-4">
                    <Truncated text={getValue(row)} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </CollapsiblePanel>
      );
    };

    return (
      <ConfirmModal
        closeAfterResolve
        show={this.props.show}
        onHide={this.props.onHide}
        icon="trash"
        buttonType="danger"
        buttonLabel="Delete selected"
        title="Delete selected"
        text={
          <>
            <p>Are you sure you want to delete the selected items?</p>
            <DevBranchStorageWarning
              message="The buckets and tables will also be deleted in production."
              hasProductionEntity={
                buckets.some((bucket) => !isCreatedInDevBranch(bucket)) ||
                linkedBuckets.some((bucket) => !isCreatedInDevBranch(bucket)) ||
                tables.some((table) => !isCreatedInDevBranch(table.get('bucket')))
              }
            />
            {renderCollapsiblePanel('These buckets will be deleted', buckets, (bucket) =>
              bucket.get('displayName'),
            )}

            {renderCollapsiblePanel('These buckets will be unlinked', linkedBuckets, (bucket) =>
              bucket.get('displayName'),
            )}

            {renderCollapsiblePanel('These tables will be deleted', tables, (table) =>
              tableName(table),
            )}

            {renderCollapsiblePanel('These alias tables will be deleted', tablesAliases, (table) =>
              tableName(table),
            )}

            {renderCollapsiblePanel('These table links will be deleted', tablesLinks, (table) =>
              tableName(table.get('table')),
            )}

            <TriggersStorageWarning tables={tables.map((table) => table.get('id'))} />
          </>
        }
        isLoading={this.props.isLoading}
        onConfirm={this.props.onSubmit}
      />
    );
  }
}

export default DeleteBucketsAndTablesModal;
