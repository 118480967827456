import { List, Map } from 'immutable';

import * as componentFlags from '@/constants/componentFlags';
import {
  KEBOOLA_DATA_APPS,
  KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION,
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_ORACLE_TRANSFORMATION,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_SYNAPSE_TRANSFORMATION,
} from '@/constants/componentIds';
import { FEATURE_WORKSPACE_VIEW_LOAD } from '@/constants/features';
import { features as componentFeatures } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import LegacyTransformationActionCreators from '@/modules/legacy-transformation/ActionCreators';
import TransformationsStore from '@/modules/legacy-transformation/stores/TransformationsStore';
import { prepareUniqueDestination } from '@/modules/transformations/helpers';
import { DBT_COMPONENTS } from '@/modules/transformations-v2/constants';
import ApplicationStore from '@/stores/ApplicationStore';

const hasInputMappingTables = (value) => value.has('tables') && !value.get('tables').isEmpty();

const isDbtTableInputMapping = (componentId, type, storage) => {
  return type === 'input' && storage === 'tables' && DBT_COMPONENTS.includes(componentId);
};

const getMappingBasePath = (componentId, type, storage) => {
  if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
    return [type];
  }

  if (isDbtTableInputMapping(componentId, type, storage)) {
    return ['parameters', 'storage', type, storage];
  }

  return ['storage', type, storage];
};

const prepareMappingForSave = (
  componentId,
  config,
  editingConfig,
  mappingType,
  storage,
  index,
  hasNewQueue,
) => {
  const dbtTableInputMapping = isDbtTableInputMapping(componentId, mappingType, storage);
  const mappingPath = getMappingBasePath(componentId, mappingType, storage);
  const mapping = editingConfig
    .getIn([...mappingPath, index])
    .update((mapping) => {
      if (mappingType === 'input' && storage === 'files') {
        return mapping.withMutations((mapping) => {
          // remove empty query param from file input mapping
          if (!mapping.get('query')) {
            mapping.delete('query');
          }

          // remove empty source tags param from file input mapping
          if (mapping.getIn(['source', 'tags'], List()).isEmpty()) {
            mapping.deleteIn(['source', 'tags']);
          }

          // remove empty source param from file input mapping
          if (mapping.get('source', Map()).isEmpty()) {
            mapping.delete('source');
          }

          // remove empty change since param from file input mapping
          if (!mapping.get('changed_since')) {
            mapping.delete('changed_since');
          }
        });
      }

      if (mappingType === 'output' && storage === 'tables' && mapping.has('delete_where')) {
        return (
          mapping
            // remove legacy where filters
            .delete('delete_where_column')
            .delete('delete_where_operator')
            .delete('delete_where_values')
            // remove not configured where filters
            .update('delete_where', (rows) => {
              return rows
                .map((row) => {
                  return row
                    .update('where_filters', List(), (filters) => {
                      return filters.filter((filter) => !!filter.get('column'));
                    })
                    .withMutations((row) => {
                      if (row.get('where_filters', List()).isEmpty()) {
                        row.delete('where_filters');
                      }

                      if (!row.get('changed_since')) {
                        row.delete('changed_since');
                      }

                      if (!row.get('changed_until')) {
                        row.delete('changed_until');
                      }
                    });
                })
                .filter((row) => !row.isEmpty());
            })
            // remove empty delete where
            .withMutations((mapping) => {
              if (mapping.get('delete_where').isEmpty()) {
                mapping.delete('delete_where');
              }
            })
        );
      }

      if (
        hasNewQueue &&
        index === 'new-mapping' &&
        mappingType === 'input' &&
        storage === 'tables' &&
        !mapping.has('keep_internal_timestamp_column')
      ) {
        return mapping.set('keep_internal_timestamp_column', false);
      }

      return mapping;
    })
    .update((mapping) => {
      if (dbtTableInputMapping && !mapping.has('tables')) {
        return Map({ source: mapping.get('source') });
      }

      if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
        return mapping.delete('keep_internal_timestamp_column');
      }

      return mapping;
    });

  if (!config.hasIn(mappingPath)) {
    config = config.setIn(mappingPath, List());
  }

  if (index !== 'new-mapping') {
    return config.setIn([...mappingPath, index], mapping);
  }

  if (!mapping.has('tables')) {
    const lastIndex = config.getIn(mappingPath, List()).count();
    return config.setIn([...mappingPath, lastIndex], mapping);
  }

  let definedDestinations = config
    .getIn(mappingPath, List())
    .map((input) => input.get('destination'))
    .toList();

  return config.updateIn(mappingPath, List(), (storage) => {
    return storage.concat(
      mapping
        .get('tables')
        .map((tableMapping) => {
          const destination = definedDestinations.includes(tableMapping.get('destination'))
            ? prepareUniqueDestination(tableMapping, definedDestinations)
            : tableMapping.get('destination');

          definedDestinations = definedDestinations.push(destination);
          return tableMapping.set('destination', destination);
        })
        .map((tableMapping) => {
          const newMapping = Map({
            source: tableMapping.get('source'),
            destination: tableMapping.get('destination'),
          });

          if (dbtTableInputMapping) {
            return newMapping.delete('destination');
          }

          return mapping.merge(newMapping);
        })
        .map((tableMapping) => tableMapping.delete('tables'))
        .toList(),
    );
  });
};

const resolveConfigData = (componentId, configId, rowId) => {
  if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
    return TransformationsStore.getTransformation(configId, rowId);
  }

  if (rowId) {
    return ConfigurationRowsStore.getConfiguration(componentId, configId, rowId);
  }

  return InstalledComponentsStore.getConfigData(componentId, configId);
};

const prepareConfigDataWithEditedMapping = (
  componentId,
  configId,
  rowId,
  mappingType,
  storage,
  index,
  mapping,
) => {
  const configData = resolveConfigData(componentId, configId, rowId);
  const editingConfigData = configData.updateIn(
    getMappingBasePath(componentId, mappingType, storage),
    List(),
    (files) => files.toMap().set(index, mapping),
  );

  return prepareMappingForSave(
    componentId,
    configData,
    editingConfigData,
    mappingType,
    storage,
    index,
    ApplicationStore.hasNewQueue(),
  );
};

const prepareConfigDataWithDeletedMapping = (
  componentId,
  configId,
  rowId,
  mappingType,
  storage,
  index,
) => {
  const configData = resolveConfigData(componentId, configId, rowId);
  const mappingPath = getMappingBasePath(componentId, mappingType, storage);

  return configData.deleteIn([...mappingPath, index]);
};

const saveMapping = (componentId, configId, rowId, configData, changeDescription) => {
  if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
    return LegacyTransformationActionCreators.saveTransformation(
      configId,
      rowId,
      configData,
      'mapping',
      changeDescription,
    );
  }

  if (rowId) {
    return ConfigurationRowsActionCreators.updateSimple(
      componentId,
      configId,
      rowId,
      { configuration: JSON.stringify(configData.toJS()) },
      changeDescription,
    );
  }

  return InstalledComponentsActionCreators.saveComponentConfigData(
    componentId,
    configId,
    configData,
    changeDescription,
  );
};

const getColumnsOptions = (columns, componentId) => {
  return columns
    .map((column) => {
      if (componentId === KEBOOLA_ORACLE_TRANSFORMATION) {
        return { label: column.toUpperCase(), value: column };
      }

      return { label: column, value: column };
    })
    .toArray();
};

const supportsFileTags = (component, configurationData) => {
  return (
    !component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_FILE_OUTPUT) &&
    component.get('flags').includes(componentFlags.GENERIC_DOCKER_UI_ROWS) &&
    component.get('features').includes(componentFeatures.ALLOW_USE_FILE_STORAGE_ONLY) &&
    configurationData.getIn(['runtime', 'use_file_storage_only'], false)
  );
};

const supportsWriteAlways = (componentId) => {
  return [KEBOOLA_SNOWFLAKE_TRANSFORMATION, KEBOOLA_GOOGLE_BIGQUERY_TRANSFORMATION].includes(
    componentId,
  );
};

const isDifferenceInColumns = (filterColumns, columnsFromDataTypes, columnsFromStorage) => {
  return !getUnsynchronizedColumns(
    filterColumns,
    columnsFromDataTypes,
    columnsFromStorage,
  ).isEmpty();
};

const getUnsynchronizedColumns = (filterColumns, columnsFromDataTypes, columnsFromStorage) => {
  const unsynchronizedColumns = columnsFromDataTypes
    .filter((column) => !columnsFromStorage.includes(column))
    .concat(columnsFromStorage.filter((column) => !columnsFromDataTypes.includes(column)));

  if (filterColumns.isEmpty()) {
    return unsynchronizedColumns;
  }

  return unsynchronizedColumns.filter((column) => filterColumns.includes(column));
};

const hasWorkspaceViewLoad = (componentId, replacementComponentId) => {
  return (
    [componentId, replacementComponentId].includes(KEBOOLA_SYNAPSE_TRANSFORMATION) &&
    ApplicationStore.hasCurrentProjectFeature(FEATURE_WORKSPACE_VIEW_LOAD)
  );
};

const canForceSourceBranch = () => {
  return DevBranchesStore.isDevModeActive() && ApplicationStore.hasProtectedDefaultBranch();
};

const getFileLocationHint = (componentId) => {
  return componentId === KEBOOLA_DATA_APPS ? '/data/in' : 'in';
};

const isDeleteWhereConfigurationValid = (value) => {
  if (value.get('delete_where_column', '') !== '') {
    return !value.get('delete_where_values', List()).isEmpty();
  }

  return value.get('delete_where', List()).every((deleteWhere) => {
    return deleteWhere.get('where_filters', List()).every((filter) => {
      if (!filter.get('column')) {
        return true;
      }

      if (filter.has('values_from_workspace')) {
        return (
          !!filter.getIn(['values_from_workspace', 'table']) &&
          !!filter.getIn(['values_from_workspace', 'column'])
        );
      }

      return !filter.get('values_from_set', List()).isEmpty();
    });
  });
};

export {
  hasInputMappingTables,
  getMappingBasePath,
  prepareMappingForSave,
  prepareConfigDataWithEditedMapping,
  prepareConfigDataWithDeletedMapping,
  saveMapping,
  getColumnsOptions,
  supportsFileTags,
  supportsWriteAlways,
  isDifferenceInColumns,
  getUnsynchronizedColumns,
  hasWorkspaceViewLoad,
  canForceSourceBranch,
  getFileLocationHint,
  isDeleteWhereConfigurationValid,
};
