import createReactClass from 'create-react-class';
import { List, Map, OrderedMap } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { SIDEBAR } from '@/constants/external';
import { FEATURE_DATA_APPS_ENFORCE_AUTH } from '@/constants/features';
import dayjs from '@/date';
import { ioType } from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import FileInputMapping from '@/modules/components/react/components/generic/FileInputMapping';
import TableInputMapping from '@/modules/components/react/components/generic/TableInputMapping';
import MappingWrapper from '@/modules/components/react/components/MappingsWrapper';
import SidebarJobs from '@/modules/components/react/components/SidebarJobs';
import SidebarVersions from '@/modules/components/react/components/SidebarVersions';
import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import { routeNames as componentsRoutes } from '@/modules/components-directory/constants';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import NotificationsStore from '@/modules/notifications/store';
import JobsStore from '@/modules/queue/store';
import { SANDBOX_LABELS, SANDBOX_SIZES } from '@/modules/sandboxes/Constants';
import { getBackendSizeNote, prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import ScheduleConfigurationButton from '@/modules/scheduler/components/ScheduleConfigurationButton';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { CreatedDate, RouterLink } from '@/react/common';
import CollapsibleBox from '@/react/common/CollapsibleBox';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import ParametersBox from '@/react/common/CredentialsBox';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import AdditionalSettings from './components/AdditionalSettings';
import AuthenticationSettings from './components/AuthenticationSettings';
import DeleteDataApp from './components/DeleteDataApp';
import DeployDataApp from './components/DeployDataApp';
import { Deployment } from './components/Deployment';
import OpenDataApp from './components/OpenDataApp';
import SecretsBox from './components/SecretsBox';
import TerminateDataApp from './components/TerminateDataApp';
import ThemeSettings from './components/ThemeSettings';
import { AUTO_SLEEP_NOTE, routeNames, SIZE_PATH } from './constants';
import DataAppsStore from './DataAppsStore';
import { getAppState, prepareExpiration } from './helpers';

const DataAppDetail = createReactClass({
  mixins: [
    createStoreMixin(
      ApplicationStore,
      RoutesStore,
      DataAppsStore,
      SandboxesStore,
      InstalledComponentsStore,
      StorageTablesStore,
      StorageBucketsStore,
      NotificationsStore,
      DevBranchesStore,
      ComponentsStore,
      StackFeaturesStore,
      JobsStore,
      VersionsStore,
    ),
  ],

  getStateFromStores() {
    const componentId = KEBOOLA_DATA_APPS;
    const configId = RoutesStore.getCurrentRouteParam('config');
    const configData = InstalledComponentsStore.getConfigData(componentId, configId);

    return {
      componentId,
      configId,
      configData,
      tables: StorageTablesStore.getAll(),
      buckets: StorageBucketsStore.getAll(),
      component: ComponentsStore.getComponent(componentId),
      allConfigurations: InstalledComponentsStore.getAll(),
      sandboxes: prepareSandboxes(
        SandboxesStore.getSandboxes(),
        InstalledComponentsStore.getComponentConfigurations(componentId),
      ),
      app: DataAppsStore.getDataApp(configData.getIn(['parameters', 'id'])),
      flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      latestJobs: JobsStore.getLatestJobs(componentId, configId),
      config: InstalledComponentsStore.getConfig(componentId, configId),
      admins: ApplicationStore.getAdmins(),
      versions: VersionsStore.getVersions(componentId, configId),
      notifications: NotificationsStore.getAll(),
      versionsConfigs: VersionsStore.getVersionsConfigs(componentId, configId),
      isLoadingVersions: VersionsStore.isLoadingVersions(componentId, configId),
      isPendingVersions: VersionsStore.isPendingConfig(componentId, configId),
      pendingMultiLoadVersions: VersionsStore.getPendingMultiLoad(componentId, configId),
      hasDataAppsEnforcedAuth: StackFeaturesStore.hasStackFeature(FEATURE_DATA_APPS_ENFORCE_AUTH),
      sapiToken: ApplicationStore.getSapiToken(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      readOnly: ApplicationStore.isReadOnly(),
      isDevModeActive: DevBranchesStore.isDevModeActive(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  render() {
    if (this.state.config.isEmpty()) {
      return <p>App details not found</p>;
    }

    return (
      <GenericConfigBody
        key={`${this.state.componentId}-${this.state.configId}`}
        componentId={this.state.componentId}
        configId={this.state.configId}
        sidebar={this.renderSidebar()}
      >
        {this.renderDetail()}
        {this.renderAuthentication()}
        {this.renderAdditionalSettings()}
        {this.renderDeployment()}
        {this.renderTheming()}
        {this.renderSecrets()}
        <MappingWrapper>
          {this.tableInputMapping()}
          {this.fileInputMapping()}
        </MappingWrapper>
      </GenericConfigBody>
    );
  },

  renderSidebar() {
    const appState = getAppState(this.state.app);

    return (
      <div className={`sidebar-content ${SIDEBAR}`}>
        <ul className="nav nav-stacked">
          <li>
            <DeployDataApp
              mode="sidebar"
              app={this.state.app}
              config={this.state.config}
              readOnly={this.state.readOnly}
              hasPayAsYouGo={this.state.hasPayAsYouGo}
            />
            {appState === 'created' && <hr />}
          </li>
          {appState !== 'created' && (
            <li className="!tw-mt-2">
              <OpenDataApp mode="sidebar" config={this.state.config} app={this.state.app} />
              <hr />
            </li>
          )}
          <li>
            <TerminateDataApp
              mode="sidebar"
              app={this.state.app}
              config={this.state.config}
              readOnly={this.state.readOnly}
            />
          </li>
          {this.renderCopyButton()}
          {this.renderScheduleConfigurationButton()}
          {this.renderRawConfigurationButton()}
          <hr />
          <DeleteDataApp mode="sidebar" readOnly={this.state.readOnly} config={this.state.config} />
        </ul>
        <SidebarJobs
          hasNewQueue
          jobs={this.state.latestJobs}
          componentId={this.state.componentId}
          configId={this.state.configId}
          allConfigurations={this.state.allConfigurations}
          admins={this.state.admins}
          currentAdmin={this.state.currentAdmin}
          notifications={this.state.notifications}
        />
        <hr />
        <SidebarVersions
          configId={this.state.configId}
          component={this.state.component}
          config={this.state.config}
          versionsLinkTo={routeNames.DATA_APP_VERSIONS}
          versions={this.state.versions}
          versionsConfigs={this.state.versionsConfigs}
          isLoading={this.state.isLoadingVersions}
          isPending={this.state.isPendingVersions}
          pendingMultiLoad={this.state.pendingMultiLoadVersions}
          admins={this.state.admins}
        />
      </div>
    );
  },

  renderCopyButton() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <li>
        <CopyButton
          mode="sidebar"
          configuration={this.state.config}
          component={this.state.component}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderScheduleConfigurationButton() {
    if (this.state.readOnly || this.state.isDevModeActive) {
      return null;
    }

    return (
      <li>
        <ScheduleConfigurationButton
          hasNewQueue
          mode="link"
          flows={this.state.flows}
          component={this.state.component}
          config={this.state.config}
          hasFlows={this.state.hasFlows}
        />
      </li>
    );
  },

  renderRawConfigurationButton() {
    return (
      <li>
        <RouterLink
          to={componentsRoutes.GENERIC_CONFIG_RAW}
          params={{ component: this.state.componentId, config: this.state.configId }}
          className="btn btn-link btn-block btn-link-inline"
        >
          <Icon icon="bug" fixedWidth />
          Debug mode
        </RouterLink>
      </li>
    );
  },

  tableInputMapping() {
    return (
      <TableInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        destinationType={ioType.FILE}
        configId={this.state.configId}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        value={this.state.configData.getIn(['storage', 'input', 'tables'], List())}
        tables={this.state.tables}
        buckets={this.state.buckets}
        sandboxes={this.state.sandboxes}
      />
    );
  },

  fileInputMapping() {
    return (
      <FileInputMapping
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        value={this.state.configData.getIn(['storage', 'input', 'files'], List())}
        onDeleteMappings={(...args) =>
          InstalledComponentsActionCreators.deleteMappings(this.state.configData, ...args)
        }
        sandboxes={this.state.sandboxes}
      />
    );
  },

  renderAdditionalSettings() {
    return (
      <AdditionalSettings
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
        readOnly={this.state.readOnly}
        app={this.state.app}
      />
    );
  },

  renderTheming() {
    if (this.state.readOnly) {
      return null;
    }

    return (
      <ThemeSettings
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
        readOnly={this.state.readOnly}
      />
    );
  },

  renderDetail() {
    const creator = this.state.config.getIn(['creatorToken', 'description']);
    const backendSize = this.state.config.getIn(SIZE_PATH, SANDBOX_SIZES.TINY);

    const rows = OrderedMap({
      'Data App ID': {
        noCopy: true,
        text: this.state.app?.id || 'N/A',
      },
      Owner: {
        noCopy: true,
        text:
          creator === this.state.sapiToken.get('description')
            ? 'You'
            : this.state.admins.getIn([creator, 'name'], creator || 'N/A'),
      },
      Created: {
        noCopy: true,
        component: <CreatedDate createdTime={this.state.config.get('created')} />,
      },
      'Last Change': {
        noCopy: true,
        component: (
          <CreatedDate createdTime={this.state.config.getIn(['currentVersion', 'created'])} />
        ),
      },
      'Backend Size': {
        noCopy: true,
        text: SANDBOX_LABELS[backendSize],
        ...(!this.state.hasPayAsYouGo && { hint: getBackendSizeNote(true) }),
      },
      'Auto Sleep': {
        noCopy: true,
        text: dayjs.duration(prepareExpiration(this.state.config), 'second').humanize(),
        hint: AUTO_SLEEP_NOTE,
      },
    });

    return (
      <CollapsibleBox title="Information" entity="data-app detail">
        <ParametersBox rows={rows} noBorder />
      </CollapsibleBox>
    );
  },

  renderAuthentication() {
    if (this.state.hasDataAppsEnforcedAuth) {
      return null;
    }

    return (
      <AuthenticationSettings
        readOnly={this.state.readOnly}
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
      />
    );
  },

  renderDeployment() {
    return (
      <Deployment
        componentId={this.state.componentId}
        configId={this.state.configId}
        configData={this.state.configData}
      />
    );
  },

  renderSecrets() {
    return (
      <SecretsBox
        readOnly={this.state.readOnly}
        configId={this.state.configId}
        componentId={this.state.componentId}
        configData={this.state.configData}
        secrets={this.state.configData.getIn(['parameters', 'dataApp', 'secrets'], Map())}
      />
    );
  },
});

export default DataAppDetail;
