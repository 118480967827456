import { BaseEdge, type Edge, type EdgeProps, getSmoothStepPath } from '@xyflow/react';

import { ARROW_OFFSET } from '@/modules/flows-v2/builder/helpers';

export const AloneEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  markerEnd,
  style,
}: EdgeProps<Edge>) => {
  const offset = id.startsWith('alone:before:') ? ARROW_OFFSET : 0;

  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY: targetY - offset,
    targetPosition,
  });

  return <BaseEdge id={id} path={edgePath} style={style} markerEnd={markerEnd} />;
};
