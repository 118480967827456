import { useState } from 'react';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

type Props = {
  componentId: string;
  configId: string;
  readOnly?: boolean;
};

export const ComponentNameEdit = ({ componentId, configId, readOnly = false }: Props) => {
  const store = useStores(
    () => {
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const value = config.get('name', '');

      return {
        originalValue: value,
        readOnly: Boolean(readOnly || ApplicationStore.isReadOnly()),
      };
    },
    [componentId, configId, readOnly],
    [InstalledComponentsStore, ApplicationStore],
  );

  const [isSaving, setIsSaving] = useState(false);

  const [value, setValue] = useState<string | null>(null);

  if (store.readOnly) {
    return store.originalValue;
  }

  const handleSubmit = () => {
    if (value === null) {
      return;
    }
    setIsSaving(true);
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      componentId,
      configId,
      { name: value.trim() },
      `Update name`,
      'name',
    ).finally(() => {
      setIsSaving(false);
      setValue(null);
    });
  };

  const handleCancel = () => {
    setIsSaving(false);
    setValue(null);
  };

  return (
    <InlineEditTextInput
      text={value ?? store.originalValue}
      placeholder="My Configuration"
      tooltipPlacement="bottom"
      isSaving={isSaving}
      isEditing={value !== null}
      isValid={!!(value ?? store.originalValue).trim()}
      isChanged={value !== store.originalValue}
      onEditStart={() => {
        setValue(store.originalValue);
      }}
      onEditCancel={handleCancel}
      onEditChange={setValue}
      onEditSubmit={handleSubmit}
    />
  );
};
