export const BACKGROUND_COLORS = [
  { name: 'Blue', color: '#3B5998' },
  { name: 'Green', color: '#07BE07' },
  { name: 'Light Green', color: '#38CE8F' },
  { name: 'Red', color: '#FF5B50' },
  { name: 'Purple', color: '#925cff' },
  { name: 'Orange', color: '#FBBC05' },
  { name: 'Light Red', color: '#FF6A3C' },
  { name: 'Light Blue', color: '#4CCBFF' },
];

export const DEFAULT_BUCKET_COLOR = '#7C8594';
