const routeNames = {
  ROOT: 'flows-v2',
  DETAIL: 'flow-v2',
};

const tabs = {
  BUILDER: 'builder',
  SCHEDULES: 'schedules',
  NOTIFICATIONS: 'notifications',
  VERSIONS: 'versions',
};

const FLOW = 'flow';

export { routeNames, tabs, FLOW };
