import { useState } from 'react';
import type { Map } from 'immutable';

import { updateFlowConfiguration } from '@/modules/flows-v2/actions';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';

type Props = {
  flow: Map<string, any>;
  isDisabled: boolean;
};

const ActivateFlowSwitch = ({ flow, isDisabled }: Props) => {
  const [isPending, setIsPending] = useState(false);
  const toggle = (state: boolean) => {
    setIsPending(true);
    updateFlowConfiguration(
      flow.get('id'),
      { isDisabled: !state },
      `${state ? 'Enable' : 'Disable'} flow`,
    ).finally(() => setIsPending(false));
  };

  return (
    <ActivateDeactivateSwitch
      withLabel
      mode="menuitem"
      activateTooltip="Disabled (click to enable)"
      deactivateTooltip="Enabled (click to disable)"
      isActive={!flow.get('isDisabled')}
      buttonDisabled={isDisabled}
      isPending={isPending}
      onChange={toggle}
    />
  );
};

export default ActivateFlowSwitch;
