import { useEffect, useMemo, useRef, useState } from 'react';
import { fromJS, List, Map } from 'immutable';
import qs from 'qs';

import { cn, Search, Separator } from '@keboola/design';

import type { ShareModalStep } from '@/constants';
import { FILTERS, FILTERS_GROUP } from '@/constants';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { canExportTable } from '@/modules/admin/privileges';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import ShareModal from '@/modules/data-catalog/react/ShareModal';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import SandboxesActions from '@/modules/sandboxes/Actions';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal/AddSandboxModal';
import { prepareSandboxes, updateExistingWorkspace } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import type { WorkspaceStep } from '@/modules/sandboxes/types';
import StackFeaturesStore from '@/modules/stack-features/Store';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import CreateSnapshotModal from './components/CreateSnapshotModal';
import DeleteBucketModal from './components/DeleteBucketModal';
import DeleteBucketsAndTablesModal from './components/DeleteBucketsAndTablesModal';
import DeleteTableModal from './components/DeleteTableModal';
import ExportModal from './components/ExportModal';
import { IndexTable, IndexTableResults } from './components/IndexTable';
import StorageTabs from './components/StorageTabs';
import {
  createSnapshots,
  deleteBucket,
  deleteBucketsAndTables,
  deleteTable,
  exportTables,
  updateSearchFilters,
  updateSearchQuery,
} from './actions';
import {
  getTableAliases,
  getTableLinks,
  isBucketLinked,
  isBucketShared,
  prepareMappingFromSelectedBucketsAndTables,
} from './helpers';
import StorageStore from './store';

const prepareBuckets = (
  allBuckets: Map<string, any>,
  tables: Map<string, any>,
  searchFilters: Map<string, any>,
) => {
  const buckets = DevBranchesStore.isDevModeActive()
    ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
    : searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
      ? filterDevBranchBuckets(allBuckets)
      : filterProductionBuckets(allBuckets);

  return buckets.map((bucket: Map<string, any>) => {
    return bucket.set(
      'bucketTables',
      tables.filter(
        (table: Map<string, any>) => table.getIn(['bucket', 'id']) === bucket.get('id'),
      ),
    );
  });
};

type State = {
  selected: Map<string, any>[];
  selectedBucket: Map<string, any>;
  selectedTable: Map<string, any>;
  isDeleting: boolean;
  showDeleteModal: boolean;
  showDeleteBucketModal: boolean;
  showDeleteTableModal: boolean;
  showExportTablesModal: boolean;
  showSnapshotModal: boolean;
  showCreateWorkspaceModal: boolean;
  showShareModal: boolean;
  createWorkspaceForceStep: WorkspaceStep | null;
  shareModalStep: ShareModalStep | null;
  selectedTables: Map<string, any>[] | null;
};

const Index = () => {
  const store = useStores(
    () => {
      const allBuckets = BucketsStore.getAll();
      const allTables = TablesStore.getAll() as Map<string, any>;
      const sapiToken = ApplicationStore.getSapiToken();
      const searchFilters = StorageStore.getSearchFilters() as Map<string, any>;

      return {
        allBuckets,
        allTables,
        sapiToken,
        searchFilters,
        devBranchBuckets: filterDevBranchBuckets(allBuckets),
        expandedBuckets: StorageStore.getExpandedBuckets() as Map<string, any>,
        bucketsSort: StorageStore.getBucketsSort() as Map<string, any>,
        canExportTable: canExportTable(sapiToken),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        configurations: InstalledComponentsStore.getAll() as Map<string, any>,
        components: ComponentsStore.getAll() as Map<string, any>,
        deletingTables: TablesStore.getDeletingTables(),
        exportingTables: TablesStore.getExportingTables(),
        searchQuery: StorageStore.getSearchQuery(),
        createSnapshotsTables: TablesStore.getCreatingSnapshotsTables(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        deletingBuckets: BucketsStore.getPendingBucketsActions().get('deleting', Map()),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        hasFlows: ApplicationStore.hasFlows(),
        showAllBuckets: StorageStore.getShowAllBuckets() as boolean,
        hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
        ),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        isDevModeActive: DevBranchesStore.isDevModeActive(),
        canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
        availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
        availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },
    [],
    [
      ApplicationStore,
      StorageStore,
      InstalledComponentsStore,
      ComponentsStore,
      SandboxesStore,
      BucketsStore,
      TablesStore,
      DevBranchesStore,
    ],
  );

  const query = RoutesStore.getRouterState().getIn(['location', 'query']);
  const q = store.searchQuery ?? query.get('q', '');
  const filters =
    store.searchFilters === null ? fromJS(qs.parse(query.get('filter'))) : store.searchFilters;

  const mountRef = useRef(true);

  useEffect(() => {
    if (mountRef.current) {
      updateSearchQuery(q);
      updateSearchFilters(filters);

      mountRef.current = false;
    }

    RoutesStore.getRouter().updateQuery({
      q,
      filter: qs.stringify(filters.toJS()),
    });
  }, [store.searchQuery, store.searchFilters, q, filters]);

  const [state, setState] = useState<State>({
    selected: [],
    selectedBucket: Map(),
    selectedTable: Map(),
    isDeleting: false,
    showDeleteModal: false,
    showDeleteBucketModal: false,
    showDeleteTableModal: false,
    showExportTablesModal: false,
    showSnapshotModal: false,
    showCreateWorkspaceModal: false,
    showShareModal: false,
    createWorkspaceForceStep: null,
    shareModalStep: null,
    selectedTables: null,
  });

  const bucketsFiltered = useMemo(
    () => prepareBuckets(store.allBuckets, store.allTables, filters),
    [filters, store.allBuckets, store.allTables],
  );

  const handleOpenDeleteBucketModal = (selectedBucket: Map<string, any>) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteBucketModal: true,
      selectedBucket,
    }));
  };

  const handleOpenDeleteTableModal = (selectedTable: Map<string, any>) => {
    setState((prevState) => ({
      ...prevState,
      showDeleteTableModal: true,
      selectedTable,
    }));
  };

  const handleOpenExportTablesModal = (tables: Map<string, any>[]) => {
    setState((prevState) => ({
      ...prevState,
      showExportTablesModal: true,
      selected: tables,
    }));
  };

  const handleOpenSnapshotModal = (tables: Map<string, any>[]) => {
    setState((prevState) => ({
      ...prevState,
      showSnapshotModal: true,
      selected: tables,
    }));
  };

  const handleOpenCreateWorkspaceModal = (
    selected: Map<string, any>[],
    forceStep?: WorkspaceStep,
  ) => {
    setState((prevState) => ({
      ...prevState,
      showCreateWorkspaceModal: true,
      selected,
      createWorkspaceForceStep: forceStep ?? null,
    }));
  };

  const handleOpenShareModal = ({
    selectedBucket = null,
    selectedTables = null,
    shareModalStep,
  }: {
    selectedBucket?: Map<string, any> | undefined | null;
    selectedTables?: Map<string, any>[] | undefined | null;
    shareModalStep: ShareModalStep;
  }) => {
    setState((prevState) => ({
      ...prevState,
      selectedBucket: selectedBucket ?? prevState.selectedBucket,
      selectedTables: selectedTables ?? prevState.selectedTables,
      shareModalStep,
      showShareModal: true,
    }));
  };

  const handleCreateSnapshots = (description: string) => {
    return createSnapshots(
      state.selected.map((table) => table.get('id')),
      { description },
    );
  };

  const handleDeleteBucket = () => {
    const bucketId = state.selectedBucket.get('id');
    const forceDelete = store.allTables.some((table) => table.getIn(['bucket', 'id']) === bucketId);
    return deleteBucket(bucketId, { forceDelete }).then(() => {
      return setState((prevState) => ({
        ...prevState,
        showDeleteBucketModal: false,
        selectedBucket: Map(),
      }));
    });
  };

  const handleOpenDeleteModal = (selected: Map<string, any>[]) => {
    const selectedBuckets = selected.filter((row) => row.has('stage'));

    // prefer dedicated delete bucket modal if there is only one bucket
    if (
      selectedBuckets.length === 1 &&
      selected.length - 1 === selectedBuckets[0].get('bucketTables').size &&
      selectedBuckets[0].get('bucketTables').every((table: Map<string, any>) => {
        return selected.some((row) => row.get('id') === table.get('id'));
      })
    ) {
      return handleOpenDeleteBucketModal(selectedBuckets[0]);
    }

    // prefer dedicated delete table modal if there is only one table
    if (selected.length === 1 && !selected[0].has('stage')) {
      return handleOpenDeleteTableModal(selected[0]);
    }

    setState((prevState) => ({ ...prevState, showDeleteModal: true, selected }));
  };

  const commonTableProps = {
    readOnly: store.readOnly,
    buckets: bucketsFiltered,
    sapiToken: store.sapiToken,
    hasPayAsYouGo: store.hasPayAsYouGo,
    deletingTables: store.deletingTables,
    deletingBuckets: store.deletingBuckets,
    exportingTables: store.exportingTables,
    createSnapshotsTables: store.createSnapshotsTables,
    openDeleteModal: handleOpenDeleteModal,
    openDeleteBucketModal: handleOpenDeleteBucketModal,
    openDeleteTableModal: handleOpenDeleteTableModal,
    openExportTablesModal: handleOpenExportTablesModal,
    openCreateWorkspaceModal: handleOpenCreateWorkspaceModal,
    openShareModal: handleOpenShareModal,
    openSnapshotModal: handleOpenSnapshotModal,
    bucketsSort: store.bucketsSort,
    hasSnowflakePartnerConnectLimited: store.hasSnowflakePartnerConnectLimited,
    canExportTable: store.canExportTable,
    searchFilters: filters,
  };

  const getPlaceholder = () => {
    const entity = filters.get(FILTERS_GROUP.ENTITY);
    const sharing = filters.get(FILTERS_GROUP.SHARING);
    const sharingFilter = [FILTERS.LINKED, FILTERS.SHARED].includes(sharing);

    const buckets = bucketsFiltered.count((bucket: Map<string, any>) => {
      if (!sharing) return true;
      if (sharing === FILTERS.LINKED) return isBucketLinked(bucket);
      if (sharing === FILTERS.SHARED) return isBucketShared(bucket);
      return true;
    });

    const tables = bucketsFiltered
      .flatMap((bucket: Map<string, any>) => bucket.get('bucketTables'))
      .count((table: Map<string, any>) => {
        if (!sharing) return true;
        if (sharing === FILTERS.LINKED)
          return !table.getIn(['bucket', 'linkedBy'], List()).isEmpty();
        if (sharing === FILTERS.SHARED) return Boolean(table.getIn(['bucket', 'sharing']));
      });

    if (entity === FILTERS.TABLES) {
      return `Search${sharingFilter ? '' : ' all'} tables (${tables})`;
    }

    if (entity === FILTERS.BUCKETS) {
      return `Search${sharingFilter ? '' : ' all'} buckets (${buckets})`;
    }

    return `Search${sharingFilter ? '' : ' all'} tables (${tables}) and buckets (${buckets})`;
  };

  const renderSearchAdditionalActions = () => {
    if (!store.allBuckets.count()) {
      return null;
    }

    return (
      <div className="tw-flex tw-gap-2 tw-whitespace-nowrap">
        {renderAdditionalActionsButton(FILTERS.ALL, null, 'ALL')}
        {!store.hasPayAsYouGo && (
          <>
            <Separator orientation="vertical" size="1/2" className="tw-mx-2" />
            {renderAdditionalActionsButton(FILTERS.LINKED, FILTERS_GROUP.SHARING, 'LINKED')}
            {renderAdditionalActionsButton(FILTERS.SHARED, FILTERS_GROUP.SHARING, 'SHARED')}
          </>
        )}
        <Separator orientation="vertical" size="1/2" className="tw-mx-2" />
        {renderAdditionalActionsButton(FILTERS.TABLES, FILTERS_GROUP.ENTITY, 'Tables')}
        {renderAdditionalActionsButton(FILTERS.BUCKETS, FILTERS_GROUP.ENTITY, 'Buckets')}
        {!store.isDevModeActive &&
          !store.devBranchBuckets.isEmpty() &&
          renderAdditionalActionsButton(FILTERS.DEV, FILTERS_GROUP.ENTITY, 'Dev branch')}
      </div>
    );
  };

  const renderAdditionalActionsButton = (
    type: (typeof FILTERS)[keyof typeof FILTERS],
    group: (typeof FILTERS_GROUP)[keyof typeof FILTERS_GROUP] | null,
    label: string,
  ) => {
    const active = group ? filters.get(group) === type : filters.isEmpty();

    return (
      <button
        className={cn('btn predefined-search-link !tw-m-0', { active })}
        onClick={() => {
          updateSearchFilters(
            group ? (active ? filters.remove(group) : filters.set(group, type)) : Map(),
          );
        }}
      >
        {label}
      </button>
    );
  };

  const selectedList = List(state.selected);

  return (
    <StorageTabs
      hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
      hasPayAsYouGo={store.hasPayAsYouGo}
      canEnableDataStreams={store.canEnableDataStreams}
    >
      <Search
        defaultValue={q}
        placeholder={getPlaceholder()}
        onChange={(value) => updateSearchQuery(value)}
        suffix={renderSearchAdditionalActions()}
        className="tw-mb-6"
      />
      {q.length > 1 ? (
        <IndexTableResults {...commonTableProps} query={q} />
      ) : (
        <IndexTable
          {...commonTableProps}
          expandedBuckets={store.expandedBuckets}
          components={store.components}
          configurations={store.configurations}
          hasFlows={store.hasFlows}
          showAllBuckets={store.showAllBuckets}
        />
      )}
      <DeleteBucketsAndTablesModal
        show={state.showDeleteModal}
        selected={state.selected}
        isLoading={state.isDeleting}
        allTables={store.allTables}
        allBuckets={store.allBuckets}
        sapiToken={store.sapiToken}
        onHide={() => setState((prevState) => ({ ...prevState, showDeleteModal: false }))}
        onSubmit={() => {
          setState((prevState) => ({ ...prevState, isDeleting: true }));

          return deleteBucketsAndTables(state.selected).finally(() => {
            setState((prevState) => ({ ...prevState, isDeleting: false }));
          });
        }}
      />
      <DeleteBucketModal
        show={state.showDeleteBucketModal}
        bucket={state.selectedBucket}
        tables={
          store.allTables.filter(
            (table) => table.getIn(['bucket', 'id']) === state.selectedBucket.get('id'),
          ) as Map<string, any>
        }
        deleting={store.deletingBuckets.get(state.selectedBucket.get('id'), false)}
        onConfirm={handleDeleteBucket}
        onHide={() =>
          setState((prevState) => ({
            ...prevState,
            showDeleteBucketModal: false,
            selectedBucket: Map(),
          }))
        }
      />
      <DeleteTableModal
        show={state.showDeleteTableModal}
        table={state.selectedTable}
        sapiToken={store.sapiToken}
        urlTemplates={store.urlTemplates}
        tableAliases={getTableAliases(state.selectedTable, store.allTables, store.sapiToken)}
        tableLinks={getTableLinks(
          state.selectedTable,
          store.allBuckets.get(state.selectedTable.getIn(['bucket', 'id']), Map()),
        )}
        deleting={store.deletingTables.get(state.selectedTable.get('id'), false)}
        onConfirm={() => deleteTable(state.selectedTable.get('id'))}
        onHide={() =>
          setState((prevState) => ({
            ...prevState,
            showDeleteTableModal: false,
            selectedTable: Map(),
          }))
        }
      />
      <ExportModal
        show={state.showExportTablesModal}
        tables={selectedList}
        onSubmit={(type) => exportTables(List(state.selected), type)}
        onHide={() =>
          setState((prevState) => ({ ...prevState, showExportTablesModal: false, selected: [] }))
        }
      />
      <CreateSnapshotModal
        show={state.showSnapshotModal}
        multiple={selectedList.size > 1}
        onConfirm={handleCreateSnapshots}
        onHide={() =>
          setState((prevState) => ({ ...prevState, showSnapshotModal: false, selected: [] }))
        }
      />
      <AddSandboxModal
        hasTableInputMapping
        show={state.showCreateWorkspaceModal}
        forceStep={state.createWorkspaceForceStep}
        onHide={() => setState((prevState) => ({ ...prevState, showCreateWorkspaceModal: false }))}
        workspaces={store.sandboxes}
        allowedComponents={store.allowedTransformationComponents}
        hasPayAsYouGo={store.hasPayAsYouGo}
        onUpdate={(workspace, preserve) => {
          return prepareMappingFromSelectedBucketsAndTables(
            state.selected,
            store.allTables,
            workspace.get('type'),
          ).then((storage) => {
            return updateExistingWorkspace(
              fromJS({ configuration: { parameters: { id: workspace.get('id') }, storage } }),
              workspace,
              preserve,
              'Use mapping from storage tables',
            );
          });
        }}
        onSubmit={(name, type, options, params, description) => {
          return prepareMappingFromSelectedBucketsAndTables(
            state.selected,
            store.allTables,
            type,
          ).then((storage) => {
            return SandboxesActions.createSandbox(
              { name, description, configuration: JSON.stringify({ storage }) },
              type,
              options,
              params.set('storage', storage),
            );
          });
        }}
      />
      <ShareModal
        showModal={state.showShareModal}
        onHide={() => setState((prevState) => ({ ...prevState, showShareModal: false }))}
        sapiToken={store.sapiToken}
        availableUsersOptions={store.availableUsersOptions}
        availableProjectsOptions={store.availableProjectsOptions}
        allBuckets={store.allBuckets}
        allTables={store.allTables}
        modalStep={state.shareModalStep}
        defaultSelectedBucket={state.selectedBucket}
        defaultSelectedTables={state.selectedTables}
      />
    </StorageTabs>
  );
};

export default Index;
