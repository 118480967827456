import { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import _ from 'underscore';

import { Button, Icon } from '@keboola/design';

import { updatePhaseConditions } from '@/modules/flows-v2/builder/actions';
import { ConditionsList } from '@/modules/flows-v2/builder/components/Conditions/ConditionsList';
import { SidebarModalHeader } from '@/modules/flows-v2/builder/components/SidebarModalHeader';
import {
  areConditionsValid,
  getAllParentIds,
  isValidCondition,
  prepareInitialConditions,
} from '@/modules/flows-v2/builder/helpers';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { NextConditionWithId } from '@/modules/flows-v2/builder/types';

export const ConditionForm = ({
  id,
  readOnly,
  onHide,
}: {
  id: string;
  readOnly: boolean;
  onHide: () => void;
}) => {
  const [context, nodes, setSelectedConditionId] = useBuilderStore((state) => [
    state.context,
    state.nodes,
    state.setSelectedConditionId,
  ]);

  const { phases, tasks, config } = context;

  const parentIds = useMemo(() => getAllParentIds(id, phases), [id, phases]);

  const initialConditions: NextConditionWithId[] = useMemo(() => {
    return prepareInitialConditions(phases, id);
  }, [id, phases]);

  const [conditions, setConditions] = useState<NextConditionWithId[]>(initialConditions);

  const isChanged = !_.isEqual(conditions, initialConditions);

  const handleSave = () => {
    updatePhaseConditions(config.get('id'), id, phases, conditions);
    setSelectedConditionId(null);
  };

  const isConditionValid = (condition: NextConditionWithId) => {
    return isValidCondition(condition, [id, ...parentIds], tasks);
  };

  return (
    <>
      <SidebarModalHeader
        onHide={onHide}
        additionalButtons={
          isChanged && (
            <>
              <Button
                variant="outline"
                icon="arrow-rotate-left"
                onClick={() => setConditions(initialConditions)}
              >
                Reset to default
              </Button>
              <span className="btn-separator tw-mx-4" />
            </>
          )
        }
      >
        Conditions
      </SidebarModalHeader>

      <Modal.Body className="tw-flex tw-grow tw-flex-col tw-gap-4 tw-overflow-hidden tw-px-0 tw-pb-4 tw-pt-1">
        <div className="tw-flex-1 tw-overflow-auto tw-px-3 tw-pb-2">
          <ConditionsList
            id={id}
            readOnly={readOnly}
            conditions={conditions}
            setConditions={setConditions}
            parentIds={parentIds}
            nodes={nodes}
            isConditionValid={isConditionValid}
          />
        </div>
        <div className="tw-mt-auto tw-px-4">
          <Button
            variant="primary"
            block
            onClick={handleSave}
            disabled={!isChanged || !areConditionsValid(conditions)}
          >
            <Icon icon="check-circle" />
            Save Condition
          </Button>
        </div>
      </Modal.Body>
    </>
  );
};
