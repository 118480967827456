import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import createReactClass from 'create-react-class';

import { Button, IconButton, Tooltip } from '@keboola/design';

import Check from '@/react/common/Check';
import Confirm from '@/react/common/Confirm';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import NewDimensionForm from './NewDimensionForm';

const DimensionsSection = createReactClass({
  propTypes: {
    value: PropTypes.shape({
      dimensions: PropTypes.object.isRequired,
    }),
    onChange: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
  },

  getInitialState() {
    return {
      newDimension: { template: 'gooddata' },
      showModal: false,
    };
  },

  openModal(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ showModal: true });
  },

  closeModal() {
    if (!this.props.disabled) {
      this.setState({ showModal: false, newDimension: { template: 'gooddata' } });
    }
  },

  renderModal() {
    return (
      <Modal onHide={this.closeModal} show={this.state.showModal}>
        <Modal.Header closeButton>
          <Modal.Title>New Date Dimension</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <NewDimensionForm
            value={this.state.newDimension}
            onChange={(val) => this.setState({ newDimension: val })}
            disabled={this.props.disabled}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            isSaving={this.props.disabled}
            isDisabled={!this.isValid()}
            saveLabel="Create Date Dimension"
            onSave={this.handleCreate}
          />
        </Modal.Footer>
      </Modal>
    );
  },

  isValid() {
    const { name, template, templateId } = this.state.newDimension;
    return name && (template === 'custom' ? templateId : true);
  },

  render() {
    const dimensionKeys = Object.keys(this.props.value.dimensions);
    const dimensions = this.props.value.dimensions;
    const hasDimensions = Object.keys(dimensions).length > 0;
    return (
      <div className="p-1">
        {this.renderModal()}
        {hasDimensions ? (
          <span>
            {!this.props.readOnly && (
              <div className="btn-toolbar">
                <div className="pull-right">{this.renderAddButton()}</div>
              </div>
            )}
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Include Time</th>
                  <th>Identifier</th>
                  <th>Template</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {dimensionKeys.map((dimName) => {
                  const dim = dimensions[dimName];
                  return (
                    <tr key={dimName}>
                      <td> {dimName}</td>
                      <td>
                        {' '}
                        <Check isChecked={!!dim.includeTime} />
                      </td>
                      <td> {dim.identifier}</td>
                      <td> {dim.template}</td>
                      <td>{!this.props.readOnly && this.renderDeleteButton(dimName)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </span>
        ) : (
          this.renderEmptyDimensions()
        )}
      </div>
    );
  },

  renderEmptyDimensions() {
    return (
      <div className="tw-flex tw-flex-col tw-items-center">
        <p>No dimensions created yet.</p>
        {this.renderAddButton()}
      </div>
    );
  },

  renderDeleteButton(dimensionName) {
    return (
      <Confirm
        icon="trash"
        text={`Are you sure you want to delete the dimension ${dimensionName}?`}
        title={`Delete Dimension ${dimensionName}`}
        buttonLabel="Delete"
        onConfirm={() => this.handleDelete(dimensionName)}
      >
        <div className="no-wrap pull-right">
          <Tooltip tooltip="Delete" placement="top">
            <IconButton
              variant="invisible"
              size="small"
              icon="trash"
              disabled={this.props.disabled}
            />
          </Tooltip>
        </div>
      </Confirm>
    );
  },

  handleDelete(dimensionName) {
    const dimensionsToSave = { ...this.props.value.dimensions };
    delete dimensionsToSave[dimensionName];
    this.props.onSave({ dimensions: dimensionsToSave });
  },

  renderAddButton() {
    return (
      <Button size="small" icon="plus" disabled={this.props.disabled} onClick={this.openModal}>
        New Date Dimension
      </Button>
    );
  },

  handleCreate(e) {
    e.preventDefault();
    e.stopPropagation();
    const newDimension = {
      ...this.state.newDimension,
      includeTime: !!this.state.newDimension.includeTime,
    };
    const name = this.state.newDimension.name;
    delete newDimension.name;
    if (newDimension.template === 'custom') {
      newDimension.template = newDimension.templateId;
    }
    delete newDimension.templateId;
    const dimensionsToSave = { ...this.props.value.dimensions, [name]: newDimension };
    this.props.onSave({ dimensions: dimensionsToSave }).then(this.closeModal);
  },
});

export default DimensionsSection;
