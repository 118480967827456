import { type MouseEvent, useCallback, useEffect } from 'react';

import { Button } from '@keboola/design';

import { KEBOOLA_FLOW, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import keyCodes from '@/constants/keyCodes';
import { constructMessage } from '@/modules/automations/helpers';
import { useSendMessage } from '@/modules/automations/queries';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames } from '@/modules/flows/constants';
import { routeNames as flowV2RouteNames } from '@/modules/flows-v2/constants';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  flow: {
    flowId: string;
    taskId?: string;
    scrollY?: string;
  };
  component: {
    id: string;
    type: string;
  };
  configuration: {
    id: string;
    configuration: Record<string, any>;
    version: string;
  };
  automationId: string;
  isAutomationView: boolean;
};

const BackToFlowButton = (props: Props) => {
  const { mutateAsync: sendMessageMutation, isPending: isLoading } = useSendMessage(
    props.automationId,
  );

  const redirectBackToFlow = useCallback(
    async (event: MouseEvent | KeyboardEvent) => {
      event.stopPropagation();

      const currentRouteName = RoutesStore.getCurrentRouteConfig()?.get('name');

      if (isLoading) return;

      if (props.isAutomationView && props.flow.taskId) {
        await sendMessageMutation(
          constructMessage({
            type:
              props.component.type === componentTypes.TRANSFORMATION
                ? 'transformationEditFinished'
                : 'configurationEditFinished',
            data: {
              taskId: props.flow.taskId,
              componentId: props.component.id,
              configurationId: props.configuration.id,
              configuration: props.configuration.configuration,
              configurationVersion: props.configuration.version,
            },
          }),
        );
      }

      if (currentRouteName !== RoutesStore.getCurrentRouteConfig()?.get('name')) return;

      // if flow-v2 config is found, use flow v2 route, otherwise use flow route
      const routeName =
        InstalledComponentsStore.getConfig(KEBOOLA_ORCHESTRATOR, props.flow.flowId).isEmpty() &&
        !InstalledComponentsStore.getConfig(KEBOOLA_FLOW, props.flow.flowId).isEmpty()
          ? flowV2RouteNames.DETAIL
          : routeNames.DETAIL;

      RoutesStore.getRouter().transitionTo(
        routeName,
        { config: props.flow.flowId },
        props.flow.scrollY ? { scroll: props.flow.scrollY } : null,
      );
    },
    [
      isLoading,
      props.flow,
      props.component,
      props.configuration,
      props.isAutomationView,
      sendMessageMutation,
    ],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        !document.body.classList.contains('modal-open') &&
        !document.body.classList.contains('full-screen-modal-open') &&
        event.key === keyCodes.ESCAPE
      ) {
        redirectBackToFlow(event);
      }
    };

    document.addEventListener('keydown', handleKeyDown, { passive: true });

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [redirectBackToFlow]);

  return (
    <Button
      variant="outline"
      onClick={redirectBackToFlow}
      isLoading={isLoading}
      disabled={isLoading}
    >
      Back to flow
    </Button>
  );
};

export default BackToFlowButton;
