import type { ReactNode } from 'react';
import _ from 'underscore';

import { Link } from '@keboola/design';

import ApplicationStore from '@/stores/ApplicationStore';
import string from '@/utils/string';

export const ExternalProjectLink = ({
  stackUrl,
  projectId,
  children,
}: {
  children: ReactNode;
  stackUrl?: string;
  projectId?: number;
}) => {
  if (!stackUrl || !projectId) {
    return children;
  }

  const projectPath = _.template(ApplicationStore.getProjectUrlTemplate())({ projectId });

  return <Link href={`${string.rtrim(stackUrl, '/')}${projectPath}`}>{children}</Link>;
};
