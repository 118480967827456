import { useState } from 'react';
import type { ReactNode } from 'react';
import type { Map } from 'immutable';

import { Card, RadioGroup } from '@keboola/design';

import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { CodeField } from '@/modules/data-apps/components/Deployment/CodeField';
import GitSetting from '@/react/common/GitSetting';

const UI_FORM = {
  GIT: 'git',
  CODE: 'code',
} as const;

export type UIFormType = (typeof UI_FORM)[keyof typeof UI_FORM];

type Props = {
  componentId: string;
  configId: string;
  configData: Map<string, any>;
  readOnly: boolean;

  type: UIFormType;
  onTypeChange: (type: UIFormType) => void;
};

export const DeploymentCard = ({
  type,
  onTypeChange,
  readOnly,
  componentId,
  configId,
  configData,
}: Props) => {
  const [isCodeSaving, setIsCodeSaving] = useState(false);
  const config = InstalledComponentsStore.getConfig(componentId, configId) as Map<any, any>;

  const handleCodeSave = (value: string) => {
    setIsCodeSaving(true);
    const newConfig = config
      .deleteIn(['configuration', 'parameters', 'dataApp', 'git'])
      .setIn(['configuration', 'parameters', 'script'], [value])
      .get('configuration');

    return InstalledComponentsActionCreators.saveComponentConfigData(
      componentId,
      configId,
      newConfig,
      'Change code',
    ).finally(() => setIsCodeSaving(false));
  };

  const renderBox = (content: ReactNode, saveButton?: ReactNode) => {
    return (
      <Card className="tw-my-6 tw-pb-6">
        <Card.Header className="tw-flex tw-min-h-10 tw-items-center tw-justify-between">
          <Card.Title>Deployment</Card.Title>
          {saveButton}
        </Card.Header>
        <Card.Content className="tw-flex tw-flex-col tw-gap-4">
          <RadioGroup variant="radio-block" value={type} onChange={onTypeChange}>
            <RadioGroup.Item value={UI_FORM.GIT}>Git Repository</RadioGroup.Item>
            <RadioGroup.Item value={UI_FORM.CODE}>Code</RadioGroup.Item>
          </RadioGroup>
          {content}
        </Card.Content>
      </Card>
    );
  };

  if (type === UI_FORM.GIT) {
    return (
      <GitSetting
        readOnly={readOnly}
        componentId={componentId}
        configId={configId}
        configData={configData}
        prepareDataBeforeSave={(configData) => {
          return configData
            .deleteIn(['parameters', 'dataApp', 'freezeDependencies'])
            .deleteIn(['parameters', 'script'])
            .deleteIn(['parameters', 'packages']);
        }}
      >
        {(renderContent, renderSaveButton) => {
          return renderBox(<div>{renderContent()}</div>, renderSaveButton());
        }}
      </GitSetting>
    );
  }

  return renderBox(
    <CodeField
      componentId={componentId}
      configId={configId}
      onSave={handleCodeSave}
      isLoading={isCodeSaving}
    />,
  );
};
