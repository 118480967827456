import { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Promise } from 'bluebird';
import { List, Map } from 'immutable';

import { ButtonGroup, FormGroup, IconButton, Label, TextInput } from '@keboola/design';

import { EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import {
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
} from '@/constants/componentIds';
import ComponentsActionCreators from '@/modules/components/ComponentsActionCreators';
import { features as componentFeatures } from '@/modules/components/Constants';
import { ensureComponentWithDetails, saveFolderToMetadata } from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { prepareRuntimesForTransformation } from '@/modules/runtimes/helpers';
import RuntimesStore from '@/modules/runtimes/store';
import { DBT_COMPONENTS, DBT_REMOTE_TRANSFORMATIONS } from '@/modules/transformations-v2/constants';
import { prepareTransformationData } from '@/modules/transformations-v2/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import { ListModal } from '@/react/common/ListModal';
import ModalIcon from '@/react/common/ModalIcon';
import Select from '@/react/common/Select';

const STEP_TRANSFORMATIONS_LIST = 'list';
const STEP_CREATE_CONFIGURATION = 'create';

const INITIAL_STATE = {
  step: STEP_TRANSFORMATIONS_LIST,
  component: null,
  isLoading: false,
  tempData: Map(),
};

class NewTransformationModal extends Component {
  state = INITIAL_STATE;

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          this.setState({
            ...INITIAL_STATE,
            ...(this.props.forceName && { tempData: Map({ name: this.props.forceName }) }),
          });

          if (this.props.forceComponent) {
            ensureComponentWithDetails(this.props.forceComponent.get('id')).then((component) => {
              this.setState({ step: STEP_CREATE_CONFIGURATION, component });
            });
          }
        }}
      >
        {this.renderBody()}
      </Modal>
    );
  }

  renderBody() {
    if (this.state.step === STEP_CREATE_CONFIGURATION) {
      return this.renderCreateConfigurationModal();
    }

    return this.renderTransformationsListModal();
  }

  renderTransformationsListModal() {
    const getNoCodeManipulation = (component) => {
      if (
        this.props.isDevModeActive &&
        component.get('id') === KEBOOLA_NO_CODE_DBT_TRANSFORMATION
      ) {
        return {
          isDisabled: true,
          disabledReason:
            'We are sorry no-code manipulation are not available in development branch at the moment',
        };
      }
      return {};
    };

    if (this.props.forceComponent) {
      return (
        <>
          <Modal.Header closeButton>
            <Modal.Title>New {this.props.forceComponent.get('name')} Transformation</Modal.Title>
            <ModalIcon icon="gear" color="green" bold />
          </Modal.Header>
          <Modal.Body>
            <p>Loading component details...</p>
          </Modal.Body>
        </>
      );
    }

    return (
      <ListModal
        title="New Transformation"
        suffix="Transformation"
        icon="gear"
        allowedComponents={this.props.allowedComponents}
        onSelectComponent={this.handleSelectComponent}
        filterComponents={(component) => !DBT_REMOTE_TRANSFORMATIONS.includes(component.get('id'))}
        getActionButtonProps={(component) => getNoCodeManipulation(component)}
      />
    );
  }

  renderCreateConfigurationModal() {
    const codePatterns = this.props.patternComponents.filter((component) => {
      return component
        .getIn(['configurationSchema', 'supported_components'], List())
        .includes(this.state.component.get('id'));
    });
    const componentId = this.state.component.get('id');
    const runtimes = RuntimesStore.getRuntimes(
      DBT_COMPONENTS.includes(componentId) ? KEBOOLA_DBT_TRANSFORMATION : componentId,
    );

    return (
      <form onSubmit={this.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>New {this.state.component.get('name')} Transformation</Modal.Title>
          <ModalIcon icon="gear" color="green" bold />
        </Modal.Header>
        <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
          <FormGroup>
            <Label htmlFor="name">Name</Label>
            <TextInput
              id="name"
              autoFocus
              variant="secondary"
              placeholder="Name your transformation"
              value={this.state.tempData.get('name', '')}
              onChange={(value) =>
                this.setState({ tempData: this.state.tempData.set('name', value) })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="description">
              Description <Label.Optional />
            </Label>
            <TextInput
              id="description"
              variant="secondary"
              placeholder="Describe your transformation"
              value={this.state.tempData.get('description', '')}
              onChange={(value) =>
                this.setState({ tempData: this.state.tempData.set('description', value) })
              }
            />
          </FormGroup>
          {this.renderWarehouseInput()}
          {this.state.component
            .get('features', List())
            .includes(componentFeatures.ALLOW_TAG_OVERRIDE) &&
            runtimes.length !== 0 && (
              <FormGroup>
                <Label htmlFor="backend-version">Backend Version</Label>
                <Select
                  id="backend-version"
                  clearable={false}
                  options={prepareRuntimesForTransformation(runtimes)}
                  value={this.state.tempData.get('image_tag', '')}
                  onChange={(selected) => {
                    this.setState({ tempData: this.state.tempData.set('image_tag', selected) });
                  }}
                />
              </FormGroup>
            )}
          <FormGroup>
            <Label htmlFor="folder">
              Folder <Label.Optional />
            </Label>
            <Select
              id="folder"
              allowCreate
              value={this.state.tempData.get('folder', '')}
              onChange={(folder) => {
                this.setState({ tempData: this.state.tempData.set('folder', folder) });
              }}
              placeholder="Select or create a folder"
              promptTextCreator={(label) => `Create folder "${label}"`}
              options={this.props.folders
                .filter((configs, componentId) => this.props.allowedComponents.has(componentId))
                .toList()
                .map((configs) => configs.toList())
                .flatten()
                .toSet()
                .sortBy((folder) => folder.toLowerCase())
                .map((folder) => ({ label: folder, value: folder }))
                .toArray()}
            />
          </FormGroup>
          {!this.props.hasPayAsYouGo && codePatterns.count() > 0 && (
            <FormGroup>
              <Label htmlFor="code-pattern">
                Use predefined code pattern <Label.Optional />
              </Label>
              <Select
                id="code-pattern"
                placeholder="Select pattern"
                value={this.state.tempData.get('pattern', '')}
                onChange={(selected) => {
                  this.setState({ tempData: this.state.tempData.set('pattern', selected) });
                }}
                options={codePatterns
                  .map((pattern) => ({ value: pattern.get('id'), label: pattern.get('name') }))
                  .toArray()}
              />
            </FormGroup>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup variant="block" space="extra-small">
            {!this.props.forceComponent && (
              <IconButton
                icon="arrow-left"
                variant="outline"
                className="tw-w-14"
                onClick={() => this.setState(INITIAL_STATE)}
              />
            )}
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel={
                this.state.isLoading ? 'Creating transformation...' : 'Create transformation'
              }
              isSaving={this.state.isLoading}
              isDisabled={this.isDisabled()}
            />
          </ButtonGroup>
        </Modal.Footer>
      </form>
    );
  }

  renderWarehouseInput() {
    if (!this.isDbtTransformation(this.state.component)) {
      return null;
    }

    const canUseKeboolaStorage = this.isDbtTransformationUsable(this.state.component);

    return (
      <FormGroup>
        <Label htmlFor="warehouse">
          Warehouse
          <InfoTooltip
            tooltip={`Choose when the transformation code will be executed.${
              canUseKeboolaStorage
                ? ' Default - Keboola storage. You can also choose various external DWH.'
                : ' You can choose various external DWH.'
            }`}
          />
        </Label>
        <Select
          id="warehouse"
          clearable={false}
          placeholder="Select warehouse"
          options={[
            ...(canUseKeboolaStorage
              ? [
                  {
                    value: this.state.component.get('id'),
                    label: 'Keboola Storage',
                  },
                ]
              : []),
            ...this.props.allowedComponents
              .filter((component) => DBT_REMOTE_TRANSFORMATIONS.includes(component.get('id')))
              .sortBy((component) => component.get('name'))
              .map((component) => ({
                value: component.get('id'),
                label: component.get('name'),
              }))
              .toArray(),
          ]}
          value={this.state.tempData.get('dbt_component', '')}
          onChange={(selected) => {
            this.setState({ tempData: this.state.tempData.set('dbt_component', selected) });
          }}
        />
      </FormGroup>
    );
  }

  handleSelectComponent = (component) => {
    /*
      Workaround to properly load all data for pattern compoennt.
      We have only one pattern and probably no more will be puplished, so it is save
    */
    return Promise.each(this.props.patternComponents.toArray(), (component) => {
      return ComponentsActionCreators.loadComponent(component.get('id'));
    })
      .then(() => ensureComponentWithDetails(component.get('id')))
      .then((component) => {
        let data = Map({
          name: '',
          description: '',
          pattern: '',
        });

        if (this.isDbtTransformation(component)) {
          data = data.set(
            'dbt_component',
            this.isDbtTransformationUsable(component) ? component.get('id') : '',
          );
        }

        this.setState({ step: STEP_CREATE_CONFIGURATION, component, tempData: data });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });

    Promise.resolve()
      .then(() => {
        if (!this.state.tempData.has('dbt_component')) {
          return this.state.component;
        }

        return ensureComponentWithDetails(this.state.tempData.get('dbt_component'));
      })
      .then((component) => prepareTransformationData(component, this.state.tempData))
      .then(({ componentId, data }) => {
        return InstalledComponentsActionCreators.createConfiguration(componentId, data)
          .tap((response) => {
            if (!!this.state.tempData.get('folder')) {
              return saveFolderToMetadata(
                componentId,
                response.id,
                this.state.tempData.get('folder'),
              );
            }
          })
          .then((response) => this.props.onCreate(componentId, response.id));
      })
      .then(() => {
        this.setState(INITIAL_STATE);
        this.props.onHide();
      });
  };

  isDbtTransformation(component) {
    return [KEBOOLA_DBT_TRANSFORMATION, KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY].includes(
      component.get('id'),
    );
  }

  isDisabled() {
    if (!this.state.tempData.get('name', '').trim()) {
      return true;
    }

    if (this.isDbtTransformation(this.state.component)) {
      return (
        !this.state.tempData.get('dbt_component') &&
        !this.isDbtTransformationUsable(this.state.component)
      );
    }

    return false;
  }

  isDbtTransformationUsable(component) {
    return !component.get('flags', List()).includes(EXCLUDE_FROM_NEW_LIST);
  }
}

NewTransformationModal.propTypes = {
  patternComponents: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  folders: PropTypes.instanceOf(Map).isRequired,
  hasPayAsYouGo: PropTypes.bool.isRequired,
  show: PropTypes.bool.isRequired,
  onCreate: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  forceComponent: PropTypes.instanceOf(Map),
  forceName: PropTypes.string,
};

export default NewTransformationModal;
