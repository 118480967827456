import type { Map } from 'immutable';

import { URLS } from '@keboola/constants';
import { Link } from '@keboola/design';

import { CircleIcon } from '@/react/common';
import ProductFruitsChecklists from './ProductFruitsChecklists';

const DemoPreviewDashboard = (props: { allComponents: Map<string, any> }) => {
  return (
    <>
      <div className="mbp-8 text-center">
        <h1 className="mtp-8 mbp-1 f-24 line-height-32">Welcome to the Keboola demo project</h1>
        <p className="font-medium f-16 line-height-24 color-base">
          In this demo project, you will explore powerful use cases of the Keboola Data Platform
          through our onboarding tasks.
        </p>
      </div>
      <ProductFruitsChecklists allComponents={props.allComponents} />
      <div>
        <h3 className="mtp-8 mbp-4 text-center color-base line-height-24">Do you need help?</h3>
        <div className="box-container three-columns">
          <Link href={URLS.USER_DOCUMENTATION} className="box">
            <CircleIcon icon={['far', 'book']} className="pp-6" />
            Documentation
          </Link>
          <Link href={URLS.ACADEMY} className="box">
            <CircleIcon icon={['far', 'graduation-cap']} bigger />
            Academy
          </Link>
          <Link className="box" href={URLS.SALES_CONTACT_FORM}>
            <CircleIcon icon={['far', 'headset']} className="pp-6" />
            Talk with Sales or an Engineer
          </Link>
        </div>
      </div>
    </>
  );
};

export default DemoPreviewDashboard;
