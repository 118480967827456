import { useCallback, useMemo, useState } from 'react';
import type { KeyboardEvent, MouseEvent } from 'react';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Map } from 'immutable';

import { cn, Search } from '@keboola/design';

import { HIDDEN_COLUMNS_WIDTH_LIMIT } from '@/constants';
import type { BucketColumn, BucketType, SourceTab } from '@/modules/data-catalog/types';
import { getFilteredSortedBuckets, getLinkToDetail } from '@/modules/data-catalog/utils';
import { onTableRowKeyDown } from '@/react/common/ConfigurationsTable/helpers';
import NoResultsFound from '@/react/common/NoResultsFound';
import useMatchMedia from '@/react/hooks/useMatchMedia';
import RoutesStore from '@/stores/RoutesStore';
import { isInteractiveElement } from '@/utils';
import hasSelections from '@/utils/hasSelections';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import { FilterButton } from './FilterButton';
import { FilterButtons } from './FilterButtons';

const HIDDEN_COLUMNS_FOR_SMALL_SCREEN = ['last_modified', 'size'];

type Props = {
  buckets: Map<string, any>;
  getColumns: (query: string) => BucketColumn[];
  sourceTab: SourceTab;
  currentAdmin: Map<string, any>;
  sorter: (a: Map<string, any>, b: Map<string, any>) => number;
};

export const DataCatalogTable = ({
  buckets,
  getColumns,
  sourceTab,
  currentAdmin,
  sorter,
}: Props) => {
  const [query, setQuery] = useState(
    RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
  );

  const [searchFilters, setSearchFilters] = useState<Map<string, any>>(Map());

  const data = useMemo(
    () => getFilteredSortedBuckets(buckets.toArray(), searchFilters, currentAdmin, query, sorter),
    [buckets, searchFilters, currentAdmin, query, sorter],
  );
  const columns = useMemo(() => getColumns(query), [getColumns, query]);

  const tableInstance = useReactTable<BucketType>({
    data,
    columns,
    getRowId: (row) => row.get('id'),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleRowAction = useCallback(
    (bucket: BucketType) => (e?: MouseEvent | KeyboardEvent) => {
      if (hasSelections()) {
        return;
      }

      const { name, params } = getLinkToDetail({ bucket, sourceTab });

      if (shouldUseNewWindow(e)) {
        return windowOpen(createUrlWithBasename(name, params));
      }

      return RoutesStore.getRouter().transitionTo(name, params);
    },
    [sourceTab],
  );

  const handleRowClick = useCallback(
    (bucket: BucketType, event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!isInteractiveElement(target)) {
        handleRowAction(bucket)(event);
      }
    },
    [handleRowAction],
  );

  const matchMediaHandler = useCallback(
    ({ matches }: { matches: boolean }) => {
      tableInstance
        .getAllColumns()
        .filter((column) => HIDDEN_COLUMNS_FOR_SMALL_SCREEN.includes(column.id))
        .forEach((column) => column.toggleVisibility(!!matches));
    },
    [tableInstance],
  );

  useMatchMedia(`(min-width: ${HIDDEN_COLUMNS_WIDTH_LIMIT}px)`, matchMediaHandler);

  return (
    <div className="tw-flex tw-flex-col tw-gap-6">
      <Search
        defaultValue={query}
        placeholder={`Search data catalog (${buckets.count()})`}
        onChange={(query) => {
          setQuery(query);
          RoutesStore.getRouter().updateQuery({ q: query });
        }}
        suffix={
          <FilterButtons
            bucketsCount={buckets.count()}
            renderFilterButton={(type, group, label) => (
              <FilterButton
                type={type}
                group={group}
                label={label}
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
              />
            )}
          />
        }
      />
      {!data.length ? (
        <NoResultsFound entityName="shared buckets" />
      ) : (
        <div className="box tw-overflow-x-auto">
          <div className="table table-hover react-table [&_.tbody_.tr]:tw-h-[58px] [&_.td]:tw-align-middle max-md:[&_.td]:!tw-p-2">
            <div className="thead">
              {tableInstance.getHeaderGroups().map((headerGroup) => (
                <div
                  key={headerGroup.id}
                  className="tr with-action-button is-sticky bg-color-white"
                >
                  {headerGroup.headers.map((header) => (
                    <div
                      key={header.column.id}
                      className={cn('th', {
                        'text-right': [
                          'last_updated_by',
                          'last_modified',
                          'size',
                          'datashare_owner',
                        ].includes(header.column.id),
                      })}
                    >
                      {flexRender(header.column.columnDef.header, header.getContext())}
                    </div>
                  ))}
                </div>
              ))}
            </div>

            <div className="tbody">
              {tableInstance.getRowModel().rows.map((row) => (
                <div
                  key={row.id}
                  className="tr hoverable-actions-with-replacement clickable"
                  onClick={(event) => handleRowClick(row.original, event)}
                  onMouseDown={simulateClickIfMiddleMouseIsUsed.mousedown}
                  onMouseUp={simulateClickIfMiddleMouseIsUsed.mouseup}
                  onKeyDown={onTableRowKeyDown(handleRowAction(row.original))}
                  role="button"
                  tabIndex={0}
                >
                  {row.getVisibleCells().map((cell) => (
                    <div
                      key={cell.id}
                      className={cn('td', {
                        'text-right': [
                          'last_updated_by',
                          'last_modified',
                          'size',
                          'datashare_owner',
                        ].includes(cell.column.id),
                      })}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
