import { useState } from 'react';
import { fromJS, Map } from 'immutable';

import { cn } from '@keboola/design';

import { componentTypes } from '@/constants';
import { getNewComponentTypeLabel } from '@/modules/components/helpers';
import SpecificRowsIcon from '@/modules/flows/components/SpecificRowsIcon';
import TaskIcon from '@/modules/flows/components/TaskIcon';
import TaskName from '@/modules/flows/components/TaskName';
import { resolveComponentId } from '@/modules/flows/helpers';
import { deleteTask } from '@/modules/flows-v2/builder/actions';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { VisualJobTask } from '@/modules/flows-v2/builder/types';
import { routeNames as storageRoutesNames } from '@/modules/storage/constants';
import StorageDataModal from '@/react/common/StorageDataModal';
import RoutesStore from '@/stores/RoutesStore';
import TaskActions from './TaskActions';
import { TaskBox } from './TaskBox';

export const PhaseJobTask = ({ task, readOnly }: { task: VisualJobTask; readOnly: boolean }) => {
  const [exploreBuckets, setExploreBuckets] = useState<Map<string, any>>(Map());

  const [context, selectedTaskId, setSelectedTaskId] = useBuilderStore((state) => [
    state.context,
    state.selectedTaskId,
    state.setSelectedTaskId,
  ]);

  const { config, tasks, configurations, tablesMetadataMap } = context;
  const realComponentId = resolveComponentId(configurations, fromJS(task));
  const buckets = tablesMetadataMap
    .get(`${realComponentId}:${task.configId}`, Map())
    .groupBy((table: Map<string, any>) => table.getIn(['bucket', 'id']));

  const onExploreData = () => {
    if (buckets.count() > 1) {
      setExploreBuckets(buckets);
      return;
    }

    RoutesStore.getRouter().transitionTo(
      storageRoutesNames.BUCKET,
      { bucketId: buckets.keySeq().first() },
      null,
      null,
      { flowId: config.get('id'), scrollY: window.scrollY },
    );
  };

  const handleDeleteTask = () => {
    deleteTask(config.get('id'), task.id, tasks);
    setSelectedTaskId(null);
  };

  return (
    <TaskBox
      onClick={() => setSelectedTaskId(task.id)}
      active={selectedTaskId === task.id}
      className={cn({
        'tw-border-error-500': task.hasDeletedConfiguration,
        'tw-border-warning-500': !task.configId,
        '[&>img]:tw-opacity-50 [&>h4]:tw-opacity-50': !task.enabled,
        'tw-bg-neutral-50': task.hasDeletedConfiguration || !task.enabled || !task.configId,
      })}
    >
      <TaskIcon size={24} src={task.iconUrl} className="tw-mr-0" />
      <TaskName
        name={task.name}
        componentName={task.component}
        componentType={task.type}
        isBlank={!task.configId}
        isDeleted={task.hasDeletedConfiguration}
      />
      {task.specificRows.length > 0 && (
        <div className="tw-absolute tw-right-2 tw-top-2 tw-flex tw-gap-2">
          {task.specificRows.length > 0 && <SpecificRowsIcon rows={fromJS(task.specificRows)} />}
        </div>
      )}

      <TaskActions
        readOnly={readOnly}
        onDelete={handleDeleteTask}
        onOpen={() => setSelectedTaskId(task.id)}
        {...(!buckets.isEmpty() &&
          !!task.configId &&
          task.type !== getNewComponentTypeLabel(componentTypes.WRITER) && {
            onExploreData: () => onExploreData(),
          })}
      />

      <StorageDataModal
        show={!exploreBuckets.isEmpty()}
        onHide={() => setExploreBuckets(Map())}
        buckets={exploreBuckets}
      />
    </TaskBox>
  );
};
