import { type ComponentProps, type ReactNode, startTransition, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { DragDropProvider, DragOverlay } from '@dnd-kit/react';
import type { ReactFlowState } from '@xyflow/react';
import { useStore } from '@xyflow/react';

import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import { updateLocalStateValue } from '@/modules/flows-v2/helpers';
import { PhaseTask } from './PhaseTask';

const zoomSelector = (state: ReactFlowState) => state.transform[2];

export const DndProvider = (props: { children: ReactNode }) => {
  const [dragging, setDragging] = useState<{ phaseId: string; taskId: string } | null>(null);

  const [context, nodes] = useBuilderStore((state) => [state.context, state.nodes]);
  const zoom = useStore(zoomSelector);

  const { config, tasks } = context;

  const task = useMemo(() => {
    if (!dragging) return null;

    return nodes
      .flatMap((node) => node.data?.tasks || [])
      .find((task) => task.id === dragging.taskId);
  }, [nodes, dragging]);

  const onDragStart: ComponentProps<typeof DragDropProvider>['onDragStart'] = (event) => {
    if (!event.operation.source) {
      return;
    }

    const [phaseId, taskId] = String(event.operation.source.id).split(':');

    setDragging({ phaseId, taskId });
  };

  const onDragEnd: ComponentProps<typeof DragDropProvider>['onDragEnd'] = (event) => {
    const targetPhaseId = event.operation?.target?.id;

    if (dragging?.taskId && targetPhaseId && !event.canceled) {
      const newTasks = tasks.map((task) => {
        if (task.get('id') === dragging.taskId) {
          return task.set('phase', targetPhaseId);
        }

        return task;
      });

      startTransition(() => updateLocalStateValue(config.get('id'), ['tasks'], newTasks));
    }

    setDragging(null);
  };

  const renderDragTask = () => {
    if (!dragging || !task) {
      return null;
    }

    const phaseTask = <PhaseTask phaseId={dragging.phaseId} task={task} readOnly />;

    if (zoom !== 1) {
      return (
        <div style={{ transform: `scale(${zoom})`, transformOrigin: 'top left' }}>{phaseTask}</div>
      );
    }

    return phaseTask;
  };

  return (
    <DragDropProvider onDragStart={onDragStart} onDragEnd={onDragEnd}>
      {props.children}
      {createPortal(<DragOverlay>{renderDragTask()}</DragOverlay>, document.body)}
    </DragDropProvider>
  );
};
