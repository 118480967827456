import { Fragment, useState } from 'react';
import type { SyntheticEvent } from 'react';
import { List, type Map } from 'immutable';
import _ from 'underscore';

import { Button, ButtonInline, Icon, IconButton, Tooltip } from '@keboola/design';

import type { ACTIVE_MENU } from '@/modules/ex-generic/constants';
import { JOB_NAME } from '@/modules/ex-generic/constants';
import { getMenuAfterEndpointDeletion, prepareRealJobPath } from '@/modules/ex-generic/helpers';
import { Truncated } from '@/react/common';
import ConnectorIcon from '@/react/common/ConnectorIcon';
import MenuItem from './MenuItem';
import NewEndpoint from './NewEndpoint';

const Endpoints = (props: {
  menu: ACTIVE_MENU;
  setMenu: (menu: ACTIVE_MENU) => void;
  parameters: Map<string, any>;
  readOnly: boolean;
  onSave: (
    parameters: Map<string, any>,
    changeDescription: string,
    newMenu?: ACTIVE_MENU,
  ) => Promise<any>;
}) => {
  const [show, setShow] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const jobs = props.parameters.getIn(['config', 'jobs'], List());
  const hasBaseURL = !!props.parameters.getIn(['api', 'baseUrl']);

  const handleDelete = (jobPath: number[]) => {
    setDeleting(true);

    return props
      .onSave(
        props.parameters.deleteIn(['config', 'jobs', ...prepareRealJobPath(jobPath)]),
        'Delete endpoint',
        getMenuAfterEndpointDeletion(jobPath, props.menu),
      )
      .finally(() => setDeleting(false));
  };

  const renderEndpoints = (jobs: List<any>, path: number[] = []): any[] => {
    return jobs
      .map((job: Map<string, any>, index) => {
        const endpoint = job.get('endpoint');
        const fullPath = [...path, index || 0];

        return (
          <Fragment key={endpoint}>
            <MenuItem
              onClick={() => props.setMenu(fullPath)}
              isActive={_.isEqual(props.menu, fullPath)}
              additionalActions={
                !props.readOnly && (
                  <Tooltip tooltip="Delete endpoint" placement="top">
                    <IconButton
                      variant="inline"
                      onClick={(e: SyntheticEvent) => {
                        e.stopPropagation();

                        handleDelete(fullPath);
                      }}
                      isLoading={deleting}
                      icon="trash"
                    />
                  </Tooltip>
                )
              }
            >
              {fullPath.length > 1 && (
                <ConnectorIcon
                  className="tw-mr-2"
                  style={{ marginLeft: 4 + (fullPath.length - 2) * 8 }}
                />
              )}
              <Truncated text={job.get(JOB_NAME, endpoint)} />
            </MenuItem>
            {!job.get('children', List()).isEmpty() &&
              renderEndpoints(job.get('children'), fullPath)}
          </Fragment>
        );
      })
      .toArray();
  };

  return (
    <>
      <h4 className="tw-flex tw-justify-between tw-px-2 tw-text-xs tw-font-semibold tw-uppercase tw-tracking-widest">
        Endpoints
        {!props.readOnly && !jobs.isEmpty() && (
          <ButtonInline
            variant="link"
            color="custom"
            className="tw-text-xs tw-font-medium tw-uppercase tw-tracking-widest tw-text-neutral-400"
            onClick={() => setShow(true)}
          >
            <Icon icon="plus" className="tw-text-primary-500" />
            Create
          </ButtonInline>
        )}
      </h4>
      {!props.readOnly && jobs.isEmpty() ? (
        <div className="tw-my-4 tw-flex tw-justify-center">
          <Tooltip
            placement="top"
            type="explanatory"
            tooltip="Please set up the base URL first."
            forceHide={hasBaseURL}
          >
            <Button disabled={!hasBaseURL} onClick={() => hasBaseURL && setShow(true)}>
              <Icon icon="plus" />
              New Endpoint
            </Button>
          </Tooltip>
        </div>
      ) : (
        <div className="tw-flex tw-flex-col tw-gap-1">{renderEndpoints(jobs)}</div>
      )}
      <NewEndpoint
        show={show}
        parameters={props.parameters}
        onSave={props.onSave}
        setMenu={props.setMenu}
        onHide={() => setShow(false)}
      />
    </>
  );
};

export default Endpoints;
