import { Badge, cn } from '@keboola/design';

type Props = {
  className?: string;
  isDraft?: boolean;
};

const ConfigurationFromAutomationLabel = (props: Props) => {
  return (
    <Badge
      text={props.isDraft ? 'Draft' : 'AI Generated'}
      className={cn(
        'tw-shrink-0 tw-bg-gradient-to-r tw-from-[#6F36E0] tw-to-[#0975E0] tw-decoration-transparent',
        props.className,
      )}
    />
  );
};

export default ConfigurationFromAutomationLabel;
