import { Component } from 'react';
import type { Map } from 'immutable';

import { HelpBlock, IconButton, Tooltip } from '@keboola/design';

import { STAGE } from '@/constants';
import StorageApiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { tableIdWarning } from '@/modules/storage/constants';
import { getBucketDisplayNameFromName } from '@/modules/storage/helpers';
import tableIdParser, { parse as parseTable } from '@/utils/tableIdParser';
import validateStorageTableId from '@/utils/validateStorageTableId';
import DestinationTableSelector from './DestinationTableSelector';

type Props = {
  tables: Map<string, any>;
  buckets: Map<string, any>;
  disabled: boolean;
  onChange: (value: string) => void;
  onEdit: () => void;
  editing: boolean;
  value: string;
};

class TableSelectorForm extends Component<Props> {
  constructor(props: Props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.parseDestination = this.parseDestination.bind(this);
    this.updateDestinationPart = this.updateDestinationPart.bind(this);
  }

  render() {
    if (this.props.editing) {
      return (
        <>
          <DestinationTableSelector
            buckets={this.props.buckets}
            tables={this.props.tables}
            parts={this.parseDestination().parts}
            updatePart={this.updateDestinationPart}
            disabled={this.props.disabled}
          />
          {this.renderHelp()}
        </>
      );
    }

    return (
      <>
        <div className="tw-flex tw-flex-row tw-gap-2">
          <StorageApiTableLinkEx tableId={this.props.value} />
          <Tooltip tooltip="Edit table name" placement="top">
            <IconButton
              variant="inline"
              icon="pen"
              onClick={this.props.onEdit}
              disabled={this.props.disabled}
            />
          </Tooltip>
        </div>
        {this.renderHelp()}
      </>
    );
  }

  parseDestination() {
    return tableIdParser.parse(this.props.value, { defaultStage: STAGE.OUT });
  }

  updateDestinationPart(partName: string, value: string) {
    return this.handleChange(this.parseDestination().setPart(partName, value).tableId);
  }

  renderHelp() {
    if (this.props.value && !validateStorageTableId(this.props.value)) {
      return (
        <HelpBlock className="tw-mt-1" state="error">
          {tableIdWarning}
        </HelpBlock>
      );
    }

    const parsedTable = parseTable(this.props.value).parts;
    const alreadyExists = this.props.tables
      .groupBy((table) => table.getIn(['bucket', 'id']))
      .map((tables) => tables?.first().get('bucket'))
      .filter((bucket) => bucket.get('stage') === parsedTable.stage)
      .find((bucket) => {
        return (
          bucket.get('name') !== parsedTable.bucket &&
          bucket.get('displayName') === getBucketDisplayNameFromName(parsedTable.bucket)
        );
      });

    if (alreadyExists) {
      return (
        <HelpBlock className="tw-mt-1" state="error">
          The bucket name {getBucketDisplayNameFromName(parsedTable.bucket)} already exists in the
          selected stage.
        </HelpBlock>
      );
    }

    return (
      <HelpBlock className="tw-mt-1">
        Table in Storage where the data will be imported. If the table does not exist, it will be
        created.
      </HelpBlock>
    );
  }

  handleChange(selectedValue: string) {
    const value = selectedValue.trim();

    return this.props.onChange(value);
  }
}

export default TableSelectorForm;
