import { Modal } from 'react-bootstrap';

import type { EditTaskProperties, VisualNotificationTask } from '@/modules/flows-v2/builder/types';
import { InlineEdit } from './InlineEdit';
import { NotificationDetail } from './NotificationDetail';
import { SidebarModal } from './SidebarModal';
import { SidebarModalHeader } from './SidebarModalHeader';

export const NotificationDetailModal = ({
  task,
  readOnly,
  onHide,
  onEditTask,
}: {
  task: VisualNotificationTask;
  readOnly: boolean;
  onHide: () => void;
  onEditTask: (taskId: string, properties: EditTaskProperties) => void;
}) => {
  const handleUpdateTask = (properties: EditTaskProperties) => {
    onEditTask(task.id, properties);
  };

  return (
    <SidebarModal onHide={onHide}>
      <SidebarModalHeader onHide={onHide}>
        <InlineEdit
          name={task.name}
          onChange={(name: string) => {
            handleUpdateTask([
              { keysPath: ['name'], value: name },
              { keysPath: ['task', 'title'], value: name },
            ]);
          }}
          placeholder="Set title"
        />
      </SidebarModalHeader>
      <Modal.Body className="tw-flex tw-grow tw-flex-col tw-gap-4 tw-overflow-hidden tw-px-3 tw-py-1">
        <NotificationDetail task={task} readOnly={readOnly} onChange={handleUpdateTask} />
      </Modal.Body>
    </SidebarModal>
  );
};
