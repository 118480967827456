import { KeboolaHttpHeader } from '../../constants';
import {
  createCallbackMiddleware,
  createOpenapiFetchClient,
  HttpHeader,
  parseContentDispositionHeader,
} from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';
import type {
  GetDataAppLogsDownloadPath,
  GetDataAppLogsTailPath,
  GetDataAppLogsTailQuery,
  PatchDataAppBody,
  PatchDataAppPath,
} from './types';
import { calculateNextLogTimestamp, parseLastLogTimestamp } from './utils';

export const createDataScienceClient = ({ baseUrl, callbacks, token }: ClientInitOptions) => {
  const client = createOpenapiFetchClient<paths>({
    baseUrl: baseUrl,
    middlewares: [createCallbackMiddleware(callbacks)],
    headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
  });

  const getAppLogsDownload = async (
    id: GetDataAppLogsDownloadPath['appId'],
    signal?: AbortSignal,
  ) => {
    const { data, response } = await client.get(
      '/apps/{appId}/logs/download',
      { path: { appId: id } },
      { signal },
    );

    const contentDisposition = parseContentDispositionHeader(
      response.headers.get(HttpHeader.CONTENT_DISPOSITION),
    );

    return {
      logs: data,
      fileName: contentDisposition?.filename ?? 'logs.txt',
    };
  };

  const getAppLogsTail = async (
    id: GetDataAppLogsTailPath['appId'],
    { query, signal }: { query?: GetDataAppLogsTailQuery; signal?: AbortSignal } = {},
  ) => {
    const { data } = await client.get(
      '/apps/{appId}/logs/tail',
      {
        query,
        path: {
          appId: id,
        },
      },
      {
        signal,
      },
    );

    const logs = data === '' ? null : data;
    const lastLogTimestamp = parseLastLogTimestamp(logs);
    const nextLogTimestamp = calculateNextLogTimestamp(lastLogTimestamp);

    return {
      logs,
      nextLogTimestamp: nextLogTimestamp,
    };
  };

  const patchApp = async (
    appId: PatchDataAppPath['appId'],
    body: PatchDataAppBody,
    signal?: AbortSignal,
  ) => {
    const { data } = await client.patch(
      '/apps/{appId}',
      {
        path: { appId: appId },
        body: body,
      },
      { signal },
    );
    return data;
  };

  return {
    getAppLogsDownload,
    getAppLogsTail,
    patchApp,
  };
};
