import { Component } from 'react';
import PropTypes from 'prop-types';

import { cn, Icon } from '@keboola/design';

import { REQUESTABLE_FEATURES } from '@/constants/features';
import { defaultTransformationBackendSize } from '@/modules/components/Constants';
import { getTransformationBackendSizes } from '@/modules/components/helpers';
import { SNOWFLAKE_BACKEND_TRANSFORMATIONS } from '@/modules/transformations-v2/constants';
import FeatureRequestButton from '@/react/common/FeatureRequestButton';
import InfoTooltip from '@/react/common/InfoTooltip';
import Loader from '@/react/common/Loader';
import Select from '@/react/common/Select';
import string from '@/utils/string';
import BackendSizeIcons from './BackendSizeIcons';
import BackendSizeLabel from './BackendSizeLabel';

class BackendSizeSelect extends Component {
  state = {
    isOpen: false,
    isLoading: false,
  };

  componentDidMount() {
    document.addEventListener('click', this.closeSelect, { passive: true });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.closeSelect);
  }

  render() {
    const isSnowflakeBased = SNOWFLAKE_BACKEND_TRANSFORMATIONS.includes(this.props.componentId);

    if (
      isSnowflakeBased &&
      !this.props.hasPayAsYouGo &&
      !this.props.hasSnowflakeDynamicBackendSize
    ) {
      return null;
    }

    const currentSize = this.getCurrentSize();

    if (this.props.readOnly && this.props.simple) {
      return (
        <BackendSizeLabel componentId={this.props.componentId} size={currentSize} emphasized />
      );
    }

    if (
      !this.props.readOnly &&
      !this.props.isSingleTenant &&
      ((isSnowflakeBased && !this.props.hasSnowflakeDynamicBackendSize) ||
        !this.props.hasJobsDynamicBackendSize)
    ) {
      const feature = isSnowflakeBased
        ? REQUESTABLE_FEATURES.FEATURE_WORKSPACE_SNOWFLAKE_DYNAMIC_BACKEND_SIZE
        : REQUESTABLE_FEATURES.FEATURE_DYNAMIC_BACKEND_JOBS;

      return (
        <FeatureRequestButton feature={feature} asChild>
          <button className="btn btn-link">
            <Icon icon={feature.buttonIcon} fixedWidth />
            {feature.buttonLabel}
            <InfoTooltip tooltip="The Dynamic Backend Size feature allows you to allocate more resources for transformations, improving performance on large datasets or complex queries." />
          </button>
        </FeatureRequestButton>
      );
    }

    return (
      <Select
        clearable={false}
        searchable={false}
        value={currentSize}
        menuIsOpen={this.state.isOpen}
        disabled={this.state.isLoading || this.props.readOnly}
        className="backend-size-select"
        controlRenderer={() => {
          if (this.props.simple) {
            const isDefaultSize = [
              this.props.defaultSize,
              defaultTransformationBackendSize,
            ].includes(currentSize);

            return (
              <button
                onClick={this.toggleSelect}
                disabled={this.state.isLoading}
                className={cn('btn btn-link', {
                  'default-backend-size-placeholder text-muted': isDefaultSize,
                })}
              >
                {isDefaultSize ? (
                  'Change default size'
                ) : (
                  <BackendSizeLabel
                    componentId={this.props.componentId}
                    size={currentSize}
                    emphasized
                  />
                )}
              </button>
            );
          }

          return (
            <button
              disabled={this.props.readOnly || this.state.isLoading}
              className="btn btn-link"
              onClick={this.toggleSelect}
            >
              {this.state.isLoading ? <Loader /> : <Icon icon="rocket" fixedWidth />}Backend size:{' '}
              <span className="tw-font-medium tw-capitalize">{currentSize}</span>
            </button>
          );
        }}
        onChange={(size) => {
          if (size === currentSize) {
            return;
          }

          this.setState({ isLoading: true });
          return this.props
            .changeBackendSize(size)
            .finally(() => this.setState({ isLoading: false }));
        }}
        options={this.prepareOptions(currentSize)}
      />
    );
  }

  renderRockets = (sizes, size) => {
    return (
      <span className="pl-1 tw-inline-flex tw-gap-3 tw-text-base">
        <BackendSizeIcons allSizes={sizes} size={size} />
      </span>
    );
  };

  prepareOptions = (currentSize) => {
    const sizes = getTransformationBackendSizes(this.props.componentId);
    const defaultSize = this.props.defaultSize || defaultTransformationBackendSize;

    let options = sizes.map((size) => {
      return {
        value: size,
        label: (
          <div className="tw-flex tw-items-center tw-justify-between">
            {string.capitalize(size)}
            {this.renderRockets(sizes, size)}
          </div>
        ),
      };
    });

    if (this.props.simple && currentSize !== defaultSize) {
      options = [
        {
          value: defaultSize,
          label: (
            <div className="tw-flex tw-items-center tw-justify-between">
              Default size
              {this.renderRockets(sizes, defaultSize)}
            </div>
          ),
        },
        ...options,
      ];
    }

    return options;
  };

  closeSelect = () => {
    if (this.state.isOpen) {
      this.setState({ isOpen: false });
    }
  };

  toggleSelect = (event) => {
    event.stopPropagation();

    this.setState({ isOpen: !this.state.isOpen });
  };

  getCurrentSize = () => {
    return this.props.currentSize || this.props.defaultSize || defaultTransformationBackendSize;
  };
}

BackendSizeSelect.propTypes = {
  componentId: PropTypes.string.isRequired,
  changeBackendSize: PropTypes.func.isRequired,
  hasSnowflakeDynamicBackendSize: PropTypes.bool.isRequired,
  hasJobsDynamicBackendSize: PropTypes.bool.isRequired,
  isSingleTenant: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  currentSize: PropTypes.string,
  defaultSize: PropTypes.string,
  simple: PropTypes.bool,
  hasPayAsYouGo: PropTypes.bool,
};

BackendSizeSelect.defaultProps = {
  readOnly: false,
  simple: false,
  hasPayAsYouGo: false,
};

export default BackendSizeSelect;
