import type { Map } from 'immutable';

import { CollapsiblePanel } from '@keboola/design';

import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { tableName } from '@/modules/storage/helpers';
import { RouterLink } from '@/react/common';
import ProjectAliasLink from './ProjectAliasLink';

type Props = {
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  tableAliases: Map<string, any>[];
  tableLinks: Map<string, any>[];
  onLinkClick: () => void;
};

const TableAliasesAndLinks = (props: Props) => {
  const hasAliasesOrLinks = props.tableAliases.length > 0 || props.tableLinks.length > 0;

  if (!hasAliasesOrLinks) {
    return null;
  }

  return (
    <CollapsiblePanel
      defaultExpanded
      header="Alias tables"
      className="tw-my-4"
      bodyClassName="tw-p-0"
    >
      <table className="table table-hover">
        <tbody>
          {props.tableAliases.map((alias: Map<string, any>) => (
            <tr key={`alias-${alias.get('id')}`}>
              <td className="!tw-px-4">
                <RouterLink
                  to={routeNames.TABLE}
                  params={{
                    bucketId: alias.getIn(['bucket', 'id']),
                    bucketTab: bucketTabs.OVERVIEW,
                    tableName: alias.get('name'),
                    tableTab: tableTabs.OVERVIEW,
                  }}
                  onClick={props.onLinkClick}
                >
                  {tableName(alias)}
                </RouterLink>
              </td>
            </tr>
          ))}

          {props.tableLinks.map((alias: Map<string, any>) => (
            <tr key={`link-${alias.get('id')}`}>
              <td className="!tw-px-4">
                <ProjectAliasLink
                  sapiToken={props.sapiToken}
                  urlTemplates={props.urlTemplates}
                  alias={alias}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </CollapsiblePanel>
  );
};

export default TableAliasesAndLinks;
