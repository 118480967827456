import { useMemo, useState } from 'react';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { Button, ButtonGroup, ClipboardButton, Icon } from '@keboola/design';

import CodeEditorModal from '@/react/common/CodeEditorModal';
import SaveButtonWithDescription from '@/react/common/SaveButtonWithDescription';
import { updateConfiguration } from './generic/code-blocks/actions';
import {
  getBlocksAsString,
  getBlocksDefaultValue,
  getTablesHints,
  getVariablesHints,
  hasQueries,
  prepareMultipleBlocks,
  resolveEditorMode,
} from './generic/code-blocks/helpers';

const CodeBlocksHeader = (props: {
  readOnly: boolean;
  config: Map<string, any>;
  component: Map<string, any>;
  blocks: List<any>;
  sharedCodes: List<any>;
  tables: Map<string, any>;
  variables: Map<string, any>;
}) => {
  const savedCode = useMemo(() => {
    return getBlocksAsString(props.component.get('id'), props.blocks, props.sharedCodes);
  }, [props.component, props.blocks, props.sharedCodes]);

  const [showAllCodeBlocksModal, setShowAllCodeBlocksModal] = useState(false);
  const [changeDescription, setChangeDescription] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const onOpenEditor = () => {
    setShowAllCodeBlocksModal(true);
    setEditedValue(savedCode || getBlocksDefaultValue(props.component.get('id')));
  };

  const label = hasQueries(props.component.get('id')) ? 'Queries' : 'Scripts';

  const isChanged = editedValue !== savedCode;
  const onReset = () => setEditedValue(savedCode);
  const onSave = () => {
    setIsSaving(true);

    return prepareMultipleBlocks(props.component.get('id'), props.sharedCodes, editedValue)
      .then((blocks: List<any>) => {
        return updateConfiguration(
          props.component.get('id'),
          props.config.get('id'),
          props.config.get('configuration').setIn(['parameters', 'blocks'], blocks),
          changeDescription || `Multiple ${label} updated`,
        ).then(() => {
          setChangeDescription('');
          setEditedValue(getBlocksAsString(props.component.get('id'), blocks, props.sharedCodes));
        });
      })
      .finally(() => setIsSaving(false));
  };
  const renderAdditionalButtons = () => {
    if (props.readOnly) {
      return null;
    }

    return (
      <SaveButtonWithDescription
        disabled={isSaving}
        isSaving={isSaving}
        isChanged={isChanged}
        onReset={onReset}
        onSave={onSave}
        changeDescription={changeDescription}
        onDescriptionChange={setChangeDescription}
      />
    );
  };

  if (!savedCode && props.readOnly) {
    return null;
  }

  return (
    <>
      <div className="box transparent-header">
        <div className="box-header big-padding smaller-height pl-0 pr-0">
          <h2 className="box-title">{label}</h2>
          <ButtonGroup>
            {!!savedCode && <ClipboardButton text={savedCode} label={`Copy all ${label}`} />}
            {(!props.readOnly || !!savedCode) && (
              <Button variant="outline" onClick={onOpenEditor}>
                {!savedCode ? (
                  <>
                    <Icon icon="plus" />
                    Create multiple {label}
                  </>
                ) : (
                  <>
                    <Icon icon="code" />
                    {!props.readOnly ? 'Edit' : 'Show'} all {label}
                  </>
                )}
              </Button>
            )}
          </ButtonGroup>
        </div>
        {showAllCodeBlocksModal && (
          <CodeEditorModal
            withAutocomplete
            title={`All ${label}`}
            onClose={() => setShowAllCodeBlocksModal(false)}
            value={editedValue}
            onChange={setEditedValue}
            onSave={onSave}
            onReset={onReset}
            isChanged={isChanged}
            renderAdditionalButtons={renderAdditionalButtons}
            codeMirrorOptions={{
              mode: resolveEditorMode(props.component.get('id')),
              ...(props.readOnly
                ? {
                    lineNumbers: false,
                    cursorHeight: 0,
                    readOnly: true,
                  }
                : {
                    placeholder: '-- Your code goes here',
                    hintOptions: {
                      completeSingle: false,
                      container: document.querySelector('.full-screen-modal.full-screen-editor'),
                      tables: getTablesHints(
                        props.component.get('id'),
                        props.config.get('configuration', Map()),
                        props.tables,
                      ),
                      variables: getVariablesHints(props.variables),
                    },
                  }),
            }}
          />
        )}
      </div>
    </>
  );
};

export default CodeBlocksHeader;
