import { List, Map } from 'immutable';

import { ClipboardIcon } from '@keboola/design';

import { MetadataKeys } from '@/modules/components/MetadataConstants';
import type { StoreSource } from '@/modules/stream/store';

export const DataStreamIcon = (props: { table: Map<string, any>; sources: StoreSource[] }) => {
  const sourceId = props.table
    .get('metadata', List())
    .find((row: Map<string, any>) => row.get('key') === MetadataKeys.STREAM_SOURCE_ID, null, Map())
    .get('value');

  const sourceUrl = props.sources.find((source) => source.sourceId === sourceId)?.http?.url;

  if (!sourceUrl) {
    return null;
  }

  return (
    <ClipboardIcon tooltipText="Copy Data Stream URL" text={sourceUrl} icon={['fas', 'webhook']} />
  );
};
