import { useState } from 'react';
import type { MouseEvent } from 'react';
import type { Map } from 'immutable';

import { Button, cn, Icon } from '@keboola/design';

import type { ExistingApp } from '@/api/routes/dataScienceService';
import CredentialsModal from './CredentialsModal';

const OpenDataApp = (props: {
  mode: 'sidebar' | 'list';
  config: Map<string, any>;
  app?: ExistingApp;
}) => {
  const [showCredentialsModal, setShowCredentialsModal] = useState(false);

  const isList = props.mode === 'list';

  if (!props.app?.url) {
    return isList ? <span className="tw-text-neutral-400">-</span> : null;
  }

  return (
    <span onClick={(event: MouseEvent) => event.stopPropagation()}>
      <Button
        block={props.mode === 'sidebar'}
        variant={props.mode === 'sidebar' ? 'outline' : 'inline'}
        className={cn({ 'btn-link-inline': isList })}
        onClick={() => setShowCredentialsModal(true)}
      >
        <Icon
          icon="arrow-up-right-from-square"
          className={cn('icon-addon-right', { 'text-muted': isList })}
        />
        Open Data App
      </Button>
      <CredentialsModal
        show={showCredentialsModal}
        onHide={() => setShowCredentialsModal(false)}
        app={props.app}
        config={props.config}
      />
    </span>
  );
};

export default OpenDataApp;
