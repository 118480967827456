import { List, Map } from 'immutable';
import memoizeOne from 'memoize-one';
import _ from 'underscore';

import type { File } from '@keboola/api-client';

import {
  BUCKET_ALREADY_SHARED_TOOLTIP_TEXT,
  BUCKET_LINKED_SHARE_TOOLTIP_TEXT,
  FILTERS,
  FILTERS_GROUP,
  NO_PERMISSION_TO_SHARE_TOOLTIP_TEXT,
  SHARING,
} from '@/constants';
import dayjs from '@/date';
import {
  canManageSharedBucket,
  isExternalBucket,
  isLinkedBucket,
} from '@/modules/admin/privileges';
import { ioType } from '@/modules/components/Constants';
import {
  ensureComponentWithDetails,
  getDestinationTypeFromStagingStorage,
} from '@/modules/components/helpers';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import { findLength, findNullable } from '@/modules/components/utils/columnMetadataHelper';
import {
  getLastActiveDatatypeProvider,
  getTableColumnMetadata,
} from '@/modules/components/utils/tableMetadataHelper';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  DISABLED_EXTERNAL_BUCKETS_MESSAGE,
  DISABLED_EXTERNAL_TABLES_MESSAGE,
} from '@/modules/sandboxes/Constants';
import { resolveComponentIdFromSandboxType } from '@/modules/sandboxes/helpers';
import type { SandboxType } from '@/modules/sandboxes/types';
import { getFakeComponentId } from '@/react/common/ConfigurationsTable/helpers';
import ApplicationStore from '@/stores/ApplicationStore';
import { matchByWords } from '@/utils';
import fromJSOrdered from '@/utils/fromJSOrdered';
import string from '@/utils/string';
import tableIdParser from '@/utils/tableIdParser';
import { backends, dataPreviewDataType, nameMaxLength, nameWarning } from './constants';
import type { Backend, Stage } from './types';

const getFilteredData = memoizeOne((data, query, searchFilters, exclude?: string[]) => {
  const connectionFilter = searchFilters?.[FILTERS_GROUP.SHARING];

  if (!exclude && searchFilters) {
    exclude =
      searchFilters[FILTERS_GROUP.ENTITY] === FILTERS.BUCKETS
        ? ['table', 'column']
        : searchFilters[FILTERS_GROUP.ENTITY] === FILTERS.TABLES
          ? ['bucket', 'column']
          : ['column'];
  }

  return data
    .filter((bucket: Map<string, any>) => {
      if (connectionFilter && Object.values(SHARING).includes(connectionFilter)) {
        if (connectionFilter === FILTERS.LINKED) return isBucketLinked(bucket);
        if (connectionFilter === FILTERS.SHARED) return isBucketShared(bucket);
      }
      return true;
    })
    .reduce((results: List<any>, bucket: Map<string, any>) => {
      if (
        !exclude?.includes('bucket') &&
        (matchByWords(bucket.get('displayName'), query) ||
          bucket.get('id').toLowerCase() === query.trim().toLowerCase())
      ) {
        bucket = bucket.set('matches', true).set('exactMatch', bucket.get('displayName') === query);
      }

      bucket = bucket.set(
        'bucketTables',
        bucket.get('bucketTables').reduce((tables: List<any>, table: Map<string, any>) => {
          if (
            !exclude?.includes('table') &&
            (matchByWords(table.get('displayName'), query) ||
              table.get('id').toLowerCase() === query.trim().toLowerCase())
          ) {
            table = table
              .set('matches', true)
              .set('exactMatch', table.get('displayName') === query);
          }

          if (exclude?.includes('column') && !table.has('matches')) {
            return tables;
          }

          table = table.update('columns', List(), (columns) =>
            columns.filter((columnName: string) => matchByWords(columnName, query)),
          );

          if (table.get('matches') || !table.get('columns').isEmpty()) {
            return tables.push(table);
          }

          return tables;
        }, List()),
      );

      if (bucket.get('matches') || !bucket.get('bucketTables').isEmpty()) {
        return results.push(bucket);
      }

      return results;
    }, List());
});

const isBucket = (bucketOrTable: Map<string, any>) => {
  return bucketOrTable.has('stage');
};

const isBucketLinked = (bucket: Map<string, any>) => {
  return !bucket.get('linkedBy', List()).isEmpty();
};

const isBucketShared = (bucket: Map<string, any>) => {
  return Boolean(bucket.get('sharing'));
};

const isTableShared = (table: Map<string, any>) => {
  return isBucketShared(table.get('bucket'));
};

const canShareExternalBucket = (bucket: Map<string, any>) => {
  return bucket.get('backend') === backends.SNOWFLAKE;
};

const getTableShareTooltipText = (bucket: Map<string, any>) => {
  if (isExternalBucket(bucket)) {
    return DISABLED_EXTERNAL_TABLES_MESSAGE;
  }
  return NO_PERMISSION_TO_SHARE_TOOLTIP_TEXT;
};

const getBucketShareTooltipText = (bucket: Map<string, any>) => {
  if (isBucketShared(bucket)) {
    return BUCKET_ALREADY_SHARED_TOOLTIP_TEXT;
  }
  if (isLinkedBucket(bucket)) {
    return BUCKET_LINKED_SHARE_TOOLTIP_TEXT;
  }
  if (isExternalBucket(bucket) && !canShareExternalBucket(bucket)) {
    return DISABLED_EXTERNAL_BUCKETS_MESSAGE;
  }
  return NO_PERMISSION_TO_SHARE_TOOLTIP_TEXT;
};

const hasBucketExternalSchema = (bucket: Map<string, any>) => {
  return Boolean(bucket.get('hasExternalSchema', false));
};

const isBucketNameAlreadyUsed = (
  buckets: Map<string, any>,
  name: string,
  stage: Stage,
  currentDevBranchId: number | null = null,
  options = { strict: true },
) => {
  const prefix = ApplicationStore.hasDisableLegacyBucketPrefix() ? '' : 'c-';
  const bucketName = ApplicationStore.hasProtectedDefaultBranch()
    ? name
    : prefixBucketNameWithBranchId(name, currentDevBranchId);

  return buckets.some((bucket: Map<string, any>) => {
    if (bucket.get('stage') !== stage) {
      return false;
    }

    return (
      bucket.get('displayName', '').toLowerCase() === bucketName.toLowerCase() ||
      (options.strict &&
        bucket.get('name', '').toLowerCase() === `${prefix}${bucketName.toLowerCase()}`)
    );
  });
};

const prefixBucketNameWithBranchId = (name: string, branchId: number | null = null) => {
  if (branchId) {
    const branchIdPrefix = `${branchId}-`;

    return `${branchIdPrefix}${string.strRight(name, branchIdPrefix)}`;
  }

  return name;
};

const getBranchTableId = (tableId: string, branchId = DevBranchesStore.getCurrentId()) => {
  if (branchId === null || ApplicationStore.hasProtectedDefaultBranch()) {
    return tableId;
  }

  const { stage, bucket, table } = tableIdParser.parse(tableId).parts;

  return `${stage}.${
    ApplicationStore.hasDisableLegacyBucketPrefix() ? '' : 'c-'
  }${branchId}-${getBucketDisplayNameFromName(bucket)}.${table}`;
};

const getCurrentBranchTableWithProductionFallback = (
  allTables: Map<string, any>,
  tableId?: string,
) => {
  if (!tableId) {
    return Map();
  }

  return allTables.get(getBranchTableId(tableId), allTables.get(tableId, Map()));
};

export type FileExpiration = {
  permanent: boolean;
  expired: boolean;
  text: string;
};

const fileExpirationStatus = (file: Map<string, any>): FileExpiration => {
  const maxAgeDays = file.get('maxAgeDays', null);

  if (maxAgeDays === null) {
    return { permanent: true, expired: false, text: 'Permanent' };
  }

  const now = dayjs();
  const expiresOn = dayjs(file.get('created')).add(maxAgeDays, 'days');
  const diffDays = expiresOn.diff(now, 'days');

  if (diffDays > 0) {
    return { permanent: false, expired: false, text: `Expires in ${diffDays} days` };
  }

  const diffMinutes = expiresOn.diff(now, 'minutes');

  if (diffMinutes > 0) {
    return { permanent: false, expired: false, text: `Expires in ${diffMinutes} minutes` };
  }

  return { permanent: false, expired: true, text: 'Expired' };
};

const isTableNameAlreadyUsed = (
  tables: Map<string, any>,
  name: string,
  options = { strict: true },
) => {
  return tables.some((table) => {
    return (
      table.get('displayName', '').toLowerCase() === name.toLowerCase() ||
      (options.strict && table.get('name', '').toLowerCase() === name.toLowerCase())
    );
  });
};

const validateTableName = (
  name: string,
  tables: Map<string, any> = Map(),
  options?: { strict: boolean },
) => {
  if (!name || name.length === 0) {
    return `Empty string is not allowed`;
  }

  if (name.length > nameMaxLength) {
    return `The maximum allowed table name length is ${nameMaxLength} characters.`;
  }

  if (name.indexOf('_') === 0) {
    return 'Table name cannot start with an underscore.';
  }

  if (!/^[a-zA-Z0-9_-]*$/.test(name)) {
    return nameWarning;
  }

  if (isTableNameAlreadyUsed(tables, name, options)) {
    return `The table "${name}" already exists.`;
  }

  return null;
};

const validateBucketName = (
  name: string,
  stage: Stage,
  allOtherBuckets: Map<string, any>,
  currentDevBranchId: number | null = null,
  options?: { strict: boolean },
) => {
  if (!name || name.length === 0) {
    return `Empty string is not allowed`;
  }

  if (name.length > nameMaxLength) {
    return `The maximum allowed bucket name length is ${nameMaxLength} characters.`;
  }

  if (name.indexOf('_') === 0) {
    return 'Bucket name cannot start with an underscore.';
  }

  if (!/^[a-zA-Z0-9_-]*$/.test(name)) {
    return nameWarning;
  }

  if (isBucketNameAlreadyUsed(allOtherBuckets, name, stage, currentDevBranchId, options)) {
    return `The bucket "${name}" already used in the ${stage.toUpperCase()} stage.`;
  }

  return null;
};

const bucketDisplayNameWithStage = (bucket: Map<string, any>) => {
  return `${bucket.get('stage')} ${bucket.get('displayName')}`;
};

const tableName = (table: Map<string, any>) => {
  return `${table.getIn(['bucket', 'displayName'])} / ${table.get('displayName')}`;
};

const tableDisplayNameWithBucketAndStage = (table: Map<string, any>) => {
  return [
    table.getIn(['bucket', 'stage']),
    table.getIn(['bucket', 'displayName']),
    table.get('displayName'),
  ].join(' ');
};

const getBucketDisplayNameFromName = (bucketName: string) => {
  return ApplicationStore.hasDisableLegacyBucketPrefix()
    ? bucketName
    : string.strRight(bucketName, 'c-');
};

const tableNameParsed = (tableId: string, storageBucket = Map()) => {
  const { stage, bucket, table } = tableIdParser.parse(tableId).parts;
  const bucketName = storageBucket.get('displayName', getBucketDisplayNameFromName(bucket));

  if (stage && bucketName && table) {
    return `${bucketName} / ${table}`;
  }

  return tableId;
};

const getTableAliases = (
  currentTable: Map<string, any>,
  tables: Map<string, any>,
  sapiToken: Map<string, any>,
) => {
  return tables
    .filter(
      (table) =>
        !table.getIn(['bucket', 'sourceBucket']) &&
        table.get('isAlias') &&
        table.getIn(['sourceTable', 'id']) === currentTable.get('id') &&
        sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id']),
    )
    .toArray();
};

const getTableLinks = (table: Map<string, any>, bucket: Map<string, any>) => {
  if (table.get('isAlias') || !bucket.get('linkedBy')) {
    return [];
  }

  return bucket
    .get('linkedBy', List())
    .map((linkedByBucket: any) => linkedByBucket.merge({ table }))
    .toArray();
};

const prepareValues = (filter: string, operator: string = '') => {
  return string
    .strRight(filter.trim(), operator)
    .split(',')
    .map((value) => value.trim())
    .filter(Boolean);
};

const prepareWhereFilters = (filters: Map<string, any>, backend: Backend) => {
  const dataType =
    backend in dataPreviewDataType
      ? dataPreviewDataType[backend as keyof typeof dataPreviewDataType]
      : null;

  return filters
    .map((filter, column) => {
      if (filter.trim().startsWith('"') && filter.trim().endsWith('"')) {
        return { column, values: [string.trim(filter, ' "')], operator: 'eq' };
      }

      let operator = filter.trim().substring(0, 2);
      let values = prepareValues(filter, operator);
      // @ts-expect-error string conversion
      let allNumeric = values.every((value) => !isNaN(parseFloat(value)) && !isNaN(value - 0));

      switch (operator) {
        case '>=':
          return { column, values, operator: 'ge', ...(allNumeric && { dataType }) };

        case '=>':
          return { column, values, operator: 'ge', ...(allNumeric && { dataType }) };

        case '<=':
          return { column, values, operator: 'le', ...(allNumeric && { dataType }) };

        case '=<':
          return { column, values, operator: 'le', ...(allNumeric && { dataType }) };

        case '!=':
          return { column, values, operator: 'ne' };

        default:
          break;
      }

      operator = filter.trim().substring(0, 1);
      values = prepareValues(filter, operator);
      // @ts-expect-error string conversion
      allNumeric = values.every((value) => !isNaN(parseFloat(value)) && !isNaN(value - 0));

      switch (operator) {
        case '>':
          return { column, values, operator: 'gt', ...(allNumeric && { dataType }) };

        case '<':
          return { column, values, operator: 'lt', ...(allNumeric && { dataType }) };

        case '=':
          return { column, values, operator: 'eq' };

        default:
          return { column, values: prepareValues(filter), operator: 'eq' };
      }
    })
    .toList()
    .toJS();
};

const prepareOrderBy = (
  sortedColumns: Map<string, any>,
  columnOrder: string[],
  table: Map<string, any>,
  backend: Backend,
) => {
  const metadata = getTableColumnMetadata(table);

  return sortedColumns
    .sortBy((orderBy, column) => columnOrder.indexOf(column as string))
    .map((orderBy, column) => {
      return {
        column,
        order: orderBy.toUpperCase(),
        ...(!table.get('isTyped') &&
          hasNumberLikeBasetype(findBasetypeDatatype(metadata.get(column, List()))) && {
            dataType: dataPreviewDataType[backend as keyof typeof dataPreviewDataType],
          }),
      };
    })
    .toList()
    .toJS();
};

const hasNumberLikeBasetype = (metadata: Map<string, any>) => {
  return (
    metadata && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(metadata.get('value', '').toUpperCase())
  );
};

const isColumnUsedInAliasColumns = (
  column: string,
  tables: Map<string, any>,
  tableId: string,
  sapiToken: Map<string, any>,
) => {
  return tables.some((table: Map<string, any>) => {
    return (
      table.get('isAlias') &&
      !table.get('aliasColumnsAutoSync') &&
      table.getIn(['sourceTable', 'id']) === tableId &&
      table.get('columns').some((columnName: string) => columnName === column) &&
      sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id'])
    );
  });
};

const isColumnUsedInAliasFilter = (
  column: string,
  tables: Map<string, any>,
  tableId: string,
  sapiToken: Map<string, any>,
) => {
  return tables.some((table: Map<string, any>) => {
    return (
      table.get('isAlias') &&
      table.getIn(['sourceTable', 'id']) === tableId &&
      table.getIn(['aliasFilter', 'column']) === column &&
      sapiToken.getIn(['owner', 'id']) === table.getIn(['sourceTable', 'project', 'id'])
    );
  });
};

const findBasetypeDatatype = (metadata: List<any>) => {
  return metadata.find(
    (item) => item.get('key') === MetadataKeys.BASE_TYPE && item.get('provider') === 'user',
    null,
    metadata
      .filter((item) => {
        return (
          item.get('key') === MetadataKeys.BASE_TYPE &&
          item.get('provider') === getLastActiveDatatypeProvider(metadata, { exclude: ['user'] })
        );
      })
      .sortBy((item) => -1 * new Date(item.get('timestamp')).getTime())
      .first(),
  );
};

const findLengthDatatypeValue = (metadata: List<any>, provider: string) => {
  return findLength(metadata.filter((item) => item.get('provider') === provider)).get('value');
};

const findNullableDatatypeValue = (metadata: List<any>, provider: string) => {
  return findNullable(metadata.filter((item) => item.get('provider') === provider)).get('value');
};

const prepareTablesMetadataMap = memoizeOne(
  (
    tables: Map<string, any>,
    includeConfigurationId: boolean = true,
    evaluateSampleDataComponent: boolean = false,
    allConfigurations?: Map<string, any>,
  ) => {
    return tables
      .groupBy((table: Map<string, any>) => {
        const componentId = table
          .get('metadata', List())
          .find(
            (row: Map<string, any>) => row.get('key') === MetadataKeys.LAST_UPDATED_BY_COMPONENT_ID,
            null,
            Map(),
          )
          .get('value');

        if (!includeConfigurationId && !evaluateSampleDataComponent) return componentId;

        const configId = table
          .get('metadata', List())
          .find(
            (row: Map<string, any>) =>
              row.get('key') === MetadataKeys.LAST_UPDATED_BY_CONFIGURATION_ID,
            null,
            Map(),
          )
          .get('value');

        if (!includeConfigurationId && evaluateSampleDataComponent) {
          return (
            getFakeComponentId(
              allConfigurations?.getIn(
                [componentId, 'configurations', configId, 'configuration'],
                Map(),
              ),
            ) || componentId
          );
        }

        return `${componentId}:${configId}`;
      })
      .toMap();
  },
);

const prepareMappingFromSelectedBucketsAndTables = (
  selected: List<any> | Map<string, any>[],
  allTables: Map<string, any>,
  sandboxType: SandboxType,
) => {
  const componentId = resolveComponentIdFromSandboxType(sandboxType);

  return ensureComponentWithDetails(componentId).then((component) => {
    const destinationType = getDestinationTypeFromStagingStorage(
      component.getIn(['data', 'staging_storage', 'input']),
    );

    const prepareStorageTable = (table: Map<string, any>) => {
      return fromJSOrdered({
        source: table.get('id'),
        destination: `${table.get('displayName', '')}${
          destinationType === ioType.FILE ? '.csv' : ''
        }`,
        where_column: '',
        where_values: [],
        where_operator: 'eq',
        columns: [],
      });
    };

    const tables = Map()
      .withMutations((tables) => {
        selected.forEach((row) => {
          if (row.has('stage')) {
            allTables
              .filter((table) => table.getIn(['bucket', 'id']) === row.get('id'))
              .forEach((table) => tables.set(table.get('id'), prepareStorageTable(table)));
          } else {
            tables.set(row.get('id'), prepareStorageTable(row));
          }
        });
      })
      .toList();

    return Map().setIn(['input', 'tables'], tables);
  });
};

const getDescriptionValue = (metadata: List<any> = List(), key = MetadataKeys.DESCRIPTION) => {
  const descriptionMetadata = metadata.filter((row: Map<string, any>) => row.get('key') === key);

  const userComment = descriptionMetadata.find((row) => row.get('provider') === 'user');

  const componentComment = descriptionMetadata
    .filter((row) => row.get('provider') !== 'user')
    .sortBy((row) => -1 * new Date(row.get('timestamp')).getTime())
    .first();

  const normalizeValue = (comment: Map<string, any>) => comment?.get('value', '')?.trim() || null;

  // if have only component comment, use it
  if (!userComment && componentComment) {
    return normalizeValue(componentComment);
  }

  // if have both, always prefer user comment if it is not empty or is newer then component comment
  if (userComment?.has('value') && componentComment?.has('value')) {
    const comment =
      !!userComment.get('value') || userComment.get('timestamp') > componentComment.get('timestamp')
        ? userComment
        : componentComment;

    return normalizeValue(comment);
  }

  // otherwise use user comment
  return normalizeValue(userComment);
};

const sortByDisplayName = (entity: Map<string, any>) => entity.get('displayName').toLowerCase();

const sortByExactMatch = (entity: Map<string, any>) => {
  return entity.get('exactMatch') ||
    entity.get('bucketTables', List()).some((table: Map<string, any>) => table.get('exactMatch'))
    ? -1
    : 0;
};

const parseBigQueryDatasetUrl = (url: string) => {
  const re = /projects\/([^/]+)\/locations\/([^/]+)\/dataExchanges\/([^/]+)\/listings\/([^/?#]+)/;
  const [, projectId, location, exchangerName, listingName] = url.match(re) || [];
  return [projectId, location, exchangerName, listingName].filter(Boolean);
};

const prepareBackendLabel = (backend: Backend) => {
  switch (backend) {
    case backends.BIGQUERY:
      return 'BigQuery';

    default:
      return string.capitalize(backend);
  }
};

const downloadFile = (url: string, fileName: string) => {
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const downloadFiles = (files: File[]) => {
  files.forEach((file, i) => {
    setTimeout(() => {
      downloadFile(file.url, file.name);
    }, i * 500);
  });
};

const downloadSlicedFiles = (slicedFiles: File[]) => {
  _.chunk(slicedFiles, 10).map((files, index) => {
    setTimeout(() => {
      files.forEach((file) => StorageActionCreators.downloadSlicedFile(file));
    }, index * 1000);
  });
};

const getLoadingActionName = ({
  isDeleting = false,
  isTruncating = false,
  isExporting = false,
  isCreatingSnapshot = false,
  tableOrBucket = 'table',
}) => {
  if (isDeleting) {
    return `Deleting ${tableOrBucket}`;
  }

  if (isTruncating) {
    return `Truncating ${tableOrBucket}`;
  }

  if (isExporting) {
    return 'Preparing export';
  }

  if (isCreatingSnapshot) {
    return 'Creating snapshot';
  }

  return null;
};

const isBucketShareDisabled = (bucket: Map<string, any>) => {
  return (
    isBucketShared(bucket) ||
    isLinkedBucket(bucket) ||
    !canManageSharedBucket(ApplicationStore.getSapiToken()) ||
    (isExternalBucket(bucket) && !canShareExternalBucket(bucket))
  );
};

const isTableShareDisabled = (table: Map<string, any>) => {
  return (
    isExternalBucket(table.get('bucket')) || !canManageSharedBucket(ApplicationStore.getSapiToken())
  );
};

const usedInMapping = (config: Map<string, any>, tableId: string) => {
  return usedInInputMapping(config, tableId) || usedInOutputMapping(config, tableId);
};

const usedInInputMapping = (config: Map<string, any>, tableId: string) => {
  return config
    .getIn(['configuration', 'storage', 'input', 'tables'], List())
    .some((mapping: Map<string, any>) => mapping.get('source') === tableId);
};

const usedInOutputMapping = (config: Map<string, any>, tableId: string) => {
  return config
    .getIn(['configuration', 'storage', 'output', 'tables'], List())
    .some((mapping: Map<string, any>) => mapping.get('destination') === tableId);
};

const usedAsOutputTable = (config: Map<string, any>, tableId: string) => {
  return config.getIn(['configuration', 'parameters', 'outputTable']) === tableId;
};

export {
  getFilteredData,
  sortByDisplayName,
  sortByExactMatch,
  isBucket,
  isBucketLinked,
  isBucketShared,
  isTableShared,
  getTableShareTooltipText,
  getBucketShareTooltipText,
  hasBucketExternalSchema,
  isBucketNameAlreadyUsed,
  prefixBucketNameWithBranchId,
  getBranchTableId,
  getCurrentBranchTableWithProductionFallback,
  fileExpirationStatus,
  isTableNameAlreadyUsed,
  validateTableName,
  validateBucketName,
  tableName,
  tableNameParsed,
  getBucketDisplayNameFromName,
  bucketDisplayNameWithStage,
  tableDisplayNameWithBucketAndStage,
  getTableAliases,
  getTableLinks,
  prepareWhereFilters,
  prepareOrderBy,
  isColumnUsedInAliasColumns,
  isColumnUsedInAliasFilter,
  prepareTablesMetadataMap,
  findBasetypeDatatype,
  hasNumberLikeBasetype,
  findLengthDatatypeValue,
  findNullableDatatypeValue,
  prepareMappingFromSelectedBucketsAndTables,
  getDescriptionValue,
  parseBigQueryDatasetUrl,
  prepareBackendLabel,
  downloadFile,
  downloadFiles,
  downloadSlicedFiles,
  getLoadingActionName,
  isBucketShareDisabled,
  isTableShareDisabled,
  usedInMapping,
  usedInInputMapping,
  usedInOutputMapping,
  usedAsOutputTable,
};
