import type { Map } from 'immutable';

import { canLoadSharedBuckets } from '@/modules/admin/privileges';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import type { BucketType } from '@/modules/data-catalog/types';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import { SharedFromThisProjectBuckets } from './SharedFromThisProjectBuckets';
import { TabNavigation } from './TabNavigation';

const SharedFromThisProject = () => {
  const {
    admins,
    currentAdmin,
    sapiToken,
    urlTemplates,
    availableUsersOptions,
    availableProjectsOptions,
    mySharedBuckets,
  } = useStores(
    () => ({
      admins: ApplicationStore.getAdmins(),
      currentAdmin: ApplicationStore.getCurrentAdmin(),
      sapiToken: ApplicationStore.getSapiToken(),
      urlTemplates: ApplicationStore.getUrlTemplates(),
      availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
      availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
      mySharedBuckets: StorageBucketsStore.getAll().filter((bucket: BucketType) =>
        bucket.get('sharing'),
      ) as Map<string, any>,
    }),
    [],
    [ApplicationStore, StorageBucketsStore],
  );

  return (
    <>
      {canLoadSharedBuckets(sapiToken) && <TabNavigation />}
      <SharedFromThisProjectBuckets
        admins={admins}
        currentAdmin={currentAdmin}
        sapiToken={sapiToken}
        urlTemplates={urlTemplates}
        availableUsersOptions={availableUsersOptions}
        availableProjectsOptions={availableProjectsOptions}
        mySharedBuckets={mySharedBuckets}
      />
    </>
  );
};

export default SharedFromThisProject;
