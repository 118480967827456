import { useState } from 'react';
import type { ReactNode } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import { Map } from 'immutable';

import { ButtonInline, Icon, IconButton, Tooltip } from '@keboola/design';

import type { ioType } from '@/modules/components/Constants';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import validateStorageTableId from '@/utils/validateStorageTableId';
import { isDeleteWhereConfigurationValid } from './helpers';
import Editor from './TableOutputMappingEditor';

const TableOutputMappingModal = (props: {
  onSave: (mapping: Map<string, any>) => Promise<any>;
  componentId: string;
  tables: Map<string, any>;
  buckets: Map<string, any>;
  sourceType: (typeof ioType)[keyof typeof ioType];
  defaultBucketName: string;
  defaultTableName: string;
  mapping?: Map<string, any>;
  isEdit?: boolean;
  simple?: boolean;
  renderCustomOpenButton?: (openHandler: () => void) => ReactNode;
}) => {
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editing, setEditing] = useState<Map<string, any>>(Map());
  const isEdit = props.isEdit ?? !!props.mapping;

  const openModal = () => setShow(true);

  const handleSave = () => {
    setIsSaving(true);
    props
      .onSave(editing)
      .then(() => setShow(false))
      .finally(() => setIsSaving(false));
  };

  const isValid = () => {
    return (
      !!editing.get('source') &&
      !!editing.get('destination') &&
      isDeleteWhereConfigurationValid(editing) &&
      validateStorageTableId(editing.get('destination', ''))
    );
  };

  const renderOpenButton = () => {
    if (props.renderCustomOpenButton) {
      return props.renderCustomOpenButton(openModal);
    }

    if (isEdit) {
      return (
        <Tooltip placement="top" tooltip="Edit Output">
          <IconButton variant="invisible" icon="pen" onClick={openModal} />
        </Tooltip>
      );
    }

    return (
      <ButtonInline
        variant="link-reverse"
        color="custom"
        className="tw-px-4 tw-py-3 tw-text-primary-500"
        onClick={openModal}
      >
        <Icon icon="plus" />
        New Table Output
      </ButtonInline>
    );
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      {renderOpenButton()}
      <Modal
        bsSize="large"
        show={show}
        onHide={() => setShow(false)}
        onEnter={() => props.mapping && setEditing(props.mapping)}
        onExited={() => setEditing(Map())}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.simple ? 'Save Data' : 'Output Mapping'}</Modal.Title>
          {isEdit ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>
        <Modal.Body>
          <Editor
            value={editing}
            onChange={setEditing}
            tables={props.tables}
            buckets={props.buckets}
            disabled={isSaving}
            defaultBucketName={props.defaultBucketName}
            defaultTableName={props.defaultTableName}
            sourceType={props.sourceType}
            componentId={props.componentId}
            simple={props.simple}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={props.simple ? 'Save Data' : isEdit ? 'Save Output' : 'Add Output'}
            onSave={handleSave}
            isSaving={isSaving}
            isDisabled={!isValid()}
          />
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default TableOutputMappingModal;
