import type { ComponentProps, ReactNode } from 'react';

import { Button, Icon, Tooltip } from '@keboola/design';

type Props = {
  tooltip: string;
  onClick: () => void;
  disabled?: boolean;
} & IconProps;

type IconProps =
  | { libraryIcon: ComponentProps<typeof Icon>['icon']; icon?: never }
  | { icon: ReactNode; libraryIcon?: never };

export const TableIconAction = ({ tooltip, libraryIcon, icon, onClick, disabled }: Props) => (
  <Tooltip
    placement="top"
    tooltip={tooltip}
    type={disabled ? 'explanatory' : 'action'}
    triggerClassName="tw-align-middle"
  >
    <Button
      variant="invisible"
      className="tw-h-10 tw-w-10 tw-p-0"
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {libraryIcon ? <Icon icon={libraryIcon} className="!tw-text-lg" fixedWidth /> : icon}
    </Button>
  </Tooltip>
);
