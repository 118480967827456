import type { Map } from 'immutable';
import qs from 'qs';

import { APP_ROUTE } from '@/constants/routeNames';
import { getQueryString } from '@/utils/router/getQueryString';

const prepareLocationContext = (location: Map<string, any>) => {
  const queryString = getQueryString({
    ...Object.fromEntries(new URLSearchParams(location.get('search') || '').entries()),
    ...{ scroll: window.scrollY.toString() },
  });

  return `${location.get('pathname')}${queryString}${location.get('hash')}`;
};

const parseLocationContext = (location: Map<string, any>) => {
  const { pathname, hash, search } = new URL(
    location.getIn(['query', 'context'], ''),
    window.location.origin,
  );
  const query = !!search ? qs.parse(new URLSearchParams(search).toString()) : null;

  return { pathname: pathname !== '/' ? pathname : APP_ROUTE, query, hash };
};

export { prepareLocationContext, parseLocationContext };
