import { fromJS, List, Map } from 'immutable';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import Dispatcher from '@/Dispatcher';
import { ActionTypes as InstalledComponentsActionTypes } from '@/modules/components/Constants';
import { ActionTypes } from '@/modules/legacy-transformation/Constants';
import parseBuckets from '@/modules/legacy-transformation/utils/parseBuckets';
import StoreUtils, { initStore } from '@/utils/StoreUtils';

let _store = initStore('TransformationBucketsStore2', Map({ bucketsById: Map() }));

const TransformationBucketsStore = StoreUtils.createStore({
  getAll() {
    return _store.get('bucketsById').sortBy((bucket) => bucket.get('name'));
  },

  get(id) {
    return _store.getIn(['bucketsById', id]);
  },
});

Dispatcher.register((payload) => {
  const { action } = payload;

  switch (action.type) {
    case InstalledComponentsActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS: {
      if (action.componentId !== KEBOOLA_LEGACY_TRANSFORMATION) {
        return;
      }
      const bucketsData = parseBuckets(action.configData);
      _store = _store.withMutations((store) =>
        store.set(
          'bucketsById',
          fromJS(bucketsData)
            .toMap()
            .mapKeys((key, bucket) => bucket.get('id')),
        ),
      );
      return TransformationBucketsStore.emitChange();
    }

    case ActionTypes.TRANSFORMATION_BUCKET_CREATE_SUCCESS:
      _store = _store.setIn(['bucketsById', action.bucket.id], fromJS(action.bucket));
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS:
      _store = _store.withMutations((store) => store.removeIn(['bucketsById', action.bucketId]));
      return TransformationBucketsStore.emitChange();

    case ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS:
      _store = _store.withMutations((store) =>
        store.updateIn(
          ['bucketsById', action.bucketId, 'transformations'],
          List(),
          (transformations) =>
            transformations.map((transformation) => {
              if (transformation.get('id') === action.transformationId) {
                return fromJS(action.data);
              }

              return transformation;
            }),
        ),
      );
      return TransformationBucketsStore.emitChange();

    default:
  }
});

export default TransformationBucketsStore;
