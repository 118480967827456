import { useState } from 'react';
import type { FormEventHandler } from 'react';
import { Modal } from 'react-bootstrap';

import { Button, FormGroup, Label, RadioGroup } from '@keboola/design';

import CodeEditor from '@/react/common/CodeEditor';
import FilesDropZone from '@/react/common/FilesDropZone';
import ModalIcon from '@/react/common/ModalIcon';
import { parseRequirementsFile } from './utils';

const UPLOAD_REQUIREMENTS_FORM = 'upload-requirements-form';
const ONE_MB = 1000 * 1000;

type Props = {
  show: boolean;
  onHide: () => void;
  onSubmit: (
    packages: ReturnType<typeof parseRequirementsFile>,
    strategy: 'replace' | 'update',
  ) => void;
  isLoading?: boolean;
};

export const UploadRequirementsFileModal = ({ onSubmit, isLoading, show, onHide }: Props) => {
  const [content, setContent] = useState<string>('');
  const [file, setFiles] = useState<File[]>([]);
  const [strategy, setStrategy] = useState<'replace' | 'update'>('replace');

  const reset = () => {
    setContent('');
    setFiles([]);
    setStrategy('replace');
  };

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    onSubmit(parseRequirementsFile(content), strategy);
    onHide();
  };

  const onDrop = (files: File[]) => {
    if (files.length === 0) {
      setFiles([]);
      setContent('');
      return;
    }

    setFiles(files);
    const reader = new FileReader();
    reader.onload = function (e) {
      setContent(e.target?.result as string);
    };

    reader.readAsText(files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} onEnter={reset}>
      <Modal.Header closeButton>
        <Modal.Title>Upload requirement.txt File</Modal.Title>
        <ModalIcon icon="upload" color="blue" bold />
      </Modal.Header>

      <Modal.Body className="tw-flex tw-flex-col tw-gap-4">
        <form id={UPLOAD_REQUIREMENTS_FORM} onSubmit={handleSubmit}>
          <FilesDropZone
            allowedType={{ 'application/text': ['.txt'] }}
            maxFileSize={ONE_MB}
            onDrop={onDrop}
            files={file}
          />
        </form>

        {content && (
          <>
            <FormGroup>
              <Label htmlFor="mergin-strategy-radio">Merge Strategy</Label>
              <RadioGroup
                id="mergin-strategy-radio"
                variant="radio-block"
                value={strategy}
                onChange={setStrategy}
              >
                <RadioGroup.Item value="replace">Replace</RadioGroup.Item>
                <RadioGroup.Item value="update">Update</RadioGroup.Item>
              </RadioGroup>
            </FormGroup>

            <FormGroup>
              <Label htmlFor="uploaded-requirements-file">Uploaded Packages</Label>
              <CodeEditor
                id="uploaded-requirements-file"
                value={content}
                onChange={(newContents: string) => {
                  if (!newContents) {
                    setFiles([]);
                  }
                  setContent(newContents);
                }}
                options={{
                  mode: 'text/plain',
                  lineNumbers: false,
                }}
              />
            </FormGroup>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          isLoading={isLoading}
          disabled={isLoading || !content}
          type="submit"
          form={UPLOAD_REQUIREMENTS_FORM}
          icon="check-circle"
          className="tw-w-full"
        >
          Save Packages
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
