import Promise from 'bluebird';
import { fromJS, Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { apiClient } from '@/apiClient';
import {
  KEBOOLA_EX_GOOGLE_ANALYTICS_V_4,
  KEBOOLA_EX_SAMPLE_DATA,
  KEBOOLA_FLOW,
  KEBOOLA_LEGACY_TRANSFORMATION,
  KEBOOLA_NO_CODE_DBT_TRANSFORMATION,
  KEBOOLA_ORCHESTRATOR,
} from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import dispatcher from '@/Dispatcher';
import * as AiApi from '@/modules/ai/api';
import { getAutomationFromFlowMetadata } from '@/modules/automations/helpers';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import { routeNames as componentsRouteNames } from '@/modules/components-directory/constants';
import ConfigurationRowsActionCreators from '@/modules/configurations/ConfigurationRowsActionCreators';
import { removeTableFromInputTableState } from '@/modules/configurations/utils/configurationState';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { prepareDataForSaveInDevBranch } from '@/modules/dev-branches/helpers';
import { routeNames as flowsRouteNames } from '@/modules/flows/constants';
import { routeNames as flowsV2RouteNames } from '@/modules/flows-v2/constants';
import oldJobsActionCreators from '@/modules/jobs/ActionCreators';
import LegacyTransformationActionCreators from '@/modules/legacy-transformation/ActionCreators';
import * as OauthUtils from '@/modules/oauth-v2/OauthUtils';
import { routeNames as orchestrationsRouteNames } from '@/modules/orchestrations-v2/constants';
import jobsActionCreators from '@/modules/queue/actions';
import { ActionTypes as jobsActionTypes } from '@/modules/queue/constants';
import { supportsSimplifiedUi } from '@/modules/simplified-ui/helpers';
import { routeNames as transformationsRouteNames } from '@/modules/transformations-v2/constants';
import { hasDisabledRestore } from '@/modules/trash/utils';
import WorkspacesActions from '@/modules/workspaces/WorkspacesActions';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import getDefaultBucket from '@/utils/getDefaultBucket';
import injectDefaultsFromConfigurationSchema from '@/utils/injectDefaultsFromConfigurationSchema';
import { getMappingBasePath } from './react/components/generic/helpers';
import configurationMovedToTrash from './react/components/notifications/configurationMovedToTrash';
import configurationRestoredNotification from './react/components/notifications/configurationRestored';
import jobScheduledNotification from './react/components/notifications/jobScheduled';
import ComponentsStore from './stores/ComponentsStore';
import InstalledComponentsStore from './stores/InstalledComponentsStore';
import preferEncryptedAttributes from './utils/preferEncryptedAttributes';
import componentRunner from './ComponentRunner';
import * as constants from './Constants';
import { uiModes } from './Constants';
import { ensureComponentWithDetails, supportAuthoritativeDataTypes } from './helpers';
import installedComponentsApi from './InstalledComponentsApi';
import { USED_METADATA_KEYS } from './MetadataConstants';

export const storeEncodedConfig = function (
  componentId,
  configId,
  configuration,
  changeDescription,
) {
  return Promise.resolve()
    .then(() => {
      const dataToSave = preferEncryptedAttributes(
        prepareDataForSaveInDevBranch(componentId, configuration, DevBranchesStore.getCurrentId()),
      );

      if (!dataToSave?.authorization?.oauth_api?.id) return dataToSave;

      return OauthUtils.saveCredentialsIntoVariable(
        componentId,
        configId,
        DevBranchesStore.getCurrentId()?.toString(),
        dataToSave.authorization.oauth_api.id,
      ).then((finalCredentialsId) => ({
        ...dataToSave,
        authorization: {
          oauth_api: { ...dataToSave.authorization.oauth_api, id: finalCredentialsId },
        },
      }));
    })
    .then((dataToSave) => {
      const isDataToSaveEmpty = _.isEmpty(dataToSave);
      const encryptedData = isDataToSaveEmpty
        ? Promise.resolve(dataToSave)
        : apiClient.encryption.encryptSecrets({
            componentId,
            projectId: ApplicationStore.getCurrentProjectId(),
            data: dataToSave,
          });

      return encryptedData.then(function (result) {
        const dataToSaveEncrypted = {
          configuration: JSON.stringify(result),
          changeDescription: changeDescription,
        };
        return installedComponentsApi.updateComponentConfiguration(
          componentId,
          configId,
          dataToSaveEncrypted,
        );
      });
    });
};

export default {
  reloadInstalledComponents: function (params) {
    // primarily prevents double loading when app starts
    if (InstalledComponentsStore.getIsJustLoaded() && !params?.include?.includes('rows')) {
      return Promise.resolve();
    }

    if (InstalledComponentsStore.getIsLoaded(params)) {
      this.loadInstalledComponentsForce(params);
      return Promise.resolve();
    }

    return this.loadInstalledComponentsForce(params);
  },
  loadInstalledComponentsForce: function (params, options) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_LOAD,
    });
    return installedComponentsApi
      .getComponents(params, options)
      .then(function (components) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_LOAD_SUCCESS,
          components: components,
          params,
        });
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_LOAD_ERROR,
          status: error.status,
          response: error.response,
        });
        throw error;
      });
  },
  loadDeletedComponentsForce: function () {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.DELETED_COMPONENTS_LOAD,
    });
    return installedComponentsApi
      .getDeletedComponents()
      .then(function (components) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_COMPONENTS_LOAD_SUCCESS,
          components: components,
        });
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.DELETED_COMPONENTS_LOAD_ERROR,
          status: error.status,
          response: error.response,
        });
        throw error;
      });
  },
  loadComponentConfigDataForce: function (componentId, configId) {
    return installedComponentsApi
      .getComponentConfiguration(componentId, configId)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS,
          componentId: componentId,
          configId: configId,
          data: response,
        });
        return response.configuration;
      });
  },
  loadComponentConfigData: function (componentId, configId) {
    if (InstalledComponentsStore.getIsConfigDataLoaded(componentId, configId)) {
      this.loadComponentConfigDataForce(componentId, configId);
      return Promise.resolve();
    }
    return this.loadComponentConfigDataForce(componentId, configId);
  },
  loadComponentConfigsDataForce: function (componentId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD,
      componentId: componentId,
    });
    return installedComponentsApi
      .getComponentConfigurations(componentId)
      .then(function (configData) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGSDATA_LOAD_SUCCESS,
          componentId: componentId,
          configData: configData || [],
        });
        return null;
      });
  },
  loadComponentConfigsData: function (componentId) {
    if (InstalledComponentsStore.getIsConfigsDataLoaded(componentId)) {
      this.loadComponentConfigsDataForce(componentId);
      return Promise.resolve();
    }
    return this.loadComponentConfigsDataForce(componentId);
  },
  loadComponentsMetadataForce(componentId, configId) {
    return installedComponentsApi
      .searchComponents({
        metadataKeys: USED_METADATA_KEYS,
        include: 'filteredMetadata',
        ...(componentId && { idComponent: componentId }),
        ...(configId && { configurationId: configId }),
      })
      .then((metadata) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_METADATA_LOAD_SUCCESS,
          result: metadata,
          componentId,
          configId,
        });
        return null;
      });
  },
  loadComponentsMetadata(componentId, configId) {
    if (InstalledComponentsStore.getIsMetadataLoaded(componentId, configId)) {
      this.loadComponentsMetadataForce(componentId, configId);
      return Promise.resolve();
    }
    return this.loadComponentsMetadataForce(componentId, configId);
  },
  saveComponentConfigData: function (componentId, configId, forceData, changeDescription) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_START,
      componentId,
      configId,
    });
    return storeEncodedConfig(componentId, configId, forceData.toJS(), changeDescription)
      .then(function (response) {
        VersionActionCreators.loadVersionsForce(componentId, configId);
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_SUCCESS,
          componentId,
          configId,
          configuration: response,
        });
        return response.configuration;
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_SAVE_ERROR,
          componentId,
          configId,
        });
        throw error;
      });
  },
  updateLocalState: function (componentId, configId, data, path) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_LOCAL_STATE_UPDATE,
      componentId: componentId,
      configId: configId,
      data: data,
      path: path,
    });
  },
  updateEditComponentRawConfigData: function (componentId, configId, newData) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_UPDATE,
      componentId: componentId,
      configId: configId,
      data: newData,
    });
  },
  cancelEditComponentRawConfigData: function (componentId, configId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATA_EDIT_CANCEL,
      componentId: componentId,
      configId: configId,
    });
  },
  updateEditComponentRawConfigDataParameters: function (componentId, configId, newData) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_UPDATE,
      componentId: componentId,
      configId: configId,
      data: newData,
    });
  },
  cancelEditComponentRawConfigDataParameters: function (componentId, configId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_RAWCONFIGDATAPARAMETERS_EDIT_CANCEL,
      componentId: componentId,
      configId: configId,
    });
  },
  loadDeletedComponents: function () {
    if (InstalledComponentsStore.getIsDeletedLoaded()) {
      this.loadDeletedComponentsForce();
      return Promise.resolve();
    }
    return this.loadDeletedComponentsForce();
  },
  createConfiguration: function (componentId, data) {
    return ensureComponentWithDetails(componentId).then((component) => {
      const configuration = component.get('emptyConfiguration', Map()).withMutations((config) => {
        if (!_.isUndefined(data.configuration)) {
          config.mergeDeep(fromJS(JSON.parse(data.configuration)));
        }

        if (ApplicationStore.hasPayAsYouGo() && supportsSimplifiedUi(component)) {
          config.setIn(['runtime', 'uiMode'], uiModes.SIMPLE);
        }

        if (ApplicationStore.hasNewNativeTypes() && supportAuthoritativeDataTypes(component)) {
          config.setIn(
            ['storage', 'output', 'data_type_support'],
            constants.DATA_TYPE_SUPPORT.AUTHORITATIVE,
          );
        }
      });

      if (!configuration.isEmpty()) {
        data.configuration = JSON.stringify(configuration.toJSON());
      }

      return injectDefaultsFromConfigurationSchema(
        data,
        component.get('configurationSchema', Map()),
      )
        .then((data) => installedComponentsApi.createConfiguration(componentId, data))
        .then((response) => {
          dispatcher.handleViewAction({
            type: constants.ActionTypes.INSTALLED_COMPONENTS_NEW_CONFIGURATION_SAVE_SUCCESS,
            componentId,
            component,
            configuration: response,
          });

          // some components require outputBucket in the configuration but it is not editable in the UI
          if ([KEBOOLA_EX_GOOGLE_ANALYTICS_V_4].includes(componentId)) {
            return installedComponentsApi.updateComponentConfiguration(
              componentId,
              response.id,
              {
                configuration: JSON.stringify(
                  fromJS(response.configuration)
                    .setIn(
                      ['parameters', 'outputBucket'],
                      getDefaultBucket('in', componentId, response.id),
                    )
                    .toJS(),
                ),
              },
              'Set output bucket',
            );
          }

          if (componentId === KEBOOLA_NO_CODE_DBT_TRANSFORMATION) {
            return WorkspacesActions.createConfigurationWorkspace(componentId, response.id, {
              backend: 'snowflake',
              readOnlyStorageAccess: ApplicationStore.hasReadOnlyStorage(),
            })
              .then((workspace) => {
                this.saveComponentConfigData(
                  componentId,
                  response.id,
                  fromJS(response.configuration).setIn(
                    ['parameters', 'authorization'],
                    workspace
                      .get('connection', Map())
                      .set('#password', workspace.getIn(['connection', 'password']))
                      .delete('password')
                      .delete('backend'),
                  ),
                  'Authorize',
                );
              })
              .then(() => response)
              .catch((error) => {
                installedComponentsApi.deleteConfiguration(componentId, response.id);

                throw error;
              });
          }

          return response;
        });
    });
  },
  updateComponentConfiguration: function (
    componentId,
    configurationId,
    data,
    changeDescription,
    field,
  ) {
    return installedComponentsApi
      .updateComponentConfiguration(componentId, configurationId, data, changeDescription)
      .then((response) => {
        VersionActionCreators.loadVersionsForce(componentId, configurationId);
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          field: field,
          data: response,
        });
        return response;
      });
  },
  restoreConfiguration: function (component, configuration, options = { skipNotification: false }) {
    const configurationId = configuration.get('id');
    const componentId = component.get('id');
    return installedComponentsApi.restoreConfiguration(componentId, configurationId).then(() => {
      return Promise.all([
        this.loadInstalledComponentsForce(),
        this.loadComponentConfigsDataForce(componentId),
      ]).then(() => {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: configurationRestoredNotification(
            componentId,
            configurationId,
            configuration.get('name'),
            ApplicationStore.hasFlows(),
          ),
          skip: Boolean(options.skipNotification),
        });

        return null;
      });
    });
  },
  deleteConfiguration: function (
    componentId,
    configurationId,
    options = { transition: true, notification: true },
  ) {
    const component = ComponentsStore.getComponent(componentId);
    const configuration = InstalledComponentsStore.getConfig(componentId, configurationId);

    if (options.transition) {
      if (component.get('type') === componentTypes.TRANSFORMATION) {
        RoutesStore.getRouter().transitionToForce(transformationsRouteNames.ROOT);
      } else if (component.get('id') === KEBOOLA_FLOW) {
        RoutesStore.getRouter().transitionToForce(flowsV2RouteNames.ROOT);
      } else if (component.get('id') === KEBOOLA_ORCHESTRATOR) {
        RoutesStore.getRouter().transitionToForce(
          ApplicationStore.hasFlows() ? flowsRouteNames.ROOT : orchestrationsRouteNames.ROOT,
        );
      } else {
        RoutesStore.getRouter().transitionToForce(componentsRouteNames.COMPONENT, {
          component:
            component.get('id') === KEBOOLA_EX_SAMPLE_DATA
              ? configuration.getIn(['configuration', 'parameters', 'componentId'])
              : componentId,
        });
      }
    }

    if (componentId === KEBOOLA_ORCHESTRATOR) {
      const { automationId, isDraft } = getAutomationFromFlowMetadata(
        configurationId,
        InstalledComponentsStore.getAllMetadata(),
      );

      if (isDraft) {
        AiApi.deleteAutomation(automationId);
      }
    }

    return installedComponentsApi.deleteConfiguration(componentId, configurationId).then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS,
        componentId: componentId,
        configurationId: configurationId,
      });
      if (options.notification) {
        return ApplicationActionCreators.sendNotification({
          type: 'info',
          message: configurationMovedToTrash(configuration),
          ...(!hasDisabledRestore(componentId) && {
            button: {
              label: 'Restore',
              action: () => {
                return this.restoreConfiguration(component, configuration);
              },
            },
          }),
        });
      }
    });
  },

  /*
    params:
      - component - id of component like ex-db
      - data - action parameters hashmap
      - method - default = run
      - notify - send notification, default true
   */
  runComponent: function (params) {
    const paramsProcessed = { method: 'run', notify: true, ...params };
    const component = ComponentsStore.getComponent(paramsProcessed.component);
    const isDisabled =
      !!paramsProcessed.data?.config &&
      InstalledComponentsStore.getConfig(
        paramsProcessed.component,
        paramsProcessed.data?.config,
      ).get('isDisabled', false);

    return componentRunner
      .run({
        component: paramsProcessed.component,
        data: {
          ...paramsProcessed.data,
          ...(!!component.get('tag') && { tag: component.get('tag') }),
        },
        method: isDisabled ? 'forceRun' : paramsProcessed.method,
      })
      .tap((job) => {
        if (paramsProcessed.notify) {
          ApplicationActionCreators.sendNotification({
            type: 'info',
            message: jobScheduledNotification(component, job),
          });
        }

        if (paramsProcessed.data?.config) {
          if (!ApplicationStore.hasNewQueue()) {
            return oldJobsActionCreators.loadComponentConfigurationLatestJobs(
              paramsProcessed.component,
              paramsProcessed.data.config,
            );
          }

          dispatcher.handleViewAction({
            type: jobsActionTypes.JOBS_LATEST_LOAD_SUCCESS,
            componentId: paramsProcessed.component,
            configurationId: paramsProcessed.data.config,
            jobs: [job],
            append: true,
          });

          Promise.delay(6000).then(() => {
            return jobsActionCreators.loadComponentConfigurationLatestJobs(
              paramsProcessed.component,
              paramsProcessed.data.config,
              paramsProcessed.data.row,
            );
          });
        }
      });
  },
  cancelEditTemplatedComponentConfigData: function (componentId, configId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_CANCEL,
      componentId: componentId,
      configId: configId,
    });
  },
  updateEditTemplatedComponentConfigDataTemplate: function (componentId, configId, template) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_TEMPLATE,
      componentId: componentId,
      configId: configId,
      template: template,
    });
  },
  updateEditTemplatedComponentConfigDataString: function (componentId, configId, value) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_STRING,
      componentId: componentId,
      configId: configId,
      value: value,
    });
  },
  updateEditTemplatedComponentConfigDataParams: function (componentId, configId, value) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.INSTALLED_COMPONENTS_TEMPLATED_CONFIGURATION_EDIT_UPDATE_PARAMS,
      componentId: componentId,
      configId: configId,
      value: value,
    });
  },

  updateComponentState: function (componentId, configurationId, state) {
    return installedComponentsApi
      .updateConfigurationState(
        componentId,
        configurationId,
        {
          state: JSON.stringify(state.toJS()),
        },
        `Update configuration state`,
      )
      .then((response) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS,
          componentId: componentId,
          configurationId: configurationId,
          data: response,
        });
        return response;
      });
  },

  resetComponentConfigurationState: function (componentId, configId) {
    return installedComponentsApi
      .updateConfigurationState(componentId, configId, { state: JSON.stringify({}) })
      .then((response) => {
        ApplicationActionCreators.sendNotification({
          type: 'info',
          message: 'State of the configuration has been deleted.',
        });
        dispatcher.handleViewAction({
          type: constants.ActionTypes.INSTALLED_COMPONENTS_CONFIGDATA_LOAD_SUCCESS,
          componentId,
          configId,
          data: response,
        });
      });
  },

  resetConfigurationsToProductionVersion: function (components) {
    return Promise.map(
      components,
      ([componentId, configId]) => {
        return installedComponentsApi
          .resetDevConfigurationToProductionVersion(componentId, configId)
          .reflect();
      },
      { concurrency: 5 },
    ).then((responses) => {
      const fulfilledResponsesCount = responses.filter((r) => r.isFulfilled()).length;
      const rejectedResponsesCount = responses.length - fulfilledResponsesCount;

      if (fulfilledResponsesCount !== 0) {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message:
            fulfilledResponsesCount > 1
              ? `${fulfilledResponsesCount} configurations have been reset.`
              : 'Configuration has been reset.',
        });
      }
      if (rejectedResponsesCount !== 0) {
        ApplicationActionCreators.sendNotification({
          type: 'error',
          message: `${rejectedResponsesCount} configuration${
            rejectedResponsesCount > 1 ? 's' : ''
          } failed to reset.`,
        });
      }
    });
  },

  resetConfigurationToProductionVersion: function (componentId, configId) {
    return installedComponentsApi.resetDevConfigurationToProductionVersion(componentId, configId);
  },

  clearInputMappingState: function (componentId, configurationId, tableId) {
    return installedComponentsApi
      .getComponentConfiguration(componentId, configurationId)
      .then((response) => {
        const data = {
          state: JSON.stringify(
            removeTableFromInputTableState(fromJS(response.state), tableId).toJS(),
          ),
        };
        return installedComponentsApi
          .updateConfigurationState(componentId, configurationId, data)
          .then((response) => {
            dispatcher.handleViewAction({
              type: constants.ActionTypes.INSTALLED_COMPONENTS_UPDATE_CONFIGURATION_SUCCESS,
              componentId,
              configurationId,
              data: response,
            });
          });
      });
  },

  deleteMappings: function (
    configData,
    componentId,
    configurationId,
    rowId,
    type,
    storage,
    mappingKeys,
  ) {
    const changeDescription = `Delete ${type} ${storage} mappings`;
    const newConfigData = configData.updateIn(
      getMappingBasePath(componentId, type, storage),
      (mappings) => mappings.filterNot((table, index) => mappingKeys.get(index, false)),
    );

    if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
      return LegacyTransformationActionCreators.saveTransformation(
        configurationId,
        rowId,
        newConfigData,
        'mapping',
        changeDescription,
      );
    }

    if (rowId) {
      return ConfigurationRowsActionCreators.updateSimple(
        componentId,
        configurationId,
        rowId,
        { configuration: JSON.stringify(newConfigData) },
        changeDescription,
      );
    }

    return this.saveComponentConfigData(
      componentId,
      configurationId,
      newConfigData,
      changeDescription,
    );
  },

  setConfigurationMetadata: function (componentId, configId, metadata, options) {
    return installedComponentsApi
      .saveConfigurationMetadata(componentId, configId, metadata)
      .then((metadata) => {
        if (options?.skipStoreUpdate) {
          return;
        }

        dispatcher.handleViewAction({
          type: constants.ActionTypes.CONFIGURATION_METADATA_SAVE_SUCCESS,
          componentId,
          configId,
          data: metadata,
        });
      });
  },

  deleteConfigurationMetadata: function (componentId, configId, metadataId, options) {
    return installedComponentsApi
      .deleteConfigurationMetadata(componentId, configId, metadataId)
      .then(() => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.CONFIGURATION_METADATA_DELETE_SUCCESS,
          skipEmitChanges: !!options?.skipEmitChanges,
          componentId,
          configId,
          metadataId,
        });
      });
  },

  toggleExpandedFolder: function (entity, folder, isExpanded) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TOGGLE_EXPAND_FOLDER,
      entity,
      folder,
      isExpanded,
    });
  },
};
