import { fromJS, List, type Map } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import type {
  AutomationDetail,
  AutomationMessage,
  Context,
  MessageTypesUser,
  UserMessage,
} from '@/api/routes/aiService';
import { KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import {
  findAutomationIdFromMetadata,
  findPublishedAutomationIdFromFlowMetadata,
} from '@/modules/components/helpers';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import { JOB_FINISHED_STATUSES, JOB_RUNNING_STATUSES } from '@/modules/queue/constants';
import type HttpError from '@/utils/errors/HttpError';
import { parse } from '@/utils/tableIdParser';
import { ERROR_MESSAGE_PREFIX, roles } from './constants';

type MessageInput = {
  text?: string | null;
  type?: MessageTypesUser;
  role?: 'user';
  data?: Context;
};

const constructMessage = (
  input: MessageInput,
  allComponents: Record<string, any> = {},
): UserMessage => {
  const {
    taskId,
    componentId,
    configurationId,
    configuration,
    rowId,
    configurationVersion,
    ...messageData
  } = input.data ?? {};
  const message: UserMessage = {
    text: input.text ?? null,
    type: input.type ?? 'plainText',
    role: input.role ?? roles.USER,
    data: messageData ?? {},
  };

  if (taskId) {
    return {
      ...message,
      ...(message.type === 'plainText' && {
        type:
          componentId && allComponents[componentId]?.type === componentTypes.TRANSFORMATION
            ? 'transformationEditInProgress'
            : 'configurationEditInProgress',
      }),
      data: {
        ...message.data,
        taskId: taskId.toString(),
        componentId,
        configurationId,
        configurationVersion: configurationVersion?.toString(),
        rowId,
        configuration,
        ...(rowId && { rowId }),
      },
    };
  }

  if (configuration && !_.isEmpty(configuration) && message.type === 'plainText') {
    return {
      ...message,
      type: 'flowConfigurationInProgress',
    };
  }

  return message;
};

const getResponseTypeFromRequestType = (
  type: AutomationMessage['type'],
): MessageTypesUser | undefined => {
  switch (type) {
    case 'componentSelectionRequest':
      return 'componentSelectionResponse';
    case 'sourceTableSelectionRequest':
      return 'sourceTableSelectionResponse';
    case 'intentConfirmationRequest':
      return 'intentConfirmationResponse';
    default:
      return;
  }
};

const isInteractiveMessageRequest = (messageObject?: AutomationMessage) => {
  if (!messageObject) return false;

  return [
    'componentSelectionRequest',
    'sourceTableSelectionRequest',
    'intentConfirmationRequest',
  ].includes(messageObject.type);
};

const isInteractiveMessageResponse = (messageObject?: AutomationMessage) => {
  if (!messageObject) return false;

  return [
    'componentSelectionResponse',
    'sourceTableSelectionResponse',
    'intentConfirmationResponse',
  ].includes(messageObject.type);
};

const isFakeErrorMessage = (messageObject?: AutomationMessage) =>
  !!messageObject?.text?.startsWith(ERROR_MESSAGE_PREFIX);

const isUserError = (error?: HttpError) =>
  !!error?.response?.statusCode?.toString().startsWith('4');

const isClientMessage = (messageObject?: AutomationMessage) => messageObject?.role === roles.USER;

const injectSourceTablesToVisualizationPhases = (
  visualizationPhases: List<Map<string, any>>,
  allTables: Map<string, any>,
  sourceTables?: AutomationDetail['sourceTables'],
) => {
  const phases = visualizationPhases;
  const sourceTablesTasks = fromJS(
    sourceTables?.map((tableId) => {
      const parsedTable = parse(tableId);

      return {
        id: tableId,
        name: allTables.getIn([tableId, 'displayName'], parsedTable.parts.table),
        component: allTables.getIn([tableId, 'bucket', 'displayName'], parsedTable.parts.bucket),
        icon: 'table',
        type: 'Table',
        hasDeletedConfiguration: !allTables.has(tableId),
        specificRows: [],
      };
    }),
  )?.toList() as List<any>;

  if (!sourceTablesTasks || !phases) return phases;

  // If there is no 'Data source' phase, add it. It should always have id `1`.
  if (!phases.some((phase) => phase?.get('id') === 1)) {
    return phases.unshift(
      fromJS({
        id: 1,
        name: 'Data source',
        key: '1-Data source',
        isFake: true,
        tasks: sourceTablesTasks,
      }),
    );
  }

  return phases.map((phase) => {
    if (phase?.get('id') === 1) {
      return phase.update('tasks', List(), (tasks) => tasks.concat(sourceTablesTasks));
    }

    return phase;
  });
};

const isAutomationProcessing = (automation?: AutomationDetail) => {
  if (!automation?.orchestrationTaskJobs.phases.length) return false;

  return automation.orchestrationTaskJobs.phases
    .flatMap((phase) => phase.tasks)
    .some((task) => JOB_RUNNING_STATUSES.includes(task.status as any));
};

const isAutomationSuccessfullyConfigured = (automation?: AutomationDetail) => {
  if (!automation?.orchestrationTaskJobs.phases.length) return false;

  return automation.orchestrationTaskJobs.phases
    .flatMap((phase) => phase.tasks)
    .every((task) => task.status === 'success');
};

const getCurrentlyFinishedTasks = (
  previousAutomation: AutomationDetail | undefined,
  automation: AutomationDetail | undefined,
) => {
  if (!previousAutomation || !automation) return;

  return automation.orchestrationTaskJobs.phases
    .flatMap((phase) => phase.tasks)
    .filter((newState) => {
      if (!JOB_FINISHED_STATUSES.includes(newState.status as any)) return false;

      const previousTaskStatus = previousAutomation.orchestrationTaskJobs.phases
        .flatMap((phase) => phase.tasks)
        .find((previousState) => previousState.id === newState.id)?.status;

      return JOB_RUNNING_STATUSES.includes(previousTaskStatus as any);
    });
};

const notifyIfTaskJobFinished = (
  tasks?: AutomationDetail['orchestrationTaskJobs']['phases'][number]['tasks'],
) => {
  if (!tasks?.length) return;

  tasks.forEach((task) => {
    if (!task || !['success', 'error'].includes(task.status)) return;

    ApplicationActionCreators.sendNotification({
      type: task.status,
      message:
        task.status === 'error'
          ? (task.errorMessage ?? 'The task job has failed.')
          : 'The task job has completed successfully!',
    });
  });
};

const injectTaskErrorToEditStartedMessage = (
  messageObject: UserMessage,
  automation?: AutomationDetail | null,
) => {
  if (
    !messageObject?.data.taskId ||
    !['configurationEditStarted', 'transformationEditStarted'].includes(messageObject.type)
  )
    return messageObject;

  const task = automation?.orchestrationTaskJobs.phases
    .flatMap((phase) => phase.tasks)
    .find((task) => task.id === messageObject.data.taskId);

  if (!task?.errorMessage) return messageObject;

  return {
    ...messageObject,
    text: `${ERROR_MESSAGE_PREFIX} \`\`\`${task.errorMessage}\`\`\``,
  };
};

const savePublishedAutomationIdMetadata = (configId: string, automationId: string) => {
  return InstalledComponentsActionCreators.setConfigurationMetadata(
    KEBOOLA_ORCHESTRATOR,
    configId,
    [{ key: MetadataKeys.PUBLISHED_BY_AUTOMATION_ID, value: automationId }],
  );
};

const isFlowCreatedByPublishedAutomation = (
  configId: string | undefined,
  componentsMetadata: Map<string, any> | undefined,
) => {
  return !!configId && !!findPublishedAutomationIdFromFlowMetadata(configId, componentsMetadata);
};

const getAutomationFromFlowMetadata = (
  configId: string | undefined,
  componentsMetadata: Map<string, any> | undefined,
) => {
  const automationId = findAutomationIdFromMetadata(
    KEBOOLA_ORCHESTRATOR,
    configId,
    componentsMetadata,
  );

  return {
    automationId,
    isDraft: !!automationId && !isFlowCreatedByPublishedAutomation(configId, componentsMetadata),
  };
};

export {
  constructMessage,
  getResponseTypeFromRequestType,
  isInteractiveMessageRequest,
  isInteractiveMessageResponse,
  isFakeErrorMessage,
  isUserError,
  isClientMessage,
  getCurrentlyFinishedTasks,
  injectTaskErrorToEditStartedMessage,
  injectSourceTablesToVisualizationPhases,
  isAutomationProcessing,
  isAutomationSuccessfullyConfigured,
  notifyIfTaskJobFinished,
  getAutomationFromFlowMetadata,
  savePublishedAutomationIdMetadata,
};
