import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { MetadataKeys } from '@/modules/components/MetadataConstants';

const routeNames = {
  DEVELOPMENT_OVERVIEW: 'development-overview',
};

const actionTypes = {
  LOAD_DATA_START: 'LOAD_DATA_START',
  LOAD_DATA_FINISH: 'LOAD_DATA_FINISH',
  SET_CURRENT_DEV_BRANCH_ID: 'SET_CURRENT_DEV_BRANCH_ID',
  LOAD_MERGE_REQUESTS_SUCCESS: 'LOAD_MERGE_REQUESTS_SUCCESS',
  LOAD_PRODUCTION_COMPONENTS_SUCCESS: 'LOAD_PRODUCTION_COMPONENTS_SUCCESS',
  LOAD_PRODUCTION_DELETED_COMPONENTS_SUCCESS: 'LOAD_PRODUCTION_DELETED_COMPONENTS_SUCCESS',
  LOAD_PRODUCTION_COMPONENTS_METADATA_SUCCESS: 'LOAD_PRODUCTION_COMPONENTS_METADATA_SUCCESS',
  INITIALIZE_SELECTED_CHANGES: 'INITIALIZE_SELECTED_CHANGES',
  RESET_SELECTED_CHANGES: 'RESET_SELECTED_CHANGES',
  TOGGLE_CONFIGURATION_CHANGE: 'TOGGLE_CONFIGURATION_CHANGE',
  TOGGLE_ALL_CONFIGURATIONS: 'TOGGLE_ALL_CONFIGURATIONS',
  TOGGLE_VARIABLE_CHANGE: 'TOGGLE_VARIABLE_CHANGE',
};

const MERGE_REQUEST_STATUS = {
  DEVELOPMENT: 'development',
  IN_REVIEW: 'in_review',
  APPROVED: 'approved',
  CANCELED: 'canceled',
};

const WAIT_AFTER_MERGE = 3000;

const NonMergableComponents = [KEBOOLA_SANDBOXES];

// only folders & source automation IDs are now supported to be merged back to the production
const MergableMetadata = [
  MetadataKeys.CONFIGURATION_FOLDER,
  MetadataKeys.CREATED_BY_AUTOMATION_ID,
  MetadataKeys.PUBLISHED_BY_AUTOMATION_ID,
];

export {
  routeNames,
  actionTypes,
  MERGE_REQUEST_STATUS,
  WAIT_AFTER_MERGE,
  NonMergableComponents,
  MergableMetadata,
};
