import { TableIconAction } from './TableIconAction';

type Props = {
  onClick: () => void;
  multiple?: boolean;
  disabled?: boolean;
  disabledReason?: string;
};

export const CreateSnapshot = ({
  onClick,
  multiple = false,
  disabled = false,
  disabledReason,
}: Props) => (
  <TableIconAction
    tooltip={disabledReason || `Create snapshot${multiple ? 's' : ''}`}
    onClick={onClick}
    disabled={disabled}
    libraryIcon="camera-viewfinder"
  />
);
