import PropTypes from 'prop-types';
import { FormControl } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import _ from 'underscore';

import { ButtonInline, cn, HelpBlock } from '@keboola/design';

import { KEBOOLA_WR_LOOKER_V2 } from '@/constants/componentIds';
import ColumnDataPreview from '@/modules/components/react/components/ColumnDataPreview';
import { DATA_TYPES_FROM_STORAGE } from '@/modules/wr-db/constants';
import { hasDisabledNullable } from '@/modules/wr-db/helpers';
import Checkbox from '@/react/common/Checkbox';
import Select from '@/react/common/Select';
import LookerLinkTableModal from './LookerLinkTableModal';

const ColumnRow = createReactClass({
  propTypes: {
    componentId: PropTypes.string.isRequired,
    loadPreviewFn: PropTypes.func.isRequired,
    ensureTableDetails: PropTypes.func.isRequired,
    loadingPreview: PropTypes.bool.isRequired,
    readOnly: PropTypes.bool.isRequired,
    defaultColumnsTypes: PropTypes.object.isRequired,
    primaryKey: PropTypes.object.isRequired,
    column: PropTypes.object.isRequired,
    editingColumn: PropTypes.object,
    editColumnFn: PropTypes.func,
    dataTypes: PropTypes.array,
    isSaving: PropTypes.bool,
    dataPreview: PropTypes.object,
    isValid: PropTypes.bool,
    disabledFields: PropTypes.array,
    tablePrimaryKey: PropTypes.object,
    otherTables: PropTypes.object,
  },

  getInitialState() {
    return {
      showLookerLinkPrimaryKeyModal: false,
    };
  },

  render() {
    return (
      <>
        <tr className={cn({ danger: !this.props.isValid })}>
          <td className="overflow-break-anywhere">{this.props.column.get('name')}</td>
          {this.renderColumnNameEdit()}
          {this._renderTypeSelect()}
          {!this.props.disabledFields.includes('nullable') && (
            <td className="pl-0 pr-0 text-center">{this._createNullable()}</td>
          )}
          {!this.props.disabledFields.includes('default') && (
            <td>{this._createInput('default')}</td>
          )}
          <td onMouseEnter={this.props.loadPreviewFn} onFocus={this.props.loadPreviewFn}>
            <ColumnDataPreview
              columnName={this.props.column.get('name')}
              tableData={this.props.dataPreview}
              loading={this.props.loadingPreview}
            />
          </td>
        </tr>
        {this.props.componentId === KEBOOLA_WR_LOOKER_V2 && (
          <LookerLinkTableModal
            show={this.state.showLookerLinkPrimaryKeyModal}
            setPrimaryKeyFn={(databaseColumnName) => {
              this.props.editColumnFn(this.props.editingColumn.set('dbName', databaseColumnName));
            }}
            onHideFn={() => this.setState({ showLookerLinkPrimaryKeyModal: false })}
            availableTables={this.props.otherTables}
            column={this.props.column}
          />
        )}
      </>
    );
  },

  renderColumnNameEdit() {
    if (
      this.props.componentId === KEBOOLA_WR_LOOKER_V2 &&
      this.props.column.get('dbName') === this.props.tablePrimaryKey.get(0)
    ) {
      return <td className="kbc-static-cell">{this.props.column.get('dbName')}</td>;
    }

    if (this.props.componentId === KEBOOLA_WR_LOOKER_V2) {
      return <td>{this._createLookerInput('dbName')}</td>;
    }

    return <td>{this._createInput('dbName')}</td>;
  },

  _renderTypeSelect() {
    return (
      <td>
        <Select
          clearable={false}
          value={this.props.editingColumn.get('type')}
          options={this._getDataTypes()
            .sort()
            .concat([DATA_TYPES_FROM_STORAGE, 'IGNORE'])
            .map((type) => ({ value: type, label: type }))}
          onChange={(value) => {
            return this.props.ensureTableDetails(value).then(() => {
              if (value === DATA_TYPES_FROM_STORAGE) {
                return this.props.editColumnFn(
                  this.props.defaultColumnsTypes.get(
                    this.props.column.get('name'),
                    this.props.editingColumn.merge({
                      type: 'IGNORE',
                      default: '',
                      size: '',
                    }),
                  ),
                );
              }
              let newColumn = this.props.editingColumn.set('type', value);
              if (value === 'IGNORE') {
                newColumn = newColumn.set('default', '');
              }
              if (_.isString(this._getSizeParam(value))) {
                const defaultSize = this._getSizeParam(value);
                newColumn = newColumn.set('size', defaultSize);
              } else {
                newColumn = newColumn.set('size', '');
              }
              return this.props.editColumnFn(newColumn);
            });
          }}
          disabled={this.props.readOnly || this.props.isSaving}
        />
        {_.isString(this._getSizeParam(this.props.editingColumn.get('type'))) &&
          this._createInput('size', 'mt-1')}
      </td>
    );
  },

  _getSizeParam(dataType) {
    const dt = _.find(this.props.dataTypes, (d) => _.isObject(d) && _.keys(d)[0] === dataType);
    return dt && dt[dataType] && dt[dataType].defaultSize;
  },

  _getDataTypes() {
    return _.map(this.props.dataTypes, (dataType) => {
      // it could be object eg {VARCHAR: {defaultSize:''}}
      if (_.isObject(dataType)) {
        return _.keys(dataType)[0];
      }
      // or string
      return dataType;
    });
  },

  _createNullable() {
    if (this.props.editingColumn.get('type') === 'IGNORE') {
      return null;
    }

    const isDisabled =
      this.props.readOnly ||
      (hasDisabledNullable(
        this.props.componentId,
        this.props.primaryKey,
        this.props.column.get('name'),
      ) &&
        this.props.editingColumn.get('null') === '0');

    return (
      <Checkbox
        disabled={isDisabled}
        checked={this.props.editingColumn.get('null') === '1'}
        onChange={(checked) => {
          const newColumn = this.props.editingColumn.set('null', checked ? '1' : '0');
          return this.props.editColumnFn(newColumn);
        }}
      />
    );
  },

  _createInput(property, className) {
    if (this.props.editingColumn.get('type') === 'IGNORE') {
      return null;
    }

    return (
      <FormControl
        type="text"
        value={this.props.editingColumn.get(property)}
        className={cn(className)}
        disabled={this.props.readOnly || this.props.isSaving}
        onChange={(e) => {
          const newValue = e.target.value;
          const newColumn = this.props.editingColumn.set(property, newValue);
          return this.props.editColumnFn(newColumn);
        }}
      />
    );
  },

  _createLookerInput(property) {
    if (this.props.editingColumn.get('type') === 'IGNORE') {
      return null;
    }

    return (
      <>
        <FormControl
          type="text"
          value={this.props.editingColumn.get(property)}
          disabled={this.props.readOnly || this.props.isSaving}
          onChange={(e) => {
            const newValue = e.target.value;
            const newColumn = this.props.editingColumn.set(property, newValue);
            return this.props.editColumnFn(newColumn);
          }}
        />
        {!this.props.readOnly && this.props.otherTables.count() > 0 && (
          <HelpBlock className="tw-mt-1">
            <ButtonInline
              className="tw-text-xs"
              onClick={() => this.setState({ showLookerLinkPrimaryKeyModal: true })}
            >
              Set Foreign Key
            </ButtonInline>
          </HelpBlock>
        )}
      </>
    );
  },
});

export default ColumnRow;
