import { useState } from 'react';
import { Button } from 'react-bootstrap';
import type { Map } from 'immutable';
import { List } from 'immutable';

import { Icon as BaseIcon, Tooltip } from '@keboola/design';

import { KEBOOLA_STORAGE } from '@/constants/componentIds';
import { isNullable } from '@/modules/components/utils/datatypeHelpers';
import {
  getNativeTypesDefinitionPath,
  getTableColumnMetadata,
} from '@/modules/components/utils/tableMetadataHelper';
import {
  findBasetypeDatatype,
  findLengthDatatypeValue,
  findNullableDatatypeValue,
} from '@/modules/storage/helpers';
import ComponentIcon from '@/react/common/ComponentIcon';
import Gravatar from '@/react/common/Gravatar';
import ColumnDatatypesModal from './ColumnDatatypesModal';

const Icon = ({ provider, components }: { provider: string; components: Map<string, any> }) => {
  if (provider === 'user') {
    return (
      <Tooltip
        placement="top"
        tooltip="Provided by user"
        triggerClassName="tw-inline-flex tw-align-text-top tw-mr-2"
      >
        <Gravatar size={16} />
      </Tooltip>
    );
  }

  const component = components.get(provider === 'storage' ? KEBOOLA_STORAGE : provider);

  if (!component) {
    return null;
  }

  return (
    <Tooltip
      placement="top"
      type="explanatory"
      tooltip={`Provided by ${component.get('name')}`}
      triggerClassName="tw-inline-flex tw-align-text-top tw-mr-2"
    >
      <ComponentIcon component={component} size="16" />
    </Tooltip>
  );
};

type DataType = {
  type: string;
  nullable: boolean;
  length: string | null;
  provider: string;
};

const getNativeDataType = (table: Map<string, any>, columnName: string): DataType | null => {
  const nativeTypesDefinitionPath = getNativeTypesDefinitionPath(table);
  const metadata = table
    .getIn([...nativeTypesDefinitionPath, 'columns'], List())
    .find((column: Map<string, any>) => column.get('name') === columnName);

  if (!metadata) {
    return null;
  }

  return {
    type: metadata.getIn(['definition', 'type']),
    length: metadata.getIn(['definition', 'length'], null),
    nullable: metadata.getIn(['definition', 'nullable']),
    provider: 'storage',
  };
};

const getDataType = (metadata: List<any>): DataType | null => {
  const baseTypeMetadata = findBasetypeDatatype(metadata);

  if (!baseTypeMetadata) {
    return null;
  }

  const provider = baseTypeMetadata.get('provider');
  const length = findLengthDatatypeValue(metadata, provider);
  const nullable = findNullableDatatypeValue(metadata, provider);

  return {
    type: baseTypeMetadata.get('value'),
    length,
    nullable: isNullable(nullable),
    provider,
  };
};

type Props = {
  table: Map<string, any>;
  components: Map<string, any>;
  columnName: string;
  saveUserType: (columnName: string, userDataType: Map<string, any>) => Promise<void>;
  deleteUserType: (columnName: string) => Promise<unknown[]>;
  canEdit: boolean;
  disabled: boolean;
  loadSampleData: () => void;
  sampleData?: Map<string, any> | null;
};

const ColumnDatatypes = ({
  table,
  components,
  columnName,
  saveUserType,
  deleteUserType,
  canEdit,
  disabled,
  loadSampleData,
  sampleData,
}: Props) => {
  const [openModal, setOpenModal] = useState(false);

  const isTableNativelyTyped = table.get('isTyped');

  const columnMetadata = isTableNativelyTyped
    ? List()
    : getTableColumnMetadata(table).get(columnName, List());

  const dataType = isTableNativelyTyped
    ? getNativeDataType(table, columnName)
    : getDataType(columnMetadata);

  const renderDatatypes = () => {
    if (!dataType) {
      return <span className="text-muted">N/A</span>;
    }

    return (
      <>
        <Icon provider={dataType.provider} components={components} />
        {`${dataType.type}${dataType.length ? ` (${dataType.length})` : ''}${
          dataType.nullable ? `, Nullable` : ''
        }`}
      </>
    );
  };

  return (
    <>
      {renderDatatypes()}
      {canEdit && (
        <>
          <span className="f-13 text-muted">
            <Tooltip tooltip="Update data type" placement="top">
              <Button
                bsStyle="link"
                className="btn-link-inline pl-1 pr-1"
                onClick={() => {
                  setOpenModal(true);

                  if (!sampleData) {
                    loadSampleData();
                  }
                }}
                disabled={disabled}
              >
                <BaseIcon icon="pen" className="font-size-inherit" />
              </Button>
            </Tooltip>
          </span>
          <ColumnDatatypesModal
            show={openModal}
            table={table}
            columnName={columnName}
            sampleData={sampleData}
            metadata={columnMetadata}
            onHide={() => setOpenModal(false)}
            saveUserType={saveUserType}
            deleteUserType={deleteUserType}
            components={components}
          />
        </>
      )}
    </>
  );
};

export default ColumnDatatypes;
