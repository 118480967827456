import type { ReactNode } from 'react';

type Props = {
  children?: ReactNode;
};

export const EmptySearchContent = ({ children = 'No results found.' }: Props) => {
  return (
    <div className="tw-px-0 tw-py-[30px] tw-text-center tw-text-sm tw-text-neutral-400">
      {children}
    </div>
  );
};
