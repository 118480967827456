export function getDestinationName(fileName: string) {
  const destinationFile = fileName.toString().split('/');
  let resultName = destinationFile[destinationFile.length - 1];
  if (resultName.endsWith('.csv')) {
    resultName = resultName.slice(0, -4);
  }
  return resultName;
}

type ObjectWithTimestamp = {
  timestamp: number;
};

export function sortTimestampsInDescendingOrder(
  obj1: ObjectWithTimestamp,
  obj2: ObjectWithTimestamp,
) {
  if (obj1.timestamp < obj2.timestamp) {
    return 1;
  }
  if (obj1.timestamp > obj2.timestamp) {
    return -1;
  }
  return 0;
}

export function sortTimestampsInAscendingOrder(
  obj1: ObjectWithTimestamp,
  obj2: ObjectWithTimestamp,
) {
  if (obj1.timestamp > obj2.timestamp) {
    return 1;
  }
  if (obj1.timestamp < obj2.timestamp) {
    return -1;
  }
  return 0;
}
