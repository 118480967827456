import createReactClass from 'create-react-class';

import {
  FEATURE_NEW_TRANSFORMATIONS_ONLY,
  FEATURE_SANDBOXES_PERSISTENT_VOLUME,
  FEATURE_SHOW_TRANSFORMATION_MIGRATION,
} from '@/constants/features';
import { ADMIN_ROLES } from '@/constants/KbcConstants';
import {
  canChangeProjectOrganization,
  canDeleteProject,
  hasAccessToProjectOrganization,
} from '@/modules/admin/privileges';
import StackFeaturesStore from '@/modules/stack-features/Store';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import DataPersistency from './components/DataPersistency';
import DataTakeout from './components/DataTakeout';
import DeleteProject from './components/DeleteProject';
import ProjectDetails from './components/ProjectDetails';
import SettingsTabs from './components/SettingsTabs';
import StorageBackend from './components/StorageBackend';
import { changeOrganization, removeProject, updateProject } from './actions';
import SettingsStore from './SettingsStore';

const Project = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, SettingsStore, StackFeaturesStore)],

  getStateFromStores() {
    return {
      user: SettingsStore.getUser(),
      project: SettingsStore.getProject(),
      organizationsViaMaintainer: SettingsStore.getOrganizationsViaMaintainer(),
      organizations: SettingsStore.getOrganizations(),
      projectTemplates: ApplicationStore.getProjectTemplates(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      hasStackEnabledPersistentStorage: StackFeaturesStore.hasStackFeature(
        FEATURE_SANDBOXES_PERSISTENT_VOLUME,
      ),
      hasNewQueue: ApplicationStore.hasNewQueue(),
      sapiToken: ApplicationStore.getSapiToken(),
      isReadOnly: ApplicationStore.isReadOnly(),
    };
  },

  render() {
    return <SettingsTabs>{this.renderBody()}</SettingsTabs>;
  },

  renderBody() {
    if (this.state.project.isEmpty()) {
      return (
        <div className="box">
          <div className="box-content">No project details available.</div>
        </div>
      );
    }

    return (
      <div className="box project-settings">
        <div>
          {this.renderProjectDetails()}
          {this.renderStorageInfo()}
        </div>
        <div>
          {this.renderDataTakeout()}
          {this.renderDataPersistency()}
          {this.renderDeleteProject()}
        </div>
      </div>
    );
  },

  renderProjectDetails() {
    return (
      <ProjectDetails
        user={this.state.user}
        project={this.state.project}
        organizations={this.state.organizationsViaMaintainer}
        projectTemplates={this.state.projectTemplates}
        handleChangeOrganization={changeOrganization}
        handleUpdateProject={updateProject}
        hasAdminRoleInProject={this.state.sapiToken.getIn(['admin', 'role']) === ADMIN_ROLES.ADMIN}
        hasAccessToProjectOrganization={hasAccessToProjectOrganization(
          this.state.user,
          this.state.organizations,
          this.state.organizationsViaMaintainer,
          this.state.project,
        )}
        canChangeProjectOrganization={canChangeProjectOrganization(
          this.state.user,
          this.state.organizationsViaMaintainer,
          this.state.project,
        )}
      />
    );
  },

  renderStorageInfo() {
    return (
      <StorageBackend
        user={this.state.user}
        project={this.state.project}
        handleUpdateStorage={updateProject}
      />
    );
  },

  renderDataPersistency() {
    if (
      !this.state.project.get('features').includes(FEATURE_SHOW_TRANSFORMATION_MIGRATION) &&
      !this.state.project.get('features').includes(FEATURE_NEW_TRANSFORMATIONS_ONLY)
    ) {
      return null;
    }

    return (
      <DataPersistency
        isActive={
          this.state.hasStackEnabledPersistentStorage ||
          this.state.project.get('features').includes(FEATURE_SANDBOXES_PERSISTENT_VOLUME)
        }
      />
    );
  },

  renderDeleteProject() {
    if (
      !canDeleteProject(
        this.state.user,
        this.state.sapiToken,
        this.state.organizationsViaMaintainer,
        this.state.project,
      )
    ) {
      return null;
    }

    return <DeleteProject project={this.state.sapiToken.get('owner')} onDelete={removeProject} />;
  },

  renderDataTakeout() {
    if (this.state.isReadOnly || this.state.hasProtectedDefaultBranch) {
      return null;
    }

    return <DataTakeout />;
  },
});

export default Project;
