import type { ReactNode } from 'react';
import Switch from 'rc-switch';

import { FormGroup, HelpBlock, Label, Tooltip } from '@keboola/design';

const SwitchControl = (props: {
  id: string;
  label: ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
  readOnly: boolean;
  help?: string;
  disabledReason?: string;
}) => {
  const toggle = () => !props.readOnly && props.onChange(!props.checked);

  return (
    <FormGroup className="tw-mb-4">
      <Tooltip
        placement="top"
        type="explanatory"
        tooltip={props.disabledReason}
        forceHide={!props.disabledReason}
        triggerClassName="tw-inline-flex tw-items-center tw-justify-start"
      >
        <Switch
          id={props.id}
          prefixCls="switch"
          className="btn-icon wider"
          checked={props.checked}
          disabled={props.readOnly}
          onChange={toggle}
        />
        <Label
          htmlFor={props.id}
          className="clickable m-0"
          onClick={(event) => {
            event.preventDefault();
            toggle();
          }}
        >
          {props.label}
        </Label>
      </Tooltip>
      {props.help && <HelpBlock className="tw-mt-1">{props.help}</HelpBlock>}
    </FormGroup>
  );
};

export default SwitchControl;
