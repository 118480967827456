import { useState } from 'react';
import type { ChangeEvent, SyntheticEvent } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import { Map } from 'immutable';

import { Alert, Button, ButtonGroup, HelpBlock, IconButton, Link } from '@keboola/design';

import { backendImages } from '@/constants/backendImages';
import StorageApi from '@/modules/components/StorageApi';
import { registerExternalBucket } from '@/modules/storage/actions';
import { backends, nameWarning, SNOWFLAKE_DOCS } from '@/modules/storage/constants';
import { parseBigQueryDatasetUrl } from '@/modules/storage/helpers';
import type { Backend } from '@/modules/storage/types';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import InfoTooltip from '@/react/common/InfoTooltip';
import Markdown from '@/react/common/Markdown';
import ModalIcon from '@/react/common/ModalIcon';
import string from '@/utils/string';

const FORM_STEPS = {
  FORM: 'form',
  REGISTER: 'register',
} as const;

type InitialState = {
  step: (typeof FORM_STEPS)[keyof typeof FORM_STEPS];
  displayName: string;
  tempData: Map<string, any>;
  error: string | null;
  isLoadingGuide: boolean;
  guide: string | null;
};

const INITIAL_STATE: InitialState = {
  step: FORM_STEPS.FORM,
  displayName: '',
  tempData: Map(),
  error: null,
  isLoadingGuide: false,
  guide: null,
};

const ICONS_MAP = Map({
  [backends.SNOWFLAKE]: backendImages.snowflake,
  [backends.TERADATA]: backendImages.teradata,
  [backends.BIGQUERY]: backendImages.bigquery,
});

const HAS_GUIDE: Backend[] = [backends.SNOWFLAKE, backends.BIGQUERY];

type Props = {
  openModal: boolean;
  onHide: () => void;
  isSaving: boolean;
  backend: Backend;
  buckets: Map<string, any>;
  hasSnowflakeRegisterExternalSecureDataShare: boolean;
};

const RegisterExternalBucketModal = (props: Props) => {
  const [state, setState] = useState<InitialState>(INITIAL_STATE);

  const renderBody = () => {
    if (state.step === FORM_STEPS.FORM) {
      return renderForm();
    }

    return renderRegister();
  };

  const renderForm = () => {
    return (
      <>
        <Modal.Body>
          {renderError()}
          <p>Connect your dataset to a bucket in Keboola.</p>
          {renderFormFields()}
        </Modal.Body>
        <Modal.Footer>
          {HAS_GUIDE.includes(props.backend) ? renderNextButton() : renderSubmitButton()}
        </Modal.Footer>
      </>
    );
  };

  const renderRegister = () => {
    return (
      <>
        <Modal.Body>
          {renderError()}
          <Markdown collapsible={false} source={state.guide} className="tw-mb-3" />
          {renderAdditionalRegisterFields()}
        </Modal.Body>
        <Modal.Footer>
          <ButtonGroup variant="block">
            <IconButton
              variant="outline"
              onClick={() => setState((prevState) => ({ ...prevState, step: FORM_STEPS.FORM }))}
              icon="arrow-left"
            />
            {renderSubmitButton()}
          </ButtonGroup>
        </Modal.Footer>
      </>
    );
  };

  const renderFormFields = () => {
    return (
      <>
        <FormGroup>
          <ControlLabel>Name</ControlLabel>
          <FormControl
            autoFocus
            type="text"
            value={state.displayName}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setState((prevState) => ({
                ...prevState,
                displayName: string.sanitizeKbcTableIdString(event.target.value),
              }));
            }}
          />
          <HelpBlock className="tw-mt-1">{nameWarning}</HelpBlock>
        </FormGroup>
        {renderAdditionalFields()}
      </>
    );
  };

  const renderAdditionalFields = () => {
    switch (props.backend) {
      case backends.BIGQUERY:
        return renderSimpleInput('Dataset', 'dataset');

      case backends.SNOWFLAKE:
        return (
          <>
            {renderSimpleInput('Database', 'database')}
            {renderSimpleInput('Schema', 'schema')}
            {props.hasSnowflakeRegisterExternalSecureDataShare && (
              <FormGroup className="tw-flex tw-items-center">
                <Checkbox
                  checked={state.tempData.get('isSnowflakeSharedDatabase', false)}
                  onChange={(checked) => {
                    setState((prevState) => ({
                      ...prevState,
                      tempData: state.tempData.set('isSnowflakeSharedDatabase', checked),
                    }));
                  }}
                >
                  Secure Data Sharing
                </Checkbox>
                <InfoTooltip
                  tooltip={
                    <p>
                      Enable this if you&apos;re registering data shared with you via Secure Data
                      Sharing. This lets Keboola access the shared data directly without copying it.
                      Ensure you have the necessary permissions from the data provider before
                      enabling. Read more <Link href={SNOWFLAKE_DOCS}>here</Link>.
                    </p>
                  }
                />
              </FormGroup>
            )}
          </>
        );

      case backends.TERADATA:
        return renderSimpleInput('Database', 'database');

      default:
        return null;
    }
  };

  const renderAdditionalRegisterFields = () => {
    switch (props.backend) {
      case backends.BIGQUERY:
        return renderSimpleInput('URL', 'url');

      default:
        return null;
    }
  };

  const renderSimpleInput = (label: string, name: string) => {
    return (
      <FormGroup>
        <ControlLabel>{label}</ControlLabel>
        <FormControl
          type="text"
          value={state.tempData.get(name, '')}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setState((prevState) => ({
              ...prevState,
              tempData: state.tempData.set(name, event.target.value),
            }))
          }
        />
      </FormGroup>
    );
  };

  const renderError = () => {
    if (!state.error) {
      return null;
    }

    return (
      <Alert variant="error" className="tw-mb-5">
        {state.error}
      </Alert>
    );
  };

  const renderSubmitButton = () => {
    return (
      <ConfirmButtons
        block
        isSaving={props.isSaving}
        isDisabled={isSubmitDisabled()}
        onSave={onSubmit}
        saveLabel={props.isSaving ? 'Registering...' : 'Register Dataset'}
      />
    );
  };

  const renderNextButton = () => {
    return (
      <Button
        block
        onClick={() => {
          setState((prevState) => ({
            ...prevState,
            error: null,
          }));
          loadGuide().then(() => {
            setState((prevState) => ({
              ...prevState,
              step: FORM_STEPS.REGISTER,
            }));
          });
        }}
        disabled={isNextStepDisabled() || state.isLoadingGuide}
      >
        {state.isLoadingGuide ? 'Loading guide...' : 'Next Step'}
      </Button>
    );
  };

  const onHide = () => {
    props.onHide();
    setState(INITIAL_STATE);
  };

  const onSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    return registerExternalBucket(
      props.backend,
      state.displayName,
      prepareRegisterPath(),
      props.backend === backends.SNOWFLAKE
        ? state.tempData.get('isSnowflakeSharedDatabase', false)
        : void 0,
    ).then(onHide, (message) => setState((prevState) => ({ ...prevState, error: message })));
  };

  const loadGuide = () => {
    setState((prevState) => ({ ...prevState, isLoadingGuide: true }));
    return StorageApi.getBucketRegistrationGuide({
      backend: props.backend,
      path: prepareGuidePath(),
      isSnowflakeSharedDatabase:
        props.backend === backends.SNOWFLAKE
          ? state.tempData.get('isSnowflakeSharedDatabase', false)
          : void 0,
    })
      .then(
        (response) => setState((prevState) => ({ ...prevState, guide: response.markdown })),
        (error) => setState((prevState) => ({ ...prevState, error: error?.message ?? error })),
      )
      .finally(() => setState((prevState) => ({ ...prevState, isLoadingGuide: false })));
  };

  const prepareRegisterPath = () => {
    switch (props.backend) {
      case backends.BIGQUERY:
        return parseBigQueryDatasetUrl(state.tempData.get('url', ''));

      case backends.SNOWFLAKE:
        return [state.tempData.get('database'), state.tempData.get('schema')];

      case backends.TERADATA:
        return [state.tempData.get('database')];

      default:
        return [];
    }
  };

  const prepareGuidePath = () => {
    switch (props.backend) {
      case backends.BIGQUERY:
        return [state.tempData.get('dataset')];

      case backends.SNOWFLAKE:
        return [state.tempData.get('database'), state.tempData.get('schema')];

      default:
        return [];
    }
  };

  const isSubmitDisabled = () => {
    switch (props.backend) {
      case backends.BIGQUERY:
        return !state.tempData.get('url');

      default:
        return false;
    }
  };

  const isNextStepDisabled = () => {
    if (!state.displayName || props.isSaving) {
      return true;
    }

    switch (props.backend) {
      case backends.BIGQUERY:
        return !state.tempData.get('dataset');

      case backends.SNOWFLAKE:
        return !state.tempData.get('database') || !state.tempData.get('schema');

      case backends.TERADATA:
        return !state.tempData.get('database');

      default:
        return false;
    }
  };

  return (
    <Modal show={props.openModal} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Register Your {string.capitalize(props.backend)} Dataset</Modal.Title>
        {ICONS_MAP.has(props.backend) && <ModalIcon iconUrl={ICONS_MAP.get(props.backend)} />}
      </Modal.Header>
      {renderBody()}
    </Modal>
  );
};

export default RegisterExternalBucketModal;
