import { Map } from 'immutable';
import _ from 'underscore';

const clearProcessorsTagOverride = (processors) => {
  return processors?.map((processor) => processor.deleteIn(['definition', 'tag']));
};

const cleanDataTypeSupportFromStorage = (configuration) => {
  return configuration.withMutations((config) => {
    config.deleteIn(['storage', 'output', 'data_type_support']);

    if (config.getIn(['storage', 'output'], Map()).isEmpty()) {
      config.deleteIn(['storage', 'output']);
    }

    if (config.get('storage', Map()).isEmpty()) {
      config.delete('storage');
    }
  });
};

const cleanDataBeforeCheck = (configuration) => {
  return configuration
    .delete('authorization')
    .delete('runtime')
    .delete('variables_id')
    .delete('variables_values_id')
    .updateIn(['processors', 'before'], clearProcessorsTagOverride)
    .updateIn(['processors', 'after'], clearProcessorsTagOverride)
    .update(cleanDataTypeSupportFromStorage);
};

const isParsableConfiguration = (configuration, parseFunction, createFunction) => {
  const configData = cleanDataBeforeCheck(configuration);

  if (configData.isEmpty()) {
    return true;
  }

  return _.isEqual(
    configData.toJS(),
    cleanDataBeforeCheck(createFunction(parseFunction(configuration))).toJS(),
  );
};

export default isParsableConfiguration;
