import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { List, Map } from 'immutable';

import { Alert, Button, FormGroup, Icon, Label, Tooltip } from '@keboola/design';

import keyCodes from '@/constants/keyCodes';
import { canLinkBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { nameWarning } from '@/modules/storage/constants';
import { validateBucketName } from '@/modules/storage/helpers';
import { isValidName } from '@/react/common/helpers';
import InputValidation from '@/react/common/InputValidation';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import { DEFAULT_BUCKET_COLOR } from '@/react/constants';

const LinkButton = ({ buckets, bucket, token, size, withIcon }) => {
  const [displayName, setDisplayName] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [creatingBucket, setCreatingBucket] = useState(false);

  const openModal = useCallback(() => {
    setDisplayName(bucket.get('displayName'));
    setShowModal(true);
  }, [bucket]);

  const closeModal = useCallback(() => {
    setShowModal(false);
    setError(null);
    setDisplayName('');
  }, []);

  const handleDisplayName = useCallback(
    (value) => {
      setDisplayName(value);
      setWarning(validateBucketName(value, 'in', buckets, DevBranchesStore.getCurrentId()));
    },
    [buckets],
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (creatingBucket) return;

      const newBucket = {
        stage: 'in',
        displayName,
        name: displayName,
        sourceProjectId: bucket.getIn(['project', 'id']),
        sourceBucketId: bucket.get('id'),
      };

      setCreatingBucket(true);
      StorageActionCreators.createBucket(newBucket, { async: true })
        .then((response) => StorageActionCreators.waitForFinishedStorageJob(response))
        .then(() => closeModal())
        .catch((error) => setError(error))
        .finally(() => setCreatingBucket(false));
    },
    [bucket, displayName, creatingBucket, closeModal],
  );

  if (!canLinkBucket(token)) {
    return (
      <Tooltip
        tooltip="You don't have permission to link this bucket."
        placement="top"
        type="explanatory"
        triggerClassName="tw-flex tw-shrink-0"
      >
        <Button size={size} disabled variant="secondary" className="tw-min-w-[84px]">
          {withIcon && <Icon icon="plus" />}
          Link
        </Button>
      </Tooltip>
    );
  }

  return (
    <>
      <Button
        size={size}
        variant="secondary"
        onClick={openModal}
        className="tw-flex tw-min-w-[84px] tw-shrink-0"
        disabled={creatingBucket}
      >
        {withIcon && !creatingBucket && <Icon icon="plus" />}
        {creatingBucket ? <Loader /> : 'Link'}
      </Button>

      <span
        className="tw-hidden"
        onClick={(e) => e.stopPropagation()}
        onKeyDown={(e) => e.key === keyCodes.ENTER && e.stopPropagation()}
      >
        <Modal show={showModal} onHide={closeModal}>
          <form onSubmit={onSubmit}>
            <Modal.Header closeButton>
              <ModalIcon
                icon="folder"
                backgroundColor={bucket.get('color') ?? DEFAULT_BUCKET_COLOR}
              />
              <Modal.Title>
                {bucket.get('displayName')}{' '}
                <span className="text-muted font-normal">
                  by {bucket.getIn(['project', 'name'])}
                </span>
              </Modal.Title>
              {bucket.get('description') && (
                <div className="text-muted mt-1">{bucket.get('description')}</div>
              )}
            </Modal.Header>
            <Modal.Body>
              {error && (
                <Alert variant="error" className="tw-mb-5">
                  {error}
                </Alert>
              )}
              <InputValidation predefined="bucketName" value={displayName}>
                {(inputState) => {
                  const validationState = !!warning || inputState === 'error' ? 'error' : 'default';
                  const messages = {
                    default: `The bucket will be linked to an IN stage. ${nameWarning}`,
                    error: warning,
                  };

                  return (
                    <FormGroup state={validationState}>
                      <Label htmlFor="name">
                        Select a name how the bucket will appear in your project
                      </Label>
                      <div className="tw-flex tw-gap-1">
                        <FormGroup.TextInput
                          id="name"
                          className="tw-grow"
                          autoFocus
                          variant="secondary"
                          value={displayName}
                          onChange={handleDisplayName}
                          disabled={creatingBucket}
                        />
                        <Button
                          className="tw-min-w-fit"
                          type="submit"
                          onClick={onSubmit}
                          disabled={
                            creatingBucket || !displayName || !isValidName(displayName) || warning
                          }
                        >
                          {creatingBucket ? <Loader /> : <Icon icon="circle-check" />}
                          Link
                        </Button>
                      </div>
                      <FormGroup.Help>{messages[validationState]}</FormGroup.Help>
                    </FormGroup>
                  );
                }}
              </InputValidation>
              {bucket.get('tables', List()).count() > 0 && (
                <div className="well">
                  <div className="well-title">Tables</div>
                  {bucket.get('tables').map((table, index) => (
                    <div className="flex-container flex-start" key={index}>
                      <Icon icon="table" className="icon-addon-right text-muted" />
                      {table.get('displayName')}
                    </div>
                  ))}
                </div>
              )}
            </Modal.Body>
          </form>
        </Modal>
      </span>
    </>
  );
};

LinkButton.propTypes = {
  buckets: PropTypes.instanceOf(Map).isRequired,
  bucket: PropTypes.instanceOf(Map).isRequired,
  token: PropTypes.instanceOf(Map).isRequired,
  size: PropTypes.string,
  withIcon: PropTypes.bool,
};

export default LinkButton;
