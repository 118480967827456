import qs from 'qs';

import { KEBOOLA_LEGACY_TRANSFORMATION, TRANSFORMATION } from '@/constants/componentIds';
import { SERVICE_ENCRYPTION } from '@/constants/serviceIds';
import TransformationBucketsStore2 from '@/modules/legacy-transformation/stores/TransformationBucketsStore';
import ServicesStore from '@/modules/services/Store';
import TransformationBucketsStore from '@/modules/transformations/stores/TransformationBucketsStore';
import ApplicationStore from '@/stores/ApplicationStore';
import request from '@/utils/request';
import InstalledComponentsStore from './stores/InstalledComponentsStore';
import { createRequest } from './StorageApi';

const installedComponentsApi = {
  getComponentConfiguration(componentId, configId) {
    return createRequest('GET', `components/${componentId}/configs/${configId}`)
      .promise()
      .then((response) => response.body);
  },

  getProductionComponentConfiguration(componentId, configId) {
    return createRequest('GET', `components/${componentId}/configs/${configId}`, {
      forceProduction: true,
    })
      .promise()
      .then((response) => response.body);
  },

  getComponents(params, options) {
    return createRequest('GET', 'components', options)
      .query(params)
      .promise()
      .then((response) => response.body);
  },

  getProductionComponents() {
    return createRequest('GET', 'components', { forceProduction: true })
      .promise()
      .then((response) => response.body);
  },

  getDeletedComponents() {
    return createRequest('GET', 'components')
      .query({ include: 'configuration', isDeleted: true })
      .promise()
      .then((response) => response.body);
  },

  getProductionDeletedComponents() {
    return createRequest('GET', 'components', { forceProduction: true })
      .query({ isDeleted: true })
      .promise()
      .then((response) => response.body);
  },

  getComponentConfigurations(componentId) {
    return createRequest('GET', `components/${componentId}/configs`)
      .promise()
      .then((response) => response.body);
  },

  getProductionComponentConfigurations(componentId) {
    return createRequest('GET', `components/${componentId}/configs`, { forceProduction: true })
      .promise()
      .then((response) => response.body);
  },

  getDeletedComponentConfigurations(componentId) {
    return createRequest('GET', `components/${componentId}/configs`)
      .query({ isDeleted: true })
      .promise()
      .then((response) => response.body);
  },

  searchComponents(params, options) {
    return createRequest('GET', `search/component-configurations`, options)
      .query(qs.stringify(params))
      .promise()
      .then((response) => response.body);
  },

  saveConfigurationMetadata(componentId, configId, metadata) {
    return createRequest('POST', `components/${componentId}/configs/${configId}/metadata`)
      .send({ metadata })
      .promise()
      .then((response) => response.body);
  },

  saveProductionConfigurationMetadata(componentId, configId, metadata) {
    return createRequest('POST', `components/${componentId}/configs/${configId}/metadata`, {
      forceProduction: true,
    })
      .send({ metadata })
      .promise()
      .then((response) => response.body);
  },

  deleteConfigurationMetadata(componentId, configId, id) {
    return createRequest('DELETE', `components/${componentId}/configs/${configId}/metadata/${id}`)
      .promise()
      .then((response) => response.body);
  },

  updateComponentConfiguration(componentId, configurationId, data, changeDescription) {
    if (changeDescription) {
      data.changeDescription = changeDescription;
    }
    return createRequest('PUT', `components/${componentId}/configs/${configurationId}`)
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  updateProductionComponentConfiguration(componentId, configurationId, data) {
    return createRequest('PUT', `components/${componentId}/configs/${configurationId}`, {
      forceProduction: true,
    })
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  encryptConfiguration(componentId, data) {
    return request('POST', `${ServicesStore.getServiceUrl(SERVICE_ENCRYPTION)}/encrypt`)
      .set('Content-Type', 'application/json')
      .query({ projectId: ApplicationStore.getCurrentProjectId(), componentId })
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  createConfiguration(componentId, data, changeDescription) {
    if (changeDescription) {
      data.changeDescription = changeDescription;
    }
    return createRequest('POST', `components/${componentId}/configs`)
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  createProductionConfiguration(componentId, data) {
    return createRequest('POST', `components/${componentId}/configs`, { forceProduction: true })
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  deleteConfiguration(componentId, configurationId) {
    return createRequest('DELETE', `components/${componentId}/configs/${configurationId}`)
      .promise()
      .then((response) => response.body);
  },

  deleteProductionConfiguration(componentId, configurationId) {
    return createRequest('DELETE', `components/${componentId}/configs/${configurationId}`, {
      forceProduction: true,
    })
      .promise()
      .then((response) => response.body);
  },

  restoreConfiguration(componentId, configurationId) {
    return createRequest('POST', `components/${componentId}/configs/${configurationId}/restore`)
      .promise()
      .then((response) => response.body);
  },

  resetDevConfigurationToProductionVersion(componentId, configurationId) {
    return createRequest(
      'POST',
      `components/${componentId}/configs/${configurationId}/reset-to-default`,
    )
      .promise()
      .then((response) => response.body);
  },

  getComponentConfigVersions(componentId, configId) {
    return createRequest('GET', `components/${componentId}/configs/${configId}/versions`)
      .promise()
      .then((response) => response.body);
  },

  getComponentConfigRowVersions(componentId, configId, rowId) {
    return createRequest(
      'GET',
      `components/${componentId}/configs/${configId}/rows/${rowId}/versions`,
    )
      .promise()
      .then((response) => response.body);
  },

  getComponentConfigByVersion(componentId, configId, versionId) {
    return createRequest(
      'GET',
      `components/${componentId}/configs/${configId}/versions/${versionId}`,
    )
      .promise()
      .then((response) => response.body);
  },

  getComponentConfigRowByVersion(componentId, configId, rowId, versionId) {
    return createRequest(
      'GET',
      `components/${componentId}/configs/${configId}/rows/${rowId}/versions/${versionId}`,
    )
      .promise()
      .then((response) => response.body);
  },

  rollbackVersion(componentId, configId, version) {
    return createRequest(
      'POST',
      `components/${componentId}/configs/${configId}/versions/${version}/rollback`,
    )
      .promise()
      .then((response) => response.body);
  },

  rollbackRowVersion(componentId, configId, rowId, version) {
    return createRequest(
      'POST',
      `components/${componentId}/configs/${configId}/rows/${rowId}/versions/${version}/rollback`,
    )
      .promise()
      .then((response) => response.body);
  },

  createConfigCopy(componentId, configId, version, name) {
    var config, description;
    if (componentId === TRANSFORMATION) {
      config = TransformationBucketsStore.get(configId);
    } else if (componentId === KEBOOLA_LEGACY_TRANSFORMATION) {
      config = TransformationBucketsStore2.get(configId);
    } else {
      config = InstalledComponentsStore.getConfig(componentId, configId);
    }
    description = `Created from ${config.get('name')} version #${version}`;
    if (config.get('description')) {
      description += `\n\n${config.get('description')}`;
    }
    const data = {
      name: name,
      description: description,
    };
    return createRequest(
      'POST',
      `components/${componentId}/configs/${configId}/versions/${version}/create`,
    )
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  createConfigRowCopy(componentId, configId, rowId, version) {
    return createRequest(
      'POST',
      `components/${componentId}/configs/${configId}/rows/${rowId}/versions/${version}/create`,
    )
      .promise()
      .then((response) => response.body);
  },

  createConfigurationRow(componentId, configurationId, data, changeDescription) {
    if (changeDescription) {
      data.changeDescription = changeDescription;
    }
    return createRequest('POST', `components/${componentId}/configs/${configurationId}/rows`)
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  createProductionConfigurationRow(componentId, configurationId, data) {
    return createRequest('POST', `components/${componentId}/configs/${configurationId}/rows`, {
      forceProduction: true,
    })
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  getConfigurationRow(componentId, configurationId, rowId) {
    return createRequest(
      'GET',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
    )
      .send()
      .promise()
      .then((response) => response.body);
  },

  getProductionConfigurationRow(componentId, configurationId, rowId) {
    return createRequest(
      'GET',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
      { forceProduction: true },
    )
      .send()
      .promise()
      .then((response) => response.body);
  },

  deleteConfigurationRow(componentId, configurationId, rowId, changeDescription) {
    return createRequest(
      'DELETE',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
    )
      .send({ changeDescription: changeDescription })
      .promise()
      .then((response) => response.body);
  },

  deleteProductionConfigurationRow(componentId, configurationId, rowId, changeDescription) {
    return createRequest(
      'DELETE',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
      { forceProduction: true },
    )
      .send({ changeDescription: changeDescription })
      .promise()
      .then((response) => response.body);
  },

  updateConfigurationRow(componentId, configurationId, rowId, data, changeDescription) {
    if (changeDescription) {
      data.changeDescription = changeDescription;
    }
    return createRequest(
      'PUT',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
    )
      .send(data)
      .promise()
      .then((response) => response.body);
  },

  updateConfigurationState(componentId, configurationId, state) {
    return createRequest('PUT', `components/${componentId}/configs/${configurationId}/state`)
      .send(state)
      .promise()
      .then((response) => response.body);
  },

  updateConfigurationRowState(componentId, configurationId, rowId, state) {
    return createRequest(
      'PUT',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}/state`,
    )
      .send(state)
      .promise()
      .then((response) => response.body);
  },

  updateProductionConfigurationRow(componentId, configurationId, rowId, data) {
    return createRequest(
      'PUT',
      `components/${componentId}/configs/${configurationId}/rows/${rowId}`,
      { forceProduction: true },
    )
      .send(data)
      .promise()
      .then((response) => response.body);
  },
};

export default installedComponentsApi;
