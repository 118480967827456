import { ButtonLink, Icon } from '@keboola/design';

import { canCreateToken } from '@/modules/admin/privileges';
import { routeNames } from '@/modules/settings/constants';
import { RouterLink } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';

export const NewTokenHeaderButton = () => {
  const store = useStores(
    () => {
      return {
        sapiToken: ApplicationStore.getSapiToken(),
      };
    },
    [],
    [ApplicationStore],
  );

  if (!canCreateToken(store.sapiToken)) {
    return null;
  }

  return (
    <ButtonLink>
      <RouterLink to={routeNames.NEW_TOKEN}>
        <Icon icon="plus" />
        New Token
      </RouterLink>
    </ButtonLink>
  );
};
