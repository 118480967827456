import { createPath } from '@/utils/router/createPath';
import { getBaseName } from '@/utils/router/getBaseName';
import type { Query } from '@/utils/router/getQueryString';

export const createUrl = (
  to: string,
  params?: Record<string, any> | null,
  query?: Query,
  hash?: string,
): string => {
  const path = createPath(to, params, query, hash);
  const hashPart = path.hash ? '#' + encodeURIComponent(path.hash) : '';

  return `${path.pathname}${path.search}${hashPart}`;
};

export const createUrlWithBasename = (
  to: string,
  params?: Record<string, any> | null,
  query?: Query,
  hash?: string,
): string => `${getBaseName()}${createUrl(to, params, query, hash)}`;
