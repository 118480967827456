import { useState } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { getClipboardVariable } from '@/modules/components/react/components/generic/variables/helpers.js';
import VariableForm from '@/modules/components/react/components/generic/variables/VariableForm';
import VariablesBox from '@/modules/components/react/components/generic/variables/VariablesBox';
import Filled from '@/react/common/KeyValueBox/Filled';

const DefineVariables = ({ readOnly, variables, onChange }) => {
  const [isAddingNewVariable, setIsAddingNewVariable] = useState(false);
  const [newVariable, setNewVariable] = useState(null);
  const [editingVariables, setEditingVariables] = useState(Map());
  const [savingVariables, setSavingVariables] = useState(Map());
  const [deletingVariables, setDeletingVariables] = useState(Map());

  return (
    <VariablesBox
      isReadOnly={readOnly}
      isDisabled={isAddingNewVariable}
      onAddNew={() => setIsAddingNewVariable(true)}
    >
      {(isAddingNewVariable || !variables.isEmpty()) && (
        <>
          {variables.map((variable, index) => {
            const variableName = variable.get('name');
            if (variableName === newVariable) return null;
            if (editingVariables.has(variableName)) {
              return (
                <VariableForm
                  key={`edit-${variableName}-${index}`}
                  saveVariableFn={(name) => {
                    setSavingVariables(savingVariables.set(variableName, true));
                    return onChange(variables.set(index, Map({ name }))).then(() =>
                      setSavingVariables(savingVariables.delete(variableName)),
                    );
                  }}
                  resetVariableFn={() => setEditingVariables(editingVariables.delete(variableName))}
                  variableName={variable.get('name')}
                  variableValue={variable.get('value')}
                  isSaving={savingVariables.has(variableName)}
                  variables={variables}
                  nameOnly
                />
              );
            }
            return (
              <Filled
                key={`view-${variableName}-${index}`}
                readOnly={readOnly}
                name={variable.get('name')}
                value={variable.get('value')}
                deleteVariableFn={() => {
                  setDeletingVariables(deletingVariables.set(variableName, true));
                  return onChange(variables.delete(index)).then(() =>
                    setDeletingVariables(deletingVariables.delete(variableName)),
                  );
                }}
                startEditingFn={() => setEditingVariables(editingVariables.set(variableName, true))}
                isDeleting={deletingVariables.has(variableName)}
                clipboardText={getClipboardVariable(variable.get('name'))}
                nameOnly
                entity="variable"
              />
            );
          })}
          {isAddingNewVariable && (
            <VariableForm
              saveVariableFn={(name) => {
                setNewVariable(name);
                return onChange(variables.push(Map({ name }))).then(() => setNewVariable(null));
              }}
              resetVariableFn={() => setIsAddingNewVariable(false)}
              isSaving={!!newVariable}
              variables={variables}
              nameOnly
            />
          )}
        </>
      )}
    </VariablesBox>
  );
};

DefineVariables.propTypes = {
  readOnly: PropTypes.bool,
  variables: PropTypes.instanceOf(List).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DefineVariables;
