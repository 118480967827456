import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import { type List, Map } from 'immutable';

import { ButtonInline, Icon, IconButton, Tooltip } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import Editor from './FileOutputMappingEditor';

const FileOutputMappingModal = (props: {
  onSave: (mapping: Map<string, any>) => Promise<any>;
  definedSources: List<string>;
  mapping?: Map<string, any>;
}) => {
  const [show, setShow] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [editing, setEditing] = useState<Map<string, any>>(Map());
  const isEdit = !!props.mapping;

  const handleSave = () => {
    setIsSaving(true);
    props
      .onSave(editing)
      .then(() => setShow(false))
      .finally(() => setIsSaving(false));
  };

  const isValid = () => {
    return !!editing.get('source') && !props.definedSources.includes(editing.get('source'));
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      {isEdit ? (
        <Tooltip placement="top" tooltip="Edit Output">
          <IconButton variant="invisible" icon="pen" onClick={() => setShow(true)} />
        </Tooltip>
      ) : (
        <ButtonInline
          variant="link-reverse"
          color="custom"
          className="tw-px-4 tw-py-3 tw-text-primary-500"
          onClick={() => setShow(true)}
        >
          <Icon icon="plus" />
          New File Output
        </ButtonInline>
      )}
      <Modal
        bsSize="large"
        show={show}
        onHide={() => setShow(false)}
        onEnter={() => setEditing(props.mapping || Map())}
      >
        <Modal.Header closeButton>
          <Modal.Title>Output Mapping</Modal.Title>
          {isEdit ? <ModalIcon.Edit /> : <ModalIcon.Plus />}
        </Modal.Header>
        <Modal.Body>
          <Editor
            value={editing}
            disabled={isSaving}
            onChange={setEditing}
            isSourceDuplicate={props.definedSources.includes(editing.get('source', ''))}
          />
        </Modal.Body>
        <Modal.Footer>
          <ConfirmButtons
            block
            saveLabel={isEdit ? 'Save File Output' : 'Add File Output'}
            onSave={handleSave}
            isSaving={isSaving}
            isDisabled={!isValid()}
          />
        </Modal.Footer>
      </Modal>
    </span>
  );
};

export default FileOutputMappingModal;
