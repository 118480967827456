import {
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_BIGQUERY,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_MSSQL,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_POSTGRESQL,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_REDSHIFT,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_SNOWFLAKE,
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_TEST_DBT_TRANSFORMATION,
} from '@/constants/componentIds';

const routeNames = {
  ROOT: 'transformations-v2',
  SHARED_CODES: 'shared-codes',
  SHARED_CODE: 'shared-code',
  SHARED_CODE_VERSIONS: 'shared-code-versions',
  GENERIC_TRANSFORMATION_CONFIG: 'generic-transformation-config',
  GENERIC_TRANSFORMATION_VERSIONS: 'generic-transformation-versions',
  GENERIC_TRANSFORMATION_NOTIFICATIONS: 'generic-transformation-notifications',
  GENERIC_TRANSFORMATION_CONFIG_DISCOVERY: 'generic-transformation-discovery',
};

const DBT_DEFAULT_THREADS = 4;

const DBT_REMOTE_TRANSFORMATIONS = [
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_MSSQL,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_BIGQUERY,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_POSTGRESQL,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_REDSHIFT,
  KEBOOLA_DBT_TRANSFORMATION_REMOTE_SNOWFLAKE,
];

const SNOWFLAKE_BACKEND_TRANSFORMATIONS = [
  KEBOOLA_SNOWFLAKE_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION,
];

const DBT_COMPONENTS = [
  KEBOOLA_DBT_TRANSFORMATION,
  KEBOOLA_TEST_DBT_TRANSFORMATION,
  KEBOOLA_DBT_TRANSFORMATION_LOCAL_BIGQUERY,
  ...DBT_REMOTE_TRANSFORMATIONS,
];

const DBT_CONNECTION_INPUTS = {
  [KEBOOLA_DBT_TRANSFORMATION_REMOTE_MSSQL]: {
    server: {
      label: 'Host',
      placeholder: 'URL',
    },
    port: {
      label: 'Port',
      type: 'number',
    },
    user: {
      label: 'User',
    },
    '#password': {
      label: 'Password',
      type: 'password',
    },
    schema: {
      label: 'Schema',
    },
    database: {
      label: 'Database',
    },
  },
  [KEBOOLA_DBT_TRANSFORMATION_REMOTE_BIGQUERY]: {
    project: {
      label: 'Project',
    },
    dataset: {
      label: 'Database',
    },
    '#key_content': {
      label: 'Key content',
      type: 'password',
    },
    location: {
      label: 'Location',
      optional: true,
    },
  },
  [KEBOOLA_DBT_TRANSFORMATION_REMOTE_POSTGRESQL]: {
    host: {
      label: 'Host',
      placeholder: 'URL',
    },
    port: {
      label: 'Port',
      type: 'number',
    },
    user: {
      label: 'User',
    },
    '#password': {
      label: 'Password',
      type: 'password',
    },
    dbname: {
      label: 'Database',
    },
    schema: {
      label: 'Schema',
    },
  },
  [KEBOOLA_DBT_TRANSFORMATION_REMOTE_REDSHIFT]: {
    host: {
      label: 'Host',
      placeholder: 'URL',
    },
    port: {
      label: 'Port',
      type: 'number',
    },
    user: {
      label: 'User',
    },
    '#password': {
      label: 'Password',
      type: 'password',
    },
    dbname: {
      label: 'Database',
    },
    schema: {
      label: 'Schema',
    },
  },
  [KEBOOLA_DBT_TRANSFORMATION_REMOTE_SNOWFLAKE]: {
    host: {
      label: 'Host',
      placeholder: 'URL',
    },
    warehouse: {
      label: 'Warehouse',
    },
    database: {
      label: 'Database',
    },
    schema: {
      label: 'Schema',
    },
    user: {
      label: 'User',
    },
    '#password': {
      label: 'Password',
      type: 'password',
    },
  },
};

export {
  routeNames,
  SNOWFLAKE_BACKEND_TRANSFORMATIONS,
  DBT_COMPONENTS,
  DBT_REMOTE_TRANSFORMATIONS,
  DBT_CONNECTION_INPUTS,
  DBT_DEFAULT_THREADS,
};
