import Promise, { CancellationError } from 'bluebird';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import RouterActionCreators from '@/actions/RouterActionCreators';
import { APP_ROUTE } from '@/constants/routeNames';
import { switchedAnyBranch } from '@/modules/dev-branches/helpers';
import { hasLegacyOrchestrationInvalidToken } from '@/modules/orchestrations/helpers';
import RoutesStore from '@/stores/RoutesStore';
import { HTTP_STATUS_CODE_BAD_GATEWAY, HTTP_STATUS_CODE_NOT_FOUND } from '@/utils/errors/helpers';
import nextTick from '@/utils/nextTick';
import type { RawRouterState } from '@/utils/router/rawRouterStateStore';
import Timer from '@/utils/Timer';

let pendingPoller: (() => Promise<any>) | null = null;
let pendingPromise: Promise<any[]> | null = null;

const registerPollers = (state: RawRouterState) => {
  const poller = RoutesStore.getPollerForLastRoute(state.routes);

  if (poller) {
    const cb = () => {
      if (poller.has('skip') && poller.get('skip')(state.params, state.location.query)) {
        return Promise.resolve();
      }

      ApplicationActionCreators.showPollLoading();
      return Promise.resolve(poller.get('action')(state.params, state.location.query))
        .catch((error) => {
          // Ignore bad gateway errors during background polling
          if (error?.response?.status === HTTP_STATUS_CODE_BAD_GATEWAY) {
            return;
          }

          throw error;
        })
        .finally(() => ApplicationActionCreators.hidePollLoading());
    };

    Timer.poll(cb, { interval: poller.get('interval'), skipFirst: poller.get('skipFirst') });
    pendingPoller = cb;
  }
};

const stopRegisteredPollers = () => {
  if (pendingPoller) {
    Timer.stop(pendingPoller);
    pendingPoller = null;
  }
};

const cancelPendingPromises = () => {
  if (pendingPromise) {
    pendingPromise.cancel();
  }
};

export const loadRequiredData = (state: RawRouterState) => {
  cancelPendingPromises();
  stopRegisteredPollers();

  const promises = RoutesStore.getRequireDataFunctionsForRouterState(state.routes)
    .map(
      (
        requireData: (
          params: Record<string, any>,
          query: Record<string, any>,
          state: RawRouterState,
        ) => any,
      ) => Promise.resolve(requireData(state.params, state.location.query, state)),
    )
    .toArray();

  pendingPromise = Promise.all(promises);

  return (
    pendingPromise
      // Space to update all stores based on loaded data before we update the router store
      .then(() => nextTick())
      .then(() => {
        registerPollers(state);
        return null;
      })
      .catch((e) => {
        // bluebird error, calling cancel on already cancelled  promise
        if (e instanceof CancellationError) {
          return null;
        }

        if (
          e?.response?.statusCode === HTTP_STATUS_CODE_NOT_FOUND ||
          hasLegacyOrchestrationInvalidToken(e)
        ) {
          if (switchedAnyBranch() && !state.location.state?.force) {
            RoutesStore.getRouter().transitionToForce(APP_ROUTE);
            return null;
          }

          RouterActionCreators.routeChangeError(e);
          return null;
        }

        throw e;
      })
  );
};
