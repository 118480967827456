import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

type RouteInfo = { path: string; name: string };

export type RawRouterState = {
  location: {
    pathname: string;
    search: string;
    query: Record<string, any>;
    state?: Record<string, any>;
    hash: string;
  };
  params: Record<string, any>;
  routes: RouteInfo[];
};

type RawRouterStateStore = {
  rawRouterState: RawRouterState | null;
  setRawRouterState: (state: RawRouterState) => void;
};

export const useRawRouterStateStore = create<RawRouterStateStore>()(
  devtools(
    (set) => ({
      rawRouterState: null,
      setRawRouterState: (state: RawRouterState) =>
        set(() => ({ rawRouterState: state }), void 0, 'setRawRouterState'),
    }),
    { name: 'Raw router state' },
  ),
);
