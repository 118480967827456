import { Component } from 'react';
import type { ComponentProps } from 'react';
import { List, type Map } from 'immutable';

import { Button, Icon, Tooltip } from '@keboola/design';

import { canUnlinkBucket } from '@/modules/admin/privileges';
import StorageActionCreators from '@/modules/components/StorageActionCreators';
import DeleteBucketModal from '@/modules/storage/components/DeleteBucketModal';
import Loader from '@/react/common/Loader';

type State = {
  showModal: boolean;
  mouseEnter: boolean;
  hasFocus: boolean;
  unlinkingBucket: boolean;
};

type Props = {
  token: Map<string, any>;
  bucket: Map<string, any>;
  size: ComponentProps<typeof Button>['size'];
  hideIcon: boolean;
};

class UnlinkButton extends Component<Props, State> {
  state: State = {
    showModal: false,
    mouseEnter: false,
    hasFocus: false,
    unlinkingBucket: false,
  };

  render() {
    if (!canUnlinkBucket(this.props.token, this.props.bucket)) {
      return (
        <Tooltip
          tooltip="You don't have permission to unlink this bucket."
          placement="top"
          type="explanatory"
        >
          <Button size={this.props.size} disabled>
            <Icon icon="circle-check" />
            Linked
          </Button>
        </Tooltip>
      );
    }

    const unlinkButton =
      this.state.mouseEnter ||
      this.state.hasFocus ||
      this.state.showModal ||
      this.state.unlinkingBucket;

    return (
      <>
        <Button
          size={this.props.size}
          variant={unlinkButton ? 'danger' : 'primary'}
          onClick={() => this.setState({ showModal: true })}
          onMouseEnter={() => this.setState({ mouseEnter: true })}
          onMouseLeave={() => this.setState({ mouseEnter: false })}
          onFocus={() => this.setState({ hasFocus: true })}
          onBlur={() => this.setState((state) => ({ hasFocus: state.showModal }))}
          className="tw-min-w-[84px]"
          disabled={this.state.unlinkingBucket}
        >
          {this.state.unlinkingBucket ? (
            <Loader />
          ) : unlinkButton ? (
            <>
              {!this.props.hideIcon && <Icon icon="trash" />}
              Unlink
            </>
          ) : (
            <>
              {!this.props.hideIcon && <Icon icon="circle-check" />}
              Linked
            </>
          )}
        </Button>
        <DeleteBucketModal
          show={this.state.showModal}
          bucket={this.props.bucket}
          tables={this.props.bucket.getIn(['sourceBucket', 'tables'], List())}
          deleting={this.state.unlinkingBucket}
          onConfirm={this.handleUnlink}
          onHide={() => {
            this.setState({ showModal: false, hasFocus: false, mouseEnter: false });
          }}
        />
      </>
    );
  }

  handleUnlink = () => {
    if (this.state.unlinkingBucket) {
      return;
    }

    this.setState({ unlinkingBucket: true });
    return StorageActionCreators.deleteBucket(this.props.bucket.get('id'))
      .then(() => {
        this.setState({ showModal: false });
      })
      .finally(() => {
        this.setState({ unlinkingBucket: false });
      });
  };
}

export default UnlinkButton;
