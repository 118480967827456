import type { ReactNode } from 'react';
import { useDraggable } from '@dnd-kit/react';

export const Draggable = ({
  id,
  disabled,
  children,
}: {
  id: string;
  disabled: boolean;
  children: ReactNode;
}) => {
  const { ref } = useDraggable({ id, disabled });

  return <div ref={ref}>{children}</div>;
};
