import { memo, useMemo } from 'react';
import CodeMirrorMerge from 'react-codemirror-merge';
import { highlightSelectionMatches } from '@codemirror/search';
import {
  EditorState,
  EditorView,
  type Extension,
  placeholder as cmPlaceholder,
} from '@uiw/react-codemirror';
import debounce from 'debounce';

import { BASIS_SETUP, customTheme } from './helpers';

export const DiffEditor = memo(
  ({
    value,
    onChange,
    commonExtensions,
    previousValue,
    placeholder,
    readOnly,
  }: {
    value: string;
    onChange: (value: string) => void;
    commonExtensions: Extension[];
    previousValue: string;
    placeholder: string;
    readOnly: boolean;
  }) => {
    const originalExtensions = useMemo(() => {
      return [
        ...commonExtensions,
        EditorView.editable.of(false),
        EditorState.readOnly.of(true),
        highlightSelectionMatches({ maxMatches: 0 }),
      ];
    }, [commonExtensions]);

    const modifiedExtensions = useMemo(() => {
      const debouncedOnChange = debounce((update) => {
        if (update.docChanged) {
          onChange(update.state.doc.toString());
        }
      }, 200);

      return [
        ...commonExtensions,
        cmPlaceholder(placeholder),
        EditorView.editable.of(!readOnly),
        EditorState.readOnly.of(readOnly),
        EditorView.updateListener.of(debouncedOnChange),
      ];
    }, [commonExtensions, placeholder, readOnly, onChange]);

    return (
      <CodeMirrorMerge theme={customTheme} highlightChanges={false} destroyRerender={false}>
        <CodeMirrorMerge.Original
          value={previousValue}
          extensions={originalExtensions}
          basicSetup={BASIS_SETUP}
        />
        <CodeMirrorMerge.Modified
          value={value}
          extensions={modifiedExtensions}
          basicSetup={BASIS_SETUP}
        />
      </CodeMirrorMerge>
    );
  },
);
