import type { ComponentProps, MouseEvent } from 'react';

import { IconButton } from '@keboola/design';

export const ActionButton = ({
  onClick,
  icon,
  size = 'small',
}: {
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  icon: ComponentProps<typeof IconButton>['icon'];
  size?: ComponentProps<typeof IconButton>['size'];
}) => {
  return (
    <IconButton
      size={size}
      variant="outline"
      className="tw-rounded-full"
      onClick={(e) => {
        e.stopPropagation();

        onClick(e);
      }}
      icon={icon}
    />
  );
};
