import type { ReactNode } from 'react';
import { List, Map } from 'immutable';

import { FormGroup, Label, TextInput } from '@keboola/design';

import {
  canManageBuckets,
  canManageTokenRestrictions,
  canManageTokens,
  canPurgeTrash,
} from '@/modules/admin/privileges';
import { routeNames as projectSettingsRouteNames } from '@/modules/settings/constants';
import { CreatedDate, RouterLink } from '@/react/common';
import Check from '@/react/common/Check';
import { BucketsSelector } from './BucketsSelector';
import { ComponentsSelector } from './ComponentsSelector';
import { ExpiresInEdit } from './ExpiresInEdit';

type Props = {
  disabled: boolean;
  isEditing: boolean;
  hasNewQueue: boolean;
  token: Map<string, any>;
  sapiToken: Map<string, any>;
  allBuckets: Map<string, any>;
  allComponents: Map<string, any>;
  updateToken: (token: Map<string, any>) => void;
};

const TokenEditor = (props: Props) => {
  const canSetupRestrictions = canManageTokenRestrictions(props.sapiToken);
  const isCustomAccess = canSetupRestrictions && !canManageBuckets(props.token);
  const isAdminToken = props.token.has('admin');

  const renderCustomExpires = () => {
    return props.isEditing ? (
      <div className="col-sm-9">
        <p className="form-control-static">
          <CreatedDate createdTime={props.token.get('expires')} fallback="Never" />
        </p>
      </div>
    ) : (
      <ExpiresInEdit
        disabled={props.disabled}
        value={props.token.get('expiresIn', null)}
        onChange={(value) => props.updateToken(props.token.set('expiresIn', value))}
      />
    );
  };

  const renderCreatorTokenLink = () => {
    const creatorToken = props.token.get('creatorToken', null);
    return (
      creatorToken && (
        <span>
          {' '}
          by{' '}
          <RouterLink
            to={projectSettingsRouteNames.TOKEN_DETAIL}
            params={{ tokenId: creatorToken.get('id') }}
          >
            {creatorToken.get('description')}
          </RouterLink>
        </span>
      )
    );
  };

  const renderFormGroup = (labelComponent: string, controlComponent: ReactNode) => {
    return (
      <FormGroup className="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <Label>{labelComponent}</Label>
        {controlComponent}
      </FormGroup>
    );
  };

  const renderFileUploadsAccessInput = () => {
    const isAdminToken = props.token.has('admin');
    const isFullAccess = props.token.get('canReadAllFileUploads', false);

    if (isAdminToken) {
      return (
        <div className="col-sm-9">
          <div className="radio">
            <span>Full access</span>
            <span className="help-block">Allow access to all files</span>
          </div>
        </div>
      );
    }
    return (
      <div className="col-sm-9">
        <div className="radio">
          <label htmlFor="fullAccess">
            <input
              id="fullAccess"
              disabled={props.disabled}
              type="radio"
              checked={isFullAccess}
              onChange={() => props.updateToken(props.token.set('canReadAllFileUploads', true))}
            />
            <span>Full Access</span>
          </label>
        </div>
        <span className="help-block">Allow access to all files</span>
        <div className="radio">
          <label htmlFor="restrictedAccess">
            <input
              id="restrictedAccess"
              disabled={props.disabled}
              type="radio"
              checked={!isFullAccess}
              onChange={() => props.updateToken(props.token.set('canReadAllFileUploads', false))}
            />
            <span>Restricted Access</span>
          </label>
        </div>
        <span className="help-block">Only files uploaded by the token are accessible</span>
      </div>
    );
  };

  const renderTrashAccessInput = () => {
    return (
      <div className="col-sm-9">
        <div className="radio">
          <label htmlFor="trashFullAccess">
            <input
              id="TrashFullAccess"
              disabled={props.disabled}
              type="radio"
              checked={canPurgeTrash(props.token)}
              onChange={() => props.updateToken(props.token.set('canPurgeTrash', true))}
            />
            <span>Full Access</span>
          </label>
        </div>
        <span className="help-block">
          Token can list, restore, and permanently delete configurations in trash.
        </span>
        <div className="radio">
          <label htmlFor="trashRestrictedAccess">
            <input
              id="trashRestrictedAccess"
              disabled={props.disabled}
              type="radio"
              checked={!canPurgeTrash(props.token)}
              onChange={() => props.updateToken(props.token.set('canPurgeTrash', false))}
            />
            <span>Restricted access</span>
          </label>
        </div>
        <span className="help-block">Token can only list and restore configurations in trash.</span>
      </div>
    );
  };

  const renderBucketsAndComponentsAccessInput = () => {
    const { isEditing } = props;
    const radioLabelStyle = isEditing ? { paddingLeft: '0px', cursor: 'default' } : {};
    const showFull = !isEditing || (isEditing && canManageBuckets(props.token));
    const showCustom = !isEditing || (isEditing && !canManageBuckets(props.token));
    return (
      <div className="col-sm-9">
        {showFull && (
          <div className="radio">
            <label htmlFor="bucketFullAccess" style={radioLabelStyle}>
              {!isEditing && (
                <input
                  id="bucketFullAccess"
                  type="radio"
                  disabled={props.disabled}
                  checked={props.token.get('canManageBuckets')}
                  onChange={() => {
                    props.updateToken(
                      props.token
                        .set('canManageBuckets', true)
                        .set('componentAccess', List())
                        .set('bucketPermissions', Map()),
                    );
                  }}
                />
              )}
              <span>Full Access</span>
            </label>
            <span className="help-block">
              Allow full access to all buckets and components including buckets created in the
              future.
            </span>
          </div>
        )}
        {showCustom && (
          <div className="radio">
            <label htmlFor="customAccess" style={radioLabelStyle}>
              {!isEditing && (
                <input
                  id="customAccess"
                  disabled={props.disabled}
                  type="radio"
                  checked={!props.token.get('canManageBuckets')}
                  onChange={() => props.updateToken(props.token.set('canManageBuckets', false))}
                />
              )}
              <span>Custom Access</span>
            </label>
            <span className="help-block">
              Only specified components and buckets will be accessible.
            </span>
          </div>
        )}
      </div>
    );
  };

  const updateComponentAccess = (components: List<any>) => {
    props.updateToken(props.token.set('componentAccess', components));
  };

  return (
    <div className="tw-flex tw-flex-col tw-gap-4">
      <FormGroup>
        <Label htmlFor="description">Description</Label>
        <TextInput
          id="description"
          variant="secondary"
          autoFocus={!props.isEditing}
          placeholder="Describe token..."
          disabled={props.disabled}
          value={props.token.get('description') || ''}
          onChange={(value) => props.updateToken(props.token.set('description', value))}
        />
      </FormGroup>
      {renderFormGroup(
        'Expires In',
        isAdminToken ? (
          <div className="col-sm-9">
            <p className="form-control-static">
              This is a user admin token that is valid as long as the user exists.
            </p>
          </div>
        ) : (
          renderCustomExpires()
        ),
      )}
      {props.isEditing &&
        renderFormGroup(
          'Created',
          <div className="col-sm-9">
            <p className="form-control-static">
              <CreatedDate createdTime={props.token.get('created')} />
              {renderCreatorTokenLink()}
            </p>
          </div>,
        )}
      {canSetupRestrictions && renderFormGroup('Files', renderFileUploadsAccessInput())}
      {canSetupRestrictions &&
        renderFormGroup('Components & Buckets', renderBucketsAndComponentsAccessInput())}
      {isCustomAccess &&
        renderFormGroup(
          '',
          <div className="col-sm-offset-3 col-sm-9">
            <ComponentsSelector
              disabled={props.disabled}
              onChange={updateComponentAccess}
              selectedComponents={props.token.get('componentAccess', List())}
              allComponents={props.allComponents}
              hasNewQueue={props.hasNewQueue}
            />
            <span className="help-block">Token can run selected components</span>
          </div>,
        )}
      {isCustomAccess &&
        renderFormGroup(
          '',
          <BucketsSelector
            disabled={props.disabled}
            bucketPermissions={props.token.get('bucketPermissions', Map()).toMap()}
            onChange={(permissions) =>
              props.updateToken(props.token.set('bucketPermissions', permissions))
            }
            allBuckets={props.allBuckets}
            permission="read"
            wrapperClassName="cols-sm-offset-3 col-sm-9"
          />,
        )}
      {isCustomAccess &&
        renderFormGroup(
          '',
          <BucketsSelector
            disabled={props.disabled}
            bucketPermissions={props.token.get('bucketPermissions', Map()).toMap()}
            onChange={(permissions) =>
              props.updateToken(props.token.set('bucketPermissions', permissions))
            }
            allBuckets={props.allBuckets}
            permission="write"
            wrapperClassName="cols-sm-offset-3 col-sm-9"
          />,
        )}
      {canSetupRestrictions && renderFormGroup('Trash', renderTrashAccessInput())}
      {canSetupRestrictions &&
        props.isEditing &&
        renderFormGroup(
          'Manage Tokens',
          <div className="col-sm-9">
            <p className="form-control-static">
              <Check isChecked={canManageTokens(props.token)} />
            </p>
            <p className="help-block">
              Token {canManageTokens(props.token) ? 'has' : "hasn't"} permission to manage (e.g.,
              create) other tokens.
            </p>
          </div>,
        )}
    </div>
  );
};

export default TokenEditor;
