import { Map } from 'immutable';
import _ from 'underscore';

import { SHARES_TYPES, sortEntities } from '@/constants';
import {
  canManageBucket,
  canManageSharedBucket,
  canUnlinkBucket,
  canWriteBucket,
} from '@/modules/admin/privileges';
import {
  DISABLED_EXTERNAL_TABLES_MESSAGE,
  DISABLED_WORKSPACE_ACTIONS_MESSAGE,
  FORM_STEPS,
} from '@/modules/sandboxes/Constants';
import { workspaceActionsDisabled } from '@/modules/sandboxes/helpers';
import type { WorkspaceStep } from '@/modules/sandboxes/types';
import { SortIcon } from '@/react/common';
import {
  CopyToWorkspace,
  CreateSnapshot,
  CreateWorkspace,
  ExportSelected,
  ShareTables,
  TableIconAction,
} from '@/react/common/TableIconActions';
import type { OpenShareModal, SetSortType, TableData } from '@/types/types';
import string from '@/utils/string';

type Props = {
  allBuckets?: Map<string, any>;
  sapiToken: Map<string, any>;
  sort: Map<string, any>;
  setSort: SetSortType;
  table: TableData;
  canExportTable: boolean;
  isSharingAvailable: boolean;
  openDeleteModal: (selectedRows: Map<string, any>[]) => void;
  openCreateWorkspaceModal: (selectedRows: Map<string, any>[], forceStep?: WorkspaceStep) => void;
  openExportTablesModal: (selectedRows: Map<string, any>[]) => void;
  openSnapshotModal: (selectedRows: Map<string, any>[]) => void;
  openShareModal: OpenShareModal;
  hasSnowflakePartnerConnectLimited: boolean;
  isCreatingSnapshot: boolean;
  isExportingTables: boolean;
};

export const HeaderActions = ({
  allBuckets = Map(),
  sort,
  setSort,
  table,
  sapiToken,
  canExportTable,
  isSharingAvailable,
  openDeleteModal,
  openCreateWorkspaceModal,
  openExportTablesModal,
  openSnapshotModal,
  openShareModal,
  hasSnowflakePartnerConnectLimited,
  isCreatingSnapshot,
  isExportingTables,
}: Props) => {
  const selectedRows = table.getSelectedRowModel().flatRows.map((row) => row.original.item);

  const { bucketsCount, tablesCount } = selectedRows.reduce(
    (bucketsAndTables, row) => {
      const key = row.has('stage') ? 'bucketsCount' : 'tablesCount';
      return { ...bucketsAndTables, [key]: bucketsAndTables[key] + 1 };
    },
    {
      bucketsCount: 0,
      tablesCount: 0,
    },
  );
  const areWorkspacesDisabled = selectedRows.some((row) =>
    workspaceActionsDisabled(row.has('stage') ? row : row.get('bucket')),
  );

  const isSortedByName = sort.get('entity') === sortEntities.NAME;
  const selectedTables = selectedRows.filter((row) => !row.has('stage'));

  /** As this component is used in IndexTableResults which does not contain tables inside buckets,
    we get those tables from allBuckets if sent and merge it with selected tables. So when user selects a bucket
    we find all the tables inside it. */
  const uniqueSelectedTables =
    allBuckets.count() === 0
      ? selectedTables
      : _.uniq(
          [
            ...selectedTables,
            ...selectedRows
              .filter((row) => row.has('stage'))
              .flatMap((row) => {
                return allBuckets.getIn([row.get('id'), 'bucketTables'], Map()).toArray();
              }),
          ],
          (table) => table.get('id'),
        );

  const renderActions = () => {
    const canManageAllSelectedTables = uniqueSelectedTables.every((selectedTable) => {
      return canManageBucket(sapiToken, selectedTable.get('bucket'));
    });

    const containsExternalTables = uniqueSelectedTables.some((selectedTable) =>
      selectedTable.getIn(['bucket', 'hasExternalSchema']),
    );

    const actions = [
      <ExportSelected
        key="export-tables"
        disabled={isExportingTables || !canExportTable || tablesCount === 0}
        disabledReason={
          tablesCount === 0
            ? 'You must select at least one table to export.'
            : !canExportTable
              ? 'You do not have permission to export tables.'
              : ''
        }
        onClick={() => openExportTablesModal(uniqueSelectedTables)}
      />,
      <CreateSnapshot
        key="create-snapshot"
        multiple={uniqueSelectedTables.length > 1}
        onClick={() => openSnapshotModal(uniqueSelectedTables)}
        disabled={
          !canManageAllSelectedTables || isCreatingSnapshot || uniqueSelectedTables.length === 0
        }
        disabledReason={
          !canManageAllSelectedTables
            ? 'You do not have permission to create snapshots.'
            : uniqueSelectedTables.length === 0
              ? 'You must select at least one table to create a snapshot.'
              : ''
        }
      />,
    ];

    if (isSharingAvailable) {
      actions.push(
        <ShareTables
          key="share-tables"
          disabled={
            !canManageSharedBucket(sapiToken) || containsExternalTables || tablesCount === 0
          }
          tooltipText={
            !canManageSharedBucket(sapiToken)
              ? 'You do not have permission to share tables.'
              : tablesCount === 0
                ? 'You must select at least one table to share.'
                : containsExternalTables
                  ? DISABLED_EXTERNAL_TABLES_MESSAGE
                  : `Share ${string.pluralize(tablesCount, 'table')}`
          }
          onClick={() =>
            openShareModal({ selectedTables, shareModalStep: SHARES_TYPES.SELECTED_TABLES })
          }
        />,
      );
    }

    if (!hasSnowflakePartnerConnectLimited) {
      actions.push(
        <CreateWorkspace
          key="create-workspace"
          tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
          onClick={() => !areWorkspacesDisabled && openCreateWorkspaceModal(selectedRows)}
          disabled={areWorkspacesDisabled}
        />,
      );
      actions.push(
        <CopyToWorkspace
          key="copy-to-workspace"
          tooltip={areWorkspacesDisabled ? DISABLED_WORKSPACE_ACTIONS_MESSAGE : null}
          onClick={() => {
            if (!areWorkspacesDisabled) {
              openCreateWorkspaceModal(selectedRows, FORM_STEPS.SANDBOX_UPDATE);
            }
          }}
          disabled={areWorkspacesDisabled}
        />,
      );
    }

    const selectedBuckets = selectedRows.filter((row) => row.has('stage'));
    const selectedTables = selectedRows.filter((row) => !row.has('stage'));
    const onlyLinkedBuckets =
      selectedBuckets.length > 0 && selectedBuckets.every((row) => row.has('linkedBy'));
    const canUnlinkSelected = selectedBuckets
      .filter((row) => row.has('sourceBucket'))
      .every((row) => canUnlinkBucket(sapiToken, row));
    const canDeleteSelectedBuckets = selectedBuckets
      .filter((row) => !row.has('sourceBucket'))
      .every((row) => canManageBucket(sapiToken, row) && !row.has('linkedBy'));
    const canDeleteSelectedTables = selectedTables.every((row) =>
      canWriteBucket(sapiToken, row.get('bucket')),
    );

    return [
      ...actions,
      <span key="delete-separator" className="group-separator compact" />,
      <TableIconAction
        key="delete-selected"
        tooltip={
          onlyLinkedBuckets
            ? 'You must unlink all linked buckets before deleting them.'
            : !canUnlinkSelected
              ? 'You do not have permission to unlink all selected items.'
              : !canDeleteSelectedBuckets
                ? 'You do not have permission to delete all selected buckets.'
                : !canDeleteSelectedTables
                  ? 'You do not have permission to delete all selected tables.'
                  : 'Delete selected'
        }
        onClick={() => openDeleteModal(selectedRows)}
        disabled={
          onlyLinkedBuckets ||
          !canUnlinkSelected ||
          !canDeleteSelectedBuckets ||
          !canDeleteSelectedTables
        }
        libraryIcon="trash"
      />,
    ];
  };

  return (
    <div className="flex-container flex-start">
      {bucketsCount + tablesCount === 0 ? (
        <span
          className="clickable"
          title="Sort by name"
          onClick={() => {
            setSort({
              entity: sortEntities.NAME,
              order: isSortedByName ? sort.get('order') * -1 : 1,
            });
          }}
        >
          Name
          <SortIcon
            className="icon-addon-left"
            isSorted={isSortedByName}
            isSortedDesc={sort.get('order') === -1}
          />
        </span>
      ) : (
        <strong>
          {!!bucketsCount && `${bucketsCount} ${string.pluralize(bucketsCount, 'bucket')} `}
          {bucketsCount && tablesCount ? 'and ' : ''}
          {!!tablesCount && `${tablesCount} ${string.pluralize(tablesCount, 'table')} `}
          selected
        </strong>
      )}

      {bucketsCount + tablesCount > 0 && (
        <div className="table-action-buttons no-wrap">{renderActions()}</div>
      )}
    </div>
  );
};
