import type { CreateVariableBody, VariableWithHash } from '@keboola/api-client';

import { apiClient } from '@/apiClient';
import Dispatcher from '@/Dispatcher';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import ApplicationStore from '@/stores/ApplicationStore';
import { ActionTypes } from './constants';
import {
  filterCurrentBranchVariables,
  filterProductionVariables,
  isVariableEncryptionRequired,
} from './helpers';
import VariablesStore from './store';

const loadDevModeVariables = async (currentBranchId: string, defaultBranchId: string) => {
  const [branchVariables, productionVariables, projectWideVariables] = await Promise.all([
    apiClient.vault.getVariablesByBranchId(currentBranchId),
    apiClient.vault.getVariablesByBranchId(defaultBranchId),
    apiClient.vault.getProjectWideVariables(),
  ]);

  const productionOnlyVariables = productionVariables.filter((variable) => {
    return variable.attributes?.branchId === defaultBranchId;
  });

  return filterCurrentBranchVariables(branchVariables)
    .concat(filterProductionVariables(productionOnlyVariables))
    .concat(projectWideVariables);
};

const loadVariables = async () => {
  if (VariablesStore.getStore().loading) {
    return Promise.resolve();
  }

  const currentBranchId = `${DevBranchesStore.getCurrentId()}`;
  const defaultBranchId = `${DevBranchesStore.getDefaultBranchId()}`;

  Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES });
  try {
    const variables = DevBranchesStore.isDevModeActive()
      ? await loadDevModeVariables(currentBranchId, defaultBranchId)
      : await apiClient.vault.getVariablesByBranchId(defaultBranchId);

    Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES_SUCCESS, variables });
  } catch (error) {
    Dispatcher.handleViewAction({ type: ActionTypes.LOAD_VARIABLES_ERROR });
    throw error;
  }
};

const createVariable = async (variable: CreateVariableBody) => {
  if (variable.group === '') {
    delete variable.group;
  }

  if (variable.attributes?.branchId === '') {
    delete variable.attributes.branchId;
  }

  const branchType = variable.attributes?.branchId
    ? variable.attributes.branchId === DevBranchesStore.getDefaultBranchId()?.toString()
      ? 'default'
      : 'dev'
    : null;

  const encryptedVariable = isVariableEncryptionRequired(variable)
    ? await apiClient.encryption.encrypt({
        projectId: ApplicationStore.getCurrentProjectId(),
        data: variable.value,
        branchType,
      })
    : variable.value;

  return apiClient.vault.createVariable({ ...variable, value: encryptedVariable });
};

const deleteVariable = (variable: VariableWithHash) => {
  if (!variable.hash) throw new Error('Variable hash is missing');
  return apiClient.vault.deleteVariable(variable.hash);
};

const updateVariable = (variable: VariableWithHash) => {
  return deleteVariable(variable).then(() => createVariable(variable));
};

const createFolder = async (group: string, variables: VariableWithHash[]) => {
  const updatedVariables = variables
    .map((variable) => ({ ...variable, group }))
    .map(updateVariable);

  await Promise.all(updatedVariables);
  await loadVariables();
};

export { loadVariables, createVariable, deleteVariable, updateVariable, createFolder };
