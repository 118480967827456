import { useCallback, useLayoutEffect, useRef } from 'react';
import { useReactFlow, useStore } from '@xyflow/react';

import { useBuilderStore } from './store/store';
import { getLayoutedElements } from './helpers';
import type { AppNode } from './types';

const isAllNodesMeasured = (nodes: AppNode[]) => {
  return nodes.length > 0 && nodes.every((node) => node.measured?.width && node.measured?.height);
};

export const useLayoutNodes = () => {
  const isProcessing = useRef(false);

  const [layoutTrigger, getNodes, getEdges, setNodes, setEdges] = useBuilderStore((state) => [
    state.layoutTrigger,
    state.getNodes,
    state.getEdges,
    state.setNodes,
    state.setEdges,
  ]);

  const allNodesMeasured = isAllNodesMeasured(getNodes());

  useLayoutEffect(() => {
    if (isProcessing.current) {
      return;
    }

    isProcessing.current = true;
    getLayoutedElements(getNodes(), getEdges(), allNodesMeasured)
      .then(({ nodes, edges }) => {
        setNodes(nodes);
        setEdges(edges);
      })
      .finally(() => {
        isProcessing.current = false;
      });

    // layoutTrigger is used to forced recalculation of layout after some changes
  }, [getNodes, getEdges, setNodes, setEdges, allNodesMeasured, layoutTrigger]);
};

export const useCenterNode = () => {
  const { setCenter } = useReactFlow<AppNode>();
  const flowHeight = useStore((state) => state.height);

  const centerNode = useCallback(
    (node?: AppNode) => {
      if (!node?.measured?.width || !node?.measured?.height) {
        return;
      }

      setCenter(node.position.x + node.measured.width / 2, flowHeight / 2 + 50);
    },
    [flowHeight, setCenter],
  );

  return centerNode;
};
