import { useState } from 'react';
import type { ChangeEvent, FormEvent } from 'react';
import { ControlLabel, FormControl, FormGroup, Modal } from 'react-bootstrap';
import type Promise from 'bluebird';
import type { Map } from 'immutable';

import { Alert, HelpBlock, ProgressBar } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import Checkbox from '@/react/common/Checkbox';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import FilesDropZone from '@/react/common/FilesDropZone';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';

type State = {
  file: File | null;
  incremental: boolean;
  delimiter: string;
  enclosure: string;
  error: string | null;
};

const INITIAL_STATE: State = {
  file: null,
  incremental: false,
  delimiter: ',',
  enclosure: '"',
  error: null,
};

type Props = {
  show: boolean;
  table: Map<string, any>;
  onSubmit: (
    file: File,
    params: { delimiter: string; enclosure: string; incremental: number },
  ) => Promise<unknown>;
  onHide: () => void;
  isLoading: boolean;
  progress: number;
};

const LoadTableFromCsvModal = (props: Props) => {
  const [state, setState] = useState<State>(INITIAL_STATE);

  const isSaving = props.isLoading || props.progress > 0;

  const renderProgress = () => {
    const progress = props.isLoading ? 100 : props.progress;

    return (
      <div>
        <p>{progress < 100 ? 'Uploading file...' : 'File was successfully uploaded.'}</p>
        <ProgressBar type="success" progress={progress} />
        {props.isLoading && (
          <p>
            <Loader /> Importing data...
          </p>
        )}
      </div>
    );
  };

  const isDisabled = isSaving || state.error ? true : !state.file || !state.delimiter;

  const onHide = () => {
    props.onHide();
    setState(INITIAL_STATE);
  };

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const params = {
      delimiter: state.delimiter,
      enclosure: state.enclosure,
      incremental: state.incremental ? 1 : 0,
    };

    if (!state.file) {
      return;
    }

    return props
      .onSubmit(state.file, params)
      .then(onHide, (error) => setState((prevState) => ({ ...prevState, error })));
  };

  return (
    <Modal show={props.show} onHide={onHide}>
      <form onSubmit={onSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>Import CSV into Table {props.table.get('displayName')}</Modal.Title>
          <ModalIcon color="green" icon="arrow-up" bold />
        </Modal.Header>
        <Modal.Body>
          {state.error ? (
            <Alert variant="error" className="tw-mb-5">
              {state.error}
            </Alert>
          ) : (
            <div>
              {isSaving ? (
                renderProgress()
              ) : (
                <>
                  <DevBranchStorageWarning
                    message="The data will also be loaded to the table in production."
                    hasProductionEntity={!isCreatedInDevBranch(props.table.get('bucket'))}
                  />
                  <FormGroup>
                    <FilesDropZone
                      label="CSV file"
                      onDrop={(files) =>
                        setState((prevState) => ({
                          ...prevState,
                          file: files[0],
                        }))
                      }
                      files={state.file}
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Delimiter</ControlLabel>
                    <FormControl
                      type="text"
                      value={state.delimiter}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setState((prevState) => ({
                          ...prevState,
                          delimiter: event.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <ControlLabel>Enclosure</ControlLabel>
                    <FormControl
                      type="text"
                      value={state.enclosure}
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        setState((prevState) => ({
                          ...prevState,
                          enclosure: event.target.value,
                        }))
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Checkbox
                      checked={state.incremental}
                      onChange={() =>
                        setState((prevState) => ({
                          ...prevState,
                          incremental: !prevState.incremental,
                        }))
                      }
                    >
                      Incremental
                    </Checkbox>
                    <HelpBlock className="tw-mt-1">
                      Data from the CSV file will be appended to the table.
                    </HelpBlock>
                  </FormGroup>
                </>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {state.error ? (
            <ConfirmButtons
              block
              saveStyle="primary"
              saveLabel="Try again"
              onSave={() => setState(INITIAL_STATE)}
            />
          ) : (
            <ConfirmButtons
              block
              saveButtonType="submit"
              saveLabel="Import"
              isDisabled={isDisabled}
            />
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default LoadTableFromCsvModal;
