import PropTypes from 'prop-types';

import { ButtonInline, HelpBlock } from '@keboola/design';

const RefreshTables = ({ refresh }) => {
  return (
    <HelpBlock className="tw-mt-1">
      Not seeing your newest tables?{' '}
      <ButtonInline variant="link" className="tw-text-xs" onClick={() => refresh()}>
        Reload
      </ButtonInline>{' '}
      the list of tables.
    </HelpBlock>
  );
};

RefreshTables.propTypes = {
  refresh: PropTypes.func.isRequired,
};

export default RefreshTables;
