import { cn } from '@keboola/design';

import { deleteTask } from '@/modules/flows-v2/builder/actions';
import { TaskBox } from '@/modules/flows-v2/builder/components/TaskBox';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { VisualNotificationTask } from '@/modules/flows-v2/builder/types';
import { CircleIcon, Truncated } from '@/react/common';
import TaskActions from './TaskActions';

export const PhaseNotificationTask = ({
  task,
  readOnly,
}: {
  task: VisualNotificationTask;
  readOnly: boolean;
}) => {
  const [context, selectedTaskId, setSelectedTaskId] = useBuilderStore((state) => [
    state.context,
    state.selectedTaskId,
    state.setSelectedTaskId,
  ]);

  const handleDeleteTask = () => {
    deleteTask(context.config.get('id'), task.id, context.tasks);
    setSelectedTaskId(null);
  };

  return (
    <TaskBox
      onClick={() => setSelectedTaskId(task.id)}
      active={selectedTaskId === task.id}
      className={cn({
        'tw-bg-neutral-50': !task.enabled,
        'tw-border-warning-500': !task.recipient,
      })}
    >
      <CircleIcon
        bold
        smaller
        icon="bell"
        color="blue"
        className={cn({ 'tw-opacity-50': !task.enabled })}
      />

      <h4
        className={cn('tw-m-0', {
          'tw-opacity-50': !task.enabled,
          'tw-text-warning-500': !task.recipient,
        })}
      >
        <Truncated
          twoLines
          text={!task.recipient ? 'No recipient' : task.name}
          className="break-all"
        />
        <div className="tw-text-xs tw-text-neutral-400">Notification</div>
      </h4>

      <TaskActions
        readOnly={readOnly}
        onDelete={handleDeleteTask}
        onOpen={() => setSelectedTaskId(task.id)}
      />
    </TaskBox>
  );
};
