import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Alert, Icon } from '@keboola/design';

import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import VersionsStore from '@/modules/components/stores/VersionsStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import * as actionsProvisioning from '@/modules/ex-db-generic/actionsProvisioning';
import {
  supportConfigRows,
  supportSimpleSetup,
  supportSplitLoading,
} from '@/modules/ex-db-generic/helpers';
import AsynchActionError from '@/modules/ex-db-generic/react/components/AsynchActionError';
import CreateQueryElement from '@/modules/ex-db-generic/react/components/CreateQueryElement';
import MigrateToRowsButton from '@/modules/ex-db-generic/react/components/MigrateToRowsButton';
import Quickstart from '@/modules/ex-db-generic/react/components/Quickstart';
import * as storeProvisioning from '@/modules/ex-db-generic/storeProvisioning';
import { shouldShowSimplifiedUi } from '@/modules/simplified-ui/helpers';
import SimplifiedUi from '@/modules/simplified-ui/Index';
import { FilterPanel, RouterLink } from '@/react/common';
import CredentialsSetupBox from '@/react/common/CredentialsSetupBox';
import NoResultsFound from '@/react/common/NoResultsFound';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import QueryTable from './QueryTable';

const Index = function (componentId) {
  const actionsCreators = actionsProvisioning.createActions(componentId);
  return createReactClass({
    mixins: [
      createStoreMixin(
        ApplicationStore,
        VersionsStore,
        ComponentsStore,
        StorageTablesStore,
        InstalledComponentsStore,
        ConfigurationRowsStore,
      ),
    ],

    UNSAFE_componentWillReceiveProps() {
      return this.setState(this.getStateFromStores());
    },

    componentDidMount() {
      if (
        !this.state.readOnly &&
        this.state.hasCredentials &&
        this.state.queries.isEmpty() &&
        !this.state.sourceTables
      ) {
        actionsProvisioning.loadSourceTables(componentId, this.state.configId);
      }
    },

    getStateFromStores() {
      const configId = RoutesStore.getRouterState().getIn(['params', 'config']);
      const config = InstalledComponentsStore.getConfig(componentId, configId);
      const ExDbStore = storeProvisioning.createStore(componentId, configId);
      const queries = ExDbStore.getQueries();
      const credentials = ExDbStore.getCredentials();
      const enabledQueries = queries.filter(function (query) {
        return query.get('enabled');
      });
      const isRowConfiguration = ExDbStore.isRowConfiguration();
      const queriesFiltered = ExDbStore.getQueriesFiltered().map((query) => {
        const configRow =
          config.get('rows').find((row) => row.get('id') === query.get('id')) || Map();

        return query
          .set('configuration', configRow.getIn(['configuration', 'parameters'], Map()))
          .merge(configRow.delete('configuration'));
      });

      return {
        isRowConfiguration,
        configId,
        config,
        queries,
        queriesFiltered,
        configData: InstalledComponentsStore.getConfigData(componentId, configId),
        versions: VersionsStore.getVersions(componentId, configId),
        pendingActions: ExDbStore.getQueriesPendingActions(),
        hasCredentials: !credentials.isEmpty(),
        newCredentials: ExDbStore.getNewCredentials(),
        sourceTables: ExDbStore.getSourceTables(),
        queriesFilter: ExDbStore.getQueriesFilter(),
        hasEnabledQueries: enabledQueries.count() > 0,
        localState: ExDbStore.getLocalState(),
        component: ComponentsStore.getComponent(componentId),
        readOnly: ApplicationStore.isReadOnly(),
        migrateOnly: supportConfigRows(componentId) && !isRowConfiguration,
      };
    },

    handleCredentialsSetup() {
      actionsCreators.updateEditingCredentials(this.state.configId, this.state.newCredentials);
      RoutesStore.getRouter().transitionTo('ex-db-generic-' + componentId + '-credentials', {
        config: this.state.configId,
      });
    },

    handleFilterChange(query) {
      return actionsCreators.setQueriesFilter(this.state.configId, query);
    },

    handleRefreshSourceTables() {
      return actionsProvisioning.reloadSourceTables(componentId, this.state.configId);
    },

    handleDismissMigrationAlert() {
      return actionsCreators.dismissMigrationAlert(this.state.configId);
    },

    handleQuickstartChange(tables) {
      return actionsCreators.quickstartSelected(this.state.configId, tables);
    },

    handleQuickstartSubmit(tables, loadColumns) {
      return actionsCreators.quickstart(this.state.configId, tables, loadColumns);
    },

    renderNewQueryLink() {
      if (this.state.readOnly || this.state.migrateOnly) {
        return null;
      }

      return (
        <CreateQueryElement
          isNav={false}
          configurationId={this.state.configId}
          componentId={componentId}
          actionsProvisioning={actionsProvisioning}
        />
      );
    },

    renderCredentialsSetup() {
      return (
        <CredentialsSetupBox
          show={!this.state.hasCredentials}
          readOnly={this.state.readOnly}
          onClick={this.handleCredentialsSetup}
        />
      );
    },

    renderQueriesMain() {
      if (this.state.queries.isEmpty() || this.state.localState.get('quickstartSaving', false)) {
        if (!this.state.hasCredentials) {
          return null;
        }

        if (
          !this.state.readOnly &&
          !this.state.migrateOnly &&
          supportSimpleSetup(componentId) &&
          !this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_ERROR_PATH)
        ) {
          return (
            <Quickstart
              isSaving={this.state.localState.get('quickstartSaving', false)}
              isLoadingSourceTables={
                this.state.localState.getIn(storeProvisioning.LOADING_SOURCE_TABLES_PATH) || false
              }
              sourceTables={this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_PATH)}
              sourceTablesError={this.state.localState.getIn(
                storeProvisioning.SOURCE_TABLES_ERROR_PATH,
              )}
              supportSplitLoading={supportSplitLoading(componentId)}
              quickstart={this.state.localState.get('quickstart') || Map()}
              onChange={this.handleQuickstartChange}
              onSubmit={this.handleQuickstartSubmit}
              refreshMethod={this.handleRefreshSourceTables}
            />
          );
        }

        return (
          <div className="box-separator">
            <h2 className="tw-m-0 tw-mb-4 tw-text-base">Tables</h2>
            <div className="box">
              <div className="box-content tw-flex tw-flex-col tw-items-center">
                <p>No tables created yet.</p>
                {this.renderNewQueryLink()}
              </div>
            </div>
          </div>
        );
      }

      if (!this.state.queriesFiltered.count()) {
        return <NoResultsFound entityName="tables" />;
      }

      return (
        <QueryTable
          component={this.state.component}
          configuration={this.state.config}
          queries={this.state.queriesFiltered}
          filter={this.state.queriesFilter}
          pendingActions={this.state.pendingActions}
          isRowConfiguration={this.state.isRowConfiguration}
          readOnly={this.state.readOnly}
        />
      );
    },

    renderAsynchError() {
      if (this.state.localState.getIn(storeProvisioning.SOURCE_TABLES_ERROR_PATH)) {
        return (
          <div className="box-separator">
            <AsynchActionError
              componentId={componentId}
              configId={this.state.configId}
              sourceTablesLoading={this.state.localState.getIn(
                storeProvisioning.LOADING_SOURCE_TABLES_PATH,
                false,
              )}
              sourceTablesError={this.state.localState.getIn(
                storeProvisioning.SOURCE_TABLES_ERROR_PATH,
              )}
            />
          </div>
        );
      }
    },

    renderMigrationToRowsButton() {
      if (supportConfigRows(componentId) && !this.state.isRowConfiguration) {
        return (
          <div className="box">
            <div className="box-content text-center">
              <p>
                Please migrate the configuration to the newest format to unlock the latest features.
              </p>
              <MigrateToRowsButton
                onMigrate={() => actionsCreators.migrateConfig(this.state.configId)}
              />
            </div>
          </div>
        );
      }
      if (
        supportConfigRows(componentId) &&
        !!this.state.localState.getIn(['migration', 'completed'])
      ) {
        return (
          <div className="box-separator">
            <Alert variant="success" onClose={this.handleDismissMigrationAlert} className="tw-mb-5">
              The configuration has been successfully migrated.
            </Alert>
          </div>
        );
      }
    },

    render() {
      if (shouldShowSimplifiedUi(this.state.component, this.state.configData)) {
        return <SimplifiedUi />;
      }

      return (
        <GenericConfigBody
          key={`${componentId}-${this.state.configId}`}
          componentId={componentId}
          configId={this.state.configId}
          sidebarProps={{
            run: {
              disabled: !this.state.hasEnabledQueries ? 'There are no tables configured.' : '',
              text: 'You are about to run an extraction.',
            },
            additionalButtons: this.state.hasCredentials && (
              <RouterLink
                to={'ex-db-generic-' + componentId + '-credentials'}
                params={{ config: this.state.configId }}
              >
                <Icon icon="user" fixedWidth />
                Database credentials
              </RouterLink>
            ),
          }}
        >
          {this.renderAsynchError()}
          {this.renderCredentialsSetup()}
          {this.renderMigrationToRowsButton()}
          {this.state.queries.count() > 0 &&
            !this.state.localState.get('quickstartSaving', false) && (
              <div className="box-separator">
                <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
                  <h2 className="tw-m-0 tw-text-base">Tables</h2>
                  {this.renderNewQueryLink()}
                </div>
                <FilterPanel query={this.state.queriesFilter} onChange={this.handleFilterChange} />
              </div>
            )}
          {this.renderQueriesMain()}
        </GenericConfigBody>
      );
    },
  });
};

export default Index;
