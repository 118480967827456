import { useState } from 'react';
import type { List, Map } from 'immutable';

import { ButtonInline, Icon, Tooltip } from '@keboola/design';

import Loader from '@/react/common/Loader';
import VersionsDiffModal from '@/react/common/VersionsDiffModal';

type Props = {
  config: Map<string, any>;
  component: Map<string, any>;
  versionConfig: Map<string, any>;
  previousVersionConfig: Map<string, any>;
  onLoadVersionConfig: () => Promise<void>;
  sharedCodes: List<any>;
  admins: Map<string, any>;
  row?: Map<string, any>;
  hideRollback?: boolean;
  isPending?: boolean;
  isDisabled?: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  text?: string;
  tooltipText?: string;
};

const DiffVersionButton = ({
  config,
  component,
  versionConfig,
  previousVersionConfig,
  onLoadVersionConfig,
  sharedCodes,
  admins,
  row,
  hideRollback,
  isPending,
  isDisabled,
  buttonClassName,
  iconClassName,
  text,
  tooltipText,
}: Props) => {
  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    if (isPending || isDisabled) {
      return;
    }

    onLoadVersionConfig().then(() => setShowModal(true));
  };

  const button = (
    <ButtonInline
      color="dark"
      variant="link"
      disabled={isDisabled || isPending}
      className={buttonClassName}
      onClick={handleClick}
    >
      {isPending ? (
        <Loader className={iconClassName} />
      ) : (
        <Icon icon="right-left" fixedWidth className={iconClassName} />
      )}
      {text}
    </ButtonInline>
  );

  return (
    <>
      {tooltipText ? (
        <Tooltip tooltip={tooltipText} placement="top">
          {button}
        </Tooltip>
      ) : (
        button
      )}
      <VersionsDiffModal
        show={showModal}
        admins={admins}
        onClose={() => setShowModal(false)}
        component={component}
        config={config}
        row={row}
        referentialVersion={versionConfig}
        compareVersion={previousVersionConfig}
        sharedCodes={sharedCodes}
        hideRollback={hideRollback}
      />
    </>
  );
};

export default DiffVersionButton;
