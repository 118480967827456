import { Item } from '@radix-ui/react-radio-group';

import { cn } from '../../utils';

import type { RadioGroupItemProps } from './types';

export const RadioGroupItemBlock = ({ className, children, ...props }: RadioGroupItemProps) => (
  <Item
    {...props}
    className={cn(
      // reset
      'tw-bg-transparent',
      'tw-p-0',
      'tw-peer',
      'tw-cursor-pointer',
      'tw-whitespace-nowrap',

      'tw-group',

      // layout
      'tw-p-4',
      'tw-flex-1',
      'tw-flex',
      'tw-gap-2.5',
      'tw-items-center',

      // border
      'tw-border-solid',
      'tw-border',
      'tw-border-neutral-200',
      'tw-rounded-lg',

      // font
      'tw-text-sm',
      'tw-font-normal',
      'tw-leading-5',

      // outline
      'tw-outline',
      'tw-outline-1',
      'tw-outline-transparent',

      // hover
      'hover:tw-border-secondary-500',
      'hover:tw-outline-secondary-500',

      // active
      'active:tw-outline-secondary-500',
      'active:tw-border-secondary-500',

      // focus-visible
      'focus-visible:tw-border-secondary-700',
      'focus-visible:tw-outline-secondary-700',

      // disabled
      'disabled:tw-cursor-not-allowed',
      'disabled:hover:tw-border-neutral-200',
      'disabled:hover:tw-outline-transparent',

      // fix global styles in kbc-ui
      'focus:[&:not(:focus-visible)]:!tw-outline',
      'focus:[&:not(:focus-visible)]:!tw-outline-1',
      'focus:[&:not(:focus-visible)]:!tw-outline-secondary-500',
      'focus:[&:not(:focus-visible)]:!tw-border-secondary-500',

      className,
    )}
  >
    <div
      className={cn(
        'tw-size-[11px]',
        'tw-ml-0.5',
        'tw-rounded-full',
        'tw-outline',
        'tw-outline-1',
        'tw-outline-offset-2',

        // unchecked
        'group-data-[state=unchecked]:tw-outline-neutral-400',

        // unchecked:hover
        'group-data-[state=unchecked]:group-hover:tw-outline-neutral-500',

        // unchecked:active
        'group-data-[state=unchecked]:group-active:tw-outline-neutral-400',

        // unchecked:disabled
        'group-data-[state=unchecked]:group-disabled:tw-opacity-40',

        // unchecked:disabled:hover
        'group-data-[state=unchecked]:group-disabled:group-hover:tw-outline-neutral-400',

        // checked
        'group-data-[state=checked]:tw-outline-secondary-500',
        'group-data-[state=checked]:tw-bg-secondary-500',

        // checked:hover
        'group-data-[state=checked]:group-hover:tw-outline-secondary-700',
        'group-data-[state=checked]:group-hover:tw-bg-secondary-700',

        // checked:active
        'group-data-[state=checked]:group-active:tw-outline-secondary-500',
        'group-data-[state=checked]:group-active:tw-bg-secondary-500',

        // checked:disabled
        'group-data-[state=checked]:group-disabled:tw-opacity-40',
        'group-data-[state=checked]:disabled:tw-cursor-not-allowed',

        // checked:disabled:hover
        'group-data-[state=checked]:group-disabled:group-hover:tw-bg-secondary-500',
        'group-data-[state=checked]:group-disabled:group-hover:tw-outline-secondary-500',
      )}
    />

    {children}
  </Item>
);
