import { Component } from 'react';
import type { ChangeEvent, FormEvent, KeyboardEvent, MouseEvent, ReactNode } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'underscore';

import { cn, Icon, IconButton } from '@keboola/design';

type Props = {
  query?: string;
  onChange: (newString: string) => void;
  onKeyDown?: (key: string) => void;
  onSubmit?: () => void;
  inputRef?: (ref: HTMLInputElement) => void;
  className?: string;
  placeholder?: string | (() => string);
  additionalActions?: ReactNode;
  bordered?: boolean;
  autoFocus?: boolean;
};

class SearchBar extends Component<Props> {
  static defaultProps = {
    placeholder: 'Search',
    query: '',
    bordered: false,
    autoFocus: false,
  };

  internalInputRef = null as HTMLInputElement | null;

  render() {
    return (
      <div
        className={cn('searchbar', { 'with-border': this.props.bordered }, this.props.className)}
      >
        <form onSubmit={this.handleSubmit} className="searchbar-form">
          <FormControl
            value={this.props.query}
            {...(!this.props.query && {
              placeholder: _.isFunction(this.props.placeholder)
                ? this.props.placeholder()
                : this.props.placeholder,
            })}
            onChange={this.handleChange}
            onKeyDown={this.handleKeyDown}
            className={cn('searchbar-input', {
              'without-decorations': !this.props.bordered,
            })}
            type="text"
            autoFocus={this.props.autoFocus}
            inputRef={(input: HTMLInputElement) => {
              this.internalInputRef = input;
              if (this.props.inputRef) {
                this.props.inputRef(input);
              }
            }}
          />
          <Icon icon="magnifying-glass" />
          {this.props.query && (
            <IconButton variant="invisible" icon="xmark" onClick={(e) => this.handleClear(e)} />
          )}
        </form>
        {this.props.additionalActions && (
          <div className="searchbar-actions">{this.props.additionalActions}</div>
        )}
      </div>
    );
  }

  handleKeyDown = (event: KeyboardEvent) => {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event.key);
    }
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.props.onSubmit) {
      this.props.onSubmit();
    }
  };

  handleClear = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    this.props.onChange('');

    if (this.props.onSubmit) {
      setTimeout(() => this.props.onSubmit?.(), 50);
    }

    this.internalInputRef?.focus();
  };
}

export default SearchBar;
