import type { ReactNode } from 'react';

import { cn } from '@keboola/design';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import { RouterLink } from './RouterLink';

const ComponentDetailLink = (props: {
  componentId: string;
  hasFlows?: boolean;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
}) => {
  const linkParams = resolveRouterLinkParams(props.componentId, null, null, props.hasFlows);

  if (!linkParams) {
    return null;
  }

  return (
    <RouterLink
      to={linkParams.to}
      params={linkParams.params}
      className={cn('dark', props.className)}
      onClick={props.onClick}
    >
      {props.children}
    </RouterLink>
  );
};

export default ComponentDetailLink;
