import { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import { prepareConfigForWorkspace } from '@/modules/legacy-transformation/helpers';
import AddSandboxModal from '@/modules/sandboxes/components/AddSandboxModal/AddSandboxModal';
import { FORM_STEPS, SANDBOX_TYPE } from '@/modules/sandboxes/Constants';
import {
  createNewWorkspaceFromTransformation,
  resolveSandboxTypeFromComponentId,
  updateExistingWorkspace,
} from '@/modules/sandboxes/helpers';

class CreateWorkspaceButton extends Component {
  state = {
    showModal: false,
    forceStep: null,
  };

  render() {
    return (
      <>
        {this.renderButton('Create new workspace', FORM_STEPS.SANDBOX_CREATE)}
        {this.renderButton('Copy to existing workspace', FORM_STEPS.SANDBOX_UPDATE)}
        <AddSandboxModal
          forceType={
            this.props.forceType || resolveSandboxTypeFromComponentId(this.props.componentId)
          }
          forceStep={this.state.forceStep}
          hasTableInputMapping={
            !this.props.config
              .getIn(['configuration', 'storage', 'input', 'tables'], List())
              .isEmpty()
          }
          allowedComponents={this.props.allowedComponents}
          workspaces={this.props.workspaces}
          hasPayAsYouGo={this.props.hasPayAsYouGo}
          show={this.state.showModal}
          onHide={this.handleClose}
          onSubmit={this.handleSubmit}
          onUpdate={this.handleUpdate}
          sourceTransformation={this.props.config}
          metadata={this.props.metadata}
        />
      </>
    );
  }

  renderButton(label, forceStep) {
    return (
      <button
        className="btn btn-link"
        onClick={() => this.setState({ showModal: true, forceStep })}
      >
        <Icon icon="box" fixedWidth />
        {label}
      </button>
    );
  }

  handleClose = () => this.setState({ showModal: false, forceStep: null });

  handleSubmit = (name, type, options, params, description) => {
    return createNewWorkspaceFromTransformation(
      this.props.componentId,
      this.props.config,
      name,
      type,
      options,
      params,
      description,
    );
  };

  handleUpdate = (workspace, preserve) => {
    return updateExistingWorkspace(
      this.props.componentId === KEBOOLA_LEGACY_TRANSFORMATION
        ? prepareConfigForWorkspace(this.props.config)
        : this.props.config,
      workspace,
      preserve,
      `Use mapping from "${this.props.config.get('name')}" transformation`,
    );
  };
}

CreateWorkspaceButton.propTypes = {
  hasPayAsYouGo: PropTypes.bool.isRequired,
  componentId: PropTypes.string.isRequired,
  config: PropTypes.instanceOf(Map).isRequired,
  metadata: PropTypes.instanceOf(Map).isRequired,
  allowedComponents: PropTypes.instanceOf(Map).isRequired,
  workspaces: PropTypes.instanceOf(Map),
  forceType: PropTypes.oneOf(Object.values(SANDBOX_TYPE)),
};

export default CreateWorkspaceButton;
