import type { ReactFlowState } from '@xyflow/react';
import { ControlButton, Controls, useReactFlow, useStore } from '@xyflow/react';

import { cn, Icon, Tooltip } from '@keboola/design';

import type { AppNode } from '@/modules/flows-v2/builder/types';

const zoomOptions = { maxZoom: 1, minZoom: 0.5 };
const zoomOutSelector = (state: ReactFlowState) => state.transform[2] <= state.minZoom;
const zoomInSelector = (state: ReactFlowState) => state.transform[2] >= state.maxZoom;

export const CanvasControls = () => {
  const { fitView, zoomIn, zoomOut } = useReactFlow<AppNode>();

  const minZoomReached = useStore(zoomOutSelector);
  const maxZoomReached = useStore(zoomInSelector);

  return (
    <Controls
      className={cn(
        '!tw-left-6 !tw-top-6 tw-m-0 tw-flex tw-flex-col tw-rounded tw-text-neutral-400 [&>span:last-child_button]:!tw-border-b-0 [&_button]:!tw-border-0 [&_button]:!tw-border-b [&_button]:!tw-border-solid [&_button]:!tw-border-neutral-200 [&_button]:tw-p-4 [&_svg]:tw-size-4 [&_svg]:tw-max-h-4 [&_svg]:tw-max-w-4',
      )}
      position="top-left"
      showZoom={false}
      showFitView={false}
      showInteractive={false}
    >
      <Tooltip placement="left" tooltip="Zoom In" type="action">
        <ControlButton onClick={() => zoomIn()} disabled={maxZoomReached}>
          <Icon icon="plus" />
        </ControlButton>
      </Tooltip>
      <Tooltip placement="left" tooltip="Zoom Out" type="action">
        <ControlButton onClick={() => zoomOut()} disabled={minZoomReached}>
          <Icon icon="minus" />
        </ControlButton>
      </Tooltip>
      <Tooltip placement="left" tooltip="Fit View" type="action">
        <ControlButton onClick={() => fitView(zoomOptions)}>
          <Icon icon="crosshairs" />
        </ControlButton>
      </Tooltip>
    </Controls>
  );
};
