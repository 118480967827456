import type { ComponentProps } from 'react';
import type { NodeProps } from '@xyflow/react';

import { cn, Icon } from '@keboola/design';

import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { TaskBox } from '@/modules/flows-v2/builder/components/TaskBox';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import { routeNames as templateRouteNames } from '@/modules/templates/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

export const EmptyNode = ({ id }: NodeProps) => {
  const [context, selectedPhaseId, setSelectedPhaseId] = useBuilderStore((state) => [
    state.context,
    state.selectedPhaseId,
    state.setSelectedPhaseId,
  ]);

  const isOpen = selectedPhaseId === id;
  const { readOnly } = context;
  const offerTemplates =
    ApplicationStore.hasTemplates() && !readOnly && !DevBranchesStore.isDevModeActive();

  const renderIcon = (icon: ComponentProps<typeof Icon>['icon']) => {
    return (
      <div className="tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-bg-secondary-500 tw-text-white">
        <Icon fixedWidth icon={icon} className="tw-text-base" />
      </div>
    );
  };

  return (
    <div className={cn('nodrag nopan', { 'flex-container justify-center gap-24': offerTemplates })}>
      <TaskBox
        className={cn({ clickable: !readOnly })}
        disabled={readOnly}
        active={isOpen}
        onClick={() => {
          if (readOnly) {
            return;
          }

          setSelectedPhaseId(isOpen ? null : id);
        }}
      >
        {readOnly ? (
          <h4 className="tw-user-select-none tw-m-0 tw-flex-1 tw-text-center">Empty phase</h4>
        ) : (
          <>
            {renderIcon(isOpen ? 'xmark' : 'plus')}
            <h4 className="tw-user-select-none tw-m-0">
              Select first step
              <div className="tw-text-xs tw-font-normal tw-text-neutral-400">
                500+ sources supported
              </div>
            </h4>
          </>
        )}
      </TaskBox>
      {offerTemplates && (
        <>
          <span className="tw-text-xs tw-font-medium tw-tracking-wider tw-text-neutral-400">
            OR
          </span>
          <TaskBox
            onClick={() => {
              RoutesStore.getRouter().transitionTo(templateRouteNames.TEMPLATES, null, {
                flowId: RoutesStore.getRouterState().getIn(['params', 'config']),
              });
            }}
          >
            {renderIcon('book-open')}
            <h4 className="tw-user-select-none tw-m-0">
              Use template
              <div className="tw-text-xs tw-font-normal tw-text-neutral-400">20+ templates</div>
            </h4>
          </TaskBox>
        </>
      )}
    </div>
  );
};
