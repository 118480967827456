import { Handle, type NodeProps, Position } from '@xyflow/react';

import { AddButton } from '@/modules/flows-v2/builder/components/AddButton';

export const AddNode = ({ id }: NodeProps) => {
  return (
    <div className="nopan">
      <AddButton id={id} />

      {id.startsWith('after:') ? (
        <Handle type="target" position={Position.Top} className="tw-opacity-0" />
      ) : (
        <Handle type="source" position={Position.Bottom} className="tw-opacity-0" />
      )}
    </div>
  );
};
