import Promise from 'bluebird';
import createReactClass from 'create-react-class';
import { fromJS, List, Map } from 'immutable';

import { cn } from '@keboola/design';

import { KEBOOLA_SANDBOXES, PROVISIONING, TRANSFORMATION } from '@/constants/componentIds';
import InstalledComponentsActionsCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentConfigurationLink from '@/modules/components/react/components/ComponentConfigurationLink';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import ConfigurationRowsStore from '@/modules/configurations/ConfigurationRowsStore';
import { MINIMUM_JOBS_FOR_GRAPH } from '@/modules/jobs/Constants';
import { getJobComponentId } from '@/modules/jobs/JobComponentResolver';
import JobsApi from '@/modules/jobs/JobsApi';
import JobsStore from '@/modules/jobs/stores/JobsStore';
import JobsGraph from '@/modules/queue/components/JobsGraph';
import RunInfoPanel from '@/modules/queue/components/RunInfoPanel';
import { prepareGraphData } from '@/modules/queue/helpers';
import { routeNames as sandboxesRouteNames } from '@/modules/sandboxes/Constants';
import { routeNames as legacyTransformationRouteNames } from '@/modules/transformations/Constants';
import TransformationsStore from '@/modules/transformations/stores/TransformationsStore';
import { RouterLink } from '@/react/common';
import EmptyGraph from '@/react/common/EmptyGraph';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import SoundNotifications from '@/utils/SoundNotifications';
import JobLogPanel from './JobLogPanel';
import PanelsRows from './PanelsRows';

const JOBS_LIMIT_FOR_GRAPH = 36;

const JobDetail = createReactClass({
  mixins: [
    createStoreMixin(
      JobsStore,
      RoutesStore,
      StorageTablesStore,
      InstalledComponentsStore,
      ComponentsStore,
    ),
  ],

  getStateFromStores() {
    return {
      job: JobsStore.get(RoutesStore.getCurrentRouteIntParam('jobId')),
      admins: ApplicationStore.getAdmins(),
      sapiToken: ApplicationStore.getSapiToken(),
      query: JobsStore.getQuery(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      hasFlows: ApplicationStore.hasFlows(),
    };
  },

  getInitialState() {
    return {
      graphData: null,
      loadingPreviousJobs: true,
    };
  },

  componentDidMount() {
    if (!this.state.job) {
      return;
    }

    this.loadPreviousJobs();
  },

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.job || this.state.job.get('status') === prevState.job.get('status')) {
      return;
    }

    switch (this.state.job.get('status')) {
      case 'success':
        if (getJobComponentId(this.state.job) === KEBOOLA_SANDBOXES) {
          Promise.delay(1000).then(() => {
            InstalledComponentsActionsCreators.loadComponentConfigsData(KEBOOLA_SANDBOXES);
          });
        }

        return SoundNotifications.success();
      case 'error':
      case 'cancelled':
      case 'canceled':
      case 'terminated':
        return SoundNotifications.crash();
      default:
        break;
    }
  },

  UNSAFE_componentWillReceiveProps() {
    this.setState(this.getStateFromStores());
  },

  componentWillUnmount() {
    if (this.cancellablePromise) {
      this.cancellablePromise.cancel();
    }
  },

  render() {
    if (!this.state.job) {
      return null;
    }

    return (
      <div className="job-detail-page">
        <RunInfoPanel
          job={this.state.job}
          admin={this.state.admins.get(this.state.job.getIn(['token', 'description']))}
          jobConfigurationLink={this.renderJobConfigurationLink()}
        />
        <PanelsRows
          job={this.state.job}
          sapiToken={this.state.sapiToken}
          graphData={this.state.graphData}
          hasPayAsYouGo={this.state.hasPayAsYouGo}
        />
        {this.renderGraph()}
        <JobLogPanel job={this.state.job} admins={this.state.admins} />
      </div>
    );
  },

  renderJobConfigurationLink() {
    let job = JobsStore.getUserRunnedParentJob(this.state.job);

    if (
      job.get('component') === 'transformation' &&
      job.getIn(['params', 'transformations'], List()).count()
    ) {
      return job.getIn(['params', 'transformations']).map((transition) => {
        job = job.setIn(['params', 'transformations', 0], transition);
        return (
          <div key={transition}>
            {this.renderConfigurationLink(job)}
            {this.renderConfigurationRowLink(job)}
            {this.renderConfigVersion(job)}
          </div>
        );
      });
    }

    return (
      <>
        {this.renderConfigurationLink(job)}
        {this.renderConfigurationRowLink(job)}
        {this.renderConfigVersion(job)}
      </>
    );
  },

  renderGraph() {
    return (
      <>
        <h3 className="job-graph-header">Duration &amp; Errors</h3>
        <hr />
        {!this.state.graphData?.count() ? (
          <EmptyGraph
            text={`This graph is only available for non-partial jobs with at least ${MINIMUM_JOBS_FOR_GRAPH}
            log entries, as we are unable to produce a useful visualisation without sufficient data.`}
            isLoading={this.state.loadingPreviousJobs}
          />
        ) : (
          <JobsGraph data={this.state.graphData} minEntries={JOBS_LIMIT_FOR_GRAPH} />
        )}
      </>
    );
  },

  renderConfigurationLink(job) {
    let componentId = getJobComponentId(job);
    let configuration = this._getConfiguration(job);
    if (
      componentId === KEBOOLA_SANDBOXES &&
      configuration.hasIn(['configuration', 'parameters', 'id'])
    ) {
      return (
        <RouterLink
          className="font-medium"
          to={sandboxesRouteNames.WORKSPACE}
          params={{
            config: configuration.get('id'),
          }}
        >
          {configuration.get('name', configuration.get('id'))}
        </RouterLink>
      );
    }

    if (job.get('component') === PROVISIONING) {
      if (job.hasIn(['params', 'transformation', 'config_id'])) {
        const configId = job.getIn(['params', 'transformation', 'config_id']);
        const configuration = InstalledComponentsStore.getConfig(TRANSFORMATION, configId);

        return (
          <ComponentConfigurationLink
            className={cn(!configuration.isEmpty() && 'font-medium')}
            componentId="transformation"
            configId={configId}
            isDeleted={configuration.isEmpty()}
            hasFlows={this.state.hasFlows}
          >
            {configuration.get('name', configId)}
          </ComponentConfigurationLink>
        );
      }

      return (
        <RouterLink to={legacyTransformationRouteNames.SANDBOX} className="font-medium">
          Plain Sandbox
        </RouterLink>
      );
    }

    const configId = job.getIn(['params', 'config']);
    if (configId) {
      return (
        <ComponentConfigurationLink
          className={cn(!configuration.isEmpty() && 'font-medium')}
          componentId={componentId}
          configId={configId}
          isDeleted={configuration.isEmpty()}
          hasFlows={this.state.hasFlows}
        >
          {configuration.get('name', configId)}
        </ComponentConfigurationLink>
      );
    }

    return <em>N/A</em>;
  },

  renderConfigurationRowLink(job) {
    let componentId = getJobComponentId(job);
    let configuration = this._getConfiguration(job);
    let configId = configuration.get('id');
    let rowId = job.getIn(['params', 'transformations', 0], null);
    let rowName = TransformationsStore.getTransformationName(configId, rowId);
    const component = ComponentsStore.getComponent(componentId) || Map();

    if (!rowId) {
      rowId = job.getIn(['params', 'row'], null);
      rowName = ConfigurationRowsStore.get(componentId, configId, rowId).get('name');
    }

    if (rowId && rowName) {
      return (
        <>
          {' / '}
          <ComponentConfigurationLink
            className="font-medium"
            componentType={component.get('type')}
            componentId={componentId}
            configId={configId}
            rowId={rowId}
          >
            {rowName}
          </ComponentConfigurationLink>
        </>
      );
    }

    if (
      job.hasIn(['params', 'transformation', 'config_id']) &&
      job.hasIn(['params', 'transformation', 'row_id'])
    ) {
      configId = job.getIn(['params', 'transformation', 'config_id']);
      rowId = job.getIn(['params', 'transformation', 'row_id']);

      return (
        <>
          {' / '}
          <ComponentConfigurationLink
            className="font-medium"
            componentId="transformation"
            configId={configId}
            rowId={rowId}
          >
            {ConfigurationRowsStore.get(TRANSFORMATION, configId, rowId).get('name', rowId)}
          </ComponentConfigurationLink>
        </>
      );
    }

    if (rowId) {
      return <>{` / ${rowId}`}</>;
    }

    return null;
  },

  renderConfigVersion(job) {
    const configVersion = job.getIn(['result', 'configVersion']);

    if (configVersion) {
      return ` / Version ${configVersion}`;
    }

    const transformationConfigVersion = job.getIn(['params', 'transformation', 'config_version']);

    if (transformationConfigVersion) {
      return ` / Version ${transformationConfigVersion}`;
    }

    return null;
  },

  _getConfiguration(job) {
    const config = job.getIn(['params', 'config'], '');
    return InstalledComponentsStore.getConfig(getJobComponentId(job), config.toString());
  },

  loadPreviousJobs() {
    this.cancellablePromise = JobsApi.loadPreviousJobs(this.state.job)
      .then((jobs) => {
        const previousJobs = fromJS(jobs).filter(
          (job) => job.get('startTime') && job.get('endTime'),
        );

        if (previousJobs.count() >= MINIMUM_JOBS_FOR_GRAPH) {
          this.setState({ graphData: prepareGraphData(previousJobs) });
        }
      })
      .finally(() => this.setState({ loadingPreviousJobs: false }));
  },
});

export default JobDetail;
