import type { Map } from 'immutable';

import { ButtonInline, cn, DotsLoader, Icon, Tooltip } from '@keboola/design';

import type { AutomationMessage, UserMessage } from '@/api/routes/aiService';
import {
  isClientMessage,
  isFakeErrorMessage,
  isInteractiveMessageResponse,
} from '@/modules/automations/helpers';
import Gravatar from '@/react/common/Gravatar';
import Markdown from '@/react/common/Markdown';
import KeboolaLogo from '@/react/layout/KeboolaLogo';
import InteractiveMessageRequest from './InteractiveMessageRequest';
import InteractiveMessageResponse from './InteractiveMessageResponse';

const Message = ({
  messageObject,
  admin,
  isDisabled,
  isFailed,
  submitMessage,
}: {
  messageObject?: AutomationMessage;
  admin?: Map<string, any>;
  isDisabled?: boolean;
  isFailed?: boolean;
  submitMessage?: (messageObject: Partial<UserMessage>) => Promise<void>;
}) => {
  const isFakeError = isFakeErrorMessage(messageObject);
  const isClient = isClientMessage(messageObject) && !isFakeError;
  const isLoading = !messageObject;
  const isRejected = messageObject?.status === 'rejected';

  if (
    !isLoading &&
    !messageObject.text &&
    (!messageObject.data || !isInteractiveMessageResponse(messageObject))
  )
    return null;

  return (
    <Tooltip
      tooltip="This message was cancelled."
      forceHide={!isRejected}
      placement="top"
      triggerClassName={cn('tw-whitespace-normal tw-flex tw-w-full tw-gap-1 first:tw-mt-auto', {
        'tw-self-end': isClient,
        'tw-opacity-60': isRejected,
      })}
    >
      {isClient ? (
        <div className="tw-order-last tw-shrink-0">
          <Gravatar user={admin} size={24} />
        </div>
      ) : (
        <KeboolaLogo className="tw-shrink-0" height={24} width={24} withoutText />
      )}
      <div className="tw-flex tw-flex-1 tw-flex-col tw-gap-2">
        <div
          className={cn(
            'tw-w-fit tw-rounded-lg tw-p-4',
            isClient
              ? 'tw-self-end tw-rounded-tr-none tw-bg-secondary-600 !tw-text-white [&_*]:!tw-text-white'
              : 'tw-rounded-tl-none tw-bg-neutral-50',
          )}
        >
          {!!messageObject?.text && !isInteractiveMessageResponse(messageObject) && (
            <Markdown
              overflow={false}
              collapsible={false}
              source={messageObject.text}
              className={cn(
                isFakeError &&
                  '[&_code]:tw-mt-2 [&_code]:tw-block [&_code]:tw-max-h-32 [&_code]:tw-overflow-y-auto [&_code]:tw-p-2',
              )}
            />
          )}
          {messageObject && isInteractiveMessageResponse(messageObject) && (
            <InteractiveMessageResponse messageObject={messageObject} />
          )}
          {!messageObject && <DotsLoader />}
        </div>
        {submitMessage && messageObject && (
          <InteractiveMessageRequest
            messageObject={messageObject}
            submitMessage={submitMessage}
            isDisabled={isDisabled}
          />
        )}
        {isClient && isFailed && (
          <div className="-tw-mt-1 tw-self-end tw-text-xs">
            <span className="tw-text-error-500">Message failed.</span>{' '}
            {submitMessage && (
              <ButtonInline
                variant="custom"
                className="tw-text-xs tw-font-bold tw-text-neutral-900"
                onClick={() => submitMessage(messageObject as UserMessage)}
              >
                Send again?{' '}
                <Icon icon="refresh" className="!tw-mr-0 tw-ml-1 !tw-text-xs !tw-text-[inherit]" />
              </ButtonInline>
            )}
          </div>
        )}
      </div>
    </Tooltip>
  );
};

export default Message;
