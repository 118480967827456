import { KeboolaHttpHeader } from '../../constants';
import { createCallbackMiddleware, createOpenapiFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { paths } from './__generated__/schema';
import type {
  CreateVariableBody,
  DeleteVariablePath,
  GetVariablesByBranchId,
  GetVariablesQuery,
  VariableWithHash,
} from './types';

export const createVaultClient = ({ baseUrl, token, callbacks }: ClientInitOptions) => {
  const client = createOpenapiFetchClient<paths>({
    baseUrl: baseUrl,
    headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
    middlewares: [createCallbackMiddleware(callbacks)],
  });

  const getVariables = async (query: GetVariablesQuery, signal?: AbortSignal) => {
    const { data } = await client.get(
      '/variables',
      { query },
      {
        signal,
      },
    );

    return data as VariableWithHash[];
  };

  const deleteVariable = async (hash: DeleteVariablePath['hash'], signal?: AbortSignal) => {
    await client.delete(
      '/variables/{hash}',
      {
        path: { hash },
      },
      { signal },
    );
  };

  const createVariable = async (body: CreateVariableBody, signal?: AbortSignal) => {
    const { data } = await client.post('/variables', { body }, { signal });
    return data;
  };

  const getVariablesByBranchId = async (
    branchId: GetVariablesByBranchId['branchId'],
    signal?: AbortSignal,
  ) => {
    const { data } = await client.get(
      '/variables/scoped/branch/{branchId}',
      {
        path: { branchId },
      },
      { signal },
    );
    return data as VariableWithHash[];
  };

  const getProjectWideVariables = (signal?: AbortSignal) => getVariablesByBranchId('null', signal);

  return {
    createVariable,
    deleteVariable,
    getProjectWideVariables,
    getVariables,
    getVariablesByBranchId,
  };
};
