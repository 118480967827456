import { Children, useState } from 'react';
import type { ReactNode } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import { ButtonInline, cn } from '@keboola/design';

const BUTTON_VARIANT = 'link';

export const PaginatedBox = ({ children }: { children: ReactNode }) => {
  const [activeBox, setActiveBox] = useState<number>(0);
  const boxes = Children.toArray(children);
  const count = boxes.length;

  if (!count) {
    return null;
  }

  return (
    <div className="box-panel-content paginated">
      <SwitchTransition>
        <CSSTransition key={activeBox} classNames="fade" timeout={300}>
          {boxes[activeBox]}
        </CSSTransition>
      </SwitchTransition>
      {count > 1 && (
        <div className="box-navigation">
          {boxes.map((_, index) => (
            <ButtonInline
              key={index}
              variant={BUTTON_VARIANT}
              className={cn({ active: activeBox === index })}
              onClick={() => setActiveBox(index)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
