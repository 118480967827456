import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import type { Map } from 'immutable';

import { ButtonGroup, IconButton, SearchForm, Tooltip } from '@keboola/design';

import { canDownloadSlicedFile } from '@/modules/admin/privileges';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import BlockButton from '@/react/common/BlockButton';
import Loader from '@/react/common/Loader';
import NoResultsFound from '@/react/common/NoResultsFound';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import ExamplesModal from './components/FilesSearchExamplesModal';
import FilesTable from './components/FilesTable';
import StorageTabs from './components/StorageTabs';
import { deleteFile, filterFiles, loadMoreFiles } from './actions';

const Files = () => {
  const store = useStores(
    () => {
      return {
        files: FilesStore.getAll() as Map<string, any>,
        hasMore: FilesStore.hasMore() as boolean,
        searchQuery: RoutesStore.getRouterState().getIn(['location', 'query', 'q']),
        downloadingFiles: FilesStore.getDownloadingFiles(),
        isLoading: FilesStore.getIsLoading(),
        isLoadingMore: FilesStore.getIsLoadingMore() as boolean,
        isDeleting: FilesStore.getIsDeleting() as Map<string, any>,
        isReadOnly: ApplicationStore.isReadOnly(),
        canDownloadSlicedFile: canDownloadSlicedFile(ApplicationStore.getSapiToken()),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        admins: ApplicationStore.getAdmins(),
        canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
      };
    },
    [],
    [ApplicationStore, RoutesStore, FilesStore],
  );

  const [openExamplesModal, setOpenExamplesModal] = useState(false);

  const renderFiles = () => {
    if (!store.files.count() && store.isLoading) {
      return (
        <div className="box">
          <div className="box-content">
            <Loader /> Loading files...
          </div>
        </div>
      );
    }

    if (!store.files.count()) {
      return <NoResultsFound entityName="files" />;
    }

    return (
      <div className="box">
        <InfiniteScroll initialLoad={false} loadMore={fetchMoreFiles} hasMore={store.hasMore}>
          <FilesTable
            readOnly={store.isReadOnly}
            files={store.files}
            downloadingFiles={store.downloadingFiles}
            admins={store.admins}
            canDownloadSlicedFile={store.canDownloadSlicedFile}
            onSearchQuery={filterFiles}
            onDeleteFile={deleteFile}
            isDeleting={store.isDeleting}
            searchQuery={store.searchQuery}
          />
        </InfiniteScroll>
        {renderMoreButton()}
      </div>
    );
  };

  const renderMoreButton = () => {
    if (!store.files || !store.hasMore) {
      return null;
    }

    return <BlockButton onClick={fetchMoreFiles} isLoading={store.isLoadingMore} />;
  };

  const fetchMoreFiles = () => {
    if (store.isLoadingMore) return;

    return loadMoreFiles({
      offset: store.files.count(),
      ...(store.searchQuery && { q: store.searchQuery }),
    });
  };

  return (
    <StorageTabs
      hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
      hasPayAsYouGo={store.hasPayAsYouGo}
      canEnableDataStreams={store.canEnableDataStreams}
    >
      <SearchForm
        name="files-search"
        placeholder='Search: tags:"tag" or click on the tag'
        value={store.searchQuery}
        onSubmit={filterFiles}
        suffix={
          <ButtonGroup>
            <Tooltip tooltip="Search syntax &amp; Examples" placement="top">
              <IconButton
                variant="outline"
                size="small"
                onClick={() => setOpenExamplesModal(true)}
                icon="circle-question"
              />
            </Tooltip>
          </ButtonGroup>
        }
        className="tw-mb-6"
      />
      {renderFiles()}
      <ExamplesModal
        show={openExamplesModal}
        onHide={() => setOpenExamplesModal(false)}
        onSelectExample={filterFiles}
      />
    </StorageTabs>
  );
};

export default Files;
