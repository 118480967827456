export const Loading = () => (
  <>
    <div className="container-topbar navbar">
      <div className="container-fluid">
        <div className="topbar tw-h-[68px]" />
        <div className="main-navbar tw-mb-[104px] !tw-h-[68px]" />
      </div>
    </div>
    <div className="container">
      <div className="box">
        <div className="box-content">
          <div className="text-muted text-center">
            <div className="mt-2 mb-2">Loading...</div>
          </div>
        </div>
      </div>
    </div>
  </>
);
