import type { List } from 'immutable';
import { Map } from 'immutable';

import { FILTERS, FILTERS_GROUP } from '@/constants';
import { KEBOOLA_SANDBOXES } from '@/constants/componentIds';
import { componentTypes } from '@/constants/componentTypes';
import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { canExportTable, canWriteBucket } from '@/modules/admin/privileges';
import { getAllowedTransformations } from '@/modules/components/helpers';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import {
  filterDevBranchBuckets,
  filterProductionAndCurrentDevBranchBuckets,
  filterProductionBuckets,
} from '@/modules/dev-branches/helpers';
import { prepareSandboxes } from '@/modules/sandboxes/helpers';
import SandboxesStore from '@/modules/sandboxes/SandboxesStore';
import { factory as eventsFactory } from '@/modules/sapi-events/BucketEventsService';
import StackFeaturesStore from '@/modules/stack-features/Store';
import { TabLink, TabNav } from '@/react/common';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import type { CommonStoreGetters } from '@/utils/StoreUtils';
import BucketActions from './components/BucketActions';
import BucketOverview from './components/BucketOverview';
import BucketEvents from './components/Events';
import SearchContextDropdown from './components/SearchContextDropdown';
import { toggleContextFilter } from './actions';
import { bucketTabs, routeNames } from './constants';
import StorageStore from './store';

type StoreState = {
  bucket: Map<string, any>;
  sapiToken: Map<string, any>;
  tables: Map<string, any>;
  allTables: Map<string, any>;
  allBuckets: Map<string, any>;
  contextFilter: boolean;
  availableBuckets: Map<string, any>;
  isDevModeActive: boolean;
  activeTab: (typeof bucketTabs)[keyof typeof bucketTabs];
  indexSearchFilters: Map<string, any>;
  indexSearchQuery: string;
  configurations: Map<string, any>;
  components: Map<string, any>;
  pendingActions: Map<string, any>;
  deletingTables: Map<string, any>;
  exportingTables: Map<string, any>;
  createSnapshotsTables: Map<string, any>;
  truncatingTables: Map<string, any>;
  canExportTable: boolean;
  canWriteBucket: boolean;
  canManageBuckets: boolean;
  admins: Map<string, any>;
  hasPayAsYouGo: boolean;
  urlTemplates: Map<string, any>;
  availableUsersOptions: List<any>;
  availableProjectsOptions: List<any>;
  sandboxes: Map<string, any>;
  allowedTransformationComponents: Map<string, any>;
  hasFlows: boolean;
  hasSnowflakePartnerConnectLimited: boolean;
};

const Bucket = () => {
  const store = useStores<CommonStoreGetters, StoreState>(
    () => {
      const bucketId = RoutesStore.getCurrentRouteParam('bucketId');
      const bucket = BucketsStore.getBucket(bucketId);
      const sapiToken = ApplicationStore.getSapiToken();
      const allBuckets = BucketsStore.getAll();
      const allTables = TablesStore.getAll() as Map<string, any>;
      const tables = allTables.filter(
        (table: Map<string, any>) => table.getIn(['bucket', 'id']) === bucketId,
      );
      const searchFilters = (StorageStore.getSearchFilters() ?? Map()) as Map<string, any>;
      const contextFilter = StorageStore.getContextFilter() as boolean;
      const isDevModeActive = DevBranchesStore.isDevModeActive();
      const availableBuckets = isDevModeActive
        ? filterProductionAndCurrentDevBranchBuckets(allBuckets)
        : contextFilter && searchFilters.get(FILTERS_GROUP.ENTITY) === FILTERS.DEV
          ? filterDevBranchBuckets(allBuckets)
          : filterProductionBuckets(allBuckets);

      return {
        bucket,
        sapiToken,
        tables,
        allTables,
        allBuckets,
        contextFilter,
        availableBuckets,
        isDevModeActive,
        activeTab: RoutesStore.getCurrentRouteParam('bucketTab') || bucketTabs.OVERVIEW,
        indexSearchFilters: searchFilters,
        indexSearchQuery: StorageStore.getSearchQuery() as string,
        configurations: InstalledComponentsStore.getAll(),
        components: ComponentsStore.getAll(),
        pendingActions: BucketsStore.getPendingBucketsActions(),
        deletingTables: TablesStore.getDeletingTables(),
        exportingTables: TablesStore.getExportingTables(),
        createSnapshotsTables: TablesStore.getCreatingSnapshotsTables(),
        truncatingTables: TablesStore.getTruncatingTables(),
        canExportTable: canExportTable(sapiToken),
        canWriteBucket: canWriteBucket(sapiToken, bucket),
        admins: ApplicationStore.getAdmins(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        urlTemplates: ApplicationStore.getUrlTemplates(),
        availableUsersOptions: ApplicationStore.getSharingToAdminsData(),
        availableProjectsOptions: ApplicationStore.getSharingToProjectsData(),
        sandboxes: prepareSandboxes(
          SandboxesStore.getSandboxes(),
          InstalledComponentsStore.getComponentConfigurations(KEBOOLA_SANDBOXES),
        ),
        allowedTransformationComponents: getAllowedTransformations(
          ComponentsStore.getAllForType(componentTypes.TRANSFORMATION),
          ApplicationStore.getSapiToken(),
          ApplicationStore.getCurrentProjectFeatures(),
          StackFeaturesStore.getAll(),
        ),
        hasFlows: ApplicationStore.hasFlows(),
        hasSnowflakePartnerConnectLimited: ApplicationStore.hasCurrentProjectFeature(
          FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED,
        ),
      } as StoreState;
    },
    [],
    [
      ApplicationStore,
      StorageStore,
      ComponentsStore,
      InstalledComponentsStore,
      SandboxesStore,
      RoutesStore,
      BucketsStore,
      TablesStore,
    ],
  );

  if (!store.bucket) {
    return <p>Bucket not found</p>;
  }

  const renderTabLink = (
    tab: (typeof bucketTabs)[keyof typeof bucketTabs],
    label: string,
    className?: string,
  ) => {
    return (
      <TabLink
        active={store.activeTab === tab}
        to={routeNames.BUCKET}
        params={{ bucketId: store.bucket.get('id'), bucketTab: tab }}
        className={className}
      >
        {label}
      </TabLink>
    );
  };

  const renderTabContent = () => {
    if (store.activeTab === bucketTabs.EVENTS) {
      return (
        <BucketEvents eventsFactory={eventsFactory(store.bucket.get('id'))} admins={store.admins} />
      );
    }

    return (
      <BucketOverview
        components={store.components}
        configurations={store.configurations}
        allowedTransformationComponents={store.allowedTransformationComponents}
        sandboxes={store.sandboxes}
        sapiToken={store.sapiToken}
        buckets={store.allBuckets}
        bucket={store.bucket}
        tables={store.tables}
        allTables={store.allTables}
        isDevModeActive={store.isDevModeActive}
        pendingActions={store.pendingActions}
        hasPayAsYouGo={store.hasPayAsYouGo}
        canExportTable={store.canExportTable}
        hasFlows={store.hasFlows}
        canWriteBucket={store.canWriteBucket}
        urlTemplates={store.urlTemplates}
        deletingTables={store.deletingTables}
        truncatingTables={store.truncatingTables}
        exportingTables={store.exportingTables}
        createSnapshotsTables={store.createSnapshotsTables}
        availableUsersOptions={store.availableUsersOptions}
        availableProjectsOptions={store.availableProjectsOptions}
        hasSnowflakePartnerConnectLimited={store.hasSnowflakePartnerConnectLimited}
      />
    );
  };

  return (
    <>
      <div className="tabs-with-border-wrapper flex-container flex-start align-top">
        <SearchContextDropdown
          activeBucket={store.bucket.get('id')}
          searchQuery={store.indexSearchQuery}
          searchFilters={store.indexSearchFilters}
          contextFilter={store.contextFilter}
          toggleContextFilter={toggleContextFilter}
          tables={store.allTables}
          buckets={store.availableBuckets}
          activeTab={store.activeTab}
        />
        <TabNav>
          {renderTabLink(bucketTabs.OVERVIEW, 'Overview', 'ml-1')}
          {renderTabLink(bucketTabs.EVENTS, 'Events')}
        </TabNav>
        <div className="ml-auto flex-container flex-end">
          <BucketActions bucket={store.bucket} />
        </div>
      </div>
      <div key={store.bucket.get('id')}>{renderTabContent()}</div>
    </>
  );
};

export default Bucket;
