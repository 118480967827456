import type { ReactNode } from 'react';

import type { SwitchChangeEventHandler } from '@keboola/design';
import { Label, Switch } from '@keboola/design';

type Props = {
  onChange: SwitchChangeEventHandler;
  label: ReactNode;
  isActive: boolean;
  readOnly: boolean;
  isPending?: boolean;
  disabled?: boolean;
  wider?: boolean;
};

const SWITCH_ID = 'run-state-link';

export const SwitchStateLink = ({
  onChange,
  label,
  isActive,
  readOnly,
  isPending,
  disabled = false,
  wider = false,
}: Props) => (
  <div className="tw-flex tw-w-full tw-items-center tw-gap-2 tw-rounded tw-pl-1 hover:tw-bg-neutral-150">
    <Switch
      id={SWITCH_ID}
      size={wider ? 'medium' : 'small'}
      checked={isActive}
      disabled={isPending || readOnly || disabled}
      onChange={onChange}
    />
    <Label htmlFor={SWITCH_ID} className="tw-block tw-w-full tw-cursor-pointer tw-py-2 tw-pr-2">
      {label}
    </Label>
  </div>
);
