import type { ChangeEvent } from 'react';
import { FormControl } from 'react-bootstrap';

import { FormGroup } from '@keboola/design';

import dayjs from '@/date';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';

type Props = {
  isActive: boolean;
  expiryDate: string | null;
  onChange: (expiryDate: string | null) => void;
  dataFormat: string;
  type?: 'date' | 'datetime-local';
  minDate?: string;
  className?: string;
};

const ExpirationDatePicker = ({
  isActive,
  expiryDate,
  onChange,
  dataFormat,
  type = 'date',
  minDate,
  className,
}: Props) => {
  const min = minDate ?? dayjs().add(1, 'day').format(dataFormat);
  return (
    <FormGroup className={className}>
      <ActivateDeactivateSwitch
        withLabel
        customLabel="Expiration"
        className="tw-pl-0 tw-pt-0"
        triggerClassName="tw-w-fit"
        activateTooltip="Set expiration"
        deactivateTooltip="No expiration"
        isActive={isActive}
        onChange={(expires) => {
          const expiryDate = expires ? min : null;

          onChange(expiryDate);
        }}
      />
      {isActive && expiryDate && (
        <FormControl
          type={type}
          min={min}
          value={expiryDate}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const expiryDate = e.target.value || null;

            onChange(expiryDate);
          }}
        />
      )}
    </FormGroup>
  );
};

export default ExpirationDatePicker;
