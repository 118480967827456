import { Button, cn } from '@keboola/design';

const ACTIVE_CLASSES =
  '!tw-bg-white !tw-text-secondary-600 !tw-cursor-default !tw-border-neutral-200';
const INACTIVE_CLASSES = '!tw-bg-neutral-100 !tw-text-neutral-800 hover:!tw-bg-neutral-50';

const VisualSwitch = (props: {
  jsonMode: boolean;
  setJsonMode: (mode: boolean) => void;
  disabled?: boolean;
}) => {
  return (
    <div className="tw-mb-3 tw-flex tw-flex-row">
      <Button
        size="small"
        variant="outline"
        onClick={() => props.setJsonMode(false)}
        className={cn(
          props.jsonMode ? INACTIVE_CLASSES : ACTIVE_CLASSES,
          'tw-rounded-r-none tw-border-r-0',
        )}
        disabled={props.disabled}
      >
        User Interface
      </Button>
      <Button
        size="small"
        variant="outline"
        onClick={() => props.setJsonMode(true)}
        className={cn(props.jsonMode ? ACTIVE_CLASSES : INACTIVE_CLASSES, 'tw-rounded-l-none')}
        disabled={props.disabled}
      >
        JSON
      </Button>
    </div>
  );
};

export default VisualSwitch;
