import type { Map } from 'immutable';

import { Icon, Link } from '@keboola/design';

import dataStreamImage from '../../../images/illustrations/data-stream.png';
import dataStreamImage2x from '../../../images/illustrations/data-stream-2x.png';
import dataStreamImageLocked from '../../../images/illustrations/data-stream-locked.png';
import dataStreamImageLocked2x from '../../../images/illustrations/data-stream-locked-2x.png';

import { REQUESTABLE_FEATURES } from '@/constants/features';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import StorageTabs from '@/modules/storage/components/StorageTabs';
import FeatureRequestButton from '@/react/common/FeatureRequestButton';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import { fileUrl } from '@/utils/fileHelpers';
import AddNewStream from './components/AddNewStream';
import IndexTable from './components/IndexTable';
import { DATA_STREAMS_DOCS } from './constants';
import StreamStore from './store';

const Index = () => {
  const {
    sources,
    admins,
    tables,
    readOnly,
    hasProtectedDefaultBranch,
    hasPayAsYouGo,
    hasDataStreams,
    canEnableDataStreams,
  } = useStores(
    () => {
      const { sources } = StreamStore.getStore();

      return {
        sources,
        tables: StorageTablesStore.getAll() as unknown as Map<string, any>,
        admins: ApplicationStore.getAdmins(),
        readOnly: ApplicationStore.isReadOnly(),
        hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
        hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
        hasDataStreams: ApplicationStore.hasDataStreams(),
        canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
      };
    },
    [],
    [StreamStore, StorageTablesStore, ApplicationStore],
  );

  if (sources?.length) {
    return (
      <StorageTabs
        hasProtectedDefaultBranch={hasProtectedDefaultBranch}
        hasPayAsYouGo={hasPayAsYouGo}
        canEnableDataStreams={canEnableDataStreams}
      >
        <IndexTable sources={sources} admins={admins} tables={tables} readOnly={readOnly} />
      </StorageTabs>
    );
  }

  const isLocked = !hasDataStreams;

  return (
    <StorageTabs
      hasProtectedDefaultBranch={hasProtectedDefaultBranch}
      hasPayAsYouGo={hasPayAsYouGo}
      canEnableDataStreams={canEnableDataStreams}
    >
      <div className="flex-container flex-column">
        <div className="max-w-500 mbp-8 text-center">
          <h1 className="mtp-8 mbp-4 f-24 line-height-32">
            {isLocked ? 'Unlock the Power of Real-Time Data' : 'Create your Data Stream'}
          </h1>
          <p className="mbp-8 font-medium f-16 line-height-24 text-muted">
            {isLocked
              ? 'Send events from your systems directly to Keboola Storage without the need to set any component.'
              : `The Keboola Data Streams allow you to receive and store various events in your
            project's storage through HTTP, webhooks, or other applications.`}
          </p>
          <div className="flex-container justify-center gap-16">
            {isLocked ? (
              <FeatureRequestButton feature={REQUESTABLE_FEATURES.FEATURE_DATA_STREAMS}>
                {REQUESTABLE_FEATURES.FEATURE_DATA_STREAMS.buttonLabel}
              </FeatureRequestButton>
            ) : (
              <AddNewStream tables={tables} sources={sources} />
            )}
            <Link href={DATA_STREAMS_DOCS} className="btn btn-default">
              <Icon icon="book-blank" className="icon-addon-right" />
              How it works
            </Link>
          </div>
        </div>
        <img
          srcSet={`${fileUrl(isLocked ? dataStreamImageLocked2x : dataStreamImage2x)} 2x`}
          src={fileUrl(isLocked ? dataStreamImageLocked : dataStreamImage)}
          loading="lazy"
          alt="Data Stream"
          className="mtp-8"
        />
      </div>
    </StorageTabs>
  );
};

export default Index;
