const TaskCount = (props: { tasks: number }) => {
  if (props.tasks < 2) {
    return null;
  }

  return (
    <span className="tw-absolute -tw-top-2 tw-left-2 tw-bg-neutral-50 tw-px-2 tw-text-xs tw-text-neutral-400">
      {props.tasks} tasks
    </span>
  );
};

export default TaskCount;
