import type { GenericFetchClient } from '../storageClient';

import type { GetWorkspacePath, Workspace } from './types';

export const createWorkspaces = (client: GenericFetchClient) => {
  const getWorkspace = async (path: GetWorkspacePath, signal?: AbortSignal) => {
    const { data } = await client.get<Workspace, GetWorkspacePath>(
      '/branch/{branchId}/workspaces/{workspaceId}',
      { path },
      { signal },
    );

    return data;
  };

  return {
    getWorkspace,
  };
};
