import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import InstalledComponentsApi from '@/modules/components/InstalledComponentsApi';
import StorageApi from '@/modules/components/StorageApi';
import parseBucket from './utils/parseBucket';
import parseTransformation from './utils/parseTransformation';

const transformationsApi = {
  createTransformationBucket(data, changeDescription) {
    return InstalledComponentsApi.createConfiguration(
      KEBOOLA_LEGACY_TRANSFORMATION,
      data,
      changeDescription,
    ).then(parseBucket);
  },

  deleteTransformationBucket(bucketId) {
    return InstalledComponentsApi.deleteConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId);
  },

  restoreTransformationBucket(bucketId) {
    return InstalledComponentsApi.restoreConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId);
  },

  createTransformation(bucketId, data, changeDescription) {
    return InstalledComponentsApi.getComponentConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId)
      .then((config) => {
        return StorageApi.generateUniqueId().then((id) => {
          return InstalledComponentsApi.updateComponentConfiguration(
            KEBOOLA_LEGACY_TRANSFORMATION,
            bucketId,
            {
              configuration: JSON.stringify({
                ...(config.configuration ?? {}),
                parameters: {
                  ...(config.configuration?.parameters ?? {}),
                  transformations: [
                    ...(config.configuration?.parameters?.transformations ?? []),
                    { parameters: { ...data, id } },
                  ],
                },
              }),
            },
            changeDescription,
          );
        });
      })
      .then((config) => {
        return parseTransformation(config.configuration.parameters.transformations.at(-1));
      });
  },

  saveTransformation(bucketId, transformationId, data, changeDescription) {
    delete data.queriesString;

    return InstalledComponentsApi.getComponentConfiguration(KEBOOLA_LEGACY_TRANSFORMATION, bucketId)
      .then((config) => {
        const transformations = config.configuration.parameters.transformations.map(
          (transformation) => {
            if (transformation.parameters.id === transformationId) {
              return { ...transformation, parameters: data };
            }

            return transformation;
          },
        );

        return InstalledComponentsApi.updateComponentConfiguration(
          KEBOOLA_LEGACY_TRANSFORMATION,
          bucketId,
          {
            configuration: JSON.stringify({
              ...config.configuration,
              parameters: { ...config.configuration.parameters, transformations },
            }),
          },
          changeDescription,
        );
      })
      .then((config) => {
        const transformation = config.configuration.parameters.transformations.find(
          (transformation) => transformation.parameters.id === transformationId,
        );

        return parseTransformation(transformation);
      });
  },

  deleteTransformation(bucketId, transformationId, changeDescription) {
    return InstalledComponentsApi.getComponentConfiguration(
      KEBOOLA_LEGACY_TRANSFORMATION,
      bucketId,
    ).then((config) => {
      const transformation = config.configuration.parameters.transformations.find(
        (transformation) => transformation.parameters.id === transformationId,
      );

      return InstalledComponentsApi.updateComponentConfiguration(
        KEBOOLA_LEGACY_TRANSFORMATION,
        bucketId,
        {
          configuration: JSON.stringify({
            ...config.configuration,
            parameters: {
              ...config.configuration.parameters,
              transformations: config.configuration.parameters.transformations.filter(
                (transformation) => transformation.parameters.id !== transformationId,
              ),
            },
          }),
        },
        changeDescription,
      ).tap(() => {
        ApplicationActionCreators.sendNotification({
          type: 'info',
          message: `Transformation ${transformation.name} has been deleted.`,
        });

        return null;
      });
    });
  },
};

export default transformationsApi;
