import { Button } from 'react-bootstrap';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { Icon, Search } from '@keboola/design';

import { GenericConfigBody } from '@/modules/components/react/pages/GenericConfigBody';
import StorageBucketsStore from '@/modules/components/stores/StorageBucketsStore';
import StorageTablesStore from '@/modules/components/stores/StorageTablesStore';
import { getStorageInputTablesWithSourceSearch } from '@/modules/wr-db/helpers';
import actionsProvisioning from '@/modules/wr-google-drive/actionsProvisioning';
import FileModal from '@/modules/wr-google-drive/react/components/FileModal';
import FilesList from '@/modules/wr-google-drive/react/components/FilesList';
import storeProvisioning, { storeMixins } from '@/modules/wr-google-drive/storeProvisioning';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

const Index = function (COMPONENT_ID) {
  return createReactClass({
    mixins: [createStoreMixin(...storeMixins, ApplicationStore, StorageTablesStore)],

    getStateFromStores() {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const store = storeProvisioning(COMPONENT_ID, configId);

      return {
        store,
        configId,
        allTables: StorageTablesStore.getAll(),
        allBuckets: StorageBucketsStore.getAll(),
        actions: actionsProvisioning(COMPONENT_ID, configId),
        localState: store.getLocalState(),
        tablesWithSourceSearchInputMapping: getStorageInputTablesWithSourceSearch(store.configData),
        readOnly: ApplicationStore.isReadOnly(),
      };
    },

    render() {
      return (
        <GenericConfigBody
          key={`${COMPONENT_ID}-${this.state.configId}`}
          componentId={COMPONENT_ID}
          configId={this.state.configId}
          sidebarProps={{
            run: { disabled: this.invalidToRun(), text: 'You are about to upload data.' },
          }}
        >
          {this.renderTables()}
          {this.renderTableModal()}
        </GenericConfigBody>
      );
    },

    renderSearchBar() {
      return (
        <Search
          className="tw-mb-6"
          defaultValue={this.state.localState.get('searchQuery', '')}
          onChange={this.handleSearchQueryChange}
        />
      );
    },

    renderFilesList() {
      return (
        <div className="box tables-by-buckets">
          <div className="box-content">
            <FilesList
              readOnly={this.state.readOnly}
              componentId={COMPONENT_ID}
              configId={this.state.configId}
              inputTables={this.state.allTables}
              buckets={this.state.allBuckets}
              items={this.state.store.tables}
              onDeleteFn={this.state.actions.deleteTable}
              onEditFn={this.showTableModal}
              toggleEnabledFn={this.state.actions.toggleEnabled}
              isPendingFn={this.state.store.isPending}
              isDeletingFn={this.state.store.isDeleting}
              searchQuery={this.state.localState.get('searchQuery', '')}
              getRunSingleDataFn={this.state.store.getRunSingleData}
              tablesWithSourceSearchInputMapping={this.state.tablesWithSourceSearchInputMapping}
              {...this.state.actions.prepareLocalState('SheetsList')}
            />
          </div>
        </div>
      );
    },

    renderEmptyItems() {
      if (!this.isAuthorized()) {
        return null;
      }

      return (
        <div className="box-separator">
          <h2 className="tw-m-0 tw-mb-4 tw-text-base">Tables</h2>
          <div className="box">
            <div className="box-content text-center">
              <p>No tables configured yet.</p>
              {!this.state.readOnly && this.renderNewButton()}
            </div>
          </div>
        </div>
      );
    },

    renderTables() {
      if (!this.hasTables()) {
        return this.renderEmptyItems();
      }

      return (
        <div className="box-separator">
          <div className="tw-mb-4 tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-m-0 tw-text-base">Tables</h2>
            {this.isAuthorized() && !this.state.readOnly && this.renderNewButton()}
          </div>
          {this.renderSearchBar()}
          {this.renderFilesList()}
        </div>
      );
    },

    renderNewButton() {
      return (
        <Button bsStyle="success" bsSize="sm" onClick={() => this.showTableModal('1', null)}>
          <Icon icon="plus" className="icon-addon-right" />
          New Table
        </Button>
      );
    },

    renderTableModal() {
      return (
        <FileModal
          show={this.state.localState.get('showTableModal', false)}
          onHideFn={() => {
            this.state.actions.updateLocalState('showTableModal', false);
            this.state.actions.updateLocalState(['FileModal'], Map());
          }}
          onSaveFn={this.state.actions.saveTable}
          isSavingFn={this.state.store.isSaving}
          buckets={this.state.allBuckets}
          tables={this.state.allTables}
          {...this.state.actions.prepareLocalState('FileModal')}
        />
      );
    },

    showTableModal(step, file) {
      const dirtyFile = file ? file : this.state.actions.touchFile();
      const mapping = file ? this.state.store.getInputMapping(file.get('tableId')) : Map();
      const modalData = Map()
        .set('file', dirtyFile)
        .set('cleanFile', file)
        .set('step', step)
        .set('uploadType', file ? 'existing' : 'new')
        .set('mapping', mapping)
        .set('cleanMapping', mapping)
        .set(
          'exclude',
          this.state.store.mappings.filter((mapping) =>
            this.state.store.tables.some((table) => table.get('tableId') === mapping.get('source')),
          ),
        );
      this.state.actions.updateLocalState(['FileModal'], modalData);
      this.state.actions.updateLocalState('showTableModal', true);
    },

    isAuthorized() {
      return this.state.store.isAuthorized();
    },

    hasTables() {
      return this.state.store.hasTables;
    },

    invalidToRun() {
      if (!this.isAuthorized()) {
        return 'No Google account authorized';
      }
      if (!this.hasTables()) {
        return 'No tables registered for upload';
      }
      return '';
    },

    handleSearchQueryChange(query) {
      return this.state.actions.updateLocalState(['searchQuery'], query);
    },
  });
};

export default Index;
