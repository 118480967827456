import type { ComponentType, ReactElement } from 'react';
import type { LoaderFunction } from 'react-router';
import { replace, Route } from 'react-router';
import qs from 'qs';

import { FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED } from '@/constants/features';
import { APP_ROUTE } from '@/constants/routeNames';
import { Loading } from '@/modules/components/Loading';
import DevBranchesStore from '@/modules/dev-branches/DevBranchesStore';
import { getExGenericRedirects } from '@/modules/ex-generic/routes';
import { isValidTarget, mapTargetToRoute } from '@/modules/home/helpers';
import { routeNames as snowflakePartnerConnectRouteNames } from '@/modules/snowflake-partner-connect/constants';
import { getStorageRedirects } from '@/modules/storage/routes';
import App from '@/react/layout/App';
import ErrorPage from '@/react/pages/ErrorPage';
import {
  routesDisabledInBranch,
  routesDisabledInDemoPreview,
  routesDisabledInProduction,
  routesDisabledInSox,
  routesDisabledInSoxProduction,
  routesDisabledInSPCTrial,
} from '@/routes';
import ApplicationStore from '@/stores/ApplicationStore';
import { createUrl } from '@/utils/router/createUrl';
import { getQueryString } from '@/utils/router/getQueryString';
import { useRawRouterStateStore } from '@/utils/router/rawRouterStateStore';
import string from '@/utils/string';
import { loadRequiredData } from './actions';

/**
 * Represents the configuration for a route in the application.
 *
 * Additional type definitions may be added for route properties as needed.
 * This type can also be used to disable or replace functionalities, ensuring that during future
 * refactorings we have a clear overview of the configuration variables used on the routes.
 */
type RouteType = {
  path?: string;
  name: string;
  title: string | ((routerState: Map<string, any>) => string);
  defaultRouteHandler: ComponentType<any>;
  headerButtonsHandler?: ComponentType<any>;
  breadcrumbHandler?: ComponentType<any>;
  infoAlertHandler?: ComponentType<any>;
  requireData?: (() => any)[];
  poll?: {
    skipFirst: boolean;
    action: () => any;
  };
  childRoutes?: RouteType[];
};

const getDisabledRoutes = (): string[] => {
  let disabledRoutes: string[] = DevBranchesStore.isDevModeActive()
    ? routesDisabledInBranch
    : routesDisabledInProduction;

  if (ApplicationStore.isDemoPreview()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInDemoPreview);
  }

  if (ApplicationStore.hasProtectedDefaultBranch()) {
    disabledRoutes = disabledRoutes.concat(routesDisabledInSox);

    if (!DevBranchesStore.isDevModeActive()) {
      disabledRoutes = disabledRoutes.concat(routesDisabledInSoxProduction);
    }
  }

  return [...new Set(disabledRoutes)];
};

const redirectLoader = (to: string, query?: Record<string, string>) => {
  return ({ request }: { request: Request }) => {
    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url.search);

    if (searchParams.has('target') && isValidTarget(searchParams.get('target'))) {
      const { name, params } = mapTargetToRoute(searchParams.get('target'))!;
      searchParams.delete('target');
      return replace(createUrl(name, params, searchParams));
    }

    return replace(`/${to}${query ? getQueryString(query) : url.search}`);
  };
};

const isPathnameDisabled = (paths: string[], pathname: string) => {
  return paths.some((path) => pathname === `/${path}` || pathname.startsWith(`/${path}/`));
};

export const createReactRouterRoutes = (
  rootRoute: RouteType & { path: string },
  basename: string = '',
): JSX.Element => {
  const disabledRoutes = getDisabledRoutes();

  const getLoader = (route: RouteType, routesTree: RouteType[] = []): LoaderFunction => {
    return ({ request, params }: { request: Request; params: any }) => {
      const url = new URL(request.url);
      const pathname = string.strRight(url.pathname, basename);

      if (
        isPathnameDisabled(disabledRoutes, pathname) ||
        (ApplicationStore.hasInvalidCustomBackend() && route.name !== APP_ROUTE)
      ) {
        return replace('/');
      }

      if (
        ApplicationStore.hasCurrentProjectFeature(FEATURE_SNOWFLAKE_PARTNER_CONNECT_LIMITED) &&
        isPathnameDisabled(routesDisabledInSPCTrial, pathname)
      ) {
        return replace(`/${snowflakePartnerConnectRouteNames.UPGRADE_PAGE}`);
      }

      const location = {
        pathname,
        search: url.search,
        query: qs.parse(new URLSearchParams(url.search).toString()),
        hash: url.hash,
      };

      const routes = [...routesTree, route].map((r) => ({
        path: r.path || r.name,
        name: r.name,
      }));

      const rawRouteState = { location, params, routes };
      useRawRouterStateStore.getState().setRawRouterState(rawRouteState);
      return loadRequiredData(rawRouteState);
    };
  };

  const composeRoutes = (route: RouteType, routes: RouteType[] = []): JSX.Element => {
    const path = route.path || route.name;

    if (!route.childRoutes || route.childRoutes.length === 0) {
      return (
        <Route
          key={route.name}
          path={path}
          Component={route.defaultRouteHandler}
          loader={getLoader(route, routes)}
          handle={{ name: route.name }}
        />
      );
    }

    return (
      <Route key={route.name} path={path} handle={{ name: route.name }}>
        <Route index Component={route.defaultRouteHandler} loader={getLoader(route, routes)} />
        {route.childRoutes.map((childRoute: RouteType) =>
          composeRoutes(childRoute, [...routes, route]),
        )}
      </Route>
    );
  };

  return (
    <Route Component={App} hydrateFallbackElement={<Loading />} errorElement={<ErrorPage />}>
      <Route index loader={redirectLoader(rootRoute.path)} Component={Loading} />
      <Route
        path={rootRoute.path}
        Component={rootRoute.defaultRouteHandler}
        loader={getLoader(rootRoute)}
        handle={{ name: rootRoute.name }}
      />

      {getRedirects()}

      {rootRoute.childRoutes?.map((route: RouteType) => composeRoutes(route))}

      <Route
        path="*"
        element={<ErrorPage title="Page not found" />}
        loader={getLoader({ name: 'notFound', path: '*' } as RouteType)}
      />
    </Route>
  );
};

const getRedirects = (): ReactElement[] => [...getStorageRedirects(), ...getExGenericRedirects()];
