import type { ReactElement } from 'react';
import { replace, Route } from 'react-router';

import { EX_GENERIC_V_2 as componentId, EX_GENERIC_V_2_DETAIL } from '@/constants/componentIds';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames } from '@/modules/components-directory/constants';
import { MENUS } from '@/modules/ex-generic/constants';
import { configPoll } from '@/utils/genericPolls';
import { configRequiredData } from '@/utils/genericRequiredData';
import Detail from './Detail';
import Header from './Header';

export const exGenericRoutes = {
  name: componentId,
  path: `${componentId}/:config/menu`,
  defaultRouteHandler: Detail,
  headerButtonsHandler: Header,
  requireData: [
    (params: { config: string; menu?: string }) => {
      if (
        params.menu &&
        params.menu !== MENUS.BASE &&
        InstalledComponentsStore.getIsConfigDataLoaded(componentId, params.config)
      ) {
        return;
      }

      return configRequiredData(componentId, params.config);
    },
  ],
  poll: configPoll(componentId, null, { skipJobs: true }),
  childRoutes: [
    {
      name: EX_GENERIC_V_2_DETAIL,
      path: ':menu',
      defaultRouteHandler: Detail,
      headerButtonsHandler: Header,
      poll: configPoll(componentId, null, { skipJobs: true }),
    },
  ],
};

export const getExGenericRedirects = (): ReactElement[] => [
  <Route
    key={`${componentId}-missing-menu-redirect`}
    path={`${routeNames.ROOT}/${componentId}/:config`}
    loader={({ params: { config } }) =>
      replace(`/${routeNames.ROOT}/${componentId}/${config}/menu`)
    }
  />,
];
