import type { ComponentProps, ReactNode } from 'react';
import { ErrorBoundary } from '@sentry/react';

import { ErrorBoundaryFallback } from '@/react/common/ErrorBoundaryFallback';

export const ErrorBoundaryWithNotification = ({
  children,
  fallback = () => <ErrorBoundaryFallback />,
}: {
  children: ReactNode;
  fallback?: ComponentProps<typeof ErrorBoundary>['fallback'];
}) => {
  return (
    <ErrorBoundary key={window.location.pathname} fallback={fallback}>
      {children}
    </ErrorBoundary>
  );
};
