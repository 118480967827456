import { useState } from 'react';
import type { List } from 'immutable';
import { Map } from 'immutable';

import { Button, ButtonGroup, Icon } from '@keboola/design';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { isInternal } from '@/constants/helpers';
import { routeNames } from '@/modules/settings/constants';
import { deleteToken, refreshToken, sendToken } from '@/modules/tokens/actionCreators';
import { RefreshTokenModal } from '@/modules/tokens/react/modals/RefreshTokenModal';
import { SendTokenModal } from '@/modules/tokens/react/modals/SendTokenModal';
import TokensStore from '@/modules/tokens/StorageTokensStore';
import Confirm from '@/react/common/Confirm';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

export const TokenHeaderButtons = () => {
  const [sendModal, setSendModal] = useState(false);
  const [refreshModal, setRefreshModal] = useState(false);

  const store = useStores(
    () => {
      const tokenId = RoutesStore.getCurrentRouteParam('tokenId');
      const token =
        (TokensStore.getAll() as List<any>).find((t) => Boolean(t && t.get('id') === tokenId)) ||
        Map({});

      return {
        tokenId,
        token,
        description: token.get('description'),
        isMaster: token.get('isMasterToken', false),
        isDeleting: TokensStore.isDeletingToken(tokenId),
        isSending: TokensStore.isSendingToken(tokenId),
        isRefreshing: TokensStore.isRefreshingToken(tokenId),
        sapiToken: ApplicationStore.getSapiToken(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
      };
    },
    [],
    [TokensStore, ApplicationStore],
  );

  if (!store.token.count()) {
    return null;
  }

  const handleSendToken = (params: { email: string }) => {
    return sendToken(store.tokenId, params).then(() =>
      ApplicationActionCreators.sendNotification({
        type: 'success',
        message: () => {
          return (
            <>
              Token <b>{store.description}</b> sent to {params.email}.
            </>
          );
        },
      }),
    );
  };

  const handleDeleteToken = () => {
    const description = store.description;

    return deleteToken(store.token).then(() => {
      RoutesStore.getRouter().transitionTo(routeNames.TOKENS);

      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: () => {
          return (
            <>
              Token <b>{description}</b> has been removed.
            </>
          );
        },
      });
    });
  };

  return (
    <>
      <ButtonGroup>
        {!store.isMaster && (
          <Button onClick={() => setSendModal(true)} variant="outline">
            <Icon icon="share" />
            Send via email
          </Button>
        )}
        {!isInternal(store.token.get('description')) && (
          <>
            <Button onClick={() => setRefreshModal(true)} variant="outline">
              <Icon icon="arrows-rotate" />
              Refresh
            </Button>
            {!store.token.has('admin') && (
              <Confirm
                icon="trash"
                title="Delete Token"
                text={`Are you sure you want to delete the token ${store.description} (${store.tokenId})?`}
                buttonLabel="Delete"
                onConfirm={handleDeleteToken}
                isLoading={store.isDeleting}
                closeAfterResolve
              >
                <Button variant="danger">
                  <Icon icon="trash" />
                  Delete
                </Button>
              </Confirm>
            )}
          </>
        )}
      </ButtonGroup>

      <RefreshTokenModal
        token={store.token}
        show={refreshModal}
        onHideFn={() => setRefreshModal(false)}
        onRefreshFn={() => refreshToken(store.token)}
        isRefreshing={!!store.isRefreshing}
        sapiToken={store.sapiToken}
        hasNewQueue={store.hasNewQueue}
      />
      <SendTokenModal
        token={store.token}
        show={sendModal}
        onHideFn={() => setSendModal(false)}
        onSendFn={handleSendToken}
        isSending={!!store.isSending}
      />
    </>
  );
};
