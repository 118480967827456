import { Button } from '@keboola/design';

const IntentConfirmation = ({
  onClick,
  isDisabled,
}: {
  onClick: (confirm?: string) => void;
  isDisabled?: boolean;
}) => {
  return (
    <div className="tw-flex tw-flex-wrap tw-gap-2">
      <Button onClick={() => onClick('yes')} disabled={isDisabled}>
        Accept
      </Button>
      <Button variant="outline" onClick={() => onClick('no')} disabled={isDisabled}>
        Continue chat
      </Button>
    </div>
  );
};

export default IntentConfirmation;
