import { useState } from 'react';
import { List, Map } from 'immutable';

import { ButtonGroup, Icon } from '@keboola/design';

import { EX_GENERIC_V_2 as componentId, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ProcessTimeoutModal from '@/modules/components/react/components/ProcessTimeoutModal';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import InstalledComponentsStore from '@/modules/components/stores/InstalledComponentsStore';
import { routeNames as componentRouteNames } from '@/modules/components-directory/constants';
import ScheduleConfigurationButton from '@/modules/scheduler/components/ScheduleConfigurationButton';
import LinkMenuItem from '@/react/admin/project/LinkMenuItem';
import { RowActionDropdown, RowActionMenuItem } from '@/react/common';
import CopyButton from '@/react/common/ConfigurationsTable/CopyButton';
import DeleteConfigurationButton from '@/react/common/DeleteConfigurationButton';
import DescriptionButton from '@/react/common/DescriptionButton';
import DescriptionModal from '@/react/common/DescriptionModal';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';

type Props = {
  descriptionIconOnly?: boolean;
};

const Header = ({ descriptionIconOnly = false }: Props) => {
  const store = useStores(
    () => {
      const configId = RoutesStore.getCurrentRouteParam('config');
      const config = InstalledComponentsStore.getConfig(componentId, configId);

      return {
        config,
        configId,
        readOnly: ApplicationStore.isReadOnly(),
        component: ComponentsStore.getComponent(componentId),
        configData: config.get('configuration', Map()),
        hasFlows: ApplicationStore.hasFlows(),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        flows: InstalledComponentsStore.getComponentConfigurations(KEBOOLA_ORCHESTRATOR),
        currentAdmin: ApplicationStore.getCurrentAdmin(),
      };
    },
    [],
    [ApplicationStore, RoutesStore, ComponentsStore, InstalledComponentsStore],
  );
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);

  return (
    <>
      <ButtonGroup>
        <DescriptionButton
          showsInModalOnly
          readOnly={store.readOnly}
          isFilled={!!store.config.get('description')}
          iconOnly={descriptionIconOnly}
          onClick={() => setShowDescriptionModal(true)}
        />
        <RunComponentButton
          label="Run component"
          buttonIcon="circle-play"
          buttonBsStyle="success"
          componentId={componentId}
          runParams={() => ({ config: store.configId })}
          disabled={store.configData.getIn(['parameters', 'config', 'jobs'], List()).isEmpty()}
          disabledReason="At least one endpoint must be configured."
        >
          You are about to run the component.
        </RunComponentButton>
        <RowActionDropdown inHeader>
          <ProcessTimeoutModal
            mode="menuitem"
            configuration={store.config}
            component={store.component}
            readOnly={store.readOnly}
          />
          {!store.readOnly && (
            <CopyButton
              hasFlows={store.hasFlows}
              configuration={store.config}
              component={store.component}
            />
          )}
          {!store.readOnly && (
            <ScheduleConfigurationButton
              mode="menuitem"
              flows={store.flows}
              component={store.component}
              config={store.config}
              hasFlows={store.hasFlows}
              hasNewQueue={store.hasNewQueue}
            />
          )}
          <LinkMenuItem
            to={componentRouteNames.GENERIC_CONFIG_RAW}
            params={{ component: componentId, config: store.configId }}
          >
            <Icon icon="bug" className="icon-addon-right" fixedWidth />
            Debug mode
          </LinkMenuItem>
          {!store.readOnly && <RowActionMenuItem divider />}
          {!store.readOnly && (
            <DeleteConfigurationButton
              forceTransition
              mode="menuitem"
              componentId={componentId}
              flows={store.flows}
              config={store.config}
            />
          )}
        </RowActionDropdown>
      </ButtonGroup>
      <DescriptionModal
        entity="generic"
        readOnly={store.readOnly}
        show={showDescriptionModal}
        description={store.config.get('description')}
        componentId={componentId}
        configId={store.configId}
        onSave={(description) => {
          return InstalledComponentsActionCreators.updateComponentConfiguration(
            componentId,
            store.configId,
            { description },
            'Update description',
            'description',
          );
        }}
        onHide={() => setShowDescriptionModal(false)}
      />
    </>
  );
};

export default Header;
