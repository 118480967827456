import type { List, Map } from 'immutable';

import { EXCLUDE_FROM_NEW_LIST } from '@/constants/componentFlags';
import { KEBOOLA_ORCHESTRATOR, KEBOOLA_SHARED_CODE, ORCHESTRATOR } from '@/constants/componentIds';
import ComponentName from '@/react/common/ComponentName';
import MarkedText from '@/react/common/MarkedText';
import Select from '@/react/common/Select';
import { matchByWords } from '@/utils';

type Props = {
  disabled: boolean;
  onChange: (value: List<any>) => void;
  selectedComponents: List<any>;
  allComponents: Map<string, any>;
  hasNewQueue: boolean;
};

export const ComponentsSelector = (props: Props) => {
  const options = props.allComponents
    .filter((component) => {
      if (
        [props.hasNewQueue ? KEBOOLA_ORCHESTRATOR : ORCHESTRATOR, KEBOOLA_SHARED_CODE].includes(
          component.get('id'),
        )
      ) {
        return true;
      }

      return !component.get('flags').includes(EXCLUDE_FROM_NEW_LIST);
    })
    .sortBy((component) => component.get('name'))
    .map((component, componentId) => ({
      label: (inputString: string) => (
        <ComponentName component={component} capitalize showType>
          {(componentName) => <MarkedText source={componentName} mark={inputString} />}
        </ComponentName>
      ),
      value: componentId,
      componentName: component.get('name'),
    }))
    .toArray();

  return (
    <Select
      multi
      allowCreate
      placeholder="Select component..."
      disabled={props.disabled}
      value={props.selectedComponents}
      onChange={props.onChange}
      options={options}
      filterOption={(option, filter) => matchByWords([option.value, option.componentName], filter)}
    />
  );
};
