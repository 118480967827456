import type { Map } from 'immutable';

import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { RouterLink } from '@/react/common';

const tableHasBeenRestoredNotification = (table: Map<string, any>) => {
  return ({ onClick }: { onClick: () => void }) => (
    <span>
      Table{' '}
      <RouterLink
        to={routeNames.TABLE}
        params={{
          bucketId: table.getIn(['bucket', 'id']),
          bucketTab: bucketTabs.OVERVIEW,
          tableName: table.get('name'),
          tableTab: tableTabs.OVERVIEW,
        }}
        onClick={onClick}
      >
        {table.get('displayName')}
      </RouterLink>{' '}
      has been created.
    </span>
  );
};

export default tableHasBeenRestoredNotification;
