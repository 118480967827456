import { useState } from 'react';

import { cn } from '@keboola/design';

import InlineEditTextInput from '@/react/common/InlineEditTextInput';

export const InlineEdit = ({
  name,
  onChange,
  placeholder = 'Set name',
  className = '',
}: {
  name: string;
  onChange: (name: string) => void;
  placeholder?: string;
  className?: string;
}) => {
  const [newName, setNewName] = useState<string | null>(null);

  return (
    <h3 className={cn('tw-my-0 tw-text-sm', className)}>
      <InlineEditTextInput
        isEditing={newName !== null}
        placeholder={placeholder}
        isValid={!!(newName ?? name).trim()}
        isChanged={newName !== name}
        text={newName ?? name}
        onEditChange={setNewName}
        onEditStart={() => setNewName(name)}
        onEditCancel={() => setNewName(null)}
        onEditSubmit={() => {
          if (newName) {
            onChange(newName.trim());
          }
          setNewName(null);
        }}
      />
    </h3>
  );
};
