import { useState } from 'react';
import type { ComponentProps } from 'react';
import type Promise from 'bluebird';

import type { Icon } from '@keboola/design';
import { cn, IconButton, Tooltip } from '@keboola/design';

import { getTransformationBackendSizes } from '@/modules/components/helpers';
import string from '@/utils/string';
import Loader from './Loader';

type Props = {
  componentId: string;
  onSelect: (value: string) => Promise<void>;
  currentSize?: string;
  className?: string;
  iconSize?: ComponentProps<typeof Icon>['size'];
  disabled?: boolean;
  showNonSqlOptionDisabled?: boolean;
};

const BackendSizeButtons = ({
  componentId,
  onSelect,
  currentSize,
  disabled,
  showNonSqlOptionDisabled,
}: Props) => {
  const [isSaving, setIsSaving] = useState(false);
  const sizes = getTransformationBackendSizes(componentId);
  const selectedIndex = currentSize ? sizes.indexOf(currentSize) : -1;

  return (
    <div className="backend-size-buttons tw-flex tw-gap-1">
      {isSaving && <Loader className="mr-1" />}
      {sizes.map((value: string, index: number) => {
        const isSelected = value === currentSize;
        const disabledForSql = componentId === 'common' && showNonSqlOptionDisabled && index === 0;

        return (
          <Tooltip
            key={value}
            tooltip={
              disabledForSql
                ? 'Xsmall backend size is available only for non-SQL transformations.'
                : string.capitalize(value)
            }
            placement="top"
            type="explanatory"
          >
            <IconButton
              className={cn({ active: index === selectedIndex }, '[&_svg]:tw-text-secondary-200')}
              variant="inline"
              icon="rocket"
              onClick={() => {
                if (disabled || isSaving || isSelected) return;
                setIsSaving(true);
                onSelect(value).then(() => setIsSaving(false));
              }}
              disabled={disabled || disabledForSql}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

export default BackendSizeButtons;
