import type { KeyboardEvent, MouseEvent } from 'react';
import { flexRender } from '@tanstack/react-table';
import type { Map } from 'immutable';

import { cn } from '@keboola/design';

import { resolveRouterLinkParams } from '@/modules/components/helpers';
import RoutesStore from '@/stores/RoutesStore';
import hasSelections from '@/utils/hasSelections';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import {
  shouldUseNewWindow,
  simulateClickIfMiddleMouseIsUsed,
  windowOpen,
} from '@/utils/windowOpen';
import type { ConfigRow } from './constants';
import { getRealComponentId, onTableRowKeyDown } from './helpers';

const ConfigurationRow = (props: {
  row: ConfigRow;
  customClasses: Map<string, any>;
  inFolder: boolean;
  readOnly: boolean;
  hasFlows: boolean;
}) => {
  const componentId = getRealComponentId(
    props.row.original.data.config,
    props.row.original.data.component,
  );
  const configId = props.row.original.data.config.get('id');
  const isCreating = props.row.original.data.config.get('isCreating');
  const isDummyItem = props.row.original.data.config.get('isDummyItem');
  let actionProps = {};

  if (!isCreating && !isDummyItem) {
    const linkParams = resolveRouterLinkParams(componentId, configId, null, props.hasFlows);
    const rowAction = (e?: MouseEvent | KeyboardEvent) => {
      if (!linkParams || hasSelections()) {
        return;
      }

      if (shouldUseNewWindow(e)) {
        return windowOpen(createUrlWithBasename(linkParams.to, linkParams.params));
      }

      return RoutesStore.getRouter().transitionTo(linkParams.to, linkParams.params);
    };

    actionProps = {
      onClick: rowAction,
      onMouseDown: simulateClickIfMiddleMouseIsUsed.mousedown,
      onMouseUp: simulateClickIfMiddleMouseIsUsed.mouseup,
      onKeyDown: onTableRowKeyDown(rowAction),
    };
  }

  const isClickable = !isCreating && !isDummyItem;

  return (
    <div
      className={cn('tr hoverable-actions-with-replacement tw-group', { clickable: isClickable })}
      {...(isClickable && { tabIndex: 0, role: 'button', ...actionProps })}
    >
      {props.row.getVisibleCells().map((cell) => {
        return (
          <div
            key={cell.id}
            className={cn('td', props.customClasses.getIn([cell.column.id, 'td']), {
              'tw-w-32 tw-text-right': cell.column.id === 'used_in',
              'text-muted tw-w-32 tw-text-right': cell.column.id === 'storage_data',
              'tw-w-44 tw-text-right': cell.column.id === 'last_change',
              'tw-w-52 tw-text-right': cell.column.id === 'job',
              'tw-pl-6': cell.column.id === 'data' && props.inFolder,
              'bg-selected': props.row.getIsSelected(),
            })}
          >
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              inFolder: props.inFolder,
            })}
          </div>
        );
      })}
    </div>
  );
};

export default ConfigurationRow;
