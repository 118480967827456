import { Map } from 'immutable';

import { sortEntities } from '@/constants';
import dispatcher from '@/Dispatcher';
import StoreUtils, { initStore } from '@/utils/StoreUtils';
import { actionTypes } from './constants';

type Store = {
  searchQuery: string | null;
  searchFilters: Map<string, any> | null;
  contextFilter: boolean;
  expandedBuckets: Map<string, any>;
  bucketsSort: Map<string, any>;
  showAllBuckets: boolean;
};

const defaultState: Store = {
  searchQuery: null,
  searchFilters: null,
  contextFilter: true,
  expandedBuckets: Map(),
  bucketsSort: Map({ entity: sortEntities.NAME, order: 1 }),
  showAllBuckets: false,
};

let _store = initStore('StorageStore', Map(defaultState));

const StorageStore = StoreUtils.createStore({
  getSearchQuery() {
    return _store.get('searchQuery', '');
  },

  getSearchFilters() {
    return _store.get('searchFilters', null);
  },

  getContextFilter() {
    return _store.get('contextFilter', true);
  },

  getExpandedBuckets() {
    return _store.get('expandedBuckets', Map());
  },

  getShowAllBuckets() {
    return _store.get('showAllBuckets', false);
  },

  getBucketsSort() {
    return _store.get('bucketsSort', Map());
  },
});

dispatcher.register(({ action }) => {
  switch (action.type) {
    case actionTypes.UPDATE_SEARCH_QUERY:
      _store = _store.set('searchQuery', action.query);
      return StorageStore.emitChange();

    case actionTypes.UPDATE_SEARCH_FILTERS:
      _store = _store.set('searchFilters', action.searchFilters);
      return StorageStore.emitChange();

    case actionTypes.TOGGLE_CONTEXT_FILTER:
      _store = _store.set('contextFilter', !_store.get('contextFilter'));
      return StorageStore.emitChange();

    case actionTypes.TOGGLE_EXPAND_BUCKET:
      if (action.isExpanded) {
        _store = _store.setIn(['expandedBuckets', action.bucketId], true);
      } else {
        _store = _store.deleteIn(['expandedBuckets', action.bucketId]);
      }
      return StorageStore.emitChange();

    case actionTypes.TOGGLE_SHOW_ALL_BUCKETS:
      _store = _store.set('showAllBuckets', action.show);
      return StorageStore.emitChange();

    case actionTypes.UPDATE_BUCKETS_SORT:
      _store = _store.set('bucketsSort', Map(action.sort));
      return StorageStore.emitChange();

    default:
      break;
  }
});

export default StorageStore;
