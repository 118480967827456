import type { ReactNode } from 'react';
import _ from 'underscore';

import { Link } from '@keboola/design';

import { bucketTabs, routeNames as storageRoutes } from '@/modules/storage/constants';
import ApplicationStore from '@/stores/ApplicationStore';
import { createUrl } from '@/utils/router/createUrl';
import string from '@/utils/string';

export const ExternalBucketLink = ({
  children,
  stackUrl,
  projectId,
  bucketId,
}: {
  children: ReactNode;
  stackUrl?: string;
  projectId?: number;
  bucketId?: string;
}) => {
  if (!stackUrl || !projectId || !bucketId) {
    return children;
  }

  const projectPath = _.template(ApplicationStore.getProjectUrlTemplate())({ projectId });

  const bucketUrl = createUrl(storageRoutes.BUCKET, { bucketId, bucketTab: bucketTabs.OVERVIEW });

  return <Link href={`${string.rtrim(stackUrl, '/')}${projectPath}${bucketUrl}`}>{children}</Link>;
};
