let _baseUrl: string = '';
let _authHeaders: Record<string, string> = {};
let _fetch: typeof window.fetch = window.fetch;
export function init(baseUrl: string, storageApiToken: string, fetch: typeof window.fetch) {
  _baseUrl = baseUrl;
  _authHeaders = { 'X-StorageApi-Token': storageApiToken };
  _fetch = fetch;
}
export type Runtime = {
  id: number;
  description: string;
  type: 'julia' | 'python' | 'python-databricks' | 'r' | 'test';
  imageName: string;
  imageTag: string;
  releaseDate: string;
  endOfLifeDate?: string | undefined;
  isTypeDefault?: boolean | undefined;
  transformationComponentId?: string | undefined;
  transformationComponentTag?: string | undefined;
  sandboxImageName?: string | undefined;
  sandboxImageTag?: string | undefined;
};
export type ExistingApp = {
  id: string;
  name: string;
  projectId: string;
  componentId: string;
  branchId: boolean;
  configId: string;
  configVersion: string;
  state: 'created' | 'starting' | 'running' | 'stopping' | 'stopped' | 'deleting' | 'deleted';
  desiredState: 'running' | 'stopped' | 'deleted';
  lastRequestTimestamp: string;
  url?: string | undefined;
  autoSuspendAfterSeconds?: number | undefined;
};
/**
 * Checks the service is up & running.
 */
export function healthCheck(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/health-check';
  return _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
}
/**
 * List project apps.
 */
export function listApps(params: {
  offset: number | undefined;
  limit: number | undefined;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps';
  url.search = new URLSearchParams({
    ...(params.offset && { offset: params.offset.toString() }),
    ...(params.limit && { limit: params.limit.toString() }),
  }).toString();
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Create new app
 */
export function createApp(body: {
  type: string;
  branchId: string | null;
  name: string;
  description?: string | undefined;
  config?: any | undefined;
}): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps';
  return _fetch(url.toString(), {
    method: 'post',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Get app detail.
 */
export function getApp(params: { appId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps/{appId}'.replace('{appId}', params.appId);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Delete app
 */
export function deleteApp(params: { appId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps/{appId}'.replace('{appId}', params.appId);
  return _fetch(url.toString(), { method: 'delete', headers: { ..._authHeaders } });
}
/**
 * Update app state.
 */
export function patchApp(
  params: { appId: string },
  body: {
    desiredState?: ('running' | 'stopped') | undefined;
    lastRequestTimestamp?: string | undefined;
    restartIfRunning?: boolean | undefined;
    configVersion?: string | undefined;
  },
): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps/{appId}'.replace('{appId}', params.appId);
  return _fetch(url.toString(), {
    method: 'patch',
    headers: { ..._authHeaders, 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  });
}
/**
 * Returns app password. Currently applicable only for apps with auth type `password`.
 */
export function getAppPassword(params: { appId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps/{appId}/password'.replace('{appId}', params.appId);
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
/**
 * Reset app password.
 */
export function resetAppPassword(params: { appId: string }): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/apps/{appId}/reset-password'.replace('{appId}', params.appId);
  return _fetch(url.toString(), { method: 'post', headers: { ..._authHeaders } });
}

/**
 * List of registered runtimes
 */
export function runtimes(): Promise<Response> {
  const url = new URL(_baseUrl);
  url.pathname = '/runtimes';
  return _fetch(url.toString(), { method: 'get', headers: { ..._authHeaders } });
}
