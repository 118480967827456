import type { ReactNode } from 'react';

import { cn } from '@keboola/design';

import RoutesStore from '@/stores/RoutesStore';
import { createUrl } from '@/utils/router/createUrl';
import { useIsActiveByPathName } from '@/utils/router/hooks/useIsActiveByPathName';
import { RouterLink } from './RouterLink';

type TabLinkProps = {
  to: string;
  children: ReactNode;
  params?: Record<string, any>;
  query?: Record<string, any>;
  hash?: string;
  className?: string;
  disabled?: boolean;
  active?: boolean;
  activeByIndexOnly?: boolean;
};

export const TabLink = ({
  to,
  children,
  active,
  params,
  query,
  hash,
  className,
  disabled,
  activeByIndexOnly = true,
}: TabLinkProps) => {
  const isActiveByPathName = useIsActiveByPathName();
  const resolvedTo = createUrl(to, params);

  return (
    <li
      role="presentation"
      className={cn(className, {
        active: active || isActiveByPathName(resolvedTo, activeByIndexOnly),
        disabled: disabled,
      })}
    >
      <RouterLink
        role="tab"
        to={resolvedTo}
        params={params}
        query={{
          ...query,
          flowId: RoutesStore.getRouterState().getIn(['location', 'query', 'flowId']),
        }}
        hash={hash}
        disabled={disabled}
      >
        {children}
      </RouterLink>
    </li>
  );
};
