import { createCallbackMiddleware, createGenericFetchClient } from '../../fetchClient';
import type { ClientInitOptions } from '../types';

import type { EncryptBody, EncryptQuery } from './types';

export const createEncryptionClient = ({
  baseUrl,
  callbacks,
}: Omit<ClientInitOptions, 'token'>) => {
  const client = createGenericFetchClient({
    baseUrl: baseUrl,
    middlewares: [createCallbackMiddleware(callbacks)],
  });

  const _encrypt = async <T extends string | Record<string, unknown>>(
    data: EncryptQuery & { data: EncryptBody },
    signal?: AbortSignal,
  ) => {
    const response = await client.post<T, void, EncryptQuery, EncryptBody>(
      '/encrypt',
      {
        query: {
          projectId: data.projectId,
          branchType: data.branchType,
          componentId: data.componentId,
        },
        body: data.data,
      },
      { signal },
    );

    return response.data;
  };

  const encrypt = (data: EncryptQuery & { data: string }, signal?: AbortSignal) =>
    _encrypt<string>(data, signal);

  /**
   * A function that encrypts a given data object using
   * Secret is an attribute prefixed with `#`.
   *
   * @example
   * Input data:
   * {
   *    "name": "test",
   *    "#secret": "sensitive-value",
   *    "nested": {
   *    "#password": "abc123"
   * }
   *
   * Return:
   * {
   *    "name": "test",
   *    "#secret": "KBC:ioeiuropqweurioewu==",
   *    "nested": {
   *    "#password": "KBC:ioeiuropqweurioewu=="
   * }
   *
   * @returns A promise resolving to the given data object with encrypted secrets.
   */
  const encryptSecrets = <T extends Record<string, unknown>>(
    data: EncryptQuery & { data: T },
    signal?: AbortSignal,
  ) => _encrypt<T>(data, signal);

  return {
    encryptSecrets,
    encrypt,
  };
};
