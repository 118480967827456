import type { Map } from 'immutable';

import { ButtonInline, cn, FormGroup } from '@keboola/design';

import { JOB_FINISHED_STATUSES, JOBS_STATUS, routeNames } from '@/modules/queue/constants';
import { createUrlWithBasename } from '@/utils/router/createUrl';
import string from '@/utils/string';
import { windowOpen } from '@/utils/windowOpen';

type Props = {
  task: Map<string, any>;
  lastJob?: Map<string, any>;
};

const LastRun = ({ task, lastJob }: Props) => {
  return (
    !task.get('invalid') &&
    task.get('enabled', true) &&
    lastJob &&
    JOB_FINISHED_STATUSES.includes(lastJob.get('status')) && (
      <FormGroup className="tw-flex tw-flex-row tw-justify-between">
        <p className="tw-m-0 tw-text-sm tw-font-medium tw-text-neutral-400">Last Run</p>
        <ButtonInline
          onClick={() => {
            const href = createUrlWithBasename(routeNames.JOB_DETAIL, {
              jobId: lastJob.get('jobId'),
            });
            windowOpen(href);
          }}
          className={cn({
            'color-success': lastJob.get('status') === JOBS_STATUS.SUCCESS,
            'color-danger': lastJob.get('status') === JOBS_STATUS.ERROR,
            'color-muted': ![JOBS_STATUS.ERROR, JOBS_STATUS.SUCCESS].includes(
              lastJob.get('status'),
            ),
          })}
        >
          {lastJob.get('status') === JOBS_STATUS.ERROR
            ? 'Debug Error'
            : string.capitalize(lastJob.get('status'))}
        </ButtonInline>
      </FormGroup>
    )
  );
};

export default LastRun;
