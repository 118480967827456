import { startTransition, useEffect, useMemo, useState } from 'react';
import { HotKeys } from 'react-hotkeys-ce';
import type { Map } from 'immutable';

import { cn, CodeEditor, IconButton, Tooltip } from '@keboola/design';

import fromJSOrdered from '@/utils/fromJSOrdered';
import { isValidJsonConfig } from '@/utils/validation';
import JSONSchemaEditor from './JSONSchemaEditor';

export const JSONSchemaEditorEditable = ({
  component,
  schema,
  value,
  onChange,
  readOnly,
}: {
  component: Map<string, any>;
  schema: Map<string, any>;
  value: Map<string, any>;
  onChange: (value: Map<string, any>) => void;
  readOnly: boolean;
}) => {
  const [showEditor, setShowEditor] = useState<boolean>(false);

  const [jsonSchema, setJsonSchema] = useState(schema);
  const [editorValue, setEditorValue] = useState(JSON.stringify(jsonSchema, null, 2));

  const [editorSide, setEditorSide] = useState<'left' | 'right'>('right');
  const [sidebarWidth, setSidebarWidth] = useState<number>(33);
  const [isResizing, setIsResizing] = useState(false);

  useEffect(() => {
    if (!isResizing) {
      return;
    }

    const handleMouseUp = () => setIsResizing(false);
    const handleMouseMove = (e: MouseEvent) => {
      const windowWidth = window.innerWidth;
      const newWidth =
        editorSide === 'right'
          ? ((windowWidth - e.clientX) / windowWidth) * 100
          : (e.clientX / windowWidth) * 100;

      setSidebarWidth(Math.min(Math.max(newWidth, 15), 80));
    };

    document.addEventListener('mousemove', handleMouseMove, { passive: false });
    document.addEventListener('mouseup', handleMouseUp, { passive: false });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing, editorSide]);

  const keyMap = useMemo(() => {
    return { TOGGLE_EDITOR: ['ctrl+d', 'option+d'] };
  }, []);

  const handlers = useMemo(() => {
    return { TOGGLE_EDITOR: () => setShowEditor((show) => !show) };
  }, []);

  return (
    <HotKeys keyMap={keyMap} handlers={handlers}>
      <JSONSchemaEditor
        component={component}
        schema={jsonSchema}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
      />
      {showEditor && (
        <div
          className={cn(
            'tw-fixed tw-inset-y-0 tw-z-[1000] tw-flex tw-flex-col tw-bg-white tw-px-2 tw-py-4',
            editorSide === 'left' ? 'tw-left-0' : 'tw-right-0',
          )}
          style={{ width: `${sidebarWidth}%` }}
        >
          <div
            className={cn(
              'tw-absolute tw-inset-y-0 tw-w-0.5 tw-bg-neutral-150 tw-cursor-col-resize hover:tw-bg-secondary-300 tw-transition-color',
              {
                'tw-right-0': editorSide === 'left',
                'tw-left-0': editorSide === 'right',
                'tw-bg-secondary-300': isResizing,
              },
            )}
            onMouseDown={() => setIsResizing(true)}
          />
          <CodeEditor
            completeAny
            value={editorValue}
            onChange={(value) => {
              setEditorValue(value);

              startTransition(() => {
                if (isValidJsonConfig(value)) {
                  setJsonSchema(fromJSOrdered(JSON.parse(value)));
                }
              });
            }}
            renderAdditionalButtons={() => {
              return (
                <>
                  <Tooltip
                    placement="bottom"
                    tooltip={editorSide === 'left' ? 'Move to right' : 'Move to left'}
                  >
                    <IconButton
                      size="small"
                      variant="invisible"
                      className="tw-rounded-none"
                      icon={editorSide === 'left' ? 'arrow-right' : 'arrow-left'}
                      onClick={() => {
                        setEditorSide((side) => (side === 'left' ? 'right' : 'left'));
                      }}
                    />
                  </Tooltip>
                  <Tooltip placement="bottom" tooltip="Close editor">
                    <IconButton
                      size="small"
                      variant="invisible"
                      className="tw-rounded-none"
                      icon="close"
                      onClick={() => setShowEditor(false)}
                    />
                  </Tooltip>
                </>
              );
            }}
          />
        </div>
      )}
    </HotKeys>
  );
};
