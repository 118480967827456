import { bucketTabs, routeNames, tableTabs } from '@/modules/storage/constants';
import { RouterLink } from '@/react/common';

const tableAliasCreatedNotification = (table: Record<string, any>) => {
  return (props: { onClick: () => void }) => {
    return (
      <>
        Table alias{' '}
        <RouterLink
          to={routeNames.TABLE}
          params={{
            bucketId: table.bucket.id,
            bucketTab: bucketTabs.OVERVIEW,
            tableName: table.name,
            tableTab: tableTabs.OVERVIEW,
          }}
          onClick={props.onClick}
        >
          {table.displayName}
        </RouterLink>{' '}
        has been created.
      </>
    );
  };
};

export default tableAliasCreatedNotification;
