import { Component } from 'react';
import type { FormEvent } from 'react';
import { ControlLabel, FormGroup, Modal, Radio } from 'react-bootstrap';
import type { List, Map } from 'immutable';

import type { File } from '@keboola/api-client';
import { Alert, ButtonInline, Icon, Tooltip } from '@keboola/design';

import { apiClient } from '@/apiClient';
import { EXPORT_TYPES } from '@/modules/storage/constants';
import { downloadFile } from '@/modules/storage/helpers';
import type { ExportTypes } from '@/modules/storage/types';
import { CreatedDate, FileSize } from '@/react/common';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import { byteConverter } from '@/react/common/helpers';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';
import contactSupport from '@/utils/contactSupport';
import string from '@/utils/string';

const EXPORT_OPTIONS = {
  [EXPORT_TYPES.GZIP]: 'GZIP (CSV)',
  [EXPORT_TYPES.CSV]: 'CSV',
  [EXPORT_TYPES.XLSX]: 'XLSX',
} as const;

const SIZE_LIMITS = {
  [EXPORT_TYPES.GZIP]: 107374182400, // 100 GB
  [EXPORT_TYPES.CSV]: 107374182400, // 100 GB
  [EXPORT_TYPES.XLSX]: 262435456, // 250 MB
};

type Props = {
  show: boolean;
  tables: List<Map<string, any>>;
  onSubmit: (type: ExportTypes) => void;
  onHide: () => void;
};

class ExportModal extends Component<Props> {
  state = {
    isLoading: false,
    exportAs: EXPORT_TYPES.GZIP,
    showAllExports: false,
    exports: [] as File[],
  };

  render() {
    const size = this.getTablesSize();
    const tooBig = size > SIZE_LIMITS[this.state.exportAs];

    return (
      <Modal
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => {
          this.setState({ isLoading: false, exports: [] });

          if (this.props.tables.count() === 1) {
            this.loadPreviousExports();
          }
        }}
      >
        <form
          onSubmit={(event: FormEvent<HTMLFormElement>) => {
            event.preventDefault();

            this.props.onSubmit(this.state.exportAs);
            this.props.onHide();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Export {string.pluralize(this.props.tables.count(), 'table')}</Modal.Title>
            <ModalIcon color="green" icon="down-to-line" bold />
          </Modal.Header>
          <Modal.Body>
            {tooBig ? (
              <Alert variant="warning" className="tw-mb-5">
                The file (<FileSize size={size} />) is too large to be exported to CSV.{' '}
                <ButtonInline onClick={() => contactSupport()}>contact support</ButtonInline> if you
                need further assistance.
              </Alert>
            ) : (
              <>
                <p>
                  You can track the export progress in the Jobs section. Once the export is
                  finished, the output is saved in Storage and you can download it.
                </p>
                {this.props.tables.count() === 1 && (
                  <>
                    {this.renderExportAs(size)}
                    {this.renderExports()}
                  </>
                )}
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <ConfirmButtons block saveButtonType="submit" saveLabel="Export" isDisabled={tooBig} />
          </Modal.Footer>
        </form>
      </Modal>
    );
  }

  renderExportAs(size: number) {
    return (
      <FormGroup className="flex-container flex-start mtp-6">
        <ControlLabel className="mr-1 mb-0 font-medium">File Format:</ControlLabel>
        {(Object.entries(EXPORT_OPTIONS) as [ExportTypes, string][]).map(([type, label]) => {
          const tooBig = size > SIZE_LIMITS[type];

          return (
            <Tooltip
              key={type}
              placement="top"
              type="explanatory"
              tooltip={`File size ${byteConverter(size)} is too big for ${type} format.`}
              forceHide={!tooBig}
            >
              <Radio
                inline
                className="mrp-3"
                name="exportType"
                onChange={() => this.setState({ exportAs: type })}
                checked={this.state.exportAs === type}
                disabled={tooBig}
              >
                {label}
              </Radio>
            </Tooltip>
          );
        })}
      </FormGroup>
    );
  }

  renderExports() {
    if (this.state.isLoading) {
      return (
        <p className="mtp-6">
          <Loader className="icon-addon-right" />
          Searching for exports in the last 14 days...
        </p>
      );
    }

    if (this.state.exports.length === 0) {
      return <p className="mtp-6">No recent export found. Please create a new one.</p>;
    }

    const exports = this.state.showAllExports ? this.state.exports : this.state.exports.slice(0, 5);

    return (
      <>
        <p className="mtp-6">
          The table was already exported in the last 14 days. You can download these versions
          without waiting:
        </p>
        <div className="table table-hover in-modal condensed mb-1">
          <div className="thead">
            <div className="tr">
              <div className="th">Export Date and Time</div>
              <div className="th">File Format</div>
            </div>
          </div>
          <div className="tbody">
            {exports.map((file) => {
              return (
                <Tooltip
                  placement="top"
                  tooltip="Download table"
                  key={file.id}
                  triggerClassName="tr clickable"
                  triggerOnClick={() => {
                    this.props.onHide();
                    downloadFile(file.url, file.name);
                  }}
                >
                  <div className="td">
                    <Icon icon={['far', 'arrow-down-to-line']} className="f-16 icon-addon-right" />
                    <CreatedDate className="color-dark" createdTime={file.created} />
                  </div>
                  <div className="td color-dark">{this.renderFormat(file)}</div>
                </Tooltip>
              );
            })}
            {this.state.exports.length > exports.length && (
              <div
                role="button"
                tabIndex={0}
                onClick={() => this.setState({ showAllExports: true })}
                className="tr no-hover text-muted clickable"
              >
                <div className="td">
                  <span className="underline">Show all exports</span>
                </div>
                <div className="td" />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  renderFormat(file: File) {
    const fileName = file.name;
    const type = fileName
      .split('.')
      .slice(fileName.endsWith('.gz') ? -2 : -1)
      .join('.');

    return type.toUpperCase();
  }

  getTablesSize() {
    return this.props.tables.reduce((sum, table) => {
      return sum + table?.get('dataSizeBytes') || 0;
    }, 0);
  }

  async loadPreviousExports() {
    this.setState({ isLoading: true });

    try {
      const files = await apiClient.storage.files.getFiles({
        q: `name:${this.props.tables.getIn([0, 'id'])} AND tags:storage-merged-export`,
      });

      this.setState({ exports: files });
    } finally {
      this.setState({ isLoading: false });
    }
  }
}

export default ExportModal;
