import { useState } from 'react';
import { Map } from 'immutable';

import { Button, Icon } from '@keboola/design';

import type { PARAM_VALUE } from '@/modules/ex-generic/constants';
import DocumentationLink from './DocumentationLink';
import NewUserData from './NewUserData';
import PropertyRow from './PropertyRow';

const UserData = (props: {
  readOnly: boolean;
  parameters: Map<string, any>;
  onSave: (parameters: Map<string, any>, changeDescription: string) => Promise<any>;
}) => {
  const [show, setShow] = useState(false);
  const [isDeleting, setDeleting] = useState<string | null>(null);
  const [parameter, editParameter] = useState<string | null>(null);

  const userData = props.parameters.getIn(['config', 'userData'], Map());

  const handleDelete = (key: string) => {
    setDeleting(key);
    return props
      .onSave(props.parameters.deleteIn(['config', 'userData', key]), 'Delete user data')
      .finally(() => setDeleting(null));
  };

  const renderBody = () => {
    if (userData.isEmpty()) {
      return (
        <div className="box-content tw-flex tw-flex-col tw-items-center tw-justify-center tw-gap-4 !tw-py-10">
          <p className="tw-mb-0 tw-max-w-xl tw-text-center">
            The option allows you to add arbitrary data to extracted records. Define columns and
            their values, set by constants or functions, to be added to every extracted table of
            parent jobs. <DocumentationLink path="configuration/config/#user-data" format="text" />
          </p>
          {!props.readOnly && (
            <Button variant="outline" onClick={() => setShow(true)}>
              <Icon icon="plus" />
              Create New User Data
            </Button>
          )}
        </div>
      );
    }

    return (
      <div className="tw-px-6 tw-py-5">
        <div className="tw-mb-2 tw-grid tw-grid-cols-3 tw-items-start tw-gap-4">
          <div className="tw-text-xs tw-font-medium tw-uppercase tw-text-neutral-500">Name</div>
          <div className="tw-col-span-2 tw-ml-1.5 tw-text-xs tw-font-medium tw-uppercase tw-text-neutral-500">
            Value
          </div>
        </div>
        <div className="tw-flex tw-flex-col tw-gap-3">
          {userData
            .sortBy((value: PARAM_VALUE, name: string) => name.toLowerCase())
            .map((value: PARAM_VALUE, name: string) => {
              return (
                <PropertyRow
                  key={name}
                  name={name}
                  value={value}
                  readOnly={props.readOnly}
                  isDeleting={isDeleting === name}
                  onEdit={() => {
                    editParameter(name);
                    setShow(true);
                  }}
                  onDelete={() => handleDelete(name)}
                />
              );
            })
            .toArray()}
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="tw-flex tw-items-start tw-justify-between">
        <h2 className="tw-mb-5 tw-mt-1 tw-text-2xl tw-font-normal">User Data</h2>
        {!props.readOnly && !userData.isEmpty() && (
          <Button variant="outline" onClick={() => setShow(true)}>
            <Icon icon="plus" />
            Create User Data
          </Button>
        )}
      </div>
      <div className="box">{renderBody()}</div>
      <NewUserData
        show={show}
        existingParameter={parameter}
        parameters={props.parameters}
        onSave={props.onSave}
        onHide={() => {
          setShow(false);
          editParameter(null);
        }}
      />
    </>
  );
};

export default UserData;
