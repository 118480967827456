import { Component, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import { Map } from 'immutable';
import memoizeOne from 'memoize-one';

import { Badge, cn, Icon, Tooltip } from '@keboola/design';

import { routeNames as transformationRoutes } from '@/modules/transformations-v2/constants';
import { RouterLink, Truncated } from '@/react/common';
import CodeEditorModal from '@/react/common/CodeEditorModal';
import InlineEditTextInput from '@/react/common/InlineEditTextInput';
import SaveButton from '@/react/common/SaveButtonWithDescription';
import { MAX_NAME_LENGTH } from '@/utils/string';
import {
  getTablesHints,
  getVariablesHints,
  prepareScriptsBeforeSave,
  resolveEditorMode,
} from './helpers';

const CodeEditorModalNew = lazy(
  () => import(/* webpackChunkName: "code-editor-modal-2" */ '@/react/common/CodeEditorModalNew'),
);

const prepareAutocompletions = memoizeOne((component, configData, tables, variables) => {
  return [
    ...Object.keys(
      getTablesHints(component.get('id'), configData, tables),
      ...getVariablesHints(variables).map((variable) => variable.name),
    ),
  ];
});

class CodeBlocksEditor extends Component {
  state = {
    changeDescription: '',
    isEditingName: false,
    isSavingName: false,
    isSavingCode: false,
  };

  render() {
    const componentId = this.props.component.get('id');
    if (this.props.hasAiConversations) {
      return (
        <Suspense fallback={null}>
          <CodeEditorModalNew
            withAiGenerator
            title={this.renderTitle()}
            codeDetail={this.props.codeDetail}
            value={this.props.codeDetail.get('scripts')}
            previousValue={this.props.codeDetail.get('originalScripts')}
            originalName={this.props.codeDetail.get('originalName')}
            onChange={this.props.onChangeScript}
            onSave={() => this.handleSaveName().then(this.handleSaveScript)}
            onSaveCode={this.handleSaveScript}
            onClose={this.props.onClose}
            onReset={this.props.onReset}
            onResetCode={this.props.onResetCode}
            isSaving={this.state.isSavingCode}
            isChanged={this.isCodeChanged()}
            onDescriptionChange={(value) => this.setState({ changeDescription: value })}
            changeDescription={this.state.changeDescription}
            readOnly={
              this.props.codeDetail.get('readOnly') ||
              !this.props.codeDetail.get('sharedCode', Map()).isEmpty()
            }
            placeholder="-- Your code goes here"
            componentId={componentId}
            aiConversation={this.props.aiConversation}
            autocompletions={prepareAutocompletions(
              this.props.component,
              this.props.configData,
              this.props.tables,
              this.props.variables,
            )}
            configData={this.props.configData}
            automation={this.props.automation}
          />
        </Suspense>
      );
    }

    return (
      <CodeEditorModal
        withAutocomplete
        editorKey={`${this.props.codeDetail.get('blockIndex')}-${this.props.codeDetail.get(
          'codeIndex',
        )}`}
        title={this.renderTitle()}
        value={this.props.codeDetail.get('scripts')}
        onChange={this.props.onChangeScript}
        onSave={() => this.handleSaveName().then(this.handleSaveScript)}
        onClose={this.props.onClose}
        onReset={this.props.onReset}
        renderAdditionalButtons={this.renderSaveButton}
        isChanged={this.isCodeChanged()}
        codeMirrorOptions={this.getCodemirrorOptions()}
        componentId={componentId}
      />
    );
  }

  renderTitle() {
    return (
      <>
        <span className="code-block-name">{this.props.codeDetail.getIn(['block', 'name'])}</span>
        <Icon
          fixedWidth
          icon={['far', 'angle-right']}
          className="f-14 text-muted icon-addon-left icon-addon-right"
        />
        <span
          className={cn('code-name', {
            'overflow-hidden':
              this.props.codeDetail.get('sharedCode', Map()).isEmpty() &&
              !this.props.codeDetail.get('readOnly'),
          })}
        >
          {this.renderCodeName()}
        </span>
      </>
    );
  }

  renderCodeName() {
    if (!this.props.codeDetail.get('sharedCode', Map()).isEmpty()) {
      return (
        <Tooltip
          placement="bottom"
          type="explanatory"
          tooltip="Shared code is read only."
          triggerClassName="flex-container inline-flex flex-start"
        >
          <RouterLink
            to={transformationRoutes.SHARED_CODE}
            params={{
              config: this.props.codeDetail.getIn(['sharedCode', 'configurationId']),
              row: this.props.codeDetail.getIn(['sharedCode', 'id']),
            }}
          >
            <Truncated twoLines noTooltip text={this.props.codeDetail.get('name')} />
          </RouterLink>
          <Badge text="Shared code" variant="blue" placement="right" />
        </Tooltip>
      );
    }

    if (this.props.codeDetail.get('readOnly')) {
      return <Truncated noTooltip twoLines text={this.props.codeDetail.get('name')} />;
    }

    return (
      <InlineEditTextInput
        placeholder="Enter code name"
        tooltipPlacement="bottom"
        editTooltip="Edit code name"
        isEditing={this.state.isEditingName}
        isChanged={this.isNameChanged()}
        isSaving={this.state.isSavingName}
        maxLength={MAX_NAME_LENGTH}
        text={this.props.codeDetail.get('name')}
        onEditChange={this.props.onChangeName}
        onEditSubmit={this.handleSaveName}
        onEditStart={this.handleStartEditName}
        onEditCancel={this.handleCancelEditName}
      />
    );
  }

  renderSaveButton = () => {
    return (
      <SaveButton
        disabled={this.state.isSavingCode || !this.props.codeDetail.get('originalName').trim()}
        disabledReason={
          !this.props.codeDetail.get('originalName').trim() ? 'Please save code name first' : ''
        }
        isSaving={this.state.isSavingCode}
        isChanged={this.isCodeChanged()}
        onReset={this.props.onResetCode}
        onSave={this.handleSaveScript}
        onDescriptionChange={(value) => this.setState({ changeDescription: value })}
        changeDescription={this.state.changeDescription}
      />
    );
  };

  handleSaveName = () => {
    if (
      this.state.isSavingName ||
      this.props.codeDetail.get('name') === this.props.codeDetail.get('originalName')
    ) {
      return Promise.resolve();
    }

    this.setState({ isSavingName: true });
    return this.props
      .onSaveName(
        this.props.codeDetail.get('blockIndex'),
        this.props.codeDetail.get('codeIndex'),
        this.props.codeDetail.get('name').trim(),
      )
      .finally(() => this.setState({ isSavingName: false, isEditingName: false }));
  };

  handleStartEditName = () => {
    this.setState({ isEditingName: true });
  };

  handleCancelEditName = () => {
    this.setState({ isEditingName: false }, this.props.onResetName);
  };

  handleSaveScript = () => {
    if (this.state.isSavingCode || !this.isCodeChanged()) {
      return Promise.resolve();
    }

    this.setState({ isSavingCode: true });
    return prepareScriptsBeforeSave(
      this.props.component.get('id'),
      this.props.codeDetail.get('scripts'),
    )
      .then((normalizedScripts) => {
        return this.props.onSaveCode(
          this.props.codeDetail.get('blockIndex'),
          this.props.codeDetail.get('codeIndex'),
          this.props.codeDetail.get('originalName'),
          normalizedScripts,
          this.state.changeDescription,
        );
      })
      .then(() => this.setState({ changeDescription: '' }))
      .finally(() => this.setState({ isSavingCode: false }));
  };

  isNameChanged() {
    return this.props.codeDetail.get('name') !== this.props.codeDetail.get('originalName');
  }

  isCodeChanged() {
    return this.props.codeDetail.get('scripts') !== this.props.codeDetail.get('originalScripts');
  }

  getCodemirrorOptions() {
    let codeMirrorOptions = {
      mode: resolveEditorMode(this.props.component.get('id')),
      placeholder: '-- Your code goes here',
    };

    if (
      this.props.codeDetail.get('readOnly') ||
      !this.props.codeDetail.get('sharedCode', Map()).isEmpty()
    ) {
      codeMirrorOptions = {
        ...codeMirrorOptions,
        cursorHeight: 0,
        readOnly: true,
      };
    } else {
      codeMirrorOptions = {
        ...codeMirrorOptions,
        hintOptions: {
          completeSingle: false,
          container: document.querySelector('.full-screen-modal.full-screen-editor'),
          tables: getTablesHints(
            this.props.component.get('id'),
            this.props.configData,
            this.props.tables,
          ),
          variables: getVariablesHints(this.props.variables),
        },
      };
    }

    return codeMirrorOptions;
  }
}

CodeBlocksEditor.propTypes = {
  component: PropTypes.instanceOf(Map).isRequired,
  codeDetail: PropTypes.instanceOf(Map).isRequired,
  configData: PropTypes.instanceOf(Map).isRequired,
  variables: PropTypes.instanceOf(Map).isRequired,
  tables: PropTypes.instanceOf(Map).isRequired,
  onChangeScript: PropTypes.func.isRequired,
  onChangeName: PropTypes.func.isRequired,
  onSaveName: PropTypes.func.isRequired,
  onSaveCode: PropTypes.func.isRequired,
  onResetName: PropTypes.func.isRequired,
  onResetCode: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hasAiConversations: PropTypes.bool.isRequired,
  aiConversation: PropTypes.object,
  automation: PropTypes.object,
};

export default CodeBlocksEditor;
