import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { ClipboardIcon, Icon, Tooltip } from '@keboola/design';

import TransformationType from '@/modules/legacy-transformation/react/components/TransformationType';
import normalizeNewlines from '@/modules/legacy-transformation/utils/normalizeNewlines';
import CodeEditor from '@/react/common/CodeEditor';
import FullScreenEditor from '@/react/common/FullScreenEditor';
import SaveButton from '@/react/common/SaveButtonWithDescription';
import resolveHighlightMode from './resolveHighlightMode';

const Queries = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    transformation: PropTypes.object.isRequired,
    queries: PropTypes.string.isRequired,
    splitQueries: PropTypes.object.isRequired,
    isSaving: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    isQueriesProcessing: PropTypes.bool.isRequired,
    isChanged: PropTypes.bool.isRequired,
    highlightQueryNumber: PropTypes.number,
    changeDescription: PropTypes.string.isRequired,
    onDescriptionChange: PropTypes.func.isRequired,
    onEditCancel: PropTypes.func.isRequired,
    onEditChange: PropTypes.func.isRequired,
    onEditSubmit: PropTypes.func.isRequired,
  },

  getDefaultProps() {
    return {
      disabled: false,
    };
  },

  getInitialState() {
    return {
      showFullScreenEditor: false,
    };
  },

  editor: null,

  componentDidUpdate(previousProps) {
    if (previousProps.highlightQueryNumber !== this.props.highlightQueryNumber) {
      this.highlightQuery();
    }
  },

  render() {
    if (this.state.showFullScreenEditor) {
      return (
        <FullScreenEditor
          className="CodeMirror-search-dialog-root"
          renderTitle={this.renderFullScreenTitle}
          renderEditor={this.renderEditor}
          renderButtons={this.renderSaveButton}
          onClose={() => {
            this.setState({ showFullScreenEditor: false });
          }}
        />
      );
    }

    return (
      <div className="box CodeMirror-search-dialog-root">
        <div className="box-header big-padding with-border">
          <h2 className="box-title">
            Queries
            <ClipboardIcon text={this.props.queries} />
          </h2>
          {this.renderSaveButton()}
        </div>
        <div className="box-content editor">
          {this.renderFullScreenButton()}
          {this.renderEditor()}
        </div>
      </div>
    );
  },

  renderSaveButton() {
    return (
      <div className="flex-container CodeMirror-search-dialog-wrapper tw-gap-4">
        <SaveButton
          isSaving={this.props.isSaving}
          isChanged={this.props.isChanged}
          onReset={this.props.onEditCancel}
          onSave={this.props.onEditSubmit}
          disabled={!!(this.props.isQueriesProcessing || this.props.disabled)}
          onDescriptionChange={this.props.onDescriptionChange}
          changeDescription={this.props.changeDescription}
        />
      </div>
    );
  },

  renderFullScreenTitle() {
    return (
      <>
        <TransformationType
          showLabel={false}
          transformation={this.props.transformation}
          imageClass="icon-addon-right"
          imageSize="32"
        />
        {this.props.transformation.get('name')}
      </>
    );
  },

  renderEditor() {
    return (
      <CodeEditor
        withSearch
        editorDidMount={(editor) => {
          this.editor = editor;

          if (this.props.highlightQueryNumber) {
            this.highlightQuery();
          }
        }}
        value={normalizeNewlines(this.props.queries)}
        onChange={(value) => {
          this.props.onEditChange(normalizeNewlines(value));
        }}
        options={{
          mode: resolveHighlightMode(this.props.transformation.get('backend')),
          readOnly: this.props.isSaving || this.props.disabled,
          placeholder: '-- Your SQL goes here...',
        }}
      />
    );
  },

  renderFullScreenButton() {
    return (
      <Tooltip
        placement="top"
        tooltip="Expand editor to full screen"
        triggerClassName="full-screen-button"
      >
        <Button
          bsStyle="link"
          onClick={() => {
            this.setState({ showFullScreenEditor: true });
          }}
        >
          <Icon icon="up-right-and-down-left-from-center" />
        </Button>
      </Tooltip>
    );
  },

  highlightQuery() {
    const splitQueries = this.props.splitQueries;
    const query = splitQueries.get(this.props.highlightQueryNumber - 1, '');
    const positionStart =
      splitQueries.slice(0, this.props.highlightQueryNumber).join('\n\n').length - query.length;
    if (!this.editor || !query || positionStart === -1) {
      return;
    }
    const lineStart = (this.props.queries.substring(0, positionStart).match(/\n/g) || []).length;
    const positionEnd = positionStart + query.length;
    const lineEnd = (this.props.queries.substring(0, positionEnd).match(/\n/g) || []).length + 1;
    this.editor.setSelection({ line: lineStart, ch: 0 }, { line: lineEnd, ch: 0 });
    const scrollTop = this.editor.cursorCoords({ line: lineStart, ch: 0 }).top - 100;

    setTimeout(() => {
      window.scrollTo(0, scrollTop);
    });
  },
});

export default Queries;
