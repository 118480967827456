import { memo, useCallback, useMemo } from 'react';
import { shallowEqualImmutable } from 'react-immutable-render-mixin';
import type { Map } from 'immutable';
import { fromJS, List } from 'immutable';
import _ from 'underscore';

import { cn, Icon, Tooltip } from '@keboola/design';

import { sortEntities } from '@/constants';
import { useAllowedProjectIds, useSorter } from '@/hooks';
import { canUnlinkBucket } from '@/modules/admin/privileges';
import { MetadataKeys } from '@/modules/components/MetadataConstants';
import StorageApiBucketLinkEx from '@/modules/components/react/components/StorageApiBucketLinkEx';
import { routeNames } from '@/modules/data-catalog/constants';
import { prepareSharedBuckets } from '@/modules/data-catalog/helpers';
import type { FlatTableRow } from '@/modules/data-catalog/types';
import type { SortConfig } from '@/modules/data-catalog/utils/sortStorage';
import { getInitialSort, saveSort, sortKeys } from '@/modules/data-catalog/utils/sortStorage';
import { getDescriptionValue } from '@/modules/storage/helpers';
import { CircleIcon, DisableTooltip, Truncated } from '@/react/common';
import MarkedText from '@/react/common/MarkedText';
import { DEFAULT_BUCKET_COLOR } from '@/react/constants';
import string from '@/utils/string';
import { lastModified, ownerColumn, sizeColumn } from './tableComponents/tableColumns';
import { DataCatalogTable } from './DataCatalogTable';
import LinkButton from './LinkButton';
import { SharedLabel, SortableHeader } from './tableComponents';
import UnlinkButton from './UnlinkButton';

const updateSort = (newSort: SortConfig) => {
  saveSort(sortKeys.SHARED_WITH_YOU, newSort);
};

type Props = {
  admins: Map<string, any>;
  currentAdmin: Map<string, any>;
  sharedBuckets: Map<string, any>;
  linkedBuckets: Map<string, any>;
  currentProjectId: string;
  sapiToken: Map<string, any>;
  allBuckets: Map<string, any>;
  urlTemplates: Map<string, any>;
};

export const SharedWithYouBuckets = memo(
  ({
    admins,
    currentAdmin,
    sharedBuckets,
    linkedBuckets,
    currentProjectId,
    sapiToken,
    allBuckets,
    urlTemplates,
  }: Props) => {
    const { sort, sorter, setSort } = useSorter(
      fromJS(getInitialSort(sortKeys.SHARED_WITH_YOU)),
      updateSort,
    );

    const allowedProjectIds = useAllowedProjectIds();

    const sharedAndLinkedBuckets: Map<string, any> = useMemo(() => {
      return prepareSharedBuckets(sharedBuckets, linkedBuckets, currentProjectId);
    }, [sharedBuckets, linkedBuckets, currentProjectId]);

    const isUnsharedLinkedBucket = useCallback(
      (bucket: Map<string, any>) => {
        return (
          !!bucket.get('linkedBucket') &&
          canUnlinkBucket(sapiToken, bucket.get('linkedBucket')) &&
          !sharedBuckets.some((sharedBucket) => sharedBucket.get('id') === bucket.get('id'))
        );
      },
      [sapiToken, sharedBuckets],
    );

    const getColumns = useCallback(
      (query: string) => {
        return [
          {
            id: 'displayName',
            header: () => (
              <SortableHeader
                label="Name"
                entityKey={sortEntities.NAME}
                arrowSide="right"
                sort={sort}
                setSort={setSort}
              />
            ),
            cell: ({ row }: { row: FlatTableRow }) => {
              const bucket = row.original;
              const bucketColor = bucket.get('color');
              const metadata = bucket.get('metadata', List());
              const displayName = bucket.get('displayName');

              const description =
                getDescriptionValue(metadata, MetadataKeys.SHARED_DESCRIPTION) ||
                getDescriptionValue(metadata, MetadataKeys.DESCRIPTION);

              return (
                <div className={cn('tw-inline-flex tw-items-center')}>
                  <CircleIcon icon="folder" backgroundColor={bucketColor ?? DEFAULT_BUCKET_COLOR} />

                  <div className={cn('tw-items-center tw-flex-col tw-px-3')}>
                    <div className={cn('tw-inline-flex tw-items-center')}>
                      {isUnsharedLinkedBucket(bucket) && (
                        <Tooltip
                          placement="top"
                          type="explanatory"
                          tooltip={
                            <>
                              This bucket is no longer shared
                              <br /> with you. If you unlink it, you
                              <br /> won&apos;t be able to link it back.
                            </>
                          }
                          triggerClassName="tw-inline"
                        >
                          <Icon icon="triangle-exclamation" className="f-15 text-warning tw-mr-1" />
                        </Tooltip>
                      )}
                      <Truncated
                        tooltip={displayName}
                        text={<MarkedText source={displayName} mark={query} />}
                      />
                    </div>
                    <div className="text-muted tw-w-[280px]">
                      {bucket.get('linkedBucket') ? (
                        <>
                          Linked as{' '}
                          <StorageApiBucketLinkEx
                            bucketId={bucket.getIn(['linkedBucket', 'id'])}
                            hideLabels
                            truncateBucketName
                            truncateLimit={25}
                          />
                        </>
                      ) : (
                        <div className="tw-truncate">{string.truncate(description, 60)}</div>
                      )}
                    </div>
                  </div>
                </div>
              );
            },
          },
          ownerColumn({ admins, sort, setSort }),
          sizeColumn({ sort, setSort }),
          {
            id: 'shared',
            header: () => (
              <SortableHeader
                label="Shared from"
                entityKey={sortEntities.SHARED}
                sort={sort}
                setSort={setSort}
              />
            ),
            cell: ({ row }: { row: FlatTableRow }) => {
              const bucket = row.original;
              const sharing = bucket.get('sharing');
              const projectId = bucket.getIn(['project', 'id']);
              const sharingParameters = bucket.get('sharingParameters');
              const { projects, users } = sharingParameters.toJS();
              const isDisabled = !allowedProjectIds.has(Number(projectId));

              return (
                <div className="tw-text-right">
                  <a
                    href={_.template(urlTemplates.get('project'))({ projectId })}
                    className={isDisabled ? 'disabled' : ''}
                    onClick={(e) => {
                      if (isDisabled) {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Tooltip
                      key={projectId}
                      placement="top"
                      type="explanatory"
                      forceHide={!isDisabled}
                      tooltip={<DisableTooltip />}
                      triggerClassName="tw-flex tw-items-center tw-justify-end"
                    >
                      <div className={isDisabled ? 'text-muted' : ''}>
                        <Truncated text={bucket.getIn(['project', 'name'])} />
                      </div>
                      {isDisabled && (
                        <Icon icon="lock" className="tw-mb-0.5 tw-ml-2 tw-text-neutral-400" />
                      )}
                    </Tooltip>
                  </a>
                  <div className="text-muted">
                    <SharedLabel projects={projects} users={users} sharing={sharing} />
                  </div>
                </div>
              );
            },
          },
          lastModified({ sort, setSort }),
          {
            id: 'actions',
            header: '',
            cell: ({ row }: { row: FlatTableRow }) => {
              const bucket = row.original;

              return (
                <div className="tw-flex tw-justify-end">
                  {bucket.get('linkedBucket') ? (
                    <UnlinkButton
                      size="small"
                      token={sapiToken}
                      bucket={bucket.get('linkedBucket')}
                      hideIcon
                    />
                  ) : (
                    <LinkButton
                      size="small"
                      buckets={allBuckets}
                      token={sapiToken}
                      bucket={bucket}
                    />
                  )}
                </div>
              );
            },
          },
        ];
      },
      [
        sort,
        admins,
        allBuckets,
        allowedProjectIds,
        urlTemplates,
        sapiToken,
        setSort,
        isUnsharedLinkedBucket,
      ],
    );

    return (
      <DataCatalogTable
        getColumns={getColumns}
        buckets={sharedAndLinkedBuckets}
        sourceTab={routeNames.SHARED_WITH_YOU}
        currentAdmin={currentAdmin}
        sorter={sorter}
      />
    );
  },
  shallowEqualImmutable,
);
