import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  onHideFn: () => void;
  onSendFn: (data: { email: string; message: string }) => Promise<void>;
  isSending: boolean;
  token: Map<string, any>;
};

export const SendTokenModal = (props: Props) => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const isValid = !!email && !!message;

  const handleClose = () => {
    props.onHideFn();
    setEmail('');
    setMessage('');
  };

  const handleSend = () => {
    props.onSendFn({ email, message }).then(handleClose);
  };

  const { token } = props;
  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Token {token.get('description')}</Modal.Title>
        <ModalIcon icon="share" color="green" bold />
      </Modal.Header>
      <Modal.Body>
        <Alert className="tw-mb-5">
          The recipient will receive an email with a link to retrieve the token. The link will
          expire in 24 hours.
        </Alert>
        <div className="form form-horizontal">
          <div className="form-group">
            <label htmlFor="recipient" className="control-label col-sm-3">
              Recipient
            </label>
            <div className="col-sm-9">
              <input
                id="recipient"
                placeholder="mail@example.com"
                disabled={props.isSending}
                className="form-control"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="message" className="control-label col-sm-3">
              Message
            </label>
            <div className="col-sm-9">
              <textarea
                id="message"
                placeholder="Here's the token to upload your data"
                disabled={props.isSending}
                className="form-control"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                rows={3}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={props.isSending}
          isDisabled={!isValid}
          onSave={handleSend}
          saveLabel="Send"
        />
      </Modal.Footer>
    </Modal>
  );
};
