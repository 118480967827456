import { Modal } from 'react-bootstrap';
import type { List, Map } from 'immutable';

import { Alert } from '@keboola/design';

import { isCreatedInDevBranch } from '@/modules/dev-branches/helpers';
import { getTableAliases, getTableLinks } from '@/modules/storage/helpers';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import ModalIcon from '@/react/common/ModalIcon';
import DevBranchStorageWarning from './DevBranchStorageWarning';
import TableAliasesAndLinks from './TableAliasesAndLinks';
import { TriggersStorageWarning } from './TriggersStorageWarning';

type Props = {
  bucket: Map<string, any>;
  tables: List<any>;
  allTables: Map<string, any>;
  sapiToken: Map<string, any>;
  urlTemplates: Map<string, any>;
  show: boolean;
  deleting: boolean;
  onConfirm: () => Promise<void>;
  onHide: () => void;
};

const DeleteMultipleTablesModal = ({
  bucket,
  tables,
  allTables,
  sapiToken,
  urlTemplates,
  show,
  deleting,
  onConfirm,
  onHide,
}: Props) => {
  const tablesAliases = tables
    .toArray()
    .reduce<
      Map<string, any>[]
    >((tableAliases, table) => [...tableAliases, ...getTableAliases(table, allTables, sapiToken)], []);
  const tablesLinks = tables
    .toArray()
    .reduce<
      Map<string, any>[]
    >((tableLinks, table) => [...tableLinks, ...getTableLinks(table, bucket)], []);

  const hasAliasesOrLinks = tablesAliases.length + tablesLinks.length > 0;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Selected Tables</Modal.Title>
        <ModalIcon.Trash />
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete the selected tables?</p>
        <DevBranchStorageWarning
          message="The tables will also be deleted in production."
          hasProductionEntity={tables.some((table) => !isCreatedInDevBranch(table.get('bucket')))}
        />
        {hasAliasesOrLinks && (
          <>
            <Alert variant="warning" className="tw-my-4">
              All alias tables and links will also be deleted.
            </Alert>
            <TableAliasesAndLinks
              sapiToken={sapiToken}
              urlTemplates={urlTemplates}
              tableAliases={tablesAliases}
              tableLinks={tablesLinks}
              onLinkClick={onHide}
            />
          </>
        )}
        <TriggersStorageWarning tables={tables.map((table) => table.get('id')).toArray()} />
      </Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={deleting}
          isDisabled={deleting}
          saveLabel={deleting ? 'Deleting...' : 'Delete'}
          saveStyle="danger"
          onSave={() => onConfirm().then(onHide)}
        />
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteMultipleTablesModal;
