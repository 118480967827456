import { useState } from 'react';
import type { Map } from 'immutable';

import { Button, ButtonGroup, Icon } from '@keboola/design';

import { NEW_ENTITY_BUTTON } from '@/constants/external';
import { canCreateAliasTable, canWriteBucket } from '@/modules/admin/privileges';
import BucketsStore from '@/modules/components/stores/StorageBucketsStore';
import FilesStore from '@/modules/components/stores/StorageFilesStore';
import TablesStore from '@/modules/components/stores/StorageTablesStore';
import {
  createAliasTable,
  createTable,
  createTableDefinition,
  uploadFile,
} from '@/modules/storage/actions';
import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import CreateAliasTableModal from './CreateAliasTableModal';
import CreateTableModal from './CreateTableModal';

const CreateTableButton = () => {
  const store = useStores(
    () => {
      const bucketId = RoutesStore.getCurrentRouteParam('bucketId');

      return {
        sapiToken: ApplicationStore.getSapiToken(),
        bucket: BucketsStore.getBucket(bucketId),
        tables: (TablesStore.getAll() as Map<string, any>).filter(
          (table) => table.getIn(['bucket', 'id']) === bucketId,
        ) as Map<string, any>,
        allBuckets: BucketsStore.getAll(),
        allTables: TablesStore.getAll() as Map<string, any>,
        creatingTable: TablesStore.getIsCreatingTable(),
        creatingAliasTable: TablesStore.getIsCreatingAliasTable(),
        uploadingProgress: FilesStore.getUploadingProgress(bucketId),
        hasNewQueue: ApplicationStore.hasNewQueue(),
        defaultBackend: ApplicationStore.getCurrentProject().get('defaultBackend'),
      };
    },
    [],
    [ApplicationStore, BucketsStore, TablesStore, FilesStore],
  );

  const [showCreateTableModal, setShowCreateTableModal] = useState(false);
  const [showCreateAliasTableModal, setShowCreateAliasTableModal] = useState(false);

  if (!canWriteBucket(store.sapiToken, store.bucket)) {
    return null;
  }

  const handleCreateTable = (file: File, params: Record<string, any>) => {
    const bucketId = store.bucket.get('id');

    return uploadFile(bucketId, file).then((dataFileId) => {
      return createTable(bucketId, { ...params, dataFileId });
    });
  };

  const handleCreateTableDefinition = (params: Record<string, any>) => {
    return createTableDefinition(store.bucket.get('id'), params);
  };

  const handleCreateAliasTable = (params: Record<string, any>) => {
    return createAliasTable(store.bucket.get('id'), params);
  };

  return (
    <>
      <ButtonGroup>
        {canCreateAliasTable(store.sapiToken, store.bucket) && (
          <Button
            variant="outline"
            onClick={() => setShowCreateAliasTableModal(true)}
            disabled={store.creatingAliasTable}
            className={NEW_ENTITY_BUTTON}
          >
            <Icon icon="plus" />
            Create alias table
          </Button>
        )}
        <Button
          onClick={() => setShowCreateTableModal(true)}
          disabled={store.creatingTable || store.uploadingProgress > 0}
          className={NEW_ENTITY_BUTTON}
        >
          <Icon icon="plus" />
          Add Table
        </Button>
      </ButtonGroup>
      <CreateTableModal
        hasNewQueue={store.hasNewQueue}
        bucket={store.bucket}
        tables={store.tables}
        openModal={showCreateTableModal}
        onCreateFromCsv={handleCreateTable}
        onCreateDefinition={handleCreateTableDefinition}
        onHide={() => setShowCreateTableModal(false)}
        isCreatingTable={store.creatingTable}
        progress={store.uploadingProgress}
        defaultBackend={store.defaultBackend}
      />
      {canCreateAliasTable(store.sapiToken, store.bucket) && (
        <CreateAliasTableModal
          bucket={store.bucket}
          buckets={store.allBuckets}
          tables={store.allTables}
          openModal={showCreateAliasTableModal}
          onSubmit={handleCreateAliasTable}
          onHide={() => setShowCreateAliasTableModal(false)}
          isSaving={store.creatingAliasTable}
        />
      )}
    </>
  );
};

export default CreateTableButton;
