import { Fragment } from 'react';
import createReactClass from 'create-react-class';

import { Icon } from '@keboola/design';

import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import dayjs from '@/date';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { routeNames } from '@/modules/legacy-transformation/Constants';
import TransformationType from '@/modules/legacy-transformation/react/components/TransformationType';
import TransformationBucketsStore from '@/modules/legacy-transformation/stores/TransformationBucketsStore';
import { BlankTransformationsPage } from '@/modules/transformations/react/components/BlankTransformationsPage';
import { FilterPanel, RouterLink, Truncated } from '@/react/common';
import ComponentIcon from '@/react/common/ComponentIcon';
import Gravatar from '@/react/common/Gravatar';
import InlineDescriptionEditInput from '@/react/common/InlineDescriptionEditInput';
import MarkedText from '@/react/common/MarkedText';
import createStoreMixin from '@/react/mixins/createStoreMixin';
import ApplicationStore from '@/stores/ApplicationStore';
import RoutesStore from '@/stores/RoutesStore';
import { matchByWords } from '@/utils';

const TransformationsIndex = createReactClass({
  mixins: [createStoreMixin(ApplicationStore, TransformationBucketsStore)],

  getStateFromStores() {
    return {
      admins: ApplicationStore.getAdmins(),
      readOnly: ApplicationStore.isReadOnly(),
      buckets: TransformationBucketsStore.getAll(),
    };
  },

  getInitialState() {
    return {
      filter: RoutesStore.getRouterState().getIn(['location', 'query', 'q'], ''),
    };
  },

  render() {
    if (!this.state.buckets || !this.state.buckets.count()) {
      return (
        <BlankTransformationsPage
          transformationLanguagesText={'They can be written in SQL (Snowflake), R, Python.'}
        />
      );
    }

    return (
      <>
        <FilterPanel
          placeholder="Search buckets"
          query={this.state.filter}
          onChange={(query) => {
            this.setState({ filter: query });
            RoutesStore.getRouter().updateQuery({ q: query });
          }}
        />
        {this.state.filter.length > 0 ? this.renderFilteredBuckets() : this.renderBuckets()}
      </>
    );
  },

  renderBuckets() {
    return (
      <>
        <ComponentIcon
          component={ComponentsStore.getComponent(KEBOOLA_LEGACY_TRANSFORMATION)}
          className="box-icon"
        />
        <div className="box mb-2">
          <div className="table table-hover">
            <div className="thead">
              <div className="tr">
                <div className="th contain-header">
                  <h3 className="m-0">Buckets</h3>
                </div>
                <div className="th w-280">Last Change</div>
              </div>
            </div>
            <div className="tbody">
              {this.state.buckets
                .sortBy((bucket) => bucket.get('name').toLowerCase())
                .map(this.renderRow)
                .toArray()}
            </div>
          </div>
        </div>
      </>
    );
  },

  renderFilteredBuckets() {
    const buckets = this.getFilteredBuckets();

    return (
      <div className="box searchbar-results-box">
        <div className="box-content with-search-results">
          {buckets.count() > 0 ? (
            <ul className="list-unstyled mb-0 f-14">
              {buckets
                .sortBy((bucket) => bucket.get('name').toLowerCase())
                .map(this.renderSearchRow)
                .toArray()}
            </ul>
          ) : (
            'No buckets or transformations found.'
          )}
        </div>
      </div>
    );
  },

  renderSearchRow(bucket) {
    return (
      <Fragment key={bucket.get('id')}>
        {bucket.has('matchBucket') && (
          <li>
            <RouterLink
              className="color-inherit flex-container flex-start"
              to={routeNames.BUCKET}
              params={{ config: bucket.get('id') }}
            >
              <ComponentIcon
                component={ComponentsStore.getComponent(KEBOOLA_LEGACY_TRANSFORMATION)}
                size="24"
                className="icon-addon-right"
              />
              <MarkedText source={bucket.get('name')} mark={this.state.filter} />
            </RouterLink>
          </li>
        )}
        {bucket.get('transformations').map((transformation) => (
          <li key={transformation.get('id')}>
            <RouterLink
              className="color-inherit flex-container flex-start"
              to={routeNames.DETAIL}
              params={{ config: bucket.get('id'), row: transformation.get('id') }}
            >
              <TransformationType
                imageClass="icon-addon-right"
                transformation={transformation}
                imageSize="24"
                showLabel={false}
              />
              <div>
                <span className="text-muted">
                  {bucket.get('name')}
                  <Icon icon={['far', 'angle-right']} fixedWidth />
                </span>
                <MarkedText source={transformation.get('name')} mark={this.state.filter} />
              </div>
            </RouterLink>
          </li>
        ))}
      </Fragment>
    );
  },

  renderRow(bucket) {
    return (
      <RouterLink
        key={bucket.get('id')}
        className="tr"
        to={routeNames.BUCKET}
        params={{ config: bucket.get('id') }}
      >
        <div className="td">
          <div className="flex-container flex-start">
            <Truncated text={bucket.get('name')} />
          </div>
          <span className="f-13 text-muted">
            <InlineDescriptionEditInput
              entity="Transformation Bucket"
              description={bucket.get('description', '')}
              onSave={(newDescription) =>
                this.handleDescriptionChange(bucket.get('id'), newDescription)
              }
              readOnly={this.state.readOnly}
            />
          </span>
        </div>
        <div className="td w-280 text-right">{this.renderUser(bucket)}</div>
      </RouterLink>
    );
  },

  renderUser(bucket) {
    const user = this.state.admins.get(
      bucket.getIn(['currentVersion', 'creatorToken', 'description']),
    );

    return (
      <div className="flex-container flex-end">
        <div className="text-right">
          <div>
            {user
              ? user.get('name')
              : bucket.getIn(['currentVersion', 'creatorToken', 'description'])}
          </div>
          <div className="text-muted f-13">
            {dayjs(bucket.getIn(['currentVersion', 'created'])).fromNow()}
          </div>
        </div>
        {user && (
          <span className="icon-addon-left">
            <Gravatar user={user} />
          </span>
        )}
      </div>
    );
  },

  getFilteredBuckets() {
    return this.state.buckets
      .map((bucket) => {
        if (
          matchByWords(
            [bucket.get('id'), bucket.get('name'), bucket.get('description')],
            this.state.filter,
          )
        ) {
          bucket = bucket.set('matchBucket', true);
        }

        return bucket.update('transformations', (transformations) =>
          transformations.filter((transformation) => {
            return matchByWords(
              [transformation.get('name'), transformation.get('id')],
              this.state.filter,
            );
          }),
        );
      })
      .filter((bucket) => bucket.has('matchBucket') || bucket.get('transformations').count() > 0);
  },

  handleDescriptionChange(configurationId, newDescription) {
    return InstalledComponentsActionCreators.updateComponentConfiguration(
      KEBOOLA_LEGACY_TRANSFORMATION,
      configurationId,
      { description: newDescription },
      'Update description',
      'description',
    );
  },
});

export default TransformationsIndex;
