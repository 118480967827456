import { memo, useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import type { EdgeTypes, NodeTypes } from '@xyflow/react';
import { Background, ReactFlow, useReactFlow } from '@xyflow/react';
import _ from 'underscore';

import { IconButton, Tooltip } from '@keboola/design';

import { KEBOOLA_FLOW } from '@/constants/componentIds';
import { ComponentNameEdit } from '@/modules/components/react/components/ComponentNameEdit';
import ComponentsStore from '@/modules/components/stores/ComponentsStore';
import { AloneEdge } from '@/modules/flows-v2/builder/edges/AloneEdge';
import { EdgeWithButton } from '@/modules/flows-v2/builder/edges/EdgeWithButton';
import { isEmptyFlow } from '@/modules/flows-v2/builder/helpers';
import { useCenterNode, useLayoutNodes } from '@/modules/flows-v2/builder/hooks';
import { AddNode } from '@/modules/flows-v2/builder/nodes/AddNode';
import { EmptyNode } from '@/modules/flows-v2/builder/nodes/EmptyNode';
import { Phase } from '@/modules/flows-v2/builder/nodes/Phase';
import { useBuilderStore } from '@/modules/flows-v2/builder/store/store';
import type { AppNode } from '@/modules/flows-v2/builder/types';
import DetailHeader from '@/modules/flows-v2/DetailHeader';
import ComponentIcon from '@/react/common/ComponentIcon';
import FullScreenModal from '@/react/common/FullScreenModal';
import { CanvasControls } from './CanvasControls';

const proOptions = { hideAttribution: true };

const nodeTypes: NodeTypes = {
  empty: EmptyNode,
  add: AddNode,
  phase: Phase,
};

const edgeTypes: EdgeTypes = {
  alone: AloneEdge,
  between: EdgeWithButton,
};

export const BuilderCanvas = memo(() => {
  const { getViewport, setViewport } = useReactFlow<AppNode>();

  const [
    nodes,
    edges,
    context,
    isFullScreen,
    getNodes,
    onNodesChange,
    onEdgesChange,
    setSelectedAddButton,
    setIsFullScreen,
    setNodeDragging,
  ] = useBuilderStore((state) => [
    state.nodes,
    state.edges,
    state.context,
    state.isFullScreen,
    state.getNodes,
    state.onNodesChange,
    state.onEdgesChange,
    state.setSelectedAddButton,
    state.setIsFullScreen,
    state.setNodeDragging,
  ]);

  // layout the nodes when the nodes or edges change
  useLayoutNodes();

  // center the top node when the canvas size changes
  const centerNode = useCenterNode();

  const centerTopNode = useCallback(() => {
    const topNode = getNodes()[0];

    if (topNode) {
      centerNode(topNode);
    }
  }, [centerNode, getNodes]);

  const onlyEmptyNode = isEmptyFlow(nodes);

  // reset the viewport when the flow is empty
  useEffect(() => {
    const defaultViewport = { x: window.innerWidth / 2 - 337, y: 120, zoom: 1 };

    if (onlyEmptyNode && !_.isEqual(getViewport(), defaultViewport)) {
      setViewport(defaultViewport);
    }
  }, [onlyEmptyNode, getViewport, setViewport]);

  const closeFullScreen = () => {
    const viewport = getViewport();

    setIsFullScreen(false);
    requestAnimationFrame(() => setViewport(viewport));
  };

  const commonProps = {
    maxZoom: 1,
    disableKeyboardA11y: true,
    nodeDragThreshold: 10,
    panOnScroll: !onlyEmptyNode,
    panOnDrag: onlyEmptyNode ? false : [0],
    minZoom: onlyEmptyNode ? 1 : 0.5,
    deleteKeyCode: null,
    selectionKeyCode: null,
    panActivationKeyCode: null,
    multiSelectionKeyCode: null,
    nodesFocusable: false,
    nodesConnectable: false,
    edgesFocusable: false,
    zoomOnDoubleClick: false,
    elevateNodesOnSelect: false,
    nodeTypes,
    edgeTypes,
    proOptions,
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onPaneClick: () => setSelectedAddButton(null),
    onNodeDragStart: () => setNodeDragging(true),
    onNodeDragStop: () => setNodeDragging(false),
  };

  if (isFullScreen) {
    return (
      <FullScreenModal onHide={closeFullScreen} className="[&_.modal-content]:tw-p-0">
        <div className="tw-z-10 tw-flex tw-h-[72px] tw-w-full tw-items-center tw-justify-between tw-gap-4 tw-self-start tw-border-x-0 tw-border-b tw-border-t-0 tw-border-solid tw-border-neutral-150 tw-bg-white tw-px-6 tw-py-4 tw-leading-5">
          <div className="tw-inline-flex tw-items-center tw-gap-3">
            <ComponentIcon size="32" component={ComponentsStore.getComponent(KEBOOLA_FLOW)} />
            <h2 className="tw-m-0 tw-text-2xl tw-text-neutral-900">
              <ComponentNameEdit componentId={KEBOOLA_FLOW} configId={context.config.get('id')} />
            </h2>
          </div>
          <div className="tw-inline-flex tw-items-center tw-justify-end tw-gap-4">
            <DetailHeader />
            <div className="tw-inline-block tw-h-6 tw-w-px tw-bg-neutral-200"></div>
            <span className="tw-flex tw-items-center">
              <IconButton onClick={closeFullScreen} variant="outline" icon="xmark" />
            </span>
          </div>
        </div>
        <Modal.Body className="tw-h-[calc(100vh-96px)] tw-content-center !tw-p-0">
          <ReactFlow {...commonProps}>
            {!onlyEmptyNode && <CanvasControls />}
            <Background />
          </ReactFlow>
        </Modal.Body>
      </FullScreenModal>
    );
  }

  return (
    <Tooltip
      placement="top"
      tooltip="Open Builder"
      triggerClassName="-tw-mt-6"
      triggerOnClick={() => {
        setIsFullScreen(true);
        requestAnimationFrame(() => centerTopNode());
      }}
    >
      <div className="tw-group tw-absolute tw-inset-x-0 tw-h-[-webkit-fill-available]">
        <div className="tw-absolute tw-inset-0 tw-z-10 tw-h-full tw-w-full group-hover:tw-bg-[rgba(34,37,41,0.1)] group-hover:tw-opacity-40"></div>
        <ReactFlow {...commonProps} fitView minZoom={0.2}>
          <Background />
        </ReactFlow>
      </div>
    </Tooltip>
  );
});
