import PropTypes from 'prop-types';
import ImmutableRenderMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';
import { Map } from 'immutable';

import { cn, Icon, Tooltip } from '@keboola/design';

import { ioType } from '@/modules/components/Constants';
import IncrementalResetWarning from '@/modules/components/react/components/IncrementalResetWarning';
import RunComponentButton from '@/modules/components/react/components/RunComponentButton';
import SapiTableLinkEx from '@/modules/components/react/components/StorageApiTableLinkEx';
import { tableName } from '@/modules/storage/helpers';
import WritersApi from '@/modules/wr-db/WritersApi';
import { RouterLink, RowActionDropdown, RowActionMenuItem } from '@/react/common';
import ActivateDeactivateSwitch from '@/react/common/ActivateDeactivateSwitch';
import InputOutputTypeIcon from '@/react/common/InputOutputTypeIcon';
import Loader from '@/react/common/Loader';

const TableRow = createReactClass({
  mixins: [ImmutableRenderMixin],

  propTypes: {
    readOnly: PropTypes.bool.isRequired,
    tableExists: PropTypes.bool.isRequired,
    isTableExported: PropTypes.bool.isRequired,
    isPending: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    onExportChangeFn: PropTypes.func.isRequired,
    table: PropTypes.object.isRequired,
    tableDbName: PropTypes.string.isRequired,
    configId: PropTypes.string.isRequired,
    componentId: PropTypes.string.isRequired,
    deleteTableFn: PropTypes.func.isRequired,
    isDeleting: PropTypes.bool.isRequired,
    showIncrementalResetWarning: PropTypes.bool.isRequired,
    tablesWithSourceSearchInputMapping: PropTypes.instanceOf(Map).isRequired,
    isIncremental: PropTypes.bool.isRequired,
  },

  render() {
    if (!this.props.tableExists) {
      return (
        <div
          className={cn('tr', {
            'text-muted': !this.props.tableExists,
            'row-disabled': !this.props.isTableExported,
          })}
        >
          {this.renderBody()}
        </div>
      );
    }

    return (
      <RouterLink
        className={cn('tr hoverable-actions', {
          'text-muted': !this.props.tableExists,
          'row-disabled': !this.props.isTableExported,
        })}
        to={`${this.props.componentId}-table`}
        params={{
          config: this.props.configId,
          tableId: this.props.table.get('id'),
        }}
      >
        {this.renderBody()}
      </RouterLink>
    );
  },

  renderBody() {
    return (
      <>
        <span className="td col-xs-5">{this.renderSourceTable()}</span>
        <span className="td">
          {this.props.isIncremental ? (
            <Tooltip placement="top" tooltip="Incremental">
              <Icon icon={['far', 'chevron-right']} className="text-muted" fixedWidth />
            </Tooltip>
          ) : (
            <Icon icon={['far', 'chevron-right']} className="text-muted" fixedWidth />
          )}
        </span>
        <span className="td col-xs-5">
          <InputOutputTypeIcon type={ioType.TABLE} />
          {this.props.tableDbName}
        </span>
        <span className="td pr-1">
          {!this.props.readOnly && (
            <RowActionDropdown
              showLoading={this.props.isUpdating || this.props.isDeleting || this.props.isPending}
            >
              {this.props.tableExists && (
                <>
                  <RunComponentButton
                    mode="menuitem"
                    title="Upload Table"
                    componentId={this.props.componentId}
                    disabled={this.props.isUpdating}
                    runParams={() => {
                      const api = WritersApi(this.props.componentId);
                      return api.getTableRunParams(this.props.configId, this.props.table.get('id'));
                    }}
                    forceModal={this.props.showIncrementalResetWarning}
                  >
                    {this.props.showIncrementalResetWarning && <IncrementalResetWarning />}
                    <p>
                      You are about to run an upload of {tableName(this.props.table)} to the
                      database.
                    </p>
                  </RunComponentButton>
                  <RowActionMenuItem divider />
                </>
              )}
              {this.props.tableExists && (
                <>
                  <ActivateDeactivateSwitch
                    mode="menuitem"
                    isActive={this.props.isTableExported}
                    isPending={this.props.isPending}
                    onChange={this.props.onExportChangeFn}
                    buttonDisabled={this.props.isUpdating}
                  />
                  <RowActionMenuItem divider />
                </>
              )}
              <RowActionMenuItem
                onSelect={() => this.props.deleteTableFn(this.props.table.get('id'))}
                disabled={this.props.isDeleting || this.props.isUpdating}
              >
                {this.props.isDeleting ? <Loader /> : <Icon icon="trash" fixedWidth />}
                Remove table
              </RowActionMenuItem>
            </RowActionDropdown>
          )}
        </span>
      </>
    );
  },

  renderSourceTable() {
    if (this.props.tablesWithSourceSearchInputMapping.has(this.props.table.get('id'))) {
      return (
        <>
          Search table by metadata
          <br />
          <code>
            {this.props.tablesWithSourceSearchInputMapping.getIn([
              this.props.table.get('id'),
              'source_search',
              'value',
            ])}
          </code>
        </>
      );
    }

    return <SapiTableLinkEx tableId={this.props.table.get('id')} showOnlyDisplayName />;
  },
});

export default TableRow;
