import { Component } from 'react';
import PropTypes from 'prop-types';
import { List, Map } from 'immutable';

import { Icon } from '@keboola/design';

import { hasDisabledRollback } from '@/modules/components/helpers';
import VersionsActionCreators from '@/modules/components/VersionsActionCreators';
import RowVersionsActionCreators from '@/modules/configurations/RowVersionsActionCreators';
import { RouterLink } from '@/react/common';
import Loader from '@/react/common/Loader';
import { getPreviousVersion } from '@/utils/VersionsDiffUtils';
import DiffVersionButton from './DiffVersionButton';
import SidebarVersionsRow from './SidebarVersionsRow';

class SidebarVersions extends Component {
  render() {
    return (
      <div className="sidebar-box">
        <h4 className="flex-container mb-1">
          <span>Versions {this.props.isLoading && <Loader />}</span>
          {this.renderLatestChangeDiffButton()}
        </h4>
        {this.renderVersions()}
        {this.renderAllVersionsLink()}
      </div>
    );
  }

  renderVersions() {
    if (this.props.versions.isEmpty()) {
      return <p className="text-muted">No versions found</p>;
    }

    return this.props.versions
      .slice(0, 3)
      .map((version) => {
        const admin = this.props.admins.get(version.getIn(['creatorToken', 'description']));

        return (
          <div key={version.get('version')} className="version-row">
            <SidebarVersionsRow
              version={version}
              admin={admin}
              isLast={version.get('version') === this.props.versions.first().get('version')}
            />
          </div>
        );
      })
      .toArray();
  }

  renderAllVersionsLink() {
    if (this.props.versions.isEmpty()) {
      return null;
    }

    return (
      <RouterLink
        className="version-link versions-page dark"
        to={this.props.versionsLinkTo}
        params={this.getVersionsLinkParams()}
      >
        <Icon icon="angle-right" className="icon-addon-right" />
        Show All Versions
      </RouterLink>
    );
  }

  renderLatestChangeDiffButton() {
    if (this.props.versions.isEmpty()) {
      return null;
    }

    const version = this.props.versions.first();
    const previousVersion = getPreviousVersion(this.props.versions, version);
    const previousVersionConfig = getPreviousVersion(this.props.versionsConfigs, version) || Map();
    const currentVersionConfig =
      this.props.versionsConfigs
        .filter((currentVersion) => version.get('version') === currentVersion.get('version'))
        .first() || Map();
    const isMultiPending = this.props.pendingMultiLoad.get(version.get('version'), false);

    return (
      <DiffVersionButton
        admins={this.props.admins}
        component={this.props.component}
        config={this.props.config}
        row={this.props.row}
        isDisabled={isMultiPending || this.props.isPending}
        isPending={isMultiPending}
        onLoadVersionConfig={() => this.prepareVersionsDiffData(version, previousVersion)}
        versionConfig={currentVersionConfig}
        previousVersionConfig={previousVersionConfig}
        sharedCodes={this.props.sharedCodes}
        hideRollback={hasDisabledRollback(this.props.component.get('id'))}
        buttonClassName="btn-link-inline compare-latest dark"
        iconClassName="f-14 btn-icon"
        text="See Latest Changes"
      />
    );
  }

  getVersionsLinkParams() {
    if (this.props.versionsLinkParams) {
      return this.props.versionsLinkParams;
    }

    return {
      component: this.props.component.get('id'),
      config: this.props.configId,
      row: this.props.rowId,
    };
  }

  prepareVersionsDiffData(version1, version2) {
    if (this.props.rowId) {
      return RowVersionsActionCreators.loadTwoComponentConfigVersions(
        this.props.component.get('id'),
        this.props.configId,
        this.props.rowId,
        version1.get('version'),
        version2.get('version'),
      );
    }

    return VersionsActionCreators.loadTwoComponentConfigVersions(
      this.props.component.get('id'),
      this.props.configId,
      version1.get('version'),
      version2.get('version'),
    );
  }
}

SidebarVersions.propTypes = {
  configId: PropTypes.string.isRequired,
  component: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  versions: PropTypes.object.isRequired,
  versionsConfigs: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isPending: PropTypes.bool.isRequired,
  pendingMultiLoad: PropTypes.object.isRequired,
  admins: PropTypes.object.isRequired,
  versionsLinkTo: PropTypes.string.isRequired,
  versionsLinkParams: PropTypes.object,
  sharedCodes: PropTypes.object,
  row: PropTypes.object,
  rowId: PropTypes.string,
  limit: PropTypes.number,
};

SidebarVersions.defaultProps = {
  versionsLinkParams: null,
  sharedCodes: List(),
  rowId: null,
  limit: 3,
};

export default SidebarVersions;
