import PropTypes from 'prop-types';
import ImmutableRendererMixin from 'react-immutable-render-mixin';
import createReactClass from 'create-react-class';

import { Badge } from '@keboola/design';

import { routeNames } from '@/modules/orchestrations/Constants';
import { CreatedDate, RouterLink } from '@/react/common';
import JobDuration from '@/react/common/JobDuration';
import JobStatusIcon from '@/react/common/JobStatusIcon';

const RunHistoryRow = createReactClass({
  mixins: [ImmutableRendererMixin],

  propTypes: {
    job: PropTypes.object.isRequired,
    isActive: PropTypes.bool.isRequired,
  },

  render() {
    return (
      <RouterLink
        to={routeNames.JOB}
        params={{
          orchestrationId: this.props.job.get('orchestrationId'),
          jobId: this.props.job.get('id'),
        }}
        className="job-link text-muted no-underline"
      >
        <div>
          <JobStatusIcon status={this.props.job.get('status')} className="icon-addon-right" />
          {this.props.isActive ? (
            <b className="mr-1">{this.props.job.getIn(['initiatorToken', 'description'])}</b>
          ) : (
            <span className="mr-1">{this.props.job.getIn(['initiatorToken', 'description'])}</span>
          )}
          {this.props.job.get('initializedBy') === 'manually' && <Badge text="manually" />}
        </div>
        <div className="text-muted time-info">
          <JobDuration
            showIcon
            status={this.props.job.get('status')}
            startTime={this.props.job.get('startTime')}
            endTime={this.props.job.get('endTime')}
          />
          {this.props.job.get('endTime') && (
            <>
              {' - '}
              <CreatedDate createdTime={this.props.job.get('endTime')} />
            </>
          )}
        </div>
      </RouterLink>
    );
  },
});

export default RunHistoryRow;
