import { fromJS, List } from 'immutable';
import _ from 'underscore';

import ApplicationActionCreators from '@/actions/ApplicationActionCreators';
import { KEBOOLA_LEGACY_TRANSFORMATION } from '@/constants/componentIds';
import dispatcher from '@/Dispatcher';
import * as installedComponentsConstants from '@/modules/components/Constants';
import InstalledComponentsActionCreators from '@/modules/components/InstalledComponentsActionCreators';
import VersionActionCreators from '@/modules/components/VersionsActionCreators';
import RoutesStore from '@/stores/RoutesStore';
import string from '@/utils/string';
import copyTransformationNotification from './react/components/notifications/copyTransformationNotification';
import deleteTransformationBucketNotification from './react/components/notifications/deleteTransformationBucketNotification';
import restoreTransformationBucketNotification from './react/components/notifications/restoreTransformationBucketNotification';
import TransformationBucketsStore from './stores/TransformationBucketsStore';
import TransformationsStore from './stores/TransformationsStore';
import parseQueries from './utils/parseQueries';
import { routeNames } from './Constants';
import * as constants from './Constants';
import transformationsApi from './TransformationsApiAdapter';

const updateTransformationEditingFieldQueriesStringDebouncer = _.debounce(function (
  bucketId,
  transformationId,
  queriesString,
) {
  dispatcher.handleViewAction({
    type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_START,
    bucketId: bucketId,
    transformationId: transformationId,
  });
  return parseQueries(queriesString)
    .then(function (splitQueries) {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_SUCCESS,
        bucketId: bucketId,
        transformationId: transformationId,
        splitQueries: splitQueries,
      });
    })
    .catch(function () {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES_ERROR,
        bucketId: bucketId,
        transformationId: transformationId,
      });
    });
}, 1000);

const reloadVersions = function (configId) {
  return VersionActionCreators.loadVersionsForce(KEBOOLA_LEGACY_TRANSFORMATION, configId);
};

export default {
  createTransformationBucket: function (data) {
    return transformationsApi
      .createTransformationBucket(data, 'Create transformation bucket ' + data.name)
      .tap(function () {
        return InstalledComponentsActionCreators.loadInstalledComponentsForce();
      })
      .then(function (bucket) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_BUCKET_CREATE_SUCCESS,
          bucket,
        });
        RoutesStore.getRouter().transitionTo(routeNames.BUCKET, {
          config: bucket.id,
        });
        return reloadVersions(bucket.id);
      });
  },
  createTransformation: function (bucketId, data) {
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), 'Create transformation ' + data.get('name'))
      .then(function (transformation) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        RoutesStore.getRouter().transitionTo(routeNames.DETAIL, {
          row: transformation.id,
          config: bucketId,
        });
        return reloadVersions(bucketId);
      });
  },
  createTransformationWithoutRedirect: function (bucketId, data) {
    return transformationsApi
      .createTransformation(bucketId, data.toJS(), 'Create transformation ' + data.get('name'))
      .then((transformation) => {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_CREATE_SUCCESS,
          bucketId: bucketId,
          transformation: transformation,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: copyTransformationNotification(bucketId, fromJS(transformation)),
        });
        return reloadVersions(bucketId);
      });
  },
  deleteTransformationBucket: function (bucketId) {
    const bucket = TransformationBucketsStore.get(bucketId);
    return transformationsApi.deleteTransformationBucket(bucketId).then(() => {
      dispatcher.handleViewAction({
        type: constants.ActionTypes.TRANSFORMATION_BUCKET_DELETE_SUCCESS,
        bucketId: bucketId,
      });
      dispatcher.handleViewAction({
        type: installedComponentsConstants.ActionTypes
          .INSTALLED_COMPONENTS_DELETE_CONFIGURATION_SUCCESS,
        componentId: KEBOOLA_LEGACY_TRANSFORMATION,
        configurationId: bucketId,
        transition: false,
      });
      InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
      ApplicationActionCreators.sendNotification({
        type: 'info',
        message: deleteTransformationBucketNotification(bucket),
        button: {
          label: 'Restore',
          action: () => this.restoreTransformationBucket(bucket),
        },
      });
      return null;
    });
  },
  restoreTransformationBucket: function (bucket) {
    const bucketId = bucket.get('id');
    return transformationsApi.restoreTransformationBucket(bucketId).then(function () {
      return InstalledComponentsActionCreators.loadComponentConfigsData(
        KEBOOLA_LEGACY_TRANSFORMATION,
      ).then(function () {
        ApplicationActionCreators.sendNotification({
          type: 'success',
          message: restoreTransformationBucketNotification(bucketId, bucket.get('name')),
        });
        return null;
      });
    });
  },
  deleteTransformation: function (bucketId, transformationId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_DELETE,
      bucketId: bucketId,
      transformationId: transformationId,
    });
    const transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    return transformationsApi
      .deleteTransformation(
        bucketId,
        transformationId,
        'Delete transformation ' + transformation.get('name'),
      )
      .then(function () {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_SUCCESS,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        InstalledComponentsActionCreators.loadComponentConfigsData(KEBOOLA_LEGACY_TRANSFORMATION);
        return reloadVersions(bucketId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_DELETE_ERROR,
          transformationId: transformationId,
          bucketId: bucketId,
        });
        throw error;
      });
  },

  changeTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    newValue,
    changeDescription,
  ) {
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId).set(
      propertyName,
      newValue,
    );

    return this.saveTransformation(
      bucketId,
      transformationId,
      transformation,
      'save-' + propertyName,
      changeDescription ||
        'Change ' + string.capitalize(propertyName) + ' in ' + transformation.get('name'),
    );
  },
  deleteTransformationProperty: function (
    bucketId,
    transformationId,
    propertyName,
    changeDescription,
  ) {
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId).delete(
      propertyName,
    );

    return this.saveTransformation(
      bucketId,
      transformationId,
      transformation,
      'save-' + propertyName,
      changeDescription ||
        'Delete ' + string.capitalize(propertyName) + ' in ' + transformation.get('name'),
    );
  },
  updateTransformationEditingField: function (bucketId, transformationId, fieldId, newValue) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
      newValue: newValue,
    });
  },
  updateTransformationEditingFieldQueriesString: function (
    bucketId,
    transformationId,
    queriesString,
  ) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_UPDATE_PARSE_QUERIES,
      bucketId: bucketId,
      transformationId: transformationId,
      queriesString: queriesString,
    });
    return updateTransformationEditingFieldQueriesStringDebouncer(
      bucketId,
      transformationId,
      queriesString,
    );
  },
  cancelTransformationEditingField: function (bucketId, transformationId, fieldId) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_CANCEL_EDITING_FIELD,
      bucketId: bucketId,
      transformationId: transformationId,
      fieldId: fieldId,
    });
  },

  saveTransformationQueries: function (bucketId, transformationId) {
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Queries in ' + transformation.get('name'),
    );
    transformation = transformation.set('queries', transformationEditingFields.get('splitQueries'));

    return this.saveTransformation(
      bucketId,
      transformationId,
      transformation,
      'save-queries',
      changeDescription,
    );
  },

  saveTransformationScript: function (bucketId, transformationId) {
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId);
    const transformationEditingFields = TransformationsStore.getTransformationEditingFields(
      bucketId,
      transformationId,
    );
    const changeDescription = transformationEditingFields.get(
      'description',
      'Change Scripts in ' + transformation.get('name'),
    );
    transformation = transformation.set(
      'queries',
      List([transformationEditingFields.get('queriesString')]),
    );
    return this.saveTransformation(
      bucketId,
      transformationId,
      transformation,
      'save-queries',
      changeDescription,
    );
  },

  saveTransformationEditingField: function (
    bucketId,
    transformationId,
    fieldId,
    changeDescription,
  ) {
    let value = TransformationsStore.getTransformationEditingFields(bucketId, transformationId).get(
      fieldId,
    );
    let transformation = TransformationsStore.getTransformation(bucketId, transformationId).set(
      fieldId,
      value,
    );

    return this.saveTransformation(
      bucketId,
      transformationId,
      transformation,
      'save-' + fieldId,
      changeDescription ||
        'Change ' + string.capitalize(fieldId) + ' in ' + transformation.get('name'),
    );
  },

  saveTransformation: function (
    bucketId,
    transformationId,
    transformation,
    pendingAction,
    changeDescription,
  ) {
    dispatcher.handleViewAction({
      type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_START,
      transformationId,
      bucketId,
      pendingAction,
    });
    return transformationsApi
      .saveTransformation(bucketId, transformationId, transformation.toJS(), changeDescription)
      .then(function (response) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_SUCCESS,
          transformationId,
          bucketId,
          pendingAction,
          data: response,
        });
        return reloadVersions(bucketId);
      })
      .catch(function (error) {
        dispatcher.handleViewAction({
          type: constants.ActionTypes.TRANSFORMATION_EDIT_SAVE_ERROR,
          transformationId,
          bucketId,
          pendingAction,
          error,
        });
        throw error;
      });
  },
};
