import React from 'react';
import BluebirdPromise from 'bluebird';
import { Map } from 'immutable';

import { EmptySearchContent, Icon, TextInput } from '@keboola/design';

import { KEBOOLA_DATA_APPS, KEBOOLA_ORCHESTRATOR } from '@/constants/componentIds';
import Box from '@/modules/flows/components/Box';
import ConfigurationRow from '@/modules/flows/components/ConfigurationRow';
import CreateConfiguration from '@/modules/flows/components/CreateConfiguration';
import { Truncated } from '@/react/common';
import { matchByWords } from '@/utils';
import Folder from './Folder';

export type Configuration = {
  id: string;
  name: string;
  description: string;
};

type Items = {
  withFolders: Record<string, Configuration[]>;
  rest: Configuration[];
};

type Props = {
  task: Map<string, any>;
  readOnly: boolean;
  hasPayAsYouGo: boolean;
  onSelectConfig: (configId: string) => Promise<any>;
  onSelectConfigWithRedirect: (
    event?: React.MouseEvent | null,
    componentId?: string,
    config?: string,
  ) => Promise<any>;
  allConfigurations: Map<string, any>;
  allComponents: Map<string, any>;
  folders: Map<string, any>;
  patternComponents: Map<string, any>;
  isDevModeActive: boolean;
};

const ComponentDetail = ({
  task,
  readOnly,
  hasPayAsYouGo,
  onSelectConfig,
  onSelectConfigWithRedirect,
  allConfigurations,
  allComponents,
  folders,
  patternComponents,
  isDevModeActive,
}: Props) => {
  const [query, setQuery] = React.useState('');

  const options = React.useMemo(() => {
    const result: Items = {
      withFolders: {},
      rest: [],
    };
    allConfigurations
      .getIn([task.get('componentId'), 'configurations'], Map())
      .sortBy((config: Map<string, any>) => {
        const lastChange = config.getIn(['currentVersion', 'created']);
        return -1 * new Date(lastChange).getTime();
      })
      .forEach((config: Map<string, any>, configId: string) => {
        const folder: string = folders.getIn([task.get('componentId'), configId]);

        const value: Configuration = {
          id: configId,
          name: config.get('name'),
          description: config.get('description'),
        };

        if (query && !matchByWords(value.name, query)) {
          return;
        }

        if (!folder) {
          result.rest.push(value);
          return;
        }

        if (!result.withFolders[folder]) {
          result.withFolders[folder] = [];
        }

        result.withFolders[folder].push(value);
      });

    return result;
  }, [allConfigurations, query, folders, task]);

  const componentId = task.get('componentId');
  const component = allComponents.get(componentId, Map());

  const handleSelectConfiguration = (_: any, configuration: Map<string, any>) => {
    return onSelectConfig(configuration.get('id'));
  };

  const isEmpty =
    options.rest.length === 0 &&
    Object.values(options.withFolders).every((configurations) => configurations.length === 0);
  const noResults = query && isEmpty;

  return (
    !task.get('invalid') && (
      <>
        <div className="tw-flex tw-flex-col tw-gap-2">
          <CreateConfiguration
            task={task}
            component={component}
            readOnly={readOnly}
            hasPayAsYouGo={hasPayAsYouGo}
            allConfigurations={allConfigurations}
            allComponents={allComponents}
            folders={folders}
            patternComponents={patternComponents}
            isDevModeActive={isDevModeActive}
            variant={isEmpty && !noResults ? 'primary' : 'outline'}
            query={query}
            customName={
              componentId === KEBOOLA_ORCHESTRATOR
                ? 'Flow'
                : componentId === KEBOOLA_DATA_APPS
                  ? 'Data App'
                  : null
            }
            onCreate={(componentId: string, configId: string) => {
              return BluebirdPromise.resolve().then(() => {
                setQuery('');
                onSelectConfigWithRedirect(null, componentId, configId);
              });
            }}
          />
          {(!isEmpty || noResults) && (
            <TextInput
              className="tw-mx-2 tw-mt-2"
              variant="secondary"
              prefix={<Icon className="tw-text-neutral-400" icon="magnifying-glass" />}
              placeholder="Search Configurations"
              value={query}
              onChange={(newValue) => setQuery(newValue)}
            />
          )}
        </div>
        {isEmpty ? (
          noResults ? (
            <EmptySearchContent />
          ) : (
            <p className="tw-mx-2">No configurations available</p>
          )
        ) : (
          <div className="tw-flex tw-flex-col tw-gap-4 tw-overflow-y-auto">
            <div className="tw-flex tw-flex-col tw-gap-1">
              {Object.entries(options.withFolders).map(([folder, configurations], index) => (
                <Folder
                  key={`${folder}-${index}-${query}`}
                  configurations={configurations}
                  folder={folder}
                  component={component}
                  query={query}
                  onSelect={handleSelectConfiguration}
                />
              ))}
            </div>
            <div className="tw-flex tw-flex-col tw-gap-1">
              <Box title="Configurations without folder">
                {options.rest.map((option) => {
                  return (
                    <ConfigurationRow
                      key={`${option.id}`}
                      withComponentType={false}
                      configuration={Map(option)}
                      component={component}
                      query={query}
                      onSelect={handleSelectConfiguration}
                      customFooterContent={
                        option.description ? (
                          <Truncated className="tw-text-neutral-400" text={option.description} />
                        ) : null
                      }
                    />
                  );
                })}
              </Box>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default ComponentDetail;
