import { useEffect, useState } from 'react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import type { DataApp } from '@keboola/api-client';
import { useApiClient } from '@keboola/api-client';

import { dataScienceApiKey } from '@/constants/queryKeys';
import Loader from '@/react/common/Loader';
import { Logs } from './Logs';

type Props = {
  app: DataApp;
};

export const TerminalLogs = ({ app }: Props) => {
  const isAppNotRunning = app.state !== 'running';

  const [nextTimestamp, setNextTimestamp] = useState<string | undefined>(undefined);
  const [allLogs, setAllLogs] = useState('');

  const api = useApiClient();
  const {
    data: { logs, nextLogTimestamp } = { logs: null, nextLogTimestamp: null },
    isError,
    isLoading,
  } = useQuery({
    queryKey: dataScienceApiKey.appLogsTail(app.id, { since: nextTimestamp }),
    queryFn: ({ signal, queryKey }) =>
      api.dataScience.getAppLogsTail(queryKey[1].appId, {
        query: {
          since: nextTimestamp,
        },
        signal,
      }),
    retry: false,
    placeholderData: keepPreviousData,
    refetchInterval: (query) => (query.state.error ? 10000 : 2000),
    enabled: !isAppNotRunning,
  });

  useEffect(() => {
    if (logs === null) return;
    setAllLogs((prev) => prev + logs);
  }, [logs]);

  useEffect(() => {
    if (nextLogTimestamp === null) return;
    setNextTimestamp(nextLogTimestamp);
  }, [nextLogTimestamp]);

  const renderedLogs = (() => {
    if (isAppNotRunning) return 'The App is not running';
    if (isLoading)
      return (
        <>
          <Loader /> Retrieving logs...
        </>
      );
    if (isError) return 'Something went wrong while connecting to the server';

    return allLogs ? allLogs : 'The app has not logs yet';
  })();

  return <Logs>{renderedLogs}</Logs>;
};
