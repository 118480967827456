import { KeboolaHttpHeader } from '../../constants';
import { createGenericFetchClient } from '../../fetchClient';

import type { ManagementToken, StorageToken } from './types';

export const createVerifyClient = () => {
  const storageApiToken = async (host: string, token: string, signal?: AbortSignal) => {
    const { data } = await createGenericFetchClient({
      baseUrl: host,
      headers: new Headers({ [KeboolaHttpHeader.STORAGE_API_TOKEN]: token }),
    }).get<StorageToken, void, void, void>('/v2/storage/tokens/verify', {}, { signal });

    return data;
  };

  const managementApiToken = async (host: string, token: string, signal?: AbortSignal) => {
    const { data } = await createGenericFetchClient({
      baseUrl: host,
      headers: new Headers({ [KeboolaHttpHeader.MANAGEMENT_API_TOKEN]: token }),
    }).get<ManagementToken, void, void, void>('/manage/tokens/verify', {}, { signal });

    return data;
  };

  return {
    storageApiToken,
    managementApiToken,
  };
};
