import type { Map } from 'immutable';

import { FILTERS, FILTERS_GROUP } from '@/constants';
import { getDescriptionWithDefaultValue } from '@/modules/data-catalog/helpers';
import type { BucketType } from '@/modules/data-catalog/types';
import { isBucketLinked } from '@/modules/storage/helpers';
import { matchByWords } from '@/utils';

export const getFilteredSortedBuckets = (
  buckets: BucketType[],
  searchFilters: Map<string, any>,
  currentAdmin: Map<string, any>,
  query: string,
  sorter: (a: any, b: any) => number,
): BucketType[] => {
  const connectionFilter = searchFilters?.toJS()[FILTERS_GROUP.SHARING];
  const ownershipFilter = searchFilters?.toJS()[FILTERS_GROUP.OWNERSHIP];

  const queryFilteredBuckets = buckets.filter((bucket) => {
    const id = bucket.get('id');
    const name = bucket.get('displayName');
    const description = getDescriptionWithDefaultValue(bucket, '');

    return (
      matchByWords([name, description], query) || id.toLowerCase() === query.trim().toLowerCase()
    );
  });

  const sharingFilteredBuckets = queryFilteredBuckets.filter((bucket) => {
    if (connectionFilter === FILTERS.LINKED) {
      return isBucketLinked(bucket) || bucket.has('linkedBucket');
    }

    return true;
  });

  const ownershipFilteredBuckets = sharingFilteredBuckets.filter((bucket) => {
    if (ownershipFilter) {
      const ownerEmail = bucket.getIn(['owner', 'email']);
      return ownerEmail === currentAdmin.get('email');
    }
    return true;
  });

  return ownershipFilteredBuckets.sort(sorter);
};
