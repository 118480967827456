import { useCallback, useEffect, useMemo, useState } from 'react';
import { useReactFlow } from '@xyflow/react';
import { useShallow } from 'zustand/react/shallow';

import { cn, IconButton } from '@keboola/design';

import { useGraphStore } from '@/modules/lineage/contexts/graphStore';
import type { CustomEdge, CustomNode } from '@/modules/lineage/rfTypes';
import { FilterPanel } from '@/react/common';
import { matchByWords } from '@/utils';
import { getFitViewOptions } from './initConfig';

const Search = (props: { inModal: boolean }) => {
  const { searchQuery, onSearch } = useGraphStore(
    useShallow((state) => ({
      searchQuery: state.searchQuery,
      onSearch: state.onSearch,
    })),
  );
  const { fitView, getNodes } = useReactFlow<CustomNode, CustomEdge>();
  const [focusNode, setFocusNode] = useState<number | null>(null);

  const matchedNodes = useMemo(() => {
    if (!searchQuery) {
      return [];
    }

    return getNodes().filter(({ data }) => {
      return matchByWords('displayName' in data ? data.displayName : data.name, searchQuery);
    });
  }, [searchQuery, getNodes]);

  useEffect(() => {
    const node = focusNode && matchedNodes[focusNode - 1];

    if (node) {
      fitView(getFitViewOptions({ id: node.id }));
    }
  }, [focusNode, matchedNodes, fitView]);

  const handleSearch = useCallback(
    (value: string) => {
      onSearch(value);
      setFocusNode(value ? 1 : null);
    },
    [onSearch],
  );

  const withControls = !!searchQuery;
  const results = matchedNodes.length;

  return (
    <span className="tw-relative">
      <FilterPanel
        asBox={false}
        placeholder="Search in Data lineage"
        className="tw-flex tw-items-center"
        searchBarClassName={cn(
          'condensed nodrag tw-mb-0 tw-w-80',
          props.inModal ? 'as-input' : 'tw-rounded tw-border tw-border-solid tw-border-neutral-200',
          { '[&_input]:!tw-pr-40': withControls },
        )}
        onChange={handleSearch}
      />
      {withControls && (
        <div className="tw-absolute tw-right-12 tw-top-1 tw-z-10 tw-inline-flex tw-h-8 tw-items-center">
          <span className="tw-text-xs tw-text-neutral-400">
            {results > 0 ? focusNode : 0}/{results}
          </span>
          <span className="tw-m-3 tw-inline-flex tw-h-4 tw-w-px tw-bg-neutral-200" />
          <IconButton
            variant="invisible"
            onClick={() => {
              if (focusNode) {
                setFocusNode(focusNode === results ? 1 : focusNode + 1);
              }
            }}
            disabled={!results}
            icon="angle-up"
          />
          <IconButton
            variant="invisible"
            onClick={() => {
              if (focusNode) {
                setFocusNode(focusNode < 2 ? results : focusNode - 1);
              }
            }}
            disabled={!results}
            icon="angle-down"
          />
        </div>
      )}
    </span>
  );
};

export default Search;
