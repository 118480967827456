import type { List } from 'immutable';

import { updateLocalStateValue } from '@/modules/flows-v2/helpers';
import { setPhaseConditions } from './components/Conditions/helpers';

export const updatePhaseConditions = (
  configId: string,
  phaseId: string,
  phases: List<any>,
  conditions: any[],
) => {
  const updatedPhases = setPhaseConditions(phaseId, phases, conditions);

  updateLocalStateValue(configId, ['phases'], updatedPhases);
};

export const deleteTask = (configId: string, taskId: string, tasks: List<any>) => {
  const updatedTasks = tasks.filter((t) => t.get('id') !== taskId);

  updateLocalStateValue(configId, ['tasks'], updatedTasks);
};
