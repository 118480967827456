import useStores from '@/react/hooks/useStores';
import ApplicationStore from '@/stores/ApplicationStore';
import EventsPage from './components/Events';
import StorageTabs from './components/StorageTabs';

const Events = () => {
  const store = useStores(
    () => ({
      admins: ApplicationStore.getAdmins(),
      hasProtectedDefaultBranch: ApplicationStore.hasProtectedDefaultBranch(),
      hasPayAsYouGo: ApplicationStore.hasPayAsYouGo(),
      canEnableDataStreams: ApplicationStore.canEnableDataStreams(),
    }),
    [],
    [ApplicationStore],
  );

  return (
    <StorageTabs
      hasProtectedDefaultBranch={store.hasProtectedDefaultBranch}
      hasPayAsYouGo={store.hasPayAsYouGo}
      canEnableDataStreams={store.canEnableDataStreams}
    >
      <EventsPage admins={store.admins} />
    </StorageTabs>
  );
};

export default Events;
