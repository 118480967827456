import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Promise from 'bluebird';
import type { Map } from 'immutable';

import { Alert } from '@keboola/design';

import jobsApi from '@/modules/jobs/JobsApi';
import queueApi from '@/modules/queue/api';
import { JOBS_STATUS, routeNames } from '@/modules/queue/constants';
import { TokenString } from '@/modules/tokens/react/components/TokenString';
import { RouterLink } from '@/react/common';
import ConfirmButtons from '@/react/common/ConfirmButtons';
import Loader from '@/react/common/Loader';
import ModalIcon from '@/react/common/ModalIcon';

type Props = {
  show: boolean;
  onHideFn: () => void;
  onRefreshFn: () => Promise<Map<string, any>>;
  isRefreshing: boolean;
  token: Map<string, any>;
  sapiToken: Map<string, any>;
  hasNewQueue: boolean;
};

export const RefreshTokenModal = (props: Props) => {
  const [newToken, setNewToken] = useState<Map<string, any> | null>(null);
  const [isValidating, setIsValidating] = useState(false);
  const [hasJobs, setHasJobs] = useState(false);

  const handleRefresh = () => {
    setNewToken(null);
    props.onRefreshFn().then((newToken) => setNewToken(newToken));
  };

  const handleClose = () => {
    props.onHideFn();
    setNewToken(null);
  };

  const checkRunningJobs = () => {
    setIsValidating(true);

    Promise.resolve()
      .then(() => {
        if (props.hasNewQueue) {
          return queueApi.getJobs(queryParams);
        }

        return jobsApi.getJobsByQuery(jobsQuery);
      })
      .then((jobs) => {
        setIsValidating(false);
        setHasJobs(jobs.length > 0);
      });
  };

  const queryParams = {
    tokenId: props.token.get('id'),
    status: [JOBS_STATUS.CREATED, JOBS_STATUS.WAITING, JOBS_STATUS.PROCESSING],
  };

  const jobsQuery = `token.id:${props.token.get(
    'id',
  )} AND (status:created OR status:waiting OR status:processing)`;

  const renderBody = () => {
    if (isValidating) {
      return (
        <p>
          <Loader /> Loading data...
        </p>
      );
    }

    if (newToken) {
      const canBeSeen =
        props.sapiToken.get('id') === newToken.get('id') || !newToken.get('isMasterToken');

      return (
        <>
          <Alert variant="success" className="tw-mb-5">
            Token has been refreshed.
            {canBeSeen && <> Make sure to copy it. You won&apos;t be able to see it again.</>}
          </Alert>
          {canBeSeen && <TokenString token={newToken} />}
        </>
      );
    }

    return (
      <>
        <p>
          You are about to refresh the token {props.token.get('description')} (
          {props.token.get('id')}). A new token will be generated and the old token becomes
          immediately invalid.
        </p>
        {hasJobs && (
          <Alert variant="error" className="tw-mb-5">
            The token is associated with some{' '}
            <RouterLink
              to={props.hasNewQueue ? routeNames.JOBS : 'jobs'}
              query={props.hasNewQueue ? queryParams : { q: jobsQuery }}
            >
              unfinished jobs
            </RouterLink>
            . Refreshing the token may cause these jobs to fail.
          </Alert>
        )}
      </>
    );
  };

  return (
    <Modal show={props.show} onHide={handleClose} onEnter={checkRunningJobs}>
      <Modal.Header closeButton>
        <Modal.Title>Refresh Token {props.token.get('description')}</Modal.Title>
        <ModalIcon icon="arrows-rotate" color="green" bold />
      </Modal.Header>
      <Modal.Body>{renderBody()}</Modal.Body>
      <Modal.Footer>
        <ConfirmButtons
          block
          isSaving={props.isRefreshing}
          onSave={handleRefresh}
          saveLabel="Refresh"
          showSave={!newToken}
        />
      </Modal.Footer>
    </Modal>
  );
};
